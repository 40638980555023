import React from 'react';
import PropTypes from 'prop-types';

const InformationIcon = ({ size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.2656 6.16585C11.8432 6.16585 6.6879 10.4955 5.75097 15.8364C4.81403 21.1772 8.45024 25.5069 13.8727 25.5069C19.2951 25.5069 24.4504 21.1772 25.3873 15.8364C26.3243 10.4955 22.6881 6.16585 17.2656 6.16585ZM3.56915 15.8364C4.71429 9.30862 11.0152 4.01685 17.6426 4.01685C24.27 4.01685 28.7143 9.30862 27.5691 15.8364C26.424 22.3641 20.1231 27.6559 13.4957 27.6559C6.86827 27.6559 2.42401 22.3641 3.56915 15.8364Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.5134 15.1194L16.2568 22.2827H12.6204L13.8771 15.1194L17.5134 15.1194Z"
      fill="black"
    />
    <path
      d="M18.5057 11.5384C18.2975 12.7253 17.1519 13.6874 15.9469 13.6874C14.7419 13.6874 13.9339 12.7253 14.1421 11.5384C14.3503 10.3515 15.4959 9.3894 16.7009 9.3894C17.9059 9.3894 18.7139 10.3515 18.5057 11.5384Z"
      fill="black"
    />
  </svg>
);

InformationIcon.propTypes = {
  size: PropTypes.number,
};
InformationIcon.defaultProps = {
  size: 32,
};

export default InformationIcon;

import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import i18next from 'i18next';
import CloseIcon from '../../components/Icons/CloseIcon';
import { fixtureProps } from '../../utils/customPropTypes';
import Toggle from '../../components/Toggle/Toggle';
import { getIsMobile } from '../../store/app/selectors';
import { textStates } from './util';
import { getLocaleDate } from '../../i18n';
import { fireEvent, GACategories, GATags } from '../../utils/trackingHelper';
import DotsIcon from '../../components/Icons/DotsIcon';
import PlusIcon from '../../components/Icons/PlusIcon';
import TrendingIcon from './TrendingIcon';
import TickIcon from '../../components/Icons/TickIcon';
import { truncateNumber } from '../../utils/general';
import PlainButton from '../../components/PlainButton/PlainButton';
import { getVenueFixturePreferences } from '../../store/venue/selectors';
import { useDetectOutsideClick } from '../../utils/CustomHooks';

export const KEYS = {
  sound: 'sound',
  bigScreen: 'bigScreen',
  active: 'active',
};

const FixtureRow = ({ fixture, updateFixture, isMobile, defaultPrefs }) => {
  const [addFeaturesOpen, setAddFeaturesOpen] = useState(false);
  const [showChannels, setShowChannels] = useState(false);
  const channelsRef = useRef();
  useDetectOutsideClick(channelsRef, () => setShowChannels(false));

  const oneTeamEvent = fixture?.teams?.length < 1;
  const isFixPopular = fixture?.isPopular;
  const isFixtureSelected = fixture?.venueEvent?.active;

  const styles = {
    row:
      'bg-white p-4 border-2 w-full my-1 rounded-lg rounded-br-2xl flex relative md:mx-6 md:my-3 md:w-auto overflow-ellipsis xl:mx-10 xl:px-6',
    compText:
      'line-clamp-2 overflow-hidden whitespace-nowrap md:whitespace-normal text-xs lg:text-sm',
    button:
      'py-2 md:p-0 text-base font-semibold flex justify-center items-center md:ml-4 w-12 md:w-40',
    extraChannels:
      'absolute transition-opacity duration-250 ease-in-out top-7 z-10 p-2 bg-mpGrey shadow-lg rounded-md w-52 -translate-x-16',
  };

  const changeMatchFeatures = feature => {
    fireEvent(GACategories.FIXTURES, GATags.UPDATE_FIXTURE_FEATURE, { feature });
    updateFixture({ eventId: fixture.id, feature });
  };

  const teamComp = (text, image, index = 0) => (
    <div key={`${text}${index}`} className="font-semibold flex items-center">
      <img className="w-6 h-6 p-1 lg:w-8 lg:h-8" src={image} alt={text} />
      <p className="pl-2 text-xs line-clamp-2 md:text-sm md:line-clamp-2 lg:text-base">{text}</p>
    </div>
  );

  const renderSportsChannels = (
    <div className="flex md:w-auto">
      <div className="md:flex pr-1 mr-1 border-r border-mpGrey min-w-0">
        <p className="block opacity-60 truncate">{fixture.channels[0].name}</p>
        {fixture.channels.length > 1 && (
          <PlainButton
            className="pl-1 flex-shrink-0 uppercase font-medium underline relative"
            onClick={() => setShowChannels(!showChannels)}
          >
            <p className="opacity-60">
              {i18next.t('fixtures_channels_more', { channels: fixture.channels.length - 1 })}
            </p>
            <div
              className={`${styles.extraChannels} ${showChannels ? 'opacity-100' : 'opacity-0'}`}
              ref={channelsRef}
            >
              <div className="absolute -top-2 left-1/2 -translate-x-1/2 border-b-mpGrey border-b-8 border-x-transparent border-x-8" />
              {fixture.channels.map(c => c.name).join(', ')}
            </div>
          </PlainButton>
        )}
      </div>
      <p>{getLocaleDate(fixture.startTimeUtc, 'HH:mm')}</p>
    </div>
  );

  const toggleFeature = (text, checked, onChange) => (
    <div className="flex justify-between items-center">
      <p className="font-bold text-xs uppercase mr-2 lg:text-sm">{i18next.t(text)}</p>
      <Toggle onChange={onChange} checked={checked} />
    </div>
  );

  const popularFixComp = isFixPopular && (
    <div className="border-r border-mpGrey my-4 px-4 flex flex-col justify-center text-xs lowercase flex-wrap space-y-1 md:m-0 md:flex-1 md:h-full lg:text-sm whitespace-normal">
      <TrendingIcon className="w-7 md:w-10 fill-green" />
      <p className="flex flex-wrap">
        <span className="text-green font-bold">
          {truncateNumber(fixture?.nbVenues, 5)}
          &nbsp;
        </span>
        {i18next.t('fixtures.venues_showing')}
      </p>
    </div>
  );

  const toggleFeatures = (
    <div className="flex flex-col justify-center m-3 mr-8 space-y-4 md:px-4 md:m-0 md:p-2.5 md:border-r border-mpGrey md:h-full md:flex-1 2xl:flex-row 2xl:justify-center 2xl:items-center 2xl:space-x-6 2xl:space-y-0">
      {toggleFeature(
        'feature.sound',
        fixture.venueEvent ? fixture?.venueEvent?.sound : defaultPrefs.sound,
        e => changeMatchFeatures(KEYS.sound, e),
      )}
      {toggleFeature(
        'feature.bigScreen',
        fixture.venueEvent ? fixture?.venueEvent?.bigScreen : defaultPrefs.bigScreen,
        e => changeMatchFeatures(KEYS.bigScreen, e),
      )}
    </div>
  );

  const renderFeatureButtons = () => {
    if (isMobile) {
      return !addFeaturesOpen ? (
        <PlainButton
          className="border-2 border-mpGrey rounded-full"
          onClick={() => {
            fireEvent(GACategories.FIXTURES, GATags.SHOW_FIXTURE_FEATURES);
            setAddFeaturesOpen(!addFeaturesOpen);
          }}
        >
          <DotsIcon className="h-10 w-10" />
        </PlainButton>
      ) : (
        <div
          className={`
            ${
              isFixtureSelected ? 'border-primary' : 'border-mpGrey'
            } flex border-2 -m-0.5 rounded-2xl rounded-tr-lg bg-white absolute right-0 top-0 bottom-0`}
        >
          {popularFixComp}
          {toggleFeatures}
          <PlainButton className="mr-4" onClick={() => setAddFeaturesOpen(!addFeaturesOpen)}>
            <CloseIcon className="w-8 h-8" />
          </PlainButton>
        </div>
      );
    }
    return toggleFeatures;
  };

  const renderAddFixtureButton = (
    <PlainButton
      onClick={e => {
        fireEvent(GACategories.FIXTURES, GATags.UPDATE_FIXTURE);
        changeMatchFeatures(KEYS.active, e);
      }}
      disabled={fixture.isUpdating}
    >
      {isFixtureSelected ? (
        <div className={`${styles.button} md:flex-col md:space-y-1`}>
          <TickIcon className="w-9 h-9 md:w-7 md:h-7 text-primary" />
          {!isMobile && <p className="px-4">{i18next.t(textStates.added)}</p>}
        </div>
      ) : (
        <div className={`${styles.button} bg-mpGrey rounded-full md:px-4 md:py-3 md:space-x-4`}>
          {!isMobile && <p>{i18next.t(textStates.add)}</p>}
          <PlusIcon className="h-8 w-8 md:w-6 md:h-6" />
        </div>
      )}
    </PlainButton>
  );

  return (
    <div
      className={`${styles.row} ${
        isFixtureSelected ? 'border-primary bg-secondaryLight' : 'border-mpGrey'
      }`}
    >
      <div className="w-1/3 border-r border-mpGrey pr-2 space-y-2 flex flex-col justify-center md:w-1/4">
        {oneTeamEvent
          ? teamComp(fixture?.name, fixture?.competition?.competitionLogo)
          : fixture?.teams?.map((t, i) => teamComp(t?.name, t?.image, i))}
      </div>
      <div className="w-2/3 px-2 uppercase text-xs font-medium flex flex-row justify-between items-center md:px-0 md:pl-4 md:w-full">
        <div className="w-1/2 whitespace-pre space-y-0.5 md:space-y-2 flex flex-col justify-center text-xs md:w-1/3 md:border-r border-mpGrey pr-2 md:pr-4 md:flex-1 lg:flex-auto xl:flex-1 md:h-full lg:text-sm">
          {isMobile && isFixPopular && <TrendingIcon className="w-7 md:w-10 fill-green" />}
          <p className={`${styles.compText} line-clamp-1`}>{fixture?.sport?.name}</p>
          <p className={styles.compText}>
            {fixture?.competitionOverride || fixture?.competition?.name}
          </p>
          {renderSportsChannels}
        </div>
        {!isMobile && popularFixComp}
        <div className="flex space-x-2 items-center md:space-x-0 h-full">
          {renderFeatureButtons()}
          {renderAddFixtureButton}
        </div>
      </div>
    </div>
  );
};

FixtureRow.propTypes = {
  fixture: fixtureProps.isRequired,
  updateFixture: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
  defaultPrefs: PropTypes.shape({
    sound: PropTypes.bool.isRequired,
    bigScreen: PropTypes.bool.isRequired,
  }).isRequired,
};

const mapStateToProps = state => ({
  isMobile: getIsMobile(state),
  defaultPrefs: getVenueFixturePreferences(state),
});

export default connect(mapStateToProps)(FixtureRow);

import * as consts from './constants';

export const initialState = {
  isFetchingLogin: false,
  isLoggedIn: false,
  user: null,
  error: null,
};

function authReducer(state = initialState, action) {
  switch (action.type) {
    case consts.FETCH_AUTH_SUCCESS:
      return {
        ...state,
        isFetchingLogin: false,
        isLoggedIn: true,
        user: action.payload,
      };
    case consts.FETCH_LOGIN_AUTH_PENDING:
      return {
        ...state,
        isFetchingLogin: true,
      };
    case consts.AUTH_LOGOUT:
      return {
        ...state,
        isLoggedIn: false,
      };
    case consts.FETCH_LOGIN_AUTH_SUCCESS:
      return {
        ...state,
        isFetchingLogin: false,
        isLoggedIn: true,
        user: action.payload,
      };
    case consts.FETCH_LOGIN_AUTH_ERROR:
      return {
        ...state,
        isFetchingLogin: false,
      };
    case consts.UPDATEPASSWORD_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
}

export default authReducer;

import PropTypes from 'prop-types';
import React from 'react';
import { t } from 'i18next';
import MPModal from '../../components/MPModal/MPModal';
import CloseIcon from '../../components/Icons/CloseIcon';
import PlainButton from '../../components/PlainButton/PlainButton';

const styles = {
  title: 'headingFont text-5xl pt-2 pb-3',
  subtitle: 'pt-4 pb-10 text-darkGrey md:text-xl',
  body: 'pb-4 text-darkGrey md:text-xl',
  btn: 'w-full p-4 font-semibold rounded-lg',
};

const ExistingAppUserModal = ({ open, onClose }) => (
  <MPModal open={open} onClose={onClose} className="max-w-sm md:max-w-md rounded-2xl">
    <div className="relative px-8 md:px-16 pb-8 md:pb-16 pt-16">
      <PlainButton onClick={onClose} className="absolute top-4 right-4">
        <CloseIcon className="w-12 h-12 p-2 bg-mpGrey rounded-full" />
      </PlainButton>
      <h1 className={styles.title}>{t('signup.appUser.title')}</h1>
      <p className={styles.body}>{t('signup.appUser.text1')}</p>
      <p className={styles.body}>{t('signup.appUser.text2')}</p>
      <p className="opacity-90 md:text-xl font-semibold pt-4">{t('signup.appUser.text3')}</p>
      <p className={`${styles.body} whitespace-pre-line`}>{t('signup.appUser.text4')}</p>
      <PlainButton
        className={`${styles.btn} bg-secondary text-secondaryButtonText`}
        onClick={onClose}
        testId="closeExistingAppUserModal"
      >
        {t('signup.appUser.button1')}
      </PlainButton>
    </div>
  </MPModal>
);

ExistingAppUserModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ExistingAppUserModal;

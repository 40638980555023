import i18n from '../../i18n';
import { cdnUrl } from '../../utils/constants';

const cdn = `${cdnUrl}/cal-sync`;

export const icons = {
  gCal: `${cdn}/google_icon.png`,
  iCal: `${cdn}/ical_icon.png`,
  outlook: `${cdn}/outlook_icon.png`,
};

const assets = () => {
  if (i18n.resolvedLanguage === 'de') {
    return {
      google1: `${cdn}/de/google_cal_1.jpg`,
      google2: `${cdn}/de/google_cal_2.jpg`,
      google3: `${cdn}/de/google_cal_3.jpg`,
      ical1: `${cdn}/de/ical_1.jpg`,
      ical2: `${cdn}/de/ical_2.jpg`,
      ical3: `${cdn}/de/ical_3.jpg`,
      ical4: `${cdn}/de/ical_4.jpg`,
      outlook1: `${cdn}/de/outlook1.jpg`,
      outlook2: `${cdn}/de/outlook2.jpg`,
      outlook3: `${cdn}/de/outlook3.jpg`,
    };
  }
  if (i18n.resolvedLanguage === 'fr') {
    return {
      google1: `${cdn}/fr/google_cal_1.jpg`,
      google2: `${cdn}/fr/google_cal_2.jpg`,
      google3: `${cdn}/fr/google_cal_3.jpg`,
      ical1: `${cdn}/fr/ical_1.jpg`,
      ical2: `${cdn}/fr/ical_2.jpg`,
      ical3: `${cdn}/fr/ical_3.jpg`,
      ical4: `${cdn}/fr/ical_4.jpg`,
      outlook1: `${cdn}/fr/outlook1.jpg`,
      outlook2: `${cdn}/fr/outlook2.jpg`,
      outlook3: `${cdn}/fr/outlook3.jpg`,
    };
  }
  return {
    google1: `${cdn}/en/google_cal_1.jpg`,
    google2: `${cdn}/en/google_cal_2.jpg`,
    google3: `${cdn}/en/google_cal_3.jpg`,
    ical1: `${cdn}/en/ical_1.jpg`,
    ical2: `${cdn}/en/ical_2.jpg`,
    ical3: `${cdn}/en/ical_3.jpg`,
    ical4: `${cdn}/en/ical_4.jpg`,
    outlook1: `${cdn}/en/outlook1.jpg`,
    outlook2: `${cdn}/en/outlook2.jpg`,
    outlook3: `${cdn}/en/outlook3.jpg`,
  };
};

export default assets();

import React from 'react';
import PropTypes from 'prop-types';
import i18n from '../../i18n';
import './styles.scss';

const TwoPartContent = ({ data, image, dynamicCoverImage }) => {
  const [showMore, setShowMore] = React.useState(false);

  const getTitle = (text, index) => {
    if (index === 1) {
      return <h1 className="TwoPartContent_title headingFont text-8xl">{text}</h1>;
    }
    if (index === 2) {
      return <h2 className="TwoPartContent_title headingFont text-5xl">{text}</h2>;
    }
    return <h3 className="TwoPartContent_title headingFont text-3xl">{text}</h3>;
  };

  const section = (content, index) => (
    <div className="w-5/6 m-auto mb-6" key={content?.title}>
      {getTitle(content?.title, index)}
      {/* eslint-disable-next-line react/no-danger */}
      <div className="text-xl" dangerouslySetInnerHTML={{ __html: content.text }} />
    </div>
  );

  if (!data || !data[0]) {
    return null;
  }

  return (
    <div className="TwoPartContent">
      <div className="flex-1 py-20">
        {section(data[0], 1)}
        {data.length > 1 && showMore && data.slice(1).map((d, i) => section(d, i + 2))}
        {data.length > 1 && (
          <>
            <div className="border-t border-white mt-16" />
            <div className="w-5/6 m-auto relative">
              <div className="absolute -top-7">
                <button
                  className="border-white border h-14 w-64 rounded-lg bg-black focus:outline-none"
                  type="button"
                  onClick={() => setShowMore(!showMore)}
                >
                  {!showMore ? i18n.t('general.seeMore') : i18n.t('general.seeLess')}
                </button>
              </div>
            </div>
          </>
        )}
      </div>
      {image && (
        <div
          style={{ backgroundImage: `url(${image})` }}
          className="flex-1 bg-cover h-96 lg:h-auto justify-end flex p-10"
        >
          {dynamicCoverImage && (
            <img alt="" src={dynamicCoverImage} className="h-80 sticky top-32 hidden lg:flex" />
          )}
        </div>
      )}
    </div>
  );
};

TwoPartContent.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  image: PropTypes.string,
  dynamicCoverImage: PropTypes.string,
};

TwoPartContent.defaultProps = {
  image: null,
  dynamicCoverImage: null,
};

export default TwoPartContent;

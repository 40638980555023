import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import './styles.scss';

const MPModal = ({ onClose, open, children, className }) => {
  // Background shouldn't be scrollable
  useEffect(() => {
    if (open) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [open]);

  return (
    <Modal
      isOpen={open}
      onRequestClose={onClose}
      className={`MPModal ${className}`}
      appElement={document.getElementById('root')}
    >
      {children}
    </Modal>
  );
};

MPModal.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};
MPModal.defaultProps = {
  className: '',
  onClose: () => {},
};

export default MPModal;

Modal.defaultStyles.overlay.backgroundColor = 'rgba(0, 0, 0, 0.5)';
Modal.defaultStyles.overlay.zIndex = 1300;

import React, { useRef, useState, useEffect } from 'react';
import './styles.scss';
import PropTypes from 'prop-types';
import { SketchPicker } from 'react-color';
import PlainButton from '../../../../components/PlainButton/PlainButton';

const ColorPicker = ({ color, onChange, alphaEnabled }) => {
  const node = useRef();
  const [displayColorPicker, setDisplayColorPicker] = useState(false);

  const handleOutsideClick = e => {
    if (!node.current.contains(e.target)) {
      setDisplayColorPicker(false);
    }
  };

  useEffect(() => {
    if (displayColorPicker) {
      document.addEventListener('mousedown', handleOutsideClick);
    }
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [displayColorPicker]);

  return (
    <div ref={node}>
      <PlainButton onClick={() => setDisplayColorPicker(!displayColorPicker)}>
        <div style={{ backgroundColor: color }} className="ColorPicker_tile" />
      </PlainButton>
      {displayColorPicker && (
        <div className="ColorPicker_popover">
          <SketchPicker disableAlpha={!alphaEnabled} color={color} onChange={c => onChange(c)} />
        </div>
      )}
    </div>
  );
};

ColorPicker.defaultProps = {
  alphaEnabled: false,
  color: null,
};

ColorPicker.propTypes = {
  color: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  alphaEnabled: PropTypes.bool,
};

export default ColorPicker;

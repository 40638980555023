import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import styles from './Checkbox.module.scss';

const SimpleCheckbox = ({ id, className, onChange, checked, indeterminate, variant }) => {
  const checkRef = useRef();

  useEffect(() => {
    if (checkRef) {
      checkRef.current.indeterminate = indeterminate;
    }
  }, [indeterminate, checkRef]);

  return (
    <input
      id={id}
      ref={checkRef}
      checked={checked}
      onChange={onChange}
      readOnly={!onChange}
      type="checkbox"
      className={`${
        variant === 'secondary' ? styles.input_secondary : styles.input_primary
      } ${className}`}
    />
  );
};

SimpleCheckbox.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  onChange: PropTypes.func,
  checked: PropTypes.bool.isRequired,
  indeterminate: PropTypes.bool,
  variant: PropTypes.oneOf(['primary', 'secondary']),
};
SimpleCheckbox.defaultProps = {
  id: null,
  indeterminate: false,
  className: '',
  onChange: null,
  variant: 'primary',
};

export default SimpleCheckbox;

import React from 'react';
import PropTypes from 'prop-types';
import i18n from 'i18next';
import { publicRoutes } from '../../routes/paths';

const TermsAndConditionsSnippet = ({ buttonText }) => (
  <>
    <p className="mt-4 mb-6 text-base text-standardGrey">
      {i18n.t('auth.signup.form.agree', { buttonText })}{' '}
      <a className="underline" href={publicRoutes.termsAndConditions}>
        {i18n.t('auth.signup.form.terms')}
      </a>
      {', '}
      <a className="underline" href={publicRoutes.termsOfService}>
        {i18n.t('auth.signup.form.serviceAgreement')}
      </a>{' '}
      {i18n.t('general.and').toLowerCase()}{' '}
      <a className="underline" href={publicRoutes.privacyPolicy}>
        {i18n.t('auth.signup.form.privacy')}
      </a>
    </p>
  </>
);

TermsAndConditionsSnippet.propTypes = {
  buttonText: PropTypes.string,
};

TermsAndConditionsSnippet.defaultProps = {
  buttonText: i18n.t('general.continue'),
};

export default TermsAndConditionsSnippet;

import React from 'react';
import { t } from 'i18next';
import { Link } from 'react-router-dom';
import ProfileHeader from './ProfileHeader';
import AddFavourites from '../../components/AddFavourites/AddFavourites';
import SportsFavourites from '../../components/AddFavourites/SportsFavourites';
import InfoIcon from '../../components/Icons/InfoIcon';
import routes from '../../routes/paths';
import { userIsOnOnTap } from '../../utils/environment';

const styles = {
  button: 'border-2 rounded-full px-4 py-1.5 sm:px-6 sm:py-2.5',
  disabled: 'border-mpGrey bg-white',
  text: 'text-xs sm:text-base opacity-80 font-semibold',
};

const FavouritesPage = () => (
  <>
    <ProfileHeader text={t('signup_favourites_title')} />
    <div className="px-4 max-w-lg mx-auto lg:max-w-xl">
      <h3 className="opacity-60 pt-4 md:pt-12 lg:pt-16">{t('editProfile_favouritesSubtitle')}</h3>

      <p className="font-bold pb-2 pt-6">{t('editProfile_favouritesSports')}</p>
      <SportsFavourites />

      <p className="font-bold pb-2 pt-6">{t('editProfile_favouritesTeamsAndComps')}</p>
      <AddFavourites />

      {!userIsOnOnTap && (
        <div className="bg-[#DBE6F5] text-[#2E6AC6] rounded-lg text-sm my-4 md:my-8 p-4">
          <div className="flex items-center gap-1">
            <InfoIcon className="h-6 w-6" />
            <p className="font-bold text-base">{t('editProfile_favouritesHelp1')}</p>
          </div>
          <p className="pt-2">{t('editProfile_favouritesHelp2')}</p>
          <p className="py-4">
            {t('editProfile_favouritesHelp3')}{' '}
            <Link to={routes.fixtures.path} className="underline font-bold">
              {t('editProfile_favouritesHelpLink')}.
            </Link>
          </p>

          <div className="flex gap-2 justify-center items-center bg-white rounded-lg text-black p-4">
            <div className={`${styles.button} ${styles.disabled}`}>
              <p className={styles.text}>{t('fixtures_allFixtures')}</p>
            </div>
            <div className={`${styles.button} ${styles.disabled}`}>
              <p className={styles.text}>{t('fixtures_popularFixtures')}</p>
            </div>
            <div className={`${styles.button} border-secondary bg-secondaryLight`}>
              <p className={styles.text}>{t('fixtures_myFavourites')}</p>
            </div>
          </div>
        </div>
      )}
    </div>
  </>
);

export default FavouritesPage;

import React from 'react';
import PropTypes from 'prop-types';

const EditIcon = ({ className }) => (
  <svg className={`fill-current ${className}`} viewBox="0 0 24 24">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.82284 6.51571C4.89718 6.07 5.32376 5.70868 5.77563 5.70868H17.0216L16.7524 7.32274H6.32458L4.5297 18.0832H15.4388L16.7955 9.94955H18.4319L16.9405 18.8902C16.8662 19.3359 16.4396 19.6972 15.9877 19.6972H3.44228C2.99041 19.6972 2.68437 19.3359 2.75872 18.8902L4.82284 6.51571Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.8055 4.93428C18.9218 3.98879 20.4759 3.98878 21.2768 4.93428C22.0776 5.87978 21.8219 7.41273 20.7057 8.35823L11.8738 15.8389L7.82863 16.325L8.97368 12.415L17.8055 4.93428ZM19.9293 6.0756C19.6624 5.76043 19.1443 5.76043 18.7722 6.0756L10.4032 13.1643L10.0215 14.4676L11.3699 14.3056L19.739 7.21691C20.111 6.90175 20.1963 6.39076 19.9293 6.0756Z"
    />
  </svg>
);

EditIcon.propTypes = {
  className: PropTypes.string,
};

EditIcon.defaultProps = {
  className: '',
};

export default EditIcon;

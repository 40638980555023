import React from 'react';
import PropTypes from 'prop-types';
import SearchIcon from '../Icons/SearchIcon';
import CloseIcon from '../Icons/CloseIcon';
import Button from '../Button/Button';
import i18n from '../../i18n';

const SearchBar = ({ value, onChange, id, placeholder, onClear }) => (
  <div className="flex justify-between bg-white items-center flex-1 shadow rounded-lg overflow-hidden md:max-w-xs">
    <SearchIcon className="w-6 h-6 ml-2" />
    <input
      id={id}
      value={value}
      placeholder={placeholder}
      className="flex-1 outline-none p-3 text-xs"
      onChange={e => onChange(e.target.value)}
    />
    {value?.length > 0 && (
      <Button onClick={onClear}>
        <CloseIcon className="w-5 h-5" />
      </Button>
    )}
  </div>
);

SearchBar.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  id: PropTypes.string,
  placeholder: PropTypes.string,
  onClear: PropTypes.func.isRequired,
};
SearchBar.defaultProps = {
  id: null,
  placeholder: i18n.t('general.search'),
};

export default SearchBar;

import React from 'react';
import PropTypes from 'prop-types';
import i18n from '../../../i18n';
import { isFutureDate } from '../../../utils/general';

const s = {
  button: (isActive, isFuture) =>
    `flex w-full mb-2 py-3 px-5 lg:px-2 xl:px-5 justify-between shadow rounded-md border ${
      isActive ? 'border-primary' : 'border-transparent'
    } ${!isFuture ? 'bg-lightGrey opacity-30' : ''}`,
  text: isHidden => `font-semibold underline capitalize ${isHidden ? 'invisible' : ''}`,
};

const RoundPrizeButton = ({ round, activeRound, onClick }) => {
  const roundPrize = round?.roundPrize?.prize;
  const isActive = activeRound?.id === round?.id;
  const isFuture = isFutureDate(round?.endTimeUtc);
  return (
    <button
      key={round?.id}
      className={s.button(isActive, isFuture)}
      onClick={onClick}
      type="button"
    >
      <p className="font-semibold truncate">{round?.name}</p>
      <p className="opacity-60 truncate">{roundPrize?.name}</p>
      <p className={s.text(isActive || !isFuture)}>
        {i18n.t(roundPrize ? 'general.edit' : 'general.add')}
      </p>
    </button>
  );
};

RoundPrizeButton.propTypes = {
  round: PropTypes.shape().isRequired,
  activeRound: PropTypes.shape(),
  onClick: PropTypes.func.isRequired,
};

RoundPrizeButton.defaultProps = {
  activeRound: null,
};

export default RoundPrizeButton;

import React, { useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { t } from 'i18next';
import queryString from 'query-string';
import { venueProps, historyProps } from '../../utils/customPropTypes';
import { getFirstName } from '../../store/auth/selectors';
import { getVenue } from '../../store/venue/selectors';
import routes from '../../routes/paths';
import { refreshVenueThunk, subscribeFinaliseThunk } from '../../store/venue/thunks';
import AddPubInfoModal from './AddPubInfo';
import PublishProfileToast from './PublishProfile';
import WelcomeModal from './WelcomeModal';
import PublishProfileModal from './PublishProfileModal';
import { navigate } from '../../store/navigation/thunks';
import ProfileTile from './ProfileTile';
import FixturesAndActionsTile from './FixturesAndActionsTile';
import StatsTile from './StatsTile';

const DashboardPageV2 = ({ name, venue, history, subscribeFinalise, refreshVenue }) => {
  const params = queryString.parse(history.location.search);
  const venueId = venue?.id;

  const refreshDashboard = useCallback(() => {}, []);

  const subscribeVenue = useCallback(
    () =>
      subscribeFinalise(venueId, params.subKey)
        .then(() => navigate(routes.signupV2Stage3.path))
        .catch(() => refreshDashboard(venueId)),
    [params.subKey, refreshDashboard, subscribeFinalise, venueId],
  );

  const fetchVenue = useCallback(
    () => refreshVenue(venueId).then(() => refreshDashboard(venueId)),
    [refreshDashboard, refreshVenue, venueId],
  );

  useEffect(() => {
    if (venueId) {
      if (!params.subKey && !params.published) {
        refreshDashboard(venueId);
      }
      if (params.subKey) {
        subscribeVenue();
      }
      if (params.published) {
        fetchVenue();
      }
    }
  }, [params.published, params.subKey, venueId, fetchVenue, refreshDashboard, subscribeVenue]);

  if (!venue) {
    return <Redirect to={routes.signupV2Stage2.path} />;
  }

  const stats = <StatsTile />;
  const fixtures = <FixturesAndActionsTile />;
  return (
    <>
      <PublishProfileToast venue={venue} isPublished={Boolean(params.published)} />
      <div className="pt-8 lg:pt-14 sm:mx-6 max-w-7xl lg:max-h-screen flex flex-col">
        <h1 className="headingFont text-4xl md:text-5xl pl-4 md:pl-0 pb-4">
          {t('home.welcome', { name })}
        </h1>
        <div className="hidden lg:flex flex-col lg:flex-row gap-4 overflow-hidden mb-4 flex-1">
          {fixtures}
          <div className="flex flex-col gap-y-4 lg:w-1/2">
            <ProfileTile />
            {stats}
          </div>
        </div>
        <div className="lg:hidden flex flex-col gap-4">
          <ProfileTile />
          {fixtures}
          {stats}
        </div>
      </div>
      <AddPubInfoModal />
      <WelcomeModal name={name} venue={venue} />
      <PublishProfileModal open={Boolean(params.previewSeen)} />
    </>
  );
};

const mapStateToProps = state => ({
  name: getFirstName(state),
  venue: getVenue(state),
});

const mapDispatchToProps = {
  subscribeFinalise: subscribeFinaliseThunk,
  refreshVenue: refreshVenueThunk,
};

DashboardPageV2.propTypes = {
  name: PropTypes.string.isRequired,
  venue: venueProps,
  history: historyProps.isRequired,
  subscribeFinalise: PropTypes.func.isRequired,
  refreshVenue: PropTypes.func.isRequired,
};

DashboardPageV2.defaultProps = {
  venue: null,
};

export default connect(mapStateToProps, mapDispatchToProps)(DashboardPageV2);

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { t } from 'i18next';
import { Link } from 'react-router-dom';
import { getVenue } from '../../store/venue/selectors';
import { getUser } from '../../store/auth/selectors';
import { userProps, venueProps } from '../../utils/customPropTypes';
import { userIsOnOnTap } from '../../utils/environment';
import { setShowMobileSidebar, setShowSwitchProfiles } from '../../store/app/actions';
import routes from '../../routes/paths';
import PlainButton from '../../components/PlainButton/PlainButton';
import SpinnerIcon from '../../components/Icons/SpinnerIcon';
import ChevronIcon from '../../components/Icons/ChevronIcon';
import CogIcon from '../../components/Icons/CogIcon';
import CardIcon from '../../components/Icons/CardIcon';
import ReimburseIcon from '../../components/Icons/ReimburseIcon';
import { canAccessBilling } from '../../utils/general';

const AccountPage = ({ title, venue, member, onSetSwitchProfiles, onSetMobileSidebar }) => {
  const showBillingPage = canAccessBilling(venue);
  const links = [
    {
      path: routes.account.subRoutes.settings.path,
      title: routes.account.subRoutes.settings.title,
      subtitle: 'titles_settingsSubtitle',
      Icon: CogIcon,
    },
    showBillingPage && {
      path: routes.account.subRoutes.billing.path,
      title: routes.account.subRoutes.billing.title,
      subtitle: 'titles_billingSubtitle',
      Icon: CardIcon,
    },
    !userIsOnOnTap && {
      path: routes.account.subRoutes.reimbursement.path,
      title: routes.account.subRoutes.reimbursement.title,
      subtitle: 'titles_reimbursementSubtitle',
      Icon: ReimburseIcon,
    },
  ].filter(l => l);

  return (
    <div className="px-4 md:px-9 xl:px-16 xl:container">
      <div className="flex pt-8 md:pt-12 xl:pt-16 md:pb-8 xl:pb-10 pb-6 items-center">
        <h1 className="font-bold text-4xl md:text-5xl pr-4 xl:pr-10 headingFont">{title}</h1>
        {venue && member?.venues?.length > 1 && (
          <PlainButton
            className="flex items-center py-2 md:py-2.5 px-4 border border-mpGrey rounded-full opacity-60 gap-2"
            onClick={() => {
              onSetSwitchProfiles(true);
              onSetMobileSidebar(true);
            }}
          >
            <p className="text-xs font-semibold xl:text-base">{venue.name}</p>
            <SpinnerIcon className="h-4 w-4 md:h-6 md:w-6" />
          </PlainButton>
        )}
      </div>

      <div className="flex flex-col gap-2 md:gap-4 xl:gap-8 md:grid md:grid-cols-2 xl:grid-cols-3">
        {links.map(link => (
          <AccountLink link={link} key={link.path} />
        ))}
      </div>
    </div>
  );
};

AccountPage.propTypes = {
  title: PropTypes.string.isRequired,
  venue: venueProps,
  member: userProps.isRequired,
  onSetSwitchProfiles: PropTypes.func.isRequired,
  onSetMobileSidebar: PropTypes.func.isRequired,
};

AccountPage.defaultProps = {
  venue: null,
};

const mapStateToProps = state => ({
  venue: getVenue(state),
  member: getUser(state),
});

const mapDispatchToProps = {
  onSetSwitchProfiles: setShowSwitchProfiles,
  onSetMobileSidebar: setShowMobileSidebar,
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountPage);

const AccountLink = ({ link: { title, subtitle, path, Icon } }) => (
  <Link
    className="flex md:block items-center gap-2 p-4 border border-mpGrey bg-white rounded-lg w-full"
    to={path}
  >
    <Icon className="h-6 w-6 md:h-8 md:w-8" />
    <p className="flex-1 text-left font-semibold md:font-bold md:pt-4">{t(title)}</p>
    <ChevronIcon className="h-6 w-6 md:hidden" />
    <p className="text-sm opacity-60 pt-2 hidden md:block">{t(subtitle)}</p>
  </Link>
);

AccountLink.propTypes = {
  link: PropTypes.shape({
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired,
    Icon: PropTypes.func.isRequired,
  }).isRequired,
};

import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';
import PlainButton from '../PlainButton/PlainButton';
import ChevronIcon from '../Icons/ChevronIcon';

const PageTab = ({ show, onClick, badgeContent, theme }) => (
  <PlainButton onClick={onClick}>
    <div className={`PageTab_${theme}${show ? '_inactive' : ''}`}>
      <div className={`PageTab_${theme}_slant`} />
      <div className={`PageTab_${theme}_buttonContainer`}>
        {badgeContent && <div className="PageTab_badge">{badgeContent}</div>}
        <ChevronIcon className={`w-6 h-6 ${theme === 'white' ? 'rotate-180' : 'text-white'}`} />
      </div>

      <div className={`PageTab_${theme}_slant_bottom`} />
    </div>
  </PlainButton>
);

PageTab.propTypes = {
  show: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  badgeContent: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  theme: PropTypes.oneOf(['white', 'black']).isRequired,
};

PageTab.defaultProps = {
  badgeContent: '',
};

export default PageTab;

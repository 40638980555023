import '../styles.scss';
import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { drawOnCanvas, calculateHorizontalCenter, renderImageWithinConstraints } from '../shared';
import { createImageObject } from '../../../../utils/general';
import { SMConstraintProps } from '../../../../utils/customPropTypes';

const SingleFixtureTemplate = ({
  text,
  logo,
  color,
  textColor,
  logoScale,
  templateHeight,
  templateWidth,
  template,
  logoConstraints,
  isLandscape,
  loadedImage,
  font,
}) => {
  const canvas = useRef(null);

  useEffect(() => {
    drawOnCanvas(canvas, templateWidth, templateHeight, color, (cc, ctx) => {
      renderImageWithinConstraints(
        logo,
        logoConstraints,
        ctx,
        cc.height,
        cc.width,
        false,
        logoScale,
      );
      ctx.drawImage(createImageObject(template), 0, 0, templateWidth, templateHeight);
      // dont add text to portrait template
      if (isLandscape) {
        const fontSize = 40;
        ctx.font = `${fontSize}px ${font}`;
        ctx.fillStyle = textColor;
        ctx.fillText(
          text.toUpperCase(),
          calculateHorizontalCenter(text.toUpperCase(), 0, cc.width, ctx),
          100,
        );
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    text,
    logo,
    logoScale,
    color,
    textColor,
    templateHeight,
    templateWidth,
    template,
    font,
    loadedImage,
  ]);

  return (
    <canvas
      className="ImageGenerator_canvas"
      ref={canvas}
      width={templateWidth}
      height={templateHeight}
    />
  );
};

SingleFixtureTemplate.propTypes = {
  templateHeight: PropTypes.number.isRequired,
  templateWidth: PropTypes.number.isRequired,
  text: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  logo: PropTypes.string,
  textColor: PropTypes.string.isRequired,
  logoScale: PropTypes.number.isRequired,
  template: PropTypes.string,
  isLandscape: PropTypes.bool,
  logoConstraints: SMConstraintProps,
  font: PropTypes.string,
  loadedImage: PropTypes.bool,
};

SingleFixtureTemplate.defaultProps = {
  logo: null,
  template: null,
  isLandscape: false,
  logoConstraints: null,
  font: null,
  loadedImage: false,
};

export default SingleFixtureTemplate;

import React from 'react';
import PropTypes from 'prop-types';
import StatsFixtureRow from './StatsFixtureRow';

const StatsFixtureList = ({ fixtures }) => {
  return (
    <div className="fixtureList_table ">
      {fixtures.map((fixture, i) => (
        <StatsFixtureRow
          key={fixture.fixture.id}
          noBorder={i === 0}
          fixture={fixture.fixture}
          searches={fixture.value}
        />
      ))}
    </div>
  );
};

StatsFixtureList.propTypes = {
  fixtures: PropTypes.arrayOf(PropTypes.shape().isRequired).isRequired,
};

export default StatsFixtureList;

import React from 'react';
import PropTypes from 'prop-types';
import './buttonStyles.scss';

const getColor = (active, disabled) => {
  if (active) {
    return 'bg-secondary';
  }
  if (disabled) {
    return 'opacity-40';
  }
  return 'bg-eggShell';
};

const PostPublishTimingButton = ({ disabled, active, onClick, text }) => (
  <button
    className={`PostButton text-sm border border-primary ${getColor(active, disabled)}`}
    type="button"
    disabled={disabled}
    onClick={onClick}
  >
    <p>{text}</p>
  </button>
);

PostPublishTimingButton.propTypes = {
  disabled: PropTypes.bool,
  active: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
};

PostPublishTimingButton.defaultProps = {
  disabled: false,
  active: true,
};
export default PostPublishTimingButton;

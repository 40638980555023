import React from 'react';

const PhotoIcon = () => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.35241 6.9231H28.8979L26.0536 24.8811H3.50815L6.35241 6.9231ZM8.19292 9.07805L6.03128 22.7261H24.2131L26.3747 9.07805H8.19292Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.7964 16.3806C22.0777 15.4912 20.6403 15.4912 19.6399 16.3806L14.0702 21.3321L13.1925 20.2459C12.4936 19.381 11.1091 19.3536 10.1072 20.1847L6.18104 23.442L5.33347 22.4093L9.25968 19.152C10.9285 17.7675 13.2344 17.8124 14.3998 19.2515L18.7449 15.3886C20.4121 13.9064 22.8079 13.9064 24.0056 15.3886L26.1481 18.0399L24.9388 19.0319L22.7964 16.3806Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.7947 11.5922C10.9914 11.5922 10.2383 12.2354 10.1126 13.0288C9.98697 13.8222 10.5363 14.4654 11.3396 14.4654C12.143 14.4654 12.8961 13.8222 13.0217 13.0288C13.1474 12.2354 12.598 11.5922 11.7947 11.5922ZM8.65809 13.0288C8.90942 11.4419 10.4156 10.1555 12.0223 10.1555C13.6289 10.1555 14.7276 11.4419 14.4763 13.0288C14.2249 14.6157 12.7187 15.9021 11.1121 15.9021C9.50545 15.9021 8.40675 14.6157 8.65809 13.0288Z"
      fill="black"
    />
  </svg>
);

export default PhotoIcon;

import * as consts from './constants';

export const resizeScreen = () => ({
  type: consts.RESIZE_SCREEN,
});

export const setGeoLocale = loc => ({
  type: consts.SET_GEO_LOCALE,
  payload: loc,
});

export const fetchCountriesSuccess = countries => ({
  type: consts.FETCH_COUNTRIES_SUCCESS,
  payload: countries,
});

export const setShowMobileSidebar = payload => ({
  type: consts.SHOW_MOBILE_SIDEBAR,
  payload,
});

export const setShowSwitchProfiles = payload => ({
  type: consts.SHOW_SWITCH_PROFILES,
  payload,
});

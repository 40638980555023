import { fetchCountriesSuccess } from '../app/actions';
import { fetchVenues } from '../venue/thunks';
import { request } from './request';

export const sendLoginRequest = (email, password, venueId) => dispatch =>
  dispatch(
    request('auth/sign-in', {
      method: 'POST',
      body: {
        email,
        password,
        venueId,
      },
    }),
  );

export const signUpReq = body => dispatch =>
  dispatch(request('auth/sign-up', { method: 'POST', body }));

export const createVenueRequest = venueData => dispatch =>
  dispatch(
    request('venues/create', {
      method: 'POST',
      body: { venueData },
    }),
  ).then(r => dispatch(fetchVenues(r.id)));

export const sendAutoLoginRequest = params => dispatch =>
  dispatch(
    request('auth/auto-signin', {
      method: 'POST',
      body: params,
    }),
  );

export const sendForgottenPassword = email => dispatch =>
  dispatch(
    request('auth/forgotten-password', {
      method: 'POST',
      body: {
        login: email,
      },
    }),
  );

export const sendPasswordUpdate = data => dispatch =>
  dispatch(
    request('auth/reset-password', {
      method: 'POST',
      body: data,
    }),
  );

export const postPreActivation = activationKey => dispatch =>
  dispatch(request('auth/pre-activation', { method: 'POST', body: { activationKey } }));

export const postActivate = activationData => dispatch =>
  dispatch(request('auth/activate', { method: 'POST', body: activationData }));

export const fetchCountriesReq = () => dispatch =>
  dispatch(request('meta/countries/for-signup'))
    .then(response => response.getResult())
    .then(res => dispatch(fetchCountriesSuccess(res)));

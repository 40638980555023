import React from 'react';
import PropTypes from 'prop-types';
import styles from './Toggle.module.scss';

const Toggle = ({ onChange, checked, className }) => (
  <div className={`relative py-1 flex items-center cursor-pointer ${className}`}>
    <input checked={checked} onChange={onChange} type="checkbox" className={`${styles.input}`} />
  </div>
);

Toggle.propTypes = {
  onChange: PropTypes.func,
  checked: PropTypes.bool,
  className: PropTypes.string,
};

Toggle.defaultProps = {
  onChange: () => {},
  checked: false,
  className: 'pr-3',
};

export default Toggle;

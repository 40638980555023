import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18next';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import PlainButton from '../../components/PlainButton/PlainButton';
import EditIcon from '../../components/Icons/EditIcon';
import { userProps, venueMemberProps } from '../../utils/customPropTypes';
import { getUser } from '../../store/auth/selectors';
import { genericRequest } from '../../store/api/request';
import { getVenueID } from '../../store/venue/selectors';
import MPModal from '../../components/MPModal/MPModal';
import CloseIcon from '../../components/Icons/CloseIcon';
import Input from '../SignupV2/Input';
import SpinnerButton from '../../components/SpinnerButton/SpinnerButton';

const MODE = {
  ADD: 'ADD',
  REMOVE: 'REMOVE',
  EDIT: 'EDIT',
  RESEND: 'RESEND',
};

const STATUS = {
  ACTIVE: 'active',
  PENDING: 'pending',
};

const Users = ({ member, venueId, request }) => {
  const [venueMembers, setVenueMembers] = useState([]);
  const [modal, setModal] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);

  const fetchMembers = useCallback(() => {
    request(`venues/${venueId}/venue-members`)
      .then(r => setVenueMembers(r.getResult()))
      .catch(() => setVenueMembers([]));
  }, [request, venueId]);

  useEffect(() => {
    fetchMembers();
  }, [fetchMembers]);

  const adminUser = venueMembers.find(m => m.type === 'Admin');
  const otherUsers = venueMembers.filter(m => m.type === 'Other');
  const isAdmin = member.id === adminUser?.memberId;

  return (
    <div>
      <h3 className="font-bold text-xl pt-8 mt-8 border-mpGrey border-t">
        {t('settings_usersTitle')}
      </h3>
      {isAdmin && <p className="opacity-60 pt-2">{t('settings_usersSubtitle')}</p>}
      <div className="pt-6">
        <p className="font-semibold opacity-90">{t('settings_usersAdmin')}</p>
        <div className="py-4">
          <UserRow user={adminUser} showEdit={false} />
        </div>
        <p className="opacity-60">
          {`${t('settings_adminChange1')} `}
          <a href="mailto:pubs@fanzo.com" className="underline">
            {t('settings_adminChange2')}
          </a>
        </p>
      </div>
      {otherUsers?.length > 0 && (
        <div className="pt-8">
          <p className="font-semibold opacity-90">{t('settings_usersMembers')}</p>
          <div className="flex flex-col gap-4 pt-4">
            {otherUsers.map(u => (
              <UserRow
                key={u.id}
                user={u}
                showEdit={isAdmin}
                onClick={() => {
                  setModal(u.status === STATUS.ACTIVE ? MODE.EDIT : MODE.RESEND);
                  setSelectedUser(u);
                }}
              />
            ))}
          </div>
        </div>
      )}
      {isAdmin && (
        <PlainButton
          className="w-full font-semibold border border-mpGrey rounded-lg py-3 mt-6"
          onClick={() => {
            setModal(MODE.ADD);
            setSelectedUser(null);
          }}
        >
          {t('userModal_addTitle')}
        </PlainButton>
      )}
      <UserModal
        mode={modal}
        user={selectedUser}
        setMode={setModal}
        fetchMembers={fetchMembers}
        venueId={venueId}
        request={request}
      />
    </div>
  );
};

Users.propTypes = {
  member: userProps.isRequired,
  venueId: PropTypes.number.isRequired,
  request: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  member: getUser(state),
  venueId: getVenueID(state),
});

const mapDispatchToProps = {
  request: genericRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(Users);

const UserRow = ({ user, onClick, showEdit }) => {
  const { firstName, lastName, email, status } = user || {};
  const fullName = `${firstName || ''} ${lastName || ''}`.trim();
  const initials = `${firstName?.[0] || ''}${lastName?.[0] || ''}` || email?.[0];

  if (!email) {
    return null;
  }

  return (
    <PlainButton className="flex w-full gap-2 items-center" onClick={onClick} disabled={!showEdit}>
      <p className="text-sm font-bold h-14 w-14 flex justify-center items-center rounded-full uppercase bg-[#FFF7CC]">
        {initials}
      </p>
      <div className="flex-1 text-left truncate">
        <p className="opacity-90 truncate">{fullName}</p>
        <p className="opacity-60 truncate">{email}</p>
      </div>
      {status === STATUS.PENDING && (
        <p className="text-xs font-semibold opacity-90 text-[#F26419] bg-[#FEEFE8] px-2 py-1 rounded-full">
          {t('general_pending')}
        </p>
      )}
      {showEdit && <EditIcon className="w-6 h-6 ml-6 mr-2" />}
    </PlainButton>
  );
};

UserRow.propTypes = {
  user: venueMemberProps,
  onClick: PropTypes.func,
  showEdit: PropTypes.bool.isRequired,
};

UserRow.defaultProps = {
  user: null,
  onClick: () => {},
};

const s = {
  button: 'w-full font-semibold py-3 rounded-lg',
};

const UserModal = ({ user, mode, setMode, venueId, request, fetchMembers }) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const onDataChange = newData => setData(prev => ({ ...prev, ...newData }));

  const onClose = () => {
    setData(null);
    setMode(null);
  };

  const onConfirm = (endpoint, method, toastMsg) => {
    setLoading(true);
    request(`venues/${venueId}/venue-members/${endpoint}`, undefined, method, data)
      .then(() => {
        toast.success(toastMsg);
        fetchMembers();
        onClose();
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    setData(user);
  }, [user]);

  const { title, subtitle, showForm, button1, button2 } =
    (() => {
      switch (mode) {
        case MODE.ADD:
          return {
            title: 'userModal_addTitle',
            subtitle: 'userModal_addSubtitle',
            showForm: true,
            button1: (
              <SpinnerButton
                className={`${s.button} bg-secondary`}
                loading={loading}
                onClick={() =>
                  onConfirm('add', 'POST', t('userModal_addToast', { email: data.email }))
                }
              >
                {t('general.add')}
              </SpinnerButton>
            ),
          };
        case MODE.REMOVE:
          return {
            title: 'userModal_removeTitle',
            subtitle: 'userModal_removeSubtitle',
            button1: (
              <SpinnerButton
                className={`${s.button} bg-[#FFF4F3] text-lightRed mt-8`}
                loading={loading}
                onClick={() => onConfirm(`${user.id}/delete`, 'DELETE', t('userModal_removeToast'))}
              >
                {t('userModal_removeBtn1')}
              </SpinnerButton>
            ),
            button2: (
              <PlainButton
                className={`${s.button} bg-mpGrey mt-2`}
                onClick={() => setMode(MODE.EDIT)}
              >
                {t('userModal_removeBtn2')}
              </PlainButton>
            ),
          };
        case MODE.EDIT:
          return {
            title: 'userModal_editTitle',
            showForm: true,
            button1: (
              <SpinnerButton
                className={`${s.button} bg-secondary`}
                loading={loading}
                onClick={() => onConfirm(`${user.id}/update`, 'PUT', t('userModal_editToast'))}
              >
                {t('general.save')}
              </SpinnerButton>
            ),
            button2: (
              <PlainButton
                className={`${s.button} bg-[#FFF4F3] text-lightRed mt-2`}
                onClick={() => setMode(MODE.REMOVE)}
              >
                {t('userModal_removeTitle')}
              </PlainButton>
            ),
          };
        case MODE.RESEND:
          return {
            title: 'userModal_editTitle',
            showForm: true,
            button1: (
              <SpinnerButton
                className={`${s.button} bg-secondary`}
                loading={loading}
                onClick={() =>
                  onConfirm(
                    `${data.id}/resend-activation-email`,
                    'POST',
                    t('userModal_addToast', { email: data.email }),
                  )
                }
              >
                {t('userModal_resendBtn')}
              </SpinnerButton>
            ),
            button2: (
              <SpinnerButton
                className={`${s.button} bg-[#FFF4F3] text-lightRed mt-2`}
                loading={loading}
                onClick={() => onConfirm(`${user.id}/delete`, 'DELETE', t('userModal_removeToast'))}
              >
                {t('userModal_removeTitle')}
              </SpinnerButton>
            ),
          };
        default:
          return null;
      }
    })() || {};

  return (
    <MPModal open={Boolean(mode)} onClose={onClose} className="bg-transparent">
      <div className="relative bg-white rounded-2xl mx-4 md:max-w-md md:mx-auto lg:max-w-lg">
        <PlainButton
          className="absolute top-4 right-4 p-2 bg-mpGrey rounded-full"
          onClick={onClose}
        >
          <CloseIcon className="w-8 h-8" />
        </PlainButton>
        <div className="p-8 pt-20">
          <h3 className="headingFont text-5xl text-center">{t(title)}</h3>
          {subtitle && <p className="text-center pt-4 opacity-60">{t(subtitle)}</p>}
          {showForm && (
            <div className="flex flex-col py-8 gap-4">
              <Input
                label={t('settings.first_name_placeholder')}
                placeholder={t('userModal_firstPlaceholder')}
                value={data?.firstName || ''}
                onChange={v => onDataChange({ firstName: v })}
                disabled={mode === MODE.RESEND}
                type="text"
              />
              <Input
                label={t('settings.last_name_placeholder')}
                placeholder={t('userModal_lastPlaceholder')}
                value={data?.lastName || ''}
                onChange={v => onDataChange({ lastName: v })}
                disabled={mode === MODE.RESEND}
                type="text"
              />
              <Input
                label={t('settings.email')}
                placeholder={t('userModal_emailPlaceholder')}
                value={data?.email || ''}
                onChange={v => onDataChange({ email: v })}
                disabled={mode === MODE.RESEND}
                type="email"
              />
            </div>
          )}
          {button1}
          {button2}
        </div>
      </div>
    </MPModal>
  );
};

UserModal.propTypes = {
  user: venueMemberProps,
  mode: PropTypes.string,
  setMode: PropTypes.func.isRequired,
  venueId: PropTypes.number.isRequired,
  request: PropTypes.func.isRequired,
  fetchMembers: PropTypes.func.isRequired,
};

UserModal.defaultProps = {
  user: null,
  mode: null,
};

import React from 'react';
import PropTypes from 'prop-types';

const InfoIcon = ({ className }) => (
  <svg className={`fill-current ${className}`} viewBox="0 0 24 24">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.9492 4.62445C8.88237 4.62445 5.01591 7.87167 4.31321 11.8773C3.61051 15.883 6.33767 19.1302 10.4045 19.1302C14.4713 19.1302 18.3378 15.883 19.0405 11.8773C19.7432 7.87167 17.016 4.62445 12.9492 4.62445ZM2.67685 11.8773C3.5357 6.98153 8.26138 3.0127 13.2319 3.0127C18.2025 3.0127 21.5357 6.98153 20.6768 11.8773C19.818 16.7731 15.0923 20.742 10.1217 20.742C5.15118 20.742 1.81799 16.7731 2.67685 11.8773Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.1346 11.3397L12.1921 16.7122H9.46484L10.4073 11.3397L13.1346 11.3397Z"
    />
    <path d="M13.8793 8.65374C13.7231 9.54389 12.8639 10.2655 11.9602 10.2655C11.0564 10.2655 10.4504 9.54389 10.6066 8.65374C10.7627 7.7636 11.6219 7.04199 12.5257 7.04199C13.4294 7.04199 14.0354 7.7636 13.8793 8.65374Z" />
  </svg>
);

InfoIcon.propTypes = {
  className: PropTypes.string,
};

InfoIcon.defaultProps = {
  className: '',
};

export default InfoIcon;

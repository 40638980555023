import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import OfferForm from '../../components/OfferForm/OfferForm';
import { createOfferThunk } from '../../store/offers/thunks';
import { historyProps } from '../../utils/customPropTypes';
import { GATags, fireEvent, GACategories } from '../../utils/trackingHelper';
import { useHiddenByPermissions } from '../../utils/CustomHooks';

const CreateOfferPage = ({ createNewOffer, history, title }) => {
  useHiddenByPermissions();

  return (
    <OfferForm
      saveAction={(id, offer, nav) => {
        fireEvent(GACategories.OFFER, GATags.CREATE_OFFER, id);
        createNewOffer(id, offer, nav);
      }}
      navigate={history.push}
      title={title}
    />
  );
};

CreateOfferPage.propTypes = {
  createNewOffer: PropTypes.func.isRequired,
  history: historyProps.isRequired,
  title: PropTypes.string.isRequired,
};

const mapDispatchToProps = {
  createNewOffer: createOfferThunk,
};

export default connect(null, mapDispatchToProps)(CreateOfferPage);

import React from 'react';
import PropTypes from 'prop-types';

const ClockIcon = ({ className }) => (
  <svg className={`stroke-current ${className}`} viewBox="0 0 24 24" fill="none">
    <circle r="8.18182" transform="matrix(1 0 -0.164531 0.986372 12 12)" strokeWidth="1.63636" />
    <path d="M12.9994 6.00891L12.0001 12L15.4411 15.0204" strokeWidth="1.63636" />
  </svg>
);

ClockIcon.propTypes = {
  className: PropTypes.string,
};
ClockIcon.defaultProps = {
  className: '',
};

export default ClockIcon;

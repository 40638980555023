import React from 'react';
import PropTypes from 'prop-types';

const HeadingRow = ({ date, className }) => (
  <p
    className={`uppercase text-xs pt-3 pb-1 px-4 md:text-base md:font-medium md:px-6 md:pt-3 md:pb-2 xl:px-10 opacity-[.87] ${className}`}
  >
    {date}
  </p>
);

HeadingRow.propTypes = {
  date: PropTypes.string.isRequired,
  className: PropTypes.string,
};

HeadingRow.defaultProps = {
  className: '',
};

export default HeadingRow;

import React from 'react';
import PropTypes from 'prop-types';

const Input = ({
  label,
  placeholder,
  value,
  onChange,
  onKeyPress,
  error,
  dataTestId,
  type,
  disabled,
}) => (
  <div>
    {label && <p className="font-bold pb-2">{label}</p>}
    <input
      className={`px-4 py-3 w-full rounded-lg border border-mpGrey focus:outline focus:outline-2 focus:outline-quaternary ${
        disabled ? 'bg-mpGrey' : 'bg-white'
      }`}
      placeholder={placeholder}
      value={value}
      onChange={e => onChange(e.target.value)}
      onKeyPress={onKeyPress}
      type={type}
      data-test-id={dataTestId}
      disabled={disabled}
    />
    {error && <p className="text-xs text-lightRed pt-1">{error}</p>}
  </div>
);

Input.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  onKeyPress: PropTypes.func,
  error: PropTypes.string,
  dataTestId: PropTypes.string,
  type: PropTypes.string,
  disabled: PropTypes.bool,
};

Input.defaultProps = {
  value: '',
  label: null,
  onChange: () => {},
  onKeyPress: () => {},
  error: '',
  dataTestId: '',
  type: 'text',
  disabled: false,
};

export default Input;

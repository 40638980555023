/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import i18next from 'i18next';
import ChevronIcon from '../../components/Icons/ChevronIcon';
import PlainButton from '../../components/PlainButton/PlainButton';

const ChevButton = ({ children, onClick, disabled, rotation }) => (
  <PlainButton className="flex flex-col items-center" onClick={onClick} disabled={disabled}>
    <ChevronIcon
      className={`h-10 w-10 ${rotation ? 'rotate-180' : ''} ${
        disabled ? 'text-lightGrey' : 'text-primary'
      }`}
    />
    <p className="font-semibold">{children}</p>
  </PlainButton>
);

ChevButton.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  rotation: PropTypes.bool,
};

ChevButton.defaultProps = {
  rotation: false,
};

const ChartScroll = ({ lessProps, moreProps, show }) =>
  show ? (
    <div className="flex justify-between py-4 px-6">
      <ChevButton rotation {...lessProps()}>
        {i18next.t('stats_older')}
      </ChevButton>
      <ChevButton {...moreProps()}> {i18next.t('stats_newer')}</ChevButton>
    </div>
  ) : null;

ChartScroll.propTypes = {
  lessProps: PropTypes.func.isRequired,
  moreProps: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
};

export default ChartScroll;

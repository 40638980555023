import React from 'react';
import PropTypes from 'prop-types';

const PintIcon = ({ className, isActive }) => (
  <svg className={`fill-current ${className}`} viewBox="0 0 24 24" fill="none">
    {isActive ? (
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.0967 2.4541H9.22169L8.94706 3.03142C7.62773 5.80481 7.10129 7.97525 6.97116 9.74606C6.84192 11.5046 7.10801 12.8306 7.31836 13.8789L7.32141 13.8941C7.50644 14.8162 7.61486 15.4111 7.51823 15.9618C7.27352 17.3568 6.95745 18.0963 6.70307 18.6914L6.6848 18.7341C6.52311 19.1122 6.3477 19.5223 6.26315 20.0043C6.12742 20.778 6.7245 21.1568 7.05025 21.3106C7.43792 21.4937 7.9302 21.5985 8.3647 21.6634C8.81202 21.7301 9.25835 21.7626 9.58825 21.7788C9.7544 21.7869 9.89376 21.791 9.99247 21.793C10.0419 21.794 10.0813 21.7946 10.1089 21.7948L10.1413 21.7951L10.1505 21.7951L10.1534 21.7951H10.1565L10.1593 21.7951L10.169 21.7951L10.2031 21.7948C10.2323 21.7946 10.2742 21.7941 10.3269 21.793C10.4322 21.791 10.5814 21.7869 10.7606 21.7789C11.1167 21.7628 11.6014 21.7305 12.0986 21.6644C12.5826 21.6 13.1357 21.4967 13.6042 21.3181C13.8373 21.2293 14.1053 21.1012 14.3413 20.9086C14.5918 20.704 14.8354 20.399 14.9047 20.0043C14.9884 19.5267 14.9619 19.1202 14.9371 18.7413L14.9344 18.7C14.8956 18.1037 14.8472 17.3612 15.0927 15.9618C15.1907 15.4035 15.5017 14.8028 15.9934 13.8828L16.0011 13.8684C16.5614 12.8198 17.2685 11.4968 17.7469 9.74243C18.2284 7.97695 18.47 5.81118 18.162 3.04103L18.0967 2.4541ZM9.37727 6.07359H16.6347C16.648 5.45183 16.6283 4.78429 16.5678 4.06585H10.1693C9.84742 4.78452 9.58677 5.45206 9.37727 6.07359Z"
      />
    ) : (
      <>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.16533 2.4646H17.6708L17.4148 4.08082H10.1397C8.6919 7.44668 8.5562 9.70774 8.73179 11.3905C8.80882 12.1287 8.94488 12.7574 9.07568 13.3618C9.10661 13.5047 9.13724 13.6463 9.16673 13.7876C9.31452 14.4958 9.44439 15.2403 9.32252 16.0098C9.11775 17.3026 8.84135 18.1304 8.592 18.7662C8.52488 18.9374 8.46613 19.0813 8.41485 19.2069C8.30229 19.4827 8.22577 19.6701 8.17599 19.8634C8.19075 19.8707 8.20753 19.8785 8.22652 19.8867C8.45667 19.9862 8.81915 20.068 9.24709 20.127C9.66249 20.1842 10.0889 20.2136 10.4157 20.2284C10.5779 20.2357 10.7132 20.2394 10.807 20.2412C10.8539 20.2421 10.8903 20.2426 10.9144 20.2428L10.9411 20.243L10.9471 20.243H10.9482C10.9482 20.243 10.948 20.243 10.82 21.0511C10.692 21.8592 10.6918 21.8592 10.6918 21.8592H10.6905L10.6876 21.8592L10.6779 21.8592L10.6435 21.8589C10.6141 21.8587 10.572 21.8581 10.5191 21.8571C10.4134 21.8551 10.2639 21.851 10.0855 21.8429C9.73092 21.8269 9.25204 21.7945 8.77197 21.7283C8.30444 21.6638 7.78124 21.5604 7.36912 21.3822C7.16421 21.2935 6.93724 21.1658 6.76403 20.9735C6.58034 20.7695 6.43494 20.4627 6.49819 20.0634C6.57624 19.5706 6.76193 19.1196 6.93245 18.7054C6.97493 18.6022 7.01647 18.5013 7.05517 18.4027C7.26374 17.8708 7.5031 17.1655 7.68616 16.0098C7.7582 15.5549 7.68999 15.0519 7.5411 14.3384C7.51516 14.2141 7.48699 14.0843 7.45762 13.9489C7.32569 13.3407 7.16966 12.6214 7.08359 11.7964C6.86808 9.73117 7.08444 7.00967 8.90028 3.04351L9.16533 2.4646Z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M18.0404 2.4646H11.6708L11.4149 4.08082H16.5382C16.8769 7.44718 16.3059 9.71217 15.6157 11.3992C15.313 12.139 14.9877 12.7692 14.6757 13.3736C14.602 13.5164 14.5291 13.6577 14.4573 13.7987C14.0967 14.5077 13.745 15.2473 13.6243 16.0098C13.42 17.2995 13.4276 18.1245 13.4687 18.7584C13.4796 18.9275 13.4911 19.0703 13.5011 19.1955C13.5238 19.4789 13.5393 19.6718 13.5252 19.8732C13.5116 19.8791 13.4965 19.8853 13.48 19.8919C13.2375 19.9883 12.8731 20.069 12.4487 20.1277C12.0375 20.1845 11.6239 20.2137 11.3087 20.2285C11.1523 20.2358 11.0228 20.2394 10.9332 20.2412C10.8885 20.2421 10.8539 20.2426 10.831 20.2428L10.8058 20.243L10.8002 20.243C10.8002 20.243 10.7996 20.243 10.6716 21.0511C10.5436 21.8592 10.5438 21.8592 10.5438 21.8592H10.5451L10.548 21.8592L10.5574 21.8592L10.5907 21.8589C10.6191 21.8587 10.6596 21.8581 10.7107 21.8571C10.8128 21.8551 10.9575 21.851 11.131 21.8429C11.4758 21.8267 11.9453 21.7942 12.4271 21.7276C12.8956 21.6628 13.4339 21.5584 13.8904 21.377C14.2644 21.2284 15.0147 20.8523 15.1397 20.0634C15.217 19.5755 15.1798 19.1299 15.1454 18.7159C15.1367 18.6122 15.1282 18.5105 15.1218 18.4105C15.0872 17.8766 15.0771 17.1687 15.2606 16.0098C15.3338 15.5479 15.5574 15.04 15.92 14.3273C15.9834 14.2027 16.0506 14.0726 16.1206 13.9371C16.4348 13.3289 16.8056 12.6111 17.1425 11.7878C17.985 9.72854 18.6201 7.01381 18.1154 3.05316L18.0404 2.4646ZM13.6425 19.8114C13.6425 19.8114 13.6338 19.8158 13.6224 19.8257C13.6378 19.8166 13.6425 19.8114 13.6425 19.8114Z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.9669 6.90918H8.78507L8.91306 6.10107H17.0949L16.9669 6.90918Z"
        />
      </>
    )}
  </svg>
);

PintIcon.propTypes = {
  className: PropTypes.string,
  isActive: PropTypes.bool,
};

PintIcon.defaultProps = {
  className: '',
  isActive: false,
};

export default PintIcon;

import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18next';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { toast } from 'react-toastify';
import ProfileHeader from '../ProfilePageV2/ProfileHeader';
import { getVenue } from '../../store/venue/selectors';
import { getLocaleDate } from '../../i18n';
import history from '../../routes/history';
import routes from '../../routes/paths';
import { genericRequest } from '../../store/api/request';
import { countryProps, venueProps } from '../../utils/customPropTypes';
import LoadingAnimation from '../../components/LoadingAnimation/LoadingAnimation';
import { canAccessBilling, formatPrice } from '../../utils/general';
import { updateVenueSuccess } from '../../store/venue/actions';
import DetailsModal from '../SignupV2/Stage3/DetailsModal';
import { fetchCountriesReq } from '../../store/api/auth';
import { getCountries } from '../../store/app/selectors';
import ContentBlock from './ContentBlock';
import InvoicesSection from './InvoicesSection';

const FREQUENCY = {
  1: {
    price: 'monthly',
    text: 'subscriptions.month',
  },
  3: {
    price: 'quarterly',
    text: 'subscriptions.quarter',
  },
  12: {
    price: 'annual',
    text: 'subscriptions.annum',
  },
};

const BillingPage = ({ venue, request, refreshVenue, fetchCountries, countries }) => {
  const [billing, setBilling] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);

  const hasAccess = canAccessBilling(venue);
  const endpoint = `venues/${venue.id}/billing`;

  const fetchBilling = useCallback(() => {
    setLoading(true);
    request(endpoint)
      .then(r => setBilling(r.getResult()))
      .catch(() => {
        history.push(routes.account.path);
        toast.error(t('settings.error'));
      })
      .finally(() => setLoading(false));
  }, [request, endpoint]);

  useEffect(() => {
    if (hasAccess) {
      fetchBilling();
    }
  }, [hasAccess, fetchBilling]);

  useEffect(() => {
    if (!countries?.length) {
      fetchCountries();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countries]);

  if (!hasAccess) {
    return <Redirect to={routes.account.path} />;
  }

  if (loading) {
    return <LoadingAnimation size={100} />;
  }

  const { planInformation, billingDetails: d, paymentInfo } = billing;
  const { tierName, nextPaymentDate, paymentFrequency, price } = planInformation || {};
  const { goCardlessLink, paymentType, paymentDetails } = paymentInfo || {};
  const { bankName, accountNumberEnding } = paymentDetails || {};
  const hasManualPay = paymentType === 'manual';
  const hasAutoPay = paymentType === 'auto';
  const frequency = FREQUENCY[paymentFrequency];

  const details = {
    fullName: `${d?.managerFirstName || ''} ${d?.managerLastName || ''}`.trim(),
    businessName: d?.businessName,
    email: d?.managerEmail,
    address1: d?.businessAddress1,
    address2: d?.businessAddress2,
    city: d?.businessAddressCity,
    postCode: d?.businessAddressPostCode,
  };

  const formFields = {
    firstName: d?.managerFirstName,
    lastName: d?.managerLastName,
    countryId: d?.businessAddressCountryId,
  };

  return (
    <>
      <ProfileHeader text={t('titles_billing')} />
      <div className="flex flex-col gap-4 mt-8 mb-16 md:max-w-md md:mx-auto lg:max-w-lg w-full">
        {tierName && (
          <ContentBlock
            title="billing_planTitle"
            buttonText="general.upgrade"
            onClick={() => history.push(routes.subscriptions.path)}
          >
            <p className="font-bold pb-2 opacity-60">{tierName}</p>
            <div className="flex items-center gap-2 font-bold">
              <p className="text-5xl">{formatPrice(price[frequency?.price])}</p>
              <p className="text-xl">/ {t(frequency?.text)}</p>
            </div>
            {nextPaymentDate && (
              <>
                <p className="font-bold pt-4 pb-2 opacity-60 text-xs uppercase">
                  {t('billing_planNextPay')}
                </p>
                <p className="opacity-60">{getLocaleDate(nextPaymentDate, 'do MMMM yyyy')}</p>
              </>
            )}
          </ContentBlock>
        )}
        {details.email && (
          <ContentBlock
            title="billing_detailsTitle"
            buttonText="general.edit"
            onClick={() => setShowModal(true)}
          >
            <div className="opacity-60">
              {Object.entries(details).map(([k, v]) => (
                <p key={k}>{v}</p>
              ))}
            </div>
          </ContentBlock>
        )}
        {(hasAutoPay || hasManualPay) && venue.paymentStatus !== 'free-tier' && (
          <ContentBlock
            title="billing_paymentTitle"
            buttonText="settings_passUpdateBtn"
            onClick={() => window.open(goCardlessLink)}
          >
            <div className="opacity-60">
              {hasAutoPay && bankName && accountNumberEnding && (
                <>
                  <p>{t('financials.mandate.directDebit')}</p>
                  <p>******{accountNumberEnding}</p>
                  <p>{bankName}</p>
                </>
              )}
              {hasManualPay && <p>{t('billing_paymentManual')}</p>}
            </div>
          </ContentBlock>
        )}
        <InvoicesSection venue={venue} hasManualPay={hasManualPay} />
      </div>

      <DetailsModal
        open={showModal}
        isBilling
        onClose={() => setShowModal(false)}
        data={{ ...details, ...formFields }}
        onSubmit={c =>
          request(`${endpoint}/update`, undefined, 'PUT', {
            venue: {
              id: venue.id,
              managerFirstName: c.firstName,
              managerLastName: c.lastName,
              businessName: c.businessName,
              businessAddress1: c.address1,
              businessAddress2: c.address2,
              businessAddressCity: c.city,
              businessAddressPostCode: c.postCode,
              businessAddressCountryId: c.countryId,
            },
          }).then(res => {
            toast.success(t('billing_detailsSaved'));
            refreshVenue(res.getResult());
            fetchBilling();
          })
        }
      />
    </>
  );
};

BillingPage.propTypes = {
  venue: venueProps.isRequired,
  request: PropTypes.func.isRequired,
  refreshVenue: PropTypes.func.isRequired,
  fetchCountries: PropTypes.func.isRequired,
  countries: PropTypes.arrayOf(countryProps).isRequired,
};

BillingPage.defaultProps = {};

const mapStateToProps = state => ({
  venue: getVenue(state),
  countries: getCountries(state),
});

const mapDispatchToProps = {
  refreshVenue: updateVenueSuccess,
  request: genericRequest,
  fetchCountries: fetchCountriesReq,
};

export default connect(mapStateToProps, mapDispatchToProps)(BillingPage);

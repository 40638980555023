import '../styles.scss';
import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  drawOnCanvas,
  percentageToCanvasCoordinates,
  wrappableCanvasText,
  verticalAlignments,
} from '../shared';
import { createImageObject } from '../../../../utils/general';
import { SMConstraintProps } from '../../../../utils/customPropTypes';

const TenFixturesTemplate = ({
  text,
  color,
  textColor,
  templateHeight,
  templateWidth,
  template,
  loadedImage,
  isLandscape,
  textConstraints,
  fontSize,
  font,
}) => {
  const canvas = useRef(null);

  useEffect(() => {
    drawOnCanvas(canvas, templateWidth, templateHeight, color, (cc, ctx) => {
      if (!isLandscape) {
        const { startX, startY, endX, endY } = percentageToCanvasCoordinates(
          textConstraints,
          cc.height,
          cc.width,
        );
        wrappableCanvasText(
          ctx,
          text,
          { startX, startY: startY + fontSize, endY, endX },
          fontSize,
          textColor,
          font,
          verticalAlignments.bottom,
          true,
        );
      }

      ctx.drawImage(createImageObject(template), 0, 0, templateWidth, templateHeight);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [text, color, textColor, templateHeight, templateWidth, template, loadedImage, fontSize]);

  return (
    <canvas
      className="ImageGenerator_canvas"
      ref={canvas}
      width={templateWidth}
      height={templateHeight}
    />
  );
};

TenFixturesTemplate.propTypes = {
  templateHeight: PropTypes.number.isRequired,
  templateWidth: PropTypes.number.isRequired,
  text: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  textColor: PropTypes.string.isRequired,
  template: PropTypes.string,
  loadedImage: PropTypes.bool,
  isLandscape: PropTypes.bool,
  fontSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  font: PropTypes.string,
  textConstraints: SMConstraintProps,
};

TenFixturesTemplate.defaultProps = {
  font: null,
  template: null,
  isLandscape: false,
  textConstraints: null,
  loadedImage: false,
};

export default TenFixturesTemplate;

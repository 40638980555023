import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './styles.scss';
import i18n from 'i18next';
import PlainButton from '../PlainButton/PlainButton';
import Button from '../Button/Button';

const ImageUpload = ({ onUpload, id, onDelete, onDefault, label, className, sizeLimitMB }) => {
  const [fileExceedsSizeLimit, setFileExceedsSizeLimit] = useState(false);
  const checkFileIsUnderLimit = file => file && file.size / 1000000 < sizeLimitMB;
  return (
    <>
      <div className="flex w-full flex-row">
        <div className={className}>
          <label htmlFor={id}>
            <input
              type="file"
              id={id}
              accept="image/png, image/jpeg"
              onChange={e => {
                if (e && e.target && e.target.files && e.target.files[0]) {
                  if (sizeLimitMB && !checkFileIsUnderLimit(e.target.files[0])) {
                    setFileExceedsSizeLimit(true);
                  } else {
                    setFileExceedsSizeLimit(false);
                    onUpload(e.target.files[0]);
                  }
                }
              }}
            />
            {label || i18n.t('general.upload').toUpperCase()}
          </label>
        </div>
        {onDelete && (
          <Button
            className="bg-primaryRed font-semibold"
            onClick={() => {
              onDelete();
              setFileExceedsSizeLimit(false);
            }}
          >
            {i18n.t('general.delete')}
          </Button>
        )}
        {onDefault && (
          <PlainButton className="ImageUpload_default" onClick={onDefault}>
            <p>{i18n.t('general.default')}</p>
          </PlainButton>
        )}
      </div>
      {fileExceedsSizeLimit && (
        <div className="ImageUpload_error_container">
          <p className="ImageUpload_error">
            {i18n.t('marketing.bespoke.imageUpload.useImageUnder', { size: sizeLimitMB })}
          </p>
        </div>
      )}
    </>
  );
};

ImageUpload.propTypes = {
  sizeLimitMB: PropTypes.number,
  label: PropTypes.string,
  className: PropTypes.string,
  onDelete: PropTypes.func,
  id: PropTypes.string,
  onUpload: PropTypes.func,
  onDefault: PropTypes.func,
};

ImageUpload.defaultProps = {
  sizeLimitMB: null,
  onDelete: undefined,
  className: 'ImageUpload_fileUpload',
  label: '',
  onUpload: () => {},
  id: null,
  onDefault: null,
};

export default ImageUpload;

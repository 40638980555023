import { toast } from 'react-toastify';
import i18next from 'i18next';
import * as actions from './actions';
import {
  fetchFinancialFormDesc,
  fetchFinancialFormValues,
  saveFinancialFormValues,
} from '../api/venues';

export const fetchFormDescriptionForFinancials = (venueId, params) => dispatch => {
  dispatch(actions.fetchFormDescriptionPending());
  return dispatch(fetchFinancialFormDesc(venueId, params))
    .then(r => dispatch(actions.fetchFormDescriptionSuccess(r.getResult())))
    .catch(() => dispatch(actions.fetchFormDescriptionError()));
};

export const fetchFormValuesForFinancials = venueId => dispatch => {
  dispatch(actions.fetchFormValuesPending());
  return dispatch(fetchFinancialFormValues(venueId))
    .then(r => dispatch(actions.fetchFormValuesSuccess(r.getResult())))
    .catch(() => dispatch(actions.fetchFormValuesError()));
};

export const saveFormValuesForFinancials = (venueId, body, method = 'update') => dispatch => {
  dispatch(actions.updateFormValuesPending());
  return dispatch(saveFinancialFormValues(venueId, body, method))
    .then(r => {
      toast.success(i18next.t('toast.updated_details_success'));
      return dispatch(actions.updateFormValuesSuccess(r.getResult()));
    })
    .catch(err => dispatch(actions.updateFormValuesError(err && err.error)));
};

import * as consts from './constants';

export function fetchOffersPending() {
  return {
    type: consts.FETCH_OFFERS_PENDING,
  };
}

export function fetchOffersSuccess(res) {
  return {
    type: consts.FETCH_OFFERS_SUCCESS,
    payload: res,
  };
}

export function fetchOffersError() {
  return {
    type: consts.FETCH_OFFERS_ERROR,
  };
}

export function fetchMoreOffersPending() {
  return {
    type: consts.FETCH_MORE_OFFERS_PENDING,
  };
}

export function fetchMoreOffersSuccess(moreOffers) {
  return {
    type: consts.FETCH_MORE_OFFERS_SUCCESS,
    payload: moreOffers,
  };
}

export function fetchMoreOffersError() {
  return {
    type: consts.FETCH_MORE_OFFERS_ERROR,
  };
}

export function fetchOfferPending() {
  return {
    type: consts.FETCH_OFFER_PENDING,
  };
}

export function fetchOfferSuccess(offer) {
  return {
    type: consts.FETCH_OFFER_SUCCESS,
    payload: offer,
  };
}

export function fetchOfferError() {
  return {
    type: consts.FETCH_OFFER_ERROR,
  };
}

export function clearOfferAction() {
  return {
    type: consts.CLEAR_OFFER,
  };
}

export function createOfferPending() {
  return {
    type: consts.CREATE_OFFER_PENDING,
  };
}

export function createOfferSuccess() {
  return {
    type: consts.CREATE_OFFER_SUCCESS,
  };
}

export function createOfferError(err) {
  return {
    type: consts.CREATE_OFFER_ERROR,
    payload: err,
  };
}

export function stopOfferPending() {
  return {
    type: consts.STOP_OFFER_PENDING,
  };
}

export function stopOfferSuccess(offer) {
  return {
    type: consts.STOP_OFFER_SUCCESS,
    payload: offer,
  };
}

export function stopOfferError() {
  return {
    type: consts.STOP_OFFER_ERROR,
  };
}

export function saveOfferPending() {
  return {
    type: consts.SAVE_OFFER_PENDING,
  };
}

export function saveOfferSuccess() {
  return {
    type: consts.SAVE_OFFER_SUCCESS,
  };
}

export function saveOfferError() {
  return {
    type: consts.SAVE_OFFER_ERROR,
  };
}

export function updateOfferPending() {
  return {
    type: consts.UPDATE_OFFER_PENDING,
  };
}

export function updateOfferSuccess() {
  return {
    type: consts.UPDATE_OFFER_SUCCESS,
  };
}

export function updateOfferError(err) {
  return {
    type: consts.UPDATE_OFFER_ERROR,
    payload: err,
  };
}

export function getCompetitionsSuccess(competitions) {
  return {
    type: consts.GET_COMPETITIONS_SUCCESS,
    payload: competitions,
  };
}

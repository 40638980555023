import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { t } from 'i18next';
import { toast } from 'react-toastify';
import { historyProps } from '../../utils/customPropTypes';
import { sendPasswordUpdate } from '../../store/api/auth';
import UnprotectedLayout from '../UnprotectedLayout/UnprotectedLayout';
import { publicRoutes } from '../../routes/paths';
import SpinnerButton from '../../components/SpinnerButton/SpinnerButton';
import PlainButton from '../../components/PlainButton/PlainButton';
import TickIcon from '../../components/Icons/TickIcon';
import PasswordInput from '../../components/PasswordInput/PasswordInput';
import PasswordValidation from '../../components/PasswordInput/PasswordValidation';
import { validatePassword } from '../../utils/FormValidation';

const styles = {
  title: 'headingFont text-4xl md:text-6xl',
  btn: 'w-full p-4 font-semibold rounded-lg',
  btnActive: 'bg-tertiary text-primaryButtonText',
  btnDisabled: 'bg-mpGrey text-standardGrey',
};

const initialState = { password: '', passwordConfirm: '' };

const ResetPasswordPage = ({ history, updatePassword, match }) => {
  const [state, setState] = useState(initialState);
  const [isFetching, setIsFetching] = useState(false);
  const [isFocused, setIsFocused] = useState({ password: false, passwordConfirm: false });

  const passwordValidation = validatePassword(state.password);
  const passwordIsValid = !Object.values(passwordValidation).some(condition => !condition);
  const passwordsAreEqual = state.password === state.passwordConfirm;

  const disabled = !(passwordIsValid && passwordsAreEqual);
  const { hasUpdated } = history?.location?.state || {};

  const changeField = (value, field) => setState({ ...state, [field]: value });

  const submitForm = () => {
    setIsFetching(true);
    return updatePassword({ ...state, token: match.params.token })
      .then(() => history.push({ state: { hasUpdated: true } }))
      .catch(e => {
        if (e.code === 202) {
          toast.error(e.message);
        }
      })
      .finally(() => setIsFetching(false));
  };

  const enterKey = event => {
    if (event.key === 'Enter' && !disabled) {
      submitForm();
    }
  };

  const getBorderColor = (focused, validPass) => {
    if (focused) {
      if (validPass) {
        return 'border-2 border-green';
      }
      return 'border-2 border-lightRed';
    }
    return 'border border-mpGrey';
  };

  return (
    <UnprotectedLayout noPad>
      <div className="flex flex-1 justify-center">
        <div className="w-full max-w-sm md:max-w-md lg:max-w-sm mt-8 md:mt-14 lg:mt-16 px-4 md:px-0">
          {!hasUpdated ? (
            <>
              <h1 className={`${styles.title} md:text-center mb-8`}>
                {t('auth_newPassword_title')}
              </h1>
              <div className="mb-4">
                <PasswordInput
                  onChange={e => changeField(e, 'password')}
                  value={state?.password}
                  id="new-password"
                  label={t('auth_newPassword_label1')}
                  placeholder={t('auth_newPassword_placeholder1')}
                  className={getBorderColor(isFocused.password, passwordIsValid)}
                  onFocus={() => setIsFocused({ ...isFocused, password: true })}
                />
                {isFocused.password && (
                  <div className="pt-2">
                    <PasswordValidation
                      text={t('password_validation1')}
                      passed={passwordValidation.has8Chars}
                    />
                    <PasswordValidation
                      text={t('password_validation2')}
                      passed={passwordValidation.hasANumber}
                    />
                    <PasswordValidation
                      text={t('password_validation3')}
                      passed={passwordValidation.hasALetter}
                    />
                  </div>
                )}
              </div>
              <div className="mb-6">
                <PasswordInput
                  onChange={e => changeField(e, 'passwordConfirm')}
                  onKeyPress={enterKey}
                  value={state?.passwordConfirm}
                  id="confirm-new-password"
                  label={t('auth_newPassword_label2')}
                  placeholder={t('auth_newPassword_placeholder2')}
                  className={getBorderColor(isFocused.passwordConfirm, !disabled)}
                  onFocus={() => setIsFocused({ ...isFocused, passwordConfirm: true })}
                />
                {isFocused.passwordConfirm && (
                  <div className="pt-2">
                    <PasswordValidation text={t('password_validation4')} passed={!disabled} />
                  </div>
                )}
              </div>
              <SpinnerButton
                className={`${styles.btn} ${disabled ? styles.btnDisabled : styles.btnActive}`}
                onClick={submitForm}
                disabled={disabled}
                loading={isFetching}
              >
                {t('auth_newPassword_cta')}
              </SpinnerButton>
            </>
          ) : (
            <>
              <div className="flex items-center md:justify-center pb-2 md:pb-4">
                <h1 className={styles.title}>{t('auth_newPassword_confirmedTitle')}</h1>
                <TickIcon className="w-10 h-10 md:w-14 md:h-14 text-secondary ml-2" thick />
              </div>
              <p className="text-darkGrey md:text-center pb-8">
                {t('auth_newPassword_confirmedSubtitle')}
              </p>
              <PlainButton
                className={`${styles.btn} ${styles.btnActive}`}
                onClick={() => {
                  setState(initialState);
                  history.push(publicRoutes.login);
                }}
              >
                {t('auth_resetPassword_sentCta')}
              </PlainButton>
            </>
          )}
        </div>
      </div>
    </UnprotectedLayout>
  );
};

const mdtp = {
  updatePassword: sendPasswordUpdate,
};

ResetPasswordPage.propTypes = {
  updatePassword: PropTypes.func.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape(),
  }).isRequired,
  history: historyProps.isRequired,
};

export default withRouter(connect(null, mdtp)(ResetPasswordPage));

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import PageTab from '../PageTab/PageTab';
import './styles.scss';
import { getIsMobile } from '../../store/app/selectors';

const SplitViewWithTabs = ({ isMobile, left, right }) => {
  const [showTemplate, setShowTemplate] = useState(!isMobile);
  return (
    <>
      {isMobile && (
        <>
          <PageTab
            theme="white"
            onClick={() => setShowTemplate(!showTemplate)}
            show={showTemplate}
          />
          <PageTab
            theme="black"
            onClick={() => setShowTemplate(!showTemplate)}
            show={showTemplate}
          />
        </>
      )}
      <div className="SplitViewWithTabs">
        <div className={`SplitViewWithTabs_column${showTemplate ? '_inactive' : ''}`}>{left}</div>

        <div className={`SplitViewWithTabs_column_right${showTemplate ? '_active' : ''}`}>
          {right}
        </div>
      </div>
    </>
  );
};

SplitViewWithTabs.propTypes = {
  isMobile: PropTypes.bool.isRequired,
  left: PropTypes.node.isRequired,
  right: PropTypes.node.isRequired,
};

const mapStateToProps = state => ({
  isMobile: getIsMobile(state),
});

export default connect(mapStateToProps)(SplitViewWithTabs);

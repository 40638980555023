import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { t } from 'i18next';
import { toast } from 'react-toastify';
import {
  getAllChannels,
  getChannelsChanged,
  getInitiallySelectedChannels,
  getIsSavingChannels,
  getVenue,
  getVenueSelectedChannels,
} from '../../store/venue/selectors';
import { refreshVenueThunk, saveVenueChannels } from '../../store/venue/thunks';
import { venueProps } from '../../utils/customPropTypes';
import ChannelSelector from '../../components/ChannelSelector/ChannelSelector';
import SpinnerButton from '../../components/SpinnerButton/SpinnerButton';
import {
  getCompsFilters,
  getFavouritesFilters,
  getFixtures,
  getSportsFilters,
  saveFixturesRequest,
} from '../../store/api/fixtures';
import FixturesHeader from './FixturesHeader';
import FixtureLimitModal from './FixtureLimitModal';
import FixturesList from './FixturesList';
import SaveModal from './SaveModal';
import { useFilters, usePaginatedRequest } from './util';
import FloatingBanner from '../../components/FloatingBanner/FloatingBanner';
import { fireEvent, GACategories, GATags } from '../../utils/trackingHelper';
import { getIsDesktop } from '../../store/app/selectors';
import AddAllFixturesModal from './AddAllFixturesModal';
import { KEYS } from './FixtureRow';
import UpgradeModal from '../../components/UpgradeModal/UpgradeModal';
import { getVenueAccessLevel, venueIsLead } from '../../utils/general';
import { VENUE_ACCESS_LEVEL } from '../../utils/constants';
import FirstFixtureModal from './FirstFixtureModal';
import { useHiddenByPermissions } from '../../utils/CustomHooks';
import AddFavourites from '../../components/AddFavourites/AddFavourites';
import PlainButton from '../../components/PlainButton/PlainButton';

const styles = {
  box: 'my-6 mx-4 md:my-8 md:mx-6 lg:max-w-xl lg:mx-auto lg:my-10 bg-white border-mpGrey',
  boxTitle: 'text-xl font-bold',
};

const FixturesPage = ({
  fetchFixtures,
  venue,
  saveChannels,
  selectedChannels,
  isSavingChannels,
  channelsChanged,
  fetchFavouritesFilters,
  fetchSportsFilters,
  fetchCompsFilters,
  saveFixtures,
  isDesktop,
  venueChannels,
  refreshVenue,
  allChannels,
}) => {
  const [showLimitModal, setShowLimitModal] = useState(false);
  const [showAddAllFixturesModal, setShowAddAllFixturesModal] = useState(false);
  const [showSaveModal, setShowSaveModal] = useState(false);
  const [savingFixtures, setSavingFixtures] = useState(false);
  const [favsChanged, setFavsChanged] = useState(false);

  // To search by term or suggested filter send one of these in an object { search: string, competitionIds: int[], sportIds: int[] }
  // eg: { competitionIds: 60  }
  const [filters, fetchPaginated, dispatch, options] = useFilters(
    venue?.id,
    fetchFavouritesFilters,
    fetchSportsFilters,
    fetchCompsFilters,
    6,
  );

  const [
    fixtures,
    fetching,
    hasMore,
    fetchNext,
    loadingPage,
    ,
    updateFixture,
    changes,
    discardChanges,
    onSuccesfulSave,
    onChangeDefaultPrefs,
    refershFixtures,
  ] = usePaginatedRequest(
    fetchFixtures,
    15,
    options,
    [options, venueChannels?.length],
    Boolean(options?.venueID),
  );

  const noOfChanges = changes?.length || 0;

  const filtersHeader = document.getElementById('filters-sticky-header');
  const filtersHeight = filtersHeader?.getBoundingClientRect().height;
  const appBarHeight = 80;
  const dateTopHeight = `${isDesktop ? filtersHeight : filtersHeight + appBarHeight}px`;

  const {
    activeFilters,
    openHours: {
      initialData: { date: dateOHID },
    },
    dates: {
      initialData: { fromDate: fromDateID, toDate: toDateID },
    },
  } = filters;

  const areThereActiveFilters =
    !!dateOHID || !!fromDateID || !!toDateID || activeFilters?.data?.length > 0;
  const showSelectAllButton =
    filters.popularFixtures || filters.onlyFavourites || !!filters.search || areThereActiveFilters;
  const selectAllButtonDisabled = showSelectAllButton && fixtures.every(f => f?.venueEvent?.active);
  const showSelectAllToast = () => toast.success(t('fixtures_filters_add_all_filtered_toast'));

  const handleSaveClose = () => {
    setShowSaveModal(false);
    refreshVenue(venue.id);
  };

  const selectAll = () =>
    fixtures.map(
      f => !f.venueEvent?.active && updateFixture({ eventId: f.id, feature: KEYS.active }),
    );

  const isLead = () => venueIsLead(venue);

  useHiddenByPermissions(isLead(venue));

  const shouldShowChannelSelector =
    (channelsChanged || selectedChannels?.length === 0) &&
    Object.keys(allChannels).length > 0 &&
    fixtures?.length === 0;
  const showChannelsStyle = shouldShowChannelSelector ? '' : 'hidden';

  return (
    <div className="h-full bg-offWhite">
      <FixturesHeader
        filters={filters}
        dispatch={dispatch}
        fetchPaginated={fetchPaginated}
        hasActiveSearch={filters?.search?.length > 0}
        showSelectAllButton={showSelectAllButton}
        areThereActiveFilters={areThereActiveFilters}
        disabledUI={shouldShowChannelSelector}
        onChangeDefaultPrefs={onChangeDefaultPrefs}
        selectAllFixtures={() => {
          fireEvent(GACategories.FIXTURES, GATags.SELECT_ALL_PRESSED, {
            venueId: venue?.id,
          });
          // If changes have been made show add all modal
          if (changes.length > 0) {
            setShowAddAllFixturesModal(true);
          } else {
            selectAll();
            showSelectAllToast();
          }
        }}
        selectAllButtonDisabled={selectAllButtonDisabled}
      />
      <div className={`${styles.box} ${showChannelsStyle} md:border md:rounded-[48px] md:p-12`}>
        <p className={`${styles.boxTitle} md:text-2xl`}>{t('fixtures.channels.title')}</p>
        <p className="opacity-60 md:text-xl py-4">{t('fixtures.channels.text')}</p>
        <ChannelSelector />
        <div className="flex justify-center">
          <SpinnerButton
            className="bg-secondary rounded px-4 uppercase font-semibold mt-8 py-2"
            onClick={() => {
              fireEvent(GACategories.FIXTURES, GATags.SAVE_CHANNELS);
              saveChannels(venue.id, selectedChannels);
            }}
            loading={isSavingChannels}
          >
            {t('general.saveChannels')}
          </SpinnerButton>
        </div>
      </div>
      {!fetching && !shouldShowChannelSelector && fixtures?.length === 0 && filters.onlyFavourites && (
        <div className={`${styles.box} border rounded-2xl p-8`}>
          <p className={`${styles.boxTitle} headingFont text-4xl`}>
            {t('fixtures_seeFavouritesTitle')}
          </p>
          <div className="opacity-60 pt-2 pb-4">
            <p>{t('fixtures_seeFavouritesText')}</p>
            <p className="pl-4">{`• ${t('fixtures_seeFavouritesItem1')}`}</p>
            <p className="pl-4">{`• ${t('fixtures_seeFavouritesItem2')}`}</p>
          </div>
          <AddFavourites onChange={() => setFavsChanged(true)} />
          {favsChanged && (
            <PlainButton
              className="mt-8 font-semibold py-3 w-full bg-secondary rounded-lg"
              onClick={refershFixtures}
            >
              {t('fixtures_seeFavouritesButton')}
            </PlainButton>
          )}
        </div>
      )}
      <FixturesList
        fixtures={fixtures}
        fetching={fetching}
        hasMore={hasMore}
        fetchNext={fetchNext}
        loadingPage={loadingPage}
        updateFixture={updateFixture}
        bottomBannerDisplayed={Boolean(noOfChanges)}
        dateTopHeight={dateTopHeight}
        hideNoResults={shouldShowChannelSelector || filters.onlyFavourites}
      />
      {noOfChanges > 0 && (
        <FloatingBanner
          text={
            <>
              <span className="font-bold block">
                {t('fixtures.changes', { count: noOfChanges })}
              </span>
              {t('fixtures.changes_subtitle')}
            </>
          }
          button1Text={t('general.save')}
          button1Action={() => {
            fireEvent(GACategories.FIXTURES, GATags.SAVE_FIXTURES);
            setSavingFixtures(true);
            saveFixtures(venue.id, changes)
              .then(() => {
                onSuccesfulSave();
                setShowSaveModal(true);
              })
              .catch(e => {
                if (e.code === 202) {
                  setShowLimitModal(true);
                }
              })
              .finally(() => setSavingFixtures(false));
          }}
          button1Loading={savingFixtures}
          button2Text={t('general.cancel')}
          button2Action={discardChanges}
        />
      )}
      <FixtureLimitModal
        venueId={venue?.id}
        open={showLimitModal && !isLead()}
        onClose={() => setShowLimitModal(false)}
      />
      {showLimitModal && isLead() && (
        <UpgradeModal
          contentOverride={{
            title: 'upgradeModal_fixtureTitle',
            text: 'upgradeModal_fixtureText',
            image: 'fixture-limit.png',
          }}
          testId="choosePlan"
        />
      )}
      <AddAllFixturesModal
        open={showAddAllFixturesModal}
        onClose={() => setShowAddAllFixturesModal(false)}
        onAdd={() => {
          selectAll();
          setShowAddAllFixturesModal(false);
          showSelectAllToast();
        }}
        loading={savingFixtures}
        venueID={venue?.id}
      />
      {getVenueAccessLevel(venue) === VENUE_ACCESS_LEVEL.NO_ACTIVITY ? (
        <FirstFixtureModal open={showSaveModal} onClose={handleSaveClose} venue={venue} />
      ) : (
        <SaveModal open={showSaveModal} onClose={handleSaveClose} />
      )}
    </div>
  );
};

FixturesPage.propTypes = {
  venue: venueProps.isRequired,
  fetchFixtures: PropTypes.func.isRequired,
  selectedChannels: PropTypes.arrayOf(PropTypes.number).isRequired,
  saveChannels: PropTypes.func.isRequired,
  isSavingChannels: PropTypes.bool.isRequired,
  channelsChanged: PropTypes.bool,
  fetchFavouritesFilters: PropTypes.func.isRequired,
  fetchSportsFilters: PropTypes.func.isRequired,
  fetchCompsFilters: PropTypes.func.isRequired,
  saveFixtures: PropTypes.func.isRequired,
  isDesktop: PropTypes.bool.isRequired,
  venueChannels: PropTypes.arrayOf(PropTypes.number),
  refreshVenue: PropTypes.func.isRequired,
  allChannels: PropTypes.shape().isRequired,
};

FixturesPage.defaultProps = {
  channelsChanged: false,
  venueChannels: [],
};

const mapDispatchToProps = {
  fetchFixtures: getFixtures,
  saveChannels: saveVenueChannels,
  fetchFavouritesFilters: getFavouritesFilters,
  fetchSportsFilters: getSportsFilters,
  fetchCompsFilters: getCompsFilters,
  saveFixtures: saveFixturesRequest,
  refreshVenue: refreshVenueThunk,
};

const mapStateToProps = state => ({
  venue: getVenue(state),
  selectedChannels: getVenueSelectedChannels(state),
  isSavingChannels: getIsSavingChannels(state),
  channelsChanged: getChannelsChanged(state),
  isDesktop: getIsDesktop(state),
  venueChannels: getInitiallySelectedChannels(state),
  allChannels: getAllChannels(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(FixturesPage);

import React from 'react';
import PropTypes from 'prop-types';

const SearchIcon = ({ className }) => (
  <svg className={`fill-current ${className}`} viewBox="0 0 24 24">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.4458 5.31608C12.1179 4.69233 9.72516 6.07379 9.10142 8.40164C8.47767 10.7295 9.85912 13.1222 12.187 13.746C14.5148 14.3697 16.9076 12.9883 17.5313 10.6604C18.1551 8.33257 16.7736 5.93983 14.4458 5.31608ZM7.52081 7.97812C8.37846 4.77732 11.6685 2.87782 14.8693 3.73548C18.0701 4.59313 19.9696 7.88315 19.1119 11.0839C18.2543 14.2847 14.9642 16.1842 11.7635 15.3266C8.56265 14.4689 6.66316 11.1789 7.52081 7.97812Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.94177 19.8805L9.60405 13.9009L12.2384 14.6068L9.57612 20.5864L6.94177 19.8805Z"
    />
  </svg>
);

SearchIcon.propTypes = {
  className: PropTypes.string,
};

SearchIcon.defaultProps = {
  className: '',
};

export default SearchIcon;

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { t } from 'i18next';
import Logo from '../../Logo/Logo';
import MainLinks from '../../NavLinks/NavLinks';
import { getUser } from '../../../store/auth/selectors';
import { getVenue } from '../../../store/venue/selectors';
import { userProps, venueProps } from '../../../utils/customPropTypes';
import { getShowSwitchProfiles } from '../../../store/app/selectors';
import { setShowSwitchProfiles } from '../../../store/app/actions';
import routes from '../../../routes/paths';
import ChevronIcon from '../../Icons/ChevronIcon';
import PlainButton from '../../PlainButton/PlainButton';
import CloseIcon from '../../Icons/CloseIcon';
import SwitchProfile from './SwitchProfile';
import LogoutButton from './LogoutButton';

const styles = {
  logoBtn: 'mx-6 mt-8 mb-4 flex h-10 items-center transition-all duration-700',
  closeBtn:
    'bg-brandedGrey-3 absolute top-4 right-4 rounded-full p-2.5 transition-all duration-700',
  profilePic: 'absolute top-8 rounded-full border-2 border-white transition-all duration-700',
  settingsBtn:
    'flex items-center justify-center pb-4 font-semibold text-white transition-all duration-700 rounded-b-2xl border-b border-white/10',
  menuLinks:
    'bg-brandedGrey-1 absolute top-32 flex w-full flex-col overflow-hidden rounded-b-2xl transition-all duration-700',
  venueName:
    'border-white/10 mx-6 border-b pb-4 text-center font-bold text-white overflow-x-hidden transition-all duration-700',
  hide: 'opacity-0 pointer-events-none',
};

const SideBar = ({ venue, member, showSwitchProfiles, onSetSwitchProfiles }) => {
  const canSwitch = member?.venues?.length > 1;

  return (
    <div className="hidden h-full flex-col lg:flex">
      <div className="flex-1">
        <div className="flex flex-col justify-center">
          <div className={`${styles.logoBtn} ${showSwitchProfiles ? styles.hide : ''}`}>
            <Link to={routes.home.path}>
              <Logo small />
            </Link>
          </div>

          <PlainButton
            className={`${styles.closeBtn} ${showSwitchProfiles ? '' : styles.hide}`}
            onClick={() => onSetSwitchProfiles(false)}
          >
            <CloseIcon className="h-7 w-7 text-white" />
          </PlainButton>
          <Link to={routes.profile.path}>
            <img
              className={`${styles.profilePic} ${
                showSwitchProfiles ? 'right-[76px] h-[88px] w-[88px]' : 'right-6 h-10 w-10'
              }`}
              alt={venue?.name}
              src={venue?.profilePicture['200_160']}
            />
          </Link>

          {canSwitch && (
            <PlainButton
              className={`${styles.settingsBtn} ${showSwitchProfiles ? styles.hide : ''}`}
              onClick={() => onSetSwitchProfiles(true)}
            >
              {t('titles.switchProfiles')}
              <ChevronIcon className="ml-2 h-6 w-6 rotate-90" />
            </PlainButton>
          )}
        </div>
        <MainLinks />
      </div>

      <LogoutButton />

      {canSwitch && (
        <div className={`${styles.menuLinks} ${showSwitchProfiles ? 'h-full' : 'h-0'}`}>
          <p className={`${styles.venueName} ${showSwitchProfiles ? '' : styles.hide}`}>
            {venue?.name}
          </p>
          <div className="noScrollBar flex-1 overflow-auto px-6 pb-36">
            <SwitchProfile />
          </div>
        </div>
      )}
    </div>
  );
};

SideBar.propTypes = {
  venue: venueProps,
  member: userProps.isRequired,
  showSwitchProfiles: PropTypes.bool.isRequired,
  onSetSwitchProfiles: PropTypes.func.isRequired,
};

SideBar.defaultProps = {
  venue: null,
};

const mapStateToProps = state => ({
  venue: getVenue(state),
  member: getUser(state),
  showSwitchProfiles: getShowSwitchProfiles(state),
});

const mapDispatchToProps = {
  onSetSwitchProfiles: setShowSwitchProfiles,
};

export default connect(mapStateToProps, mapDispatchToProps)(SideBar);

import React from 'react';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import { venueProps } from '../../utils/customPropTypes';
import MPModal from '../../components/MPModal/MPModal';
import PlainButton from '../../components/PlainButton/PlainButton';
import CloseIcon from '../../components/Icons/CloseIcon';
import { getPreviewUrl } from '../../utils/general';
import { cdnUrl } from '../../utils/constants';

const FirstFixtureModal = ({ open, onClose, venue }) => (
  <MPModal
    open={open}
    onClose={onClose}
    className="max-w-md md:max-w-lg lg:max-w-2xl bg-transparent"
  >
    <div className="rounded-2xl flex flex-col bg-white mx-4 p-8 pt-16 md:p-16 text-center relative">
      <PlainButton
        className="absolute right-4 top-4 p-2 bg-mpGrey rounded-full outline-none"
        onClick={onClose}
      >
        <CloseIcon className="w-8 h-8" />
      </PlainButton>
      <h2 className="headingFont text-4xl md:text-5xl lg:text-6xl py-2">
        {t('signup_venue_firstFixtureModalTitle')}
      </h2>
      <p className="opacity-80 md:text-xl">{t('signup_venue_firstFixtureModalSubtitle')}</p>
      <img
        src={`${cdnUrl}/onboarding/fixture-added.png`}
        className="w-full max-h-64 py-8 object-contain"
        alt=""
      />
      <a
        className="w-full bg-black text-white font-semibold rounded-lg py-4"
        href={getPreviewUrl(venue)}
        onClick={onClose}
      >
        {t('signup_venue_firstFixtureModalButton')}
      </a>
    </div>
  </MPModal>
);

FirstFixtureModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  venue: venueProps.isRequired,
};

FirstFixtureModal.defaultProps = {
  open: false,
};

export default FirstFixtureModal;

import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import i18n from 'i18next';
import ChevronIcon from '../Icons/ChevronIcon';
import { useDetectOutsideClick } from '../../utils/CustomHooks';

const styles = {
  button: disabled =>
    `inline-flex items-center justify-between outline-none capitalize shadow rounded pl-3 pr-2 ${
      !disabled ? '' : 'bg-mpGrey'
    }`,
  list:
    'w-full absolute z-10 mt-1 py-1 max-h-56 rounded bg-white shadow-lg text-xs overflow-auto animate-dropdown',
  item: 'w-full select-none py-2 p-3 capitalize hover:bg-veryLightGrey',
  chevron: (menu, disabled) =>
    `w-5 h-5 ml-3 ${menu ? '-rotate-90' : 'rotate-90'} ${!disabled ? '' : 'text-lightGrey'}`,
};

const Dropdown = ({
  data,
  selected,
  buttonText,
  label,
  error,
  onClickItem,
  containerStyle,
  buttonStyle,
  disabled,
  isMulti,
  nullableValue,
}) => {
  const [menu, setMenu] = useState(false);
  const ref = useRef();
  useDetectOutsideClick(ref, () => setMenu(false));

  const value = !isMulti ? selected?.value : selected?.join(', ');
  const items = nullableValue ? [{ key: null, value: null }, ...data] : data;
  const selectedStyle = val => (!isMulti ? val === value : selected.includes(val));

  return (
    <div className={`relative ${containerStyle}`} ref={ref}>
      {label && <p className="text-xs text-standardGrey absolute -top-4 left-1">{label}</p>}
      <button
        data-test-id="dropdown"
        type="button"
        className={`${styles.button(disabled)} ${buttonStyle}`}
        onClick={() => setMenu(!menu)}
        disabled={disabled}
      >
        <span className="truncate">{value || buttonText}</span>
        <ChevronIcon className={styles.chevron(menu, disabled)} />
      </button>

      {menu && (
        <ul className={styles.list}>
          {items?.map(item => (
            <button
              key={item.key}
              type="button"
              data-test-id={item.value}
              className={`${styles.item} ${selectedStyle(item.value) ? 'bg-lightGrey' : ''}`}
              onClick={() => {
                onClickItem(item);
                if (!isMulti) {
                  setMenu(!menu);
                }
              }}
              disabled={!isMulti && item.value === value}
            >
              {item.value || '---'}
            </button>
          ))}
        </ul>
      )}
      {error && <p className="text-xs text-primaryRed absolute -bottom-4 left-1">{error}</p>}
    </div>
  );
};

const keyValue = PropTypes.shape({
  key: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
});

Dropdown.propTypes = {
  containerStyle: PropTypes.string,
  buttonStyle: PropTypes.string,
  buttonText: PropTypes.string,
  label: PropTypes.string,
  error: PropTypes.string,
  data: PropTypes.arrayOf(keyValue),
  selected: PropTypes.oneOfType([keyValue, PropTypes.arrayOf(PropTypes.string)]),
  onClickItem: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  isMulti: PropTypes.bool,
  nullableValue: PropTypes.bool,
};

Dropdown.defaultProps = {
  data: [],
  containerStyle: '',
  buttonStyle: '',
  buttonText: i18n.t('general.select'),
  label: '',
  error: '',
  selected: null,
  disabled: false,
  isMulti: false,
  nullableValue: false,
};

export default Dropdown;

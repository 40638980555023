import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import FacebookButton from '../FacebookButton/FacebookButton';
import { getSocialAccounts } from '../../store/socialMedia/selectors';
import { fetchSocialMediaAccounts } from '../../store/socialMedia/thunks';
import { getVenue } from '../../store/venue/selectors';
import { venueProps } from '../../utils/customPropTypes';

const SocialMediaButtons = ({
  accounts,
  fetchAccounts,
  venue,
  toggleable,
  onFacebookToggle,
  facebookSelected,
}) => {
  useEffect(() => {
    fetchAccounts(venue.id, true, true);
  }, [fetchAccounts, venue.id]);

  return (
    <FacebookButton
      venueID={venue.id}
      account={accounts?.facebook}
      onToggle={onFacebookToggle}
      selected={facebookSelected}
      toggleable={toggleable}
    />
  );
};

SocialMediaButtons.propTypes = {
  accounts: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.shape()), PropTypes.shape()]),
  fetchAccounts: PropTypes.func.isRequired,
  venue: venueProps.isRequired,
  toggleable: PropTypes.bool,
  onFacebookToggle: PropTypes.func,
  facebookSelected: PropTypes.bool,
};

SocialMediaButtons.defaultProps = {
  toggleable: false,
  facebookSelected: false,
  onFacebookToggle: () => {},
  accounts: null,
};

const mapStateToProps = state => ({
  accounts: getSocialAccounts(state),
  venue: getVenue(state),
});

const mapDispatchToProps = dispatch => ({
  fetchAccounts: (venueId, facebook, twitter) =>
    dispatch(fetchSocialMediaAccounts(venueId, facebook, twitter)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SocialMediaButtons);

import React from 'react';
import PropTypes from 'prop-types';

const HomeIcon = ({ className, isActive }) => (
  <svg className={`stroke-current fill-current ${className}`} viewBox="0 0 24 24" fill="none">
    {isActive ? (
      <path
        d="M14.2105 3.81824L5.08834 9.57424L3.35484 20.5191H7.47444L8.15707 16.2092C8.51051 13.9777 10.6286 12.1687 12.8879 12.1687C15.1473 12.1687 16.6923 13.9777 16.3389 16.2092L15.6563 20.5191H19.7759L21.5094 9.57424L14.2105 3.81824Z"
        strokeWidth="2.18182"
      />
    ) : (
      <path
        d="M14.2105 3.81824L5.08834 9.57424L3.35484 20.5191H7.47444L8.15707 16.2092C8.51051 13.9777 10.6286 12.1687 12.8879 12.1687C15.1473 12.1687 16.6923 13.9777 16.3389 16.2092L15.6563 20.5191H19.7759L21.5094 9.57424L14.2105 3.81824Z"
        strokeWidth="2.18182"
        fill="none"
      />
    )}
  </svg>
);

HomeIcon.propTypes = {
  className: PropTypes.string,
  isActive: PropTypes.bool,
};

HomeIcon.defaultProps = {
  className: '',
  isActive: false,
};

export default HomeIcon;

import { getURLQuery } from '../../utils/general';
import { request } from './request';

export const getFixtures = ({ venueID, ...rest }) => dispatch =>
  dispatch(
    request(
      `venues/${venueID}/fixtures${getURLQuery({
        ...rest,
        numberOfMatches: 1,
      })}`,
    ),
  );

export const getListFixtures = (venueID, offset, limit) => dispatch =>
  dispatch(
    request(
      `venues/${venueID}/fixtures?limit=${limit}&numberOfMatches=1&offset=${offset}&sport=&onlyScheduled=true`,
    ),
  );

export const saveFixturesRequest = (venueID, fixtures) => dispatch =>
  dispatch(
    request(`venues/${venueID}/fixtures/save`, {
      body: {
        fixtures,
      },
      method: 'POST',
    }),
  );

export const fetchWeeklyFixtureLimit = (venueId, from, to) => dispatch =>
  dispatch(request(`venues/${venueId}/fixtures-per-week${`${getURLQuery({ from, to })}`}`));

export const getFavouritesFilters = (venueId, limit) => async dispatch =>
  dispatch(
    request(
      `venues/${venueId}/fixtures/favourites-filter${getURLQuery({
        limit,
      })}`,
    ),
  );

export const getSportsFilters = ({ venueId, limit, offset }) => async dispatch =>
  dispatch(
    request(
      `venues/${venueId}/fixtures/sports-filter${getURLQuery({
        limit,
        offset,
      })}`,
    ),
  );

export const getCompsFilters = ({ venueId, limit, offset, sportId }) => async dispatch =>
  dispatch(
    request(
      `venues/${venueId}/fixtures/competitions-by-sport${getURLQuery({
        sportId,
        limit,
        offset,
      })}`,
    ),
  );

export const getTrendingFilters = (venueId, params = {}) => dispatch =>
  dispatch(request(`venues/${venueId}/fixtures/trending-filter${`${getURLQuery(params)}`}`));

import React from 'react';
import PropTypes from 'prop-types';

const CardIcon = ({ className }) => (
  <svg className={`fill-current ${className}`} viewBox="0 0 24 24">
    <path d="M15.6804 13.7892C15.6804 14.2871 15.2768 14.6906 14.779 14.6906C14.2812 14.6906 13.8777 14.2871 13.8777 13.7892C13.8777 13.2914 14.2812 12.8879 14.779 12.8879C15.2768 12.8879 15.6804 13.2914 15.6804 13.7892Z" />
    <path d="M18.1808 13.7892C18.1808 14.2871 17.7772 14.6906 17.2794 14.6906C16.7816 14.6906 16.3781 14.2871 16.3781 13.7892C16.3781 13.2914 16.7816 12.8879 17.2794 12.8879C17.7772 12.8879 18.1808 13.2914 18.1808 13.7892Z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.4635 9.29613L22.0037 5.69043H4.00496L3.40025 9.29613L2.00049 18.3096H20.0275L21.4635 9.29613ZM3.57868 16.9576L4.55852 10.6482H19.8791L18.8738 16.9576H3.57868ZM20.299 7.94411L20.4341 7.04246H5.14912L4.9979 7.94411H20.299Z"
    />
  </svg>
);

CardIcon.propTypes = {
  className: PropTypes.string,
};

CardIcon.defaultProps = {
  className: '',
};

export default CardIcon;

import React from 'react';
import { Router, Route, Switch, Prompt, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import i18n from 'i18next';
import LoginPage from '../pages/LoginPage/LoginPage';
import ResetPasswordPage from '../pages/ResetPasswordPage/ResetPasswordPage';
import { getHasUnsavedChanges } from '../store/navigation/selectors';
import Prices from '../pages/Prices/Prices';
import history from './history';
import ActivationPage from '../pages/ActivationPage/ActivationPage';
import { publicRoutes } from './paths';
import { routes as loggedInRoutes } from './routeConfig';
import StrapiPage from '../pages/Strapi/StrapiPage';
import SignupV2 from '../pages/SignupV2/Stage1';
import ActivateNewUser from '../pages/ActivateNewUser/ActivateNewUser';

const homePage = ({ hasUnsavedChanges }) => {
  window.onbeforeunload = () => (hasUnsavedChanges ? true : undefined);
  return (
    <Router history={history}>
      <>
        <Switch>
          <Route exact path={publicRoutes.landing} component={LoginPage} />
          <Route exact path="/reset-password/:token" component={ResetPasswordPage} />
          <Route exact path={publicRoutes.pricing} component={Prices} />
          <Route exact path={publicRoutes.activate} component={ActivationPage} />
          <Route exact path={publicRoutes.activateNewUser} component={ActivateNewUser} />
          <Route exact path={publicRoutes.login} component={LoginPage} />
          <Route exact path={publicRoutes.signupV2} component={SignupV2} />
          <Route
            exact
            path="/index.html"
            render={() => <Redirect to={{ pathname: publicRoutes.landing }} />}
          />
          {Object.values(loggedInRoutes).map(route => (
            <Route key={route.path} path={route.path} component={LoginPage} />
          ))}
          <Route component={StrapiPage} />
        </Switch>
        <Prompt when={hasUnsavedChanges} message={i18n.t('navigation.leave')} />
      </>
    </Router>
  );
};

const mapStateToProps = state => ({
  hasUnsavedChanges: getHasUnsavedChanges(state),
});

homePage.propTypes = {
  hasUnsavedChanges: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps)(homePage);

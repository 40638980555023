import React from 'react';

const OutdoorScreen = () => (
  <svg width="26" height="24" viewBox="0 0 26 24" fill="none">
    <rect
      width="13.0887"
      height="8.18043"
      transform="matrix(1 0 -0.156434 0.987688 10.9966 9.70898)"
      stroke="black"
      strokeWidth="1.63609"
    />
    <path d="M12.1025 19.9434L19.7382 19.9434" stroke="black" strokeWidth="1.63609" />
    <path
      d="M12.3428 8.09243C12.5784 6.60512 11.5486 5.39941 10.0428 5.39941C8.5369 5.39941 7.1252 6.60512 6.88963 8.09243C6.65406 9.57975 7.68383 10.7855 9.18969 10.7855"
      stroke="black"
      strokeWidth="1.3634"
    />
    <path d="M10.3847 3.24593L10.7261 1.09082" stroke="black" strokeWidth="1.09072" />
    <path d="M4.70908 8.09277L2.5271 8.09277" stroke="black" strokeWidth="1.09072" />
    <path d="M6.96845 4.99218L5.47632 3.24512" stroke="black" strokeWidth="1.09072" />
    <path d="M5.96551 11.3242L3.89819 13.21" stroke="black" strokeWidth="1.09072" />
    <path d="M13.3791 4.99223L15.5652 3.24523" stroke="black" strokeWidth="1.09072" />
  </svg>
);

export default OutdoorScreen;

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { t } from 'i18next';
import { getServicesEndpoint } from '../../utils/environment';
import { getFormattedDateRange, getURLQuery } from '../../utils/general';
import { getAssetGroup } from '../../store/api/venues';
import { getVenueID } from '../../store/venue/selectors';
import LoadingAnimation from '../../components/LoadingAnimation/LoadingAnimation';
import AssetTile from './AssetTile';
import PlainButton from '../../components/PlainButton/PlainButton';
import BackIcon from '../../components/Icons/BackIcon';
import { historyProps } from '../../utils/customPropTypes';
import styles from './AssetGroupPage.module.scss';
import ImagePreviewModal from './ImagePreviewModal';
import AssetTipsModal from './AssetTipsModal';
import SimpleCheckbox from '../../components/Checkbox/SimpleCheckbox';
import MegaPhoneIcon from '../../components/Icons/MegaPhoneIcon';
import { getByType } from './utils';
import { fireEvent, GATags } from '../../utils/trackingHelper';

const filters = ['insta_post', 'fb_post', 'x_post', 'fb_insta_story'];
const activeFilterStyle = condition =>
  condition ? 'border-quaternary bg-secondaryLight font-medium' : 'border-mpGrey';

const AssetGroupPage = ({ venueId, fetchAssets, match, history }) => {
  const [assets, setAssets] = useState(null);
  const [title, setTitle] = useState(null);
  const [loading, setLoading] = useState(true);
  const [imgPreview, setImgPreview] = useState(null);
  const [tipsModal, setTipModal] = useState(null);
  const [activeFilter, setActiveFilter] = useState(null);
  const groupId = match?.params?.id;

  const activeAssets = assets
    ?.map(asset => (activeFilter === asset.assetPlatform || !activeFilter) && asset)
    .filter(Boolean);

  const selectedToDownload = assets?.filter(a => a.selected);
  const selectedQty = selectedToDownload?.length || null;
  const hasSelected = selectedQty > 0;

  const url = `${getServicesEndpoint()}meta/${venueId}`;
  const downloadOne = assetId => `${url}/asset-download?assetId=${assetId}`;
  const downloadMulti = assetIds => `${url}/asset-multi-download${getURLQuery({ assetIds })}`;

  const trackFilter = filter =>
    fireEvent(GATags.PROMOHUB_FILTER_CLICKED, null, {
      venueId,
      filter,
    });

  useEffect(() => {
    fetchAssets(venueId, groupId)
      .then(res => {
        setAssets(res.getResult().assetImages);
        setTitle(res.getResult().name);
      })
      .finally(() => setLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupId, venueId]);

  if (loading) {
    return <LoadingAnimation size={56} />;
  }

  return (
    <>
      <div className={styles.header}>
        <PlainButton className="flex flex-1 items-center" onClick={() => history.goBack()}>
          <BackIcon className="w-6 h-6 sm:w-8 sm:h-8" />
          <p className="text-xl font-semibold ml-2 hidden sm:flex">{t('general_back')}</p>
        </PlainButton>
        <h1 className="headingFont text-2xl sm:text-4xl">{title}</h1>
        <div className="flex-1" />
      </div>
      {assets?.length > 0 && (
        <>
          <div className={`${styles.header} overflow-auto`}>
            <PlainButton
              className={`${styles.filterBtn} ${activeFilterStyle(!activeFilter)}`}
              onClick={() => {
                trackFilter('all');
                setActiveFilter(null);
              }}
            >
              {t('general_all')}
            </PlainButton>
            {filters.map((filter, i) => (
              <PlainButton
                className={`${styles.filterBtn} ${activeFilterStyle(activeFilter === filter)}`}
                onClick={() => {
                  trackFilter(filters[i]);
                  setActiveFilter(filters[i]);
                }}
                key={filter}
              >
                {getByType(filter).text}
              </PlainButton>
            ))}
          </div>
          <p className={styles.description}>{t('promoHub_assetGroup_title')}</p>
          <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-4 px-4 sm:px-6 lg:px-10 mb-40 sm:mb-28">
            {activeAssets.map(d => (
              <div
                key={d.name}
                className={`flex flex-col outline bg-offWhite rounded-2xl ${
                  d.selected ? 'outline-2 outline-secondary' : 'outline-mpGrey'
                }`}
              >
                <div className="relative z-10">
                  <PlainButton
                    className="absolute top-4 left-4 bg-secondary py-1 px-2 rounded-md flex"
                    onClick={() => {
                      fireEvent(GATags.PROMOHUB_TIPS_CLICKED, null, {
                        venueId,
                        assetId: d.id,
                        groupId,
                      });
                      setTipModal(d);
                    }}
                  >
                    <MegaPhoneIcon className="text-black h-4 mr-1" />
                    <p className="text-xs font-semibold">{t('promoHub_promotionTips')}</p>
                  </PlainButton>
                  <SimpleCheckbox
                    className="absolute top-5 right-5 before:border-white checked:before:border-secondary scale-150"
                    variant="secondary"
                    onChange={() =>
                      setAssets(prevData =>
                        prevData.map(asset =>
                          asset.id === d.id ? { ...asset, selected: !asset.selected } : asset,
                        ),
                      )
                    }
                    checked={Boolean(d.selected)}
                  />
                </div>
                <PlainButton
                  onClick={() => {
                    fireEvent(GATags.PROMOHUB_PREVIEW_CLICKED, null, {
                      venueId,
                      assetId: d.id,
                      groupId,
                    });
                    setImgPreview(d);
                  }}
                >
                  <AssetTile
                    data={{
                      image: d.assetImage,
                      title: d.name,
                      line1: getFormattedDateRange(d.fixturesStartDate, d.fixturesEndDate),
                      line2: getByType(d.assetPlatform).text,
                      type: d.assetPlatform,
                    }}
                  />
                </PlainButton>
              </div>
            ))}
          </div>
          <div className={styles.banner}>
            <PlainButton
              className={`${styles.footerBtn} ${hasSelected ? '' : 'opacity-30'} bg-secondary`}
              disabled={!hasSelected}
              onClick={() => {
                const assetIds = selectedToDownload.map(a => a.id);
                window.open(selectedQty > 1 ? downloadMulti(assetIds) : downloadOne(assetIds[0]));
                fireEvent(GATags.PROMOHUB_DOWNLOAD, null, { venueId, assetIds, groupId });
              }}
            >
              {t('general_downloadSelected', { count: selectedQty })}
            </PlainButton>
            <PlainButton
              className={`${styles.footerBtn} bg-mpGrey`}
              onClick={() => {
                window.open(downloadMulti(activeAssets.map(a => a.id)));
                fireEvent(GATags.PROMOHUB_DOWNLOAD, null, { venueId, all: true, groupId });
              }}
            >
              {t('general_downloadAll')}
            </PlainButton>
          </div>
        </>
      )}

      <ImagePreviewModal
        onChange={setImgPreview}
        viewTips={() => setTipModal(imgPreview)}
        data={imgPreview}
        assets={assets}
      />
      <AssetTipsModal onClose={() => setTipModal(null)} data={tipsModal} />
    </>
  );
};

AssetGroupPage.propTypes = {
  venueId: PropTypes.number.isRequired,
  fetchAssets: PropTypes.func.isRequired,
  match: PropTypes.shape().isRequired,
  history: historyProps.isRequired,
};

const mdtp = {
  fetchAssets: getAssetGroup,
};

const mstp = state => ({
  venueId: getVenueID(state),
});

export default connect(mstp, mdtp)(AssetGroupPage);

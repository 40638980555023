import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { t } from 'i18next';
import { genericRequest } from '../../store/api/request';
import { getVenueID } from '../../store/venue/selectors';
import routes from '../../routes/paths';
import { userIsOnOnTap } from '../../utils/environment';
import ChevronIcon from '../../components/Icons/ChevronIcon';
import { fixtureProps } from '../../utils/customPropTypes';
import { getLocaleDate } from '../../i18n';
import CalendarIcon from '../../components/Icons/CalendarIcon';
import LoadingAnimation from '../../components/LoadingAnimation/LoadingAnimation';
import MegaPhoneIcon from '../../components/Icons/MegaPhoneIcon';
import { GATags, fireEvent } from '../../utils/trackingHelper';

const AbstractLink = ({ to, className, children, onClick }) => {
  if (to?.startsWith('http')) {
    return (
      <a onClick={onClick} href={to} className={className}>
        {children}
      </a>
    );
  }
  return (
    <Link onClick={onClick} to={to} className={className}>
      {children}
    </Link>
  );
};

const actionMap = action => {
  switch (action) {
    case 'addFixtures':
      return { Logo: CalendarIcon, buttonText: t('home_actions_addMore') };
    case 'marketing':
    case 'promotionalHub':
      return { Logo: MegaPhoneIcon, buttonText: t('home_actions_promote') };
    case 'purchaseFixtures':
      return { Logo: CalendarIcon, buttonText: t('home_actions_purchase') };
    default:
      return { Logo: CalendarIcon, buttonText: '' };
  }
};

const FixturesAndActionsTile = ({ generic, venueID }) => {
  const [fixtures, setFixtures] = useState(null);
  const [actions, setActions] = useState(null);

  useEffect(() => {
    generic(`/venues/${venueID}/dashboard/actions`).then(r => setActions(r.getResult()));
  }, [generic, venueID]);

  useEffect(() => {
    generic(`venues/${venueID}/fixtures`, { venueID, onlyScheduled: true, limit: 5 }).then(r =>
      setFixtures(r.getResult()),
    );
  }, [generic, venueID]);

  if (fixtures === null) {
    return <LoadingAnimation />;
  }

  // this is a bit of a hack for the no fixtures state on joe hands
  const addFixtureAction =
    actions?.find(f => f.action === 'addFixtures') ||
    actions?.find(f => f.action === 'purchaseFixtures');

  return (
    <div className="bg-white border border-mpGrey rounded-2xl p-4 sm:p-6 lg:p-8 flex flex-col lg:w-1/2">
      {fixtures?.length > 0 ? (
        <>
          <div className="flex items-center">
            <h2 className="headingFont opacity-60 text-xl sm:text-3xl lg:text-4xl flex-1">
              {t('home_yourFixtures')}
            </h2>
            {!userIsOnOnTap && (
              <Link
                onClick={() => fireEvent(GATags.HOME_FIXTURES_SCHEDULE, undefined, { venueID })}
                className="text-xs font-semibold border border-mpGrey rounded-2xl flex px-4 py-2 items-center"
                to={routes.fixtures.subRoutes.mySchedule.path}
              >
                {t('home_schedule')}
                <ChevronIcon className="ml-2 h-4" />
              </Link>
            )}
          </div>
          <div className="flex-1 overflow-auto">
            <div className="overflow-auto flex lg:block noScrollBar">
              {fixtures?.map(f => (
                <Fixture key={f.id} fixture={f} />
              ))}
            </div>
          </div>
          <div className="mt-4 border-t border-mpGrey pt-3">
            <div className="flex gap-3">
              {actions?.map((a, i) => {
                const { Logo, buttonText } = actionMap(a.action);
                return (
                  <AbstractLink
                    onClick={() =>
                      fireEvent(GATags.HOME_FIXTURES_CTA, null, {
                        venueID,
                        action: a.action,
                      })
                    }
                    key={a.action}
                    to={a.target}
                    className={`flex rounded-xl text-black flex-1 items-center text-xs sm:text-base font-medium justify-center py-2 ${
                      i === 0 ? 'bg-secondary' : 'bg-mpGrey'
                    }`}
                  >
                    <Logo className="text-black h-8 mr-2" />
                    {buttonText}
                  </AbstractLink>
                );
              })}
            </div>
          </div>
        </>
      ) : (
        <div className="flex justify-center items-center flex-1">
          <div className="max-w-xs flex items-center flex-col">
            <CalendarIcon className="w-12 h-12 mb-6" />
            <p className="headingFont text-3xl md:text-4xl pb-4 text-center">
              {t('home_noFixtures_title')}
            </p>
            <div className="text-sm md:text-base lg:text-xl opacity-60 text-center">
              <p className="pb-4">{t('home_fixtures_subText1')}</p>
            </div>
            {addFixtureAction && (
              <AbstractLink
                onClick={() =>
                  fireEvent(GATags.HOME_FIXTURES_CTA, null, {
                    venueID,
                    action: addFixtureAction.action,
                  })
                }
                className="bg-secondary font-semibold w-full text-center rounded-lg py-2"
                to={addFixtureAction.target}
              >
                {actionMap(addFixtureAction.action).buttonText}
              </AbstractLink>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

const formats = () => {
  if (userIsOnOnTap) {
    return ['MMM. do', 'h:mm a'];
  }
  return ['do MMM.', 'HH:mm'];
};

const Fixture = ({ fixture: { teams, name, startTimeUtc, competition, competitionOverride } }) => {
  const [dateF, timeF] = formats();
  return (
    <div className="mt-4 mr-4 lg:mr-0 rounded-lg border border-veryLightGrey min-w-[275px]">
      <div className="flex justify-between bg-[#FAFAFA] p-2 rounded-t-lg">
        <p className="text-xs font-medium uppercase text-[#969696] line-clamp-1 flex-1">
          {competitionOverride || competition?.name}
        </p>
        <div className="flex items-center">
          <p className="text-xs font-medium uppercase">{getLocaleDate(startTimeUtc, dateF)}</p>
          <p className="ml-2 rounded-full bg-black py-px px-2 text-xs font-semibold text-secondary">
            {getLocaleDate(startTimeUtc, timeF)}
          </p>
        </div>
      </div>
      {teams?.length === 2 ? (
        teams?.map((team, i) => (
          <div
            key={team?.id}
            className={`mx-2 flex items-center p-2 text-xs font-bold ${
              i === 0 ? 'border-b border-veryLightGrey' : ''
            }`}
          >
            <img alt="" src={team?.logo} className="bg-transparent p-px w-4 h-4 lg:w-6 lg:h-6" />
            <p className="pl-2">{team.name}</p>
          </div>
        ))
      ) : (
        <p className="p-2 text-xs font-bold">{name}</p>
      )}
    </div>
  );
};

Fixture.propTypes = {
  fixture: fixtureProps.isRequired,
};

FixturesAndActionsTile.propTypes = {
  generic: PropTypes.func.isRequired,
  venueID: PropTypes.number.isRequired,
};

AbstractLink.propTypes = {
  to: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.string.isRequired,
  onClick: PropTypes.func,
};

AbstractLink.defaultProps = {
  onClick: undefined,
};

const mapDispatchToProps = {
  generic: genericRequest,
};

const mapStateToProps = state => ({
  venueID: getVenueID(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(FixturesAndActionsTile);

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import i18next from 'i18next';
import PlainButton from '../PlainButton/PlainButton';
import ClockIcon from '../Icons/ClockIcon';
import TickIcon from '../Icons/TickIcon';
import { getIsMobile, getIsTablet } from '../../store/app/selectors';
import AddFixtureButton from './AddFixtureButton/AddFixtureButton';
import { fixtureProps } from '../../utils/customPropTypes';
import { getLocaleDate } from '../../i18n';

const SelectorFixtureRow = ({
  fixture,
  addFixture,
  deleteFixture,
  active,
  isMobile,
  isTablet,
  addDisabled,
}) => {
  const themeClass = 'fixtureList_table_cell_background';
  const activeClass = active && isMobile ? 'fixtureList_table_cell_active' : '';
  const actionFunction = () => (active ? deleteFixture(fixture) : addFixture(fixture));
  const isDisabled = !active && addDisabled;
  const disabledClass = isDisabled ? 'fixtureList_table_cell_disabled' : '';
  return (
    <PlainButton
      className="w-full text-left"
      onClick={isMobile ? () => actionFunction() : () => {}}
      disabled={isDisabled}
    >
      <div
        className={`fixtureList_table_cell_col_100 ${themeClass} ${activeClass} ${disabledClass}`}
      >
        <div className="fixtureList_table_cell_col_5 fixtureList_table_cell_noBorder fixtureList_selector" />
        {!isTablet && (
          <div className="fixtureList_table_cell_col_20 fixtureList_table_cell_noBorder fixtureList_selector">
            <div>
              {fixture.postAlreadyScheduled && !fixture.postAlreadySent && (
                <p className="fixtureList_selector_text">{i18next.t('fixtures.scheduled')}</p>
              )}
              {fixture.postAlreadySent && (
                <p className="fixtureList_selector_text">{i18next.t('fixtures.posted')}</p>
              )}
              <p>{fixture.sport.name.toUpperCase()}</p>
            </div>
          </div>
        )}
        <div className="fixtureList_table_cell_noBorder fixtureList_selector fixtureList_table_cell_col_60">
          <div>
            {isTablet && (
              <div className="fixtureList_selector_tabletNameContainer">
                <p>{fixture.sport.name.toUpperCase()} </p>
                {fixture.postAlreadyScheduled && !fixture.postAlreadySent && (
                  <ClockIcon className="mx-2 text-secondary h-5 w-5" />
                )}
                {fixture.postAlreadySent && <TickIcon className="h-6 w-6 mx-2 text-secondary" />}
              </div>
            )}

            <p className="text-standardGrey text-sm">
              {getLocaleDate(fixture.startTimeUtc, 'HH:mm')} - {fixture.channels[0].name}
            </p>
            <p className="text-sm font-semibold">{fixture.name}</p>
            <p className="text-sm">{fixture?.competitionOverride || fixture?.competition?.name}</p>
          </div>
        </div>
        {isMobile && (
          <div className="fixtureList_table_cell_noBorder fixtureList_selector fixtureList_table_cell_col_10">
            {active && <TickIcon className="h-6 w-6" />}
          </div>
        )}
        {!isMobile && (
          <div
            className={`fixtureList_table_cell_noBorder fixtureList_selector fixtureList_table_cell_col_10 AddRemoveButton ${themeClass}`}
          >
            <AddFixtureButton
              isUpdating={false}
              active={active}
              onClick={actionFunction}
              disabled={isDisabled}
            />
          </div>
        )}
        <div className="fixtureList_table_cell_noBorder fixtureList_selector fixtureList_table_cell_col_5" />
      </div>
    </PlainButton>
  );
};

SelectorFixtureRow.propTypes = {
  fixture: fixtureProps.isRequired,
  addFixture: PropTypes.func.isRequired,
  deleteFixture: PropTypes.func.isRequired,
  active: PropTypes.bool.isRequired,

  isMobile: PropTypes.bool.isRequired,
  isTablet: PropTypes.bool.isRequired,
  addDisabled: PropTypes.bool,
};

SelectorFixtureRow.defaultProps = {
  addDisabled: false,
};

const mapStateToProps = state => ({
  isMobile: getIsMobile(state),
  isTablet: getIsTablet(state),
});

export default connect(mapStateToProps)(SelectorFixtureRow);

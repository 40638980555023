import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import i18n from 'i18next';
import { connect } from 'react-redux';
import LoadingAnimation from '../../components/LoadingAnimation/LoadingAnimation';
import SpinnerButton from '../../components/SpinnerButton/SpinnerButton';
import {
  getFinancialInitialValues,
  getFinancialUpdatedValues,
  getFinancialsFormDesc,
  getFinancialsErrors,
  getIsFetchingFinancials,
  getIsUpdatingFinancialValues,
} from '../../store/financials/selectors';
import { updateFieldValue, discardFormChanges } from '../../store/financials/actions';
import {
  fetchFormDescriptionForFinancials,
  fetchFormValuesForFinancials,
  saveFormValuesForFinancials,
} from '../../store/financials/thunks';
import { bankSortCodeCheck, eightDigitsBankAccNumberCheck } from '../../utils/FormValidation';
import { getVenueID } from '../../store/venue/selectors';
import { fireEvent, GACategories, GATags } from '../../utils/trackingHelper';
import ContentBlock from '../BillingPage/ContentBlock';
import PlainButton from '../../components/PlainButton/PlainButton';
import CloseIcon from '../../components/Icons/CloseIcon';
import MPModal from '../../components/MPModal/MPModal';
import DropdownSelector from '../../components/DropdownSelector/DropdownSelector';
import Input from '../SignupV2/Input';

const fieldValidationErrors = values => ({
  sortCode: !bankSortCodeCheck(values.sortCode) && i18n.t('financials.error.sortCodeFormat'),
  accountNumber:
    !eightDigitsBankAccNumberCheck(values.accountNumber) &&
    i18n.t('financials.error.accountNumberFormat'),
});

const FinancialsForm = ({
  venueId,
  initialValues,
  updatedValues,
  errors,
  formDescription,
  isFetching,
  isUpdatingValues,
  fetchFormDescription,
  fetchFormValues,
  saveFormValues,
  updateValue,
  discardChanges,
}) => {
  const [editModalOpen, setEditModalOpen] = useState(false);
  const { label, options } = formDescription?.type || {};

  useEffect(() => {
    fetchFormDescription(venueId);
    fetchFormValues(venueId);
  }, [fetchFormDescription, fetchFormValues, venueId]);

  const editingMode = initialValues.id ? 'update' : 'add';

  const getFormError = field =>
    fieldValidationErrors(updatedValues)?.[field] || errors?.[field]?.[0];

  const createInputComponent = field => (
    <div key={field} className="pt-2">
      <Input
        error={getFormError(field)}
        placeholder={formDescription[field].label}
        label={formDescription[field].label}
        maxLength={formDescription[field]?.options?.maxLength}
        onChange={val => updateValue(field, val)}
        value={updatedValues[field]}
      />
    </div>
  );

  if (isFetching) {
    return <LoadingAnimation />;
  }

  const onClose = () => {
    discardChanges();
    setEditModalOpen(false);
  };

  const hasDetails = Object.keys(updatedValues).length > 0;

  return editModalOpen ? (
    <MPModal open onClose={onClose} className="bg-transparent">
      <div className="relative bg-white rounded-2xl mx-4 md:max-w-md md:mx-auto lg:max-w-lg">
        <PlainButton
          className="absolute top-4 right-4 p-2 bg-mpGrey rounded-full"
          onClick={onClose}
        >
          <CloseIcon className="w-8 h-8" />
        </PlainButton>
        <div className="p-8 pt-20">
          <h3 className="headingFont text-5xl text-center">
            {i18n.t('financials.updateReimbursements.details')}
          </h3>
          {formDescription.name && createInputComponent('name')}
          {formDescription.type && (
            <div className="pt-2">
              <DropdownSelector
                onValueChange={v => updateValue('type', v.key)}
                label={label}
                selected={options.enum[updatedValues.type]}
                options={[
                  { key: undefined, value: i18n.t('financials.reimbursement.chooseType') },
                  ...Object.keys(options.enum).map(option => ({
                    key: option,
                    value: options.enum[option],
                  })),
                ]}
              />
              {getFormError('type') && (
                <p className="text-xs text-lightRed pt-1">{getFormError('type')}</p>
              )}
            </div>
          )}

          {Object.keys(formDescription).map(
            field => field !== 'name' && field !== 'type' && createInputComponent(field),
          )}
          <SpinnerButton
            className="bg-secondary px-4 py-2 capitalise font-semibold rounded-lg w-full mt-4"
            text={i18n.t('general.saveChanges')}
            loading={isUpdatingValues}
            onClick={() => {
              saveFormValues(venueId, { bankDetails: updatedValues }, editingMode);
              fireEvent(GACategories.SETTINGS, GATags.FINANCIAL_DETAILS);
            }}
          >
            {i18n.t('general.save')}
          </SpinnerButton>
        </div>
      </div>
    </MPModal>
  ) : (
    <ContentBlock
      buttonText="settings_passUpdateBtn"
      onClick={() => setEditModalOpen(true)}
      title={
        hasDetails ? 'financials_reimbursements_details' : 'financials_reimbursements_addDetails'
      }
      showButton={hasDetails}
    >
      {hasDetails ? (
        <div className="opacity-60">
          <p>{updatedValues.name}</p>
          <p>Account number/ IBAN / Kontonummer</p>
          <p>
            {updatedValues.iban || updatedValues.accountNumber || updatedValues.ausAccountNumber}
          </p>
          <p>Sort code / SWIFT / BIC / BLZ</p>
          <p>{updatedValues.sortCode || updatedValues.swiftCode || updatedValues.bsbNumber}</p>
        </div>
      ) : (
        <>
          <p className="opacity-60">{i18n.t('financials.empty.title')}</p>
          <PlainButton
            onClick={() => setEditModalOpen(true)}
            className="bg-secondary w-full py-3 font-semibold rounded-lg mt-3"
          >
            {i18n.t('financials.empty.button')}
          </PlainButton>
        </>
      )}
    </ContentBlock>
  );
};

FinancialsForm.propTypes = {
  venueId: PropTypes.number,
  initialValues: PropTypes.oneOfType([PropTypes.shape(), PropTypes.arrayOf(PropTypes.shape())]),
  updatedValues: PropTypes.oneOfType([PropTypes.shape(), PropTypes.arrayOf(PropTypes.shape())]),
  errors: PropTypes.shape(),
  formDescription: PropTypes.shape(),
  isFetching: PropTypes.bool,
  isUpdatingValues: PropTypes.bool,
  fetchFormDescription: PropTypes.func.isRequired,
  fetchFormValues: PropTypes.func.isRequired,
  saveFormValues: PropTypes.func.isRequired,
  updateValue: PropTypes.func.isRequired,
  discardChanges: PropTypes.func.isRequired,
};
FinancialsForm.defaultProps = {
  venueId: null,
  initialValues: {},
  updatedValues: {},
  errors: null,
  formDescription: {},
  isFetching: false,
  isUpdatingValues: false,
};

const mapStateToProps = state => ({
  initialValues: getFinancialInitialValues(state),
  updatedValues: getFinancialUpdatedValues(state),
  errors: getFinancialsErrors(state),
  formDescription: getFinancialsFormDesc(state),
  isFetching: getIsFetchingFinancials(state),
  isUpdatingValues: getIsUpdatingFinancialValues(state),
  venueId: getVenueID(state),
});
const mapDispatchToProps = dispatch => ({
  fetchFormDescription: (venueId, params) =>
    dispatch(fetchFormDescriptionForFinancials(venueId, params)),
  fetchFormValues: venueId => dispatch(fetchFormValuesForFinancials(venueId)),
  saveFormValues: (venueId, body, method) =>
    dispatch(saveFormValuesForFinancials(venueId, body, method)),
  updateValue: (field, val) => dispatch(updateFieldValue(field, val)),
  discardChanges: () => dispatch(discardFormChanges()),
});

export default connect(mapStateToProps, mapDispatchToProps)(FinancialsForm);

export const emailErrorCheck = email => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

export const bankSortCodeCheck = sortCode =>
  // https://www.etl-tools.com/regular-expressions/is-uk-bank-sort-code.html - for when typing value into field
  !sortCode ||
  /^[0-9]{2}[-][0-9]{2}[-][0-9]{2}$/.test(sortCode) ||
  // https://stackoverflow.com/a/13107894/10424372- for string formatted as `*****12` coming from backend
  /^\*\*\*\*\*\*[0-9]{2}$/.test(sortCode);

export const eightDigitsBankAccNumberCheck = bankNumber =>
  !bankNumber || /^[0-9]{8}$/.test(bankNumber) || /^\*\*\*\*\*\*[0-9]{2}$/.test(bankNumber);

export const validatePassword = password => {
  const has8Chars = /^.{8,}$/.test(password);
  const hasANumber = /[0-9]/.test(password);
  const hasALetter = /[a-zA-Z]/.test(password);
  return { has8Chars, hasANumber, hasALetter };
};

import React from 'react';
import PropTypes from 'prop-types';
import PlainButton from '../../components/PlainButton/PlainButton';
import SearchBar from '../../components/SearchBar/SearchBar';

const Search = ({ searchResults, onChange, clear, value, onClick, placeholder }) => {
  const hasResults = searchResults?.length > 0;
  return (
    <div
      className={`relative flex-1 md:max-w-xs border ${
        hasResults ? ' border-primary' : 'border-white'
      }`}
    >
      <SearchBar value={value} onChange={onChange} onClear={clear} placeholder={placeholder} />
      {hasResults && (
        <div className="absolute w-full bg-white flex-1 flex flex-col z-10 -mt-1 rounded-b p-3 border-r border-l border-b border-primary">
          {searchResults.map(e => (
            <PlainButton onClick={() => onClick(e?.member?.id)}>
              <p className="text-xl pt-2 hover:underline text-left">
                {e?.member?.firstName} {e?.member?.lastName}
              </p>
            </PlainButton>
          ))}
        </div>
      )}
    </div>
  );
};

Search.propTypes = {
  searchResults: PropTypes.arrayOf(PropTypes.shape()),
  onChange: PropTypes.func.isRequired,
  clear: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
};

Search.defaultProps = {
  placeholder: undefined,
  searchResults: null,
};

export default Search;

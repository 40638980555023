import React from 'react';
import PropTypes from 'prop-types';
import TickIcon from '../Icons/TickIcon';

const PasswordValidation = ({ text, passed }) => (
  <div className="flex items-center">
    {passed ? (
      <TickIcon className="w-4 h-4 mr-2 text-green" />
    ) : (
      <svg width={16} height={16} className="mr-2 fill-lightRed">
        <circle cx={8} cy={8} r={2} />
      </svg>
    )}
    <p className={`text-xs ${passed ? '' : 'text-lightRed'}`}>{text}</p>
  </div>
);

PasswordValidation.propTypes = {
  text: PropTypes.string.isRequired,
  passed: PropTypes.bool,
};

PasswordValidation.defaultProps = {
  passed: false,
};

export default PasswordValidation;

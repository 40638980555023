import React from 'react';
import PropTypes from 'prop-types';
import ChevronIcon from './ChevronIcon';

const BackIcon = ({ className }) => <ChevronIcon className={`rotate-180 ${className}`} />;

BackIcon.propTypes = {
  className: PropTypes.string,
};

BackIcon.defaultProps = {
  className: '',
};

export default BackIcon;

import React from 'react';
import PropTypes from 'prop-types';

const ChevronIcon = ({ className }) => (
  <svg className={`fill-current transition-all duration-150 ${className}`} viewBox="0 0 24 24">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.38302 5L16.9896 12.1066L9.38302 19L8 17.617L14.2236 12.1066L8 6.38302L9.38302 5Z"
    />
  </svg>
);

ChevronIcon.propTypes = {
  className: PropTypes.string,
};
ChevronIcon.defaultProps = {
  className: '',
};

export default ChevronIcon;

import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import i18n from 'i18next';
import { connect } from 'react-redux';
import SearchIcon from '../Icons/SearchIcon';
import CloseIcon from '../Icons/CloseIcon';
import MPModal from '../MPModal/MPModal';
import { useAlgoliaSearch, useDetectOutsideClick, useRequest } from '../../utils/CustomHooks';
import { getTrendingFilters } from '../../store/api/fixtures';
import { getVenue } from '../../store/venue/selectors';
import { venueProps } from '../../utils/customPropTypes';
import Spinner from '../Spinner/Spinner';
import PlainButton from '../PlainButton/PlainButton';
import { filterActions, filterTypes } from '../../pages/FixturesPage/filtersReducer';
import { fireEvent, GACategories, GATags } from '../../utils/trackingHelper';
import ChevronIcon from '../Icons/ChevronIcon';

const resTypes = {
  competition: 'Competition',
  team: 'Team',
  sport: 'Sport',
};

const styles = {
  searchBar: 'flex rounded-3xl p-2.5 pl-4 border-2 lg:ml-2 mr-2',
  searchBarResults:
    'hidden sm:block pt-3 bg-white absolute rounded-b-3xl border-2 border-t-0 border-secondary right-0 left-0 lg:ml-2 mr-2 text-left',
};

const getBorderColor = isActive => (isActive ? 'border-secondary' : 'border-mpGrey');

const FixturesSearch = ({ venue: { id }, fetchTrending, hasActiveSearch, dispatch }) => {
  const [value, setValue] = useState('');
  const [searchModal, setSearchModal] = useState(false);
  const [, trendingSearches, fetchTrend] = useRequest(fetchTrending);
  const [loading, search, categories, clearResults, hits, nbHits, sendEvent] = useAlgoliaSearch(
    'fixtures',
    id,
  );

  useEffect(() => {
    fetchTrend(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSearch = val => {
    setValue(val);
    search(val, Object.values(categories), 5);
  };

  const clear = () => {
    clearResults();
    setValue('');
    dispatch({
      type: filterActions?.selectManualSearch,
      payload: {
        search: '',
      },
    });
  };

  const close = () => setSearchModal(false);

  const manualSearchAndClose = val => {
    fireEvent(GACategories.FIXTURES, GATags.SEARCH_BY_TERM);
    dispatch({
      type: filterActions?.selectManualSearch,
      payload: { search: val },
    });
    close();
  };

  const searchResultsList = () => {
    if (hits) {
      if (nbHits)
        return (
          <ResultsList
            title={i18n.t('fixtures_suggestedFilters')}
            results={hits.map(r => ({
              logo: r.competition_icon || r.sport_icon || r.logo,
              name: r.name,
              id: r.entity_id,
              category: r.category,
              objectID: r.objectID,
              sport: r.sport_name,
            }))}
            dispatch={dispatch}
            close={() => {
              close();
              clear();
            }}
            algoliaSendEvent={sendEvent}
          />
        );
      return <p className="py-2 px-4">{i18n.t('fixtures_searchNoResults')}</p>;
    }
    return null;
  };

  const trendingSearchesList = trendingSearches?.length > 0 && (
    <ResultsList
      title={i18n.t('fixtures_trendingSearches')}
      results={trendingSearches}
      dispatch={dispatch}
      close={close}
    />
  );

  const trendingAndResults = value ? (
    <>
      <PlainButton
        className="flex w-full items-center text-sm pb-4 px-4"
        onClick={() => manualSearchAndClose(value)}
      >
        <SearchIcon className="w-6 h-6" />
        <p className="ml-4 truncate">{i18n.t('fixtures_searchFor', { value })}</p>
      </PlainButton>
      {loading ? <Spinner className="h-8" /> : searchResultsList()}
    </>
  ) : (
    trendingSearchesList
  );

  return (
    <>
      <PlainButton
        className={`sm:hidden rounded-full p-2.5 border-2 ${getBorderColor(hasActiveSearch)}`}
        onClick={() => setSearchModal(true)}
      >
        <SearchIcon className="w-6 h-6" />
      </PlainButton>

      <div className="hidden sm:block flex-1 max-w-xs">
        <SearchBar
          placeholder={i18n.t('fixtures_searchPlaceholder')}
          value={value}
          onChange={val => onSearch(val)}
          onPressEnter={v => manualSearchAndClose(v)}
          clear={clear}
          results={trendingAndResults}
          hasActiveSearch={hasActiveSearch}
        />
      </div>

      <MPModal open={searchModal} onClose={close} className="h-full max-h-full">
        <div className="flex p-4">
          <SearchBar
            className="flex-1 mr-6"
            placeholder={i18n.t('general.search')}
            value={value}
            onChange={val => onSearch(val)}
            onPressEnter={v => manualSearchAndClose(v)}
            clear={clear}
          />
          <PlainButton className="font-semibold" onClick={close}>
            {i18n.t('general_close')}
          </PlainButton>
        </div>
        {trendingAndResults}
      </MPModal>
    </>
  );
};

FixturesSearch.propTypes = {
  venue: venueProps.isRequired,
  fetchTrending: PropTypes.func.isRequired,
  hasActiveSearch: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  venue: getVenue(state),
});

const mapDispatchToProps = {
  fetchTrending: getTrendingFilters,
};

export default connect(mapStateToProps, mapDispatchToProps)(FixturesSearch);

const SearchBar = ({
  className,
  placeholder,
  value,
  onChange,
  clear,
  results,
  hasActiveSearch,
  onPressEnter,
}) => {
  const [isFocused, setIsFocused] = useState(false);
  const ref = useRef();
  useDetectOutsideClick(ref, () => setIsFocused(false));

  return (
    <div className={`relative ${className}`} ref={ref}>
      <div
        className={`${styles.searchBar} ${
          results && isFocused ? 'border-b-transparent rounded-b-none' : ''
        } ${getBorderColor(isFocused || hasActiveSearch)}`}
      >
        <input
          className="flex-1 outline-none"
          value={value}
          placeholder={placeholder}
          onKeyUp={e => {
            if (e.key === 'Enter') {
              onPressEnter(e.target.value);
              e.target.blur();
              setIsFocused(false);
            }
          }}
          onChange={e => onChange(e.target.value)}
          onFocus={() => setIsFocused(true)}
        />
        {value?.length > 0 || hasActiveSearch ? (
          <PlainButton className="ml-3" onClick={clear}>
            <CloseIcon className="w-6 h-6 bg-mpGrey rounded-full" />
          </PlainButton>
        ) : (
          <SearchIcon className="w-6 h-6 ml-3" />
        )}
      </div>
      {results && isFocused && (
        <PlainButton className={styles.searchBarResults} onClick={() => setIsFocused(false)}>
          {results}
        </PlainButton>
      )}
    </div>
  );
};

SearchBar.propTypes = {
  className: PropTypes.string,
  placeholder: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  clear: PropTypes.func.isRequired,
  results: PropTypes.node,
  hasActiveSearch: PropTypes.bool,
  onPressEnter: PropTypes.func.isRequired,
};

SearchBar.defaultProps = {
  className: '',
  results: null,
  hasActiveSearch: false,
};

const ResultsList = ({ title, results, dispatch, close, algoliaSendEvent }) => {
  const resultRow = (res, index) => (
    <PlainButton
      key={res?.id}
      className="flex w-full items-center text-sm py-2 px-4"
      onClick={() => {
        fireEvent(GACategories.FIXTURES, GATags.ADDED_FILTER_FROM_SEARCH);
        algoliaSendEvent(res?.objectID, index);
        dispatch({
          type: filterActions?.selectFilterFromSearch,
          payload: {
            filter: {
              ...res,
              type: res?.category === resTypes?.sport ? filterTypes?.sport : res?.id,
            },
          },
        });
        close();
      }}
    >
      <img className="w-8 h-8 p-1" src={res?.logo} alt={res?.name} />
      <div className="text-left ml-4 flex-1">
        <p>{res?.name}</p>
        {res?.sport && <p className="text-xs opacity-60 pt-1">{res.sport}</p>}
      </div>
      <ChevronIcon className="h-6 w-6" />
    </PlainButton>
  );

  return (
    <>
      <p className="text-xs font-semibold uppercase py-2 px-4">{title}</p>
      <div className="divide-y divide-mpGrey">{results?.map((r, i) => resultRow(r, i))}</div>
    </>
  );
};

ResultsList.propTypes = {
  title: PropTypes.string.isRequired,
  close: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  algoliaSendEvent: PropTypes.func,
  results: PropTypes.arrayOf(
    PropTypes.shape({
      logo: PropTypes.string,
      name: PropTypes.string,
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      category: PropTypes.string,
    }),
  ),
};

ResultsList.defaultProps = {
  results: [],
  algoliaSendEvent: () => {},
};

import { safelyParseJSON } from '../../../utils/general';

const imageKey = 'image';
const logoKey = 'logo';
const headlineKey = 'headline';
const constraintAreaKey = 'constraintArea';

export const textColors = ['#ffffff', '#000000'];

export const defaultConstraints = {
  portrait: {},
  landscape: {},
  instagram: {},
};

export const defaultState = {
  secondaryTextColor: '#000000', // default value for initial previews fetch
  fontSize: 64, // backend doesnt return these values on initial load - instead we set them for it.
  logoScale: 1,
};

const isConstraintsObj = (key, obj) =>
  // check if obj is shaped:
  //  obj = {
  //   logo: {
  //      constraintArea: [XX, YY, XX, YY]
  //    }
  //  } and if so, set as constraints.
  Boolean(obj[key]) &&
  obj[key][constraintAreaKey] &&
  Array.isArray(obj[key][constraintAreaKey]) &&
  obj[key][constraintAreaKey].length === 4;

export const createFontsForTemplates = fontsArray => {
  // creates fonts in stylesheets - canvas needs that for correct render
  if (fontsArray && Array.isArray(fontsArray) && fontsArray.length) {
    const newStyle = document.createElement('style');
    const text = document.createTextNode(
      fontsArray
        .map(
          font => `@font-face {
            font-family: ${font.identifier};
            font-weight: normal;
            src: url(${font.url});
          }`,
        )
        .join(' '),
    );
    newStyle.appendChild(text);
    document.head.appendChild(newStyle);
  }
};

export const getBase64image = (file, callback) => {
  if (file) {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      callback(reader.result);
      return true;
    };
  }
};

export const composeStateFromCustomAndDefaultDatasets = (defaultData, customData) => {
  // resource heavy way to scan all themes to gather all the data in one place - so one piece of state
  // can be used to manage all the themes. Should only be run once.
  const customConfig = safelyParseJSON(customData);
  const safeCustomConfig = customConfig && Object.keys(customConfig) ? customConfig : {};
  const newState = {
    ...defaultState,
    ...safeCustomConfig,
  };

  const newConstraints = { ...defaultConstraints };

  defaultData?.forEach(theme => {
    // for the remaining values - scan throught all the themes and assign state values
    if (theme.defaultConfig) {
      const configForTheme = safelyParseJSON(theme.defaultConfig);
      if (configForTheme) {
        Object.keys(configForTheme).forEach(key => {
          const value = configForTheme[key];

          if (
            typeof value === 'object' ||
            isConstraintsObj(logoKey, value) ||
            isConstraintsObj(imageKey, value) ||
            isConstraintsObj(headlineKey, value)
          ) {
            newConstraints[theme.format][theme.nbFixtures] = value;
            return;
          }
          if (key === imageKey || key === logoKey) {
            newState[`default${key}`] = value;
          }
          if (newState[key] === undefined) {
            newState[key] = value;
          }
        });
      }
    }
  });

  return { newConstraints, newState };
};

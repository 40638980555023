import PropTypes from 'prop-types';
import React from 'react';
import { t } from 'i18next';
import PlainButton from '../../components/PlainButton/PlainButton';
import ChevronIcon from '../../components/Icons/ChevronIcon';
import { historyProps } from '../../utils/customPropTypes';

export const Header = ({ history, title }) => (
  <div className="flex items-center p-4 md:p-6 lg:px-10 border-b border-mpGrey bg-white">
    <div className="flex-1">
      <PlainButton className="flex items-center" onClick={() => history.goBack()}>
        <ChevronIcon className="h-6 w-6 md:h-8 md:w-8 rotate-180" />
        <p className="text-xl font-semibold hidden md:flex ml-2">{t('general_back')}</p>
      </PlainButton>
    </div>
    <h1 className="headingFont text-2xl md:text-4xl">{title}</h1>
    <div className="flex-1" />
  </div>
);

Header.propTypes = {
  history: historyProps.isRequired,
  title: PropTypes.string.isRequired,
};

export const SliderNavigation = ({ clickLeft, clickRight, title, textComponent }) => (
  <>
    <h3 className="text-xs font-bold opacity-60 uppercase pb-2">{title}</h3>
    <div className="flex justify-between p-3">
      <PlainButton onClick={clickLeft} className="outline-none">
        <ChevronIcon className="h-6 w-6 rotate-180" />
      </PlainButton>
      {textComponent}
      <PlainButton onClick={clickRight} className="outline-none">
        <ChevronIcon className="h-6 w-6" />
      </PlainButton>
    </div>
  </>
);

SliderNavigation.propTypes = {
  clickLeft: PropTypes.func.isRequired,
  clickRight: PropTypes.func.isRequired,
  textComponent: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
};

import React, { useRef, useEffect } from 'react';
import { PropTypes } from 'prop-types';
import './styles.scss';
import { createImageObject } from '../../../utils/general';

const CompetitionImageGenerator = ({ preview, image, templateWidth, templateHeight }) => {
  const canvas = useRef(null);
  useEffect(() => {
    const cc = canvas.current;
    const ctx = cc && cc.getContext('2d');
    ctx.clearRect(0, 0, cc.width, cc.height);
    if (image) {
      ctx.drawImage(createImageObject(image), 0, 0, cc.width, cc.height);
    }
    ctx.drawImage(createImageObject(preview), 0, 0, cc.width, cc.height);
  }, [preview, canvas, image]);

  return (
    <canvas
      className="ImageGenerator_canvas"
      ref={canvas}
      width={templateWidth}
      height={templateHeight}
    />
  );
};

CompetitionImageGenerator.propTypes = {
  preview: PropTypes.string,
  image: PropTypes.string,
  templateHeight: PropTypes.number.isRequired,
  templateWidth: PropTypes.number.isRequired,
};

CompetitionImageGenerator.defaultProps = {
  image: null,
  preview: null,
};

export default CompetitionImageGenerator;

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import React, { useState } from 'react';
import i18n from 'i18next';
import TierList, { FREQUENCY } from '../../components/TierList/TierList';
import PlainButton from '../../components/PlainButton/PlainButton';
import BackIcon from '../../components/Icons/BackIcon';
import { getVenue } from '../../store/venue/selectors';
import { historyProps, venueProps } from '../../utils/customPropTypes';
import { attemptSubscribeThunk } from '../../store/venue/thunks';
import { fireEvent, GACategories, GATags } from '../../utils/trackingHelper';
import { VENUE_STATUS } from '../../utils/constants';

const SUBSCRIPTION = {
  ORGANIC: 'organic',
  UPGRADE: 'upgrade',
  RESUB: 'resubscribe',
};

const Subscriptions = ({ title, history, venue, subscribe }) => {
  const [selectedTier, setSelectedTier] = useState(venue.tierId);
  const [frequency, setFrequency] = useState(FREQUENCY.YEARLY);
  const { page } = history?.location?.state || {};

  const subStatus = (() => {
    if (venue?.status === VENUE_STATUS.LEAD) {
      return SUBSCRIPTION.ORGANIC;
    }
    if (venue?.status === VENUE_STATUS.ACTIVE) {
      return SUBSCRIPTION.UPGRADE;
    }
    return SUBSCRIPTION.RESUB;
  })();

  return (
    <div className="bg-white h-screen">
      <div className="flex justify-between items-center px-4 md:px-6 lg:px-10 py-4 md:py-6 border-b-2 border-mpGrey">
        <PlainButton className="flex items-center flex-1" onClick={() => history.goBack()}>
          <BackIcon className="w-10 h-10 -ml-3" />
          <span className="hidden md:block font-semibold text-xl">{i18n.t('general_back')}</span>
        </PlainButton>
        <h1 className="headingFont flex-1 text-3xl md:text-4xl leading-none text-center">
          {title}
        </h1>
        <div className="flex flex-1" />
      </div>

      <TierList
        onSelect={(tier, promoCode) => {
          if (tier) {
            fireEvent(GACategories.TIER, GATags.TIER_CONTINUE, { tier, subStatus, page });
            subscribe(venue.id, tier, frequency === FREQUENCY.YEARLY ? 12 : 3, promoCode);
          }
        }}
        currentTierID={selectedTier}
        currentFrequency={frequency}
        onChangeFrequency={val => {
          setFrequency(val);
          setSelectedTier(null);
        }}
        countryID={venue.countryId}
        venueId={venue.id}
        showPromoCode
      />
    </div>
  );
};

Subscriptions.propTypes = {
  title: PropTypes.string.isRequired,
  history: historyProps.isRequired,
  venue: venueProps.isRequired,
  subscribe: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  venue: getVenue(state),
});

const mapDispatchToProps = {
  subscribe: attemptSubscribeThunk,
};

export default connect(mapStateToProps, mapDispatchToProps)(Subscriptions);

import React from 'react';
import PropTypes from 'prop-types';

const DotsIcon = ({ className }) => (
  <svg className={`fill-current ${className}`} viewBox="0 0 24 24">
    <path d="M7.09091 10.9091H9.27273L8.93142 13.0641H6.7496L7.09091 10.9091Z" />
    <path d="M11.4545 10.9091H13.6363L13.295 13.0641H11.1132L11.4545 10.9091Z" />
    <path d="M15.8182 10.9091H18L17.6587 13.0641H15.4769L15.8182 10.9091Z" />
  </svg>
);

DotsIcon.propTypes = {
  className: PropTypes.string,
};

DotsIcon.defaultProps = {
  className: '',
};

export default DotsIcon;

export function isDevEnvironment() {
  return process.env.NODE_ENV === 'development';
}

const host = window?.location?.hostname || '';

export const allDomains = {
  fanzoURL: 'fanzo.com',
  onTap: 'ontapsports.live',
};

export const findDomainExt = () =>
  Object.values(allDomains).find(item => host.includes(item)) || '';

const replaceDomain = tobeReplaced => tobeReplaced.replace('{{domain}}', findDomainExt());

export const userIsOnFanzo = host.includes('fanzo');
export const userIsOnOnTap = host.includes('ontapsports');

export function getServicesEndpoint() {
  return replaceDomain(process.env.REACT_APP_ROOT_URL);
}

export function getWebsiteURL() {
  return replaceDomain(process.env.REACT_APP_WEBSITE_URL);
}

export function getCookieDomain() {
  return replaceDomain(process.env.REACT_APP_COOKIE_DOMAIN);
}

const locales = {
  enGB: 'en',
  enIE: 'en-ie',
  enAU: 'en-au',
  enUS: 'en-us',
  fr: 'fr',
  de: 'de',
};

const commonValues = {
  domain: allDomains.fanzoURL,
  countryId: 54,
  algoliaIndex: 'matchpint',
  ga4Id: process.env.REACT_APP_MP_GA,
  mainColor: '#000000',
  faviconPath: '/FANZO/favicon.ico',
  profileCompleteImg: 'profile-complete.png',
  menu: ['home', 'fixtures', 'marketing', 'stats', 'offers'],
};

export const getDomainInfo = locale => {
  if (locale === locales.fr) {
    return {
      ...commonValues,
      countryId: 62,
      algoliaIndex: 'allomatch',
    };
  }
  if (locale === locales.enAU) {
    return {
      ...commonValues,
      countryId: 10,
      algoliaIndex: 'matchscooner',
    };
  }
  if (locale === locales.enIE) {
    return {
      ...commonValues,
      countryId: 83,
      algoliaIndex: 'matchpintie',
    };
  }
  if (locale === locales.de) {
    return {
      ...commonValues,
      countryId: 66,
      algoliaIndex: 'daznde',
      menu: ['home', 'fixtures', 'marketing', 'stats'],
    };
  }
  if (locale === locales.enUS) {
    return {
      ...commonValues,
      countryId: 187,
      algoliaIndex: 'joehandsus',
      menu: ['home'],
      faviconPath: '/ONTAP/favicon.ico',
      profileCompleteImg: 'profile-complete-ontap.png',
      mainColor: '#131530',
      ga4Id: process.env.REACT_APP_ONTAP_GA,
    };
  }
  return commonValues;
};

import i18n from 'i18next';
import { fr, de, enGB, enUS } from 'date-fns/locale';
import { isValid, format } from 'date-fns';
import { initReactI18next } from 'react-i18next';
import { toast } from 'react-toastify';
import LanguageDetector from 'i18next-browser-languagedetector';
import enTranslations from './translations/en.json';
import ie from './translations/en-ie.json';
import aus from './translations/en-au.json';
import us from './translations/en-us.json';
import deTranslations from './translations/de.json';
import frTranslations from './translations/fr.json';
import nzTranslations from './translations/en-nz.json';
import { isDevEnvironment, userIsOnOnTap } from './utils/environment';

const getLanguages = () => {
  if (userIsOnOnTap) {
    return { 'en-us': { translation: us } };
  }

  return {
    en: { translation: enTranslations },
    fr: { translation: frTranslations },
    'en-ie': { translation: ie },
    'en-au': { translation: aus },
    'en-nz': { translation: nzTranslations },
    de: { translation: deTranslations },
  };
};

const getFallback = () => {
  if (userIsOnOnTap) {
    return 'en-us';
  }
  return 'en';
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: getLanguages(),
    fallbackLng: getFallback(),
    lowerCaseLng: true,
    saveMissing: true,
    keySeparator: false, // we do not use keys in form messages.welcome
    interpolation: {
      escapeValue: false, // react already saves from xss
    },
  });

i18n.on('missingKey', (_, __, key) => {
  if (isDevEnvironment()) {
    toast.warning(`KEY MISSING ${key}`);
  }
});

export const getLocale = () => {
  switch (i18n.resolvedLanguage) {
    case 'fr':
      return fr;
    case 'de':
      return de;
    case 'en-us':
      return enUS;
    default:
      return enGB;
  }
};

export const updateLanguage = locale => i18n.changeLanguage(locale);

// assuming 0 = monday and 6 = sunday
const indexDay = i => {
  const iDay = i + 1;
  if (iDay > 6) {
    return 0;
  }
  return iDay;
};

export const getTranslatedDay = i => getLocale().localize.day(indexDay(i));

export const getLocaleDate = (date, formatStr = 'EEE d') => {
  let tempFormat = formatStr;
  if (i18n.resolvedLanguage === 'fr' && formatStr.includes('do')) {
    tempFormat = formatStr.replace('do', 'd');
  }

  return isValid(new Date(date))
    ? format(new Date(date), tempFormat, {
        locale: getLocale(),
      })
    : date;
};

export default i18n;

import * as consts from './constants';
import { POST_WAS_SCHEDULED, POST_WAS_POSTED } from '../socialMedia/constants';

export const initialState = {
  isFetching: true,
  isFetchingNext: false,
  fixtures: [],
  offset: 15,
  limit: 15,
  hasMorePages: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case consts.FETCH_LIST_FIXTURES_PENDING:
      return {
        ...state,
        offset: initialState.offset,
        isFetching: true,
      };
    case consts.FETCH_LIST_FIXTURES_SUCCESS:
      return {
        ...state,
        isFetching: false,
        fixtures: [...action.payload.data.result],
        hasMorePages: action.payload.data.result.length >= state.limit,
      };
    case consts.FETCH_LIST_FIXTURES_ERROR:
      return {
        ...state,
        isFetching: false,
      };
    case consts.FETCH_NEXT_LIST_FIXTURES_PENDING:
      return {
        ...state,
        isFetchingNext: true,
      };
    case consts.FETCH_NEXT_LIST_FIXTURES_SUCCESS: {
      const newFixtures = state.fixtures.concat(action.payload.data.result);

      return {
        ...state,
        isFetchingNext: false,
        offset: newFixtures.length,
        fixtures: [...newFixtures],
        hasMorePages: action.payload.data.result.length >= state.limit,
      };
    }
    case consts.FETCH_NEXT_LIST_FIXTURES_ERROR:
      return {
        ...state,
        isFetchingNext: false,
      };
    case POST_WAS_SCHEDULED: {
      const fixtures = [...state.fixtures];
      fixtures.forEach((fixture, i) => {
        if (action.payload.includes(fixture.id)) {
          fixtures[i].postAlreadyScheduled = true;
        }
      });
      return {
        ...state,
        fixtures,
      };
    }
    case POST_WAS_POSTED: {
      const fixtures = [...state.fixtures];
      fixtures.forEach((fixture, i) => {
        if (action.payload.includes(fixture.id)) {
          fixtures[i].postAlreadySent = true;
        }
      });
      return {
        ...state,
        fixtures,
      };
    }
    default:
      return state;
  }
};

export default reducer;

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { t } from 'i18next';
import { genericRequest } from '../../store/api/request';
import { getVenueID } from '../../store/venue/selectors';
import LoadingAnimation from '../../components/LoadingAnimation/LoadingAnimation';
import DivHoverEffect from '../../components/DivHoverEffect/DivHoverEffect';
import { userIsOnOnTap } from '../../utils/environment';
import ChevronIcon from '../../components/Icons/ChevronIcon';
import routes from '../../routes/paths';
import HelpIcon from '../../components/Icons/HelpIcon';
import { GATags, fireEvent } from '../../utils/trackingHelper';

const StatBox = ({ stat: { label, value, description } }) => {
  const [showBack, setShowBack] = useState(false);
  return (
    <DivHoverEffect
      className={`flex-1 relative flex flex-col justify-center rounded-xl border border-mpGrey px-2 md:px-4 py-10 ${
        showBack ? 'bg-black text-white' : 'text-center items-center'
      }`}
      onHover={() => setShowBack(!showBack)}
    >
      <HelpIcon className="h-6 absolute top-2 right-2 sm:top-4 sm:right-4" />
      <div className={`absolute ${showBack ? 'invisible' : ''}`}>
        <p className="headingFont text-3xl sm:text-4xl pb-2">{value}</p>
        <p className="font-semibold text-xs sm:text-base opacity-60">{label}</p>
      </div>
      <div className={showBack ? '' : 'invisible'}>
        <p className="font-bold text-sm sm:text-xl lg:text-sm">{label}</p>
        <p className="opacity-60 text-xs sm:text-base lg:text-xs">{description}</p>
      </div>
    </DivHoverEffect>
  );
};

const StatsTile = ({ venueID, generic }) => {
  const [stats, setStats] = useState(null);

  useEffect(() => {
    generic(`/venues/${venueID}/dashboard/stats`)
      .then(r => setStats(r.getResult()?.stats))
      .catch(() => setStats([]));
  }, [generic, venueID]);

  if (stats === null) {
    return <LoadingAnimation />;
  }

  if (Object.values(stats || {}).length === 0) {
    return null;
  }

  return (
    <div className="bg-white border border-mpGrey rounded-2xl p-4 sm:p-6 lg:p-8">
      <h2 className="headingFont opacity-60 text-xl sm:text-3xl lg:text-4xl pb-6">
        {t('home_stats')}
      </h2>
      <div className="flex gap-4">
        {stats?.fixturesListed && <StatBox stat={stats.fixturesListed} />}
        {stats?.listViews && <StatBox stat={stats.listViews} />}
        {stats?.profileViews && <StatBox stat={stats.profileViews} />}
      </div>
      {!userIsOnOnTap && (
        <Link
          onClick={() => fireEvent(GATags.HOME_STATS_SEEALL, undefined, { venueID })}
          to={routes.stats.path}
          className="bg-mpGrey mt-4 rounded-xl text-xs items-center sm:text-base lg:text-sm flex-1 justify-between py-2 px-4 font-semibold flex text-black"
        >
          {t('home_stats_seeAll')}
          <ChevronIcon className="h-6 text-black" />
        </Link>
      )}
    </div>
  );
};

const mapStateToProps = state => ({
  venueID: getVenueID(state),
});

const mapDispatchToProps = {
  generic: genericRequest,
};

StatsTile.propTypes = {
  venueID: PropTypes.number.isRequired,
  generic: PropTypes.func.isRequired,
};

StatBox.propTypes = {
  stat: PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired,
    description: PropTypes.string.isRequired,
  }).isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(StatsTile);

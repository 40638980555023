import React from 'react';
import './styles.scss';
import PropTypes from 'prop-types';
import i18n from 'i18next';

const TextArea = ({
  placeholder,
  rows,
  maxLength,
  value,
  onChange,
  className,
  noBorder,
  ...rest
}) => {
  return (
    <>
      <textarea
        placeholder={placeholder}
        className={`TextArea${noBorder ? '_noBorder' : ''} ${className}`}
        rows={rows}
        maxLength={maxLength}
        value={value}
        onChange={e => onChange(e.target.value)}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...rest}
      />
      {maxLength && (
        <p className="absolute right-2 bottom-4 text-standardGrey text-xs">
          {i18n.t('marketing.postPublish.char_limit')}
          {maxLength - (value?.length || 0)}/{maxLength}
        </p>
      )}
    </>
  );
};

export default TextArea;

TextArea.propTypes = {
  className: PropTypes.string,
  placeholder: PropTypes.string,
  rows: PropTypes.string,
  maxLength: PropTypes.number,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  noBorder: PropTypes.bool,
};

TextArea.defaultProps = {
  noBorder: false,
  placeholder: null,
  className: '',
  rows: '4',
  maxLength: undefined,
  value: '',
};

import React from 'react';
import PropTypes from 'prop-types';
import { format, isValid, parse } from 'date-fns';
import { t } from 'i18next';
import PlainButton from '../../components/PlainButton/PlainButton';
import MPModal from '../../components/MPModal/MPModal';
import CloseIcon from '../../components/Icons/CloseIcon';
import CalendarIcon from '../../components/Icons/CalendarIcon';
import { getByType } from './utils';

const AssetTipsModal = ({ onClose, data }) => {
  const { Icon, text } = getByType(data?.assetPlatform);
  const getFormattedDate = date => {
    const parsedDate = parse(date, 'yyyy-MM-dd HH:mm:ss', new Date());
    return isValid(parsedDate) && `${format(parsedDate, 'MMMM do yyyy')}`;
  };

  return (
    <MPModal open={Boolean(data)} onClose={onClose} className="bg-transparent max-w-md">
      <div className="rounded-2xl flex flex-col bg-white mx-4 p-8 relative sm:pt-12 overflow-hidden">
        <PlainButton
          className="absolute right-2 top-2 p-2 bg-mpGrey rounded-full outline-none sm:right-4 sm:top-4"
          onClick={onClose}
        >
          <CloseIcon className="w-8 h-8" />
        </PlainButton>
        <p className="opacity-60 text-xs pr-8 sm:text-base">{t('promoHub_tipsModal_title')}</p>
        <p className="headingFont text-4xl pr-8 py-2 sm:text-5xl">{data?.name}</p>
        {data?.fixturesStartDate && (
          <div className="flex items-center pb-1">
            <CalendarIcon className="h-4 w-4 mr-2 text-black" />
            <p className="text-xs sm:text-base">{getFormattedDate(data.fixturesStartDate)}</p>
          </div>
        )}
        <div className="flex items-center pb-4 mb-4 border-b border-mpGrey">
          <Icon className="w-4 h-4 mr-2" />
          <p className="text-xs sm:text-base">{text}</p>
        </div>
        {data?.suggestedCopy && (
          <div className="sm:text-xl">
            <p className="font-medium">{t('promoHub_tipsModal_caption')}</p>
            <p className="pt-1">{data.suggestedCopy}</p>
          </div>
        )}
        {data?.suggestedPostDate && (
          <div className="sm:text-xl">
            <p className="font-medium pt-4">{t('promoHub_tipsModal_date')}</p>
            <p className="pt-1">{getFormattedDate(data.suggestedPostDate)}</p>
          </div>
        )}
      </div>
    </MPModal>
  );
};

AssetTipsModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  data: PropTypes.shape(),
};

AssetTipsModal.defaultProps = {
  data: null,
};

export default AssetTipsModal;

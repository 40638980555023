export const FETCH_OFFERS_PENDING = 'fetch/offers/pending';
export const FETCH_OFFERS_SUCCESS = 'fetch/offers/success';
export const FETCH_OFFERS_ERROR = 'fetch/offers/error';

export const FETCH_MORE_OFFERS_PENDING = 'fetch/offers/more/pending';
export const FETCH_MORE_OFFERS_SUCCESS = 'fetch/offers/more/success';
export const FETCH_MORE_OFFERS_ERROR = 'fetch/offers/more/error';

export const FETCH_OFFER_PENDING = 'fetch/offer/pending';
export const FETCH_OFFER_SUCCESS = 'fetch/offer/success';
export const FETCH_OFFER_ERROR = 'fetch/offer/error';

export const CLEAR_OFFER = 'clear/offer';

export const CREATE_OFFER_PENDING = 'create/offer/pending';
export const CREATE_OFFER_SUCCESS = 'create/offer/success';
export const CREATE_OFFER_ERROR = 'create/offer/error';

export const STOP_OFFER_PENDING = 'post/offer/stop/pending';
export const STOP_OFFER_SUCCESS = 'post/offer/stop/success';
export const STOP_OFFER_ERROR = 'post/offer/stop/error';

export const SAVE_OFFER_PENDING = 'save/offer/pending';
export const SAVE_OFFER_SUCCESS = 'save/offer/success';
export const SAVE_OFFER_ERROR = 'save/offer/error';

export const UPDATE_OFFER_PENDING = 'update/offer/pending';
export const UPDATE_OFFER_SUCCESS = 'update/offer/success';
export const UPDATE_OFFER_ERROR = 'update/offer/error';

export const GET_COMPETITIONS_PENDING = 'fetch/offer/competitions/pending';
export const GET_COMPETITIONS_SUCCESS = 'fetch/offer/competitions/success';
export const GET_COMPETITIONS_ERROR = 'fetch/offer/competitions/error';

import { createSelector } from 'reselect';
import { shallowCompareArrays } from '../../utils/ObjectComparison';

export const getIsFetching = state => state.venue.isFetching;

export const getVenue = state => state.venue.venues[0];

export const getVenueFixturePreferences = state => {
  const { sound, bigScreen } = state.venue.venues[0]?.venueFixturePreferences || {};

  return { sound, bigScreen };
};

export const getVenueGame = state => state.venue.venueGame;

export const getVenueLeague = state => state.venue.venueLeague;

export const getVenueID = state => state.venue.venues[0].id;

export const getPermissions = createSelector(
  state => state.venue.permissions,
  data => (Array.isArray(data) ? data.map(perm => perm.code) : null),
);

export const getIsFetchingPermissions = state => state.venue.isFetchingPermissions;

export const getProfilePicture = state => getVenue(state).profilePicture;

export const getIsUploadingImage = state => state.venue.isUploadingImage;

export const getIsSavingVenue = state => state.venue.isSavingVenue;

export const getIsSavingChannels = state => state.venue.isSavingChannels;

export const getAllChannels = state => state.venue.allChannels;

export const getVenueSelectedChannels = state => state.venue.selectedChannels;

export const getInitiallySelectedChannels = state => state.venue.initiallySelectedChannels;

export const getChannelsChanged = state =>
  state.venue.selectedChannels &&
  state.venue.initiallySelectedChannels &&
  !shallowCompareArrays(state.venue.selectedChannels, state.venue.initiallySelectedChannels);

export const getAuthModalStatus = state => state.venue.authModalStatus;

export const getIsDisconnecting = state => state.venue.isDisconnecting;

export const getVenueFieldErrors = state => state.venue.fieldErrors;

export const getIsLoggingInToTwitter = state => state.venue.isLoggingInToTwitter;

export const getTwitterLoginURL = state => state.venue.twitterLoginURL;

export const getIsFinalisingResub = state => state.venue.isFinalisingResub;
export const getFinaliseResubSuccess = state => state.venue.finaliseResubSuccess;
export const getFinaliseResubError = state => state.venue.finaliseResubError;

import React, { useState, useEffect } from 'react';
import { PropTypes } from 'prop-types';
import i18next from 'i18next';
import { connect } from 'react-redux';
import AssetEditorWrapper from './AssetEditorWrapper/AssetEditorWrapper';
import CompetitionImageGenerator from './ImageGenerator/CompetitionImageGenerator';
import AssetFormatIcon, { types } from '../../components/AssetFormatIcon/AssetFormatIcon';
import { getVenueID } from '../../store/venue/selectors';
import {
  fetchCompetitionThemeConfig,
  fetchFontsForSM,
  fetchBespokePreviews,
  saveCompetitionConfig,
  fetchThemeData,
} from '../../store/socialMedia/thunks';
import {
  templateHeightLandscape,
  templateWidthLandscape,
  templateWidthInstagram,
  templateHeightInstagram,
} from './ImageGenerator/shared';
import { historyProps } from '../../utils/customPropTypes';
import paths from '../../routes/paths';
import { fireEvent, GACategories, GATags } from '../../utils/trackingHelper';
import { assetTypes } from '../../utils/constants';

const CompetitionAssetEditor = ({
  getFonts,
  getThemeConfigs,
  venueId,
  history,
  match,
  getBespokePreviews,
  saveConfig,
  getThemeData,
}) => {
  const { format, name, returnRoute } = history?.location?.state || {};
  const competitionId = match?.params?.id;
  const [initialLandscapeState, setInitialLandscapeState] = useState({});
  const [landscapeState, setLandscapeState] = useState({});
  const [initialPortraitState, setInitialPortraitState] = useState({});
  const [portraitState, setPortraitState] = useState({});
  const [error, setError] = useState(null);
  const [fonts, setFonts] = useState([]);
  const [previews, setPreviews] = useState([]);
  const [themeData, setThemeData] = useState([]);
  const [loadingAssets, setLoadingAssets] = useState(false);
  const [loaded, setLoaded] = useState({ backgroundImage: true });
  const [type, setType] = useState(format || types.landscape);

  const state = type === types.landscape ? landscapeState : portraitState;
  const initialState = type === types.landscape ? initialLandscapeState : initialPortraitState;
  const setState = type === types.landscape ? setLandscapeState : setPortraitState;
  const getConfig = typeOver =>
    getThemeConfigs(venueId, competitionId, typeOver).then(r => {
      let newState = {
        ...state,
        ...r.default,
        defaultimage: r.default.backgroundImage,
      };

      if (r.custom) {
        newState = { ...newState, ...r.custom };
      }

      const setStateOver = typeOver === types.landscape ? setLandscapeState : setPortraitState;
      const setInitialStateOver =
        typeOver === types.landscape ? setInitialLandscapeState : setInitialPortraitState;

      setStateOver(newState);
      setInitialStateOver(newState);
    });

  useEffect(() => {
    setLoadingAssets(true);
    Promise.all([
      getFonts(venueId).then(r => setFonts(r)),
      getConfig(types.landscape),
      getConfig(types.instagram),
      getThemeData(venueId).then(r => {
        if (Array.isArray(r) && r.length) {
          setThemeData(r.filter(th => th.type === assetTypes.comp));
        }
      }),
    ])
      .then(() => setLoadingAssets(false))
      .catch(err => {
        setError(err.message);
        setLoadingAssets(false);
      });
    // getConfig creates loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [competitionId, getFonts, venueId]);

  useEffect(() => {
    if (fonts.length && state.secondaryTextColor && themeData.length && type) {
      setLoadingAssets(true);
      getBespokePreviews(
        venueId,
        state.textFont,
        state.textColor.slice(1, 7), // gets rid of '#' in the hex color
        i18next.resolvedLanguage,
        state.secondaryTextFont,
        state.secondaryTextColor.slice(1, 7),
        // We just fetch the images of the type currently selected
        themeData?.filter(th => th?.format === type)?.map(el => el?.id),
      ).then(r => {
        if (r && Array.isArray(r)) {
          setPreviews(r.filter(prev => themeData.find(p => p.id === prev.id)));
          setLoadingAssets(false);
        }
      });
    }
    // getBespokePreviews/ type dependencies fire thunks multiple times
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    competitionId,
    fonts,
    venueId,
    state.textColor,
    state.textFont,
    state.secondaryTextColor,
    state.secondaryTextFont,
    themeData,
    type,
  ]);

  /*
    Whenever a new template is fetched or background image is changed,
    the `loaded` status is set to false, so that the Image generator does not try to render them.
    Once the images are ready and loaded (see bottom of render) the states are set to true and 
    available for render
  */
  const updateLoaded = obj => setLoaded({ ...loaded, ...obj });

  useEffect(() => {
    // change only if a background Image is returned at all
    if (state.backgroundImage) {
      updateLoaded({ backgroundImage: false });
    }
    // `updateLoaded` dependency creates setState loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.backgroundImage]);

  useEffect(() => {
    const previewsLoaded = {};
    previews.forEach(p => {
      previewsLoaded[p.id] = false;
    });
    if (previews.length) updateLoaded(previewsLoaded);
    // `loaded` dependency creates setState loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [previews]);

  const hasChanges = Boolean(Object.keys(state).find(key => state[key] !== initialState[key]));

  const allImagesLoaded = !Object.keys(loaded).find(key => loaded[key] === false);

  return (
    <>
      <AssetEditorWrapper
        optionalComponent={
          <div className="PostToSocial_column_listTitle_buttons">
            {[types.landscape, types.instagram].map(key => (
              <AssetFormatIcon
                key={key}
                type={key}
                selected={type === key}
                onClick={() => {
                  fireEvent(GACategories.CUSTOM_ASSET, GATags.CHOSE_FORMAT, { key });
                  setType(key);
                }}
              />
            ))}
          </div>
        }
        onChange={object => setState({ ...state, ...object })}
        state={state}
        isCompetitionEdit
        isLoading={!previews.length || !themeData.length || !allImagesLoaded || loadingAssets}
        fonts={fonts}
        title={i18next.t('marketing.title.edit_competition_asset', { name })}
        error={error}
        hasChanges={hasChanges}
        onSave={() => {
          fireEvent(GACategories.CUSTOM_ASSET, GATags.SAVE_CUSTOMISED_COMPETITION_TEMPLATE, {
            venueId,
          });
          return saveConfig(venueId, competitionId, {
            textFont: state.textFont,
            textColor: state.textColor,
            secondaryTextColor: state.secondaryTextColor,
            secondaryTextFont: state.secondaryTextFont,
            type,
            backgroundImage: state.backgroundImage,
          })
            .then(() => history.push(returnRoute || paths.marketing.path))
            .catch(err => {
              if (err.error) {
                if (err.error.backgroundLandscape && Array.isArray(err.error.backgroundLandscape)) {
                  setError(err.error.backgroundLandscape[0]);
                } else if (
                  err.error.backgroundInstagram &&
                  Array.isArray(err.error.backgroundInstagram)
                ) {
                  setError(err.error.backgroundInstagram[0]);
                }
              } else {
                setError(err.message);
              }
            });
        }}
        onDefault={() => {
          fireEvent(GACategories.CUSTOM_ASSET, GATags.DISCARD_CHANGES, { venueId });
          setState(initialState);
        }}
      >
        <div className="AssetEditorWrapper_canvasSlider">
          {themeData
            .filter(td => td.format === type)
            .map(elm => (
              <CompetitionImageGenerator
                key={elm.id}
                templateHeight={
                  type === types.instagram ? templateHeightInstagram : templateHeightLandscape
                }
                templateWidth={
                  type === types.instagram ? templateWidthInstagram : templateWidthLandscape
                }
                preview={
                  previews.find(pre => pre.id === elm.id)
                    ? previews.find(pre => pre.id === elm.id).url
                    : null
                }
                image={state.backgroundImage}
              />
            ))}
        </div>
      </AssetEditorWrapper>
      {/* invisible images for correct canvas render */}
      {previews.map(prev => (
        <img
          key={prev.id}
          alt="preview"
          className="ImageGenerator_invisibleImage"
          onLoad={() => updateLoaded({ [prev.id]: true })}
          src={prev.url}
        />
      ))}
      <img
        alt="bgImage"
        className="ImageGenerator_invisibleImage"
        onLoad={() => updateLoaded({ backgroundImage: true })}
        src={state.backgroundImage}
      />
    </>
  );
};

CompetitionAssetEditor.propTypes = {
  getFonts: PropTypes.func.isRequired,
  getThemeConfigs: PropTypes.func.isRequired,
  venueId: PropTypes.number.isRequired,
  history: historyProps.isRequired,
  match: PropTypes.shape().isRequired,
  getBespokePreviews: PropTypes.func.isRequired,
  saveConfig: PropTypes.func.isRequired,
  getThemeData: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  venueId: getVenueID(state),
});

const mapDispatchToProps = dispatch => ({
  getFonts: venueId => dispatch(fetchFontsForSM(venueId)),
  saveConfig: (venueId, competitionId, config) =>
    dispatch(saveCompetitionConfig(venueId, competitionId, config)),
  getThemeData: venueId => dispatch(fetchThemeData(venueId)),

  getThemeConfigs: (venueId, competitionId, type) =>
    dispatch(fetchCompetitionThemeConfig(venueId, competitionId, type)),
  getBespokePreviews: (
    venueId,
    font,
    color,
    language,
    secondaryTextFont,
    secondaryTextColor,
    themeIds,
  ) =>
    dispatch(
      fetchBespokePreviews(
        venueId,
        font,
        color,
        language,
        secondaryTextFont,
        secondaryTextColor,
        themeIds,
      ),
    ),
});

export default connect(mapStateToProps, mapDispatchToProps)(CompetitionAssetEditor);

import React from 'react';
import { withRouter } from 'react-router-dom';
import ReactGA4 from 'react-ga4';
import { connect } from 'react-redux';
import { getVenue } from '../store/venue/selectors';

const GAPageTracker = ({ children, venue }) => {
  React.useEffect(() => {
    if (venue) {
      ReactGA4.set({ venueId: venue.id });
    }
  }, [venue]);

  return children;
};

const mapStateToProps = state => ({
  venue: getVenue(state),
});

export default withRouter(connect(mapStateToProps)(GAPageTracker));

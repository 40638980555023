import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { t } from 'i18next';
import { getVenue } from '../../store/venue/selectors';
import { transformImages } from '../../utils/general';
import { useDidMountEffect } from '../../utils/CustomHooks';
import { venueProps } from '../../utils/customPropTypes';
import Photos from '../SignupV2/Stage3/Steps/Photos';
import { saveVenuePhotos } from '../../store/api/venues';
import ProfileHeader from './ProfileHeader';
import SaveBar from './SaveBar/SaveBar';
import { refreshVenueThunk } from '../../store/venue/thunks';

const getProfilePic = images => images?.length > 0 && images.find(i => i.profilePicture)?.image;

const ProfilePagePhotos = ({ venue, savePhotos, refreshVenue }) => {
  const [venueImages, setVenueImages] = useState(() => transformImages(venue));
  const [fieldErrors, setFieldErrors] = useState(null);
  const [loading, setLoading] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);

  const profilePicChanged = getProfilePic(venueImages) !== getProfilePic(transformImages(venue));
  const hasNewImages = venueImages.filter(e => e.source !== 'db').length > 0;
  const hasOrderChanges = venueImages.some((vi, i) => vi.order !== i);

  useDidMountEffect(() => {
    setHasChanges(profilePicChanged || hasNewImages || hasOrderChanges);
  }, [profilePicChanged, hasNewImages, hasOrderChanges]);

  const save = () => {
    setLoading(true);
    setFieldErrors(null);
    savePhotos(
      venue?.id,
      venueImages.map((vi, i) => ({ ...vi, order: i })),
    )
      .then(r => {
        refreshVenue(venue.id);
        setHasChanges(false);
        const res = r.getResult();
        if (res?.saveErrors?.length === 0) {
          setVenueImages(res.saveSuccess);
        } else {
          // if some errors then update venue images partially
          setVenueImages([
            ...venueImages.filter(vi => res?.saveErrors.find(f => f.id === vi.id)),
            ...res.saveSuccess,
          ]);
          setFieldErrors(res.saveErrors);
        }
      })
      .finally(() => setLoading(false));
  };

  return (
    <>
      <ProfileHeader text={t('editProfile.tabs.photos')} />
      <div className="px-4 pt-4 sm:pt-12 lg:pt-16 mb-32 md:mb-24 w-full max-w-lg mx-auto lg:max-w-xl">
        <Photos
          venueImages={venueImages}
          setVenueImages={setVenueImages}
          venue={venue}
          fieldErrors={fieldErrors}
          setFieldErrors={setFieldErrors}
          uploadingImages={loading}
          showTitle={false}
        />
        {hasChanges && (
          <SaveBar
            onSave={save}
            saveCopy={t('photos.save')}
            loading={loading}
            onDiscard={() => setVenueImages(transformImages(venue))}
          />
        )}
      </div>
    </>
  );
};

const mapStateToProps = state => ({
  venue: getVenue(state),
});

const mapDispatchToProps = {
  savePhotos: saveVenuePhotos,
  refreshVenue: refreshVenueThunk,
};

ProfilePagePhotos.propTypes = {
  venue: venueProps.isRequired,
  savePhotos: PropTypes.func.isRequired,
  refreshVenue: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfilePagePhotos);

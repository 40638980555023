/* eslint-disable import/prefer-default-export */
import { t } from 'i18next';
import Asset from '../../components/Icons/Asset';
import InstagramIcon from '../../components/Icons/InstagramIcon';
import FacebookIcon from '../../components/Icons/FacebookIcon';
import TwitterIcon from '../../components/Icons/TwitterIcon';
import Story from '../../components/Icons/Story';

export const getByType = type => {
  switch (type) {
    case 'insta_post':
      return { Icon: InstagramIcon, text: t('instagram_post') };
    case 'fb_post':
      return { Icon: FacebookIcon, text: t('facebook_post') };
    case 'x_post':
      return { Icon: TwitterIcon, text: t('twitterx_post') };
    case 'fb_insta_story':
      return { Icon: Story, text: t('fb_insta_post') };
    default:
      return { Icon: Asset, text: null };
  }
};

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import PlainButton from '../PlainButton/PlainButton';
import Checkbox from '../Checkbox/Checkbox';
import ChevronIcon from '../Icons/ChevronIcon';

const ChannelCategory = ({
  channelGroup,
  groupName,
  selectedChannels,
  updateChannel,
  setOpenUponSearch,
  activeChannelCount,
  channelSelector,
}) => {
  const [open, setOpen] = useState(false);
  const [length, setLength] = useState(50);
  const listEl = useRef(null);

  const handleScroll = () => {
    if (
      listEl.current &&
      channelSelector &&
      listEl.current.scrollHeight - 200 < channelSelector.scrollTop
    ) {
      // needs to be set using previous value. Checking in the if(...) condtion otherwise doesn't see current length
      setLength(prevVal =>
        prevVal + 50 < channelGroup.length ? 50 + prevVal : channelGroup.length,
      );
    }
  };

  useEffect(() => {
    return () => {
      setLength(50);
      return channelSelector && channelSelector.removeEventListener('scroll', handleScroll, false);
    };
  }, [open]);

  useEffect(() => {
    if (channelSelector && open && channelGroup.length > 50) {
      channelSelector.addEventListener('scroll', handleScroll, false);
    }
  }, [open]);

  useEffect(() => {
    setOpen(setOpenUponSearch);
  }, [setOpenUponSearch]);

  const allChannelIds = channelGroup.map(c => c.id);
  const allChannelsSelected = allChannelIds.every(c => selectedChannels.includes(c));
  const someChannelsSelected =
    allChannelIds.some(c => selectedChannels.includes(c)) && !allChannelsSelected;

  return (
    <>
      <div className="px-4 py-2 flex items-center border-2 border-mpGrey mb-2 rounded-lg">
        <Checkbox
          checked={allChannelsSelected}
          onChange={() => updateChannel(allChannelIds)}
          indeterminate={someChannelsSelected}
        />

        <PlainButton
          onClick={() => setOpen(!open)}
          className="text-sm items-center flex justify-between font-semibold flex-1"
        >
          <p className="text-sm">
            {groupName} ({activeChannelCount}/{channelGroup.length})
          </p>
          <ChevronIcon className={`h-5 w-5 mt-1 ${!open ? 'rotate-90' : '-rotate-90'}`} />
        </PlainButton>
      </div>
      {open && (
        <div ref={listEl}>
          {channelGroup.slice(0, length).map(c => (
            <Checkbox
              onChange={() => updateChannel(c.id)}
              checked={selectedChannels.includes(c.id)}
              label={c.name}
              className="my-1 ml-8 gap-3"
              key={c.id}
            />
          ))}
        </div>
      )}
    </>
  );
};

ChannelCategory.propTypes = {
  setOpenUponSearch: PropTypes.bool,
  channelGroup: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
  groupName: PropTypes.string.isRequired,
  updateChannel: PropTypes.func.isRequired,
  selectedChannels: PropTypes.arrayOf(PropTypes.number).isRequired,
  activeChannelCount: PropTypes.number.isRequired,
  channelSelector: PropTypes.shape(),
};

ChannelCategory.defaultProps = {
  setOpenUponSearch: false,
  channelSelector: null,
};

export default ChannelCategory;

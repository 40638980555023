import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import i18n from 'i18next';
import { add, format, formatISO, parse, parseISO, sub } from 'date-fns';
import PageHeader from '../PageHeader/PageHeader';
import PageSubHeader from '../PageSubHeader/PageSubHeader';
import ScrollToTop from '../ScrollToTop/ScrollToTop';
import FormField from '../FormField/FormField';
import { getVenue } from '../../store/venue/selectors';
import { venueProps, offerProps } from '../../utils/customPropTypes';
import SecondaryButton from '../SecondaryButton/SecondaryButton';
import SpinnerButton from '../SpinnerButton/SpinnerButton';
import './styles.scss';
import Dropdown from '../Dropdown/Dropdown';
import Checkbox from '../Checkbox/Checkbox';
import { getCompetitionsForVenue } from '../../store/offers/thunks';
import { getCompetitions, getOfferFormErrors } from '../../store/offers/selectors';
import { generateTimeIntervals } from '../../utils/general';
import routes from '../../routes/paths';
import TextArea from '../TextArea/TextArea';
import Input from '../Input/Input';
import { getTranslatedDay } from '../../i18n';
import RadioInput from '../RadioInput/RadioInput';
import { fireEvent, GACategories, GATags } from '../../utils/trackingHelper';

const initialDays = {
  monday: false,
  tuesday: false,
  wednesday: false,
  thursday: false,
  friday: false,
  saturday: false,
  sunday: false,
};

const getRadioState = value => {
  if (value === null) {
    return 'custom';
  }
  if (value === '') {
    return false;
  }
  return 'competition';
};

const DatePicker = ({ label, ...rest }) => (
  <div className="mr-4">
    <p>{label}</p>
    {/* eslint-disable-next-line react/jsx-props-no-spreading */}
    <input className="DatePicker text-sm" type="date" {...rest} />
  </div>
);

DatePicker.propTypes = {
  label: PropTypes.string.isRequired,
};

const OfferForm = ({
  venue,
  fetchCompetitions,
  saveAction,
  competitions,
  navigate,
  offer,
  title,
  formErrors,
}) => {
  const {
    availability: { specificDays, competition, allDay, allWeek },
  } = offer;
  // this is a little more complex because the date can be
  // undefined ->  set it to a year from now
  // null -> offer is ongoing so must be '' (controlled component)
  // a date -> parse it
  const getToDateUTC = () => {
    if (offer.toDateUtc === null) {
      return '';
    }
    if (typeof offer.toDateUtc === 'undefined') {
      return add(new Date(), { years: 1 });
    }
    return parseISO(offer.toDateUtc);
  };

  const [name, setName] = useState(offer.name);
  const [radioButton, setRadioButton] = useState(getRadioState(competition?.id));
  const [fromDateUtc, setFromDateUtc] = useState(
    typeof offer.fromDateUtc === 'undefined' ? new Date() : parseISO(offer.fromDateUtc),
  );
  const [toDateUtc, setToDateUtc] = useState(getToDateUTC());
  const [description, setDescription] = useState(offer.description);
  const [nbVisits, setVisits] = useState(offer.nbVisits);
  const [hourFrom, setHourFrom] = useState(
    specificDays.fromTimeUtc ? format(parseISO(specificDays.fromTimeUtc), 'HH:mm') : null,
  );
  const [hourTo, setHourTo] = useState(
    specificDays.toTimeUtc ? format(parseISO(specificDays.toTimeUtc), 'HH:mm') : null,
  );
  const [availability, setAvailability] = useState({
    competition: competition?.id,
    duration: competition?.period,
    allDay: allDay ?? false,
    allWeek: allWeek ?? false,
    days: {
      monday: specificDays.monday,
      tuesday: specificDays.tuesday,
      wednesday: specificDays.wednesday,
      thursday: specificDays.thursday,
      friday: specificDays.friday,
      saturday: specificDays.saturday,
      sunday: specificDays.sunday,
    },
  });

  useEffect(() => {
    fetchCompetitions(venue.id);
  }, [fetchCompetitions, venue.id]);

  const save = () => {
    const newOffer = {
      name,
      fromDateUtc: formatISO(fromDateUtc),
      toDateUtc: toDateUtc ? formatISO(toDateUtc) : null,
      description,
      nbVisits,
      availability: {
        allDay: availability.allDay,
        allWeek: availability.allWeek,
        competition: {
          id: availability.competition,
          period: availability.duration,
        },
        specificDays: {
          ...availability.days,
          fromTimeUtc: hourFrom ? formatISO(parse(hourFrom, 'HH:mm', new Date())) : null,
          toTimeUtc: hourTo ? formatISO(parse(hourTo, 'HH:mm', new Date())) : null,
        },
      },
    };
    saveAction(venue.id, newOffer, navigate);
  };

  const optionsDuration = [
    { key: 'competitionDays', value: i18n.t('offers.form.allday') },
    { key: 'competitionHours', value: i18n.t('offers.form.fixture') },
  ];

  const getErrorMessage = field =>
    formErrors && formErrors[field] && Array.isArray(formErrors[field]) && formErrors[field][0];
  const saveDisabled = !(
    Boolean(name) &&
    Boolean(fromDateUtc) &&
    (Boolean(toDateUtc) || toDateUtc === '') &&
    Boolean(nbVisits)
  );

  const fire = field => fireEvent(GACategories.OFFER, GATags.UPDATE_FIELD, { field });

  return (
    <>
      <ScrollToTop />
      <PageHeader title={title} />
      <PageSubHeader>
        <p>{i18n.t('offers.main.create')}</p>
      </PageSubHeader>
      <div className="OfferForm GlobalPad">
        <FormField
          number={1}
          title={i18n.t('offers.form.name').toUpperCase()}
          subtitle={i18n.t('offers.form.name_sub')}
        >
          <div className="OfferForm_nameInput_container">
            <div>
              <Input
                className="OfferForm_nameInput"
                value={name}
                onChange={val => {
                  fire('name');
                  setName(val);
                }}
                disabled={false}
                errorMessage={getErrorMessage('name')}
                showError={getErrorMessage('name') !== null}
                maxLength={60}
              />
            </div>
            <div className="OfferForm_description_container">
              <TextArea
                value={description}
                onChange={val => {
                  fire('description');
                  setDescription(val);
                }}
                noBorder
                className="OfferForm_description"
                maxLength={100}
              />
              {getErrorMessage('description') && (
                <p className="OfferForm_error_message">{getErrorMessage('description')}</p>
              )}
            </div>
          </div>
        </FormField>
        <FormField
          autoComplete="disabled"
          number={2}
          title={i18n.t('offers.form.when').toUpperCase()}
          subtitle={i18n.t('offers.form.when_sub')}
          disabled={name === ''}
        >
          <div className="OfferForm_datePicker">
            <DatePicker
              label={i18n.t('general.from')}
              value={format(fromDateUtc, 'yyyy-MM-dd')}
              onChange={({ currentTarget: { value } }) => {
                fire('from');
                if (value) setFromDateUtc(parse(value, 'yyyy-MM-dd', new Date()));
              }}
              max={format(add(new Date(), { years: 2 }), 'yyyy-MM-dd')}
              min={format(sub(new Date(), { days: 1 }), 'yyyy-MM-dd')}
            />
            {getErrorMessage('fromDateUtc') && (
              <p className="OfferForm_error_message">{getErrorMessage('fromDateUtc')}</p>
            )}

            <DatePicker
              label={i18n.t('general.to')}
              value={toDateUtc ? format(toDateUtc, 'yyyy-MM-dd') : ''}
              onChange={({ currentTarget: { value } }) => {
                fire('to');
                if (value) setToDateUtc(parse(value, 'yyyy-MM-dd', new Date()));
              }}
              disabled={toDateUtc === ''}
              max={format(add(new Date(), { years: 2 }), 'yyyy-MM-dd')}
              min={format(new Date(), 'yyyy-MM-dd')}
            />

            {getErrorMessage('toDateUtc') && (
              <p className="OfferForm_error_message">{getErrorMessage('toDateUtc')}</p>
            )}
            <Checkbox
              onChange={() => {
                fire('ongoing');
                setToDateUtc(toDateUtc !== '' ? '' : add(new Date(), { years: 1 }));
              }}
              label={i18n.t('offers.ongoing')}
              checked={toDateUtc === ''}
            />
          </div>
        </FormField>
        <FormField
          autoComplete="disabled"
          number={3}
          title={i18n.t('offers.form.visits').toUpperCase()}
          subtitle={i18n.t('offers.form.visits_sub')}
          type="single"
          inputType="number"
          value={nbVisits}
          inputErrorMessage={getErrorMessage('nbVisits')}
          onValueChange={val => {
            if ((val < 11 && val > 0) || val === '') {
              fire('visits');
              setVisits(`${val}`);
            }
          }}
          disabled={name === ''}
        />
        <FormField
          noBorder
          number={4}
          title={i18n.t('offers.form.availability').toUpperCase()}
          subtitle={i18n.t('offers.form.availability_sub')}
          disabled={nbVisits === ''}
        >
          <RadioInput
            options={{
              competition: 'offers.form.availability.competition_days',
              custom: 'offers.on_custom_days',
            }}
            active={radioButton}
            onClick={a => {
              fire(a);
              setRadioButton(a);
            }}
          />
          <div className="OfferForm_availability">
            {radioButton === 'competition' && (
              <div className="OfferForm_availability_wrapper">
                <Dropdown
                  containerStyle="mt-5 mr-1"
                  buttonStyle="w-40 py-1 text-sm"
                  onClickItem={({ key }) => {
                    setHourFrom('');
                    setHourTo('');
                    setAvailability({
                      ...availability,
                      days: { ...initialDays },
                      competition: availability.competition === key ? undefined : key,
                    });
                  }}
                  selected={{
                    key: availability.competition,
                    value: competitions.find(c => availability.competition === c.id)?.name,
                  }}
                  disabled={nbVisits === ''}
                  label={i18n.t('offers.form.competitions')}
                  data={competitions.map(c => ({ key: c.id, value: c.name }))}
                />
                <p className="text-xs uppercase px-3 font-semibold pb-1">
                  {i18n.t('offers.form.availability.during')}
                </p>
                <Dropdown
                  containerStyle="mt-5 mr-1"
                  buttonStyle="w-40 py-1 text-sm"
                  onClickItem={({ key }) => {
                    setHourFrom('');
                    setHourTo('');
                    setAvailability({
                      ...availability,
                      days: { ...initialDays },
                      duration: key,
                    });
                  }}
                  selected={{
                    key: availability.duration,
                    value: optionsDuration.find(({ key }) => key === availability.duration)?.value,
                  }}
                  disabled={nbVisits === ''}
                  data={optionsDuration}
                />
              </div>
            )}
          </div>
          {radioButton === 'custom' && (
            <div className="OfferForm_availability">
              <div className="flex items-end">
                {!availability.allWeek && (
                  <Dropdown
                    containerStyle="mt-5 mr-1"
                    buttonStyle="w-36 py-1 text-sm"
                    onClickItem={({ value }) => {
                      Object.keys(availability.days).forEach((d, i) => {
                        if (value.toLowerCase() === getTranslatedDay(i).toLowerCase()) {
                          setAvailability({
                            ...availability,
                            days: { ...availability.days, [d]: !availability.days[d] },
                            competition: null,
                            duration: null,
                          });
                        }
                      });
                    }}
                    selected={Object.keys(availability.days)
                      .map((d, i) => availability.days[d] === true && getTranslatedDay(i))
                      .filter(d => typeof d === 'string')}
                    disabled={nbVisits === ''}
                    label={i18n.t('offers.form.customDay')}
                    data={Object.keys(initialDays).map((day, i) => ({
                      key: day,
                      value: getTranslatedDay(i),
                    }))}
                    isMulti
                  />
                )}
                <Checkbox
                  className="OfferForm_checkBox"
                  onChange={() => {
                    fire('everyDay');
                    setAvailability({
                      ...availability,
                      allWeek: !availability.allWeek,
                      competition: null,
                      duration: null,
                    });
                  }}
                  label={i18n.t('offers.every_day')}
                  checked={availability.allWeek}
                />
              </div>
              <p className="text-xs uppercase px-3 font-semibold pb-1">{i18n.t('general.and')}</p>
              <div className="flex items-end">
                {!availability.allDay && (
                  <Dropdown
                    containerStyle="mt-5 mx-1"
                    buttonStyle="w-24 py-1 text-sm"
                    label={i18n.t('general.from')}
                    onClickItem={({ key }) => setHourFrom(key)}
                    selected={{ key: hourFrom, value: hourFrom }}
                    disabled={nbVisits === ''}
                    data={generateTimeIntervals(30)}
                    error={getErrorMessage('fromTimeUtc')}
                    nullableValue
                  />
                )}
                <Checkbox
                  className="OfferForm_checkBox"
                  onChange={() => {
                    fire('anyTime');
                    setAvailability({
                      ...availability,
                      allDay: !availability.allDay,
                      competition: null,
                      duration: null,
                    });
                  }}
                  label={i18n.t('offers.any_time')}
                  checked={availability.allDay}
                />
              </div>
              {!availability.allDay && (
                <div>
                  <Dropdown
                    containerStyle="mt-5 mx-1"
                    buttonStyle="w-24 py-1 text-sm"
                    label={i18n.t('general.to')}
                    onClickItem={({ key }) => setHourTo(key)}
                    selected={{ key: hourTo, value: hourTo }}
                    disabled={nbVisits === ''}
                    data={generateTimeIntervals(30, hourFrom)}
                    error={getErrorMessage('toTimeUtc')}
                    nullableValue
                  />
                </div>
              )}
              <p className="text-xs uppercase px-3 font-semibold pb-1">
                {i18n.t('offers.form.availability.each_selected_day')}
              </p>
            </div>
          )}
          {!getErrorMessage('availabilityType') && (
            <p className="OfferForm_error_message">{getErrorMessage('availabilityType')}</p>
          )}
        </FormField>
        <div className="flex pb-4">
          <SpinnerButton
            disabled={saveDisabled}
            className={`bg-secondary py-1 w-40 mr-3 rounded uppercase font-semibold ${
              saveDisabled ? 'opacity-20' : ''
            }`}
            onClick={save}
          >
            {i18n.t('general.save')}
          </SpinnerButton>
          <SecondaryButton
            className="w-44"
            label={i18n.t('general.cancel')}
            onClick={() => navigate(routes.offers.path)}
          />
        </div>
      </div>
    </>
  );
};

OfferForm.propTypes = {
  formErrors: PropTypes.shape(),
  venue: venueProps.isRequired,
  fetchCompetitions: PropTypes.func.isRequired,
  saveAction: PropTypes.func.isRequired,
  navigate: PropTypes.func.isRequired,
  competitions: PropTypes.arrayOf(
    PropTypes.shape({ id: PropTypes.number.isRequired, name: PropTypes.string.isRequired })
      .isRequired,
  ).isRequired,
  offer: offerProps,
  title: PropTypes.string.isRequired,
};

OfferForm.defaultProps = {
  formErrors: null,
  offer: {
    id: null,
    name: '',
    from: '',
    to: '',
    nbVisits: '',
    availability: {
      competition: { id: null, period: null },
      specificDays: { ...initialDays, hourFrom: '', hourTo: '' },
    },
  },
};

const mapStateToProps = state => ({
  venue: getVenue(state),
  competitions: getCompetitions(state),
  formErrors: getOfferFormErrors(state),
});

const mapDispatchToProps = dispatch => ({
  fetchCompetitions: venueID => dispatch(getCompetitionsForVenue(venueID)),
});

export default connect(mapStateToProps, mapDispatchToProps)(OfferForm);

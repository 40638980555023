import React from 'react';
import PropTypes from 'prop-types';

const HorizontalTV = ({ className }) => (
  <svg viewBox="0 0 32 32" fill="none" className={`stroke-current ${className}`}>
    <rect
      width="17.4516"
      height="10.9072"
      transform="matrix(1 0 -0.156434 0.987688 8.12695 9.177)"
      strokeWidth="1.63609"
    />
    <path d="M9.60156 22.8228L19.7825 22.8228" strokeWidth="1.63609" />
  </svg>
);

HorizontalTV.propTypes = {
  className: PropTypes.string.isRequired,
};

export default HorizontalTV;

import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';
import PlainButton from '../../../components/PlainButton/PlainButton';

const RoundedThemeSelector = ({ selected, onSelect, number }) => (
  <PlainButton onClick={() => onSelect(number)}>
    <div className={`ThemeSelector ${selected ? 'ThemeSelector_selected' : ''}`}>
      <p className="font-semibold text-sm">
        {number.toLocaleString('en-US', { minimumIntegerDigits: 2 })}
      </p>
    </div>
  </PlainButton>
);

RoundedThemeSelector.propTypes = {
  number: PropTypes.number.isRequired,
  selected: PropTypes.bool.isRequired,
  onSelect: PropTypes.func.isRequired,
};

export default RoundedThemeSelector;

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { t } from 'i18next';
import { getFormattedDateRange } from '../../utils/general';
import routes from '../../routes/paths';
import { getAssetGroups } from '../../store/api/venues';
import { getVenueID } from '../../store/venue/selectors';
import LoadingAnimation from '../../components/LoadingAnimation/LoadingAnimation';
import AssetTile from './AssetTile';

const AssetGroupTiles = ({ venueId, fetchAssetGroups }) => {
  const [assetGroups, setAssetsGroups] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchAssetGroups(venueId)
      .then(res => setAssetsGroups(res.getResult()))
      .finally(() => setLoading(false));
  }, [fetchAssetGroups, venueId]);

  if (loading) {
    return <LoadingAnimation size={56} />;
  }

  return assetGroups?.length > 0 ? (
    <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-4">
      {assetGroups.map(d => (
        <Link
          className="flex flex-col outline outline-mpGrey bg-offWhite rounded-2xl"
          to={`${routes.promotionalHub.path}/${d.id}`}
          key={d.name}
        >
          <AssetTile
            data={{
              image: d.displayImage,
              title: d.name,
              line1: getFormattedDateRange(d.fixturesStartDate, d.fixturesEndDate),
              line2: t('promoHub_assets', { count: d.assetCount }),
            }}
          />
        </Link>
      ))}
    </div>
  ) : (
    <p>{t('promoHub_noAssets')}</p>
  );
};

AssetGroupTiles.propTypes = {
  venueId: PropTypes.number.isRequired,
  fetchAssetGroups: PropTypes.func.isRequired,
};

const mdtp = {
  fetchAssetGroups: getAssetGroups,
};

const mstp = state => ({
  venueId: getVenueID(state),
});

export default connect(mstp, mdtp)(AssetGroupTiles);

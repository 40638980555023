import React from 'react';
import PropTypes from 'prop-types';

const CrownIcon = ({ className, isActive }) => (
  <svg className={`fill-current ${className}`} viewBox="0 0 24 24" fill="none">
    {isActive ? (
      <>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.6779 4.5625L17.0694 8.92074L22.3671 6.21259L18.742 20.8225H3.46313L4.46598 6.21259L8.90584 8.92074L13.6779 4.5625ZM13.0496 8.52933L8.90774 12.312L6.87056 11.0694L6.386 18.1288H16.6724L18.424 11.0694L15.9932 12.312L13.0496 8.52933Z"
        />
        <path d="M12.2545 13.5495L13.4048 14.8964L11.8278 16.2432L10.6775 14.8964L12.2545 13.5495Z" />
        <path d="M8.98173 13.5495L10.132 14.8964L8.55509 16.2432L7.40477 14.8964L8.98173 13.5495Z" />
        <path d="M15.5272 13.5495L16.6775 14.8964L15.1005 16.2432L13.9502 14.8964L15.5272 13.5495Z" />
      </>
    ) : (
      <>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.5521 5.35535L16.854 9.59848L21.5784 7.18344L18.328 20.2832H4.04761L4.9468 7.18344L8.90612 9.59848L13.5521 5.35535ZM13.1752 7.73545L8.90726 11.6333L6.38955 10.0975L5.80133 18.667H17.0862L19.2125 10.0975L16.2083 11.6333L13.1752 7.73545Z"
        />
        <path d="M12.2544 13.549L13.4047 14.8959L11.8277 16.2427L10.6774 14.8959L12.2544 13.549Z" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.2544 13.549L13.4047 14.8959L11.8277 16.2427L10.6774 14.8959L12.2544 13.549ZM11.8345 14.8959L12.0087 15.0999L12.2476 14.8959L12.0734 14.6918L11.8345 14.8959Z"
        />
        <path d="M8.4362 13.549L9.58651 14.8959L8.00956 16.2427L6.85924 14.8959L8.4362 13.549Z" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.4362 13.549L9.58651 14.8959L8.00956 16.2427L6.85924 14.8959L8.4362 13.549ZM8.01632 14.8959L8.19056 15.0999L8.42943 14.8959L8.25519 14.6918L8.01632 14.8959Z"
        />
        <path d="M16.0726 13.549L17.2229 14.8959L15.6459 16.2427L14.4956 14.8959L16.0726 13.549Z" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.0726 13.549L17.2229 14.8959L15.6459 16.2427L14.4956 14.8959L16.0726 13.549ZM15.6527 14.8959L15.8269 15.0999L16.0658 14.8959L15.8916 14.6918L15.6527 14.8959Z"
        />
      </>
    )}
  </svg>
);

CrownIcon.propTypes = {
  className: PropTypes.string,
  isActive: PropTypes.bool,
};

CrownIcon.defaultProps = {
  className: '',
  isActive: false,
};

export default CrownIcon;

import React from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18next';
import FacilitiesSelector from '../../../../components/FacilitiesSelector/FacilitiesSelector';

const Facilities = ({ data, onChange }) => (
  <>
    <h1 className="headingFont text-4xl md:text-5xl lg:text-6xl pb-2 mr-32 md:mr-0">
      {t('editProfile.general.facilities')}
    </h1>
    <p className="opacity-60 md:text-xl pb-4">{t('signup_venue_facilitiesSubtitle')}</p>
    <div className="p-4 md:p-8 flex flex-wrap gap-2 bg-white rounded-2xl border border-mpGrey">
      <FacilitiesSelector data={data} onClick={onChange} />
    </div>
  </>
);

Facilities.propTypes = {
  data: PropTypes.shape().isRequired,
  onChange: PropTypes.func.isRequired,
};

export default Facilities;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18next';
import { connect } from 'react-redux';
import Claims from '../../components/Claims/Claims';
import UnprotectedLayout from '../UnprotectedLayout/UnprotectedLayout';
import Input from '../../components/Input/Input';
import SpinnerButton from '../../components/SpinnerButton/SpinnerButton';
import TermsAndConditionsSnippet from '../../components/TermsAndConditionsSnippet/TermsAndConditionsSnippet';
import { getIsMobile } from '../../store/app/selectors';
import PasswordSection from './PasswordSection';
import { validatePassword } from '../../utils/FormValidation';
import { emailCheck as emailCheckReq, preSignUp as preSignUpReq } from '../../store/api/member';
import { signUpThunk } from '../../store/auth/thunks';
import { GACategories, GATags, fireEvent } from '../../utils/trackingHelper';
import routes from '../../routes/paths';
import { navigate } from '../../store/navigation/thunks';
import AppUserErrorModal from './AppUserErrorModal';

const STEPS = {
  EMAIL: 'EMAIL',
  NAME: 'NAME',
  PASSWORD: 'PASSWORD',
};

const styles = {
  container:
    'max-w-7xl container mx-auto md:flex pt-8 sm:pt-14 md:pt-24 px-4 md:px-8 gap-x-6 lg:gap-x-32',
};

const Stage1 = ({ isMobile, preSignUp, emailCheck, signUp }) => {
  const [state, setState] = useState({
    email: '',
    password: '',
    firstName: '',
    lastName: '',
    acceptedMarketing: false,
  });

  const steps = Object.values(STEPS);
  const [error, setError] = useState(null);
  const [appUserError, setAppUserError] = useState(false);
  const [step, setStep] = useState(steps[0]);
  const [loading, setLoading] = useState(false);

  const passwordIsValid = !Object.values(validatePassword(state.password)).some(
    condition => !condition,
  );

  const sendEmailCheck = () => {
    setLoading(true);
    setError(null);
    return emailCheck(state.email)
      .then(r => r.getResult())
      .then(r => {
        if (r.pubPortalUser) {
          setError(t('signup.pubPortalUser.error'));
          throw new Error();
        }
        if (r.appUser) {
          setAppUserError(true);
          throw new Error();
        }
        preSignUp(state);
      })
      .finally(() => setLoading(false));
  };

  const sendSignup = () => {
    setLoading(true);
    fireEvent(GACategories.SIGN_UP_V2, GATags.ATTEMPT, { email: state.email });
    return signUp(state)
      .then(() => {
        fireEvent(GACategories.SIGN_UP_V2, GATags.SUCCESS, { email: state.email });
        navigate(routes.signupV2Stage2.path);
      })
      .catch(() => setLoading(false));
  };

  const pressContinue = () => {
    const index = steps.findIndex(e => e === step);
    if (step === STEPS.EMAIL) {
      fireEvent(GACategories.SIGN_UP_V2, GATags.EMAIL, { email: state.email });
      return sendEmailCheck().then(() => setStep(steps[index + 1]));
    }
    if (isMobile && step !== steps[steps.length - 1]) {
      fireEvent(GACategories.SIGN_UP_V2, GATags.STEP, { email: state.email, step });
      return setStep(steps[index + 1]);
    }

    return sendSignup();
  };

  const fields = [
    {
      id: 'email',
      label: 'auth.email',
      placeholder: 'auth_emailPlaceholder',
      type: 'email',
      grouping: STEPS.EMAIL,
      error,
      onBlur: () => {
        if (step !== STEPS.EMAIL) {
          sendEmailCheck();
        }
      },
    },
    {
      id: 'firstName',
      label: 'auth.signup.form.first_name',
      placeholder: 'settings.first_name_placeholder',
      grouping: STEPS.NAME,
    },
    {
      id: 'lastName',
      label: 'auth.signup.form.last_name',
      placeholder: 'settings.last_name_placeholder',
      grouping: STEPS.NAME,
    },
  ];

  const shouldShowField = name => {
    if (!isMobile && step === STEPS.EMAIL && name !== STEPS.EMAIL) {
      return false;
    }
    if (!isMobile) {
      return true;
    }

    return name === step;
  };

  const isFormValid = () => {
    const { email, firstName, lastName } = state;

    if (step === STEPS.EMAIL) {
      return email.length > 0;
    }

    if (isMobile) {
      if (step === STEPS.NAME) {
        return firstName.length > 0 && lastName.length > 0;
      }
      if (step === STEPS.PASSWORD) {
        return passwordIsValid;
      }
    }

    return passwordIsValid && email.length > 0 && firstName.length > 0 && lastName.length > 0;
  };

  const enterKey = event => {
    if (event.key === 'Enter' && isFormValid()) {
      pressContinue();
    }
  };

  const renderField = ({ id, label, placeholder, grouping, onBlur, error: errorMessage, type }) => (
    <div key={id} className={shouldShowField(grouping) ? '' : 'invisible h-0'}>
      <Input
        showError={Boolean(errorMessage)}
        errorMessage={errorMessage}
        id={id}
        required
        type={type}
        value={state[id]}
        onChange={val => setState({ ...state, [id]: val })}
        label={t(label)}
        onKeyPress={enterKey}
        onBlur={onBlur}
        placeholder={t(placeholder)}
        className={`my-0 rounded-lg py-3 px-4 mb-4 ${errorMessage ? '' : 'border border-mpGrey'}`}
        data-test-id={id}
      />
    </div>
  );

  return (
    <UnprotectedLayout noPad>
      {appUserError && <AppUserErrorModal onClose={() => setAppUserError(false)} t={t} />}
      <div className={styles.container}>
        <div className="flex-1">
          <h1 className="headingFont mb-8 text-4xl md:text-6xl lg:text-7xl">{t('signup.title')}</h1>
          <Claims />
        </div>
        <div className="flex-1 pt-6 md:pt-0">
          {fields.map(f => renderField(f))}
          {shouldShowField(STEPS.PASSWORD) && (
            <PasswordSection state={state} setState={setState} onKeyPress={enterKey} />
          )}
          <SpinnerButton
            className={`w-full p-4  font-semibold rounded-lg mt-6 ${
              isFormValid() ? 'bg-black text-white' : 'bg-mpGrey text-standardGrey'
            }`}
            onClick={pressContinue}
            disabled={!isFormValid()}
            loading={loading}
          >
            {t('general.continue')}
          </SpinnerButton>

          <TermsAndConditionsSnippet />
        </div>
      </div>
    </UnprotectedLayout>
  );
};

const mapStateToProps = state => ({
  isMobile: getIsMobile(state),
});

const mapDispatchToProps = {
  preSignUp: preSignUpReq,
  emailCheck: emailCheckReq,
  signUp: signUpThunk,
};

Stage1.propTypes = {
  isMobile: PropTypes.bool.isRequired,
  preSignUp: PropTypes.func.isRequired,
  emailCheck: PropTypes.func.isRequired,
  signUp: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(Stage1);

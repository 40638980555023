import React from 'react';
import PropTypes from 'prop-types';

const HelpIcon = ({ className }) => (
  <svg className={`fill-current ${className}`} viewBox="0 0 24 24" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.9495 4.62408C8.88271 4.62408 5.01625 7.8713 4.31355 11.877C3.61084 15.8826 6.338 19.1298 10.4048 19.1298C14.4717 19.1298 18.3381 15.8826 19.0408 11.877C19.7435 7.8713 17.0164 4.62408 12.9495 4.62408ZM2.67718 11.877C3.53604 6.98116 8.26172 3.01233 13.2323 3.01233C18.2028 3.01233 21.536 6.98116 20.6772 11.877C19.8183 16.7728 15.0926 20.7416 10.1221 20.7416C5.15152 20.7416 1.81832 16.7728 2.67718 11.877Z"
    />
    <path d="M12.1851 15.8263C12.0736 16.4948 11.4337 17.0368 10.7559 17.0368C10.0781 17.0368 9.61901 16.4948 9.73053 15.8263C9.84205 15.1577 10.4819 14.6157 11.1597 14.6157C11.8375 14.6157 12.2966 15.1577 12.1851 15.8263Z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.74362 9.69377C9.00751 8.11176 10.5216 6.82928 12.1255 6.82928H12.9885C14.6278 6.82928 15.738 8.14004 15.4683 9.75694C15.312 10.6936 14.7109 11.5737 13.851 12.1246L12.8893 12.7408C12.56 12.9518 12.3297 13.2889 12.2699 13.6476L12.243 13.8087H10.6067L10.6335 13.6476C10.7795 12.7725 11.3412 11.9502 12.1445 11.4355L13.1063 10.8193C13.4921 10.5721 13.7618 10.1772 13.8319 9.75694C13.9529 9.03146 13.4548 8.44335 12.7193 8.44335H11.8563C11.1561 8.44335 10.4952 9.00318 10.38 9.69377L10.3037 10.151H8.66736L8.74362 9.69377Z"
    />
  </svg>
);

HelpIcon.propTypes = {
  className: PropTypes.string,
};

HelpIcon.defaultProps = {
  className: '',
};

export default HelpIcon;

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { userIsOnFanzo, userIsOnOnTap } from './utils/environment';
import App from './routes';
import configureStore from './store';
import './i18n';
import ErrorBoundary from './BugSnag';

const Matchpint = React.lazy(() => import('./theme/matchpint/Matchpint'));
const OnTap = React.lazy(() => import('./theme/ontap/OnTap'));

const store = configureStore();
ReactDOM.render(
  <ErrorBoundary>
    <Provider store={store}>
      <React.Suspense fallback={<div />}>
        {userIsOnFanzo && <Matchpint />}
        {userIsOnOnTap && <OnTap />}
      </React.Suspense>
      <App />
    </Provider>
  </ErrorBoundary>,
  document.getElementById('root'),
);

export const getIsMobile = state => state.app.isMobile;

export const getIsTablet = state => state.app.isTablet;

export const getIsDesktop = state => state.app.isDesktop;

export const getGeoLocale = state => state.app.geoLocale;

export const getCountries = state => state.app.countries;

export const getShowMobileSidebar = state => state.app.showMobileSidebar;

export const getShowSwitchProfiles = state => state.app.showSwitchProfiles;

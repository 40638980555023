import React from 'react';
import PropTypes from 'prop-types';

const CloseIcon = ({ className }) => (
  <svg className={`fill-current ${className}`} viewBox="0 0 24 24">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.6616 7.63638L6.98358 17.5278L5.69464 16.006L17.3726 6.11462L18.6616 7.63638Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.469 17.5278L7.0909 7.63638L8.88751 6.11463L17.2656 16.006L15.469 17.5278Z"
    />
  </svg>
);

CloseIcon.propTypes = {
  className: PropTypes.string,
};

CloseIcon.defaultProps = {
  className: '',
};

export default CloseIcon;

import React from 'react';
import { withRouter } from 'react-router-dom';
import i18next from 'i18next';
import UnprotectedLink from '../UnprotectedLink/UnprotectedLink';
import { publicRoutes } from '../../routes/paths';

const LegalsBar = () => {
  const legalsRoutes = [
    { path: publicRoutes.termsOfService, text: i18next.t('titles.termsOfService') },
    { path: publicRoutes.termsAndConditions, text: i18next.t('titles.termsAndConditions') },
    { path: publicRoutes.privacyPolicy, text: i18next.t('titles.privacyPolicy') },
    { path: publicRoutes.cookiePolicy, text: i18next.t('titles.cookiePolicy') },
  ];
  return (
    <div className="hidden sm:flex flex-row justify-center">
      {legalsRoutes.map(link => (
        <UnprotectedLink key={link.path} path={link.path} text={link.text} />
      ))}
    </div>
  );
};

export default withRouter(LegalsBar);

export const getIsFetchingOffers = state => state.offers.isFetching;

export const getOffersSelector = state => state.offers.offers;

export const getIsFetchingOffer = state => state.offers.isFetchingOffer;

export const getOfferSelector = state => state.offers.offer;

export const getCompetitions = state => state.offers.competitions;

export const getTotalOffers = state => state.offers.totalOffers;

export const getIsLoadingMoreOffers = state => state.offers.isFetchingMoreOffers;

export const getOfferFormErrors = state => state.offers.formErrors;

import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18next';
import { connect } from 'react-redux';
import { fetchInvoices as fetchInvoicesApi } from '../../store/api/venues';
import ContentBlock from './ContentBlock';
import PlainButton from '../../components/PlainButton/PlainButton';
import { getLocaleDate } from '../../i18n';
import ChevronIcon from '../../components/Icons/ChevronIcon';
import SpinnerButton from '../../components/SpinnerButton/SpinnerButton';
import { venueProps } from '../../utils/customPropTypes';
import { formatPrice } from '../../utils/general';

const limit = 4;

const InvoicesSection = ({ venue, fetchInvoices, hasManualPay }) => {
  const [invoices, setInvoices] = useState({ paid: [], upcoming: [] });
  const [loading, setLoading] = useState(true);
  const [showLoadMore, setShowLoadMore] = useState(false);

  const loadInvoices = useCallback(
    (offset = 0) => {
      setLoading(true);
      fetchInvoices(venue.id, offset)
        .then(result => {
          setShowLoadMore(result.paid.length === limit);
          setInvoices(prev => ({
            paid: [...prev.paid, ...result.paid],
            upcoming: [...result.upcoming],
          }));
        })
        .finally(() => setLoading(false));
    },
    [fetchInvoices, venue.id],
  );

  useEffect(() => {
    loadInvoices();
  }, [loadInvoices]);

  const hasUnpaidInvoices = invoices?.upcoming?.length > 0;
  const payLastInvoiceLink = invoices?.upcoming?.[0]?.payURL;

  return (
    <ContentBlock
      title="billing_invoicesTitle"
      buttonText="billing_invoicesBtn"
      onClick={() => window.location.replace(payLastInvoiceLink)}
      showButton={Boolean(hasManualPay && hasUnpaidInvoices && payLastInvoiceLink)}
    >
      {!hasUnpaidInvoices && !invoices?.paid?.length > 0 ? (
        <p className="opacity-60 pt-2">{t('billing_noInvoices')}</p>
      ) : (
        <>
          <Invoices
            invoices={invoices.upcoming}
            title={t('financials.invoices_upcoming')}
            statusText={t('settings.invoices.pending')}
            noDataText={t('billing_noInvoicesUpcoming')}
          />
          <Invoices
            invoices={invoices.paid}
            title={t('billing_invoicesPast')}
            statusText={t('settings.invoices.paid')}
            noDataText={t('billing_noInvoicesPast')}
          />
          {showLoadMore && (
            <SpinnerButton
              className="rounded-lg border border-mpGrey text-center w-full py-3.5 font-semibold"
              loading={loading}
              onClick={() => loadInvoices(invoices.paid.length)}
            >
              {t('general.load_more')}
            </SpinnerButton>
          )}
        </>
      )}
    </ContentBlock>
  );
};

InvoicesSection.propTypes = {
  venue: venueProps.isRequired,
  fetchInvoices: PropTypes.func.isRequired,
  hasManualPay: PropTypes.bool,
};

InvoicesSection.defaultProps = {
  hasManualPay: false,
};

const mdtp = dispatch => ({
  fetchInvoices: (venueId, offset) =>
    dispatch(fetchInvoicesApi(venueId, limit, offset)).then(r => r.getResult()),
});

export default connect(null, mdtp)(InvoicesSection);

const Invoices = ({ invoices, title, statusText, noDataText }) => (
  <div className="pb-2">
    <p className="font-semibold pb-2 opacity-60">{title}</p>
    {invoices?.length > 0 ? (
      invoices.map(i => (
        <PlainButton
          className="flex justify-between w-full pb-2"
          onClick={() => window.open(i.downloadLink)}
          key={i.id}
        >
          <p className="opacity-60 flex-1 text-left">
            {getLocaleDate(i?.creationDate, 'do MMM yyyy')}
          </p>
          <p className="opacity-60 flex-1">{formatPrice(i?.amount)}</p>
          <p className="opacity-60 flex-1 text-left">{statusText}</p>
          <ChevronIcon className="w-6 h-6" />
        </PlainButton>
      ))
    ) : (
      <p className="opacity-60 pb-2">{noDataText}</p>
    )}
  </div>
);

Invoices.propTypes = {
  invoices: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  title: PropTypes.string.isRequired,
  statusText: PropTypes.string.isRequired,
  noDataText: PropTypes.string.isRequired,
};

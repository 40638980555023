import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Route } from 'react-router-dom';
import { request } from '../../store/api/request';
import { historyProps, locationProps } from '../../utils/customPropTypes';
import PageNotFound from '../PageNotFound/PageNotFound';
import LoadingAnimation from '../../components/LoadingAnimation/LoadingAnimation';
import StaticContent from './StaticContent';
import TwoPartContent from './TwoPartContent';
import BannerImage from './BannerImage';
import ByOneLink from './ByOneLink';
import YoutubeEmbed from './YoutubeEmbed';
import InstagramEmbed from './InstagramEmbed';
import TweetEmbed from './TweetEmbed';
import './styles.scss';
import Iframe from './Iframe';
import LoginPage from '../LoginPage/LoginPage';
import { getIsLoggedIn } from '../../store/auth/selectors';
import UnprotectedLayout from '../UnprotectedLayout/UnprotectedLayout';

const BLOCKS = {
  TWO_PART_CONTENT: 'two_part_content',
  STATIC_CONTENT: 'static_content',
  BANNER_IMAGE: 'banner_image',
  TWO_BY_ONE_LINK: 'two_by_one_link',
  THREE_BY_ONE_LINK: 'three_by_one_link',
  YOUTUBE_EMBED: 'youtube_embed',
  TWITTER_EMBED: 'twitter_embed',
  INSTAGRAM_EMBED: 'instagram_embed',
  IFRAME: 'iframe',
};

const StrapiPage = ({ history, location, isLoggedIn, generic }) => {
  const [data, setData] = useState(undefined);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const getBlock = (block, i) => {
    switch (block?.blockType) {
      case BLOCKS.TWO_PART_CONTENT:
        return (
          <TwoPartContent
            key={i}
            image={block?.coverImage}
            dynamicCoverImage={block?.dynamicCoverImage}
            data={block?.content}
          />
        );
      case BLOCKS.STATIC_CONTENT:
        return <StaticContent key={i} data={block?.content[0]?.text} />;
      case BLOCKS.BANNER_IMAGE:
        return <BannerImage key={i} image={block?.image} />;
      case BLOCKS.TWO_BY_ONE_LINK:
        return <ByOneLink key={i} links={block?.links} linkStyles="TwoByOneLink" />;
      case BLOCKS.THREE_BY_ONE_LINK:
        return <ByOneLink key={i} links={block?.links} linkStyles="ThreeByOneLink" />;
      case BLOCKS.YOUTUBE_EMBED:
        return <YoutubeEmbed key={i} video={block?.videoId} />;
      case BLOCKS.TWITTER_EMBED:
        return <TweetEmbed key={i} tweet={block?.id} index={i} />;
      case BLOCKS.INSTAGRAM_EMBED:
        return <InstagramEmbed key={i} image={block?.id} index={i} />;
      case BLOCKS.IFRAME:
        return <Iframe key={i} url={block?.url} />;
      default:
        return <div />;
    }
  };

  useEffect(() => {
    setLoading(true);
    generic(`contents/pages/by-slug${location.pathname}`)
      .then(response => response.getResult())
      .then(newData => {
        if (newData.content) {
          setError(false);
          return setData(newData.content?.map((block, index) => getBlock(block, index)));
        }
        throw Error();
      })
      .catch(() => setError(true))
      .finally(() => setLoading(false));
  }, [location.pathname, generic]);

  if (error) {
    return isLoggedIn ? <PageNotFound history={history} /> : <Route component={LoginPage} />;
  }

  if (loading) {
    return <LoadingAnimation />;
  }

  if (!data) {
    return null;
  }

  return isLoggedIn ? data : <UnprotectedLayout noPad>{data}</UnprotectedLayout>;
};

StrapiPage.propTypes = {
  history: historyProps.isRequired,
  location: locationProps.isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
  generic: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  isLoggedIn: getIsLoggedIn(state),
});

const mapDispatchToProps = {
  generic: request,
};

export default connect(mapStateToProps, mapDispatchToProps)(StrapiPage);

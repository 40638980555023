import React from 'react';
import PropTypes from 'prop-types';
import i18n, { t } from 'i18next';
import { connect } from 'react-redux';
import ProfileHeader from '../ProfilePageV2/ProfileHeader';
import { userProps } from '../../utils/customPropTypes';
import { getUser } from '../../store/auth/selectors';
import { updateMemberThunk } from '../../store/settings/thunks';
import { supportedLocales } from '../../utils/constants';
import { updateLanguage } from '../../i18n';
import DropdownSelector from '../../components/DropdownSelector/DropdownSelector';
import LoginDetails from './LoginDetails';
import ConnectSocialMedia from './ConnectSocialMedia';
import Users from './Users';

const SettingsPage = ({ member, updateMember }) => {
  const enUS = 7; // en-us only supported on ontap sports only
  const customLocales = supportedLocales.filter(loc => loc?.localeId !== enUS);
  const selectedLocale = customLocales?.find(elm => elm?.locale === i18n?.resolvedLanguage);

  return (
    <>
      <ProfileHeader text={t('titles_settings')} />

      <div className="px-4 md:px-0 md:max-w-md md:mx-auto lg:max-w-lg mb-36 md:mb-32 pt-8 md:pt-12 lg:pt-16">
        <LoginDetails />
        <ConnectSocialMedia />
        <h3 className="font-bold text-xl pb-4 pt-8 mt-8 border-mpGrey border-t">
          {t('settings_languageTitle')}
        </h3>
        <DropdownSelector
          label={t('settings_languageLabel')}
          placeholder={t('settings_languagePlaceholder')}
          options={customLocales?.map(elm => ({ key: elm.localeId, value: t(elm.localeKey) }))}
          selected={t(selectedLocale?.localeKey)}
          onValueChange={({ key: localeId }) => {
            updateLanguage(supportedLocales.find(elm => elm.localeId === localeId).locale);
            updateMember({ localeId }, member.id);
          }}
        />
        <Users />
      </div>
    </>
  );
};

SettingsPage.propTypes = {
  member: userProps.isRequired,
  updateMember: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  member: getUser(state),
});

const mapDispatchToProps = {
  updateMember: updateMemberThunk,
};

export default connect(mapStateToProps, mapDispatchToProps)(SettingsPage);

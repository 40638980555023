import React from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18next';
import PlainButton from '../../../../components/PlainButton/PlainButton';
import { venueProps } from '../../../../utils/customPropTypes';

const BuildProfile = ({ onSync, venue }) => (
  <div className="p-8 md:p-16 border border-mpGrey bg-white rounded-2xl text-center">
    <img
      className="w-32 h-32 rounded-full mx-auto"
      alt={venue.name}
      src={venue.profilePicture['200_160']}
    />
    <h2 className="headingFont text-4xl md:text-5xl py-4">{t('signup_venue_syncGoogleTitle')}</h2>
    <p className="opacity-80 md:text-xl whitespace-pre-wrap">
      {t('signup_venue_syncGoogleDescription')}
    </p>
    <PlainButton
      className="mt-6 md:mt-8 py-4 w-full font-semibold rounded-lg outline-none bg-tertiary text-primaryButtonText"
      onClick={onSync}
    >
      {t('signup_completeProfile')}
    </PlainButton>
  </div>
);

BuildProfile.propTypes = {
  onSync: PropTypes.func.isRequired,
  venue: venueProps.isRequired,
};

export default BuildProfile;

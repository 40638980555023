import i18next from 'i18next';
import React from 'react';
import { Helmet } from 'react-helmet';
import { getDomainInfo } from '../utils/environment';
import i18n from '../i18n';

const HeadTagOverride = () => {
  const { faviconPath, mainColor } = getDomainInfo(i18n.resolvedLanguage);
  return (
    <Helmet>
      <title>{i18next.t('general.browserTitle')}</title>
      <link rel="apple-touch-icon" href={faviconPath} />
      <link rel="shortcut icon" href={faviconPath} />
      <meta name="theme-color" content={mainColor} />
    </Helmet>
  );
};

export default HeadTagOverride;

import * as consts from './constants';

export const initialState = {
  initialValues: {},
  updatedValues: {},
  mandate: {},
  goCardlessLink: '',
  isFetching: false,
  errors: {},
  formDescription: {},
  isUpdatingValues: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case consts.FETCH_FINANCIALSFORM_DESCRIPTION_PENDING:
      return {
        ...state,
        isFetching: true,
      };
    case consts.FETCH_FINANCIALSFORM_DESCRIPTION_SUCCESS:
      return {
        ...state,
        formDescription: action.payload,
        isFetching: false,
      };
    case consts.FETCH_FINANCIALSFORM_DESCRIPTION_ERROR:
      return {
        ...state,
        isFetching: false,
      };

    case consts.FETCH_FINANCIALSFORM_VALUES_PENDING:
      return {
        ...state,
        isFetching: true,
      };
    case consts.FETCH_FINANCIALSFORM_VALUES_SUCCESS: {
      const { bankDetails, mandate, goCardlessLink } = action.payload;
      return {
        ...state,
        initialValues: bankDetails,
        updatedValues: bankDetails,
        goCardlessLink,
        mandate,
        isFetching: false,
      };
    }
    case consts.FETCH_FINANCIALSFORM_VALUES_ERROR:
      return {
        ...state,
        isFetching: false,
      };

    case consts.UPDATE_FINANCIALSFORM_VALUE: {
      return {
        ...state,
        updatedValues: {
          ...state.updatedValues,
          [action.payload.field]: action.payload.value,
        },
      };
    }
    case consts.UPDATE_FINANCIALSFORM_VALUES_PENDING:
      return {
        ...state,
        isUpdatingValues: true,
      };
    case consts.UPDATE_FINANCIALSFORM_VALUES_SUCCESS:
      return {
        ...state,
        initialValues: state.updatedValues,
        errors: null,
        isUpdatingValues: false,
      };
    case consts.UPDATE_FINANCIALSFORM_VALUES_ERROR:
      return {
        ...state,
        errors: action.payload,
        isUpdatingValues: false,
      };
    case consts.DISCARD_FINANCIALSFORM_CHANGES:
      return {
        ...state,
        updatedValues: state.initialValues,
      };

    default:
      return state;
  }
};
export default reducer;

import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { t } from 'i18next';
import { sendForgottenPassword } from '../../store/api/auth';
import Input from '../../components/Input/Input';
import { fireEvent, GACategories, GATags } from '../../utils/trackingHelper';
import MPModal from '../../components/MPModal/MPModal';
import SpinnerButton from '../../components/SpinnerButton/SpinnerButton';
import CloseIcon from '../../components/Icons/CloseIcon';
import { emailErrorCheck } from '../../utils/FormValidation';
import PlainButton from '../../components/PlainButton/PlainButton';
import { historyProps } from '../../utils/customPropTypes';

const styles = {
  title: 'headingFont text-4xl text-center pt-2',
  subtitle: 'pt-4 pb-10 text-darkGrey text-center md:text-xl',
  btn: 'w-full p-4 font-semibold rounded-lg',
  btnActive: 'bg-tertiary text-primaryButtonText',
  btnDisabled: 'bg-mpGrey text-standardGrey',
  resendBtn: 'pl-1 underline font-semibold',
};

const ForgotPasswordModal = ({ open, onClose, forgottenPassword, history }) => {
  const [email, setEmail] = useState(history?.location?.state?.email || '');
  const [isFetching, setIsFetching] = useState(false);
  const [isSent, setIsSent] = useState(false);
  const disabled = !emailErrorCheck(email);

  const submitForm = () => {
    setIsFetching(true);
    fireEvent(GACategories.LOGIN, GATags.FORGOT_PASSWORD);
    return forgottenPassword(email).finally(() => {
      setIsFetching(false);
      setIsSent(true);
    });
  };

  const onReset = () => {
    onClose();
    setEmail('');
    setIsSent(false);
  };

  const enterKey = event => {
    if (event.key === 'Enter') {
      submitForm();
    }
  };

  return (
    <MPModal open={open} onClose={onReset} className="max-w-sm md:max-w-md rounded-2xl">
      <div className="relative px-8 md:px-16 pb-8 md:pb-16 pt-16">
        <PlainButton onClick={onReset} className="absolute top-4 right-4">
          <CloseIcon className="w-12 h-12 p-2 bg-mpGrey rounded-full" />
        </PlainButton>
        {!isSent ? (
          <>
            <h1 className={styles.title}>{t('auth_resetPassword')}</h1>
            <p className={styles.subtitle}>{t('auth_resetPassword_subtitle')}</p>
            <Input
              onChange={e => setEmail(e)}
              onKeyPress={enterKey}
              value={email}
              label={t('auth.email')}
              placeholder={t('auth_emailPlaceholder')}
              className="my-0 rounded-lg py-3 px-4 border border-mpGrey"
              id="forgot-password"
            />
            <SpinnerButton
              className={`${styles.btn} ${disabled ? styles.btnDisabled : styles.btnActive} mt-6`}
              onClick={submitForm}
              disabled={disabled}
              loading={isFetching}
            >
              {t('auth_resetPassword_cta')}
            </SpinnerButton>
            <PlainButton className={`${styles.btn} mt-4 bg-mpGrey`} onClick={onReset}>
              {t('general.cancel')}
            </PlainButton>
          </>
        ) : (
          <>
            <h1 className={styles.title}>{t('auth_resetPassword_sent')}</h1>
            <p className={styles.subtitle}>{t('auth_resetPassword_sentSubtitle')}</p>
            <PlainButton
              className={`${styles.btn} bg-tertiary text-primaryButtonText`}
              onClick={onReset}
            >
              {t('auth_resetPassword_sentCta')}
            </PlainButton>
            <div className="flex justify-center mt-8">
              <p>{t('auth_resetPassword_resend')}</p>
              <PlainButton className={styles.resendBtn} onClick={() => setIsSent(false)}>
                {t('auth_resetPassword_resendCta')}
              </PlainButton>
            </div>
          </>
        )}
      </div>
    </MPModal>
  );
};

const mdtp = {
  forgottenPassword: sendForgottenPassword,
};

ForgotPasswordModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  forgottenPassword: PropTypes.func.isRequired,
  history: historyProps.isRequired,
};

export default withRouter(connect(null, mdtp)(ForgotPasswordModal));

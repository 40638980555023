export const getIsFetching = state => state.fixtures.isFetching;

export const getFixturesSelector = state => state.fixtures.fixtures;

export const getNumberOfChanges = state => state.fixtures.numberOfChanges;

export const getIsFetchingNext = state => state.fixtures.isFetchingNext;

export const getOffset = state => state.fixtures.offset;

export const getLimit = state => state.fixtures.limit;

export const getHasMorePages = state => state.fixtures.hasMorePages;

/* eslint-disable no-param-reassign */
import { createImageObject } from '../../../utils/general';

export const verticalAlignments = {
  top: 'top',
  bottom: 'bottom',
  middle: 'middle',
};
export const templateHeightLandscape = 1190;
export const templateWidthLandscape = 1920;

export const templateHeightPortrait = 1920;
export const templateWidthPortrait = 1358;

export const templateHeightInstagram = 1920;
export const templateWidthInstagram = 1080;

export const aspectRatioLandscape = templateWidthLandscape / templateHeightLandscape;
export const aspectRatioPortrait = templateWidthPortrait / templateHeightPortrait;

export const calculateHorizontalCenter = (string, startX, endX, ctx) => {
  const xCoordinate = (endX + startX) / 2 - ctx.measureText(string).width / 2;
  return xCoordinate > 0 ? xCoordinate : 0;
};

export const percentageToCanvasCoordinates = (
  { endXPercent, endYPercent, startXPercent, startYPercent },
  canvasHeight,
  canvasWidth,
) => ({
  startX: canvasWidth * (startXPercent / 100),
  startY: canvasHeight * (startYPercent / 100),
  endX: canvasWidth * (endXPercent / 100),
  endY: canvasHeight * (endYPercent / 100),
});

export const getImageConstraints = (
  imageWidth,
  imageHeight,
  imgScale,
  { endXPercent, endYPercent, startXPercent, startYPercent },
  canvasHeight,
  canvasWidth,
  renderFromCenterTop,
) => {
  const { startX, startY, endX, endY } = percentageToCanvasCoordinates(
    { endXPercent, endYPercent, startXPercent, startYPercent },
    canvasHeight,
    canvasWidth,
  );

  const limiterAspectRatio = (endX - startX) / (endY - startY);
  const imageAspectRatio = imageWidth / imageHeight;
  let width = (endX - startX) * imgScale;
  let height = width / imageAspectRatio;
  // if image is wider than limiter - limit by width first. if it is taller - limit its height.
  if (limiterAspectRatio >= imageAspectRatio) {
    height = (endY - startY) * imgScale;
    width = height * imageAspectRatio;
  }

  let imageXstart = endX - width;
  let imageYstart = endY - height;
  if (renderFromCenterTop) {
    imageXstart = (endX - width) / 2;
    imageYstart = startY;
  }
  return {
    imageXstart,
    imageYstart,
    width,
    height,
  };
};

export const renderImageWithinConstraints = (
  img,
  { endXPercent, endYPercent, startXPercent, startYPercent },
  ctx,
  canvasHeight,
  canvasWidth,
  renderFromCenterTop,
  imgScale = 1,
) => {
  const image = createImageObject(img);
  const { imageXstart, imageYstart, width, height } = getImageConstraints(
    image.width,
    image.height,
    imgScale,
    { endXPercent, endYPercent, startXPercent, startYPercent },
    canvasHeight,
    canvasWidth,
    renderFromCenterTop,
  );

  ctx.drawImage(image, imageXstart, imageYstart, width, height);
};

export const drawOnCanvas = (canvas, templateWidth, templateHeight, color, renderFunc) => {
  const cc = canvas.current;
  const ctx = canvas.current && canvas.current.getContext('2d');
  if (ctx) {
    ctx.clearRect(0, 0, cc.width, cc.height);
    ctx.fillStyle = color;
    ctx.fillRect(0, 0, templateWidth, templateHeight);
    renderFunc(cc, ctx);
  }
};

const calculateNumberOfLines = (text, fitWidth, fontSize, font) => {
  // used for `wrappableCanvasText` only.
  // originally yoinked from https://stackoverflow.com/a/4478894

  const fakeCanvas = document.createElement('canvas');
  const fakeContext = fakeCanvas && fakeCanvas.getContext('2d');
  fakeContext.font = `${fontSize}px ${font}`;

  const resultObject = {};
  const textWidth = fitWidth || 0;

  if (text.length === 0) {
    return resultObject;
  }

  let words = text.split(' ');
  let currentLine = 0;
  let idx = 1;
  while (words.length > 0 && idx <= words.length) {
    const str = words.slice(0, idx).join(' ');
    const w = fakeContext.measureText(str).width;
    if (w > textWidth) {
      if (idx === 1) {
        idx = 2;
      }
      resultObject[currentLine] = words.slice(0, idx - 1).join(' ');
      currentLine += 1;
      words = words.splice(idx - 1);
      idx = 1;
    } else {
      idx += 1;
    }
  }
  if (idx > 0) {
    // prevent from creating a -Infinity key when clearing text
    if (Object.keys(resultObject).length === 0) {
      resultObject[0] = words.join(' ');
    } else {
      resultObject[Math.max(...Object.keys(resultObject)) + 1] = words.join(' ');
    }
  }
  fakeCanvas.remove();
  return resultObject;
};

export const wrappableCanvasText = (
  context,
  text,
  { startX, startY, endY, endX },
  fontSize,
  textColor,
  font,
  verticalAlign,
  horizontallyCentered = false,
) => {
  // creates a mock canvas to measure the text and split it by line.
  // render it on canvas later

  const textSplitInLines = calculateNumberOfLines(text, endX - startX, fontSize, font);
  context.font = `${fontSize}px ${font}`;
  context.fillStyle = textColor;

  const renderText = currentY =>
    Object.values(textSplitInLines).forEach(line => {
      context.fillText(
        line,
        horizontallyCentered ? calculateHorizontalCenter(line, startX, endX, context) : startX,
        currentY,
      );
      currentY += fontSize;
    });

  // default to render from `top`
  let textY = startY;
  if (verticalAlign === verticalAlignments.middle) {
    textY =
      (endY +
        startY +
        fontSize -
        // adjust by half line, but only if text value is not empty.
        //  prevents jumpiness when text overflows `the allowed width`.
        fontSize * Object.keys(textSplitInLines).filter(key => textSplitInLines[key]).length) /
      2;
  } else if (verticalAlign === verticalAlignments.bottom) {
    textY = endY - fontSize * (Object.keys(textSplitInLines).length - 1);
  }
  renderText(textY);
};

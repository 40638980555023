import React from 'react';
import { t } from 'i18next';
import assets from './assets';
import AssetBlock from './AssetBlock';
import { cdnUrl } from '../../utils/constants';

const ActivationInfo = () => (
  <>
    <p className="text-lg font-semibold pb-6">{t('activation_assets_info')}</p>
    {assets?.length > 0 && (
      <div className="flex flex-row flex-wrap px-4 gap-10">
        {assets.map(a => (
          <AssetBlock
            key={a.img}
            image={`${cdnUrl}/activation/${a.img}`}
            title={a.text1}
            description={a.text2}
          />
        ))}
      </div>
    )}
  </>
);

export default ActivationInfo;

import { request } from './request';

export const getMember = memberId => dispatch =>
  dispatch(
    request(`members/${memberId}`, {
      method: 'GET',
    }),
  );

export const updateMember = (member, id) => dispatch =>
  dispatch(
    request(`members/${id}/update`, {
      method: 'PUT',
      body: {
        member,
      },
    }),
  );

export const emailCheck = email => dispatch =>
  dispatch(request(`members/check-email-exist?email=${email}`));

export const preSignUp = body => dispatch =>
  dispatch(request('members/pre-sign-up', { method: 'POST', body }));

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18next';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import NumberSelector from './NumberSelector';
import PlainButton from '../../components/PlainButton/PlainButton';
import { getUser } from '../../store/auth/selectors';
import { countryProps, userProps, venueProps } from '../../utils/customPropTypes';
import { getVenue } from '../../store/venue/selectors';
import { navigate } from '../../store/navigation/thunks';
import routes from '../../routes/paths';
import { getCountries } from '../../store/app/selectors';
import DropdownSelector from '../../components/DropdownSelector/DropdownSelector';
import AddressSearch from './AddressSearch';
import { createVenueRequest, fetchCountriesReq } from '../../store/api/auth';
import Input from './Input';
import SpinnerButton from '../../components/SpinnerButton/SpinnerButton';
import { fireEvent, GACategories, GATags } from '../../utils/trackingHelper';
import { validatePhoneNumber } from '../../utils/general';
import { genericRequest } from '../../store/api/request';

const STEPS = {
  NUM_OF_VENUES: 'NUM_OF_VENUES',
  INTERESTED_IN: 'INTERESTED_IN',
  VENUE_NAME: 'VENUE_NAME',
  ADDRESS: 'ADDRESS',
  PHONE_NUMBER: 'PHONE_NUMBER',
};

const INTERESTED_IN = [
  'signup_venue_interestedIn1',
  'signup_venue_interestedIn2',
  'signup_venue_interestedIn3',
  'signup_venue_interestedIn4',
  'signup_venue_interestedIn5',
  'signup_venue_interestedIn6',
];

const Stage2 = ({ member, countries, fetchCountries, createVenue, venue, generic }) => {
  const steps = Object.values(STEPS);
  const [step, setStep] = useState(steps[0]);
  const [loading, setLoading] = useState(false);
  const [numOfVenues, setNumVenues] = useState(1);
  const [addressQuery, setAddressQuery] = useState('');
  const [showAddressInputs, setShowAddressInputs] = useState(false);
  const [venueData, setVenueData] = useState({
    memberId: member.id,
    interestedIn: '',
    name: '',
    googlePlaceId: '',
    googlePlaceTypes: '',
    address1: '',
    address2: '',
    city: '',
    postCode: '',
    countryId: null,
    country: null,
    managerPhone: '',
  });
  const dataChange = newData => setVenueData(prev => ({ ...prev, ...newData }));
  const track = (tag, params = {}) =>
    fireEvent(GACategories.SIGN_UP_V2, tag, { ...params, memberId: member.id });

  const scrollToTop = () => setTimeout(window.scrollTo(0, 0), 100);

  const currentStep = steps.findIndex(e => e === step);
  const moveToNextStep = () => setStep(steps[currentStep + 1]);
  const pressContinue = () => {
    scrollToTop();
    if (step === STEPS.NUM_OF_VENUES) {
      track(GATags.VENUE_NUMBER, { numOfVenues });
      if (numOfVenues > 1) {
        generic(`members/notify-multiple-venues`, undefined, 'POST', {
          memberId: member.id,
          numberOfVenues: numOfVenues,
        });
        return navigate({ pathname: routes.multipleVenues.path, state: { numOfVenues } });
      }
      return moveToNextStep();
    }
    if (step === STEPS.INTERESTED_IN) {
      track(GATags.VENUE_INTERESTED, { selectedInterest: venueData.interestedIn });
      return moveToNextStep();
    }
    if (step === STEPS.VENUE_NAME) {
      track(GATags.VENUE_NAME_CONTINUE);
      return moveToNextStep();
    }
    if (step === STEPS.ADDRESS) {
      track(GATags.VENUE_ADDRESS_CONTINUE);
      return moveToNextStep();
    }
    setLoading(true);
    track(GATags.VENUE_CREATION_COMPLETE);
    return createVenue(venueData)
      .then(() => navigate(routes.home.path))
      .catch(() => setLoading(false));
  };

  const canContinue = () => {
    const { interestedIn, name, address1, city, countryId, postCode, managerPhone } =
      venueData || {};
    if (loading) {
      return false;
    }
    switch (step) {
      case STEPS.INTERESTED_IN:
        return interestedIn.length > 0;
      case STEPS.VENUE_NAME:
        return name.length > 0;
      case STEPS.ADDRESS:
        return address1.length > 0 && city.length > 0 && countryId && postCode.length > 0;
      case STEPS.PHONE_NUMBER:
        return managerPhone > 0;
      default:
        return true;
    }
  };

  const enterKey = event => {
    if (event.key === 'Enter' && canContinue()) {
      pressContinue();
    }
  };

  useEffect(() => {
    if (!countries.length) {
      fetchCountries();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countries.length]);

  if (venue) {
    return <Redirect to={routes.home.path} />;
  }

  return (
    <div className="px-4 my-8 md:my-14 lg:my-16 mx-auto max-w-md md:max-w-lg">
      {step === STEPS.NUM_OF_VENUES && (
        <>
          <h1 className="text-3xl md:text-4xl lg:text-5xl font-bold text-center pb-8">
            {t('signup_venue_numTitle')}
          </h1>
          <p className="font-bold pb-2">{t('signup_venue_numInputLabel')}</p>
          <NumberSelector counter={numOfVenues} onChange={setNumVenues} />
        </>
      )}

      {step === STEPS.INTERESTED_IN && (
        <>
          <h1 className="text-3xl md:text-4xl lg:text-5xl font-bold text-center pb-2">
            {t('signup_venue_interestedInTitle')}
          </h1>
          <p className="opacity-60 pb-8 text-center md:text-xl">
            {t('signup_venue_interestedInSubtitle')}
          </p>
          <div className="flex flex-col gap-4">
            {INTERESTED_IN.map(b => (
              <PlainButton
                className={`px-4 py-3 w-full border outline-none rounded-full ${
                  venueData.interestedIn === t(b)
                    ? 'bg-secondaryLight border-primary'
                    : 'bg-white border-mpGrey'
                }`}
                onClick={() => dataChange({ interestedIn: t(b) })}
                key={b}
                testId="interestedIn"
              >
                <p className="font-semibold opacity-80">{t(b)}</p>
              </PlainButton>
            ))}
          </div>
        </>
      )}

      {step === STEPS.VENUE_NAME && (
        <>
          <h1 className="text-3xl md:text-4xl lg:text-5xl font-bold text-center pb-8">
            {t('signup_venue_nameTitle')}
          </h1>
          <Input
            label={t('signup_venue_nameLabel')}
            placeholder={t('signup_venue_namePlaceholder')}
            value={venueData.name}
            onChange={v => dataChange({ name: v })}
            onKeyPress={enterKey}
            dataTestId="venueName"
          />
        </>
      )}

      {step === STEPS.ADDRESS && (
        <>
          <h1 className="text-3xl md:text-4xl lg:text-5xl font-bold text-center pb-8">
            {t('signup_venue_addressTitle', { name: venueData.name })}
          </h1>
          <AddressSearch
            value={addressQuery}
            onChange={v => setAddressQuery(v)}
            onItemClick={i => {
              track(GATags.VENUE_ADDED_ADDRESS_LOOKUP);
              dataChange(i);
              setShowAddressInputs(true);
            }}
            countries={countries}
          />
          {!showAddressInputs ? (
            <PlainButton
              className="px-2 py-4 underline font-semibold outline-none"
              onClick={() => {
                track(GATags.VENUE_ADDED_ADDRESS_MANUAL);
                setShowAddressInputs(true);
              }}
            >
              {t('signup_venue_addressManually')}
            </PlainButton>
          ) : (
            <div className="flex flex-col gap-4 pt-4" data-test-id="addressInputs">
              <Input
                label={t('signup_venue_address2Label')}
                placeholder={t('signup_venue_address2Placeholder')}
                value={venueData.address1}
                onChange={v => dataChange({ address1: v })}
                onKeyPress={enterKey}
              />
              <Input
                label={t('signup_venue_address3Label')}
                placeholder={t('signup_venue_address3Placeholder')}
                value={venueData.address2}
                onChange={v => dataChange({ address2: v })}
                onKeyPress={enterKey}
              />
              <Input
                label={t('signup_venue_address4Label')}
                placeholder={t('signup_venue_address4Placeholder')}
                value={venueData.city}
                onChange={v => dataChange({ city: v })}
                onKeyPress={enterKey}
              />
              <DropdownSelector
                label={t('signup_venue_address5Label')}
                placeholder={t('signup_venue_address5Placeholder')}
                options={countries.map(c => ({ key: c.id, value: c.name }))}
                selected={venueData.country?.value}
                onValueChange={v => dataChange({ country: v, countryId: v.key })}
              />
              <Input
                label={t('signup_venue_address6Label')}
                placeholder={t('signup_venue_address6Placeholder')}
                value={venueData.postCode}
                onChange={v => dataChange({ postCode: v })}
                onKeyPress={enterKey}
              />
            </div>
          )}
        </>
      )}

      {step === STEPS.PHONE_NUMBER && (
        <>
          <h1 className="text-3xl md:text-4xl lg:text-5xl font-bold text-center pb-8">
            {t('signup_venue_phoneTitle')}
          </h1>
          <Input
            label={t('signup_venue_phoneLabel')}
            placeholder={t('signup_venue_phonePlaceholder')}
            value={venueData.managerPhone}
            onChange={v => dataChange({ managerPhone: validatePhoneNumber(v) })}
            dataTestId="phoneNumber"
            type="tel"
            onKeyPress={enterKey}
          />
        </>
      )}

      <div className="flex gap-4">
        {currentStep > 0 && (
          <PlainButton
            className="mt-12 py-4 w-full bg-mpGrey font-semibold rounded-lg outline-none"
            onClick={() => setStep(steps[currentStep - 1])}
          >
            {t('general_back')}
          </PlainButton>
        )}
        <SpinnerButton
          className={`mt-12 py-4 w-full font-semibold rounded-lg outline-none ${
            canContinue() ? 'bg-black text-white' : 'bg-mpGrey text-standardGrey'
          }`}
          onClick={pressContinue}
          disabled={!canContinue()}
          loading={loading}
        >
          {t('general.continue')}
        </SpinnerButton>
      </div>
    </div>
  );
};

Stage2.propTypes = {
  member: userProps.isRequired,
  fetchCountries: PropTypes.func.isRequired,
  countries: PropTypes.arrayOf(countryProps).isRequired,
  createVenue: PropTypes.func.isRequired,
  venue: venueProps,
  generic: PropTypes.func.isRequired,
};

Stage2.defaultProps = {
  venue: null,
};

const mstp = state => ({
  member: getUser(state),
  countries: getCountries(state),
  venue: getVenue(state),
});

const mdtp = {
  fetchCountries: fetchCountriesReq,
  createVenue: createVenueRequest,
  generic: genericRequest,
};

export default connect(mstp, mdtp)(Stage2);

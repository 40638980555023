import React from 'react';
import { t } from 'i18next';
import TickIcon from '../Icons/TickIcon';

const Claims = () =>
  ['subscriptions_claim1', 'subscriptions_claim2', 'subscriptions_claim3'].map(text => (
    <div key={text} className="flex items-center mb-2">
      <TickIcon className="w-6 h-6 text-secondary mr-2" thick />
      <p className="font-semibold md:text-lg">{t(text)}</p>
    </div>
  ));

export default Claims;

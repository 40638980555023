import {
  add,
  parse,
  format,
  getMinutes,
  differenceInDays,
  parseISO,
  isValid,
  startOfDay,
} from 'date-fns';
import { t } from 'i18next';
import { VENUE_ACCESS_LEVEL, VENUE_STATUS } from './constants';
import { userIsOnOnTap } from './environment';

export const generateTimeIntervals = (minutes, startTime = '00:00', endTime = '23:59') => {
  const result = [];
  const start = parse(startTime, 'HH:mm', new Date());
  const end = parse(endTime, 'HH:mm', new Date());
  for (let i = start; i <= end; i = add(i, { minutes })) {
    result.push({
      value: format(i, 'HH:mm'),
      key: format(i, 'HH:mm'),
    });
  }
  return result;
};

export const roundToNearestXminutes = (minutes, time = new Date()) => {
  const remainder = minutes - (getMinutes(time) % minutes);
  return format(add(time, { minutes: remainder }), 'HH:mm');
};

export const getFileTypeFromString = string => {
  // supports data://... and download links with file extension.

  if (string) {
    // yeeted from https://miguelmota.com/bytes/base64-mime-regex/ - if it's a data://... string
    const mime = string.match(/data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+).*,.*/);
    if (mime) {
      return mime && mime.length && /[a-z][/][a-z]{2,4}/.test(mime[1]) && mime[1].split('/')[1];
    }
    if (/[^.]+$/.test(string)) {
      // https://stackoverflow.com/a/190878/10424372 - in case of download link
      return /[^.]+$/.exec(string)[0];
    }
  }
  return null;
};

export const base64DownscaleUpload = async file => {
  const landmaximumWidth = 1600;
  const portmaximumHeight = 1800;
  const reader = new FileReader();
  reader.readAsDataURL(file);

  return new Promise(resolve => {
    reader.onload = e => {
      const img = document.createElement('img');
      img.src = e.target.result;
      img.onload = () => {
        const type = getFileTypeFromString(file.path);

        // resizes image to fit within maximumWidth and maximumHeight before using it in passed function.
        let { width, height } = img;

        if (width > height) {
          // landscape
          if (width > landmaximumWidth) {
            height *= landmaximumWidth / width;
            width = landmaximumWidth;
          }
        } else {
          // eslint-disable-next-line no-lonely-if
          if (height > portmaximumHeight) {
            width *= portmaximumHeight / height;
            height = portmaximumHeight;
          }
        }

        const canvas = document.createElement('canvas');
        canvas.width = width;
        canvas.height = height;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0);
        ctx.drawImage(img, 0, 0, width, height);
        const dataurl = canvas.toDataURL(type.includes('jp') ? 'image/jpeg' : 'image/png');

        resolve(dataurl);
      };
    };
  });
};

export const createImageObject = url => {
  const img = new Image();
  img.src = url;
  return img;
};

export const safelyParseJSON = jsonString => {
  try {
    return JSON.parse(jsonString);
  } catch {
    return false;
  }
};

export const truncateString = (str, length) =>
  str.length <= length ? str : `${str.slice(0, length - 3)}...`;

// n expects: (Number || string representing a num) > 0
// length expects: Number > 1
// returns: (Number || string) indistinctly, of length [length]
export const truncateNumber = (n, length) =>
  `${n}`?.length <= length ? n : `${Array(length).join('9')}+`;

export const debounce = (callback, time) => {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      callback.apply(this, args);
    }, time);
  };
};

export const isFutureDate = date => differenceInDays(parseISO(date), new Date()) > 0;

export const getFormattedDateRange = (fromDate, toDate) => {
  const getDate = date => parse(date, 'yyyy-MM-dd HH:mm:ss', new Date());
  const from = getDate(fromDate);
  const to = getDate(toDate);
  if (isValid(from)) {
    const isDifferentDay = differenceInDays(startOfDay(to), startOfDay(from)) > 0;
    const fromMonth = format(from, 'MMM').toUpperCase();
    const toMonth = isValid(to) && format(to, 'MMM').toUpperCase();
    return isDifferentDay
      ? `${fromMonth} ${format(from, 'do')} - ${toMonth} ${format(to, 'do yyyy')}`
      : `${fromMonth} ${format(from, 'do yyyy')}`;
  }
  return null;
};

export const fieldsChanged = (pubData, venue) =>
  Object.keys(pubData)
    .filter(k => k !== 'openingHours' && k !== 'socialMedia')
    .map(k => (pubData[k] !== venue[k] ? k : null))
    .filter(k => k !== null);

export const getAddress = addressComponents => {
  const shouldBeComponent = {
    number: ['street_number', 'premise'],
    street: ['street_address', 'route'],
    postcode: ['postal_code'],
    city: ['locality', 'postal_town'],
    region: [
      'administrative_area_level_1',
      'administrative_area_level_2',
      'administrative_area_level_3',
      'administrative_area_level_4',
      'administrative_area_level_5',
    ],
    subregion: [
      'sublocality',
      'sublocality_level_1',
      'sublocality_level_2',
      'sublocality_level_3',
      'sublocality_level_4',
    ],
    country: ['country'],
  };

  const obj = {
    number: '',
    street: '',
    postcode: '',
    city: '',
    region: '',
    country: '',
    subregion: '',
  };

  addressComponents.forEach(component => {
    Object.keys(shouldBeComponent).forEach(shouldBe => {
      if (shouldBeComponent[shouldBe].includes(component.types[0])) {
        obj[shouldBe] = component.long_name;
      }
    });
  });

  return obj;
};

export const venueIsLead = venue =>
  venue?.status === VENUE_STATUS.LEAD && !venue?.hasFullAccess && !venue?.tierId;

export const getVenueAccessLevel = venue => {
  const { tierId, hasBasicInfo, mindsetFunnelData } = venue || {};

  const hasSavedAFixture = mindsetFunnelData?.totalCoreActivityCount > 0;

  if (venueIsLead(venue) && !hasSavedAFixture) {
    return VENUE_ACCESS_LEVEL.NO_ACTIVITY;
  }

  if (tierId && !hasBasicInfo) {
    return VENUE_ACCESS_LEVEL.NO_BASIC_INFO;
  }

  return null;
};

export const canAccessBilling = venue => {
  const { status, paymentStatus } = venue || {};
  return (
    !userIsOnOnTap &&
    paymentStatus !== 'group' &&
    (status === VENUE_STATUS.ACTIVE ||
      status === VENUE_STATUS.MATCHPINT_ONLY ||
      status === VENUE_STATUS.END_REGAIN_ACCESS_IF_PAYMENT)
  );
};

export const getPreviewUrl = venue => `${venue?.matchpintUrl}?preview=true`;

export const flattenRoutes = routes =>
  Object.keys(routes).reduce((flattened, key) => {
    const updatedFlattened = flattened;
    const route = routes[key];
    updatedFlattened[key] = route;

    if (route.subRoutes) {
      Object.assign(updatedFlattened, flattenRoutes(route.subRoutes));
    }

    return updatedFlattened;
  }, {});

export const hasPermission = (permissionsArray, route) =>
  !route?.permissionCheck || permissionsArray.includes(route?.permissionCheck);

export const transformImages = v => {
  const arr = [];
  if (!v.profilePicture?.original?.includes('/pub/default/')) {
    arr.push({
      source: 'db',
      image: v.profilePicture['500_285'],
      id: v.profilePicture.id,
      profilePicture: true,
      order: 0,
    });
  }
  if (v.extraImages?.length > 0) {
    arr.push(
      ...v.extraImages.map((exIm, i) => ({
        source: 'db',
        image: exIm['500_285'],
        id: exIm.id,
        profilePicture: false,
        order: i + 1,
      })),
    );
  }
  return arr;
};

export function deepEqual(x, y) {
  const ok = Object.keys;
  const tx = typeof x;
  const ty = typeof y;
  return x && y && tx === 'object' && tx === ty
    ? ok(x).length === ok(y).length && ok(x).every(key => deepEqual(x[key], y[key]))
    : x === y;
}

export const validatePhoneNumber = num => num.replace(/\D/g, '');

export const getURLQuery = (parameters = {}, stripNullParams = false) => {
  const BOOLEAN = 'boolean';
  const NUMBER = 'number';
  const OBJECT = 'object';
  const STRING = 'string';

  const params = [];
  const keys = Object.keys(parameters);
  keys.forEach(_key => {
    const key = [encodeURIComponent(_key)];
    if ((parameters[key] === null || parameters[key] === '') && !stripNullParams) {
      params.push(key);
    } else {
      switch (typeof parameters[key]) {
        case BOOLEAN:
          params.push(`${key}=${parameters[key] ? '1' : '0'}`);
          break;
        case NUMBER:
        case STRING:
          params.push(`${key}=${encodeURIComponent(parameters[key])}`);
          break;
        case OBJECT: {
          if (Array.isArray(parameters[key])) {
            params.push(parameters[key].map(param => `${key}[]=${param}`).join('&'));
          }
          break;
        }
        default:
          break;
      }
    }
  });

  return params.length === 0 ? '' : `?${params.join('&')}`;
};

export const formatPrice = price =>
  t('general.price_with_currency', {
    currency: t('general.currency'),
    price,
  });

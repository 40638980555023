import React from 'react';
import PropTypes from 'prop-types';
import i18n from 'i18next';
import MarketingActionTile from './MarketingActionTile/MarketingActionTile';
import PageHeader from '../../components/PageHeader/PageHeader';
import routes from '../../routes/paths';
import { userIsOnFanzo } from '../../utils/environment';
import { GATags } from '../../utils/trackingHelper';
import { cdnUrl } from '../../utils/constants';

const cdn = `${cdnUrl}/marketing`;

const Marketing = ({ title }) => {
  const { postToSocial, socialSchedule, integrate, customiseAssets } = routes.marketing.subRoutes;
  return (
    <div className="bg-white flex-1">
      <PageHeader title={title} />
      <div className="py-10 md:py-6 md:flex md:flex-wrap lg:px-16 md:px-14 gap-5">
        <MarketingActionTile
          className="text-white bg-black"
          label={i18n.t('marketing.postFixtures')}
          description={i18n.t('marketing.postFixtures.desc')}
          link={postToSocial.path}
          images={{ desktop: `${cdn}/desktop1.png`, mobile: `${cdn}/mobile1.png` }}
          tag={GATags.CREATE_ASSETS}
        />
        <MarketingActionTile
          className="bg-secondary"
          label={i18n.t('marketing.viewSchedule')}
          description={i18n.t('marketing.viewSchedule.desc')}
          link={socialSchedule.path}
          images={{ desktop: `${cdn}/desktop2.png`, mobile: `${cdn}/mobile2.png` }}
        />
        <MarketingActionTile
          className="bg-white"
          label={i18n.t('marketing.websiteFixtures')}
          description={i18n.t('marketing.websiteFixtures.desc')}
          link={integrate.path}
          images={{ desktop: `${cdn}/desktop3.png`, mobile: `${cdn}/mobile3.png` }}
          tag={GATags.PUBLISH_TO_WEBSITE}
        />
        {userIsOnFanzo && (
          <MarketingActionTile
            className="bg-lightGrey"
            label={i18n.t('marketing.customiseAssets')}
            description={i18n.t('marketing.customiseAssets.desc')}
            link={customiseAssets.path}
            images={{ desktop: `${cdn}/desktop4.png`, mobile: `${cdn}/mobile4.png` }}
          />
        )}
      </div>
    </div>
  );
};

Marketing.propTypes = {
  title: PropTypes.string.isRequired,
};

export default Marketing;

import React from 'react';
import PropTypes from 'prop-types';
import i18n from 'i18next';
import spinner from './spinner.gif';

const Spinner = ({ className }) => (
  <div className="flex justify-center items-center">
    <img src={spinner} className={className} alt={i18n.t('fixtures.loadingMore')} />
  </div>
);

Spinner.propTypes = {
  className: PropTypes.string,
};

Spinner.defaultProps = {
  className: 'w-5',
};

export default Spinner;

import { toast } from 'react-toastify';
import i18n from 'i18next';
import * as actions from './actions';
import {
  sendLoginRequest,
  sendAutoLoginRequest,
  postPreActivation,
  postActivate,
  signUpReq,
} from '../api/auth';
import { getMember } from '../api/member';
import { MpCookieGet } from '../../utils/MpCookie';
import { fetchVenues, fetchPermissions } from '../venue/thunks';
import { fetchPermissionsSuccess, fetchVenuesSuccess } from '../venue/actions';
import { GACategories, GATags, fireEvent } from '../../utils/trackingHelper';
import { updateLanguage } from '../../i18n';
import { navigate } from '../navigation/thunks';
import routes from '../../routes/paths';
import { supportedLocales } from '../../utils/constants';

export const signUpThunk = body => dispatch => {
  dispatch(fetchVenuesSuccess([]));
  return dispatch(signUpReq(body)).then(r => {
    dispatch(actions.fetchLoginAuthSuccess(r.getResult()));
    return dispatch(fetchPermissionsSuccess([]));
  });
};

const postLogin = (member, venueId) => dispatch => {
  fireEvent(GACategories.LOGIN, GATags.LOGIN, { venueId });
  if (venueId) {
    return Promise.all([
      dispatch(fetchPermissions(venueId)),
      dispatch(actions.fetchLoginAuthSuccess(member)),
    ]);
  }

  navigate(routes.signupV2Stage2.path);
  return Promise.all([
    dispatch(fetchPermissionsSuccess([])),
    dispatch(actions.fetchLoginAuthSuccess(member)),
  ]);
};

export const requestLogin = (email, password, venueId) => dispatch => {
  dispatch(actions.fetchLoginAuthPending());
  return dispatch(sendLoginRequest(email, password, venueId))
    .then(response => response.getResult())
    .then(async result => {
      let venue = null;
      if (venueId || result?.venues?.length > 0) {
        venue = await dispatch(fetchVenues(venueId));
      }
      return dispatch(postLogin(result, venue?.[0]?.id));
    })
    .catch(error => {
      if (error.code === 202) {
        // Some hacky awful GK stuff - see FAN-2813
        const lcEmail = email?.toLowerCase();
        const indexOfCharBeforeAt = lcEmail.indexOf('@') - 1;
        if (
          lcEmail.includes('@greeneking.co.uk') &&
          parseInt(lcEmail[indexOfCharBeforeAt], 10) > -1 &&
          lcEmail[0] !== 'm'
        ) {
          toast.error(
            'Please make sure you are logging in with M at the beginning of your email, if you have any problems contact us on venues@fanzo.com',
          );
        } else {
          toast.error(error.message);
        }
      }
      return dispatch(actions.fetchLoginAuthError(error.error));
    });
};

export const requestAutoLogin = params => dispatch => {
  dispatch(actions.fetchLoginAuthPending());
  return dispatch(sendAutoLoginRequest(params))
    .then(response => response.getResult())
    .then(async result => {
      let { venueId } = params;
      if (params.loginKey) {
        [venueId] = params.loginKey.split('.');
      }
      let venue = null;
      if (venueId || result?.venues?.length > 0) {
        venue = await dispatch(fetchVenues(venueId));
      }
      return dispatch(postLogin(result, venue?.[0]?.id));
    })
    .catch(error => {
      if (error.code === 202) {
        toast.error(error.message);
      }
      return dispatch(actions.fetchLoginAuthError(error.error));
    });
};

export const checkAuthentication = (venueId, activationKey = null) => dispatch => {
  // don't bother checking auth if theres no cookie OR we have activationKey set
  if (MpCookieGet('id') && MpCookieGet('token') && !activationKey) {
    const venueOverride = venueId || MpCookieGet('venueId');
    return dispatch(getMember(MpCookieGet('id')))
      .then(response => response.getResult())
      .then(async result => {
        // check if language has been overriden and its not the current language and if so set it
        if (
          result?.localeId !==
            supportedLocales.find(elm => elm.locale === i18n.resolvedLanguage)?.localeId &&
          supportedLocales.find(elm => elm.localeId === result.localeId)
        ) {
          updateLanguage(supportedLocales.find(elm => elm.localeId === result.localeId).locale);
        }

        // once we are authenticated always fetch the venue and permissions
        let venue = null;
        if (venueOverride || result?.venues?.length > 0) {
          venue = await dispatch(fetchVenues(venueOverride));
        }
        return dispatch(postLogin(result, venue?.[0]?.id));
      });
  }
  return Promise.reject(new Error('no cookie, token or activation key found'));
};

export const preActivationThunk = activationKey => dispatch =>
  dispatch(postPreActivation(activationKey)).then(response => response.getResult());

export const activationThunk = activationData => dispatch =>
  dispatch(postActivate(activationData)).then(response => response.getResult());

import React, { useEffect, useState } from 'react';
import i18n from 'i18next';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PageHeader from '../../components/PageHeader/PageHeader';
import SpinnerButton from '../../components/SpinnerButton/SpinnerButton';
import Button from '../../components/Button/Button';
import { getVenue } from '../../store/venue/selectors';
import './styles.scss';
import { venueProps, historyProps, fixtureProps } from '../../utils/customPropTypes';
import {
  fetchSocialMediaScheduledPosts,
  fetchSocialMediaPostedPosts,
  deleteSocialMediaPost,
  fetchSocialMediaNextPostedPosts,
  fetchSocialMediaNextScheduledPosts,
} from '../../store/socialMedia/thunks';
import {
  getSocialMediaPostedPosts,
  getSocialMediaScheduledPosts,
  getIsDeletingPost,
  getHasMorePostedPosts,
  getHasMoreScheduledPosts,
  getIsFetchingPostedPosts,
  getIsFetchingScheduledPosts,
} from '../../store/socialMedia/selectors';
import routes from '../../routes/paths';
import FacebookIcon from '../../components/Icons/FacebookIcon';
import SocialIcon from './SocialIcon/SocialIcon';
import MPModal from '../../components/MPModal/MPModal';
import LoadingAnimation from '../../components/LoadingAnimation/LoadingAnimation';
import { fireEvent, GATags, GACategories } from '../../utils/trackingHelper';
import { getLocaleDate } from '../../i18n';

const limit = 10;
const SocialSchedule = ({
  venue,
  getScheduledPosts,
  getPostedPosts,
  hasMorePostedPosts,
  hasMoreScheduledPosts,
  fetchingPostedPosts,
  fetchingScheduledPosts,
  scheduledPosts,
  postedPosts,
  history,
  title,
  deletePost,
  isDeleting,
  getNextPostedPosts,
  getNextScheduledPosts,
}) => {
  useEffect(() => {
    getScheduledPosts(venue.id, 0);
    getPostedPosts(venue.id, 0);
    fireEvent(GACategories.SOCIAL_MEDIA_CONTENT, GATags.VIEW_SCHEDULE);
  }, [getPostedPosts, getScheduledPosts, venue.id]);

  const [scheduledPostsOffset, setScheduledPostsOffset] = useState(limit);
  const [postedPostsOffset, setPostedPostsOffset] = useState(limit);
  const [postToRemove, setPostToRemove] = useState(false);

  const activePostRow = post => (
    <div key={post.id} className="SocialSchedule_bottomContainer_postRow_container">
      <img alt="" className="SocialSchedule_bottomContainer_postRow_preview" src={post.preview} />
      <p className="SocialSchedule_bottomContainer_postRow_time">
        {getLocaleDate(post.timeUtc, 'do MMM HH:mm')}
      </p>
      <div className="SocialSchedule_buttons">
        <SocialIcon Icon={FacebookIcon} brandColor="text-[#3b5998]" disabled={!post.facebook} />

        <Button
          className="bg-lightGrey text-sm font-semibold"
          onClick={() =>
            history.push(routes.marketing.subRoutes.postPublish.path, {
              facebook: post.facebook === 'scheduled',
              twitter: post.twitter === 'scheduled',
              message: post.message,
              time: post.timeUtc,
              id: post.id,
              content: { ...post, link: post.preview },
              preventEdit: true,
            })
          }
        >
          {i18n.t('general.edit')}
        </Button>

        <SpinnerButton
          className="bg-primaryRed px-3 rounded text-sm font-semibold uppercase"
          onClick={() => setPostToRemove(post)}
          loading={isDeleting}
        >
          {i18n.t('general.delete')}
        </SpinnerButton>
      </div>
      <MPModal
        className="SocialSchedule_modal"
        onClose={() => setPostToRemove(null)}
        open={Boolean(postToRemove)}
      >
        <div className="SocialSchedule_modal_items">
          <h2 className="SocialSchedule_modal_items_title">{i18n.t('general.areYouSure')}</h2>
          <div className="SocialSchedule_modal_items_buttons">
            <Button className="bg-lightGrey" onClick={() => setPostToRemove(null)}>
              {i18n.t('general.cancel')}
            </Button>
            <Button
              className="bg-secondary"
              onClick={() => {
                fireEvent(GACategories.SOCIAL_MEDIA_CONTENT, GATags.DELETE_SM_POST, {
                  venueId: venue.id,
                });
                deletePost(venue.id, postToRemove.id);
                setPostToRemove(null);
              }}
            >
              {i18n.t('general.delete')}
            </Button>
          </div>
        </div>
      </MPModal>
    </div>
  );

  const inActivePostRow = post => (
    <div key={post.id} className="SocialSchedule_bottomContainer_postRow_container">
      <img alt="" className="SocialSchedule_bottomContainer_postRow_preview" src={post.thumbnail} />
      <p className="SocialSchedule_bottomContainer_postRow_time">
        {getLocaleDate(post.timeUtc, 'do MMM HH:mm')}
      </p>
      <div className="SocialSchedule_buttons">
        <SocialIcon
          Icon={FacebookIcon}
          brandColor="text-[#3b5998]"
          disabled={!post.facebook}
          onClick={
            post.facebook && post.facebook !== 'error'
              ? () => window.open(post.facebookUrl)
              : undefined
          }
          showBadge={post.facebook !== null}
          success={post.facebook === 'success'}
        />
      </div>
    </div>
  );

  const loadMoreButton = type => (
    <div className="SocialSchedule_loadMoreContainer">
      <SpinnerButton
        className="bg-secondary px-3 py-1 rounded text-sm font-semibold uppercase"
        loading={type === 'posted' ? fetchingPostedPosts : fetchingScheduledPosts}
        onClick={() => {
          if (type === 'posted') {
            getNextPostedPosts(venue.id, postedPostsOffset, () =>
              setPostedPostsOffset(postedPostsOffset + limit),
            );
          } else {
            getNextScheduledPosts(venue.id, scheduledPostsOffset, () =>
              setScheduledPostsOffset(scheduledPostsOffset + limit),
            );
          }
        }}
      >
        {i18n.t('general.load_more')}
      </SpinnerButton>
    </div>
  );

  return (
    <>
      <PageHeader title={title} />
      <div className="GlobalPad">
        <>
          {scheduledPosts.length > 0 && (
            <>
              <div className="SocialSchedule_bottomContainer">
                <p className="SocialSchedule_bottomContainer_postRow_title">
                  {i18n.t('socialSchedule.ScheduledPosts')}
                </p>
                <div className="SocialSchedule_bottomContainer_postRow">
                  {scheduledPosts.map(post => activePostRow(post))}
                </div>
              </div>
              {hasMoreScheduledPosts && loadMoreButton()}
            </>
          )}
          {(fetchingScheduledPosts || fetchingPostedPosts) && <LoadingAnimation />}
          {postedPosts.length > 0 && (
            <>
              <div className="SocialSchedule_bottomContainer">
                <p className="SocialSchedule_bottomContainer_postRow_title">
                  {i18n.t('socialSchedule.postedPosts')}
                </p>
                <div className="SocialSchedule_bottomContainer_postRow">
                  {postedPosts.map(post => inActivePostRow(post))}
                </div>
              </div>
              {hasMorePostedPosts && loadMoreButton('posted')}
            </>
          )}
        </>
        {!fetchingPostedPosts &&
          !fetchingScheduledPosts &&
          postedPosts.length === 0 &&
          scheduledPosts.length === 0 && (
            <div className="SocialSchedule_bottomContainer">
              <h2>{i18n.t('socialSchedule.noPosts')}</h2>
            </div>
          )}
      </div>
    </>
  );
};

SocialSchedule.propTypes = {
  venue: venueProps.isRequired,
  getScheduledPosts: PropTypes.func.isRequired,
  getPostedPosts: PropTypes.func.isRequired,
  fetchingScheduledPosts: PropTypes.bool.isRequired,
  fetchingPostedPosts: PropTypes.bool.isRequired,
  scheduledPosts: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      time: PropTypes.string,
      facebook: PropTypes.string,
      twitter: PropTypes.string,
      fixtures: PropTypes.arrayOf(fixtureProps),
      contentId: PropTypes.number,
      themeId: PropTypes.number,
    }),
  ).isRequired,
  postedPosts: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      time: PropTypes.string,
      facebook: PropTypes.string,
      twitter: PropTypes.string,
      fixtures: PropTypes.arrayOf(fixtureProps),
      contentId: PropTypes.number,
      themeId: PropTypes.number,
    }),
  ).isRequired,
  history: historyProps.isRequired,
  title: PropTypes.string.isRequired,
  deletePost: PropTypes.func.isRequired,
  isDeleting: PropTypes.bool.isRequired,

  hasMorePostedPosts: PropTypes.bool.isRequired,
  hasMoreScheduledPosts: PropTypes.bool.isRequired,
  getNextPostedPosts: PropTypes.func.isRequired,
  getNextScheduledPosts: PropTypes.func.isRequired,
};

const mapDispatchToProps = dispatch => ({
  getPostedPosts: venueID => dispatch(fetchSocialMediaPostedPosts(venueID, limit)),
  getScheduledPosts: venueID => dispatch(fetchSocialMediaScheduledPosts(venueID, limit)),
  getNextPostedPosts: (venueID, offset, callback) =>
    dispatch(fetchSocialMediaNextPostedPosts(venueID, offset, limit, callback)),
  getNextScheduledPosts: (venueID, offset, callback) =>
    dispatch(fetchSocialMediaNextScheduledPosts(venueID, offset, limit, callback)),
  deletePost: (venueID, postId) => dispatch(deleteSocialMediaPost(venueID, postId)),
});

const mapStateToProps = state => ({
  venue: getVenue(state),
  hasMorePostedPosts: getHasMorePostedPosts(state),
  hasMoreScheduledPosts: getHasMoreScheduledPosts(state),
  postedPosts: getSocialMediaPostedPosts(state),
  scheduledPosts: getSocialMediaScheduledPosts(state),
  fetchingPostedPosts: getIsFetchingPostedPosts(state),
  fetchingScheduledPosts: getIsFetchingScheduledPosts(state),
  isDeleting: getIsDeletingPost(state),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SocialSchedule));

import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useScript } from '../../utils/CustomHooks';

const TweetEmbed = ({ tweet, index }) => {
  useScript(
    'https://platform.twitter.com/widgets.js',
    'twitterScript',
    useCallback(() => {
      const tweetEl = document.getElementById(`tweet${index}`);
      window.twttr.widgets.createTweet(tweet, tweetEl);
    }, [index, tweet]),
  );

  return <div id={`tweet${index}`} className="flex justify-center m-5" />;
};

TweetEmbed.propTypes = {
  tweet: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
};

export default TweetEmbed;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import i18n from 'i18next';
import { format } from 'date-fns';
import { Link } from 'react-router-dom';
import FixturesFilters from './FixturesFilters/FixturesFilters';
import routes from '../../routes/paths';
import FixtureDateSelector from '../../components/Fixtures/FixtureDateSelector/FixtureDateSelector';
import FixturesSearch from '../../components/Fixtures/FixturesSearch';
import { filterActions, filterTypes } from './filtersReducer';
import PlainButton from '../../components/PlainButton/PlainButton';
import CloseIcon from '../../components/Icons/CloseIcon';
import ChevronIcon from '../../components/Icons/ChevronIcon';
import { fireEvent, GACategories, GATags } from '../../utils/trackingHelper';
import PlusIcon from '../../components/Icons/PlusIcon';
import PreferencesIcon from '../../components/Icons/PreferencesIcon';
import PreferencesModal from './PreferencesModal';

const FixturesHeader = ({
  filters,
  dispatch,
  fetchPaginated,
  hasActiveSearch,
  showSelectAllButton,
  selectAllFixtures,
  selectAllButtonDisabled,
  areThereActiveFilters,
  disabledUI,
  onChangeDefaultPrefs,
}) => {
  const [filtersOpen, setFiltersOpen] = useState(false);
  const [prefOpen, setPrefOpen] = useState(false);
  const activeFilters = filters?.[filterTypes.active]?.data;

  const topFilters = [
    {
      text: 'fixtures_allFixtures',
      tag: GATags.SHOW_ALL_FIXTURES,
      action: filterActions.showAll,
      active: !filters.popularFixtures && !filters.onlyFavourites,
    },
    {
      text: 'fixtures_popularFixtures',
      tag: GATags.POPULAR_FIXTURES_FILTER_ON,
      action: filterActions.popularFixtures,
      active: filters.popularFixtures,
    },
    {
      text: 'fixtures_myFavourites',
      tag: GATags.FAVOURITES_FIXTURES_FILTER_ON,
      action: filterActions.onlyFavourites,
      active: filters.onlyFavourites,
    },
  ];

  return (
    <div className="bg-white contents">
      <div className="px-4 md:px-6 lg:px-10 pt-4 sm:pt-12 bg-white flex justify-between items-center">
        <h1 className="headingFont text-4xl sm:text-5xl leading-none pr-4">
          {i18n.t('titles_addFixtures')}
        </h1>
        <Link
          to={routes.fixtures.subRoutes.mySchedule.path}
          className="font-semibold bg-mpGrey rounded-lg px-4 py-3 h-full flex-shrink-0 self-start"
          onClick={() => fireEvent(GACategories.FIXTURES, GATags.MY_SCHEDULE_BUTTON)}
        >
          {i18n.t('fixtures_mySchedule')}
        </Link>
      </div>
      <div
        className="px-4 md:px-6 lg:px-10 bg-white border-b border-mpGrey sticky top-20 lg:top-0 z-20"
        id="filters-sticky-header"
      >
        <div className={disabledUI ? 'opacity-30 pointer-events-none' : ''}>
          <div className="flex gap-2 py-4 sm:py-6">
            {topFilters.map(({ text, tag, action, active }) => (
              <PlainButton
                key={text}
                className={`border-2 rounded-full px-4 py-1.5 sm:px-6 sm:py-2.5 ${
                  active ? 'border-secondary bg-secondaryLight' : 'border-mpGrey bg-white'
                }`}
                onClick={() => {
                  fireEvent(GACategories.FIXTURES, tag);
                  dispatch({ type: action });
                }}
                testId="fixturesTopFilter"
              >
                <p className="text-xs sm:text-base opacity-80 font-semibold">{i18n.t(text)}</p>
              </PlainButton>
            ))}
          </div>
          <div className="flex justify-between items-center pb-4 sm:pb-6 lg:flex-row-reverse">
            <FixturesSearch dispatch={dispatch} hasActiveSearch={hasActiveSearch} />
            <div className="flex gap-2">
              {showSelectAllButton && (
                <PlainButton
                  onClick={selectAllFixtures}
                  className={`flex bg-mpGrey p-3 rounded-full items-center ${
                    selectAllButtonDisabled ? 'opacity-30' : ''
                  }`}
                  disabled={selectAllButtonDisabled}
                >
                  <PlusIcon className="w-6 h-6 ml-1" />
                  <p className="text-xs md:text-base font-semibold">
                    {i18n.t('fixtures_filters_add_all')}
                  </p>
                </PlainButton>
              )}
              <PlainButton
                className="flex items-center rounded-full p-2.5 sm:py-3 sm:px-4 border-2 border-veryLightGrey"
                onClick={() => {
                  fireEvent(GATags.PREFERENCES_CLICK);
                  setPrefOpen(true);
                }}
              >
                <PreferencesIcon className="h-6 w-6" />
                <p className="hidden sm:block font-semibold ml-2 capitalize">
                  {i18n.t('fixtures_preferences')}
                </p>
              </PlainButton>
              <PreferencesModal
                open={prefOpen}
                onClose={() => setPrefOpen(false)}
                onChangeDefaultPrefs={onChangeDefaultPrefs}
              />
              <FixturesFilters
                filters={filters}
                fetchPaginated={fetchPaginated}
                dispatch={dispatch}
                areThereActiveFilters={areThereActiveFilters}
              />
            </div>
          </div>

          {filters?.openHours?.initialData?.date && (
            <FixtureDateSelector
              onClick={date =>
                dispatch({
                  type: filterActions.changeDayOpenHours,
                  payload: { date: format(date, 'yyyy-MM-dd') },
                })
              }
              openHours={filters.openHours}
            />
          )}
          {activeFilters?.length > 0 && (
            <div className="mb-6 gap-2 flex-wrap hidden lg:flex">
              {activeFilters?.slice(0, filtersOpen ? activeFilters?.length : 2)?.map(filter => (
                <PlainButton
                  key={filter?.id}
                  onClick={() => {
                    dispatch({
                      type: filterActions.unselectFilter,
                      payload: { filter },
                    });
                    dispatch({ type: filterActions.applyFilters });
                  }}
                  className="uppercase text-xs py-1 pl-4 pr-2 border-2 rounded-full border-mpGrey flex items-center"
                >
                  <p>{filter?.name}</p>
                  <CloseIcon className="w-6 h-6 ml-4" />
                </PlainButton>
              ))}
              {activeFilters?.length > 2 && (
                <PlainButton className="flex items-center" onClick={() => setFiltersOpen(o => !o)}>
                  <ChevronIcon
                    className={`h-5 w-5 mr-2 ${!filtersOpen ? 'rotate-90' : '-rotate-90'}`}
                  />
                  {i18n.t(filtersOpen ? 'general.show_less' : 'general.show_more')}
                </PlainButton>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

FixturesHeader.propTypes = {
  filters: PropTypes.shape().isRequired,
  dispatch: PropTypes.func.isRequired,
  fetchPaginated: PropTypes.func.isRequired,
  hasActiveSearch: PropTypes.bool.isRequired,
  showSelectAllButton: PropTypes.bool.isRequired,
  selectAllButtonDisabled: PropTypes.bool.isRequired,
  selectAllFixtures: PropTypes.func.isRequired,
  areThereActiveFilters: PropTypes.bool.isRequired,
  disabledUI: PropTypes.bool.isRequired,
  onChangeDefaultPrefs: PropTypes.func.isRequired,
};

export default FixturesHeader;

import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getVenue } from '../../../store/venue/selectors';
import { venueProps } from '../../../utils/customPropTypes';
import MainLinks from '../../NavLinks/NavLinks';
import PlainButton from '../../PlainButton/PlainButton';
import SwitchProfile from './SwitchProfile';
import LogoutButton from './LogoutButton';
import CloseIcon from '../../Icons/CloseIcon';

const MobileSideBar = ({ venue, closeFunction }) => (
  <div className="flex flex-col justify-between h-full lg:hidden">
    <div className="flex flex-col items-center p-8">
      <PlainButton
        className="absolute top-4 right-4 bg-brandedGrey-3 rounded-full p-2.5"
        onClick={closeFunction}
      >
        <CloseIcon className="w-7 h-7 text-white" />
      </PlainButton>
      <img
        className="rounded-full border-2 border-white h-[88px] w-[88px]"
        alt={venue?.name}
        src={venue?.profilePicture['200_160']}
      />
      <p className="text-xl font-bold text-white pt-2 text-center">{venue?.name}</p>
    </div>

    <div className="flex-1 overflow-auto noScrollBar">
      <PlainButton onClick={closeFunction} className="w-full">
        <MainLinks />
      </PlainButton>
      <SwitchProfile />
    </div>

    <LogoutButton />
  </div>
);

MobileSideBar.propTypes = {
  venue: venueProps,
  closeFunction: PropTypes.func.isRequired,
};

MobileSideBar.defaultProps = {
  venue: null,
};

const mapStateToProps = state => ({
  venue: getVenue(state),
});

export default connect(mapStateToProps)(MobileSideBar);

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link, useLocation } from 'react-router-dom';
import { t } from 'i18next';
import { connect } from 'react-redux';
import routes, { upgradeModal } from '../../routes/paths';
import { getVenue } from '../../store/venue/selectors';
import { venueProps } from '../../utils/customPropTypes';
import { GACategories, GATags, fireEvent } from '../../utils/trackingHelper';
import { venueIsLead } from '../../utils/general';
import { cdnUrl } from '../../utils/constants';

const s = {
  overlay: 'absolute inset-0 bg-black opacity-60',
  innerContainer:
    'max-w-sm md:max-w-md flex flex-col p-8 mx-4 md:mx-0 bg-white rounded-2xl text-center z-30',
  btn: 'block w-full p-4 font-semibold rounded-lg bg-black text-white',
};

const UpgradeModal = ({ contentOverride, venue, testId }) => {
  const { pathname } = useLocation();
  const [content, setContent] = useState(null);
  const { title, text, image } = content || {};

  useEffect(() => {
    if (content && venueIsLead(venue)) {
      document.body.style.overflow = 'hidden';
      window.scrollTo(0, 0);
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [content, venue]);

  useEffect(() => {
    setContent(contentOverride || upgradeModal[pathname]);
  }, [contentOverride, pathname]);

  if (!venueIsLead(venue)) {
    return null;
  }

  return (
    <div
      className={`${
        content ? '' : 'hidden'
      } absolute inset-0 flex items-center justify-center z-20 `}
    >
      <div className={s.overlay} />
      <div className={s.innerContainer}>
        <h1 className="headingFont text-4xl md:text-5xl">{t(title)}</h1>
        <p className="opacity-60 md:text-xl pt-2">{t(text)}</p>
        {image && (
          <img
            src={`${cdnUrl}/upgrade-modal/${image}`}
            className="w-full p-8 object-contain"
            alt=""
          />
        )}

        <Link
          to={{ pathname: routes.subscriptions.path, state: { page: pathname } }}
          className={s.btn}
          onClick={() => {
            fireEvent(GACategories.UPGRADE, GATags.UPGRADE_MODAL, { page: pathname });
            setContent(null);
          }}
          data-test-id={testId}
        >
          {t('subscriptions_choosePlan')}
        </Link>
      </div>
    </div>
  );
};

UpgradeModal.propTypes = {
  venue: venueProps,
  contentOverride: PropTypes.shape({
    title: PropTypes.string,
    text: PropTypes.string,
    image: PropTypes.string,
  }),
  testId: PropTypes.string,
};

UpgradeModal.defaultProps = {
  contentOverride: null,
  venue: null,
  testId: '',
};

const mapStateToProps = state => ({
  venue: getVenue(state),
});

export default connect(mapStateToProps)(UpgradeModal);

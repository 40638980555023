import React from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18next';
import PlainButton from '../../../../components/PlainButton/PlainButton';
import TextArea from '../../../../components/TextArea/TextArea';
import Input from '../../Input';
import EditIcon from '../../../../components/Icons/EditIcon';

const KeyInfo = ({ data, onChange, openModal, fieldErrors }) => (
  <>
    <div className="flex pt-4 lg:pt-8 justify-between items-start">
      <div className="mr-4">
        <p className="font-bold pb-2">{t('signup_venue_nameAddressLabel')}</p>
        <p className="opacity-60">{data.name}</p>
      </div>
      <PlainButton
        className="flex items-center px-4 py-3 bg-mpGrey rounded-full outline-none"
        onClick={openModal}
      >
        <EditIcon className="h-5 w-5" />
        <p className="ml-2 text-xs font-semibold">
          <span className="lg:hidden capitalize">{t('general.edit')}</span>
          <span className="hidden lg:block">{t('editProfile_editAddress')}</span>
        </p>
      </PlainButton>
    </div>
    <p className="opacity-60">{data.address1}</p>
    <p className="opacity-60">{data.city}</p>
    <p className="opacity-60 pb-2 md:pb-6 lg:pb-8">{data.postCode}</p>
    <p className="font-bold pb-2">{t('signup_venue_aboutLabel')}</p>
    <div className="flex flex-col gap-4">
      <TextArea
        className="rounded-lg mb-0 px-4 py-3 border border-mpGrey focus:outline-primary !outline-offset-0"
        placeholder={t('signup_venue_aboutPlaceholder')}
        value={data.longDescription}
        onChange={v => onChange({ longDescription: v })}
        data-test-id="longDescription"
      />
      <Input
        label={t('signup_venue_websiteLabel')}
        placeholder={t('signup_venue_websitePlaceholder')}
        type="url"
        value={data.website}
        onChange={v => onChange({ website: v })}
        error={fieldErrors?.website?.[0]}
        dataTestId="website"
      />
      <Input
        label={t('signup_venue_phoneNumberLabel')}
        placeholder={t('signup_venue_phoneNumberPlaceholder')}
        type="tel"
        value={data.phone}
        onChange={v => onChange({ phone: v })}
        error={fieldErrors?.phone?.[0]}
        dataTestId="phone"
      />
    </div>
  </>
);

KeyInfo.propTypes = {
  data: PropTypes.shape().isRequired,
  onChange: PropTypes.func.isRequired,
  openModal: PropTypes.func.isRequired,
  fieldErrors: PropTypes.shape(),
};

KeyInfo.defaultProps = {
  fieldErrors: null,
};

export default KeyInfo;

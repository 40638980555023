import React, { useState } from 'react';
import PropTypes from 'prop-types';
import i18n from 'i18next';
import { Link } from 'react-router-dom';
import routes, { pdfPath } from '../../routes/paths';
import BackIcon from '../Icons/BackIcon';
import CalendarPlusIcon from '../Icons/CalendarPlusIcon';
import { fireEvent, GACategories, GATags } from '../../utils/trackingHelper';
import Modal from '../../pages/FixturesPage/Modal';
import { getServicesEndpoint } from '../../utils/environment';

const styles = {
  button: 'flex flex-1 font-semibold bg-mpGrey rounded-lg px-4 py-3 items-center justify-center',
  downloadButtons: 'font-semibold flex-1 rounded-lg py-3 bg-mpGrey text-center',
};

const MyScheduleHeader = ({ venueID }) => {
  const [downloadModal, setDownloadModal] = useState(false);

  const downloadTypes = [
    {
      text: i18n.t('fixtures_excel'),
      link: `${getServicesEndpoint()}venues/${venueID}/fixtures/excel`,
      tag: GATags.EXCEL,
    },
    {
      text: i18n.t('fixtures_pdf'),
      link: pdfPath(venueID),
      tag: GATags.PDF,
    },
  ];

  const rightButtons = (
    <>
      <div className="md:flex-1" />
      <div className="flex flex-1 md:flex-none md:grid grid-cols-2 gap-4 justify-center">
        <Link
          className={styles.button}
          to={routes.marketing.subRoutes.integrate.subRoutes.calendar.path}
          onClick={() => fireEvent(GACategories.FIXTURES, GATags.CALENDAR_SYNC, { venueID })}
        >
          <CalendarPlusIcon className="h-6 w-6 mr-2" />
          <span className="2xl:hidden">{i18n.t('fixtures_sync')}</span>
          <span className="hidden 2xl:block">{i18n.t('fixtures_syncToCalendar')}</span>
        </Link>
        <button className={styles.button} type="button" onClick={() => setDownloadModal(true)}>
          {i18n.t('general_download')}
        </button>
      </div>
    </>
  );

  return (
    <div className="px-4 md:px-6 xl:px-10 pt-14 pb-4 lg:py-5 border-b border-mpGrey bg-white">
      <div className="flex justify-between items-center gap-2">
        <Link className="flex items-center md:flex-1" to={routes.fixtures.path}>
          <BackIcon className="w-10 h-10 -ml-3" />
          <span className="hidden md:block font-semibold text-xl">
            {i18n.t('titles_addFixtures')}
          </span>
        </Link>

        <h1 className="headingFont flex-1 md:flex-none text-4xl md:text-5xl leading-none text-center">
          {i18n.t('fixtures_mySchedule')}
        </h1>

        <div className="hidden md:flex flex-1">{rightButtons}</div>
      </div>

      <div className="flex md:hidden mt-8">{rightButtons}</div>

      <Modal
        closeModal={() => setDownloadModal(false)}
        open={downloadModal}
        title={i18n.t('fixtures_download')}
        buttons={
          <div className="flex pt-3 text-lg gap-2">
            {downloadTypes.map(type => (
              <a
                key={type.tag}
                className={styles.downloadButtons}
                onClick={() => {
                  fireEvent(GACategories.FIXTURES, type.tag, { venueID });
                  setDownloadModal(false);
                }}
                href={type.link}
              >
                {type.text}
              </a>
            ))}
          </div>
        }
      >
        <p className="text-lg my-5">{i18n.t('fixtures_downloadDescription')}</p>
      </Modal>
    </div>
  );
};

export default MyScheduleHeader;

MyScheduleHeader.propTypes = {
  venueID: PropTypes.number.isRequired,
};

/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';
import Input from '../Input/Input';

const FormField = ({
  number,
  title,
  subtitle,
  type,
  onValueChange,
  disabled,
  inputType,
  secondType,
  secondOnChange,
  children,
  noBorder,
  value,
  secondValue,
  inputErrorMessage,
  ...rest
}) => (
  <div
    style={{ borderBottom: `${noBorder ? 'none' : '1px solid lightgrey'}` }}
    className={`FormField${disabled ? '_disabled' : ''}`}
  >
    <p className="font-semibold">
      {number.toLocaleString('en-US', { minimumIntegerDigits: 2 })} - {title.toUpperCase()}
    </p>
    <p className="text-standardGrey text-sm pb-3">{subtitle}</p>
    {!children ? (
      <>
        <Input
          className="FormField_input"
          disabled={disabled}
          type={inputType}
          size={type === 'double' ? 'double' : 'single'}
          onChange={onValueChange}
          errorMessage={inputErrorMessage}
          value={value}
          {...rest}
        />
        {type === 'two' && (
          <Input
            className="FormField_input"
            disabled={disabled}
            type={secondType}
            size="single"
            onChange={secondOnChange}
            value={secondValue}
            errorMessage={inputErrorMessage}
            {...rest}
          />
        )}
      </>
    ) : (
      children
    )}
  </div>
);

FormField.propTypes = {
  inputErrorMessage: PropTypes.string,
  number: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['two', 'single', 'double']),
  onValueChange: PropTypes.func,
  inputType: PropTypes.string,
  disabled: PropTypes.bool,
  secondOnChange: PropTypes.func,
  secondType: PropTypes.string,
  children: PropTypes.node,
  noBorder: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  secondValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

FormField.defaultProps = {
  inputErrorMessage: null,
  type: 'single',
  inputType: 'string',
  secondOnChange: () => {},
  onValueChange: () => {},
  secondType: 'string',
  children: null,
  noBorder: false,
  disabled: false,
  value: undefined,
  secondValue: undefined,
};

export default FormField;

import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';

const BannerImage = ({ image }) => (
  <div style={{ backgroundImage: `url(${image})` }} className="BannerImage" />
);

BannerImage.propTypes = {
  image: PropTypes.string.isRequired,
};

export default BannerImage;

import React from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18next';
import NumberSelector from '../../NumberSelector';

const NumOfScreens = ({ data, onChange }) => (
  <>
    <h1 className="headingFont text-4xl md:text-5xl lg:text-6xl pb-2 mr-32 md:mr-0">
      {t('signup_venue_numScreensTitle')}
    </h1>
    <p className="opacity-60 md:text-xl pb-8">{t('signup_venue_numScreensSubtitle')}</p>
    <NumberSelector counter={data} onChange={v => onChange(v)} />
  </>
);

NumOfScreens.propTypes = {
  data: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default NumOfScreens;

import React from 'react';
import PropTypes from 'prop-types';

const Legend = ({ data }) => (
  <div className="BubbleChart_legendContainer">
    {data.map(elm => (
      <React.Fragment key={elm.text}>
        <div className={`BubbleChart_legendContainer_legend ${elm?.color}`} />
        <p>{elm.text}</p>
      </React.Fragment>
    ))}
  </div>
);

Legend.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      color: PropTypes.string.isRequired,
    }).isRequired,
  ).isRequired,
};

export default Legend;

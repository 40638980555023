import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18next';
import { connect } from 'react-redux';
import SplitViewWithTabs from '../../components/SplitViewWithTabs/SplitViewWithTabs';
import SpinnerButton from '../../components/SpinnerButton/SpinnerButton';
import Button from '../../components/Button/Button';
import routes from '../../routes/paths';
import AssetFormatIcon, {
  types as formats,
} from '../../components/AssetFormatIcon/AssetFormatIcon';
import { useRequest } from '../../utils/CustomHooks';
import { historyProps } from '../../utils/customPropTypes';
import { fetchAssetsAPI } from '../../store/api/socialMedia';
import { getVenueLeague } from '../../store/venue/selectors';
import { getServicesEndpoint } from '../../utils/environment';
import { fireEvent, GACategories, GATags } from '../../utils/trackingHelper';

const styles = {
  title: 'text-white text-xl font-bold',
  button: 'rounded-full mr-4 h-11 w-full',
  unselected: 'border text-white',
  selected: 'bg-secondary text-black',
};

const PromotionalMaterial = ({ fetchAssets, league, history }) => {
  const { gameId, venueId } = league || {};

  const [format, setFormat] = useState(null);
  const [, assets, fetch] = useRequest(fetchAssets);
  const [type, setType] = useState(null);
  const [availableFormats, setAvailableFormats] = useState([]);

  const imageURL = `${getServicesEndpoint()}/games/${gameId}/marketing-assets/${
    format?.id
  }?venueId=${venueId}`;

  const types = [
    { key: 'join_league', textKey: t('tipping.promotional.join') },
    { key: 'top_ten', textKey: t('tipping.promotional.topTen') },
  ];

  useEffect(() => {
    fetch(venueId, gameId);
  }, [fetch, gameId, venueId]);

  useEffect(() => {
    if (type) {
      // Filter the fetched assets based on the active type
      const activeAssets = assets?.assets?.filter(e => e.type === type);
      setAvailableFormats([
        ...activeAssets,
        {
          // Add vertical screen format copied from the story format
          ...activeAssets.find(af => af.format === formats.story),
          format: formats.screenV,
        },
      ]);
      setFormat(null);
    }
  }, [assets?.assets, type]);

  return (
    <SplitViewWithTabs
      left={
        <div className="p-10 overflow-y-auto md:w-96 flex-1 bg-veryDarkGrey">
          <p className={styles.title}>{t('tipping.promotional')}</p>
          <div className="flex py-4">
            {types.map(({ key, textKey }) => (
              <Button
                key={key}
                className={`${key === type ? styles.selected : styles.unselected} ${styles.button}`}
                onClick={() => {
                  fireEvent(GACategories.FOOTY_TIPPING, GATags.CHOOSE_FORMAT, {
                    format: key,
                  });
                  setType(key);
                }}
              >
                <p className="text-xs font-semibold">{textKey}</p>
              </Button>
            ))}
          </div>
          {type && availableFormats?.length > 0 && (
            <>
              <p className={styles.title}>{t('marketing.format.title')}</p>
              <div className="flex flex-wrap gap-4 py-4">
                {Object.values(availableFormats).map(f => (
                  <AssetFormatIcon
                    key={f.format}
                    type={f.format}
                    selected={format?.format === f.format}
                    onClick={() => {
                      fireEvent(GACategories.FOOTY_TIPPING, GATags.CHOOSE_MEDIUM, {
                        medium: f,
                      });
                      setFormat(f);
                    }}
                  />
                ))}
              </div>
            </>
          )}
        </div>
      }
      right={
        <div className="flex flex-col flex-1 bg-white p-10 items-center">
          <p className="text-xl font-bold self-start pb-2">{t('marketing.preview')}</p>
          {format && (
            <img src={imageURL} alt="preview" className="PostToSocial_column_template_image" />
          )}
          {format?.downloadable && (
            <SpinnerButton
              className="bg-veryLightGrey w-56 mt-3 py-1 rounded mb-3"
              onClick={() => {
                fireEvent(GACategories.FOOTY_TIPPING, GATags.DOWNLOAD_ASSET);
                window.open(imageURL);
              }}
            >
              <p className="text-base font-semibold">{t('general_download')}</p>
            </SpinnerButton>
          )}
          {format?.shareable && (
            <SpinnerButton
              className="bg-secondary w-56 py-1 rounded"
              onClick={() => {
                fireEvent(GACategories.FOOTY_TIPPING, GATags.SCHEDULE);
                history.push(routes.marketing.subRoutes.postPublish.path, {
                  preventFixtureFetch: true,
                  content: { link: imageURL, gameAssetTemplateId: format.id },
                  preventEdit: true,
                });
              }}
            >
              <p className="text-base font-semibold">{t('marketing.SchedulePost')}</p>
            </SpinnerButton>
          )}
        </div>
      }
    />
  );
};

PromotionalMaterial.propTypes = {
  fetchAssets: PropTypes.func.isRequired,
  league: PropTypes.shape(),
  history: historyProps.isRequired,
};
PromotionalMaterial.defaultProps = {
  league: {},
};

const mapStateToProps = state => ({
  league: getVenueLeague(state),
});

const mapDispatchToProps = {
  fetchAssets: fetchAssetsAPI,
};

export default connect(mapStateToProps, mapDispatchToProps)(PromotionalMaterial);

import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { GoogleApiWrapper } from 'google-maps-react';
import { t } from 'i18next';
import { GoogleMapsAPIKey } from '../../utils/constants';
import { debounce, getAddress } from '../../utils/general';
import SearchIcon from '../../components/Icons/SearchIcon';
import PlainButton from '../../components/PlainButton/PlainButton';
import { countryProps } from '../../utils/customPropTypes';
import { getRegion } from '../../store/api/request';

const s = {
  input: 'w-full bg-white outline-none py-3 pl-4 pr-10',
  open: 'border-t-2 border-x-2 border-primary rounded-t-lg',
  closed: 'border border-mpGrey rounded-lg',
  results:
    'absolute bg-white w-full rounded-b-lg border-b-2 border-x-2 border-b-primary border-x-primary z-10',
  item:
    'w-full text-left py-3 px-4 border-t border-mpGrey text-standardGrey outline-none hover:bg-secondaryLight last:rounded-b-lg',
};

const AddressSearch = ({ value, onChange, countries, onItemClick, google }) => {
  const [places, setPlaces] = useState([]);
  const [sessionToken, setSessionToken] = useState(null);
  const [region, setRegion] = useState(null);
  const hasResults = places.length > 0;
  const googlePlaces = google?.maps?.places;

  useEffect(() => {
    if (googlePlaces) {
      setSessionToken(new googlePlaces.AutocompleteSessionToken());
    }
    if (!region) {
      getRegion().then(res => setRegion(res));
    }
  }, [googlePlaces, region]);

  const debouncedSearch = useMemo(
    () =>
      debounce(search => {
        if (search.length > 2) {
          const service = new googlePlaces.AutocompleteService();
          service.getPlacePredictions(
            { input: search, sessionToken, region },
            (predictions, status) => {
              if (status === googlePlaces.PlacesServiceStatus.OK) {
                setPlaces(predictions);
              } else {
                setPlaces([]);
              }
            },
          );
        }
      }, 750),
    [googlePlaces, sessionToken, region],
  );

  const handleInputChange = e => {
    onChange(e.target.value);
    if (e.target.value.length === 0) {
      setPlaces([]);
    }
    debouncedSearch(e.target.value);
  };

  const onClick = placeId => {
    const service = new googlePlaces.PlacesService(document.createElement('div'));
    service.getDetails(
      { placeId, sessionToken, fields: ['address_components', 'types'] },
      (place, status) => {
        if (status === googlePlaces.PlacesServiceStatus.OK) {
          const adr = getAddress(place.address_components);
          const country = countries.find(c => c.name === adr.country || c.name === adr.region);

          onItemClick({
            googlePlaceId: placeId,
            googlePlaceTypes: place.types.join(', '),
            address1:
              adr.number && adr.street
                ? `${adr.number} ${adr.street}`
                : adr.number || adr.street || '',
            address2: adr.subregion,
            city: adr.city,
            postCode: adr.postcode,
            ...(country && {
              countryId: country?.id,
              country: { key: country?.id, value: country?.name },
            }),
          });
          onChange('');
          setPlaces([]);
          setSessionToken(new googlePlaces.AutocompleteSessionToken());
        }
      },
    );
  };

  return (
    <div>
      <p className="font-bold pb-2">{t('signup_venue_address1Label')}</p>
      <div className="relative">
        <input
          className={`${s.input} ${hasResults ? s.open : s.closed}`}
          placeholder={t('signup_venue_address1Placeholder')}
          value={value}
          onChange={handleInputChange}
          type="text"
          data-test-id="addressSearch"
        />
        <div className="pointer-events-none absolute top-1/2 right-4 -translate-y-1/2 transform">
          <SearchIcon className="text-black w-6" />
        </div>
        {hasResults && (
          <ul className={s.results}>
            {places.map(prediction => (
              <PlainButton
                onClick={() => onClick(prediction.place_id)}
                key={prediction.place_id}
                className={s.item}
                testId="addressSearchResult"
              >
                {prediction.description}
              </PlainButton>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

AddressSearch.propTypes = {
  countries: PropTypes.arrayOf(countryProps).isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onItemClick: PropTypes.func.isRequired,
  google: PropTypes.shape().isRequired,
};

AddressSearch.defaultProps = {
  value: '',
};

export default GoogleApiWrapper({
  apiKey: GoogleMapsAPIKey,
})(AddressSearch);

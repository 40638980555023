import React from 'react';
import PropTypes from 'prop-types';
import i18n from 'i18next';
import { Link } from 'react-router-dom';
import HeadingRow from '../../pages/FixturesPage/HeadingRow';
import { fixtureProps } from '../../utils/customPropTypes';
import SelectorFixtureRow from './SelectorFixtureRow';
import routes from '../../routes/paths';
import { getLocaleDate } from '../../i18n';
import LoadingAnimation from '../LoadingAnimation/LoadingAnimation';
import InfiniteScroll from '../InfiniteScroll';

const dateFormat = 'EEEE do LLLL';
const dFormat = date => getLocaleDate(date, dateFormat);

const FixtureList = ({
  fixtures,
  hasMorePages,
  isFetching,
  isFetchingNext,
  fetchMore,
  addFixture,
  deleteFixture,
  selectedFixtures,
  addDisabled,
}) => {
  if (fixtures.length === 0 && !isFetching) {
    return (
      <div className="fixtureList_Error_noFixtures">
        <p className="fixtureList_Error_noFixtures_text">
          {i18n.t('fixtures.no_fixtures.selector.before_link')}
          <Link to={routes.fixtures.path}>{i18n.t('titles.fixtures')}</Link>
          {i18n.t('fixtures.no_fixtures.selector.after_link')}
        </p>
      </div>
    );
  }
  const firstDate = dFormat(fixtures.length > 0 ? fixtures[0].startTimeUtc : undefined);
  let newestDate = dFormat(fixtures.length > 0 ? fixtures[0].startTimeUtc : undefined);

  const getRow = fixture => (
    <SelectorFixtureRow
      key={fixture.id}
      fixture={fixture}
      addFixture={addFixture}
      deleteFixture={deleteFixture}
      addDisabled={addDisabled}
      active={selectedFixtures.includes(fixture.id)}
    />
  );

  return (
    <div className="bg-secondaryVeryDarkGrey">
      <HeadingRow date={firstDate} className="text-white" />
      {isFetching ? (
        <LoadingAnimation size={100} />
      ) : (
        <InfiniteScroll hasMore={hasMorePages} fetchMore={fetchMore} loading={isFetchingNext}>
          {fixtures.map(fixture => {
            if (dFormat(fixture.startTimeUtc) === newestDate) {
              return getRow(fixture);
            }
            newestDate = dFormat(fixture.startTimeUtc);
            return (
              <React.Fragment key={fixture.id}>
                <HeadingRow date={newestDate} className="text-white" />
                {getRow(fixture)}
              </React.Fragment>
            );
          })}
        </InfiniteScroll>
      )}
    </div>
  );
};

FixtureList.propTypes = {
  fixtures: PropTypes.arrayOf(fixtureProps).isRequired,
  hasMorePages: PropTypes.bool.isRequired,
  isFetching: PropTypes.bool.isRequired,
  isFetchingNext: PropTypes.bool.isRequired,
  fetchMore: PropTypes.func.isRequired,
  addFixture: PropTypes.func,
  deleteFixture: PropTypes.func,
  selectedFixtures: PropTypes.arrayOf(PropTypes.number.isRequired),
  addDisabled: PropTypes.bool,
};

FixtureList.defaultProps = {
  selectedFixtures: [],
  deleteFixture: () => {},
  addFixture: () => {},
  addDisabled: false,
};

export default FixtureList;

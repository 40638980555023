import React from 'react';
import i18next from 'i18next';
import AddFavourites from '../../../../components/AddFavourites/AddFavourites';

const Favourites = () => (
  <>
    <h1 className="headingFont text-4xl md:text-5xl lg:text-6xl pb-2 mr-32 md:mr-0">
      {i18next.t('signup_favourites_title')}
    </h1>
    <p className="opacity-60 md:text-xl">{i18next.t('signup_favourites_subtitle')}</p>
    <div className="bg-white border border-mpGrey rounded-2xl p-4 md:p-8 mt-4">
      <AddFavourites />
    </div>
  </>
);

export default Favourites;

export const getIsUploading = state => state.socialMedia.isUploading;

export const getSMFixtures = state => state.socialMedia.fixtures;

export const getSMTheme = state => state.socialMedia.theme;

export const getContent = state => state.socialMedia.content;

export const getLimit = state => state.socialMedia.limit;

export const getOffset = state => state.socialMedia.offset;

export const getHasMorePages = state => state.socialMedia.hasMorePages;

export const getIsFetchingNextContent = state => state.socialMedia.isFetchingNextContent;

export const getIsFetchingContent = state => state.socialMedia.isFetchingContent;

export const getIsFetchingPosts = state => state.socialMedia.isFetchingPosts;

export const getSocialMediaScheduledPosts = state => state.socialMedia.scheduledPosts;
export const getHasMoreScheduledPosts = state => state.socialMedia.hasMoreScheduledPosts;
export const getIsFetchingScheduledPosts = state => state.socialMedia.isFetchingScheduledPosts;

export const getSocialMediaPostedPosts = state => state.socialMedia.postedPosts;
export const getHasMorePostedPosts = state => state.socialMedia.hasMorePostedPosts;
export const getIsFetchingPostedPosts = state => state.socialMedia.isFetchingPostedPosts;

export const getIsDeletingPost = state => state.socialMedia.isDeletingPost;

export const getIsFetchingPages = state => state.socialMedia.isFetchingPages;

export const getPages = state => state.socialMedia.pages;

export const getIsFetchingTwitterAccount = state => state.socialMedia.isFetchingTwitterAccount;

export const getIsFetchingFacebookAccount = state => state.socialMedia.isFetchingFacebookAccount;

export const getSocialAccounts = state => state.socialMedia.accounts;

export const getIsSavingCustomTemplate = state => state.socialMedia.isCreatingBespokeTheme;

export const getSMnumberOfFixtures = state => state.socialMedia.SMnumberOfFixtures;

export const getSMformat = state => state.socialMedia.SMformat;

import React from 'react';
import PropTypes from 'prop-types';

const VisibilityIcon = ({ className, show }) => (
  <svg className={`fill-current ${className}`} viewBox="0 0 24 24">
    {show ? (
      <>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.263 10.884c-.603 0-1.176.482-1.28 1.075-.104.593.3 1.075.903 1.075.602 0 1.175-.482 1.28-1.075.103-.593-.3-1.075-.903-1.075ZM8.8 11.96c.313-1.78 2.031-3.224 3.839-3.224 1.807 0 3.02 1.444 2.707 3.224-.312 1.78-2.03 3.223-3.838 3.223-1.808 0-3.02-1.443-2.708-3.223Z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.642 10.94c-1.277.854-2.104 1.978-2.312 3.16H3.694c.313-1.786 1.544-3.348 3.175-4.438 1.629-1.09 3.695-1.74 5.818-1.74 2.123 0 3.96.65 5.207 1.74 1.248 1.09 1.931 2.652 1.618 4.438h-1.636c.207-1.182-.226-2.306-1.204-3.16-.978-.855-2.475-1.407-4.268-1.407-1.793 0-3.484.552-4.762 1.407Z"
        />
      </>
    ) : (
      <>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.61 13.12c1.269-.773 2.034-1.76 2.208-2.756h1.637c-.294 1.674-1.541 3.104-3.146 4.081-1.608.98-3.65 1.56-5.753 1.56-2.104 0-3.942-.58-5.206-1.56-1.262-.977-2.007-2.407-1.714-4.081h1.637c-.175.996.243 1.983 1.24 2.756.995.77 2.513 1.273 4.326 1.273 1.812 0 3.506-.502 4.771-1.273Z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="m8.323 17.442.97-2.418 1.536.35-.97 2.417-1.536-.35ZM4.427 15.536l1.693-1.88 1.168.937-1.694 1.88-1.167-.937ZM19.122 15.536l-1.034-1.88-1.496.937 1.034 1.88 1.496-.937ZM14.83 17.442l-.122-2.418-1.658.35.121 2.417 1.659-.35Z"
        />
      </>
    )}
  </svg>
);

VisibilityIcon.propTypes = {
  className: PropTypes.string,
  show: PropTypes.bool.isRequired,
};

VisibilityIcon.defaultProps = {
  className: '',
};

export default VisibilityIcon;

/**
 * Object representing a response from Matchpint API servers.
 */
class MpResponse {
  constructor(status, data) {
    this.status = status;
    this.data = data;
  }

  /**
   * @description Returns if the response doesn't describe any error.
   * @returns {boolean}
   */
  isOK() {
    return this.status === 200;
  }

  /**
   * @description When updating an entity/feature, if some fields in the form are wrong,
   * The answer will still be 2XX but will have an error field.
   * @returns {boolean}
   */
  isFormError() {
    return Object.hasOwnProperty.call(this.data, 'error');
  }

  /**
   * @description Gets the error message from an error object.
   * @param error
   * @return {string}
   */
  getErrorMessage() {
    if (this.data && this.data.error && this.data.error.message) {
      return this.data.error.message;
    }
    return 'Sorry there was an error';
  }

  /**
   * @description Returns the result of the response.
   * @returns {Array|Object}
   */
  getResult() {
    return Object.hasOwnProperty.call(this.data, 'result') ? this.data.result : this.data;
  }

  getErrorFields() {
    if (!Object.hasOwnProperty.call(this.data, 'error')) {
      return {};
    }
    if (!Object.hasOwnProperty.call(this.data.error, 'fields')) {
      return this.data.error;
    }
    return this.data.error.fields;
  }
}

export default MpResponse;

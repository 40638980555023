import React from 'react';
import PropTypes from 'prop-types';

const SpinnerIcon = ({ className }) => (
  <svg className={`fill-current stroke-current ${className}`} viewBox="0 0 16 16">
    <path
      d="M1.18423 7.76088L1.16941 7.91015L1.31868 7.92497L2.19733 8.0122L2.34659 8.02702L2.36141 7.87776L2.40503 7.43843C2.68631 4.6054 5.07749 2.39234 7.98483 2.39234C10.0402 2.39234 11.8383 3.49821 12.8149 5.14926L11.7784 4.80288L11.6272 4.75236L11.586 4.90633L11.4597 5.3778L11.4227 5.51574L11.5587 5.55943L13.9976 6.34324L14.1528 6.39312L14.1896 6.23431L14.7764 3.7036L14.8091 3.56236L14.6691 3.52483L14.1976 3.3985L14.0465 3.35802L14.0124 3.51066L13.7949 4.48334C12.605 2.52157 10.4487 1.20937 7.98483 1.20937C4.46336 1.20937 1.5686 3.88947 1.22785 7.32156L1.18423 7.76088ZM14.8161 8.23872L14.8309 8.08945L14.6816 8.07463L13.803 7.9874L13.6537 7.97258L13.6389 8.12184L13.5953 8.56117C13.314 11.3942 10.9228 13.6073 8.01549 13.6073C5.96902 13.6073 4.17763 12.511 3.19819 10.8719L4.24366 11.2212L4.39484 11.2717L4.4361 11.1178L4.56242 10.6463L4.59938 10.5084L4.46342 10.4647L2.02458 9.68093L1.86938 9.63105L1.83257 9.78986L1.24585 12.3204L1.2131 12.4617L1.35315 12.4992L1.82459 12.6255L1.97568 12.666L2.0098 12.5134L2.22542 11.549C3.41933 13.4926 5.56538 14.7902 8.01549 14.7902C11.5369 14.7902 14.4317 12.1101 14.7725 8.67804L14.8161 8.23872Z"
      strokeWidth="0.3"
    />
  </svg>
);

SpinnerIcon.propTypes = {
  className: PropTypes.string,
};

SpinnerIcon.defaultProps = {
  className: '',
};

export default SpinnerIcon;

import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import styles from './Selector.module.scss';
import PlainButton from '../PlainButton/PlainButton';
import ChevronIcon from '../Icons/ChevronIcon';
import { useDetectOutsideClick } from '../../utils/CustomHooks';

export const OpenSelector = ({ options, selected, onValueChange, emptyValue, isOpen }) => {
  const [menu, setMenu] = useState(false);
  const ref = useRef(undefined);
  useDetectOutsideClick(ref, () => setMenu(false));
  return (
    <div className="flex items-center flex-1 mr-2 w-2/5" ref={ref}>
      <div className="relative flex-1">
        <PlainButton
          className={`${styles.openButton} ${
            isOpen ? 'border-quaternary bg-secondaryLight' : 'border-mpGrey'
          }`}
          onClick={() => setMenu(!menu)}
        >
          <p className={isOpen ? 'opacity-80 font-semibold' : 'opacity-60'}>{selected}</p>
          <ChevronIcon className={`h-6 w-6 shrink-0 ${menu ? '-rotate-90' : 'rotate-90'}`} />
        </PlainButton>

        {menu && (
          <ul className={styles.openOptions}>
            {options.map(
              option =>
                option !== emptyValue && (
                  <PlainButton
                    key={option.key}
                    id={option.key}
                    className={`${styles.openItem} ${
                      option.value === selected ? 'bg-lightGrey' : ''
                    }`}
                    onClick={() => {
                      onValueChange(option.value);
                      setMenu(!menu);
                    }}
                    disabled={option.value === selected}
                  >
                    {option.value}
                  </PlainButton>
                ),
            )}
          </ul>
        )}
      </div>
    </div>
  );
};

const keyValue = PropTypes.shape({
  key: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
});

OpenSelector.propTypes = {
  options: PropTypes.arrayOf(keyValue).isRequired,
  selected: PropTypes.oneOfType([keyValue, PropTypes.string]),
  emptyValue: keyValue.isRequired,
  onValueChange: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
};

OpenSelector.defaultProps = {
  selected: null,
  isOpen: false,
};

export const TimeInput = ({ value, onChange }) => (
  <input
    type="time"
    className={styles.timeInput}
    value={value}
    onChange={e => onChange(e.target.value)}
  />
);

TimeInput.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

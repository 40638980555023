import React, { useEffect, useState } from 'react';
import i18n from 'i18next';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { isAfter, isBefore, parseISO } from 'date-fns';
import LoadingAnimation from '../../components/LoadingAnimation/LoadingAnimation';
import PageHeader from '../../components/PageHeader/PageHeader';
import SpinnerButton from '../../components/SpinnerButton/SpinnerButton';
import Button from '../../components/Button/Button';
import { fetchOffers, stopOfferThunk, fetchMoreOffers } from '../../store/offers/thunks';
import {
  getIsFetchingOffers,
  getOffersSelector,
  getTotalOffers,
  getIsLoadingMoreOffers,
} from '../../store/offers/selectors';
import { getVenue, getIsFetching } from '../../store/venue/selectors';
import './styles.scss';
import { venueProps, offerProps, historyProps } from '../../utils/customPropTypes';
import routes from '../../routes/paths';
import { fireEvent, GACategories, GATags } from '../../utils/trackingHelper';
import PlainButton from '../../components/PlainButton/PlainButton';

const limit = 10;
const OfferPage = ({
  venue,
  getOffers,
  isFetching,
  getMoreOffers,
  totalOffers,
  loadingMoreOffers,
  offers,
  history,
  stopOffer,
  isFetchingVenue,
  title,
}) => {
  useEffect(() => {
    getOffers(venue.id, limit);
    fireEvent(GACategories.OFFER, GATags.VIEW_PAGE);
  }, [getOffers, venue.id]);
  const [offset, setOffset] = useState(0);

  const isActive = offer =>
    isBefore(parseISO(offer?.fromDateUtc), new Date()) &&
    (offer?.toDateUtc === null || isAfter(parseISO(offer?.toDateUtc), new Date()));

  const isInactive = offer =>
    offer?.toDateUtc !== null && isBefore(parseISO(offer?.toDateUtc), new Date());

  const isFuture = offer => isAfter(parseISO(offer?.fromDateUtc), new Date());

  const activeOffers = offers.filter(offer => isActive(offer));
  const inactiveOffers = offers.filter(offer => isInactive(offer));
  const futureOffers = offers.filter(offer => isFuture(offer));

  const offerRow = (offer, edit, redemptions, stop, restart) => (
    <div key={offer.id} className="OfferPage_bottomContainer_offer_container">
      <p>{offer.name}</p>
      <div className="OfferPage_bottomContainer_offer_buttons">
        {edit && offer.venueId && (
          <Button className="bg-lightGrey" onClick={() => history.push(`/offers/${offer.id}/edit`)}>
            {i18n.t('general.edit')}
          </Button>
        )}
        {stop && offer.venueId && (
          <Button className="bg-lightGrey" onClick={() => stopOffer(venue.id, offer.id)}>
            {i18n.t('offers.stop')}
          </Button>
        )}
        {redemptions && <p className="">{offer.nbRedemptionsInVenue}</p>}
        {restart && offer.venueId && (
          <Button className="bg-lightGrey" onClick={() => history.push(`/offers/${offer.id}/edit`)}>
            {i18n.t('offers.restart')}
          </Button>
        )}
      </div>
    </div>
  );

  if (isFetchingVenue) {
    return <LoadingAnimation />;
  }

  return (
    <>
      <PageHeader title={title} />
      <div className="OfferPage GlobalPad">
        <PlainButton
          className="w-full"
          onClick={() => {
            fireEvent(GACategories.OFFER, GATags.CREATE_OWN_OFFER);
            history.push(routes.offers.subRoutes.createOffer.path);
          }}
        >
          <div className="OfferPage_create">
            <p className="text-xl">{i18n.t('offers.main.create')}</p>
            <div className="OfferPage_create_plus">
              <span>+</span>
            </div>
          </div>
        </PlainButton>
        {isFetching ? (
          <LoadingAnimation />
        ) : (
          <div className="OfferPage_bottomContainer">
            <div className="OfferPage_bottomContainer_offer">
              <p className="OfferPage_bottomContainer_offer_title">
                {i18n.t('offers.main.upcoming')}
              </p>
              {futureOffers.length > 0 ? (
                futureOffers.map(offer => offerRow(offer, true))
              ) : (
                <p>{i18n.t('general.none')}</p>
              )}
            </div>
            <div className="OfferPage_bottomContainer_offer_pad" />
            <div className="OfferPage_bottomContainer_offer">
              <p className="OfferPage_bottomContainer_offer_title">
                {i18n.t('offers.main.active')}
              </p>
              {activeOffers.length > 0 ? (
                <>
                  <p className="text-right">{i18n.t('offers.redemptions')}</p>
                  {activeOffers.map(offer => offerRow(offer, true, true, true))}
                </>
              ) : (
                <p className="">{i18n.t('general.none')}</p>
              )}
            </div>
            <div className="OfferPage_bottomContainer_offer_pad" />
            <div className="OfferPage_bottomContainer_offer">
              <p className="OfferPage_bottomContainer_offer_title">
                {i18n.t('offers.main.inactive')}
              </p>
              {inactiveOffers.length > 0 ? (
                <>
                  <p className="text-right">{i18n.t('offers.redemptions')}</p>
                  {inactiveOffers.map(offer => offerRow(offer, false, true, false, false))}
                </>
              ) : (
                <p className="">{i18n.t('general.none')}</p>
              )}
            </div>
          </div>
        )}
        {offset + limit < totalOffers && (
          <div className="OfferPage_bottomContainer_loadMore">
            <SpinnerButton
              className="bg-secondary rounded py-1 px-4 uppercase font-semibold"
              loading={loadingMoreOffers}
              onClick={() => {
                getMoreOffers(venue.id, offset + limit, limit);
                setOffset(offset + limit);
              }}
            >
              {i18n.t('general.load_more')}
            </SpinnerButton>
          </div>
        )}
      </div>
    </>
  );
};

OfferPage.propTypes = {
  venue: venueProps.isRequired,
  getOffers: PropTypes.func.isRequired,
  getMoreOffers: PropTypes.func.isRequired,
  totalOffers: PropTypes.number,
  loadingMoreOffers: PropTypes.bool.isRequired,
  stopOffer: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
  offers: PropTypes.arrayOf(offerProps).isRequired,
  history: historyProps.isRequired,
  isFetchingVenue: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
};

OfferPage.defaultProps = {
  totalOffers: null,
};

const mapDispatchToProps = dispatch => ({
  getOffers: (venueID, lim) => dispatch(fetchOffers(venueID, lim)),
  getMoreOffers: (venueID, offset, lim) => dispatch(fetchMoreOffers(venueID, offset, lim)),
  stopOffer: (venueID, offerID) => dispatch(stopOfferThunk(venueID, offerID)),
});

const mapStateToProps = state => ({
  venue: getVenue(state),
  isFetching: getIsFetchingOffers(state),
  offers: getOffersSelector(state),
  totalOffers: getTotalOffers(state),
  loadingMoreOffers: getIsLoadingMoreOffers(state),
  isFetchingVenue: getIsFetching(state),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(OfferPage));

import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import './styles.scss';
import { connect } from 'react-redux';
import SideBar from './SideBar/SideBar';
import MobileSideBar from './SideBar/MobileSideBar';
import { getIsFetching } from '../../store/venue/selectors';
import LoadingAnimation from '../LoadingAnimation/LoadingAnimation';
import { useDetectOutsideClick } from '../../utils/CustomHooks';

const styles = {
  drawer:
    'Drawer fixed h-full bg-brandedGrey-1 transition-all ease-in-out duration-300 transform z-50 lg:translate-x-0',
};

const Drawer = ({ open, onClose, isFetchingVenue }) => {
  const ref = useRef();
  useDetectOutsideClick(ref, onClose, open);

  // Background shouldn't be scrollable
  useEffect(() => {
    if (open) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [open]);

  return (
    <>
      <div className={`${styles.drawer} ${open ? '' : '-translate-x-full'}`} ref={ref}>
        {isFetchingVenue ? (
          <div className="flex justify-center h-full">
            <LoadingAnimation size={100} />
          </div>
        ) : (
          <>
            <SideBar />
            <MobileSideBar closeFunction={onClose} />
          </>
        )}
      </div>
      {open && <div className="fixed z-40 w-screen h-screen bg-black opacity-50 lg:hidden" />}
      <div className="Drawer hidden lg:flex" />
    </>
  );
};

Drawer.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  isFetchingVenue: PropTypes.bool.isRequired,
};

Drawer.defaultProps = {
  open: false,
  onClose: () => {},
};

const mapStateToProps = state => ({
  isFetchingVenue: getIsFetching(state),
});

export default connect(mapStateToProps)(Drawer);

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18next';
import { connect } from 'react-redux';
import queryString from 'query-string';
import UnprotectedLayout from '../UnprotectedLayout/UnprotectedLayout';
import TermsAndConditionsSnippet from '../../components/TermsAndConditionsSnippet/TermsAndConditionsSnippet';
import SpinnerButton from '../../components/SpinnerButton/SpinnerButton';
import BorderedInput from '../../components/BorderedInput/BorderedInput';
import { preActivationThunk, activationThunk } from '../../store/auth/thunks';
import LoadingAnimation from '../../components/LoadingAnimation/LoadingAnimation';
import { historyProps } from '../../utils/customPropTypes';
import { publicRoutes } from '../../routes/paths';
import { emailErrorCheck } from '../../utils/FormValidation';
import { getIsDesktop, getIsMobile } from '../../store/app/selectors';
import ActivationInfo from './ActivationInfo';

const styles = {
  input: 'text-base px-4 py-3 rounded-lg',
};

const ActivationPage = ({ preActivation, history, activation, isDesktop, isMobile }) => {
  const [fieldErrors, setFieldErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [venue, setVenue] = useState(null);
  const [venueMember, setVenueMember] = useState({
    firstName: '',
    lastName: '',
    email: '',
    postcode: '',
  });
  const [state, updateState] = useState({
    password: '',
    passwordConfirm: '',
    acceptedMarketing: true,
    activationKey: queryString.parse(history.location.search).activationKey,
  });

  const passwordsAreOk = Boolean(
    state.password && state.passwordConfirm && state.password === state.passwordConfirm,
  );

  const setState = val => {
    setFieldErrors({});
    updateState(val);
  };

  useEffect(() => {
    const params = queryString.parse(history.location.search);
    preActivation(params.activationKey)
      .then(res => setVenue(res))
      .catch(() => history.push(publicRoutes.landing));
  }, [history, history.location.search, preActivation]);

  if (!venue) {
    return <LoadingAnimation />;
  }

  const getFieldError = fieldName => fieldErrors?.[fieldName];

  const mailToBody = t('activation_mailToBody', { id: venue?.id });
  const mailToSubject = t('activation_mailToSubject', { id: venue?.id });

  const disabled = !passwordsAreOk || loading;

  return (
    <UnprotectedLayout noPad={isMobile}>
      <div className="text-left bg-white border border-mpGrey rounded-lg flex-col flex max-w-6xl mx-4 my-7 sm:mx-6 sm:my-14 md:mx-0 px-4 py-10 sm:px-6 sm:py-14 lg:p-12 xl:p-16">
        <h1 className="headingFont text-4xl sm:text-6xl lg:max-w-2xl">
          {t('activation_title', { venueName: venue?.name })}
        </h1>
        <h2 className="py-6 text-xl lg:max-w-2xl">{t('activation_subtitle')}</h2>

        <div className="flex">
          <div className="lg:pr-8 lg:border-r lg:border-lightGrey lg:max-w-md">
            <h3 className="opacity-60">{t('activation_activeProfile1')}</h3>
            <h3 className="pb-4 opacity-60">{t('activation_activeProfile2')}</h3>
            {!venue?.hasVenueMembers && (
              <>
                <p className="font-semibold">{t('activation_fullName')}</p>
                <BorderedInput
                  className={styles.input}
                  autoComplete="given-name"
                  showError={Boolean(getFieldError('firstName'))}
                  errorMessage={getFieldError('firstName')}
                  value={venueMember.firstName}
                  placeholder={t('auth.signup.form.first_name')}
                  onChange={e => setVenueMember({ ...venueMember, firstName: e })}
                />
                <BorderedInput
                  className={`mb-4 ${styles.input}`}
                  autoComplete="family-name"
                  showError={Boolean(getFieldError('lastName'))}
                  errorMessage={getFieldError('lastName')}
                  value={venueMember.lastName}
                  placeholder={t('auth.signup.form.last_name')}
                  onChange={e => setVenueMember({ ...venueMember, lastName: e })}
                />
                <p className="font-semibold">{t('auth.signup.form.postcode')}</p>
                <BorderedInput
                  className={`mb-4 ${styles.input}`}
                  showError={Boolean(getFieldError('postcode'))}
                  errorMessage={getFieldError('postcode')}
                  value={venueMember.postcode}
                  autoComplete="shipping postal-code"
                  placeholder={t('editProfile.general.postCode')}
                  onChange={e => setVenueMember({ ...venueMember, postcode: e })}
                />
                <p className="font-semibold">{t('auth.email')}</p>
                <BorderedInput
                  className={`mb-4 ${styles.input}`}
                  errorMessage={
                    !emailErrorCheck(venueMember?.email) || Boolean(getFieldError('email'))
                  }
                  value={venueMember.email}
                  autoComplete="email"
                  showError={
                    (venueMember?.email && !emailErrorCheck(venueMember?.email)) ||
                    Boolean(getFieldError('email'))
                  }
                  placeholder={t('auth.email')}
                  type="email"
                  onChange={e => setVenueMember({ ...venueMember, email: e })}
                />
              </>
            )}
            <p className="font-semibold">{t('activation_password')}</p>
            <BorderedInput
              className={styles.input}
              value={state.password}
              autoComplete="new-password"
              type="password"
              placeholder={t('auth.password')}
              onChange={password => setState({ ...state, password })}
              showError={Boolean(getFieldError('password'))}
            />
            <BorderedInput
              className={styles.input}
              value={state.passwordConfirm}
              autoComplete="new-password"
              type="password"
              placeholder={t('auth.password_confirm')}
              onChange={e => setState({ ...state, passwordConfirm: e })}
              showError={Boolean(getFieldError('passwordConfirm'))}
            />
            <p className="text-primaryRed">
              {state.password !== state.passwordConfirm && t('settings.passwordError')}
              {getFieldError('password')}
            </p>

            {!isDesktop && (
              <div className="py-6 my-6 border-t border-b border-lightGrey">
                <ActivationInfo />
              </div>
            )}

            <TermsAndConditionsSnippet buttonText={t('general.submit')} />
            <SpinnerButton
              className={`capitalize  text-primaryButtonText rounded-lg py-3 w-full ${
                disabled ? 'bg-mpGrey text-white' : 'bg-tertiary'
              }`}
              onClick={() => {
                setLoading(true);
                activation(venue?.hasVenueMembers ? state : { ...state, ...venueMember })
                  .catch(err => {
                    if (err?.error) {
                      setFieldErrors(err.error);
                    }
                  })
                  .finally(() => setLoading(false));
              }}
              disabled={disabled}
              loading={loading}
            >
              {t('general.submit')}
            </SpinnerButton>
            <p className="mt-4 text-standardGrey">
              {t('activation_remove')}{' '}
              <a
                className="underline"
                href={`mailto:contact@fanzo.com?subject=${mailToSubject}&body=${mailToBody}`}
              >
                {t('activation_remove_here')}
              </a>
              .
            </p>
          </div>

          {isDesktop && (
            <div className="pl-8">
              <ActivationInfo />
            </div>
          )}
        </div>
      </div>
    </UnprotectedLayout>
  );
};

ActivationPage.propTypes = {
  preActivation: PropTypes.func.isRequired,
  history: historyProps.isRequired,
  activation: PropTypes.func.isRequired,
  isDesktop: PropTypes.bool.isRequired,
  isMobile: PropTypes.bool.isRequired,
};

const mstp = state => ({
  isDesktop: getIsDesktop(state),
  isMobile: getIsMobile(state),
});

const mdtp = {
  preActivation: preActivationThunk,
  activation: activationThunk,
};

export default connect(mstp, mdtp)(ActivationPage);

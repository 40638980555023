import React from 'react';

const AllowsPet = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="black">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.0793 11.0544C4.94901 11.5061 5.24025 11.9036 5.70144 11.9036C6.12629 11.9036 6.52737 11.5639 6.59727 11.1448L6.80529 9.8977C6.86833 9.51982 6.60886 9.21348 6.22575 9.21348C5.87461 9.21348 5.53568 9.47229 5.43648 9.81618L5.0793 11.0544ZM8.30529 9.8977L8.09727 11.1448C7.89107 12.381 6.70792 13.3831 5.45464 13.3831C4.09416 13.3831 3.23505 12.2105 3.61939 10.8781L3.97657 9.6399C4.29027 8.55239 5.3621 7.73392 6.47255 7.73392C7.68408 7.73392 8.50463 8.70268 8.30529 9.8977Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.52569 7.28825C9.3954 7.73992 9.68664 8.13744 10.1478 8.13744C10.5727 8.13744 10.9738 7.79772 11.0437 7.37865L11.2517 6.13156C11.3147 5.75367 11.0552 5.44733 10.6721 5.44733C10.321 5.44733 9.98207 5.70615 9.88287 6.05003L9.52569 7.28825ZM12.7517 6.13156L12.5437 7.37865C12.3375 8.61486 11.1543 9.61699 9.90103 9.61699C8.54056 9.61699 7.68144 8.44434 8.06578 7.11197L8.42296 5.87375C8.73666 4.78624 9.8085 3.96777 10.9189 3.96777C12.1305 3.96777 12.951 4.93653 12.7517 6.13156Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.3164 7.37865C14.2465 7.79772 14.5342 8.13744 14.9591 8.13744C15.4203 8.13744 15.8441 7.73992 15.8645 7.28825L15.9204 6.05003C15.936 5.70615 15.6834 5.44733 15.3322 5.44733C14.9491 5.44733 14.5874 5.75367 14.5244 6.13156L14.3164 7.37865ZM17.4391 5.87375L17.3832 7.11197C17.3231 8.44434 16.0728 9.61699 14.7123 9.61699C13.459 9.61699 12.6102 8.61486 12.8164 7.37865L13.0244 6.13156C13.2237 4.93653 14.3675 3.96777 15.579 3.96777C16.6895 3.96777 17.4882 4.78625 17.4391 5.87375Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.5064 11.1448C17.4365 11.5639 17.7242 11.9036 18.1491 11.9036C18.6103 11.9036 19.0341 11.5061 19.0545 11.0544L19.1104 9.81618C19.1259 9.47229 18.8733 9.21348 18.5222 9.21348C18.1391 9.21348 17.7774 9.51982 17.7144 9.8977L17.5064 11.1448ZM20.6291 9.6399L20.5732 10.8781C20.5131 12.2105 19.2627 13.3831 17.9023 13.3831C16.649 13.3831 15.8002 12.381 16.0064 11.1448L16.2144 9.8977C16.4137 8.70268 17.5575 7.73392 18.769 7.73392C19.8794 7.73392 20.6782 8.55239 20.6291 9.6399Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.1673 18.0908C13.843 18.0908 15.176 16.2838 14.5428 14.8705L13.5172 12.5815C13.2538 11.9936 12.6673 11.6346 11.9702 11.6346C11.2732 11.6346 10.5668 11.9936 10.1073 12.5815L8.31808 14.8705C7.21337 16.2837 7.9435 18.0908 9.61921 18.0908H12.1673ZM16.0816 14.0336C17.1967 16.5225 14.8492 19.7049 11.8981 19.7049H9.34998C6.39885 19.7049 5.11303 16.5224 7.05854 14.0335L8.84776 11.7445C9.68438 10.6742 10.9704 10.0205 12.2394 10.0205C13.5085 10.0205 14.5765 10.6742 15.056 11.7445L16.0816 14.0336Z"
    />
  </svg>
);

export default AllowsPet;

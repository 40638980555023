import React from 'react';
import PropTypes from 'prop-types';

const MegaPhoneIcon = ({ className, isActive }) => (
  <svg className={`fill-current ${className}`} viewBox="0 0 24 24" fill="none">
    {isActive ? (
      <>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.29927 2.66382L2.8287 18.2624L7.40664 16.5306C9.39932 15.7768 11.4643 15.3892 13.4871 15.3892H16.3212C19.0757 15.3892 21.658 13.1837 22.0889 10.4631C22.5198 7.74249 20.6362 5.53701 17.8816 5.53701H15.0475C13.0247 5.53701 11.0825 5.14937 9.32864 4.39557L5.29927 2.66382ZM7.21764 6.33608L8.02322 6.6823C10.0575 7.55661 12.3102 8.00623 14.6564 8.00623H17.4906C18.8644 8.00623 19.8038 9.1062 19.5889 10.4631C19.374 11.82 18.0861 12.9199 16.7123 12.9199H13.8782C11.532 12.9199 9.13685 13.3696 6.82558 14.2439L5.91033 14.5901L7.21764 6.33608Z"
        />
        <path d="M16.8043 14.1546H13.9293L12.9516 20.3276H14.8265C15.3788 20.3276 15.8966 19.8854 15.983 19.3399L16.8043 14.1546Z" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.3825 13.6607H13.5075L12.3733 20.8215H14.7483C15.5768 20.8215 16.3534 20.1581 16.483 19.3399L17.3825 13.6607ZM16.226 14.6484L15.483 19.3399C15.4398 19.6127 15.1809 19.8338 14.9048 19.8338H13.5298L14.3511 14.6484H16.226Z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.4442 13.5866L14.4337 7.33954H15.9337L14.9442 13.5866H13.4442Z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.40618 8.30253L5.63183 13.1916H4.88183C4.55681 13.1916 4.24499 13.1283 3.96417 13.0055C3.68335 12.8826 3.43902 12.7026 3.24515 12.4756C3.05128 12.2486 2.91165 11.9791 2.83425 11.6825C2.75684 11.3859 2.74317 11.0681 2.79402 10.747C2.84486 10.426 2.95923 10.1082 3.13058 9.81157C3.30193 9.51499 3.52692 9.24551 3.7927 9.01851C4.05848 8.79152 4.35984 8.61145 4.67957 8.48861C4.99931 8.36576 5.33116 8.30253 5.65618 8.30253H6.40618ZM4.61647 10.1317C4.54231 10.2059 4.47827 10.2891 4.4266 10.3785C4.3591 10.4954 4.31405 10.6206 4.29402 10.747C4.27399 10.8735 4.27937 10.9987 4.30987 11.1156C4.33321 11.205 4.3709 11.2882 4.42155 11.3624L4.61647 10.1317Z"
        />
      </>
    ) : (
      <>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.68294 3.39844L3.44502 17.5281L7.29043 16.0734C9.34682 15.2955 11.4778 14.8955 13.5653 14.8955H16.3994C18.8778 14.8955 21.2012 12.9111 21.5889 10.4633C21.9766 8.0154 20.2818 6.03102 17.8034 6.03102H14.9693C12.8818 6.03102 10.8775 5.63099 9.06756 4.85309L5.68294 3.39844ZM6.83396 5.60179L8.2843 6.22512C10.2625 7.07533 12.4531 7.51255 14.7346 7.51255H17.5688C19.2187 7.51255 20.347 8.83363 20.0889 10.4633C19.8308 12.0929 18.284 13.414 16.6341 13.414H13.7999C11.5184 13.414 9.18934 13.8512 6.94179 14.7014L5.294 15.3247L6.83396 5.60179Z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M17.6716 13.414H13.2966L12.1625 20.5747H14.7874C15.7539 20.5747 16.66 19.8008 16.8112 18.8462L17.6716 13.414ZM15.9369 14.8955L15.3112 18.8462C15.2896 18.9826 15.1602 19.0931 15.0221 19.0931H13.8971L14.5619 14.8955H15.9369Z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.4442 13.5868L14.4337 7.33971H15.4337L14.4442 13.5868H13.4442Z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.40618 8.30269L5.63183 13.1917H4.88183C4.55681 13.1917 4.24499 13.1285 3.96417 13.0057C3.68335 12.8828 3.43902 12.7027 3.24515 12.4758C3.05128 12.2488 2.91165 11.9793 2.83425 11.6827C2.75684 11.3861 2.74317 11.0682 2.79402 10.7472C2.84486 10.4262 2.95923 10.1083 3.13058 9.81174C3.30193 9.51515 3.52692 9.24567 3.7927 9.01868C4.05848 8.79168 4.35984 8.61162 4.67957 8.48877C4.99931 8.36592 5.33116 8.30269 5.65618 8.30269H6.40618ZM4.61647 10.1319C4.54231 10.2061 4.47827 10.2893 4.4266 10.3787C4.3591 10.4955 4.31405 10.6208 4.29402 10.7472C4.27399 10.8737 4.27937 10.9989 4.30987 11.1157C4.33321 11.2052 4.3709 11.2883 4.42155 11.3625L4.61647 10.1319Z"
        />
      </>
    )}
  </svg>
);

MegaPhoneIcon.propTypes = {
  className: PropTypes.string,
  isActive: PropTypes.bool,
};

MegaPhoneIcon.defaultProps = {
  className: '',
  isActive: false,
};
export default MegaPhoneIcon;

export function getIsLoggedIn(state) {
  return state.auth.isLoggedIn;
}

export function getLoginIsFetching(state) {
  return state.auth.isFetchingLogin;
}

export function getUser(state) {
  return state.auth.user;
}

export const getFirstName = state => state.auth.user.firstName;

export const getErrors = state => state.auth.error;

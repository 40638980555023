import React from 'react';
import PropTypes from 'prop-types';
import i18n from '../../../i18n';

const s = {
  button: 'flex w-full mb-2 py-2 px-5 lg:px-2 xl:px-5 justify-between shadow rounded-md border',
  name: 'truncate pr-10 lg:pr-2 xl:pr-10',
  setTips: 'underline truncate',
};

const MemberButton = ({ member, isActive, onClick, disabled }) => (
  <button
    className={`${s.button} ${isActive ? 'border-primary' : 'border-transparent'}`}
    onClick={onClick}
    type="button"
    disabled={disabled}
  >
    <p className={s.name}>
      {member.firstName} {member.lastName}
    </p>
    <p className={`${s.setTips} ${isActive ? 'invisible' : ''}`}>{i18n.t('tipping.setTips')}</p>
  </button>
);

MemberButton.propTypes = {
  member: PropTypes.shape().isRequired,
  isActive: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

MemberButton.defaultProps = {
  isActive: false,
  disabled: false,
};

export default MemberButton;

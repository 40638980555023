import { request } from './request';

export function getOffers(venueID, offset, limit) {
  return async function _getOffers(dispatch) {
    return dispatch(
      request(`offers/${venueID}?&numberOfMatches=1&offset=${offset}&limit=${limit}`),
    );
  };
}

export function getOffer(venueID, offerID) {
  return async function _getOffer(dispatch) {
    return dispatch(request(`offers/${venueID}/${offerID}`));
  };
}

export function stopOffer(venueID, offerID) {
  return async function _stopOffer(dispatch) {
    return dispatch(
      request(`offers/${venueID}/${offerID}/stop`, {
        method: 'PUT',
      }),
    );
  };
}

export function createOffer(venueID, offer) {
  return async function _createOffer(dispatch) {
    return dispatch(
      request(`offers/${venueID}/add`, {
        method: 'POST',
        body: { offer },
      }),
    );
  };
}

export function updateOffer(venueID, offer, offerID) {
  return async function _updateOffer(dispatch) {
    return dispatch(
      request(`offers/${venueID}/${offerID}/update`, {
        method: 'PUT',
        body: { offer },
      }),
    );
  };
}

export function getCompetitions(venueID) {
  return async function _getCompetitions(dispatch) {
    return dispatch(request(`sports/${venueID}/competitions/for-offers`));
  };
}

import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { t } from 'i18next';
import styles from './ProfileHeader.module.scss';
import BackIcon from '../../components/Icons/BackIcon';

const ProfileHeader = ({ text }) => {
  const history = useHistory();
  return (
    <div className={styles.profileHeader}>
      <button type="button" onClick={history.goBack} className="flex items-center md:flex-1">
        <BackIcon className="w-6 h-6 md:w-8 md:h-8" />
        <span className="hidden md:block font-bold text-xl ml-2">{t('general_back')}</span>
      </button>

      <h1 className="headingFont flex-1 text-2xl md:text-4xl leading-none md:text-center">
        {text}
      </h1>

      <div className="hidden md:flex flex-1" />
    </div>
  );
};

ProfileHeader.propTypes = {
  text: PropTypes.string.isRequired,
};

export default ProfileHeader;

import * as consts from './constants';

export const initialState = {
  isUpdatingMember: false,
  fieldsErrors: {},
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case consts.UPDATE_MEMBER_PENDING:
      return {
        ...state,
        fieldsErrors: {},
        isUpdatingMember: true,
      };
    case consts.UPDATE_MEMBER_SUCCESS:
      return {
        ...state,
        isUpdatingMember: false,
      };
    case consts.UPDATE_MEMBER_ERROR:
      return {
        ...state,
        fieldsErrors: action.payload,
        isUpdatingMember: false,
      };
    case consts.TOGGLE_AUTH_MODAL: {
      return {
        ...state,
        showAuthModal: !state.showAuthModal,
      };
    }
    default:
      return state;
  }
};

export default reducer;

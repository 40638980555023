import React from 'react';
import PropTypes from 'prop-types';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import i18next from 'i18next';
import colors from '../../theme/index.module.scss';
import ChartScroll from './ChartScroll';
import { useChartScroll } from './hook';

const colourArray = [
  colors.lightRed,
  colors.lightGrey,
  colors.secondaryVeryDarkGrey,
  colors.primaryRed,
  colors.standardGrey,
];
const StackedBarChart = ({ data, height, isMobile }) => {
  const { lessProps, moreProps, filteredData, show } = useChartScroll({
    data,
    isMobile,
  });

  const uniqueKeys = Object.keys(Object.assign({}, ...filteredData));

  return (
    <>
      <ChartScroll lessProps={lessProps} moreProps={moreProps} show={show} />

      <div style={{ height, width: '100%' }}>
        <ResponsiveContainer>
          <BarChart
            margin={{ right: isMobile ? 0 : 24, left: isMobile ? 0 : 24 }}
            data={filteredData}
          >
            <CartesianGrid vertical={false} />
            <XAxis dataKey="name" />
            <YAxis
              tickLine={false}
              label={{
                value: i18next.t('stats_barChart_offersLegend'),
                angle: -90,
                position: 'insideLeft',
                offset: 15,
              }}
              axisLine={false}
            />
            <Tooltip />
            {uniqueKeys &&
              uniqueKeys
                .filter(elm => elm !== 'name')
                .map((key, i) => (
                  <Bar
                    key={key}
                    name={key}
                    dataKey={key}
                    stackId="a"
                    fill={colourArray[i % colourArray.length]}
                  />
                ))}
          </BarChart>
        </ResponsiveContainer>
      </div>
    </>
  );
};

StackedBarChart.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape().isRequired).isRequired,
  height: PropTypes.number.isRequired,
  isMobile: PropTypes.bool.isRequired,
};

export default StackedBarChart;

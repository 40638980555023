import { useSelector } from 'react-redux';
import { useCallback, useEffect, useReducer, useState } from 'react';
import reducer, { ACTIONS, initialState } from './reducer';
import filterReducer, { filterActions, filterInitial, filterTypes } from './filtersReducer';
import { getVenueFixturePreferences } from '../../store/venue/selectors';

export const textStates = {
  added: 'general.added',
  add: 'general.add',
};

export const usePaginatedRequest = (
  func,
  limit = 10,
  params = {},
  paramsToRerender = [],
  condition = true,
) => {
  const [
    { data, loading, offset, loadingPage, hasMorePages, error, changes },
    dispatch,
  ] = useReducer(reducer, initialState);

  const defaultPrefs = useSelector(getVenueFixturePreferences);

  const fetch = useCallback(() => {
    if (condition) {
      dispatch({ type: ACTIONS.fetch });
      func({
        limit,
        offset: 0,
        ...params,
      })
        .then(({ data: { result, matches } }) =>
          dispatch({ type: ACTIONS.success, payload: { result, matches, limit } }),
        )
        .then(() => dispatch({ type: ACTIONS.showChangesAfterFetch }))
        .catch(() => dispatch({ type: ACTIONS.error }))
        .finally(() => dispatch({ type: ACTIONS.endFetch }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [condition, params, limit]);

  const fetchNextPage = useCallback(() => {
    if (condition && !loadingPage) {
      dispatch({ type: ACTIONS.fetchPage });
      func({
        limit,
        offset,
        ...params,
      })
        .then(({ data: { result, matches } }) =>
          dispatch({ type: ACTIONS.successPage, payload: { result, matches, limit } }),
        )
        .then(() => dispatch({ type: ACTIONS.showChangesAfterFetch }))
        .catch(() => dispatch({ type: ACTIONS.error }))
        .finally(() => dispatch({ type: ACTIONS.endFetch }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [condition, data, limit, offset, loadingPage]);

  useEffect(() => {
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, paramsToRerender);

  const updateFixture = payload =>
    dispatch({ type: ACTIONS.updateFixture, payload: { ...payload, defaultPrefs } });

  return [
    data,
    loading,
    hasMorePages,
    fetchNextPage,
    loadingPage,
    error,
    updateFixture,
    changes,
    () => dispatch({ type: ACTIONS.discard }),
    () => dispatch({ type: ACTIONS.saveSuccess }),
    updatedPrefs => dispatch({ type: ACTIONS.updateDefaultPrefs, payload: { updatedPrefs } }),
    fetch,
  ];
};

export const useFilters = (
  venueId,
  fetchFavouritesFilters,
  fetchSportsFilters,
  fetchCompsFilters,
  limit,
) => {
  const [data, dispatch] = useReducer(filterReducer, filterInitial);

  const fetchFavourites = () => {
    const type = filterTypes?.favourite;
    dispatch({ type: filterActions.fetchFilters, payload: { type } });
    fetchFavouritesFilters(venueId, limit)
      .then(res =>
        dispatch({
          type: filterActions.successFilters,
          payload: {
            type,
            result: res.getResult()?.map(el => ({ ...el, type })),
          },
        }),
      )
      .catch(() => dispatch({ type: filterActions.errorFilters, payload: { type } }))
      .finally(() => dispatch({ type: filterActions.endFetchFilters, payload: { type } }));
  };

  const fetchPaginated = useCallback(
    (type, offset = undefined) => {
      const fn =
        type !== filterTypes?.sport && type !== filterTypes?.favourite
          ? fetchCompsFilters
          : fetchSportsFilters;

      dispatch({
        type: offset ? filterActions?.fetchPageFilters : filterActions.fetchFilters,
        payload: { type },
      });
      fn({ venueId, offset: offset || 0, limit, sportId: type })
        .then(({ data: { result, matches } }) =>
          dispatch({
            type: offset ? filterActions.successPaginatedFilters : filterActions.successFilters,
            payload: {
              type,
              result: result?.map(el => ({ ...el, type })),
              matches,
              limit,
            },
          }),
        )
        .catch(() => dispatch({ type: filterActions.errorFilters, payload: { type } }))
        .finally(() => dispatch({ type: filterActions.endFetchFilters, payload: { type } }));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data, limit, venueId],
  );

  useEffect(() => {
    fetchFavourites();
    fetchPaginated(filterTypes?.sport);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [options, setOptions] = useState();

  const {
    activeFilters: { initialData: activeFilters },
    search,
    openHours: {
      initialData: { date: openHoursDate },
    },
    dates: {
      initialData: { fromDate, toDate },
    },
    popularFixtures,
    onlyFavourites,
  } = data;

  useEffect(() => {
    const newOptions = activeFilters?.reduce(
      (prev, f) => {
        if (f?.type === filterTypes?.sport)
          return { ...prev, sportIds: [...(prev?.sportIds || []), f?.id] };
        if (f?.type === filterTypes?.team)
          return { ...prev, teamIds: [...(prev?.teamIds || []), f?.id] };
        return { ...prev, competitionIds: [...(prev?.competitionIds || []), f?.id] };
      },
      { venueID: venueId },
    );

    if (search?.length > 0) newOptions.search = search;
    if (openHoursDate) newOptions.onlyDuringOpeningHoursOn = openHoursDate;
    if (fromDate?.length > 0) newOptions.fromDate = fromDate;
    if (toDate?.length > 0) newOptions.toDate = toDate;
    if (popularFixtures) newOptions.bigFixture = popularFixtures;
    if (onlyFavourites) newOptions.onlyFavourites = onlyFavourites;

    setOptions(newOptions);
  }, [
    activeFilters,
    search,
    openHoursDate,
    fromDate,
    toDate,
    popularFixtures,
    onlyFavourites,
    venueId,
  ]);

  return [data, fetchPaginated, dispatch, options];
};

import React from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18next';
import routes from '../../routes/paths';
import { historyProps } from '../../utils/customPropTypes';
import { fireEvent, GACategories, GATags } from '../../utils/trackingHelper';
import PlainButton from '../../components/PlainButton/PlainButton';
import ChevronIcon from '../../components/Icons/ChevronIcon';
import { Header } from './common';
import { cdnUrl } from '../../utils/constants';

const CDN = `${cdnUrl}/integration`;

const WidgetSelectorPage = ({ history, title }) => (
  <div className="bg-offWhite flex-1">
    <Header history={history} title={title} />
    <div className="px-4 md:px-6 lg:px-10 pt-8 lg:pt-10">
      <p className="text-base md:text-xl pb-4 md:pb-8 lg:whitespace-pre-line">
        {t('integrate_description')}
      </p>
      <div className="flex flex-col md:flex-row gap-4 md:gap-6 lg:gap-10">
        <Tile
          onClick={() => {
            fireEvent(GACategories.SOCIAL_MEDIA_CONTENT, GATags.USE_WIDGET);
            history.push(routes.marketing.subRoutes.integrate.subRoutes.widget.path);
          }}
          images={{ mobile: `${CDN}/mobile1.png`, laptop: `${CDN}/laptop1.png` }}
          text={{
            tab: t('integrate_widgetTab'),
            title: t('integrate_widgetTitle'),
            description: t('integrate_widgetDescription'),
          }}
          tabColor="bg-[#00C89C]"
        />
        <Tile
          onClick={() => {
            fireEvent(GACategories.SOCIAL_MEDIA_CONTENT, GATags.INTEGRATE_XML);
            history.push(routes.marketing.subRoutes.integrate.subRoutes.apiFeed.path);
          }}
          images={{ mobile: `${CDN}/mobile2.png`, laptop: `${CDN}/laptop2.png` }}
          text={{
            tab: t('integrate_XMLTab'),
            title: t('integrate_XMLTitle'),
            description: t('integrate_XMLDescription'),
          }}
          tabColor="bg-[#F26419]"
        />
      </div>
    </div>
  </div>
);

WidgetSelectorPage.propTypes = {
  history: historyProps.isRequired,
  title: PropTypes.string.isRequired,
};

export default WidgetSelectorPage;

const styles = {
  tileContainer:
    'relative text-left p-4 lg:p-8 lg:pt-12 border-2 border-mpGrey rounded-2xl flex-1 flex flex-col lg:max-w-md bg-white',
  tabText:
    'absolute top-0 right-0 rounded-tr-2xl rounded-bl-2xl py-2 px-4 text-white text-xs lg:text-base font-semibold',
};

const Tile = ({ onClick, images, text, tabColor }) => (
  <PlainButton onClick={onClick} className={styles.tileContainer}>
    <p className={`${styles.tabText} ${tabColor}`}>{text.tab}</p>
    <p className="font-bold opacity-80 pb-3 mr-28 lg:mr-0 leading-5">{text.title}</p>
    <div className="flex">
      <p className="pt-4 lg:pt-0 mr-4 lg:mr-0 opacity-80 leading-5">{text.description}</p>
      <img src={images.mobile} className="lg:hidden w-24 object-contain" alt="" />
    </div>
    <img
      src={images.laptop}
      className="hidden lg:flex w-64 py-8 object-contain mt-auto mx-auto"
      alt=""
    />
    <div className="flex justify-between items-center pt-4 w-full">
      <p className="font-semibold opacity-80">{t('integrate_tileCta')}</p>
      <ChevronIcon className="h-6 w-6" />
    </div>
  </PlainButton>
);

Tile.propTypes = {
  onClick: PropTypes.func.isRequired,
  images: PropTypes.shape({
    mobile: PropTypes.string.isRequired,
    laptop: PropTypes.string.isRequired,
  }).isRequired,
  text: PropTypes.shape({
    tab: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
  }).isRequired,
  tabColor: PropTypes.string.isRequired,
};

import * as consts from './constants';

export const fetchFormDescriptionPending = () => ({
  type: consts.FETCH_FINANCIALSFORM_DESCRIPTION_PENDING,
});

export const fetchFormDescriptionSuccess = formDesc => ({
  type: consts.FETCH_FINANCIALSFORM_DESCRIPTION_SUCCESS,
  payload: formDesc,
});

export const fetchFormDescriptionError = () => ({
  type: consts.FETCH_FINANCIALSFORM_DESCRIPTION_ERROR,
});

export const fetchFormValuesPending = () => ({
  type: consts.FETCH_FINANCIALSFORM_VALUES_PENDING,
});

export const fetchFormValuesSuccess = bankingAndMandateValues => ({
  type: consts.FETCH_FINANCIALSFORM_VALUES_SUCCESS,
  payload: bankingAndMandateValues,
});

export const fetchFormValuesError = () => ({
  type: consts.FETCH_FINANCIALSFORM_VALUES_ERROR,
});

export const updateFormValuesPending = () => ({
  type: consts.UPDATE_FINANCIALSFORM_VALUES_PENDING,
});

export const updateFormValuesSuccess = formValues => ({
  type: consts.UPDATE_FINANCIALSFORM_VALUES_SUCCESS,
  payload: formValues,
});

export const updateFormValuesError = errors => ({
  type: consts.UPDATE_FINANCIALSFORM_VALUES_ERROR,
  payload: errors,
});

export const updateFieldValue = (field, value) => ({
  type: consts.UPDATE_FINANCIALSFORM_VALUE,
  payload: { field, value },
});

export const discardFormChanges = () => ({
  type: consts.DISCARD_FINANCIALSFORM_CHANGES,
});

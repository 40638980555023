import { getWebsiteURL } from '../../../utils/environment';

const domain = getWebsiteURL();
// eslint-disable-next-line import/prefer-default-export
export const getTableData = (venue, t, selectedType) => {
  const td = {
    XML: [
      {
        ti: 'title',
        description: t('integrate.XML.table.d.title'),
        example: 'Hibernian vs Rangers',
      },
      {
        ti: 'description',
        description: t('integrate.XML.table.d.description'),
        example: 'Scottish Premiership',
      },
      {
        ti: 'sport',
        description: t('integrate.XML.table.d.sport'),
        example: 'Football',
      },
      {
        ti: 'team1',
        description: t('integrate.XML.table.d.team1'),
        example: `${domain}imagenes/teams/2018-08/6741-hibernian201819.png`,
      },
      {
        ti: 'team2',
        description: t('integrate.XML.table.d.team2'),
        example: `${domain}imagenes/teams/2018-07/7639-rangersfc2018.png`,
      },
      {
        ti: 'link',
        description: t('integrate.XML.table.d.link'),
        example: `${venue?.matchpintUrl}?fixtureId={fixtureId}`,
      },
      {
        ti: 'date',
        description: t('integrate.XML.table.d.date'),
        example: '2019-03-08 19:45:00',
      },
      {
        ti: 'bigScreen',
        description: t('integrate.XML.table.d.bigScreen'),
        example: '<bigScreen>true</bigScreen>',
      },
      {
        ti: 'sound',
        description: t('integrate.XML.table.d.sound'),
        example: '<sound>false</sound>',
      },
    ],
    JSON: [
      {
        ti: 'id',
        description: 'integer',
        example: t('integrate.json.id'),
      },
      {
        ti: 'eventName',
        description: 'string',
        example: t('integrate.json.eventName'),
      },
      {
        ti: 'startTimeUtc',
        description: 'string',
        example: t('integrate.json.startTimeUtc'),
      },
      {
        ti: 'endTimeUtc',
        description: 'string',
        example: t('integrate.json.endTimeUtc'),
      },
      {
        ti: 'startTimeLocal',
        description: 'string',
        example: t('integrate.json.startTimeLocal'),
      },
      {
        ti: 'endTimeLocal',
        description: 'string',
        example: t('integrate.json.endTimeLocal'),
      },
      {
        ti: 'venueEvent',
        description: 'object',
        example: t('integrate.json.venueEvent'),
      },
      {
        ti: '↳venueEvent[bigScreen]',
        description: 'boolean',
        example: t('integrate.json.venueEvent.bigScreen'),
      },
      {
        ti: '↳venueEvent[sound]',
        description: 'boolean',
        example: t('integrate.json.venueEvent.sound'),
      },
      {
        ti: 'sport',
        description: 'object',
        example: t('integrate.json.sport'),
      },
      {
        ti: '↳sport[id]',
        description: 'integer',
        example: t('integrate.json.sport.id'),
      },
      {
        ti: '↳sport[name]',
        description: 'string',
        example: t('integrate.json.sport.name'),
      },
      {
        ti: '↳sport[logo]',
        description: 'string',
        example: t('integrate.json.sport.logo'),
      },
      {
        ti: 'competition',
        description: 'object',
        example: t('integrate.json.competition'),
      },
      {
        ti: '↳competition[id]',
        description: 'integer',
        example: t('integrate.json.competition.id'),
      },
      {
        ti: '↳competition[name]',
        description: 'string',
        example: t('integrate.json.competition.name'),
      },
      {
        ti: '↳competition[logo]',
        description: 'string',
        example: t('integrate.json.competition.logo'),
      },
      {
        ti: 'teams',
        description: 'array',
        example: t('integrate.json.teams'),
      },
      {
        ti: '↳teams[id]',
        description: 'integer',
        example: t('integrate.json.teams.id'),
      },
      {
        ti: '↳teams[name]',
        description: 'string',
        example: t('integrate.json.teams.name'),
      },
      {
        ti: '↳teams[logo]',
        description: 'string',
        example: t('integrate.json.teams.logo'),
      },
      {
        ti: 'channel',
        description: 'object',
        example: t('integrate.json.channel'),
      },
      {
        ti: '↳channel[id]',
        description: 'integer',
        example: t('integrate.json.channel.id'),
      },
      {
        ti: '↳channel[name]',
        description: 'string',
        example: t('integrate.json.channel.name'),
      },
      {
        ti: '↳channel[logo]',
        description: 'string',
        example: t('integrate.json.channel.logo'),
      },
      {
        ti: 'link',
        description: 'string',
        example: t('integrate.json.link'),
      },
    ],
  };
  return td[selectedType];
};

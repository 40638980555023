import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { withRouter, NavLink, Link, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { t } from 'i18next';
import LegalsBar from './LegalsBar';
import { requestAutoLogin } from '../../store/auth/thunks';
import { historyProps } from '../../utils/customPropTypes';
import { publicRoutes } from '../../routes/paths';
import Logo from '../../components/Logo/Logo';
import styles from './UnprotectedLayout.module.scss';
import AppBar from '../../components/AppBar/AppBar';
import { userIsOnOnTap } from '../../utils/environment';

const UnprotectedLayout = ({ children, noPad, displayLegalsBar, history, autoLogin }) => {
  const { pathname } = useLocation();

  const isSignUp = pathname === publicRoutes.signupV2;

  useEffect(() => {
    const params = queryString.parse(history.location.search);
    if ((params.key && params.venueId) || params.loginKey) {
      // adding a key and venue id or a login key to any page that uses the unprotected layout will attempt to log the user in
      autoLogin(params);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getLinks = () => {
    if (userIsOnOnTap) {
      return null;
    }

    return (
      <>
        <NavLink to={publicRoutes.pricing} className={`${styles.link} border-2 border-white`}>
          {t('titles.pricing')}
        </NavLink>
        <NavLink
          to={publicRoutes.login}
          activeClassName="hidden"
          className={`${styles.link} ${isSignUp ? styles.mainLink : 'bg-brandedGrey-1'}`}
        >
          {t('auth.login')}
        </NavLink>
        <NavLink
          to={publicRoutes.signupV2}
          activeClassName="hidden"
          className={`${styles.link} ${styles.mainLink}`}
        >
          {t('auth.signup')}
        </NavLink>
      </>
    );
  };

  return (
    <div className="flex flex-col min-h-screen bg-offWhite">
      <AppBar>
        <Link to="/">
          <Logo />
        </Link>
        <div className="flex gap-2" data-test-id="header">
          {getLinks()}
        </div>
      </AppBar>
      <div className={`${styles.container} ${noPad ? '' : 'GlobalPad'}`}>{children}</div>
      {displayLegalsBar && <LegalsBar />}
    </div>
  );
};

UnprotectedLayout.propTypes = {
  children: PropTypes.node,
  noPad: PropTypes.bool,
  displayLegalsBar: PropTypes.bool,
  history: historyProps.isRequired,
  autoLogin: PropTypes.func.isRequired,
};

UnprotectedLayout.defaultProps = {
  displayLegalsBar: false,
  children: null,
  noPad: false,
};

const mapDispatchToProps = dispatch => ({
  autoLogin: params => dispatch(requestAutoLogin(params)),
});

export default withRouter(connect(null, mapDispatchToProps)(UnprotectedLayout));

import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';

const ByOneLink = ({ links, linkStyles }) => (
  <div className="linksDiv">
    {links?.map(link => (
      <a href={link?.link}>
        <div className="p-10 h-full">
          <div className={linkStyles}>
            <img src={link?.image} alt="" />
            <div className="mb-12 px-4">
              <h1 className="text-3xl my-3">{link?.title}</h1>
              {link?.abstract}
            </div>
            <div className="border-t-2 border-veryLightGrey p-4 absolute bottom-0 w-full">
              <span className="font-semibold">{link?.linkText}</span>
            </div>
          </div>
        </div>
      </a>
    ))}
  </div>
);

ByOneLink.propTypes = {
  links: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  linkStyles: PropTypes.shape().isRequired,
};

export default ByOneLink;

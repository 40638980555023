import React from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18next';

export const DarkLogoExample = ({ logo, className, errorMsg }) => (
  <ExampleLogo
    logo={logo}
    className={`bg-white border border-mpGrey ${className}`}
    textClass="bg-standardGrey text-white"
    text={t('promoHub_onLight')}
    errorMsg={errorMsg}
  />
);

export const LightLogoExample = ({ logo, className, errorMsg }) => (
  <ExampleLogo
    logo={logo}
    className={`bg-black ${className}`}
    textClass="text-black bg-mpGrey"
    text={t('promoHub_onDark')}
    errorMsg={errorMsg}
  />
);

const ExampleLogo = ({ logo, className, textClass, text, errorMsg }) => (
  <>
    {errorMsg && <p className="text-xs px-8 mb-2 font-medium text-lightRed">{errorMsg}</p>}
    <div
      style={{ backgroundImage: `url(${logo})` }}
      className={`min-h-[120px] rounded-lg overflow-hidden bg-contain bg-no-repeat bg-center relative ${
        errorMsg ? 'border-2 border-lightRed' : ''
      } ${className}`}
    >
      <p className={`absolute top-0 right-0 py-1 px-2 rounded-bl-lg text-xs ${textClass}`}>
        {text}
      </p>
    </div>
  </>
);

DarkLogoExample.propTypes = {
  logo: PropTypes.string,
  className: PropTypes.string,
  errorMsg: PropTypes.string,
};

DarkLogoExample.defaultProps = {
  logo: '',
  className: '',
  errorMsg: '',
};

LightLogoExample.propTypes = {
  logo: PropTypes.string,
  className: PropTypes.string,
  errorMsg: PropTypes.string,
};

LightLogoExample.defaultProps = {
  logo: '',
  className: '',
  errorMsg: '',
};

ExampleLogo.propTypes = {
  logo: PropTypes.string,
  className: PropTypes.string.isRequired,
  textClass: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  errorMsg: PropTypes.string.isRequired,
};

ExampleLogo.defaultProps = {
  logo: '',
};

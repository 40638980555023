import { checkIfMoreMatches } from './reducer';

export const filterActions = {
  fetchFilters: 'FETCH_FILTERS',
  fetchPageFilters: 'FETCH_PAGE_FILTERS',
  successFilters: 'SUCCESS_FILTERS',
  successPaginatedFilters: 'SUCCESS_PAGINATED_FILTERS',
  endFetchFilters: 'END_FETCH_FILTERS',
  errorFilters: 'ERROR_FILTERS',
  selectFilter: 'SELECT_FILTER',
  selectFilterFromSearch: 'SELECT_FILTER_FROM_SEARCH',
  selectManualSearch: 'SELECT_MANUAL_SEARCH',
  unselectFilter: 'UNSELECT_FILTER',
  applyFilters: 'APPLY_FILTERS',
  resetFilters: 'RESET_FILTERS',
  closeUnsaved: 'CLOSE_UNSAVED',
  setDate: 'SET_DATE',
  setOpenHours: 'SET_OPEN_HOURS',
  showAll: 'SHOW_ALL_FIXTURES',
  popularFixtures: 'SHOW_POPULAR_FIXTURES',
  onlyFavourites: 'SHOW_FAV_FIXTURES',
  changeDayOpenHours: 'CHANGE_DAY_OPEN_HOURS',
};

export const filterTypes = {
  favourite: 'favouriteFilters',
  sport: 'sportFilters',
  team: 'teamFilters',
  active: 'activeFilters',
};

export const dateTypes = {
  fromDate: 'fromDate',
  toDate: 'toDate',
};

export const initialState = {
  offset: 0,
  loading: true,
  loadingPage: false,
  hasMorePages: false,
  data: [],
  initialData: [],
  error: false,
  type: undefined,
};

const datesInitialState = { fromDate: '', toDate: '' };
const OHInitialState = { date: undefined };

export const filterInitial = {
  activeFilters: initialState,
  favouriteFilters: initialState,
  sportFilters: initialState,
  dates: {
    initialData: datesInitialState,
    data: datesInitialState,
  },
  openHours: {
    initialData: OHInitialState,
    data: OHInitialState,
  },
  search: '',
  sportsComps: [],
  popularFixtures: false,
  onlyFavourites: false,
};

const reducer = (state, action) => {
  const isSportsCompsFilter = type =>
    type !== filterTypes.favourite && type !== filterTypes.sport && type !== filterTypes.team;

  const findIndexAndObj = type => {
    const index = state?.sportsComps?.findIndex(e => e?.type === type);
    const obj = state?.sportsComps?.[index];
    return [index, obj];
  };

  const getNewState = (type, newObj, options) => {
    if (isSportsCompsFilter(type) && type !== undefined) {
      const [filterIndex, filterObj] = findIndexAndObj(type);

      if (filterIndex !== -1) {
        const newSportsComps = [...state?.sportsComps];

        newSportsComps[filterIndex] = newObj(filterObj);
        return { ...state, sportsComps: newSportsComps, ...options };
      }
      return state;
    }

    const existingSection = state?.[type] ? { [type]: newObj(state?.[type]) } : {};
    return {
      ...state,
      ...existingSection,
      ...options,
    };
  };

  const iterateNewState = (newObj, options) => {
    const {
      dates,
      openHours,
      search,
      sportsComps,
      popularFixtures,
      onlyFavourites,
      ...otherSections
    } = state;
    const newSportsComps = state?.sportsComps?.map(newObj);

    const newOtherSections = Object.entries(otherSections)?.reduce(
      (prev, [key, value]) => ({
        ...prev,
        [key]: newObj(value),
      }),
      {},
    );

    return {
      ...state,
      sportsComps: newSportsComps,
      ...newOtherSections,
      ...options,
    };
  };

  switch (action.type) {
    case filterActions.fetchFilters: {
      const { type } = action.payload;

      const newObj = {
        ...initialState,
        loading: true,
        type,
      };

      // If has already been fetched, set it last
      if (isSportsCompsFilter(type)) {
        return {
          ...state,
          sportsComps: [newObj, ...state?.sportsComps?.filter(e => e?.type !== type)],
        };
      }

      return {
        ...state,
        [type]: newObj,
      };
    }
    case filterActions.fetchPageFilters: {
      const { type } = action.payload;

      const getNewObj = currentState => ({
        ...currentState,
        loadingPage: true,
      });

      return getNewState(type, getNewObj);
    }
    case filterActions.successFilters: {
      const { type, result, matches, limit } = action.payload;

      const getNewObj = currentState => ({
        ...currentState,
        error: false,
        hasMorePages: checkIfMoreMatches(matches, limit, limit),
        offset: result?.length,
        data: [...result],
        initialData: [...result],
      });

      return getNewState(type, getNewObj);
    }
    case filterActions.successPaginatedFilters: {
      const { type, result, matches, limit } = action.payload;
      const getNewObj = currentState => ({
        ...currentState,
        error: false,
        hasMorePages: checkIfMoreMatches(matches, currentState?.data?.length + limit, limit),
        offset: currentState?.offset + result?.length,
        data: [...currentState?.data, ...result],
        initialData: [...currentState?.initialData, ...result],
      });

      return getNewState(type, getNewObj);
    }
    case filterActions.endFetchFilters: {
      const { type } = action.payload;

      const getNewObj = currentState => ({
        ...currentState,
        loading: false,
        loadingPage: false,
      });

      return getNewState(type, getNewObj);
    }
    case filterActions.errorFilters: {
      const { type } = action.payload;

      const getNewObj = currentState => ({
        ...currentState,
        error: true,
      });

      return getNewState(type, getNewObj);
    }
    case filterActions.selectFilter: {
      const { filter } = action.payload;
      const getNewObj = currentState => ({
        ...currentState,
        data: currentState?.data?.filter(fil => fil?.id !== filter?.id),
      });

      return getNewState(filter?.type, getNewObj, {
        [filterTypes?.active]: {
          ...state?.[filterTypes?.active],
          data: [...state?.[filterTypes?.active]?.data, filter],
        },
        // Reset OH when selecting a filter
        openHours: { data: OHInitialState, initialData: OHInitialState },
      });
    }
    case filterActions.selectFilterFromSearch: {
      const { filter } = action.payload;

      const { dates, activeFilters, openHours, search, sportsComps, ...otherState } = state;

      // We turn them into strings because some ids come in as strings, some as nums.
      const findIfExists = arr => arr?.find(f => `${f?.id}` === `${filter?.id}`);

      // If it's already selected, don't duplicate
      if (findIfExists(activeFilters?.data)) return state;

      let newFilter = {
        ...filter,
        type: filter?.category === 'Team' ? filterTypes.team : undefined,
      };

      const filterOut = f => parseInt(f?.id, 10) !== parseInt(filter?.id, 10);
      const newSection = section => ({
        ...section,
        data: section?.data?.filter(filterOut),
        initialData: section?.initialData?.filter(filterOut),
      });

      // If it's already in one of the filter sections, we use that object so that it can return to its section if unselected
      const newSportsComps = sportsComps?.map(section => {
        const doesItExist = findIfExists(section?.data);
        if (doesItExist) {
          // Is the selected filter already in the modal? Then replace the object
          newFilter = doesItExist;
          return newSection(section);
        }
        return section;
      });

      const newOtherSections = Object.entries(otherState)?.reduce((prev, [key, value]) => {
        const doesItExist = findIfExists(value?.data);
        if (doesItExist) {
          newFilter = doesItExist;
          return {
            ...prev,
            [key]: newSection(value),
          };
        }

        return { ...prev, [key]: value };
      }, {});

      return {
        ...state,
        sportsComps: newSportsComps,
        ...newOtherSections,
        [filterTypes?.active]: {
          ...state?.[filterTypes?.active],
          data: [...state?.[filterTypes?.active]?.data, newFilter],
          initialData: [...state?.[filterTypes?.active]?.initialData, newFilter],
        },
        // Reset OH when selecting a filter
        openHours: { data: OHInitialState, initialData: OHInitialState },
      };
    }
    case filterActions.selectManualSearch: {
      const { search } = action.payload;

      return {
        ...state,
        search,
        openHours: { data: OHInitialState, initialData: OHInitialState },
      };
    }
    case filterActions.unselectFilter: {
      const { filter } = action.payload;

      const getNewObj = currentState => ({
        ...currentState,
        data: [...currentState?.data, filter],
      });

      // Only return it to its competition if there is one. It could happen that there isn't if it's been selected from search
      return getNewState(filter?.type, getNewObj, {
        [filterTypes?.active]: {
          ...state?.[filterTypes?.active],
          data: state?.[filterTypes?.active]?.data?.filter(fil => fil?.id !== filter?.id),
        },
      });
    }
    case filterActions.applyFilters: {
      const { dates, openHours } = state;

      const getNewObj = currentState => ({
        ...currentState,
        initialData: currentState?.data,
      });

      return iterateNewState(getNewObj, {
        dates: { ...dates, initialData: dates.data },
        openHours: { ...openHours, initialData: openHours.data },
      });
    }
    case filterActions.resetFilters: {
      const newData = state?.[filterTypes?.active]?.data?.reduce((prev, el) => {
        if (el?.type) {
          if (isSportsCompsFilter(el.type)) {
            const [index, obj] = findIndexAndObj(el.type);
            const newSportsComps = [...prev?.sportsComps];
            newSportsComps[index] = { ...obj, data: [...obj?.data, el] };

            return {
              ...prev,
              sportsComps: newSportsComps,
            };
          }
          if (state?.[el.type]) {
            return {
              ...prev,
              [el.type]: {
                ...prev?.[el.type],
                data: [...prev?.[el.type]?.data, el],
              },
            };
          }
        }
        return prev;
      }, state);

      return {
        ...newData,
        openHours: { ...state?.openHours, data: OHInitialState },
        dates: { ...state?.dates, data: datesInitialState },
        [filterTypes?.active]: { ...state?.[filterTypes?.active], data: [] },
      };
    }
    case filterActions.closeUnsaved: {
      const { dates, openHours } = state;

      const getNewObj = currentState => ({
        ...currentState,
        data: currentState?.initialData,
      });

      return iterateNewState(getNewObj, {
        dates: {
          ...dates,
          data: dates.initialData,
        },
        openHours: {
          ...openHours,
          data: openHours.initialData,
        },
      });
    }
    case filterActions.setDate: {
      const { date, type } = action.payload;

      return {
        ...state,
        dates: { ...state?.dates, data: { ...state?.dates?.data, [type]: date } },
        // Turn off OH when selecting a date
        openHours: { ...state?.openHours, data: OHInitialState },
      };
    }
    case filterActions.setOpenHours: {
      const { date } = action.payload;

      return {
        ...state,
        dates: {
          ...state.dates,
          // If there are dates active on filters, get rid of them
          data: datesInitialState,
        },
        openHours: {
          ...state.openHours,
          data: { date },
        },
      };
    }
    case filterActions.changeDayOpenHours: {
      const { date } = action.payload;

      return {
        ...state,
        openHours: {
          ...state.openHours,
          initialData: { date },
        },
      };
    }
    case filterActions.showAll: {
      return {
        ...state,
        popularFixtures: false,
        onlyFavourites: false,
      };
    }
    case filterActions.popularFixtures: {
      return {
        ...state,
        popularFixtures: true,
        onlyFavourites: false,
      };
    }
    case filterActions.onlyFavourites: {
      return {
        ...state,
        popularFixtures: false,
        onlyFavourites: true,
      };
    }
    default:
      return state;
  }
};

export default reducer;

import { toast } from 'react-toastify';
import i18n from 'i18next';
import { Identify, identify } from '@amplitude/analytics-browser';
import * as actions from './actions';
import * as api from '../api/venues';
import { fetchSocialMediaAccounts } from '../socialMedia/thunks';
import { fireEvent, GACategories, GATags } from '../../utils/trackingHelper';
import { navigate } from '../navigation/thunks';
import routes from '../../routes/paths';

export const fetchVenues = venueId => dispatch => {
  dispatch(actions.fetchVenuesPending());
  return dispatch(api.getVenuesList(venueId))
    .then(res => res.getResult())
    .then(result => {
      const firstVenue = result?.length > 0 ? result[0] : null;
      if (firstVenue) {
        const { id, ownershipType, tierId, isMasterPub, ownerId } = firstVenue;
        const event = new Identify();
        event.setOnce('venueId', `${id}`);
        event.setOnce('ownershipType', `${ownershipType}`);
        event.setOnce('tierId', `${tierId}`);
        event.setOnce('ownerId', `${ownerId}`);
        event.setOnce('isMasterPub', `${isMasterPub}`);
        identify(event);
      }
      dispatch(actions.fetchVenuesSuccess(result));
      return result;
    })
    .catch(() => dispatch(actions.fetchVenuesError()));
};

export const refreshVenueThunk = venueId => dispatch =>
  dispatch(api.getVenuesList(venueId))
    .then(res => res.getResult())
    .then(result => dispatch(actions.fetchVenuesSuccess(result)));

export const fetchPermissions = venueID => dispatch => {
  dispatch(actions.fetchPermissionsPending());
  return dispatch(api.getPermissions(venueID))
    .then(res => res.getResult())
    .then(result => dispatch(actions.fetchPermissionsSuccess(result)))
    .catch(() => dispatch(actions.fetchPermissionsError()));
};

export function updateVenueThunk(venue, callback = () => {}, preventToast) {
  return function _updateVenue(dispatch) {
    dispatch(actions.updateVenuePending());
    fireEvent(GACategories.PROFILE, GATags.UPDATE_INFO);
    return dispatch(api.saveVenue(venue))
      .then(res => res.getResult())
      .then(result => {
        if (!preventToast) toast.success(i18n.t('toast.updated_details_success'));
        return dispatch(actions.updateVenueSuccess(result));
      })
      .then(() => callback())
      .catch(error => {
        if (error.code === 202) {
          toast.error(error.message);
        }
        return dispatch(actions.updateVenueError(error.error));
      });
  };
}

export function fetchVenueChannels(id) {
  return function _fetchVenueChannels(dispatch) {
    dispatch(actions.fetchVenueChannelsPending());
    return dispatch(api.getVenueChannels(id))
      .then(res => res.getResult())
      .then(result => dispatch(actions.fetchVenueChannelsSuccess(result)))
      .catch(() => dispatch(actions.fetchVenueChannelsError()));
  };
}

export function saveVenueChannels(id, channels) {
  return function _saveVenueChannels(dispatch) {
    dispatch(actions.updateVenueChannelsPending());
    fireEvent(GACategories.PROFILE, GATags.UPDATE_CHANNELS);
    return dispatch(api.updateVenueChannels(id, channels))
      .then(res => res.getResult())
      .then(() => dispatch(actions.updateVenueChannelsSuccess(channels)))
      .catch(() => dispatch(actions.updateVenueChannelsError()));
  };
}

export function updateFacebookInfoThunk(venueID, facebookInfo) {
  return function _updateFacebookInfoThunk(dispatch) {
    return dispatch(api.updateFacebookInfo(venueID, facebookInfo))
      .then(() => {
        dispatch(actions.AuthModalSuccess());
        return dispatch(fetchSocialMediaAccounts(venueID, true, false));
      })
      .catch(e => {
        dispatch(actions.AuthModalError());
        if (e.code === 202) {
          toast.error(e.message);
        }
      });
  };
}

export function facebookLogoutThunk(venueID) {
  return function _facebookLogoutThunk(dispatch) {
    dispatch(actions.facebookDisconnectStart());
    return dispatch(api.removeFacebookInfo(venueID))
      .then(() => {
        dispatch(actions.facebookDisconnectSuccess());
        return dispatch(fetchSocialMediaAccounts(venueID, true, false));
      })
      .catch(e => {
        dispatch(actions.facebookDisconnectError());
        if (e.code === 202) {
          toast.error(e.message);
        }
      });
  };
}

export function getTwitterAuthURLThunk(venueID) {
  return function _getTwitterAuthURLThunk(dispatch) {
    dispatch(actions.startTwitterLogin());
    return dispatch(api.getTwitterAuthURL(venueID))
      .then(res => res.getResult())
      .then(url => dispatch(actions.setTwitterAuthURL(url)))
      .catch(() => dispatch(actions.AuthModalError()));
  };
}

export function twitterLogout(venueID) {
  return function _twitterLogout(dispatch) {
    dispatch(actions.startTwitterLogin());
    return dispatch(api.twitterLogoutReq(venueID))
      .then(() => {
        dispatch(actions.stopTwitterLogin());
        return dispatch(fetchSocialMediaAccounts(venueID, false));
      })
      .catch(() => {
        dispatch(actions.stopTwitterLogin());
        return dispatch(fetchSocialMediaAccounts(venueID, false));
      });
  };
}

export const attemptSubscribeThunk = (venueID, tierId, frequency = 12, promoCode) => dispatch =>
  dispatch(api.venueSubscribe(venueID, tierId, frequency, promoCode))
    .then(res => {
      const result = res.getResult();
      if (result.goCardlessLink) {
        window.location.href = result.goCardlessLink;
        return null;
      }
      return dispatch(refreshVenueThunk(venueID)).then(() => navigate(routes.signupV2Stage3.path));
    })
    .catch(error => {
      if (error.code === 202) {
        toast.error(error.message);
      }
    });

export const getStatesByCountry = countryID => dispatch =>
  dispatch(api.getStatesByCountry(countryID)).then(res => res.getResult());

export const subscribeFinaliseThunk = (venueID, subscriptionKey) => dispatch =>
  dispatch(api.subscribeFinalise(venueID, subscriptionKey))
    .then(() => {
      dispatch(fetchPermissions(venueID));
      return dispatch(refreshVenueThunk(venueID));
    })
    .catch(error => {
      if (error.code === 202) {
        toast.error(error.message);
      }
      throw new Error();
    });

export const venueResubscribeFinalise = (venueID, resubKey) => dispatch => {
  dispatch(actions.startFinaliseResub());
  return dispatch(api.resubscribeFinalise(venueID, resubKey))
    .then(() => dispatch(actions.successFinaliseResub()))
    .catch(e =>
      dispatch(actions.errorFinaliseResub(e?.error?.message || 'Sorry, something went wrong')),
    );
};

export const updateLeagueVenueThunk = (id, leagueId, data) => dispatch =>
  dispatch(api.updateVenueLeague(id, leagueId, data))
    .then(res => res.getResult())
    .then(r => dispatch(actions.updateVenueLeague(r)));

import React, { useState, useEffect, useRef } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import i18n from 'i18next';
import { connect } from 'react-redux';
import routes from '../../routes/paths';
import { venueProps, locationProps, historyProps } from '../../utils/customPropTypes';
import { getVenue } from '../../store/venue/selectors';
import {
  fetchThemeData,
  fetchBespokePreviews,
  fetchThemeConfig,
  fetchFontsForSM,
  saveSMConfig,
} from '../../store/socialMedia/thunks';
import ImageGenerator from './ImageGenerator/ImageGenerator';
import { fireEvent, GATags, GACategories } from '../../utils/trackingHelper';
import {
  createFontsForTemplates,
  composeStateFromCustomAndDefaultDatasets,
} from './AssetEditorWrapper/AssetEditorUtils';
import AssetEditorWrapper from './AssetEditorWrapper/AssetEditorWrapper';
import { getIsMobile } from '../../store/app/selectors';
import { aspectRatioLandscape, aspectRatioPortrait } from './ImageGenerator/shared';
import sizes from './ImageGenerator/styles.scss';
import AssetFormatIcon, { types } from '../../components/AssetFormatIcon/AssetFormatIcon';
import { assetTypes } from '../../utils/constants';
import { useHiddenByPermissions } from '../../utils/CustomHooks';

const ColorAssetEditor = ({
  title,
  venue,
  location,
  history,
  getThemeConfig,
  getFonts,
  getThemeData,
  getBespokePreviews,
  onSave,
  isMobile,
}) => {
  const routerState = history?.location?.state;
  const scrollRef = useRef(null);
  const [defaultThemeConfig, setdefaultThemeConfig] = useState(null);

  const [loadingAssets, setLoadingAssets] = useState(null);
  const [fonts, setFonts] = useState([]);

  const [error, setError] = useState(null);
  const [state, updateState] = useState({});
  const [initialState, setInitialState] = useState({});

  const [type, setType] = useState(routerState?.format || types.landscape);
  const [previewsForGenerators, setPreviewsForGenerators] = useState([]);
  const [refreshingPreviews, setRefreshingPreviews] = useState(false);

  const [themeConstraints, setThemeConstraints] = useState({});

  useEffect(() => {
    // refreshes previews when secondary font related data changes
    if (fonts && state.secondaryTextFont && state.secondaryTextColor && type) {
      // Just fetch the images that will be displayed
      const filteredThemeConfig = defaultThemeConfig?.filter(el => el?.format === type);
      setRefreshingPreviews(true);
      getBespokePreviews(
        venue.id,
        state.secondaryTextFont,
        state.secondaryTextColor.slice(1, 7), // gets rid of '#' in the hex color
        i18n.resolvedLanguage,
        filteredThemeConfig.map(el => el?.id),
      )
        .then(previews => {
          setRefreshingPreviews(false);
          if (defaultThemeConfig && previews) {
            const locatePreviewURL = themeId => {
              const preview = previews.find(p => p.id === themeId);
              return preview && preview.url;
            };
            setPreviewsForGenerators(
              filteredThemeConfig.map(theme => ({
                nbFixtures: theme.nbFixtures,
                format: theme.format,
                id: theme.id,
                url: locatePreviewURL(theme.id),
              })),
            );
          }
        })
        .catch(err => {
          setRefreshingPreviews(false);
          setError(err.message);
        });
    }
  }, [
    state.secondaryTextFont,
    state.secondaryTextColor,
    fonts,
    getBespokePreviews,
    venue.id,
    defaultThemeConfig,
    type,
  ]);

  useEffect(() => {
    setLoadingAssets(true);
    Promise.all([
      getFonts(venue.id),
      getThemeData(venue.id, assetTypes.color),
      getThemeConfig(venue.id),
    ])
      .then(([retrievedFonts, originalThemeData, customConfig]) => {
        setFonts(retrievedFonts);
        createFontsForTemplates(retrievedFonts);
        setLoadingAssets(false);
        setdefaultThemeConfig(originalThemeData);
        const { newConstraints, newState } = composeStateFromCustomAndDefaultDatasets(
          originalThemeData,
          customConfig,
        );
        setThemeConstraints(newConstraints);
        setInitialState(newState);
        updateState(newState);
      })
      .catch(err => {
        setLoadingAssets(false);
        setError(err.message);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const hasChanges = Boolean(Object.keys(state).find(key => state[key] !== initialState[key]));

  const scrollToRelevantTemplate = () => {
    // if coming from PostToSocial, scroll to the format that was selected when navigating.
    if (!(location.state && location.state.nbFixtures)) {
      return;
    }
    const { isLandscape, nbFixtures } = location.state;

    const portraitElWidth =
      (isMobile ? sizes.canvasHeightMobile : sizes.canvasHeight) * aspectRatioPortrait +
      2 * sizes.canvasSideMargin;
    const landscapeElWidth =
      (isMobile ? sizes.canvasHeightMobile : sizes.canvasHeight) * aspectRatioLandscape +
      2 * sizes.canvasSideMargin;

    const offsetPerElement = isLandscape ? landscapeElWidth : portraitElWidth;

    // scroll past all landscape items if portrait
    const groupOffset = isLandscape ? 0 : (landscapeElWidth + 2 * sizes.canvasSideMargin) * 4;
    const fixNumbersOrder = [1, 3, 5, 10];

    const offset = groupOffset + offsetPerElement * fixNumbersOrder.indexOf(nbFixtures);

    if (scrollRef && scrollRef.current) {
      scrollRef.current.scrollLeft = offset;
    }
  };

  const {
    textColor,
    secondaryTextColor,
    backgroundColor,
    image,
    logo,
    headline,
    logoScale,
    fontSize,
    textFont,
    secondaryTextFont,
  } = state;

  useHiddenByPermissions();

  return (
    <AssetEditorWrapper
      isLoading={
        loadingAssets || !defaultThemeConfig || !previewsForGenerators.length || refreshingPreviews
      }
      optionalComponent={
        <div className="flex items-end gap-4 pb-4">
          {Object.keys(themeConstraints).map(key => (
            <AssetFormatIcon
              key={key}
              type={key}
              selected={type === key}
              onClick={() => setType(key)}
            />
          ))}
        </div>
      }
      onChange={object => updateState({ ...state, ...object })}
      state={state}
      fonts={fonts}
      title={title}
      error={error}
      hasChanges={hasChanges}
      onSave={() => {
        fireEvent(GACategories.CUSTOM_ASSET, GATags.SAVE_CUSTOMISED_TEMPLATE, {
          venueId: venue.id,
        });
        onSave(
          venue.id,
          JSON.stringify({
            textColor,
            secondaryTextColor,
            backgroundColor,
            image,
            logo,
            headline,
            logoScale,
            fontSize,
            textFont,
            secondaryTextFont,
          }),
        ).then(() => history.push(location?.state?.returnRoute || routes.marketing.path));
      }}
      onDefault={() => {
        fireEvent(GACategories.CUSTOM_ASSET, GATags.DISCARD_CHANGES);
        updateState(initialState);
      }}
    >
      <div ref={scrollRef} className="AssetEditorWrapper_canvasSlider">
        <ImageGenerator
          onLoadAllAssets={scrollToRelevantTemplate}
          font={textFont}
          constraints={{ [type]: themeConstraints[type] }}
          previews={previewsForGenerators}
          logoScale={logoScale}
          fontSize={fontSize}
          image={image}
          logo={logo}
          text={headline || ''}
          color={backgroundColor}
          textColor={textColor}
        />
      </div>
    </AssetEditorWrapper>
  );
};

const mapDispatchToProps = dispatch => ({
  onSave: (venueId, JSONconfig) => dispatch(saveSMConfig(venueId, JSONconfig)),
  getThemeConfig: venueId => dispatch(fetchThemeConfig(venueId)),
  getThemeData: (venueId, type) => dispatch(fetchThemeData(venueId, type)),
  getBespokePreviews: (venueId, font, color, language, themeId) =>
    dispatch(fetchBespokePreviews(venueId, font, color, language, undefined, undefined, themeId)),
  getFonts: venueId => dispatch(fetchFontsForSM(venueId)),
});

const mapStateToProps = state => ({
  venue: getVenue(state),
  isMobile: getIsMobile(state),
});

ColorAssetEditor.propTypes = {
  venue: venueProps.isRequired,
  title: PropTypes.string.isRequired,
  location: locationProps.isRequired,
  getThemeConfig: PropTypes.func.isRequired,
  getFonts: PropTypes.func.isRequired,
  getThemeData: PropTypes.func.isRequired,
  getBespokePreviews: PropTypes.func.isRequired,
  history: historyProps.isRequired,
  onSave: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ColorAssetEditor));

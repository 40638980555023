export const ACTIONS = {
  fetch: 'FETCH',
  success: 'SUCCESS',
  fetchPage: 'FETCH_PAGE',
  successPage: 'SUCCESS_PAGE',
  endFetch: 'END_FETCH',
  error: 'ERROR',
  showChangesAfterFetch: 'SHOW_CHANGES_AFTER_FETCH',
  updateFixture: 'UPDATE_FIXTURE',
  discard: 'DISCARD',
  saveSuccess: 'SAVE_SUCCESS',
  updateDefaultPrefs: 'UPDATE_DEFAULT_PREFS',
};

export const checkIfMoreMatches = (matches, length, limit) => {
  if (matches) {
    return matches > length;
  }
  return length === limit;
};

export const initialState = {
  offset: 0,
  loading: true,
  loadingPage: false,
  hasMorePages: false,
  data: [],
  initialData: [],
  changes: [],
  error: false,
};

// this is really weird logic, long story short null and !active we'll consider as the same
export const compareVenueObjects = (obj1, obj2) => {
  if (obj2 === null) {
    return !obj1.active;
  }
  if (obj1 === null) {
    return !obj2.active;
  }
  return (
    obj1?.active === obj2?.active &&
    obj1?.sound === obj2?.sound &&
    obj1?.bigScreen === obj2?.bigScreen
  );
};

const reducer = (state, action) => {
  switch (action.type) {
    case ACTIONS.fetch:
      return {
        ...state,
        loading: true,
      };
    case ACTIONS.fetchPage:
      return {
        ...state,
        loadingPage: true,
      };
    case ACTIONS.success: {
      const { matches, result, limit } = action.payload;
      return {
        ...state,
        error: false,
        hasMorePages: checkIfMoreMatches(matches, result?.length, limit),
        offset: 0 + result?.length,
        data: [...result],
        initialData: [...result],
      };
    }
    case ACTIONS.successPage: {
      const { matches, result, limit } = action.payload;
      return {
        ...state,
        error: false,
        hasMorePages: checkIfMoreMatches(matches, state?.data?.length + limit, limit),
        offset: state.offset + result?.length,
        data: [...state.data, ...result],
        initialData: [...state.initialData, ...result],
      };
    }
    case ACTIONS.showChangesAfterFetch: {
      let dataWithChanges = [...state.data];
      // apply temporary changes, if any, to recently fetched fixtures
      if (state.changes.length > 0) {
        // loop through changes and look for every item in the new results
        state.changes.forEach(changedFixtures => {
          dataWithChanges = dataWithChanges.map(fixture => {
            // if the fixture has a change update accordingly
            if (fixture.id === changedFixtures.eventId) {
              return { ...fixture, venueEvent: changedFixtures };
            }
            return fixture;
          });
        });
      }
      return {
        ...state,
        data: [...dataWithChanges],
      };
    }
    case ACTIONS.updateFixture: {
      const {
        payload: { eventId, feature, defaultPrefs },
      } = action;

      // avoid mutation
      const safeData = [...state?.data];
      const index = safeData.findIndex(elm => elm.id === eventId);

      // if the venue hasn't been added before venueEvent will be null so lets build the default + avoid mutation
      const newVenueEvent = safeData?.[index]?.venueEvent
        ? { ...safeData?.[index]?.venueEvent }
        : {
            active: false,
            sound: defaultPrefs.sound,
            bigScreen: defaultPrefs.bigScreen,
            eventId,
          };

      const initialFixtureVenueEvent = state.initialData.find(
        elm => elm.id === newVenueEvent.eventId,
      )?.venueEvent;

      newVenueEvent[feature] = !newVenueEvent[feature];

      // If we edit 'sound' or 'big screen' of an unactive fixture, the fixture activates
      if (!newVenueEvent?.active && feature !== 'active') {
        newVenueEvent.active = true;
      }

      // avoid mutation
      const safeFixture = { ...safeData[index] };

      // attach the new venue event to the fixture
      safeFixture.venueEvent = newVenueEvent;

      // We add the modified fixture to the array of fixtures
      safeData.splice(index, 1, safeFixture);

      // This is what will go to the BE when the user presses 'save', an array with all changes.
      const newFixtureChanges = state.changes.filter(elm => elm.eventId !== newVenueEvent.eventId);

      // only add into changes if its different to initial
      if (!compareVenueObjects(newVenueEvent, initialFixtureVenueEvent)) {
        newFixtureChanges.push(newVenueEvent);
      }

      return {
        ...state,
        data: safeData,
        changes: newFixtureChanges,
      };
    }
    case ACTIONS.discard: {
      return {
        ...state,
        data: [...state.initialData],
        changes: [],
      };
    }
    case ACTIONS.saveSuccess: {
      return {
        ...state,
        initialData: [...state.data],
        changes: [],
      };
    }
    case ACTIONS.endFetch:
      return {
        ...state,
        loading: false,
        loadingPage: false,
      };
    case ACTIONS.updateDefaultPrefs: {
      const { sound, bigScreen } = action.payload?.updatedPrefs || {};
      // keep the changes array and the venue event object on the fixture in line with each other and the default perferences

      const safeChanges = [...state?.changes];

      const safeFixtures = [...state.data];

      return {
        ...state,
        changes: safeChanges.map(f => ({ ...f, sound, bigScreen })),
        data: safeFixtures.map(f => {
          if (safeChanges.find(ch => ch.eventId === f.id)) {
            return { ...f, venueEvent: { ...f.venueEvent, sound, bigScreen } };
          }
          return f;
        }),
      };
    }
    case ACTIONS.error:
      return { ...state, error: true };
    default:
      return state;
  }
};

export default reducer;

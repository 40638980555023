import * as consts from './constants';

export function fetchListFixturesPending() {
  return {
    type: consts.FETCH_LIST_FIXTURES_PENDING,
  };
}

export function fetchListFixturesSuccess(fixtures) {
  return {
    type: consts.FETCH_LIST_FIXTURES_SUCCESS,
    payload: fixtures,
  };
}

export function fetchListFixturesError() {
  return {
    type: consts.FETCH_LIST_FIXTURES_ERROR,
  };
}

export function fetchNextListFixturesPending() {
  return {
    type: consts.FETCH_NEXT_LIST_FIXTURES_PENDING,
  };
}

export function fetchNextListFixturesSuccess(fixtures) {
  return {
    type: consts.FETCH_NEXT_LIST_FIXTURES_SUCCESS,
    payload: fixtures,
  };
}

export function fetchNextListFixturesError() {
  return {
    type: consts.FETCH_NEXT_LIST_FIXTURES_ERROR,
  };
}

import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import Button from '../../components/Button/Button';
import SpinnerButton from '../../components/SpinnerButton/SpinnerButton';
import Toggle from '../../components/Toggle/Toggle';
import ChevronIcon from '../../components/Icons/ChevronIcon';
import EditIcon from '../../components/Icons/EditIcon';
import i18n from '../../i18n';
import { getVenueGame, getVenueLeague } from '../../store/venue/selectors';
import {
  addVenueLeaguePaperTippers,
  fetchLeagueMembers,
  fetchLeagueRankings,
  removeVenueLeaguePaperTipper,
  updatePendingVenueMember,
  updateVenueLeaguePaperTipper,
} from '../../store/api/venues';
import { updateLeagueVenueThunk } from '../../store/venue/thunks';
import { useRequest } from '../../utils/CustomHooks';
import MpModal from '../../components/MPModal/MPModal';
import { historyProps } from '../../utils/customPropTypes';
import { debounce } from '../../utils/general';
import Search from './Search';
import LoadingAnimation from '../../components/LoadingAnimation/LoadingAnimation';
import { getServicesEndpoint } from '../../utils/environment';
import { fireEvent, GACategories, GATags } from '../../utils/trackingHelper';
import CloseIcon from '../../components/Icons/CloseIcon';
import PlusIcon from '../../components/Icons/PlusIcon';
import PlainButton from '../../components/PlainButton/PlainButton';

const s = {
  title: 'text-lg font-bold',
  subTitle: 'opacity-60 text-lg pt-3 pb-4 max-w-md',
  commonRow: 'pl-3 py-1 text-lg font-semibold',
  border: 'border-r-2 border-mpGrey',
  posRow: 'w-28 flex flex-row items-center justify-around',
  approvalButton: 'px-2 py-1 mr-2 rounded-full border',
  modalHeading: 'headingFont text-7xl max-w-xs mb-7 text-left leading-compact',
  inputHeading: 'font-bold text-base pb-2',
  inputContainer:
    'flex justify-between bg-white items-center flex-1 rounded-lg border overflow-hidden',
  spinnerButton: 'py-2 px-3 rounded w-full mt-7 uppercase',
};

const limit = 20;

const League = ({
  league,
  fetchRankings,
  fetchMembers,
  updateLeague,
  updatePendingMember,
  addPaperTippersAPI,
  history,
  removePaperTipperAPI,
  updatePaperTipperAPI,
  game,
}) => {
  const [value, setValue] = useState('');
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedTipper, setSelectedTipper] = useState(false);
  const [rankingsState, setRankingsState] = useState({
    loading: false,
    loadingPage: false,
    data: [],
    offset: 0,
    hasMorePages: false,
  });
  const [, leagueApprovals, fetchMem] = useRequest(fetchMembers);
  const [, searchResults, searchMem, clearSearch] = useRequest(fetchMembers);
  const { memberApproval, gameId, venueId, freePot, id: leagueId } = league || {};

  const offsetIsZero = rankingsState.offset === 0;

  const fetchR = () => {
    setRankingsState({ ...rankingsState, loading: offsetIsZero, loadingPage: !offsetIsZero });
    fetchRankings(gameId, venueId, { offset: rankingsState.offset, limit }).then(r => {
      const result = r.getResult();
      const data = !offsetIsZero ? [...rankingsState.data, ...result] : result;
      setRankingsState({
        ...rankingsState,
        data,
        loading: false,
        loadingPage: false,
        hasMorePages: result.length === limit,
      });
    });
  };

  const refetchRankings = () => {
    if (offsetIsZero === 0) {
      fetchR();
    } else {
      setRankingsState({ ...rankingsState, offset: 0 });
    }
  };

  const clear = () => {
    clearSearch();
    setValue('');
  };

  const debouncedSearch = useMemo(
    () =>
      debounce(search => {
        if (search.length > 2) {
          fireEvent(GACategories.FOOTY_TIPPING, GATags.USE_PT_SEARCH);
          searchMem(gameId, venueId, { search, offlinePlayers: true });
        }
      }, 750),
    [gameId, searchMem, venueId],
  );

  useEffect(() => {
    if (gameId && venueId) {
      fetchR();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rankingsState.offset, gameId, venueId]);

  useEffect(() => {
    if (gameId && venueId && memberApproval) {
      fetchMem(gameId, venueId, { approvalStatus: 'pending' });
    }
  }, [fetchMem, gameId, venueId, memberApproval]);

  useEffect(() => {
    debouncedSearch(value);
  }, [debouncedSearch, value]);

  useEffect(() => fireEvent(GACategories.FOOTY_TIPPING, GATags.VIEW_PAGE), []);

  const getTipperLeagueApprovalText = () => {
    if (!memberApproval) {
      return i18n.t('tipping.approval.text1');
    }
    if (leagueApprovals?.length === 0) {
      return i18n.t('tipping.approval.text2');
    }
    return null;
  };

  const acceptReject = (memberId, action) =>
    updatePendingMember(memberId, action).then(() => {
      refetchRankings();
      fetchMem(gameId, venueId, { approvalStatus: 'pending' });
    });

  const addPaperTippers = players =>
    addPaperTippersAPI(gameId, venueId, players, leagueId).then(refetchRankings);

  const removePaperTipper = memberId =>
    removePaperTipperAPI(gameId, venueId, memberId, leagueId).then(refetchRankings);

  const updatePaperTipper = (memberId, name) =>
    updatePaperTipperAPI(gameId, venueId, memberId, name).then(refetchRankings);

  const navtoPaperTipper = id =>
    history.push({
      pathname: '/footy-tipping/paper-tips',
      state: {
        selectedUser: id,
      },
    });

  return (
    <div className="GlobalPad pt-10 bg-white flex-1">
      <div className="flex flex-row">
        <Search
          placeholder={i18n.t('tipping.serachPaper')}
          clear={clear}
          onChange={newVal => setValue(newVal)}
          value={value}
          searchResults={searchResults}
          onClick={navtoPaperTipper}
        />
        <Button onClick={() => setModalOpen(true)} className="bg-anotherGrey mx-5">
          <p className="font-semibold">{i18n.t('tipping.addPaper')}</p>
        </Button>
        <Button className="bg-anotherGrey hidden md:block">
          <a
            className="font-semibold"
            onClick={() => fireEvent(GACategories.FOOTY_TIPPING, GATags.DOWNLOAD_TABLE)}
            href={`${getServicesEndpoint()}games/${gameId}/marketing-assets/league-pdf/${venueId}`}
          >
            {i18n.t('tipping.downloadTable')}
          </a>
        </Button>
      </div>

      <div className="max-w-xl pb-10">
        <p className="text-lg pt-10">
          <span className="font-bold">{i18n.t('tipping.league.title')}</span> {league?.code}
        </p>
        <p className={s.subTitle}>{i18n.t('tipping.subTitle')}</p>
        {game?.allowFreePotToggle && (
          <Section
            title={i18n.t('tipping.freePot.title')}
            explainer={i18n.t('tipping.freePot', {
              value: freePot ? i18n.t('tipping.can') : i18n.t('tipping.cannot'),
            })}
            checked={freePot}
            onChange={() => updateLeague(gameId, venueId, { freePot: !freePot })}
          />
        )}
        <Section
          title={i18n.t('tipping.approval.title')}
          explainer={getTipperLeagueApprovalText()}
          checked={memberApproval}
          onChange={() =>
            updateLeague(gameId, venueId, {
              memberApproval: !memberApproval,
            })
          }
        />
        {memberApproval &&
          leagueApprovals?.map(la => (
            <Approvals update={acceptReject} key={la.id} approvals={la} />
          ))}
        <LeagueTable
          loadMore={() =>
            setRankingsState({ ...rankingsState, offset: rankingsState?.data?.length })
          }
          state={rankingsState}
          onClickPaperTipper={setSelectedTipper}
        />
      </div>
      <AddPaperTippersModal
        open={modalOpen}
        updatePaperTippers={addPaperTippers}
        onClose={() => setModalOpen(false)}
      />
      <EditPaperTipperModal
        updateSelectedTipper={updatePaperTipper}
        removePaperTipper={removePaperTipper}
        selectedTipper={selectedTipper}
        open={Boolean(selectedTipper)}
        onClose={() => setSelectedTipper(false)}
        navToEditPaper={navtoPaperTipper}
      />
    </div>
  );
};

const AddPaperTippersModal = ({ open, onClose, updatePaperTippers }) => {
  const [values, setValues] = useState(['']);
  const [loading, setLoading] = useState(false);

  const Field = i => (
    <div key={i} className="pb-4">
      <p className={s.inputHeading}>{i18n.t('tipping.addPaper.tipper', { value: i + 1 })}</p>
      <div className={`${s.inputContainer} border-lightGrey`}>
        <input
          autoComplete="new-password"
          value={values[i]}
          placeholder={i18n.t('tipping.addPaper.placeholder')}
          className="flex-1 outline-none p-3"
          onChange={e => {
            const newValues = [...values];
            newValues[i] = e.target.value;
            setValues(newValues);
          }}
        />
        {i > 0 && (
          <Button onClick={() => setValues(values.filter((_, newI) => i !== newI))}>
            <CloseIcon className="w-5 h-5" />
          </Button>
        )}
      </div>
    </div>
  );
  const anyNameSet = values.find(e => e.length > 0);

  return (
    <Modal onClose={onClose} open={open}>
      <p className={s.modalHeading}>{i18n.t('tipping.addPaper')}</p>
      {[...Array(values.length).keys()].map(e => Field(e))}
      <Button
        capitalize={false}
        className="bg-anotherGrey ml-auto flex items-center"
        onClick={() => {
          fireEvent(GACategories.FOOTY_TIPPING, GATags.ANOTHER_PT);
          setValues([...values, '']);
        }}
      >
        <PlusIcon className="w-4 h-4 mr-1" />
        <p className="text-base">{i18n.t('tipping.addPaper.addAnother')}</p>
      </Button>
      <SpinnerButton
        className={`${anyNameSet ? 'bg-primary' : 'bg-anotherGrey'} ${s.spinnerButton}`}
        disabled={!anyNameSet}
        loading={loading}
        onClick={() => {
          setLoading(true);
          fireEvent(GACategories.FOOTY_TIPPING, GATags.SAVE_PT);
          updatePaperTippers(values)
            .then(() => {
              setValues(['']);
              onClose();
              toast.success(i18n.t('tipping.addPaper.success', { value: values.length }));
            })
            .finally(() => setLoading(false));
        }}
      >
        <p className={`${!anyNameSet ? 'opacity-30' : ''} font-semibold text-base`}>
          {i18n.t('tipping.addPaper')}
        </p>
      </SpinnerButton>
    </Modal>
  );
};

const EditPaperTipperModal = ({
  open,
  onClose,
  selectedTipper,
  updateSelectedTipper,
  navToEditPaper,
  removePaperTipper,
}) => {
  const [name, setName] = useState(selectedTipper.memberName);
  const [editable, setEditable] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setName(selectedTipper.memberName);
    setEditable(false);
  }, [selectedTipper]);

  return (
    <Modal onClose={onClose} open={open}>
      <p className={s.modalHeading}>
        {name?.split(' ')[0]}
        <br />
        {name?.split(' ')[1]}
      </p>
      <p className={s.inputHeading}>{i18n.t('tipping.editTipper')}</p>
      <div className={`${s.inputContainer} ${editable ? 'border-secondary' : 'border-lightGrey'}`}>
        <input
          disabled={!editable}
          autoComplete="new-password"
          value={name}
          placeholder={i18n.t('tipping.addPaper.placeholder')}
          className="flex-1 outline-none p-3"
          onChange={e => setName(e.target.value)}
        />
        <Button onClick={() => setEditable(true)}>
          <EditIcon className="h-8 w-8" />
        </Button>
      </div>
      <SpinnerButton
        className={`${editable ? 'bg-primary' : 'bg-anotherGrey'} ${s.spinnerButton}`}
        disabled={!editable}
        loading={loading}
        onClick={() => {
          setLoading(true);
          updateSelectedTipper(selectedTipper.memberId, name)
            .then(() => setEditable(false))
            .finally(() => setLoading(false));
        }}
      >
        <p className={`${!editable ? 'opacity-30' : ''} font-semibold text-base`}>
          {i18n.t('tipping.saveTipper')}
        </p>
      </SpinnerButton>
      <div className="p-8 bg-anotherGrey flex flex-col items-center my-6 rounded-md">
        <p className={s.title}>{i18n.t('tipping.paperTips')}</p>
        <p className="opacity-60 text-base pt-3 pb-8">{i18n.t('tipping.paperTips.subtitle')}</p>
        <Button onClick={() => navToEditPaper(selectedTipper.memberId)} className="bg-black w-full">
          <p className="font-semibold text-white text-base">{i18n.t('tipping.setEditTips')}</p>
        </Button>
      </div>
      <SpinnerButton
        className="w-full"
        loading={loading}
        onClick={() => {
          setLoading(true);
          removePaperTipper(selectedTipper.memberId)
            .then(onClose)
            .finally(() => setLoading(false));
        }}
      >
        <p className="font-semibold text-primaryRed underline text-base uppercase">
          {i18n.t('tipping.deleteTipper')}
        </p>
      </SpinnerButton>
    </Modal>
  );
};

const LeagueTable = ({ state, onClickPaperTipper, loadMore }) => {
  const headings = [
    { name: 'Postition', style: `py-4 ${s.border} w-28 pl-2 ${s.title}` },
    { name: 'Name', style: `flex-1 py-4 ${s.border} pl-2 ${s.title}` },
    { name: 'Type', style: `py-4 ${s.border} w-20 pl-2 ${s.title}` },
    { name: 'Score', style: `py-4 w-20 pl-2 ${s.title}` },
  ];

  const getRankingChange = change => {
    if (change === 'up') {
      return <ChevronIcon className="h-7 w-7 text-green -rotate-90" />;
    }

    if (change === 'down') {
      return <ChevronIcon className="h-7 w-7 text-primaryRed rotate-90" />;
    }
    return <p>-</p>;
  };

  if (state.loading) return <LoadingAnimation size={80} />;

  if (state.data?.length === 0) {
    return null;
  }

  return (
    <>
      <p className={`${s.title} pt-4`}>{i18n.t('tipping.leagueTable')}</p>
      <div className="border-r-2 border-t-2 border-l-2 rounded border-mpGrey mt-4">
        <div className="flex flex-row border-b-2 border-mpGrey">
          {headings.map(h => (
            <p key={h.name} className={h.style}>
              {h.name}
            </p>
          ))}
        </div>
        {state.data?.map(r => {
          const clickable = r.playerType === 'paper';
          return (
            <div key={r.memberId} className="flex flex-row py-3 border-b-2 border-mpGrey">
              <div className={`${s.posRow} ${s.commonRow} ${s.border}`}>
                <p className="opacity-60">{r.ranking}</p>
                {getRankingChange(r.rankingChange)}
              </div>
              {clickable ? (
                <PlainButton onClick={() => onClickPaperTipper(r)} className="flex-1">
                  <p className={`underline text-left ${s.commonRow} ${s.border}`}>{r.memberName}</p>
                </PlainButton>
              ) : (
                <p className={`flex-1 ${s.commonRow} ${s.border}`}>{r.memberName}</p>
              )}
              <p className={`w-20 ${s.commonRow} ${s.border} opacity-60 capitalize`}>
                {r.playerType}
              </p>
              <p className={`w-20 ${s.commonRow} opacity-60`}>{r.points}</p>
            </div>
          );
        })}
      </div>
      {state.hasMorePages && (
        <div className="flex-1 flex pt-4 justify-center">
          <SpinnerButton
            onClick={loadMore}
            className="bg-secondary px-6 py-2 uppercase"
            loading={state.loadingPage}
          >
            {i18n.t('general.load_more')}
          </SpinnerButton>
        </div>
      )}
    </>
  );
};

const Section = ({ title, explainer, checked, onChange }) => (
  <div className="border-t border-opacity-10 border-mpGrey py-5">
    <div className="flex flex-row justify-between items-center">
      <p className={s.title}>{title}</p>
      <div className="flex flex-row items-center">
        <p className="mr-3 opacity-30 text-lg">
          {checked ? i18n.t('general.on') : i18n.t('general.off')}
        </p>
        <Toggle onChange={onChange} checked={checked} />
      </div>
    </div>
    {explainer && <p className={s.subTitle}>{explainer}</p>}
  </div>
);

const Approvals = ({ approvals: { id, member }, update }) => {
  const button = (icon, text, color, onClick) => (
    <PlainButton onClick={onClick} className="flex flex-row items-center">
      <span className={`${s.approvalButton} ${color}`}>{icon}</span>
      <p className="text-sm font-semibold opacity-60 underline">{text}</p>
    </PlainButton>
  );
  return (
    <div key={id} className="border-mpGrey border-b-2 flex flex-row py-2">
      <div className="flex-1">
        <p className={`${s.border} underline ${s.commonRow}`}>
          {member.firstName} {member.lastName}
        </p>
      </div>
      <div className="flex flex-row flex-1 justify-around items-center">
        {button('✓', i18n.t('tipping.approve'), 'text-green', () => update(id))}
        {button('✕', i18n.t('tipping.deny'), 'text-primaryRed', () => update(id, 'decline'))}
      </div>
    </div>
  );
};

const Modal = ({ onClose, open, children }) => (
  <MpModal className="max-w-lg border-t-4 border-primary px-10 py-10" onClose={onClose} open={open}>
    <Button className="flex ml-auto p-0" onClick={onClose}>
      <CloseIcon className="h-8 w-8" />
    </Button>
    {children}
  </MpModal>
);

EditPaperTipperModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  selectedTipper: PropTypes.shape().isRequired,
  updateSelectedTipper: PropTypes.func.isRequired,
  navToEditPaper: PropTypes.func.isRequired,
  removePaperTipper: PropTypes.func.isRequired,
};

Modal.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
};

League.propTypes = {
  league: PropTypes.shape(),
  fetchRankings: PropTypes.func.isRequired,
  fetchMembers: PropTypes.func.isRequired,
  updateLeague: PropTypes.func.isRequired,
  updatePendingMember: PropTypes.func.isRequired,
  addPaperTippersAPI: PropTypes.func.isRequired,
  history: historyProps.isRequired,
  removePaperTipperAPI: PropTypes.func.isRequired,
  updatePaperTipperAPI: PropTypes.func.isRequired,
  game: PropTypes.shape(),
};
League.defaultProps = {
  league: null,
  game: {},
};

AddPaperTippersModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  updatePaperTippers: PropTypes.func.isRequired,
};

LeagueTable.propTypes = {
  state: PropTypes.shape({
    data: PropTypes.arrayOf(PropTypes.shape()),
    loadingPage: PropTypes.bool,
    loading: PropTypes.bool,
    hasMorePages: PropTypes.bool,
  }).isRequired,
  onClickPaperTipper: PropTypes.func.isRequired,
  loadMore: PropTypes.func.isRequired,
};

Section.propTypes = {
  title: PropTypes.string.isRequired,
  explainer: PropTypes.string,
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
};

Section.defaultProps = {
  explainer: null,
};

Approvals.propTypes = {
  approvals: PropTypes.shape().isRequired,
  update: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  league: getVenueLeague(state),
  game: getVenueGame(state),
});

const mapDispatchToProps = {
  fetchRankings: fetchLeagueRankings,
  fetchMembers: fetchLeagueMembers,
  updateLeague: updateLeagueVenueThunk,
  updatePendingMember: updatePendingVenueMember,
  addPaperTippersAPI: addVenueLeaguePaperTippers,
  removePaperTipperAPI: removeVenueLeaguePaperTipper,
  updatePaperTipperAPI: updateVenueLeaguePaperTipper,
};

export default connect(mapStateToProps, mapDispatchToProps)(League);

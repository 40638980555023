import React from 'react';
import PropTypes from 'prop-types';
import PlainButton from '../PlainButton/PlainButton';

const TextToggle = ({ active, onToggleOff, onToggleOn, offText, onText, containerClasses }) => {
  const { off, on } = (() => {
    if (active) {
      return {
        off: { btn: 'bg-white border-mpGrey border-r-0', text: 'font-medium opacity-60' },
        on: { btn: 'bg-secondaryLight border-primary', text: 'font-bold' },
      };
    }
    return {
      off: { btn: 'bg-secondaryLight border-primary', text: 'font-bold' },
      on: { btn: 'bg-white border-mpGrey border-l-0', text: 'font-medium opacity-60' },
    };
  })();

  return (
    <div className={`flex sm:max-w-xs ${containerClasses}`}>
      <PlainButton
        className={`p-2.5 lg:py-3.5 flex-1 border-2 rounded-l-full ${off.btn}`}
        onClick={onToggleOff}
      >
        <p className={off.text}>{offText}</p>
      </PlainButton>
      <PlainButton
        className={`p-2.5 lg:py-3.5 flex-1 border-2 rounded-r-full ${on.btn}`}
        onClick={onToggleOn}
      >
        <p className={on.text}>{onText}</p>
      </PlainButton>
    </div>
  );
};

TextToggle.propTypes = {
  active: PropTypes.bool,
  onToggleOff: PropTypes.func.isRequired,
  onToggleOn: PropTypes.func.isRequired,
  offText: PropTypes.string.isRequired,
  onText: PropTypes.string.isRequired,
  containerClasses: PropTypes.string,
};

TextToggle.defaultProps = {
  active: false,
  containerClasses: '',
};

export default TextToggle;

import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18next';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import ProfileHeader from './ProfileHeader';
import InputUpload from '../../components/FileUpload/InputUpload';
import SaveBar from './SaveBar/SaveBar';
import styles from './SaveBar/styles.module.scss';
import uploadFileIcon from './icons/uploadFileIcon.png';
import { getVenue } from '../../store/venue/selectors';
import { venueProps } from '../../utils/customPropTypes';
import { base64DownscaleUpload, getFileTypeFromString } from '../../utils/general';
import { saveVenue } from '../../store/api/venues';
import { updateVenueSuccess } from '../../store/venue/actions';
import SpinnerButton from '../../components/SpinnerButton/SpinnerButton';
import { GATags, fireEvent } from '../../utils/trackingHelper';

// safe to assume a png, since any image file gets converted to png when uploading
const IMG = 'png';
const PDF = 'pdf';

const MenuPage = ({ venue, updateVenue, refreshVenue }) => {
  const [file, setFile] = useState({ type: getFileTypeFromString(venue.menu), data: venue.menu });
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    if (venue.menu) {
      setFile({ type: getFileTypeFromString(venue.menu), data: venue.menu });
    }
  }, [venue.menu]);

  const discardChanges = () => setFile({ type: null, data: null });
  const onDrop = useCallback(
    acceptedFiles => {
      fireEvent(GATags.MENU_ADDED, null, { venueId: venue.id });
      acceptedFiles.forEach(item => {
        if (item.type.includes(`image`)) {
          base64DownscaleUpload(item).then(result => setFile({ type: IMG, data: result }));
        } else {
          const reader = new FileReader();
          reader.readAsDataURL(item);
          reader.onload = e => setFile({ type: PDF, data: e.target.result });
        }
      });
    },
    [venue.id],
  );

  const onUpdate = (add = true) => {
    setIsSaving(true);
    updateVenue({ id: venue.id, menu: add ? file.data : null })
      .then(res => {
        toast.success(t(add ? 'editProfile_menuToastAdd' : 'editProfile_menuToastRemove'));
        refreshVenue(res.getResult());
        return !add && discardChanges();
      })
      .catch(() => {})
      .finally(() => setIsSaving(false));
  };

  return (
    <>
      <ProfileHeader text={t('editProfile.tabs.menu')} />
      <div className="px-4 mb-36 md:mb-32 w-full max-w-lg mx-auto lg:max-w-xl">
        <h3 className="opacity-60 py-4 md:pt-12 lg:pt-16">{t('editProfile_menuSubtitle')}</h3>

        {file.type ? (
          <>
            {file.type === PDF && (
              <iframe
                id="pdfer"
                title={t('editProfile.tabs.menu')}
                className="w-full h-[520px] md:h-[694px] lg:h-[786px] rounded-xl"
                src={file.data}
              />
            )}
            {file.type === IMG && (
              <img
                alt={t('editProfile.tabs.menu')}
                className="mx-auto rounded-xl"
                src={file.data}
              />
            )}
          </>
        ) : (
          <InputUpload
            className="flex flex-col bg-white border border-mpGrey justify-center items-center rounded-lg py-8"
            acceptedFiles="image/*, application/pdf"
            onDrop={onDrop}
          >
            <img src={uploadFileIcon} className="h-12 w-12" alt="" />
            <div className="text-center pt-2">
              <p className="font-bold">{t('editProfile_menuUpload')}</p>
              <p className="text-xs opacity-60">{t('editProfile_menuUploadHelp')}</p>
            </div>
          </InputUpload>
        )}

        {!venue.menu && file.data && (
          <SaveBar
            onSave={onUpdate}
            loading={isSaving}
            saveCopy={t('editProfile_menuSave')}
            onDiscard={discardChanges}
          />
        )}
        {venue.menu && file.data === venue.menu && (
          <div className={styles.banner}>
            <SpinnerButton
              className="w-full md:max-w-xl mx-auto bg-[#FFEBE9] text-lightRed font-semibold rounded-lg py-2 md:py-3"
              loading={isSaving}
              onClick={() => {
                fireEvent(GATags.PROFILE_SAVE, null, { venueId: venue.id });
                onUpdate(false);
              }}
            >
              {t('editProfile_menuRemove')}
            </SpinnerButton>
          </div>
        )}
      </div>
    </>
  );
};

MenuPage.propTypes = {
  venue: venueProps.isRequired,
  updateVenue: PropTypes.func.isRequired,
  refreshVenue: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  venue: getVenue(state),
});

const mapDispatchToProps = {
  updateVenue: saveVenue,
  refreshVenue: updateVenueSuccess,
};

export default connect(mapStateToProps, mapDispatchToProps)(MenuPage);

import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { t } from 'i18next';
import MPModal from '../../../components/MPModal/MPModal';
import routes from '../../../routes/paths';
import PlainButton from '../../../components/PlainButton/PlainButton';
import CloseIcon from '../../../components/Icons/CloseIcon';
import { cdnUrl } from '../../../utils/constants';
import { getDomainInfo } from '../../../utils/environment';
import i18n from '../../../i18n';

const ProfileCompleteModal = ({ open, onClose, onClick }) => (
  <MPModal
    open={open}
    onClose={onClose}
    className="max-w-md md:max-w-lg lg:max-w-xl bg-transparent px-4"
  >
    <div className="relative bg-white rounded-2xl text-center p-8 md:p-16">
      <PlainButton
        className="absolute right-4 top-4 p-2 bg-mpGrey rounded-full outline-none"
        onClick={onClose}
      >
        <CloseIcon className="w-8 h-8" />
      </PlainButton>
      <h1 className="headingFont pb-2 text-4xl md:pt-2 md:text-5xl lg:text-6xl">
        {t('profileComplete_modalTitle')}
      </h1>
      <p className="opacity-80 md:text-xl whitespace-pre-wrap">
        {t('profileComplete_modalSubtitle')}
      </p>
      <img
        src={`${cdnUrl}/onboarding/${getDomainInfo(i18n.resolvedLanguage).profileCompleteImg}`}
        className="w-full max-h-72 p-8 object-contain"
        alt=""
      />
      <Link
        className="block w-full rounded-lg py-4 font-semibold bg-tertiary text-primaryButtonText"
        to={`${routes.home.path}?published=true`}
        onClick={onClick}
        data-test-id="profileCompleted"
      >
        {t('profileComplete_modalCta')}
      </Link>
    </div>
  </MPModal>
);

ProfileCompleteModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default ProfileCompleteModal;

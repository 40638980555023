import React from 'react';

const Sound = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="black">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.9809 6.49617C9.37129 4.27081 11.5193 2.4668 13.7787 2.4668C16.038 2.4668 17.5531 4.27081 17.1627 6.49617L16.2202 11.8687C15.8298 14.094 13.6818 15.8981 11.4225 15.8981C9.16312 15.8981 7.64803 14.094 8.03842 11.8687L8.9809 6.49617ZM13.4959 4.07855C12.1403 4.07855 10.8515 5.16096 10.6173 6.49617L9.67478 11.8687C9.44055 13.2039 10.3496 14.2863 11.7052 14.2863C13.0608 14.2863 14.3496 13.2039 14.5839 11.8687L15.5264 6.49617C15.7606 5.16096 14.8515 4.07855 13.4959 4.07855Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.23026 10.2569L6.94751 11.8687C6.45302 14.6875 8.37213 16.9726 11.234 16.9726C14.0958 16.9726 16.8167 14.6875 17.3111 11.8687L17.5939 10.2569H19.2303L18.9475 11.8687C18.2969 15.5776 14.7168 18.5843 10.9512 18.5843C7.18565 18.5843 4.6605 15.5776 5.31115 11.8687L5.59389 10.2569H7.23026Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.89742 19.9274L10.2744 17.7784H11.9108L11.5338 19.9274H14.8065L14.5238 21.5392H6.61468L6.89742 19.9274H9.89742Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.2672 7.97362H10.0854L10.321 6.63049H12.5028L12.2672 7.97362Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.8901 10.1227H9.70823L9.94386 8.77955H12.1257L11.8901 10.1227Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.5132 12.2716H9.33137L9.56699 10.9285H11.7488L11.5132 12.2716Z"
    />
  </svg>
);

export default Sound;

import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';

const PageSubHeader = ({ children }) => <div className="GlobalPad PageSubHeader">{children}</div>;

PageSubHeader.propTypes = {
  children: PropTypes.node.isRequired,
};

export default PageSubHeader;

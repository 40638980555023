import * as consts from './constants';

export const initialState = {
  isFetching: false,
  offers: [],
  totalOffers: null,
  offer: null,
  isFetchingOffer: false,
  isFetchingMoreOffers: false,
  isSaving: false,
  isUpdating: false,
  competitions: [],
  formErrors: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case consts.FETCH_OFFERS_PENDING:
      return {
        ...state,
        isFetching: true,
      };
    case consts.FETCH_OFFERS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        offers: action.payload.data.result,
        totalOffers: action.payload.data.matches,
      };
    case consts.FETCH_OFFERS_ERROR:
      return {
        ...state,
        isFetching: false,
      };
    case consts.FETCH_MORE_OFFERS_PENDING:
      return {
        ...state,
        isFetchingMoreOffers: true,
      };
    case consts.FETCH_MORE_OFFERS_SUCCESS: {
      const offersCopy = [...state.offers].concat(action.payload);
      return {
        ...state,
        isFetchingMoreOffers: false,
        offers: offersCopy,
      };
    }
    case consts.FETCH_MORE_OFFERS_ERROR:
      return {
        ...state,
        isFetchingMoreOffers: false,
      };
    case consts.FETCH_OFFER_PENDING:
      return {
        ...state,
        isFetchingOffer: true,
      };
    case consts.FETCH_OFFER_SUCCESS:
      return {
        ...state,
        isFetchingOffer: false,
        offer: action.payload,
      };
    case consts.FETCH_OFFER_ERROR:
      return {
        ...state,
        isFetchingOffer: false,
      };
    case consts.CLEAR_OFFER:
      return {
        ...state,
        offer: null,
      };
    case consts.STOP_OFFER_PENDING:
      return {
        ...state,
      };
    case consts.STOP_OFFER_SUCCESS: {
      const copy = [...state.offers];
      const index = copy.findIndex(elm => elm.id === action.payload.id);
      copy.splice(index, 1);
      copy.push(action.payload);
      return {
        ...state,
        offers: copy,
      };
    }
    case consts.STOP_OFFER_ERROR:
      return {
        ...state,
      };
    case consts.CREATE_OFFER_PENDING:
      return {
        ...state,
        isSaving: true,
      };
    case consts.CREATE_OFFER_SUCCESS:
      return {
        ...state,
        formErrors: null,
        isSaving: false,
      };
    case consts.CREATE_OFFER_ERROR:
      return {
        ...state,
        isSaving: false,
        formErrors: action.payload,
      };
    case consts.UPDATE_OFFER_PENDING:
      return {
        ...state,
        isUpdating: true,
      };
    case consts.UPDATE_OFFER_SUCCESS:
      return {
        ...state,
        formErrors: null,
        isUpdating: false,
      };
    case consts.UPDATE_OFFER_ERROR:
      return {
        ...state,
        isUpdating: false,
        formErrors: action.payload,
      };
    case consts.GET_COMPETITIONS_SUCCESS:
      return {
        ...state,
        competitions: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;

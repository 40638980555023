import React from 'react';
import PropTypes from 'prop-types';

const AvatarIcon = ({ className, isActive }) => (
  <svg className={`fill-current ${className}`} viewBox="0 0 24 24" fill="none">
    {isActive ? (
      <>
        <path d="M15.2727 15.2727H9.81812C9.31148 15.2727 9.05816 15.2727 8.84065 15.3003C7.33866 15.4907 5.98335 16.629 5.53626 18.0755C5.37395 18.6006 5.77611 19.0335 6.31383 19.0335H17.4575C17.9952 19.0335 18.5492 18.6006 18.5712 18.0755C18.6316 16.629 17.6756 15.4907 16.2405 15.3003C16.0326 15.2727 15.7793 15.2727 15.2727 15.2727Z" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.9316 14.1982C9.95624 14.1982 9.98124 14.1982 10.0066 14.1982H15.4612C15.4865 14.1982 15.5115 14.1982 15.5362 14.1982C15.9698 14.1981 16.291 14.198 16.5697 14.235C18.4833 14.4889 19.7579 16.0065 19.6773 17.9352C19.6656 18.2161 19.61 18.5325 19.535 18.9596C19.5307 18.9838 19.5264 19.0085 19.522 19.0335L19.3335 20.108H4.06079L4.24929 19.0335C4.25367 19.0085 4.25799 18.9838 4.26224 18.9596C4.33703 18.5325 4.39245 18.2161 4.47928 17.9352C5.07541 16.0065 6.88249 14.4889 8.88514 14.235C9.17685 14.198 9.49805 14.1981 9.9316 14.1982ZM9.62962 16.3472C9.08831 16.3472 8.92289 16.3495 8.79616 16.3656C7.88556 16.481 7.05583 17.1191 6.68878 17.959H17.4595C17.3871 17.1191 16.7813 16.481 15.9112 16.3656C15.7901 16.3495 15.6255 16.3472 15.0842 16.3472H9.62962Z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.21524 7.59605C8.59224 5.21575 10.8515 3.28613 13.2615 3.28613C15.6715 3.28613 17.3195 5.21575 16.9425 7.59605L16.7719 8.67352C16.3949 11.0538 14.1356 12.9834 11.7256 12.9834H11.1801C9.07142 12.9834 7.62938 11.295 7.95926 9.21226L8.21524 7.59605ZM12.9202 5.44109C11.7152 5.44109 10.5856 6.4059 10.3971 7.59605L10.1411 9.21226C9.9997 10.1049 10.6177 10.8285 11.5215 10.8285H12.0669C13.2719 10.8285 14.4015 9.86367 14.59 8.67352L14.7607 7.59605C14.9492 6.4059 14.1252 5.44109 12.9202 5.44109Z"
        />
      </>
    ) : (
      <>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.13704 8.14113C9.49048 5.9096 11.6086 4.10059 13.8679 4.10059C16.1273 4.10059 17.6723 5.9096 17.3189 8.14113L17.1482 9.21861C16.7948 11.4501 14.6767 13.2592 12.4173 13.2592H11.8719C9.91378 13.2592 8.57474 11.6913 8.88106 9.75735L9.13704 8.14113ZM13.6119 5.7168C12.2563 5.7168 10.9855 6.80221 10.7734 8.14113L10.5174 9.75735C10.3525 10.7987 11.0735 11.6429 12.1279 11.6429H12.6733C14.0289 11.6429 15.2998 10.5575 15.5118 9.21861L15.6825 8.14113C15.8946 6.80221 14.9675 5.7168 13.6119 5.7168Z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.008 14.4642H14.3388C15.0766 14.4642 15.6718 14.4642 16.151 14.4969C16.6437 14.5304 17.0729 14.6012 17.4629 14.7718C18.4004 15.1819 19.0722 15.9685 19.3306 16.9585C19.4381 17.3704 19.4408 17.8054 19.3969 18.2973C19.3542 18.7757 19.2611 19.3636 19.1456 20.0923L19.1411 20.121H17.5047C17.6258 19.3569 17.7097 18.8242 17.7469 18.4073C17.7835 17.9977 17.7683 17.7592 17.7208 17.577C17.5658 16.983 17.1627 16.511 16.6002 16.265C16.4278 16.1895 16.1945 16.1373 15.7842 16.1093C15.3666 16.0809 14.8274 16.0804 14.0538 16.0804H10.7811C10.0074 16.0804 9.46808 16.0809 9.04148 16.1093C8.62231 16.1373 8.3725 16.1895 8.17614 16.265C7.53574 16.511 6.98315 16.983 6.63995 17.577C6.53472 17.7592 6.44402 17.9977 6.35084 18.4073C6.25601 18.8242 6.17121 19.3569 6.05018 20.121H4.41382L4.41836 20.0923C4.53378 19.3636 4.62687 18.7757 4.73569 18.2973C4.84759 17.8054 4.98813 17.3704 5.22611 16.9585C5.79812 15.9685 6.71909 15.1819 7.78643 14.7718C8.23048 14.6012 8.6821 14.5304 9.18548 14.4969C9.67502 14.4642 10.2702 14.4642 11.008 14.4642Z"
        />
      </>
    )}
  </svg>
);

AvatarIcon.propTypes = {
  className: PropTypes.string,
  isActive: PropTypes.bool,
};

AvatarIcon.defaultProps = {
  className: '',
  isActive: false,
};

export default AvatarIcon;

export const FETCH_FINANCIALSFORM_DESCRIPTION_SUCCESS = 'financialsForm/fetch/description/success';
export const FETCH_FINANCIALSFORM_DESCRIPTION_PENDING = 'financialsForm/fetch/description/pending';
export const FETCH_FINANCIALSFORM_DESCRIPTION_ERROR = 'financialsForm/fetch/description/error';

export const FETCH_FINANCIALSFORM_VALUES_SUCCESS = 'financialsForm/fetch/values/success';
export const FETCH_FINANCIALSFORM_VALUES_PENDING = 'financialsForm/fetch/values/pending';
export const FETCH_FINANCIALSFORM_VALUES_ERROR = 'financialsForm/fetch/values/error';

export const UPDATE_FINANCIALSFORM_VALUE = 'financialsForm/updateValue/action';
export const DISCARD_FINANCIALSFORM_CHANGES = 'financialsForm/resetValues/action';

export const UPDATE_FINANCIALSFORM_VALUES_PENDING = 'financialsForm/saveValues/pending';
export const UPDATE_FINANCIALSFORM_VALUES_SUCCESS = 'financialsForm/saveValues/success';
export const UPDATE_FINANCIALSFORM_VALUES_ERROR = 'financialsForm/saveValues/error';

import { getServicesEndpoint } from '../utils/environment';
import perm from './permissions';

const routes = {
  home: {
    path: '/home',
    title: 'titles.home',
  },
  footyTipping: {
    path: '/footy-tipping',
  },
  fixtures: {
    path: '/fixtures',
    title: 'titles.fixtures',
    permissionCheck: perm.FIXTURES,
    subRoutes: {
      mySchedule: {
        path: '/fixtures/my-schedule',
        title: 'fixtures_mySchedule',
      },
    },
  },
  marketing: {
    path: '/marketing',
    title: 'titles.marketing',
    subRoutes: {
      postToSocial: {
        path: '/marketing/post-to-social-media',
        title: 'titles.marketing',
        permissionCheck: perm.SOCIAL_MEDIA_POST,
      },
      postPublish: {
        path: '/marketing/post-publish',
        title: 'titles.marketing',
        permissionCheck: perm.GREAT_CONTENT_POST,
      },
      socialSchedule: {
        path: '/marketing/schedule',
        title: 'titles.marketing',
      },
      customiseAssets: {
        path: '/marketing/customise-assets',
        title: 'marketing.customiseAssets',
        permissionCheck: perm.BESPOKE_POSTS,
        subRoutes: {
          competitions: {
            path: '/marketing/customise-assets/competitions',
            title: 'marketing.customiseAssets',
            permissionCheck: perm.BESPOKE_POSTS,
          },
          competitionsEdit: {
            path: '/marketing/customise-assets/competitions/:id/edit',
            permissionCheck: perm.BESPOKE_POSTS,
          },
          colorAssets: {
            path: '/marketing/customise-assets/color',
            title: 'titles.colorAssets',
            permissionCheck: perm.BESPOKE_POSTS,
          },
        },
      },
      integrate: {
        path: '/marketing/integrate',
        title: 'titles_integration',
        subRoutes: {
          apiFeed: {
            path: '/marketing/integrate/apiFeed',
            title: 'integrate_XMLTitle',
            permissionCheck: perm.BESPOKE_WIDGET,
          },
          widget: {
            path: '/marketing/integrate/widget',
            title: 'titles_integrationWidget',
            permissionCheck: perm.BESPOKE_WIDGET,
          },
          calendar: {
            path: '/calendar/integrate',
            title: 'titles.calendarIntegration',
          },
        },
      },
    },
  },
  stats: {
    path: '/stats',
    title: 'titles.stats',
  },
  about: {
    path: '/about',
    title: 'auth.about',
  },
  offers: {
    path: '/offers',
    title: 'titles.offers',
    subRoutes: {
      createOffer: {
        path: '/offers/create',
        title: 'titles.offers',
        permissionCheck: perm.BESPOKE_OFFER,
      },
      editOffer: {
        path: '/offers/:id/edit',
        title: 'titles.offers',
      },
    },
  },
  profile: {
    path: '/profile',
    title: 'titles.profile',
    subRoutes: {
      general: {
        path: '/profile/general',
      },
      photos: {
        path: '/profile/photos',
      },
      favourites: {
        path: '/profile/favourites',
      },
      bookings: {
        path: '/profile/bookings',
      },
      channels: {
        path: '/profile/channels',
      },
      menu: {
        path: '/profile/menu',
      },
    },
  },
  subscriptions: {
    path: '/subscriptions',
    title: 'titles.subscriptions',
  },
  termsAndConditions: {
    path: '/terms-and-conditions',
    title: 'titles.termsAndConditions',
  },
  termsOfService: {
    path: '/terms-of-service',
    title: 'titles.termsOfService',
  },
  privacyPolicy: {
    path: '/privacy-policy',
    title: 'titles.privacyPolicy',
  },
  cookiePolicy: {
    path: '/cookies-policy',
    title: 'titles.cookiePolicy',
  },
  resubscriptions: {
    path: '/resubscriptions',
    title: 'titles.subscriptions',
  },
  signupV2Stage2: {
    path: '/sign-up/2',
    title: 'auth.signup',
  },
  signupV2Stage3: {
    path: '/sign-up/3',
    title: 'auth.signup',
  },
  multipleVenues: {
    path: '/multiple-venues',
    title: 'signup_multipleVenues',
  },
  promotionalHub: {
    path: '/marketing/promotional-hub',
    title: 'titles_promoHub',
    subRoutes: {
      assetGroup: {
        path: '/marketing/promotional-hub/:id',
        title: 'titles_promoHub',
      },
    },
  },
  account: {
    path: '/account',
    title: 'titles.account',
    subRoutes: {
      settings: {
        path: '/account/settings',
        title: 'titles_settings',
      },
      billing: {
        path: '/account/billing',
        title: 'titles_billing',
      },
      reimbursement: {
        path: '/account/reimbursement',
        title: 'titles_reimbursement',
      },
    },
  },
};

export default routes;

export const publicRoutes = {
  landing: '/',
  signUp: '/sign-up',
  signUpStage: '/sign-up/:stage',
  pricing: '/pricing',
  login: '/login',
  privacyPolicy: '/privacy-policy',
  activate: '/activate',
  activateNewUser: '/activate-new-user',
  termsAndConditions: '/terms-and-conditions',
  termsOfService: '/terms-of-service',
  cookiePolicy: '/cookies-policy',
  about: '/about',
  signupV2: '/sign-up',
};

export const upgradeModal = {
  [routes.fixtures.subRoutes.mySchedule.path]: {
    title: 'upgradeModal_scheduleTitle',
    text: 'upgradeModal_scheduleText',
    image: 'fixture-schedule.png',
  },
  [routes.marketing.subRoutes.postToSocial.path]: {
    title: 'upgradeModal_createAssetsTitle',
    text: 'upgradeModal_createAssetsText',
    image: 'marketing-create.png',
  },
  [routes.marketing.subRoutes.socialSchedule.path]: {
    title: 'upgradeModal_viewAssetsTitle',
    text: 'upgradeModal_viewAssetsText',
    image: 'marketing-schedule.png',
  },
  [routes.marketing.subRoutes.customiseAssets.path]: {
    title: 'upgradeModal_customiseAssetsTitle',
    text: 'upgradeModal_customiseAssetsText',
    image: 'marketing-create.png',
  },
  [routes.marketing.subRoutes.integrate.path]: {
    title: 'upgradeModal_integrationTitle',
    text: 'upgradeModal_integrationText',
    image: 'marketing-integration.png',
  },
  [routes.stats.path]: {
    title: 'upgradeModal_statsTitle',
    text: 'upgradeModal_statsText',
    image: 'stats.png',
  },
  [routes.offers.path]: {
    title: 'upgradeModal_offersTitle',
    text: 'upgradeModal_offersText',
    image: 'offers.png',
  },
  [routes.profile.path]: {
    title: 'upgradeModal_profileTitle',
    text: 'upgradeModal_profileText',
    image: 'profile.png',
  },
};

export const pdfPath = venueId => `${getServicesEndpoint()}venues/${venueId}/fixtures/pdf`;

import React from 'react';

const Wifi = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="black">
    <path d="M11.9793 17.8048C11.885 18.3998 11.3202 18.8822 10.7177 18.8822C10.1152 18.8822 9.70323 18.3998 9.79748 17.8048C9.89173 17.2097 10.4566 16.7273 11.059 16.7273C11.6615 16.7273 12.0736 17.2097 11.9793 17.8048Z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.0164 16.9967C10.5645 16.9967 10.1409 17.3585 10.0702 17.8048C9.99952 18.2511 10.3085 18.6129 10.7604 18.6129C11.2123 18.6129 11.6359 18.2511 11.7066 17.8048C11.7773 17.3585 11.4683 16.9967 11.0164 16.9967ZM9.52475 17.8048C9.64257 17.0609 10.3486 16.4579 11.1017 16.4579C11.8548 16.4579 12.3698 17.0609 12.252 17.8048C12.1342 18.5486 11.4282 19.1516 10.6751 19.1516C9.92195 19.1516 9.40694 18.5486 9.52475 17.8048Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.0728 14.6799C9.63044 14.8499 9.21347 15.099 8.84573 15.4131L7.86966 14.2703C8.41311 13.8061 9.02933 13.4379 9.68312 13.1867C10.3369 12.9355 11.0155 12.8062 11.6801 12.8062C12.3447 12.8062 12.9823 12.9355 13.5565 13.1867C14.1307 13.4379 14.6303 13.8061 15.0267 14.2703L13.6886 15.4131C13.4204 15.099 13.0824 14.8499 12.6938 14.6799C12.3052 14.51 11.8738 14.4225 11.4241 14.4225C10.9744 14.4225 10.5152 14.51 10.0728 14.6799Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.24613 11.5124C8.35529 11.8547 7.51566 12.3563 6.77516 12.9888L5.79909 11.846C6.7153 11.0634 7.75418 10.4427 8.85642 10.0192C9.95865 9.5957 11.1027 9.37773 12.2231 9.37773C13.3435 9.37773 14.4185 9.59571 15.3866 10.0192C16.3547 10.4427 17.1969 11.0634 17.8653 11.846L16.5272 12.9888C15.987 12.3563 15.3063 11.8547 14.5239 11.5124C13.7415 11.1701 12.8727 10.994 11.9671 10.994C11.0616 10.994 10.137 11.1701 9.24613 11.5124Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.41943 8.34486C7.08015 8.85943 5.81785 9.61366 4.70459 10.5645L3.72852 9.42163C5.01749 8.32074 6.47904 7.44746 8.02972 6.85167C9.58039 6.25587 11.1898 5.94922 12.7661 5.94922C14.3424 5.94922 15.8547 6.25587 17.2167 6.85167C18.5786 7.44747 19.7635 8.32074 20.7038 9.42163L19.3657 10.5645C18.5536 9.61366 17.5302 8.85943 16.354 8.34486C15.1777 7.83028 13.8716 7.56544 12.5101 7.56544C11.1487 7.56544 9.75871 7.83028 8.41943 8.34486Z"
    />
  </svg>
);

export default Wifi;

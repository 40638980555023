import React, { useEffect, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import i18n from 'i18next';

const InfiniteScroll = ({ hasMore, loading, fetchMore, children }) => {
  const loader = useRef(null);
  const observer = useRef(null);

  const handleObserver = useCallback(
    entities => {
      const target = entities[0];
      if (target.isIntersecting && hasMore && !loading) {
        fetchMore();
      }
    },
    [fetchMore, hasMore, loading],
  );

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: '20px',
      threshold: 1.0,
    };

    if (observer.current) {
      observer.current.disconnect();
    }

    observer.current = new IntersectionObserver(handleObserver, options);

    if (loader.current) {
      observer.current.observe(loader.current);
    }
  }, [handleObserver]);

  return (
    <>
      {children}
      <div className="loading" ref={loader} />
      {loading && <p className="text-center mb-20">{i18n.t('fixtures.loadingMore')}</p>}
    </>
  );
};

InfiniteScroll.propTypes = {
  hasMore: PropTypes.bool.isRequired,
  fetchMore: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default InfiniteScroll;

import { publicRoutes } from '../routes/paths';

export const cdnUrl = 'https://cdn.internal.matchpint.cloud/pogba';

export const unprotectedLinks = () => [
  { path: publicRoutes.pricing, text: 'titles.pricing' },
  { path: publicRoutes.signUp, text: 'auth.signup' },
  { path: publicRoutes.login, text: 'auth.login' },
];

export const assetTypes = { comp: 'competition', color: 'color' };

export const GoogleMapsAPIKey = 'AIzaSyASNYYlWnQuhzqreWQvRtr_4xhVfkVWFXw';
export const AppcuesId = '132288';

export const newUserAppCuesFlow = '97099db8-0f36-400e-bbe6-e71987f44db2';

export const existingUserAppCuesFlow = 'd56be581-233a-4bbf-adfb-33cf90c741fb';

export const VENUE_STATUS = {
  MATCHPINT_ONLY: -2,
  DISABLES: -1,
  INACTIVE: 0,
  ACTIVE: 1,
  END_REGAIN_ACCESS_IF_PAYMENT: 2,
  END_FORMERLY_PART_OF_GROUP: 3,
  HIDDEN_MASTER_PUB: 4,
  LEAD: 5,
  ACTIVATION_REQUIRED: 6,
};

export const VENUE_ACCESS_LEVEL = {
  NO_ACTIVITY: 'NO_ACTIVITY',
  NO_BASIC_INFO: 'NO_BASIC_INFO',
};

export const supportedLocales = [
  {
    localeKey: 'settings.locale.en',
    locale: 'en',
    localeId: 1,
  },
  {
    localeKey: 'settings.locale.en-au',
    locale: 'en-au',
    localeId: 2,
  },
  {
    localeKey: 'settings.locale.fr',
    locale: 'fr',
    localeId: 3,
  },
  {
    localeKey: 'settings.locale.en-ie',
    locale: 'en-ie',
    localeId: 4,
  },
  {
    localeKey: 'settings.locale.de',
    locale: 'de',
    localeId: 5,
  },
  {
    localeKey: 'settings.locale.en-us',
    locale: 'en-us',
    localeId: 7,
  },
  {
    localeKey: 'settings.locale.en-nz',
    locale: 'en-nz',
    localeId: 8,
  },
];

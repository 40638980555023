import Cookies from 'js-cookie';
import { getCookieDomain } from './environment';

const defaultAttributes = {
  domain: getCookieDomain(),
};

export function MpCookieGet(name) {
  return Cookies.get(name);
}

export function MpCookieSet(name, value, attributes = {}) {
  const cookieAttributes = { ...defaultAttributes, ...attributes };
  return Cookies.set(name, value, cookieAttributes);
}

/**
 * Removes Cookie from the browser.
 * @param name of the attribute in Cookie.
 * @param attributes Optional attributes ex : {path: '/q', domain: 'qwew'}, see more here: https://github.com/js-cookie/js-cookie
 * @return {*}
 */
export function MpCookieRemove(name, attributes = {}) {
  const cookieAttributes = { ...defaultAttributes, ...attributes };
  return Cookies.remove(name, cookieAttributes);
}

const obj = { MpCookieGet, MpCookieSet, MpCookieRemove };

export default obj;

import React from 'react';
import { withRouter, useLocation } from 'react-router-dom';
import i18n, { t } from 'i18next';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { routes } from '../../routes/routeConfig';
import { getVenueGame } from '../../store/venue/selectors';
import { getDomainInfo } from '../../utils/environment';
import PintIcon from '../Icons/PintIcon';
import { CustomNavLink, getRouteData } from './Shared';

const MainLinks = ({ venueGame }) => {
  const { pathname } = useLocation();
  const { menu } = getDomainInfo(i18n.resolvedLanguage);
  const mainLinks = getRouteData(menu);
  const settingsLinks = getRouteData(['profile', 'account']);

  return (
    <nav className="mt-4">
      {Object.keys(mainLinks).map(key => {
        const { path, title, Icon } = mainLinks[key];
        const isHome = path === '/home' && pathname === '/';
        return (
          <CustomNavLink
            key={key}
            path={path}
            additionalMatch={isHome}
            title={t(title)}
            Icon={Icon}
          />
        );
      })}

      {/* SORRY - this is a crappy hack for CUB */}
      {Boolean(venueGame) && (
        <CustomNavLink path={routes.footyTipping.path} title={venueGame?.name} Icon={PintIcon} />
      )}

      {Object.keys(settingsLinks).map(key => {
        const { path, title, Icon } = settingsLinks[key];
        return <CustomNavLink key={key} path={path} title={t(title)} Icon={Icon} />;
      })}
    </nav>
  );
};

MainLinks.propTypes = {
  venueGame: PropTypes.shape(),
};

MainLinks.defaultProps = {
  venueGame: null,
};

const mapStateToProps = state => ({
  venueGame: getVenueGame(state),
});

export default withRouter(connect(mapStateToProps)(MainLinks));

import React from 'react';
import PropTypes from 'prop-types';

const MinusIcon = ({ className }) => (
  <svg viewBox="0 0 24 24" className={`fill-current ${className}`}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.7681 12.9678H4.58627L4.96327 10.8188H19.1451L18.7681 12.9678Z"
    />
  </svg>
);

MinusIcon.propTypes = {
  className: PropTypes.string,
};

MinusIcon.defaultProps = {
  className: '',
};

export default MinusIcon;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { t } from 'i18next';
import { connect } from 'react-redux';
import { logoutAction } from '../../../store/auth/actions';
import LogoutIcon from '../../Icons/LogoutIcon';

const LogoutButton = ({ logout }) => {
  const [hover, setHover] = useState(false);
  const onHover = () => setHover(prev => !prev);
  return (
    <NavLink
      onClick={logout}
      onMouseEnter={onHover}
      onMouseLeave={onHover}
      className="flex gap-4 text-white opacity-60 border-t border-white/10 px-6 py-4 hover:opacity-100 hover:font-bold"
      to="/"
    >
      <LogoutIcon className="h-6 w-6" isActive={hover} />
      {t('auth.logout')}
    </NavLink>
  );
};

LogoutButton.propTypes = {
  logout: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  logout: logoutAction,
};

export default connect(null, mapDispatchToProps)(LogoutButton);

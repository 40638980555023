import React from 'react';
import PropTypes from 'prop-types';
import i18n from '../../../i18n';
import MPModal from '../../../components/MPModal/MPModal';
import CloseIcon from '../../../components/Icons/CloseIcon';
import Button from '../../../components/Button/Button';

const s = {
  btn: 'w-full py-3 font-semibold text-sm',
};

const DeletePrizeModal = ({ open, close, confirm, applyToText }) => (
  <MPModal className="border-t-4 border-secondary p-10 sm:w-96" onClose={close} open={open}>
    <Button className="fixed top-5 right-6" onClick={close}>
      <CloseIcon className="w-5 h-5" />
    </Button>
    <h1 className="headingFont text-6xl mt-8">{i18n.t('tipping.prizes.delete')}</h1>
    <p className="mt-4 mb-8">
      {i18n.t('tipping.prizes.deleteModal.text')}
      <span className="font-bold lowercase"> {i18n.t(applyToText)}?</span>
    </p>
    <Button className={`${s.btn} bg-lightRed text-white`} onClick={confirm}>
      {i18n.t('tipping.prizes.deleteModal.confirm')}
    </Button>
    <Button className={`${s.btn} bg-mpGrey mt-3`} onClick={close}>
      {i18n.t('tipping.prizes.deleteModal.cancel')}
    </Button>
  </MPModal>
);

DeletePrizeModal.propTypes = {
  open: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
  confirm: PropTypes.func.isRequired,
  applyToText: PropTypes.string.isRequired,
};

export default DeletePrizeModal;

import ReactGA4 from 'react-ga4';
import { track } from '@amplitude/analytics-browser';

// fireEvent(string, string, {key: string})
export const fireEvent = (category, action, amplitudeData = {}) => {
  const tagName = category && action ? `${category} ${action}` : category || action;
  // amplitude
  track(tagName, amplitudeData);
  // ga4
  ReactGA4.event(tagName, amplitudeData);
};

export const GATags = {
  LOGOUT: 'Logout',
  LOGIN: 'Login',
  UPDATE_SM_POST: 'Update Social Media Post',
  CREATE_SM_POST: 'Create Social Media Post',
  SELECT_MEDIUM: 'Select SM post Medium',
  SELECT_NB_FIXTURES: 'Select nb fixtuers',
  PRINTER_FRIENDLY: 'Toggle Printer Friendly',
  UPDATE_CONTENT_POST: 'Update Content Post',
  CREATE_CONTENT_POST: 'Create Content Post',
  DELETE_SM_POST: 'Delete Social Media Post',
  DOWNLOAD_SM_POSTER: 'Download Social Media Poster',
  CLICK_CUSTOMISE: 'Clicks Customise',
  CLICK_SCHEDULE: 'Clicks Schedule',
  SAVE_CUSTOMISED_TEMPLATE: 'Save Customised Template',
  SAVE_CUSTOMISED_COMPETITION_TEMPLATE: 'Save Customised Competition Template',
  CREATE_OFFER: 'Create Offer',
  UPDATE_OFFER: 'Update Offer',
  CREATE_OWN_OFFER: 'Click Create Own Offer',
  UPDATE_FIELD: 'Update Field',
  EDIT_PROFILE_GENERAL: 'Edit Profile General',
  CONNECT_TWITTER_STARTED: 'Connect Twitter Started',
  CONNECT_TWITTER_COMPLETED: 'Connect Twitter Completed',
  DISCONNECT_TWITTER: 'Disconnect Twitter',
  CONNECT_FACEBOOK_STARTED: 'Connect Facebook Started',
  CONNECT_FACEBOOK_COMPLETED: 'Connect Facebook Completed',
  DISCONNECT_FACEBOOK: 'Disconnect Facebook',
  COMPLETED_STEP: step => `Completed Step ${step}`,
  OPENING_HOURS: 'Set Opening Hours',
  DESCRIPTION: 'Set Description',
  UPLOAD_PHOTO: 'Upload Photo',
  UPLOAD_SUCCESS: 'Upload Success',
  UPLOAD_FAIL: 'Upload Fail',
  FORGOT_PASSWORD: 'Forgot Password',
  SET_POSTCODE: 'set postcode',
  TOGGLE_CHANNEL: 'Toggle Channel',
  SUBMIT: 'Submit',
  SIGN_UP: 'Sign Up',
  SIGNUP_INTERESTED_IN: 'Sign Up Interested In',
  CALENDAR_OUTLOOK: 'Outlook instrunctions',
  CALENDAR_GOOGLE: 'Google instrunctions',
  CALENDAR_APPLE: 'Apple instrunctions',
  CALENDAR_URL_COPY: 'Calendar URL copied to clipboard',
  CREATE_ASSETS: 'Click create assets and posters',
  CHANGE_BACKGROUND: 'Change Background Color',
  CHANGE_FONT: 'Changes font title',
  CHANGE_SCALE: 'Changes scale',
  CHANGE_COPY: 'Changes font copy',
  CHANGE_COLOUR: 'Changes colour',
  UPLOAD_IMAGE: 'Uploads theme image',
  DELETE_IMAGE: 'Deletes theme image',
  UPLOAD_LOGO: 'Uploads logo',
  DELETE_LOGO: 'Deletes logo',
  ADD_HEADLINE: 'Adds headline text',
  DISCARD_CHANGES: 'Discard Changes',
  EDIT_POST: 'Back To Edit',
  CHOSE_SCHEDULE: 'Chose Post Time',
  CLICKS_POST: 'Clicks Post',
  VIEW_SCHEDULE: 'View Post Schedule',
  PUBLISH_TO_WEBSITE: 'Clicked publish to website',
  USE_WIDGET: 'Clicked use widget',
  INTEGRATE_XML: 'Clicked integrate XML Feed',
  COPIED_XML_LINK: 'Copied XML link in XML page',
  COPIED_XML_STRUCTURE: 'Copied XML structure feed',
  COPIED_WIDGET_CODE: 'Copied widget code',
  SUCCESSFULLY_INTEGRATED_WIDGET: 'Successfully integrated widget',
  CLICKED_COMPETITION: 'Clicked on competition',
  CLICKED_COMPETITION_SPECIFIC: 'Clicked on specific competition',
  CHOSE_FORMAT: 'Clicked on format',
  SETTINGS_COG: 'Clicked settings cog',
  SELECTED_TIER: 'Selected tier',
  PROMO_CODE: 'Added promo code',
  FREQUENCY_YEARLY: 'Changed frequency to yearly',
  FREQUENCY_QUARTERLY: 'Changed frequency to quarterly',
  FINANCIAL_DETAILS: 'Update Financial Details',
  TIER_CONTINUE: 'Clicked continue with tier',
  UPDATE_INFO: 'Updated venue Info',
  UPDATE_CHANNELS: 'Updated Channels',
  SEARCH_CHANNELS: 'Search Channels',
  ADD_FAVE_TEAM: 'Add a Favourite Team',
  VIEW_PAGE: 'Viewed Page',
  USE_PT_SEARCH: 'Use Paper Tipper Search',
  ADD_PAPER_TIPPER: 'Clicks Add Paper Tipper',
  DOWNLOAD_TABLE: 'Downloads League Table',
  ANOTHER_PT: 'Clicks Add another paper tipper',
  SAVE_PT: 'Clicks Save paper tipper',
  VIEW_PRIZES: 'View Prizes',
  EDIT_PRIZE: 'Edits Prize',
  APPLY_TO: 'Applies to',
  ADD_PRIZE: 'Adds or Edits Prize',
  DELETE_PRIZE: 'Deletes Prize',
  CHOOSE_FORMAT: 'Chooses Format',
  CHOOSE_MEDIUM: 'Chooses medium',
  DOWNLOAD_ASSET: 'Download social media asset',
  SCHEDULE: 'Click Schedule and Post',
  SELECT_ROUND: 'Select a round',
  SEARCH_TIPPER: 'Search For A Tipper',
  SET_SCORES: 'Set paper tipper Score',
  SAVE_SCORE: 'Save Paper tipper score',
  CALENDAR_SYNC: 'Click Calendar Sync',
  FILTERS_BUTTON: 'Clicked button to open filters modal',
  MY_SCHEDULE_BUTTON: 'Clicked my scheduled fixtures button',
  OPEN_HOURS_TOGGLE: 'Clicked during open hours toggle',
  EXCEL: 'Download scheduled fixtures excel',
  PDF: 'Download scheduled fixtures pdf',
  TO_MARKETING_PAGE: 'Navigated to marketing page',
  UPDATE_FIXTURE: 'Updated fixture',
  SAVE_FIXTURES: 'Confirmed fixtures updates',
  UPDATE_FIXTURE_FEATURE: 'Updated Fixture Feature',
  ADDED_FILTER_FROM_SEARCH: 'Added filter from search',
  SEARCH_BY_TERM: 'Clicked search by term',
  REMOVE_FILTER: 'Removed filter',
  ADD_FILTER: 'Added filter',
  FILTER_BY_FROM_DATE: 'From date selected',
  FILTER_BY_TO_DATE: 'To date selected',
  FILTER_BY_DATE: 'Date selected from open hours selector',
  RESET_FILTERS: 'Removed all filters',
  APPLY_FILTERS: 'Applied all filters from modal',
  CHANNEL_UPDATED: 'channel_updated',
  SAVE_CHANNELS: 'Channels saved',
  SHOW_FIXTURE_FEATURES: 'Expanded to show fixture features on mobile',
  FETCH_MORE_FILTERS: 'Requested more of specific filter',
  NO_FIXTURES: 'No fixtures found',
  FIXTURE_LIMIT_MODAL_OPEN: 'Fixture limit modal open',
  FIXTURE_LIMIT_MODAL_UPGRADE: 'Fixture limit modal Clicked Upgrade',
  SHOW_ALL_FIXTURES: 'Show all fixtures',
  POPULAR_FIXTURES_FILTER_ON: 'Big fixtures filter on',
  FAVOURITES_FIXTURES_FILTER_ON: 'My favourite fixtures filter on',
  SELECT_ALL_PRESSED: 'Select all filtered fixtures button pressed',
  SELECT_ALL_MODAL_ACCEPT: 'Select all filtered fixtures modal accept',
  SELECT_ALL_MODAL_CANCEL: 'Select all filtered fixtures modal cancel',
  UPGRADE_MODAL: 'Clicked choose plan',
  EMAIL: 'user_email_continue',
  STEP: 'user_step',
  ATTEMPT: 'user_attempt',
  SUCCESS: 'user_success',
  SEE_LIVE_PROFILE: 'see_live_profile',
  VENUE_NUMBER: 'venue_number',
  VENUE_NUMBER_CONTACTUS: 'venue_number_contactus',
  VENUE_INTERESTED: 'venue_interested',
  VENUE_NAME_CONTINUE: 'venue_name_continue',
  VENUE_ADDED_ADDRESS_LOOKUP: 'venue_added_address_lookup',
  VENUE_ADDED_ADDRESS_MANUAL: 'venue_added_address_manual',
  VENUE_ADDRESS_CONTINUE: 'venue_address_continue',
  VENUE_CREATION_COMPLETE: 'venue_creation_complete',
  ONBOARDING_WELCOME_MODAL: 'onboarding_welcome_modal',
  VSO_KEY_INFO_EDIT: 'vso_key_info_edit',
  VSO_CLOSED: 'vso_closed',
  VSO_BACK: 'vso_back',
  VSO_SKIP: 'vso_skip',
  VSO_CONTINUE: 'vso_continue',
  VSO_GET_STARTED: 'vso_get_started',
  VSO_PROFILE_COMPLETED_CLOSE: 'vso_profile_completed_close',
  FACILITES_SELECTED: 'facilites_selected',
  PHOTO_UPLOAD: 'photo_upload',
  PHOTO_GOOGLE: 'photo_google',
  GOOGLE_PHOTO_ADDED: 'google_photo_added',
  GOOGLE_PHOTO_REMOVED: 'google_photo_removed',
  PHOTO_EDIT: 'photo_edit',
  PHOTO_MAKE_PROFILE_PIC: 'photo_make_profile_pic',
  PHOTO_MOVE_UP: 'photo_move_up',
  PHOTO_MOVE_DOWN: 'photo_move_down',
  PHOTO_DELETE: 'photo_delete',
  PHOTO_UPLOAD_SMALL: 'photo_upload_small',
  PHOTO_GOOGLE_SMALL: 'photo_google_small',
  PHOTO_ERROR: 'photo_error',
  HOME_PROFILE_VIEW: 'home_profile_view',
  HOME_PROFILE_EDIT: 'home_profile_edit',
  HOME_FIXTURES_CTA: 'home_fixtures_cta',
  HOME_FIXTURES_SCHEDULE: 'home_fixtures_schedule',
  HOME_STATS_SEEALL: 'home_stats_seeAll',
  PREFERENCES_CLICK: 'fixture_preferences_click',
  PREFERENCES_CHANGE: 'fixture_preferences_change',
  PREFERENCES_SAVE: 'fixture_preferences_save',
  PROFILE_SAVE: 'profile_clicked_save_changes',
  PROFILE_DISCARD: 'profile_clicked_discard_changes',
  PROFILE_SECTION: 'profile_clicked_section',
  PROFILE_VIEW: 'profile_clicked_view',
  FAVOURITE_UPDATED: 'favourite_updated',
  BOOKING_METHOD: 'booking_method_clicked',
  MENU_ADDED: 'menu_file_added',
  PROMOHUB_OPEN_UPLOAD_MODAL: 'promohub_open_upload_modal',
  PROMOHUB_UPLOAD_LOGO: 'promohub_upload_logo',
  PROMOHUB_UPLOAD_ERROR: 'promohub_upload_error',
  PROMOHUB_FILTER_CLICKED: 'promohub_filter_clicked',
  PROMOHUB_TIPS_CLICKED: 'promohub_tips_clicked',
  PROMOHUB_DOWNLOAD: 'promohub_download',
  PROMOHUB_PREVIEW_CLICKED: 'promohub_preview_clicked',
  PROMOHUB_SHOW_LOGO: 'promohub_show_logo',
};

export const GACategories = {
  SOCIAL_MEDIA_CONTENT: 'Social Media Content',
  SOCIAL_MEDIA_ACCOUNT: 'Social Media Account',
  CUSTOM_ASSET: 'Edit Custom Asset',
  LOGIN: 'Login',
  SIGN_UP: 'Sign up',
  PROFILE: 'Profile',
  OFFER: 'Offers',
  STATS: 'Stats',
  FIXTURES: 'Fixtures',
  LANDING_PAGE: 'Landing Page',
  TIER: 'Tier',
  CALENDAR_SYNC: 'Calendar Sync',
  FOOTY_TIPPING: 'Footy Tipping',
  SETTINGS: 'Settings',
  UPGRADE: 'Upgrade Modal',
  ONBOARDING: page => `Onboarding_${page}`,
  SIGN_UP_V2: 'signup_',
  FACILITIES: 'facilities',
  FAVOURITES: 'favourites',
  PHOTOS: 'photos',
};

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18next';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import AssetGroupTiles from './AssetGroupTiles';
import LogoUpload from './LogoUpload';
import PlainButton from '../../components/PlainButton/PlainButton';
import { genericRequest } from '../../store/api/request';
import { getVenueID } from '../../store/venue/selectors';
import UploadIcon from '../../components/Icons/UploadIcon';
import EditIcon from '../../components/Icons/EditIcon';
import { DarkLogoExample, LightLogoExample } from './common';
import { fireEvent, GATags } from '../../utils/trackingHelper';
import BackIcon from '../../components/Icons/BackIcon';
import InfoIcon from '../../components/Icons/InfoIcon';
import CogIcon from '../../components/Icons/CogIcon';
import history from '../../routes/history';
import styles from './AssetGroupPage.module.scss';
import MPModal from '../../components/MPModal/MPModal';
import CloseIcon from '../../components/Icons/CloseIcon';
import routes from '../../routes/paths';

const BetaModal = ({ open, close }) => (
  <MPModal open={open} onClose={close} className="max-w-md text-center pt-16 rounded-2xl ">
    <PlainButton
      className="flex absolute right-4 top-4 p-2 bg-mpGrey rounded-full outline-none"
      onClick={close}
    >
      <CloseIcon className="w-8 h-8" />
    </PlainButton>
    <div className="px-8 pb-8 flex flex-col">
      <CogIcon className="h-20 my-4" />
      <p className="headingFont text-4xl md:text-5xl pb-2">{t('betaModa.title')}</p>
      <p className="opacity-60 pb-4">{t('betaModa.subtitle')}</p>
      <Link
        className="bg-mpGrey rounded-lg py-3 font-semibold"
        onClick={close}
        to={`${routes.promotionalHub.path}?appcue=23db08ec-a0e7-471c-959c-6ab8dcb4e26c `}
      >
        {t('betaModa.buttonText')}
      </Link>
    </div>
  </MPModal>
);

const PromotionalHub = ({ request, venueID }) => {
  const [logoModalOpen, setLogoModalOpen] = useState(false);
  const [logos, setLogos] = useState(null);
  const [betaModalOpen, setBetaModalOpen] = useState(false);
  const onModalChange = () => setLogoModalOpen(!logoModalOpen);

  useEffect(() => {
    request(`venues/${venueID}/logos`).then(r => setLogos(r.getResult()));
  }, [request, venueID]);

  const { logoImageDark, logoImageLight } = logos || {};

  const hasBothImages = logoImageDark && logoImageLight;

  const onClick = () => {
    fireEvent(GATags.PROMOHUB_OPEN_UPLOAD_MODAL, null, {
      venueID,
      edit: Boolean(hasBothImages),
    });
    onModalChange();
  };

  return (
    <>
      <BetaModal open={betaModalOpen} close={() => setBetaModalOpen(false)} />
      <div className={styles.header}>
        <PlainButton className="flex items-center" onClick={history.goBack}>
          <BackIcon className="w-6 h-6 sm:w-8 sm:h-8" />
          <p className="text-xl font-semibold ml-2 hidden sm:flex">{t('general_back')}</p>
        </PlainButton>
        <h2 className="headingFont text-2xl sm:text-4xl flex-1 text-center truncate px-4">
          {t('titles_promoHub')}
        </h2>
        <PlainButton
          className="flex items-center text-white bg-blue rounded-full px-2 py-1.5"
          onClick={() => setBetaModalOpen(true)}
        >
          <InfoIcon className="w-5 h-5" />
          <p className="text-xs font-semibold ml-1 hidden sm:flex">{t('general_beta')}</p>
        </PlainButton>
      </div>
      <div className="mt-8 px-4 md:px-6 lg:px-10">
        <div className="md:border-b border-mpGrey pb-6 lg:pb-8 md:flex">
          <div className="md:border-r border-mpGrey pr-4 relative">
            <PlainButton
              className={`absolute top-0 right-0 flex rounded-full py-2 px-4 border border-mpGrey items-center ${
                hasBothImages ? 'flex md:hidden' : 'hidden'
              }`}
              onClick={onClick}
            >
              <EditIcon className="mr-2 h-6" />
              <p className="font-medium capitalize">{t('general.edit')}</p>
            </PlainButton>
            <p className="font-semibold text-xl pb-2">{t('promoHub_yourLogos')}</p>
            <p
              className={`text-sm sm:text-base opacity-60 pb-4 ${
                hasBothImages ? 'hidden md:block' : ''
              }`}
            >
              {t('promoHub_yourLogos_subtitle')}
            </p>
            <PlainButton
              className={`flex rounded-full py-3 min-w-[300px] justify-center border border-mpGrey items-center ${
                hasBothImages ? 'hidden md:flex' : ''
              }`}
              onClick={onClick}
            >
              {hasBothImages ? (
                <EditIcon className="mr-2 h-6" />
              ) : (
                <UploadIcon className="mr-2 h-6" />
              )}
              <p className="font-medium capitalize">
                {t(hasBothImages ? 'general.edit' : 'promoHub_upload')}
              </p>
            </PlainButton>
          </div>
          <div
            className={`${
              hasBothImages ? 'flex' : 'hidden sm:flex'
            } flex-1 pt-4 md:pt-0 md:pl-6 lg:pl-8`}
          >
            <LightLogoExample className="flex-1 mr-3 max-w-[250px]" logo={logoImageLight} />
            <DarkLogoExample className="flex-1 max-w-[250px]" logo={logoImageDark} />
          </div>
        </div>
        <p className="font-semibold text-xl mb-4 pt-4">{t('promoHub_library')}</p>
        <AssetGroupTiles />
      </div>
      {logos !== null && (
        <LogoUpload
          open={logoModalOpen}
          onClose={onModalChange}
          logos={logos}
          onSave={tempLogos => setLogos(tempLogos)}
        />
      )}
    </>
  );
};

PromotionalHub.propTypes = {
  request: PropTypes.func.isRequired,
  venueID: PropTypes.number.isRequired,
};

BetaModal.propTypes = {
  open: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  venueID: getVenueID(state),
});

const mapDispatchToProps = {
  request: genericRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(PromotionalHub);

import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';
import Spinner from '../Spinner/Spinner';

const SecondaryButton = ({
  id,
  disabled,
  label,
  className,
  style,
  bgColor,
  textColor,
  loading,
  uppercase,
  ...rest
}) => (
  <button
    id={id}
    disabled={disabled || loading}
    className={`${className} Secondary_button${disabled ? '' : '_enabled'}`}
    type="button"
    style={{
      ...style,
      backgroundColor: bgColor,
    }}
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...rest}
  >
    {!loading ? (
      <p
        style={{ color: textColor }}
        className={`Secondary_button_text${disabled ? '' : '_enabled'} ${
          uppercase ? 'uppercase' : ''
        }`}
      >
        {label}
      </p>
    ) : (
      <Spinner />
    )}
  </button>
);

SecondaryButton.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  style: PropTypes.shape(),
  bgColor: PropTypes.string,
  textColor: PropTypes.string,
  loading: PropTypes.bool,
  uppercase: PropTypes.bool,
  id: PropTypes.string,
};

SecondaryButton.defaultProps = {
  id: null,
  className: '',
  disabled: false,
  style: {},
  bgColor: '',
  textColor: '',
  loading: false,
  uppercase: true,
};

export default SecondaryButton;

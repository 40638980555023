import React from 'react';
import PropTypes from 'prop-types';
import ChevronIcon from '../../components/Icons/ChevronIcon';
import CalendarIcon from '../../components/Icons/CalendarIcon';
import { getByType } from './utils';

const AssetTile = ({ data }) => {
  const { Icon } = getByType(data.type);
  return (
    <>
      <div className="relative">
        <div className="h-full w-full bg-black opacity-30 absolute rounded-tl-2xl rounded-tr-2xl" />
        <img
          className="rounded-tl-2xl rounded-tr-2xl w-full h-36 lg:h-44 object-cover"
          src={data.image}
          alt=""
        />
      </div>
      <div className="flex items-center w-full p-4">
        <div className="flex flex-col flex-1 gap-1 text-left">
          <h3 className="font-semibold">{data.title}</h3>
          {data.line1 && (
            <div className="flex items-center">
              <CalendarIcon className="h-4 w-4 mr-2 text-black" />
              <p className="text-xs">{data.line1}</p>
            </div>
          )}
          <div className="flex items-center">
            <Icon className="w-4 h-4 mr-2" />
            <p className="text-xs">{data.line2}</p>
          </div>
        </div>
        <div className="p-3 rounded-full outline-none">
          <ChevronIcon className="w-8 h-8" />
        </div>
      </div>
    </>
  );
};

AssetTile.propTypes = {
  data: PropTypes.shape({
    image: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    line1: PropTypes.string,
    line2: PropTypes.string.isRequired,
    type: PropTypes.string,
  }).isRequired,
};

export default AssetTile;

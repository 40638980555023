import React, { useState } from 'react';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import PlainButton from '../../components/PlainButton/PlainButton';
import MPModal from '../../components/MPModal/MPModal';
import CloseIcon from '../../components/Icons/CloseIcon';
import UploadIcon from '../../components/Icons/UploadIcon';
import { DarkLogoExample, LightLogoExample } from './common';
import InputUpload from '../../components/FileUpload/InputUpload';
import { base64DownscaleUpload, deepEqual } from '../../utils/general';
import { getVenueID } from '../../store/venue/selectors';
import { genericRequest } from '../../store/api/request';
import SpinnerButton from '../../components/SpinnerButton/SpinnerButton';
import { fireEvent, GATags } from '../../utils/trackingHelper';

const UploadButton = ({ children, updateLogo }) => (
  <InputUpload
    className="flex items-center py-8 px-4 rounded-xl border-mpGrey border mb-4"
    acceptedFiles="image/jpeg, image/png"
    onDrop={async f => {
      if (f?.length === 0) {
        return;
      }

      const image = await base64DownscaleUpload(f[0]);
      updateLogo(image);
    }}
  >
    <UploadIcon />
    <p className="font-semibold pl-4 text-left">{children}</p>
  </InputUpload>
);

const RemoveButton = ({ remove }) => (
  <PlainButton className="py-2 rounded-xl border-mpGrey border my-4" onClick={remove}>
    <p className="font-semibold ">{t('promoHub_remove')}</p>
  </PlainButton>
);

const LogoUpload = ({ onClose, open, logos, onSave, venueID, request }) => {
  const [unSavedLogos, setUnsavedLogos] = useState(logos);
  const [loading, setLoading] = useState(false);
  const [errorFields, setErrorFields] = useState(null);
  const { logoImageDark, logoImageLight } = unSavedLogos || {};

  const update = (field, image) => {
    setUnsavedLogos({ ...unSavedLogos, [field]: image });
    setErrorFields(null);
  };

  const close = () => {
    onClose();
    setUnsavedLogos(logos);
  };

  const save = () => {
    setLoading(true);
    setErrorFields(null);
    request(`venues/${venueID}/logos/add`, {}, 'POST', unSavedLogos)
      .then(() => {
        fireEvent(GATags.PROMOHUB_UPLOAD_LOGO, null, {
          venueID,
          dark: Boolean(logoImageDark),
          light: Boolean(logoImageLight),
        });
        onSave(unSavedLogos);
        onClose();
      })
      .catch(e => {
        if (e.code === 202) {
          setErrorFields(e.error);
          fireEvent(GATags.PROMOHUB_UPLOAD_ERROR, null, {
            venueID,
            dark: Boolean(e.error.logoImageDark),
            light: Boolean(e.error.logoImageLight),
          });
        }
      })
      .finally(() => setLoading(false));
  };

  const canSave = logoImageDark && logoImageLight && !deepEqual(logos, unSavedLogos);

  return (
    <MPModal open={open} onClose={close} className="max-w-lg text-center pt-16 rounded-2xl ">
      <PlainButton
        className="flex absolute right-4 top-4 p-2 bg-mpGrey rounded-full outline-none"
        onClick={close}
      >
        <CloseIcon className="w-8 h-8" />
      </PlainButton>
      <div className="px-8">
        <p className="headingFont text-4xl md:text-5xl pb-2">{t('promoHub_yourLogos')}</p>
        <p className="text-sm opacity-60 md:text-base pb-4">{t('promoHub_upload_subtitle')}</p>
        <div className="flex flex-col">
          {!logoImageDark ? (
            <UploadButton updateLogo={img => update('logoImageDark', img)}>
              {t('promoHub_upload_dark')}
            </UploadButton>
          ) : (
            <>
              <DarkLogoExample
                className="h-[140px] md:h-[200px]"
                logo={logoImageDark}
                errorMsg={errorFields?.logoImageDark}
              />
              <RemoveButton remove={() => update('logoImageDark', null)} />
            </>
          )}
          {!logoImageLight ? (
            <UploadButton updateLogo={img => update('logoImageLight', img)}>
              {t('promoHub_upload_light')}
            </UploadButton>
          ) : (
            <>
              <LightLogoExample
                className="h-[140px] md:h-[200px]"
                logo={logoImageLight}
                errorMsg={errorFields?.logoImageLight}
              />
              <RemoveButton remove={() => update('logoImageLight', null)} />
            </>
          )}
        </div>
        {(!logoImageLight || !logoImageDark) && (
          <div className="opacity-60 text-xs md:text-base mb-8">
            <p>
              <span className="font-medium">{t('promoHub_upload_files')}:</span>{' '}
              {t('promoHub_upload_files_restriction')}
            </p>
            <p>
              <span className="font-medium">{t('promoHub_upload_dimensions')}:</span> 500x500
            </p>
          </div>
        )}
      </div>
      <div className="border-t border-mpGrey py-4 px-8">
        <SpinnerButton
          loading={loading}
          className={`w-full py-3 rounded-lg font-semibold  ${
            !canSave ? 'bg-mpGrey text-darkGrey' : 'bg-tertiary text-primaryButtonText'
          }`}
          disabled={!canSave}
          onClick={save}
        >
          <p>{t('promoHub_done')}</p>
        </SpinnerButton>
      </div>
    </MPModal>
  );
};

RemoveButton.propTypes = {
  remove: PropTypes.func.isRequired,
};

UploadButton.propTypes = {
  children: PropTypes.node.isRequired,
  updateLogo: PropTypes.func.isRequired,
};

LogoUpload.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  logos: PropTypes.shape({ logoImageDark: PropTypes.string, logoImageLight: PropTypes.string }),
  onSave: PropTypes.func.isRequired,
  venueID: PropTypes.number.isRequired,
  request: PropTypes.func.isRequired,
};

LogoUpload.defaultProps = {
  logos: null,
};

const mapStateToProps = state => ({
  venueID: getVenueID(state),
});

const mapDispatchToProps = {
  request: genericRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(LogoUpload);

import { toast } from 'react-toastify';
import { t } from 'i18next';
import MpResponse from '../../utils/mpResponse';
import ServerError from '../../utils/serverError';
import { logoutAction } from '../auth/actions';
import { navigate } from '../navigation/thunks';
import { getServicesEndpoint, userIsOnFanzo } from '../../utils/environment';
import { getGeoLocale } from '../app/selectors';
import { setGeoLocale } from '../app/actions';
import { getURLQuery } from '../../utils/general';

const defaultHeaders = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
};

export const defaultOptions = {
  method: 'GET',
  body: null,
  headers: {},
};

/**
 * @description Manages error behaviour in the redux store.
 * @param {function} dispatch
 * @return {function(Error|ServerError): Promise<never>}
 */
const handleError = dispatch => error => {
  if (error.code !== 202) {
    if (error.code === 413) {
      toast.error(t('signup.photos.tooLarge'));
    } else {
      toast.error(error.message);
    }
  }
  if (error.code === 401) {
    // if we get a 401 then make sure we log the user out
    dispatch(logoutAction());
  }

  return Promise.reject(error);
};

const fanzoGeoConverter = locale => {
  // this is a bit hacky but maybe one day we'll make county decide content and keep locale separeate
  switch (locale) {
    case 'GB':
      return 'en';
    case 'IE':
      return 'en-ie';
    case 'AU':
      return 'en-au';
    case 'FR':
      return 'fr';
    case 'DE':
      return 'de';
    default:
      return 'en';
  }
};

/**
 * @description Handles response for a classic request.
 * @param {MpResponse} mpResponse
 * @return {Promise<MpResponse|Error|ServerError>}
 */
async function handleRequest(mpResponse) {
  if (mpResponse.isOK()) {
    if (mpResponse.data && mpResponse.data.externalRedirect) {
      if (mpResponse.data.externalRedirect.startsWith('http')) {
        window.location.href = mpResponse.data.externalRedirect;
      } else {
        navigate(mpResponse.data.externalRedirect);
        return Promise.resolve(null);
      }
    }

    return Promise.resolve(mpResponse);
  }

  return Promise.reject(
    new ServerError(mpResponse.getErrorMessage(), mpResponse.status, mpResponse.getErrorFields()),
  );
}

/**
 * @description Default requests send to the server.
 * @param {string} path
 * @param {Object} options
 * @return {Promise<MpResponse>}
 */
async function apiRequest(path, locale, options) {
  const headers = {
    ...defaultHeaders,
    ...options.headers,
  };

  if (userIsOnFanzo) {
    headers.locale = locale;
  }

  const response = await fetch(`${getServicesEndpoint()}${path}`, {
    body: options.body ? JSON.stringify(options.body) : null,
    headers,
    method: options.method,
    // Option to send / retrieve cookies.
    credentials: 'include',
  });

  // Transforms the raw response to a MpResponse instance.

  let data;
  try {
    data = await response.json();
  } catch {
    data = {};
  }

  return new MpResponse(response.status, data);
}

export const getRegion = () =>
  // use cloudfare to get the users country by IP and pass that to the BE as the locale
  fetch('https://cloudflare.com/cdn-cgi/trace')
    .then(res => res.text())
    .then(response => {
      const trace = [];
      const lines = response.split('\n');
      let keyValue;
      lines.forEach(line => {
        keyValue = line.split('=');
        trace[keyValue[0]] = decodeURIComponent(keyValue[1] || '');
      });
      return trace.loc;
    });

export const request = (path, options = defaultOptions) => async (dispatch, getState) => {
  let country = getGeoLocale(getState());
  let tempcountry = '';
  if (!country) {
    try {
      tempcountry = await getRegion();
    } catch (e) {
      // TODO do something better here
    }
    country = fanzoGeoConverter(tempcountry);
    dispatch(setGeoLocale(country));
  }

  return apiRequest(path, country, options)
    .then(handleRequest)
    .catch(handleError(dispatch));
};

export const genericRequest = (endpoint, params, method = 'GET', body) => dispatch =>
  dispatch(request(`${endpoint}${getURLQuery(params)}`, { method, body }));

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import i18n from 'i18next';
import { connect } from 'react-redux';
import { request } from '../../store/api/request';
import PageHeader from '../../components/PageHeader/PageHeader';
import './styles.scss';
import BarChart from './BarChart';
import StackedBarChart from './StackedBarChart';
import colors from '../../theme/index.module.scss';
import StatsFixtureList from '../../components/FixtureList/StatsFixtureList';

import StatsBox from './StatsBox';
import { getVenueID } from '../../store/venue/selectors';
import { getIsMobile } from '../../store/app/selectors';
import { userIsOnFanzo } from '../../utils/environment';
import { fireEvent, GACategories, GATags } from '../../utils/trackingHelper';
import PlainButton from '../../components/PlainButton/PlainButton';

const PERIODS = {
  seven: 7,
  thirty: 30,
  ninety: 90,
  alltime: 'allTime',
};

const Stats = ({ title, venueId, isMobile, generic }) => {
  const [period, setPeriod] = useState(PERIODS.seven);
  const [apiData, setApiData] = useState(null);
  const graphHeight = isMobile ? 320 : 400;
  useEffect(() => {
    let periodSegment = '';
    // on desktop and tablet we want more data points than the default - on mobile we use the detault
    if (!isMobile) {
      if (period === PERIODS.thirty) {
        periodSegment = '&periodSegment=day';
      }
      if (period === PERIODS.ninety) {
        periodSegment = '&periodSegment=week';
      }
    }
    fireEvent(GACategories.STATS, GATags.VIEW_PAGE, { period: periodSegment });
    generic(`meta/${venueId}/stats?period=${period}${periodSegment}`)
      .then(response => response.getResult())
      .then(newData => setApiData(newData));
  }, [isMobile, period, venueId, generic]);

  const dateButton = (timePeriod, selected) => (
    <PlainButton
      key={timePeriod}
      className={`Stats_period${selected ? '_selected' : ''}`}
      onClick={() => setPeriod(timePeriod)}
    >
      {timePeriod === PERIODS.alltime ? (
        <p>{i18n.t('stats_allTime')}*</p>
      ) : (
        <p>{i18n.t('stats_days', { days: timePeriod })}</p>
      )}
    </PlainButton>
  );
  return (
    <>
      <PageHeader title={title} />
      <div className="GlobalPad Stats_heading">
        {Object.values(PERIODS).map(timePeriod => dateButton(timePeriod, timePeriod === period))}
      </div>
      <div className="Stats_stripedHero">
        <h1>
          {period === PERIODS.alltime
            ? i18n.t('stats_heroTitle_allTime')
            : i18n.t('stats_heroTitle', { days: period })}
        </h1>
      </div>
      {apiData?.info && (
        <div className="Stats_statContainer">
          <StatsBox stat={apiData.info?.fixturesListed} />
          <StatsBox stat={apiData.info?.listViews} />
          <StatsBox stat={apiData.info?.profileViews} />
        </div>
      )}
      <p className="Stats_graphTitle_pad">{i18n.t('stats_profileViewRatio')}</p>
      {apiData?.data && (
        <BarChart isMobile={isMobile} height={graphHeight} data={apiData.data?.views} />
      )}
      {apiData?.data?.opportunity?.length > 0 && (
        <>
          <div className="Stats_hr" />
          <p className="Stats_graphTitle">{i18n.t('stats_mostPopularFixtures')}</p>
          {apiData.data.opportunity.filter(elm => !elm.missed).length > 0 && (
            <div className="Stats_fixtureList">
              <div className="Stats_fixtureList_title">{i18n.t('stats_popularFixtures')}</div>
              <StatsFixtureList fixtures={apiData.data.opportunity.filter(elm => !elm.missed)} />
            </div>
          )}
          {apiData.data.opportunity.filter(elm => elm.missed).length > 0 && (
            <div className="Stats_fixtureList">
              <div
                style={{ backgroundColor: colors.lightGrey }}
                className="Stats_fixtureList_title"
              >
                {i18n.t('stats_missedOpportunities')}
              </div>
              <StatsFixtureList fixtures={apiData.data.opportunity.filter(elm => elm.missed)} />
            </div>
          )}
        </>
      )}
      {apiData?.data && userIsOnFanzo && (
        <>
          <div className="Stats_hr" />
          <p className="Stats_graphTitle_pad">{i18n.t('stats_offerRedemptions')}</p>
          <StackedBarChart
            data={apiData.data?.redemptions}
            height={graphHeight}
            isMobile={isMobile}
          />
        </>
      )}
      <p className="Stats_allTime">{i18n.t('stats_allTimeDisclaimer')}</p>
    </>
  );
};

Stats.propTypes = {
  title: PropTypes.string.isRequired,
  venueId: PropTypes.number.isRequired,
  isMobile: PropTypes.bool.isRequired,
  generic: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  venueId: getVenueID(state),
  isMobile: getIsMobile(state),
});
const mapDispatchToProps = {
  generic: request,
};

export default connect(mapStateToProps, mapDispatchToProps)(Stats);

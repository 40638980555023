import { toast } from 'react-toastify';
import i18n from 'i18next';
import * as actions from './actions';
import { updateMember } from '../api/member';

// eslint-disable-next-line import/prefer-default-export
export const updateMemberThunk = (member, id) => dispatch => {
  dispatch(actions.updateMemberPending());
  return dispatch(updateMember(member, id))
    .then(r => {
      toast.success(i18n.t('settings.success'));
      dispatch(actions.updateMemberSuccess());
      return r.getResult();
    })
    .catch(error => {
      if (error.code === 202) {
        toast.error(error.message);
      }
      dispatch(actions.updateMemberError(error.error));
      throw error;
    });
};

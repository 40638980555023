import React from 'react';
import PropTypes from 'prop-types';

const TwitterIcon = ({ className }) => (
  <svg viewBox="0 0 16 16" fill="none" className={`fill-current ${className}`}>
    <path d="M8.99261 7.18372L12.8761 2.66943H11.9559L8.58379 6.58912L5.89054 2.66943H2.78418L6.85692 8.5967L2.78418 13.3306H3.7045L7.26549 9.1913L10.1098 13.3306H13.2161L8.99238 7.18372H8.99261ZM7.7321 8.64892L7.31944 8.0587L4.03611 3.36224H5.44967L8.09936 7.15243L8.51201 7.74265L11.9563 12.6693H10.5427L7.7321 8.64915V8.64892Z" />
  </svg>
);

TwitterIcon.propTypes = {
  className: PropTypes.string,
};

TwitterIcon.defaultProps = {
  className: '',
};

export default TwitterIcon;

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18next';
import { connect } from 'react-redux';
import queryString from 'query-string';
import { Redirect, Link } from 'react-router-dom';
import { getVenueAccessLevel, transformImages } from '../../../utils/general';
import { VENUE_ACCESS_LEVEL } from '../../../utils/constants';
import routes from '../../../routes/paths';
import PlainButton from '../../../components/PlainButton/PlainButton';
import { countryProps, historyProps, venueProps } from '../../../utils/customPropTypes';
import { fetchCountriesReq } from '../../../store/api/auth';
import { getCountries } from '../../../store/app/selectors';
import { getVenue } from '../../../store/venue/selectors';
import { saveVenuePhotos, saveVenue as saveVenueThunk } from '../../../store/api/venues';
import DetailsModal from './DetailsModal';
import BuildProfile from './Steps/BuildProfile';
import KeyInfo from './Steps/KeyInfo';
import OpeningHours from './Steps/OpeningHours';
import NumOfScreens from './Steps/NumOfScreens';
import Favourites from './Steps/Favourites';
import Facilities from './Steps/Facilities';
import Photos from './Steps/Photos';
import ProfileCompleteModal from './ProfileCompleteModal';
import SpinnerButton from '../../../components/SpinnerButton/SpinnerButton';
import BackIcon from '../../../components/Icons/BackIcon';
import CloseIcon from '../../../components/Icons/CloseIcon';
import { GACategories, GATags, fireEvent } from '../../../utils/trackingHelper';

const STEPS = {
  BUILD_PROFILE: 'BUILD_PROFILE',
  KEY_INFO: 'KEY_INFO',
  OPENING_HOURS: 'OPENING_HOURS',
  NUM_OF_SCREENS: 'NUM_OF_SCREENS',
  FAVOURITES: 'FAVOURITES',
  FACILITIES: 'FACILITIES',
  PHOTOS: 'PHOTOS',
};

const Stage3 = ({ countries, fetchCountries, venue, saveVenueChanges, savePhotos, history }) => {
  const params = queryString.parse(history.location.search);
  const steps = Object.values(STEPS);
  const [step, setStep] = useState(steps[0]);
  const [keyInfo, setKeyInfo] = useState({
    name: venue?.name,
    address1: venue?.address1,
    address2: venue?.address2 || '',
    city: venue?.city,
    countryId: venue?.countryId,
    postCode: venue?.postCode,
    longDescription: '',
    website: '',
    phone: '',
  });
  const [openingHours, setOpeningHours] = useState(venue?.openingHours);
  const [screensNumber, setScreensNumber] = useState(1);
  const [facilities, setFacilities] = useState({
    hasGarden: false,
    servesFood: false,
    allowsPet: false,
    hasWifi: false,
    hasSound: false,
    hasBigScreen: false,
    hasOutdoorScreen: false,
  });
  const [venueImages, setVenueImages] = useState(() => transformImages(venue));
  const [detailsModal, setDetailsModal] = useState(false);
  const [publishModal, setPublishModal] = useState(false);
  const [fieldErrors, setFieldErrors] = useState(null);
  const [loading, setLoading] = useState(false);
  const [uploadingImages, setUploadingImages] = useState(false);

  const onDetailsModalChange = () => setDetailsModal(prev => !prev);
  const onPublishModalChange = () => setPublishModal(prev => !prev);

  const isFirstStep = step === STEPS.BUILD_PROFILE;
  const currentStep = steps.findIndex(e => e === step);
  const scrollToTop = () => setTimeout(window.scrollTo(0, 0), 100);
  const moveToNextStep = () => {
    scrollToTop();
    setStep(steps[currentStep + 1]);
  };

  const track = (tag, options = {}) =>
    fireEvent(GACategories.SIGN_UP_V2, tag, { ...options, venueId: venue.id });

  const changeKeyInfo = changes => setKeyInfo(prev => ({ ...prev, ...changes }));
  const saveVenue = changes => {
    setLoading(true);
    return saveVenueChanges({ id: venue?.id, ...changes })
      .then(() => {
        setFieldErrors(null);
        changeKeyInfo(changes);
        moveToNextStep();
      })
      .catch(error => {
        if (error.code === 202) {
          setFieldErrors(error.error);
        }
      })
      .finally(() => setLoading(false));
  };

  const pressContinue = notSkipped => {
    track(notSkipped ? GATags.VSO_CONTINUE : GATags.VSO_SKIP, { step });
    if (step === STEPS.PHOTOS) {
      setLoading(true);
      setFieldErrors(null);
      setUploadingImages(true);
      return savePhotos(
        venue?.id,
        venueImages.map((vi, i) => ({ ...vi, order: i })),
      )
        .then(r => {
          const res = r.getResult();
          if (res?.saveErrors?.length === 0) {
            onPublishModalChange();
            setVenueImages(res.saveSuccess);
          } else {
            // if some errors then update venue images partially
            setVenueImages([
              ...venueImages.filter(vi => res?.saveErrors.find(f => f.id === vi.id)),
              ...res.saveSuccess,
            ]);
            setFieldErrors(res.saveErrors);
          }
        })
        .finally(() => {
          setUploadingImages(false);
          setLoading(false);
        });
    }
    if (!notSkipped) {
      return moveToNextStep();
    }
    if (isFirstStep) {
      changeKeyInfo({
        longDescription: venue?.longDescription || '',
        website: venue?.website || '',
        phone: venue?.phone || '',
      });
      return moveToNextStep();
    }
    if (step === STEPS.KEY_INFO) {
      saveVenue(keyInfo);
    }
    if (step === STEPS.OPENING_HOURS) {
      saveVenue({ openingHours });
    }
    if (step === STEPS.NUM_OF_SCREENS) {
      saveVenue({ screensNumber });
    }
    if (step === STEPS.FACILITIES) {
      saveVenue(facilities);
    }
    if (step === STEPS.FAVOURITES) {
      return moveToNextStep();
    }
    return null;
  };

  const canContinue = () => {
    const { longDescription, phone } = keyInfo || {};
    switch (step) {
      case STEPS.KEY_INFO:
        return longDescription.length > 0 && phone.length > 0;
      case STEPS.PHOTOS:
        return venueImages.length > 0;
      default:
        return true;
    }
  };

  useEffect(() => {
    if (!countries.length) {
      fetchCountries();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countries.length]);

  if (!params.force && getVenueAccessLevel(venue) !== VENUE_ACCESS_LEVEL.NO_BASIC_INFO) {
    return <Redirect to={routes.home.path} />;
  }

  return (
    <div className="absolute w-full h-full left-0 bg-offWhite lg:pt-20 z-50">
      <div className="fixed top-0 p-4 bg-white border-b border-mpGrey w-full flex justify-between z-10">
        <PlainButton
          className={`flex items-center outline-none p-2 ${currentStep > 0 ? '' : 'invisible'}`}
          onClick={() => {
            track(GATags.VSO_BACK, { step });
            scrollToTop();
            setStep(steps[currentStep - 1]);
          }}
        >
          <BackIcon className="w-8 h-8" />
          <p className="font-semibold ml-2">{t('general_back')}</p>
        </PlainButton>
        <Link
          className="p-2 bg-mpGrey rounded-full outline-none"
          to={routes.home.path}
          onClick={() => track(GATags.VSO_CLOSED, { step })}
        >
          <CloseIcon className="w-8 h-8" />
        </Link>
      </div>

      <div className="flex justify-center bg-offWhite lg:pb-20">
        <div className="px-4 pb-24 my-8 md:my-14 lg:my-16 max-w-md md:max-w-lg relative w-full">
          {currentStep > 0 && (
            <p className="md:text-xl font-bold text-quaternary">
              {currentStep}
              <span className="text-black opacity-60">{` / ${steps.length - 1}`}</span>
            </p>
          )}
          {currentStep > 1 && step !== STEPS.PHOTOS && (
            <PlainButton
              className="absolute top-4 right-4 md:-right-28 border border-mpGrey bg-white rounded-full"
              onClick={() => pressContinue(false)}
            >
              <p className="text-xs md:text-base font-semibold py-4 px-6 md:px-8">
                {t('general_skip')}
              </p>
            </PlainButton>
          )}

          {isFirstStep && <BuildProfile onSync={pressContinue} venue={venue} />}

          {step === STEPS.KEY_INFO && (
            <>
              <h1 className="headingFont text-4xl md:text-5xl lg:text-6xl">
                {t('signup_venue_keyInfoTitle')}
              </h1>
              <KeyInfo
                data={keyInfo}
                onChange={changeKeyInfo}
                openModal={() => {
                  track(GATags.VSO_KEY_INFO_EDIT);
                  onDetailsModalChange();
                }}
                fieldErrors={fieldErrors}
              />
            </>
          )}

          {step === STEPS.OPENING_HOURS && openingHours && (
            <OpeningHours data={openingHours} onChange={setOpeningHours} />
          )}

          {step === STEPS.NUM_OF_SCREENS && (
            <NumOfScreens data={screensNumber} onChange={setScreensNumber} />
          )}

          {step === STEPS.FAVOURITES && <Favourites />}

          {step === STEPS.FACILITIES && <Facilities data={facilities} onChange={setFacilities} />}

          {step === STEPS.PHOTOS && (
            <Photos
              venueImages={venueImages}
              setVenueImages={setVenueImages}
              venue={venue}
              fieldErrors={fieldErrors}
              setFieldErrors={setFieldErrors}
              uploadingImages={uploadingImages}
            />
          )}

          {!isFirstStep && (
            <div className="fixed left-0 bottom-0 w-full flex justify-center bg-white border-t border-mpGrey">
              <SpinnerButton
                loading={loading}
                className={`m-4 md:m-6 py-4 flex-1 max-w-md md:max-w-lg font-semibold rounded-lg outline-none ${
                  canContinue()
                    ? 'bg-tertiary text-primaryButtonText'
                    : 'bg-mpGrey text-standardGrey'
                }`}
                onClick={pressContinue}
                disabled={!canContinue()}
              >
                {t(step === STEPS.PHOTOS ? 'signup_venue_finishUp' : 'general.continue')}
              </SpinnerButton>
            </div>
          )}
        </div>
      </div>

      <DetailsModal
        open={detailsModal}
        onClose={onDetailsModalChange}
        data={keyInfo}
        onSubmit={saveVenue}
      />
      <ProfileCompleteModal
        open={publishModal}
        onClose={() => {
          track(GATags.VSO_PROFILE_COMPLETED_CLOSE);
          onPublishModalChange();
        }}
        onClick={() => track(GATags.VSO_GET_STARTED)}
      />
    </div>
  );
};

Stage3.propTypes = {
  fetchCountries: PropTypes.func.isRequired,
  countries: PropTypes.arrayOf(countryProps).isRequired,
  venue: venueProps.isRequired,
  saveVenueChanges: PropTypes.func.isRequired,
  savePhotos: PropTypes.func.isRequired,
  history: historyProps.isRequired,
};

const mstp = state => ({
  countries: getCountries(state),
  venue: getVenue(state),
});

const mdtp = {
  fetchCountries: fetchCountriesReq,
  saveVenueChanges: saveVenueThunk,
  savePhotos: saveVenuePhotos,
};

export default connect(mstp, mdtp)(Stage3);

import React from 'react';
import PropTypes from 'prop-types';

const PreferencesIcon = ({ className }) => (
  <svg
    className={className}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.4054 10.2913C16.6756 8.75112 18.1622 7.50256 19.7259 7.50256C21.2896 7.50256 22.3382 8.75112 22.068 10.2913L21.4157 14.0096C21.1455 15.5498 19.6589 16.7983 18.0952 16.7983C16.5315 16.7983 15.4829 15.5498 15.7531 14.0096L16.4054 10.2913ZM19.5302 8.61806C18.592 8.61806 17.7 9.36719 17.5379 10.2913L16.8856 14.0096C16.7235 14.9337 17.3527 15.6828 18.2909 15.6828C19.2291 15.6828 20.1211 14.9337 20.2832 14.0096L20.9355 10.2913C21.0976 9.36719 20.4684 8.61806 19.5302 8.61806Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.1938 12.8941L14.9981 14.0096C14.6558 15.9605 15.984 17.542 17.9647 17.542C19.9454 17.542 21.8285 15.9605 22.1707 14.0096L22.3664 12.8941H23.499L23.3033 14.0096C22.853 16.5766 20.3752 18.6575 17.769 18.6575C15.1629 18.6575 13.4152 16.5766 13.8655 14.0096L14.0612 12.8941H15.1938Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.0397 19.5871L17.3006 18.0997H18.4331L18.1722 19.5871H20.4373L20.2416 20.7026H14.7677L14.9634 19.5871H17.0397Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.6798 11.3138H17.1698L17.3328 10.3843H18.8429L18.6798 11.3138Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.4188 12.8012H16.9088L17.0718 11.8716H18.5819L18.4188 12.8012Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.158 14.2885H16.6479L16.811 13.3589H18.321L18.158 14.2885Z"
      fill="black"
    />
    <path
      d="M3.44327 11.8929H9.18263M2.61197 4.2002H12.4501L11.4882 10.2733H1.65009L2.61197 4.2002Z"
      stroke="black"
      strokeWidth="1.22976"
    />
  </svg>
);

PreferencesIcon.propTypes = { className: PropTypes.string };
PreferencesIcon.defaultProps = { className: '' };

export default PreferencesIcon;

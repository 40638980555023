import React from 'react';
import PropTypes from 'prop-types';

const PosterIcon = ({ className }) => (
  <svg viewBox="0 0 32 32" fill="none" className={`fill-current ${className}`}>
    <path d="M3.00732 24.4946C3.19582 23.3044 4.32547 22.3396 5.53045 22.3396H7.81328L7.46059 24.5664H23.9454L24.2981 22.3396H25.8941C27.0991 22.3396 27.9231 23.3044 27.7346 24.4946C27.5461 25.6847 26.4164 26.6496 25.2115 26.6496H4.84783C3.64284 26.6496 2.81882 25.6847 3.00732 24.4946Z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.68134 4.74048H21.5152L27.191 11.8544L24.9046 26.29H5.26823L8.68134 4.74048ZM10.5219 6.89544L7.79136 24.1351H23.0641L24.8734 12.7112L20.2334 6.89544H10.5219Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.1471 5.81796L21.4812 5.32474L26.6391 11.7896L25.9184 13.0011H19.7366L19.1231 12.2828L20.1471 5.81796ZM21.3154 7.62469L20.6914 11.5645H24.4588L21.3154 7.62469Z"
    />
  </svg>
);

PosterIcon.propTypes = {
  className: PropTypes.string.isRequired,
};

export default PosterIcon;

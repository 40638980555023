import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18next';
import { connect } from 'react-redux';
import PlainButton from '../../components/PlainButton/PlainButton';
import MPModal from '../../components/MPModal/MPModal';
import BackIcon from '../../components/Icons/BackIcon';
import Toggle from '../../components/Toggle/Toggle';
import DownloadIcon from '../../components/Icons/DownloadIcon';
import ChevronIcon from '../../components/Icons/ChevronIcon';
import { getByType } from './utils';
import { getServicesEndpoint } from '../../utils/environment';
import { getVenueID } from '../../store/venue/selectors';
import DotsIcon from '../../components/Icons/DotsIcon';
import ImageWithSpinner from './ImageWithSpinner';
import { fireEvent, GATags } from '../../utils/trackingHelper';
import styles from './AssetGroupPage.module.scss';
import { getURLQuery } from '../../utils/general';

const ImagePreviewModal = ({ viewTips, data, assets, venueId, onChange }) => {
  const [navIsVisible, setNavIsVisible] = useState(true);
  const [withLogo, setWithLogo] = useState(true);
  const { Icon, text } = getByType(data?.assetPlatform);

  const currentAsset = assets?.findIndex(a => a.id === data?.id);
  const canClickLeft = currentAsset > 0;
  const canClickRight = currentAsset < assets?.length - 1;

  const assetDetails = (
    <div className="flex gap-4 justify-center">
      <p className="font-medium opacity-60">
        {currentAsset + 1}/{assets?.length}
      </p>
      <p className="font-medium">{data?.name}</p>
      <div className="flex items-center">
        <Icon className="w-4 h-4 mr-2" />
        <p>{text}</p>
      </div>
    </div>
  );

  const imageUrl = `${getServicesEndpoint()}meta/${venueId}/asset-download${getURLQuery({
    assetId: data?.id,
    withoutLogo: !withLogo,
  })}`;

  const track = tag =>
    fireEvent(tag, null, {
      venueId,
      assetId: data.id,
      groupId: data.assetGroupId,
      preview: true,
    });

  const clickLeft = useCallback(() => {
    onChange(assets[canClickLeft ? currentAsset - 1 : assets?.length - 1]);
  }, [assets, canClickLeft, currentAsset, onChange]);
  const clickRight = useCallback(() => {
    onChange(assets[canClickRight ? currentAsset + 1 : 0]);
  }, [assets, canClickRight, currentAsset, onChange]);

  useEffect(() => {
    const handleKeyPress = e => {
      if (e.key === 'ArrowLeft') {
        clickLeft();
      } else if (e.key === 'ArrowRight') {
        clickRight();
      }
    };

    if (data) {
      document.addEventListener('keydown', handleKeyPress);
    }

    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [clickLeft, clickRight, data]);

  return (
    <MPModal
      open={Boolean(data)}
      onClose={() => onChange(null)}
      className="max-h-full flex flex-col relative bg-brandedGrey-1 text-white h-screen noScrollBar"
    >
      <div
        className={`absolute top-0 inset-x-0 z-10 bg-brandedGrey-2 transition-all ${
          navIsVisible ? 'p-4' : '-translate-y-full'
        }`}
      >
        <div className="flex items-center justify-between mb-6 md:mb-0">
          <PlainButton className="flex items-center" onClick={() => onChange(null)}>
            <BackIcon className="w-6 h-6 md:w-8 md:h-8" />
            <p className="text-base md:text-xl font-semibold ml-2">{t('general_back')}</p>
          </PlainButton>
          <div className="hidden md:flex">{assetDetails}</div>
          <PlainButton
            className="flex items-center bg-brandedGrey-3 rounded-full pl-4 pr-2 py-2"
            onClick={() => {
              track(GATags.PROMOHUB_TIPS_CLICKED);
              viewTips();
            }}
          >
            <p className="text-xs md:text-base font-medium mr-2">{t('promoHub_promotionTips')}</p>
            <DotsIcon className="h-8 w-8" />
          </PlainButton>
        </div>
        <div className="md:hidden">{assetDetails}</div>
      </div>

      <div
        className={`flex relative w-full justify-center ${navIsVisible ? 'overflow-hidden' : ''}`}
      >
        <PlainButton
          className={`absolute inset-y-1/2 left-4 ${navIsVisible ? '' : 'hidden'}`}
          onClick={clickLeft}
        >
          <div className="p-3 sm:p-4 rounded-full bg-[#000]/30">
            <ChevronIcon className="h-6 w-6 rotate-180" />
          </div>
        </PlainButton>

        <div className={`lg:flex hidden ${styles.previewImgContainer}`}>
          <ImageWithSpinner src={imageUrl} />
        </div>
        <PlainButton
          className={`lg:hidden flex ${styles.previewImgContainer}`}
          onClick={() => setNavIsVisible(!navIsVisible)}
        >
          <ImageWithSpinner src={imageUrl} />
        </PlainButton>

        <PlainButton
          className={`absolute inset-y-1/2 right-4 ${navIsVisible ? '' : 'hidden'}`}
          onClick={clickRight}
        >
          <div className="p-3 sm:p-4 rounded-full bg-[#000]/30">
            <ChevronIcon className="h-6 w-6" />
          </div>
        </PlainButton>
      </div>

      <div
        className={`absolute bottom-0 inset-x-0 flex flex-col sm:flex-row gap-2 justify-center bg-brandedGrey-2 transition-all ${
          navIsVisible ? 'p-4' : 'translate-y-full h-0 overflow-hidden'
        }`}
      >
        <PlainButton
          className="flex items-center justify-between bg-brandedGrey-3 rounded-full px-4 py-3 sm:px-6"
          onClick={() => {
            track(GATags.PROMOHUB_SHOW_LOGO);
            setWithLogo(!withLogo);
          }}
        >
          <p className="text-xs md:text-base font-medium mr-4">{t('promoHub_preview_showLogo')}</p>
          <Toggle onChange={() => setWithLogo(!withLogo)} checked={withLogo} className="p-0" />
        </PlainButton>
        <PlainButton
          className="flex items-center justify-between bg-brandedGrey-3 rounded-full px-4 py-3 sm:px-6"
          onClick={() => {
            track(GATags.PROMOHUB_DOWNLOAD);
            window.open(imageUrl);
          }}
        >
          <p className="text-xs md:text-base font-medium mr-6">{t('general_download')}</p>
          <DownloadIcon className="h-6 w-6 my-1" />
        </PlainButton>
      </div>
    </MPModal>
  );
};

ImagePreviewModal.propTypes = {
  viewTips: PropTypes.func.isRequired,
  data: PropTypes.shape(),
  assets: PropTypes.arrayOf(PropTypes.shape()),
  venueId: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
};

ImagePreviewModal.defaultProps = {
  data: null,
  assets: null,
};

const mstp = state => ({
  venueId: getVenueID(state),
});

export default connect(mstp)(ImagePreviewModal);

import React from 'react';
import PropTypes from 'prop-types';

const FacebookIcon = ({ className }) => (
  <svg viewBox="0 0 16 16" fill="none" className={`fill-current ${className}`}>
    <path d="M13.8757 8.03499C14.4101 4.83106 12.2101 2.23376 8.96195 2.23376C5.71375 2.23376 2.64733 4.83106 2.1129 8.03499C1.62991 10.9305 3.3803 13.3305 6.1194 13.7657L6.79559 9.71191H5.30227L5.58199 8.03499H7.07531L7.2885 6.75691C7.53102 5.30298 8.54306 4.49987 9.88647 4.49987C10.53 4.49987 11.1841 4.61318 11.1841 4.61318L10.946 6.04082H10.2044C9.47378 6.04082 9.17128 6.48804 9.09476 6.94681L8.91324 8.03499H10.5444L10.0039 9.71191H8.63353L7.95733 13.7657C10.8416 13.3305 13.3927 10.9305 13.8757 8.03499Z" />
  </svg>
);

FacebookIcon.propTypes = {
  className: PropTypes.string,
};

FacebookIcon.defaultProps = {
  className: '',
};

export default FacebookIcon;

/* eslint-disable react/jsx-props-no-spreading */
import './styles.scss';
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import SingleFixtureTemplate from './ImageGenerators/SingleFixtureTemplate';
import ImageAndFixturesTemplate from './ImageGenerators/ImageAndFixturesTemplate';
import TenFixturesTemplate from './ImageGenerators/TenFixturesTemplate';
import { SMPreviewProps } from '../../../utils/customPropTypes';
import {
  templateHeightLandscape,
  templateWidthLandscape,
  templateHeightPortrait,
  templateWidthPortrait,
  templateHeightInstagram,
  templateWidthInstagram,
} from './shared';

const transformConstraints = ([startXPercent, startYPercent, endXPercent, endYPercent]) => ({
  startXPercent,
  endXPercent,
  startYPercent,
  endYPercent,
});

const ImageGenerator = ({
  previews,
  logoScale,
  logo,
  text,
  image,
  color,
  textColor,
  constraints: { portrait, landscape, instagram },
  fontSize,
  font,
  onLoadAllAssets,
}) => {
  const defaultPreviewsToFalse = () => {
    const result = {};
    previews.forEach(p => {
      result[p.id] = false;
    });
    return result;
  };

  const [imagesLoaded, setImagesLoaded] = useState({
    ...defaultPreviewsToFalse(),
    logo: true,
    image: true,
  });
  const onImageLoad = (templateKey, val) =>
    setImagesLoaded({ ...imagesLoaded, [templateKey]: val });

  /*
    Whenever a new template, logo or background image is changed,
    the `loaded` status is set to false, so that the Image generator does not try to render them.
    Once the images are ready and loaded (see bottom of render) the states are set to true and 
    available for render
  */
  useEffect(() => {
    setImagesLoaded({ ...imagesLoaded, ...defaultPreviewsToFalse() });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [previews]);
  useEffect(() => {
    if (image !== null) onImageLoad('image', false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [image]);
  useEffect(() => {
    if (logo !== null) onImageLoad('logo', false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [logo]);

  const allImagesLoaded = !Object.keys(imagesLoaded).find(key => imagesLoaded[key] === false);

  useEffect(() => {
    onLoadAllAssets();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imagesLoaded, allImagesLoaded]);

  const landscapeCanvasProps = {
    text,
    logo,
    color,
    textColor,
    image,
    logoScale,
    templateHeight: templateHeightLandscape,
    templateWidth: templateWidthLandscape,
    fontSize,
    font,
    isLandscape: true,
  };

  const portraitCanvasProps = {
    ...landscapeCanvasProps,
    isLandscape: false,
    templateHeight: templateHeightPortrait,
    templateWidth: templateWidthPortrait,
  };

  const instagramCanvasProps = {
    ...landscapeCanvasProps,
    isLandscape: false,
    templateHeight: templateHeightInstagram,
    templateWidth: templateWidthInstagram,
  };

  const findPreviewForTemplate = (nbFixtures, format) => {
    const preview = previews.find(prev => prev.nbFixtures === nbFixtures && prev.format === format);
    return preview && preview.url;
  };

  const getImageWasLoaded = (nbFixtures, format) => {
    const preview = previews.find(pr => pr.nbFixtures === nbFixtures && pr.format === format);
    return imagesLoaded[preview && preview.id];
  };

  return (
    <>
      {landscape && (
        <>
          <ImageAndFixturesTemplate
            {...landscapeCanvasProps}
            loadedImage={
              getImageWasLoaded('5', 'landscape') && imagesLoaded.logo && imagesLoaded.image
            }
            template={findPreviewForTemplate('5', 'landscape')}
            textConstraints={transformConstraints(landscape[5].headline.constraintArea)}
            logoConstraints={transformConstraints(landscape[5].logo.constraintArea)}
            imageConstraints={transformConstraints(landscape[5].image.constraintArea)}
          />
          <SingleFixtureTemplate
            {...landscapeCanvasProps}
            loadedImage={getImageWasLoaded('1', 'landscape') && imagesLoaded.logo}
            template={findPreviewForTemplate('1', 'landscape')}
            logoConstraints={transformConstraints(landscape[1].logo.constraintArea)}
          />
          <ImageAndFixturesTemplate
            {...landscapeCanvasProps}
            loadedImage={
              getImageWasLoaded('3', 'landscape') && imagesLoaded.logo && imagesLoaded.image
            }
            template={findPreviewForTemplate('3', 'landscape')}
            textConstraints={transformConstraints(landscape[3].headline.constraintArea)}
            logoConstraints={transformConstraints(landscape[3].logo.constraintArea)}
            imageConstraints={transformConstraints(landscape[3].image.constraintArea)}
          />

          <TenFixturesTemplate
            {...landscapeCanvasProps}
            loadedImage={getImageWasLoaded('10', 'landscape')}
            template={findPreviewForTemplate('10', 'landscape')}
          />
        </>
      )}
      {portrait && (
        <>
          <SingleFixtureTemplate
            {...portraitCanvasProps}
            loadedImage={getImageWasLoaded('1', 'portrait') && imagesLoaded.logo}
            template={findPreviewForTemplate('1', 'portrait')}
            logoConstraints={transformConstraints(portrait[1].logo.constraintArea)}
          />
          <ImageAndFixturesTemplate
            {...portraitCanvasProps}
            loadedImage={
              getImageWasLoaded('3', 'portrait') && imagesLoaded.logo && imagesLoaded.image
            }
            template={findPreviewForTemplate('3', 'portrait')}
            textConstraints={transformConstraints(portrait[3].headline.constraintArea)}
            logoConstraints={transformConstraints(portrait[3].logo.constraintArea)}
            imageConstraints={transformConstraints(portrait[3].image.constraintArea)}
          />
          <ImageAndFixturesTemplate
            {...portraitCanvasProps}
            loadedImage={
              getImageWasLoaded('5', 'portrait') && imagesLoaded.logo && imagesLoaded.image
            }
            template={findPreviewForTemplate('5', 'portrait')}
            textVerticallyCentered
            textConstraints={transformConstraints(portrait[5].headline.constraintArea)}
            logoConstraints={transformConstraints(portrait[5].logo.constraintArea)}
            imageConstraints={transformConstraints(portrait[5].image.constraintArea)}
            portraitCenteredText
          />
          <TenFixturesTemplate
            {...portraitCanvasProps}
            loadedImage={getImageWasLoaded('10', 'portrait')}
            textConstraints={transformConstraints(portrait[10].headline.constraintArea)}
            template={findPreviewForTemplate('10', 'portrait')}
          />
        </>
      )}
      {instagram && (
        <SingleFixtureTemplate
          {...instagramCanvasProps}
          loadedImage={getImageWasLoaded('1', 'instagram') && imagesLoaded.logo}
          template={findPreviewForTemplate('1', 'instagram')}
          logoConstraints={transformConstraints(instagram[1].logo.constraintArea)}
        />
      )}
      <img
        onLoad={() => onImageLoad('logo', true)}
        alt=""
        src={logo}
        className="ImageGenerator_invisibleImage"
      />
      <img
        onLoad={() => onImageLoad('image', true)}
        alt=""
        src={image}
        className="ImageGenerator_invisibleImage"
      />
      {previews.map(pic => (
        <img
          key={pic.id}
          onLoad={() => onImageLoad(pic.id, true)}
          alt=""
          src={pic.url}
          className="ImageGenerator_invisibleImage"
        />
      ))}
    </>
  );
};

ImageGenerator.propTypes = {
  image: PropTypes.string,
  logo: PropTypes.string,
  text: PropTypes.string,
  color: PropTypes.string,
  textColor: PropTypes.string,
  logoScale: PropTypes.number,
  fontSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  previews: PropTypes.arrayOf(SMPreviewProps),
  font: PropTypes.string,
  constraints: PropTypes.shape({
    landscape: PropTypes.shape(),
    portrait: PropTypes.shape(),
    instagram: PropTypes.shape(),
  }).isRequired,
  onLoadAllAssets: PropTypes.func.isRequired,
};

ImageGenerator.defaultProps = {
  image: null,
  logo: null,
  text: null,
  color: null,
  textColor: null,
  logoScale: null,
  fontSize: null,
  previews: null,
  font: null,
};

export default ImageGenerator;

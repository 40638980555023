import React from 'react';
import PropTypes from 'prop-types';

const VerticalTV = ({ className }) => (
  <svg viewBox="0 0 32 32" fill="none" className={`stroke-current ${className}`}>
    <path
      d="M10.6671 8.12537L8.13379 23.9996L18.667 23.9996L21.2838 8.12635L10.6671 8.12537Z"
      strokeWidth="1.63609"
    />
  </svg>
);

VerticalTV.propTypes = {
  className: PropTypes.string.isRequired,
};

export default VerticalTV;

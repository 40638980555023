import React, { useState } from 'react';
import PropTypes from 'prop-types';
import PlainButton from '../PlainButton/PlainButton';
import VisibilityIcon from '../Icons/VisibilityIcon';

const PasswordInput = ({
  onChange,
  onKeyPress,
  value,
  id,
  label,
  placeholder,
  className,
  onFocus,
  dataTestId,
}) => {
  const [show, setShow] = useState(false);
  return (
    <>
      {label && (
        <label htmlFor={id} className="block font-bold mb-2">
          {label}
        </label>
      )}
      <div className="relative">
        <input
          data-test-id={dataTestId}
          onChange={e => onChange(e.target.value)}
          onKeyPress={onKeyPress}
          type={show ? 'text' : 'password'}
          required
          value={value}
          id={id}
          placeholder={placeholder}
          className={`w-full bg-white outline-none focus:outline-none rounded-lg py-3 pl-4 pr-12 ${className}`}
          onFocus={onFocus}
        />
        <PlainButton className="outline-none focus:outline-none" onClick={() => setShow(!show)}>
          <VisibilityIcon className="h-6 w-6 absolute right-4 inset-y-0 my-auto" show={show} />
        </PlainButton>
      </div>
    </>
  );
};

PasswordInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  onKeyPress: PropTypes.func,
  value: PropTypes.string,
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  className: PropTypes.string,
  onFocus: PropTypes.func,
  dataTestId: PropTypes.string,
};

PasswordInput.defaultProps = {
  onKeyPress: () => {},
  value: '',
  className: '',
  onFocus: () => {},
  dataTestId: '',
};

export default PasswordInput;

import React, { useEffect, useState } from 'react';
import i18next from 'i18next';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Modal from './Modal';
import PlainButton from '../../components/PlainButton/PlainButton';
import SpinnerButton from '../../components/SpinnerButton/SpinnerButton';
import { genericRequest } from '../../store/api/request';
import Toggle from '../../components/Toggle/Toggle';
import { getVenueFixturePreferences, getVenueID } from '../../store/venue/selectors';
import { fetchVenues } from '../../store/venue/thunks';
import { GATags, fireEvent } from '../../utils/trackingHelper';

const buttonStyles = 'font-semibold flex-1 text-xl rounded-lg py-3';

const PreferencesModal = ({
  open,
  onClose,
  initial,
  request,
  venueID,
  fetchVenue,
  onChangeDefaultPrefs,
}) => {
  const [initialPrefs, setInitialPrefs] = useState(initial);
  const [currentPrefs, setCurrentPrefs] = useState(initial);
  const [loading, setLoading] = useState(false);

  const hasChanges = !(
    initialPrefs.sound === currentPrefs.sound && initialPrefs.bigScreen === currentPrefs.bigScreen
  );

  const update = () => {
    setLoading(true);
    fireEvent(GATags.PREFERENCES_SAVE);
    request(`/venues/${venueID}/fixture-preferences/save`, {}, 'POST', {
      venueFixturePreferences: {
        bigScreen: currentPrefs.bigScreen,
        sound: currentPrefs.sound,
      },
    })
      .then(() => {
        onChangeDefaultPrefs(currentPrefs);
        onClose();
        return fetchVenue(venueID).then();
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    setInitialPrefs(initial);
    setCurrentPrefs(initial);
  }, [initial]);

  return (
    <Modal
      closeModal={onClose}
      open={open}
      title={i18next.t('preferences_title')}
      buttons={
        hasChanges && (
          <div className="flex pt-3 text-sm gap-2">
            <PlainButton className={`${buttonStyles} bg-mpGrey`} onClick={onClose}>
              {i18next.t('general.discard')}
            </PlainButton>
            <SpinnerButton
              loading={loading}
              className={`${buttonStyles} bg-secondary`}
              onClick={update}
            >
              {i18next.t('general.save')}
            </SpinnerButton>
          </div>
        )
      }
    >
      <p className="opacity-60 pt-4 pb-8">{i18next.t('preferences_subtitle')}</p>
      <div className="flex justify-between items-center">
        <p className="font-bold text-xs uppercase mr-2 lg:text-sm">{i18next.t('feature.sound')}</p>
        <Toggle
          onChange={() => {
            fireEvent(GATags.PREFERENCES_CHANGE, undefined, { preference: 'sound' });
            setCurrentPrefs({ sound: !currentPrefs.sound, bigScreen: currentPrefs.bigScreen });
          }}
          checked={currentPrefs.sound}
        />
      </div>
      <div className="flex justify-between items-center my-4">
        <p className="font-bold text-xs uppercase mr-2 lg:text-sm">
          {i18next.t('feature.bigScreen')}
        </p>
        <Toggle
          onChange={() => {
            fireEvent(GATags.PREFERENCES_CHANGE, undefined, { preference: 'bigScreen' });
            setCurrentPrefs({ sound: currentPrefs.sound, bigScreen: !currentPrefs.bigScreen });
          }}
          checked={currentPrefs.bigScreen}
        />
      </div>
    </Modal>
  );
};

PreferencesModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  initial: PropTypes.shape({
    sound: PropTypes.bool.isRequired,
    bigScreen: PropTypes.bool.isRequired,
  }).isRequired,
  venueID: PropTypes.number.isRequired,
  request: PropTypes.func.isRequired,
  fetchVenue: PropTypes.func.isRequired,
  onChangeDefaultPrefs: PropTypes.func.isRequired,
};
PreferencesModal.defaultProps = {};

const mapDispatchToProps = {
  request: genericRequest,
  fetchVenue: fetchVenues,
};

const mapStateToProps = state => ({
  initial: getVenueFixturePreferences(state),
  venueID: getVenueID(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(PreferencesModal);

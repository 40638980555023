import React, { useState, useEffect } from 'react';
import i18n from 'i18next';
import PropTypes from 'prop-types';
import { Redirect, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  add,
  format,
  min,
  differenceInDays,
  differenceInHours,
  endOfDay,
  parseISO,
} from 'date-fns';
import { getIsMobile } from '../../store/app/selectors';
import { createPostPublishThunk, updatePostPublishThunk } from '../../store/socialMedia/thunks';
import { getIsUploading } from '../../store/socialMedia/selectors';
import { getVenue } from '../../store/venue/selectors';
import PageHeader from '../../components/PageHeader/PageHeader';
import { venueProps, historyProps } from '../../utils/customPropTypes';
import './styles.scss';
import SecondaryButton from '../../components/SecondaryButton/SecondaryButton';
import routes from '../../routes/paths';
import Dropdown from '../../components/Dropdown/Dropdown';
import { generateTimeIntervals, roundToNearestXminutes } from '../../utils/general';
import { setHasUnsavedChanges } from '../../store/navigation/actions';
import SpinnerButton from '../../components/SpinnerButton/SpinnerButton';
import SocialMediaButtons from '../../components/SocialMediaButtons/SocialMediaButtons';
import TextArea from '../../components/TextArea/TextArea';
import { fireEvent, GATags, GACategories } from '../../utils/trackingHelper';
import {
  bulkRemoveSMFixture,
  setSMFormat,
  setNumberOfFixtures,
} from '../../store/socialMedia/actions';
import { getLocaleDate } from '../../i18n';
import PostPublishTimingButton from './PostPublishTimingButton';
import { useHiddenByPermissions } from '../../utils/CustomHooks';

const characterLimit = 220;

const generateNextDaysAhead = number => {
  const result = [];
  let day = new Date();
  let dayStr = '';
  for (let i = 0; i < number; i += 1) {
    switch (i) {
      case 0:
        dayStr = i18n.t('general.today');
        break;
      case 1:
        dayStr = i18n.t('general.tomorrow');
        break;
      default:
        dayStr = getLocaleDate(day, 'do MMMM yyyy');
    }
    result.push({
      key: format(day, 'yyyy-MM-dd'),
      value: dayStr,
    });
    day = add(day, { days: 1 });
  }
  return result;
};

const PostPublish = ({
  venue,
  createPost,
  history,
  changeHasUnSavedChanges,
  title,
  isMobile,
  clearSMPostData,
  updatePost,
  isUploading,
}) => {
  const {
    message: messageh,
    time: timeh,
    twitter: twitterh,
    facebook: fbh,
    content,
    id,
    preventFixtureFetch,
    preventEdit,
  } = history?.location?.state || {};
  const initialMessage = messageh || '';
  const initialDate = timeh ? getLocaleDate(timeh, 'yyyy-MM-dd') : '';

  const [twitter, selectTwitter] = useState(Boolean(twitterh));
  const [facebook, selectFacebook] = useState(Boolean(fbh));
  const [message, setMessage] = useState(initialMessage);
  const [date, setDate] = useState(initialDate);
  const [time, setTime] = useState(timeh ? getLocaleDate(timeh, 'HH:mm') : '');
  const [timeButtonSelected, setTimeButton] = useState(timeh ? 'custom' : 'now');

  const [earliestFixtureEndTime, setEarliestFixtureEndTime] = useState(null);
  const [dateOptions, setDateOptions] = useState(generateNextDaysAhead(14));

  useEffect(() => {
    if (content?.fixtures?.length) {
      const earlyFixtureEnd = min(content.fixtures.map(f => new Date(f.endTimeUtc)));

      setEarliestFixtureEndTime(earlyFixtureEnd);
      // Only generate days up to the date of the early fixture +1 so that we can schedule the full day up to the fixture
      setDateOptions(
        generateNextDaysAhead(
          differenceInDays(endOfDay(new Date(earlyFixtureEnd)), new Date()) + 1,
        ),
      );
    }
  }, [content?.fixtures]);

  useEffect(() => {
    if (date === initialDate && message === initialMessage) {
      changeHasUnSavedChanges(false);
    } else {
      changeHasUnSavedChanges(true);
    }
  }, [message, date, initialDate, initialMessage, changeHasUnSavedChanges]);

  useEffect(() => {
    return () => changeHasUnSavedChanges(false);
  }, [changeHasUnSavedChanges]);

  const getTimeOptions = () =>
    generateTimeIntervals(
      30,
      date === format(new Date(), 'yyyy-MM-dd') ? roundToNearestXminutes(30) : '00:00',
      date === format(new Date(earliestFixtureEndTime), 'yyyy-MM-dd')
        ? format(new Date(earliestFixtureEndTime), 'HH:mm')
        : '23:59',
    );

  const schedulePost = () => {
    const callbackURL = routes.marketing.subRoutes.postToSocial.path;
    const venueId = venue.id;

    const post = {
      ...content,
      message,
      facebook,
      twitter,
      time: timeButtonSelected !== 'custom' ? timeButtonSelected : parseISO(`${date} ${time}`),
      fixtures: content?.fixtures ? content.fixtures.map(elm => elm.id) : undefined,
    };

    changeHasUnSavedChanges(false);
    if (id) {
      post.id = id;
      fireEvent(GACategories.SOCIAL_MEDIA_CONTENT, GATags.UPDATE_SM_POST, { venueId });
      return updatePost(venueId, post, () => history.push(callbackURL));
    }
    post.locallyUpdatePosted = timeButtonSelected === 'now';
    fireEvent(GACategories.SOCIAL_MEDIA_CONTENT, GATags.CREATE_SM_POST, { venueId });
    clearSMPostData();
    return createPost(venueId, post, () => history.push(callbackURL, { preventFixtureFetch }));
  };

  const submitButtonsDisabled =
    !((time && date) || timeButtonSelected !== 'custom') || !(facebook || twitter);

  const checkTimeIsAvailable = hours =>
    earliestFixtureEndTime &&
    differenceInHours(earliestFixtureEndTime, add(new Date(), { hours })) < 0;

  const setPostWhen = when => {
    setDate('');
    setTime('');
    setTimeButton(when);
    fireEvent(GACategories.SOCIAL_MEDIA_CONTENT, GATags.CHOSE_SCHEDULE, {
      when,
    });
  };

  useHiddenByPermissions();

  if (!content) {
    return <Redirect to={routes.marketing.path} />;
  }

  return (
    <>
      <PageHeader smallPad title={title} />
      <div className={`PostPublish ${!isMobile ? 'GlobalPad' : ''}`}>
        <div className="PostPublish_column">
          <div className="PostPublish_column_messageContainer">
            <h2 className="PostPublish_column_title">
              {i18n.t('marketing.postPublish.edit_message')}
            </h2>
            <TextArea
              className="PostPublish_column_messageContainer_textarea"
              placeholder={i18n.t('marketing.postPublish.type_message')}
              rows="4"
              maxLength={characterLimit}
              value={message}
              onChange={val => setMessage(val)}
            />
          </div>
          <p className="PostPublish_column_text">{i18n.t('marketing.postPublish.where')}</p>
          <div className="PostPublish_column_socialMediaButtonContainer">
            <SocialMediaButtons
              toggleable
              onTwitterToggle={() => selectTwitter(!twitter)}
              twitterSelected={Boolean(twitter)}
              onFacebookToggle={() => selectFacebook(!facebook)}
              facebookSelected={Boolean(facebook)}
            />
          </div>
          <p className="PostPublish_column_text">{i18n.t('marketing.postPublish.when')}</p>
          <div className="PostPublish_noBorder">
            <div className="PostPublish_timeButtonContainer">
              <PostPublishTimingButton
                onClick={() => setPostWhen('now')}
                active={timeButtonSelected === 'now'}
                text={i18n.t('marketing.postPublish.now')}
              />
              {content?.fixtures?.length > 0 && (
                <>
                  <PostPublishTimingButton
                    disabled={checkTimeIsAvailable(2)}
                    onClick={() => setPostWhen('2hours')}
                    active={timeButtonSelected === '2hours'}
                    text={i18n.t('marketing.postPublish.2h_before')}
                  />
                  <PostPublishTimingButton
                    disabled={checkTimeIsAvailable(24)}
                    onClick={() => setPostWhen('24hours')}
                    active={timeButtonSelected === '24hours'}
                    text={i18n.t('marketing.postPublish.24h_before')}
                  />
                </>
              )}
              <PostPublishTimingButton
                text={i18n.t('general.custom')}
                onClick={() => setPostWhen('custom')}
                active={timeButtonSelected === 'custom'}
              />
            </div>
          </div>
          {timeButtonSelected === 'custom' && (
            <div className="PostPublish_dateButtonContainer">
              <Dropdown
                containerStyle="mt-4 w-1/2"
                buttonStyle="w-full bg-white py-1 text-xs"
                label={i18n.t('marketing.postPublish.choose_date')}
                data={dateOptions}
                selected={{ key: date, value: dateOptions.find(({ key }) => key === date)?.value }}
                onClickItem={({ key }) => setDate(key)}
              />
              <Dropdown
                containerStyle="mt-4 w-1/2"
                buttonStyle="w-full bg-white py-1 text-xs"
                disabled={!date}
                label={i18n.t('marketing.postPublish.choose_time')}
                data={getTimeOptions()}
                selected={{ key: time, value: time }}
                onClickItem={({ key }) => setTime(key)}
              />
            </div>
          )}
        </div>

        <div className="PostPublish_column PostPublish_noBorder">
          <img className="PostPublish_column_preview" alt="publish" src={content.link} />

          <div className="PostPublish_column_publishButtonContainer">
            {!preventEdit && (
              <SecondaryButton
                onClick={() => {
                  fireEvent(GACategories.SOCIAL_MEDIA_CONTENT, GATags.EDIT_POST);
                  history.goBack();
                }}
                label={i18n.t('general.edit')}
                style={{ marginBottom: '8px' }}
              />
            )}
            <SpinnerButton
              className={`bg-secondary py-1 font-semibold rounded text-sm uppercase ${
                submitButtonsDisabled ? 'opacity-20' : ''
              }`}
              onClick={schedulePost}
              disabled={submitButtonsDisabled}
              loading={isUploading}
            >
              {i18n.t('marketing.postPublish.publish')}
            </SpinnerButton>
          </div>
        </div>
      </div>
    </>
  );
};

PostPublish.propTypes = {
  createPost: PropTypes.func.isRequired,
  history: historyProps.isRequired,
  venue: venueProps.isRequired,
  changeHasUnSavedChanges: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  isMobile: PropTypes.bool.isRequired,
  updatePost: PropTypes.func.isRequired,
  isUploading: PropTypes.bool.isRequired,
  clearSMPostData: PropTypes.func.isRequired,
};

const mapDispatchToProps = dispatch => ({
  createPost: (venueID, post, callback) =>
    dispatch(createPostPublishThunk(venueID, post, callback)),
  updatePost: (venueID, post, callback) =>
    dispatch(updatePostPublishThunk(venueID, post, callback)),
  changeHasUnSavedChanges: value => dispatch(setHasUnsavedChanges(value)),
  clearSMPostData: () => {
    dispatch(bulkRemoveSMFixture(0));
    dispatch(setSMFormat(null));
    dispatch(setNumberOfFixtures(0));
  },
});

const mapStateToProps = state => ({
  venue: getVenue(state),
  isMobile: getIsMobile(state),
  isUploading: getIsUploading(state),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PostPublish));

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18next';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import { genericRequest } from '../../store/api/request';
import { getVenue } from '../../store/venue/selectors';
import { venueProps } from '../../utils/customPropTypes';
import ProfileHeader from './ProfileHeader';
import SaveBar from './SaveBar/SaveBar';
import PlainButton from '../../components/PlainButton/PlainButton';
import Input from '../SignupV2/Input';
import InfoIcon from '../../components/Icons/InfoIcon';
import routes from '../../routes/paths';
import { GATags, fireEvent } from '../../utils/trackingHelper';
import { refreshVenueThunk } from '../../store/venue/thunks';

// hacky way to show different placeholder for DMN
const DMN_PLATFORM_ID = 2;

const BookingsPage = ({ venue, generic, refreshVenue }) => {
  const getBookingValue = () => {
    const { method, platformId, platformExternalId } = venue.booking;
    return { value: platformExternalId || '', platformId, method };
  };

  const [bookingMethods, setBookingMethods] = useState([]);
  const [saving, setSaving] = useState(false);

  const initBookingValue = getBookingValue();
  const [initialBookingValue, setInitialBookingValue] = useState(initBookingValue);
  const [bookingValue, setBookingValue] = useState(initBookingValue);

  const bookingChanged = JSON.stringify(initialBookingValue) !== JSON.stringify(bookingValue);

  useEffect(() => {
    generic(`/venues/${venue.id}/booking-method`).then(response =>
      setBookingMethods(response.getResult()),
    );
  }, [venue.id, generic]);

  const updateBookingPrefs = () => {
    setSaving(true);
    generic(`/venues/${venue.id}/booking-method/update`, {}, 'PUT', {
      platformId: bookingValue.platformId,
      platformExternalId: bookingValue.value,
      method: bookingValue.method,
    })
      .then(r => {
        refreshVenue(venue.id);
        if (r.status === 202) {
          throw r.getErrorMessage();
        }
        return setInitialBookingValue(bookingValue);
      })
      .catch(e => toast.error(e.message))
      .finally(() => setSaving(false));
  };

  return (
    <>
      <ProfileHeader text={t('editProfile.preferences.booking_pref')} />
      <div className="px-4 md:px-0 mb-36 md:mb-32 w-full md:max-w-xl mx-auto">
        <p className="opacity-60 py-4 md:pt-12 xl:pt-16">{t('editProfile.booking_desc')}</p>
        <div className="flex flex-wrap gap-2">
          {bookingMethods.map(bm => {
            const isSelected =
              bm.method === 'platform'
                ? bookingValue.method === bm.method && bm.id === bookingValue.platformId
                : bookingValue.method === bm.method;

            return (
              <PlainButton
                key={`${bm.id}${bm.name}`}
                onClick={() => {
                  fireEvent(GATags.BOOKING_METHOD, null, { venueId: venue.id, selected: bm.name });
                  setBookingValue({ method: bm.method, value: '', platformId: bm.id });
                }}
                className={`flex items-center outline-none gap-2 px-6 py-2 rounded-full border whitespace-nowrap ${
                  isSelected ? 'border-quaternary bg-secondaryLight' : 'border-mpGrey bg-white'
                }`}
              >
                {bm.platformLogo && <img alt="" src={bm.platformLogo} />}
                <p className="font-semibold leading-none">{bm.name}</p>
              </PlainButton>
            );
          })}
        </div>

        {bookingValue.method === 'platform' && (
          <>
            <p className="uppercase font-bold text-xs mt-6 sm:mt-8 pb-2">
              {t('editProfile.booking_id')}
            </p>
            <Input
              placeholder={
                bookingValue.platformId === DMN_PLATFORM_ID
                  ? '123456a78e1b9c123b4cf56c'
                  : '123456789'
              }
              value={bookingValue.value}
              onChange={value => setBookingValue({ ...bookingValue, value })}
            />
          </>
        )}
        {bookingValue.method === 'phone' && (
          <div className="bg-[#DBE6F5] text-[#2E6AC6] rounded-lg my-4 md:my-8 p-4">
            <div className="flex gap-1">
              <InfoIcon className="h-6 w-6" />
              <p>
                {t('editProfile_bookingsHelp')}{' '}
                <Link className="underline font-bold" to={routes.profile.subRoutes.general.path}>
                  {t('signup_venue_keyInfoTitle')}.
                </Link>
              </p>
            </div>
          </div>
        )}
      </div>
      {bookingChanged && (
        <SaveBar
          loading={saving}
          onDiscard={() => setBookingValue(initBookingValue)}
          onSave={updateBookingPrefs}
          saveCopy={t('editProfile_saveBookings')}
        />
      )}
    </>
  );
};

BookingsPage.propTypes = {
  venue: venueProps.isRequired,
  generic: PropTypes.func.isRequired,
  refreshVenue: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  venue: getVenue(state),
});

const mapDispatchToProps = {
  generic: genericRequest,
  refreshVenue: refreshVenueThunk,
};

export default connect(mapStateToProps, mapDispatchToProps)(BookingsPage);

import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';
import Input from '../Input/Input';

const BorderedInput = ({ className, onChange, size, value, ...rest }) => (
  <Input
    onChange={onChange}
    className={`BorderedInput${size !== 'half' ? '' : '_half'} ${className}`}
    value={value}
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...rest}
    data-test-id="inputBox"
  />
);

BorderedInput.propTypes = {
  onChange: PropTypes.func,
  className: PropTypes.string,
  size: PropTypes.oneOf(['full', 'half', '']),
  value: PropTypes.string,
};

BorderedInput.defaultProps = {
  onChange: () => {},
  className: '',
  size: '',
  value: '',
};
export default BorderedInput;

import { request } from './request';

export const requestTiers = (countryId = null) => async dispatch => {
  const countryParam = countryId ? `?countryId=${countryId}` : '';
  return dispatch(request(`meta/tiers/by-country${countryParam}`));
};

export const requestPromoCodeTiers = (promoCode, countryId, venueId) => async dispatch => {
  return dispatch(
    request('venues/promo-code', {
      method: 'POST',
      body: {
        promoCode,
        countryId,
        venueId,
      },
    }),
  );
};

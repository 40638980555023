import React from 'react';
import i18next from 'i18next';
import BrokenGlass from './BrokenGlass';
import './styles.scss';

const PageNotFound = () => (
  <div className="PageNotFound">
    <BrokenGlass />
    <h2 className="PageNotFound_text">
      {i18next.t('error.page_not_found.something_went_wrong')}{' '}
      <a className="PageNotFound_link" href="/">
        {i18next.t('general.clickHere')}
      </a>
    </h2>
  </div>
);

export default PageNotFound;

/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { PropTypes } from 'prop-types';
import { useDropzone } from 'react-dropzone';

const InputUpload = ({
  onDrop,
  acceptedFiles,
  allowMultiple,
  children,
  className,
  setIsHovered,
}) => {
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: acceptedFiles,
    multiple: allowMultiple,
    onDragEnter: () => setIsHovered(true),
    onDragLeave: () => setIsHovered(false),
    onDropAccepted: () => setIsHovered(false),
  });

  return (
    <div className={`${className} cursor-pointer`} {...getRootProps()}>
      <input {...getInputProps()} />
      {children}
    </div>
  );
};

InputUpload.propTypes = {
  onDrop: PropTypes.func.isRequired,
  acceptedFiles: PropTypes.string,
  allowMultiple: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
  setIsHovered: PropTypes.func,
};

InputUpload.defaultProps = {
  allowMultiple: false,
  acceptedFiles: 'image/jpeg, image/png',
  children: undefined,
  className: undefined,
  setIsHovered: () => {},
};

export default InputUpload;

import React from 'react';
import PropTypes from 'prop-types';

const FilterIcon = ({ className }) => (
  <svg className={`fill-current ${className}`} viewBox="0 0 24 24">
    <path d="M9.99175 17.2318H19.8712L20.2302 15.0798H10.3705C10.4563 15.4034 10.4743 15.7595 10.4119 16.1335C10.3466 16.5247 10.1999 16.8965 9.99175 17.2318Z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.04822 16.1335C3.37039 14.2021 5.21891 12.6364 7.17701 12.6364H8.26792C10.226 12.6364 11.5522 14.2021 11.23 16.1335C10.9079 18.0649 9.05935 19.6306 7.10125 19.6306H6.01034C4.05224 19.6306 2.72605 18.0649 3.04822 16.1335ZM6.90778 14.2504C5.85342 14.2504 4.85806 15.0935 4.68458 16.1335C4.51111 17.1735 5.22521 18.0166 6.27957 18.0166H7.37048C8.42484 18.0166 9.4202 17.1735 9.59367 16.1335C9.76715 15.0935 9.05305 14.2504 7.99869 14.2504H6.90778Z"
    />
    <path d="M14.6231 7.44339C14.4293 7.767 14.2926 8.12316 14.2302 8.49713C14.1649 8.88837 14.1876 9.26011 14.2839 9.59548H4.05309L4.41207 7.44339H14.6231Z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.4119 8.49714C13.7341 6.56572 15.5826 5 17.5407 5H18.6316C20.5897 5 21.9159 6.56572 21.5938 8.49714C21.2716 10.4286 19.4231 11.9943 17.465 11.9943H16.3741C14.416 11.9943 13.0898 10.4286 13.4119 8.49714ZM17.2715 6.61406C16.2171 6.61406 15.2218 7.45714 15.0483 8.49714C14.8748 9.53713 15.5889 10.3802 16.6433 10.3802H17.7342C18.7886 10.3802 19.7839 9.53713 19.9574 8.49714C20.1309 7.45714 19.4168 6.61406 18.3624 6.61406H17.2715Z"
    />
  </svg>
);

FilterIcon.propTypes = {
  className: PropTypes.string,
};
FilterIcon.defaultProps = {
  className: '',
};

export default FilterIcon;

import React from 'react';

const BrokenGlass = () => (
  <svg
    x="0px"
    y="0px"
    height={60}
    width={60}
    viewBox="0 0 426.667 426.667"
    enableBackground="new 0 0 426.667 426.667"
    xmlSpace="preserve"
  >
    <g>
      <g>
        <path
          d="M381.25,3.531C379.229,1.281,376.354,0,373.333,0h-96c-3.354,0-6.521,1.583-8.542,4.271l-32,42.667
			c-2.417,3.229-2.813,7.552-1,11.167l17.896,35.792l-26.604,26.615l7.042-21.135c1.271-3.833,0.271-8.063-2.583-10.917
			l-36.125-36.125l26.458-35.271c2.417-3.229,2.813-7.552,1-11.167S217.375,0,213.333,0h-160c-3.021,0-5.896,1.281-7.917,3.531
			c-2.042,2.24-3,5.24-2.688,8.25l39.5,376.938c2.417,21.635,20.667,37.948,42.417,37.948h177.375c21.75,0,40-16.313,42.417-38.021
			l39.5-376.865C384.25,8.771,383.292,5.771,381.25,3.531z M323.229,386.344c-1.208,10.823-10.333,18.99-21.208,18.99H124.646
			c-10.875,0-20-8.167-21.208-18.917L65.167,21.333H192l-19.208,25.604c-3.167,4.24-2.75,10.188,1,13.938l38,38.01l-19.25,57.74
			c-1.563,4.698,0.313,9.844,4.521,12.448c4.188,2.615,9.646,1.99,13.146-1.531l64-64c3.25-3.25,4.063-8.208,2-12.313
			l-18.396-36.771l24.854-33.125H361.5L323.229,386.344z"
        />
      </g>
    </g>
  </svg>
);

export default BrokenGlass;

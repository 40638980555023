import React from 'react';
import PropTypes from 'prop-types';
import './Slider.styles.scss';

const Slider = ({ disabled, value, min, max, onChange }) => (
  <input type="range" disabled={disabled} value={value} min={min} max={max} onChange={onChange} />
);

Slider.propTypes = {
  disabled: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  min: PropTypes.number,
  max: PropTypes.number,
  onChange: PropTypes.func.isRequired,
};

Slider.defaultProps = {
  disabled: false,
  min: 1,
  max: 100,
};

export default Slider;

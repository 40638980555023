import React from 'react';
import PropTypes from 'prop-types';

const Trending = ({ className }) => (
  <svg className={className} viewBox="0 0 29 16">
    <path d="M12.1668 7.71434L11.4708 7.99371L11.4708 7.99371L12.1668 7.71434ZM13.4047 10.7984L12.7087 11.0777L12.7087 11.0777L13.4047 10.7984ZM13.8848 10.9527L14.2876 11.5853L13.8848 10.9527ZM24.7996 4.00195L16.1475 4.37813L20.7994 11.683L24.7996 4.00195ZM11.6989 7.55263L11.3239 6.90311V6.90311L11.6989 7.55263ZM3.625 11.3481C3.26628 11.5552 3.14337 12.0139 3.35048 12.3726C3.55759 12.7313 4.01628 12.8542 4.375 12.6471L3.625 11.3481ZM11.4708 7.99371L12.7087 11.0777L14.1007 10.519L12.8628 7.43496L11.4708 7.99371ZM14.2876 11.5853L19.5089 8.2603L18.7032 6.99507L13.4819 10.3201L14.2876 11.5853ZM12.7087 11.0777C12.96 11.7039 13.7185 11.9477 14.2876 11.5853L13.4819 10.3201C13.705 10.178 14.0022 10.2736 14.1007 10.519L12.7087 11.0777ZM12.8628 7.43496C12.6185 6.82635 11.8918 6.5752 11.3239 6.90311L12.0739 8.20214C11.8513 8.33065 11.5665 8.23223 11.4708 7.99371L12.8628 7.43496ZM4.375 12.6471L12.0739 8.20214L11.3239 6.90311L3.625 11.3481L4.375 12.6471Z" />
  </svg>
);

Trending.propTypes = {
  className: PropTypes.string,
};

Trending.defaultProps = {
  className: 'w-10 fill-green',
};

export default Trending;

import React from 'react';
import PropTypes from 'prop-types';

const Asset = ({ className }) => (
  <svg viewBox="0 0 16 16" fill="none" className={`fill-current ${className}`}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.17604 3.46155L14.4488 3.46155L13.0266 12.4405L1.75391 12.4405L3.17604 3.46155ZM4.09629 4.53903L3.01547 11.3631L12.1064 11.3631L13.1872 4.53903L4.09629 4.53903Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.398 8.19029C11.0387 7.74561 10.32 7.74561 9.81978 8.19029L7.03495 10.6661L6.59608 10.1229C6.24665 9.69052 5.55437 9.67679 5.05346 10.0924L3.09035 11.721L2.66657 11.2046L4.62967 9.576C5.46406 8.88377 6.61703 8.90623 7.19972 9.62574L9.37226 7.6943C10.2059 6.95318 11.4038 6.95318 12.0026 7.6943L13.0739 9.01997L12.4692 9.51595L11.398 8.19029Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.89719 5.79608C5.49553 5.79608 5.11898 6.11769 5.05615 6.5144C4.99332 6.91112 5.26799 7.23272 5.66965 7.23272C6.07131 7.23272 6.44786 6.91112 6.51069 6.5144C6.57353 6.11769 6.29885 5.79608 5.89719 5.79608ZM4.32888 6.5144C4.45454 5.72097 5.20764 5.07777 6.01096 5.07777C6.81429 5.07777 7.36364 5.72097 7.23797 6.5144C7.1123 7.30784 6.3592 7.95104 5.55588 7.95104C4.75256 7.95104 4.20321 7.30784 4.32888 6.5144Z"
    />
  </svg>
);

Asset.propTypes = {
  className: PropTypes.string.isRequired,
};

export default Asset;

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import BorderedInput from '../../components/BorderedInput/BorderedInput';
import TextArea from '../../components/TextArea/TextArea';
import i18n from '../../i18n';
import { isFutureDate } from '../../utils/general';
import { fetchLeaguePrizes, removeLeaguePrizes, updateLeaguePrizes } from '../../store/api/venues';
import { getVenueLeague } from '../../store/venue/selectors';
import Button from '../../components/Button/Button';
import { getIsDesktop } from '../../store/app/selectors';
import ChevronIcon from '../../components/Icons/ChevronIcon';
import BackIcon from '../../components/Icons/BackIcon';
import RadioInput from '../../components/RadioInput/RadioInput';
import RoundPrizeButton from './components/RoundPrizeButton';
import DeletePrizeModal from './components/DeletePrizeModal';
import { fireEvent, GACategories, GATags } from '../../utils/trackingHelper';
import PlainButton from '../../components/PlainButton/PlainButton';

const initialPrize = { prizeName: '', prizeText: '', applyTo: 'thisRound' };

const s = {
  section: 'border-opacity-10 border-mpGrey lg:w-1/3',
  title: 'text-lg font-bold',
  form: 'mb-5 rounded-md mt-2 border-lightGrey p-3',
  btn: 'mt-4 py-3 w-full font-semibold',
  prizesOpen: 'mt-3 w-full items-center flex justify-center',
};

const Prizes = ({ fetchRounds, update, league, isDesktop, remove }) => {
  const [prize, setPrize] = useState(initialPrize);
  const [activeRound, setActiveRound] = useState(null);
  const [autoPrizesOpen, setAutoPrizesOpen] = useState(false);
  const [rounds, setRounds] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);

  const closeModal = () => setDeleteModal(false);
  const { gameId, venueId } = league || {};

  useEffect(() => {
    fetchRounds(gameId, venueId).then(res => setRounds(res.getResult()));
    fireEvent(GACategories.FOOTY_TIPPING, GATags.VIEW_PRIZES);
  }, [fetchRounds, gameId, venueId]);

  const roundsList = (
    <div className={s.section}>
      <div className="mr-5">
        <p className={s.title}>{i18n.t('tipping.prizes.title')}</p>
        <p className="py-5">{i18n.t('tipping.prizes.subtitle')}</p>
        {rounds?.roundPrizes?.map(round => (
          <RoundPrizeButton
            key={round?.id}
            round={round}
            activeRound={activeRound}
            onClick={() => {
              setActiveRound(round);
              const selectedPrize = round?.roundPrize?.prize;
              const { description, name } = selectedPrize || {};
              fireEvent(GACategories.FOOTY_TIPPING, GATags.EDIT_PRIZE);
              if (selectedPrize) {
                return setPrize({ ...initialPrize, prizeName: name, prizeText: description });
              }
              return setPrize(initialPrize);
            }}
          />
        ))}
      </div>
    </div>
  );

  const applyToOptions = {
    allRounds: 'tipping.prizes.apply.all',
    thisRound: 'tipping.prizes.apply.this',
    futureRounds: 'tipping.prizes.apply.future',
  };
  const activePrize = activeRound?.roundPrize?.prize;
  const disabled = !prize?.prizeName || !prize?.prizeText || !prize?.applyTo;
  const isFuture = isFutureDate(activeRound?.endTimeUtc);
  const editFields = (
    <div className={s.section}>
      <div className="mr-5 lg:ml-5">
        {!isDesktop && (
          <PlainButton className="flex items-center mb-5" onClick={() => setActiveRound(null)}>
            <BackIcon className="h-8 w-8" />
            <div className="text-left">
              <h1>{activeRound?.name}</h1>
              <h1 className="headingFont text-4xl">{i18n.t('tipping.prizes.title2')}</h1>
            </div>
          </PlainButton>
        )}
        <p className={s.title}>{i18n.t('tipping.prizes.name')}</p>
        <BorderedInput
          className={`${s.form} ${!isFuture ? 'text-standardGrey bg-veryLightGrey' : ''}`}
          onChange={prizeName => setPrize({ ...prize, prizeName })}
          value={prize?.prizeName}
          placeholder={i18n.t('tipping.prizes.name.placeholder')}
          disabled={!isFuture}
        />
        <p className={s.title}>{i18n.t('tipping.prizes.description')}</p>
        <TextArea
          className={`${s.form} ${!isFuture ? 'text-standardGrey bg-veryLightGrey' : 'text-black'}`}
          placeholder={i18n.t('tipping.prizes.description.placeholder')}
          value={prize?.prizeText}
          onChange={prizeText => setPrize({ ...prize, prizeText })}
          disabled={!isFuture}
        />
        {isFuture && (
          <>
            <p className={s.title}>{i18n.t('tipping.prizes.apply')}</p>
            <div className="mx-1 mb-8">
              <RadioInput
                options={applyToOptions}
                active={prize?.applyTo}
                onClick={applyTo => {
                  fireEvent(GACategories.FOOTY_TIPPING, GATags.APPLY_TO, { applyTo });
                  setPrize({ ...prize, applyTo });
                }}
              />
            </div>
            <Button
              className={`${s.btn} ${disabled ? 'bg-mpGrey text-standardGrey' : 'bg-secondary'}`}
              disabled={disabled}
              onClick={() => {
                fireEvent(GACategories.FOOTY_TIPPING, GATags.ADD_PRIZE);
                update(gameId, venueId, activeRound?.id, prize)
                  .then(res => setRounds({ ...rounds, roundPrizes: res.getResult() }))
                  .finally(() => setActiveRound(null));
              }}
            >
              {i18n.t(activePrize ? 'tipping.prizes.edit' : 'tipping.prizes.add')}
            </Button>
            {activePrize && (
              <Button
                className={`${s.btn} bg-mpGrey ${disabled ? 'text-standardGrey' : 'text-lightRed'}`}
                disabled={disabled}
                onClick={() => setDeleteModal(true)}
              >
                {i18n.t('tipping.prizes.delete')}
              </Button>
            )}
          </>
        )}
      </div>
    </div>
  );

  const autoPrizes = (
    <div className={s.section}>
      <div className="bg-mpGrey rounded-md p-5 lg:ml-5">
        <p className={s.title}>{i18n.t('tipping.prizes.auto')}</p>
        <p className="mt-4">{i18n.t('tipping.prizes.auto.description')}</p>
        {(isDesktop || autoPrizesOpen) &&
          rounds?.pageText?.map(({ text, title }, i) => (
            <p className="mt-5" key={title}>
              <span className="font-bold">
                {i + 1}. {title}
              </span>
              <span> - {text}</span>
            </p>
          ))}
        {!isDesktop && (
          <PlainButton className={s.prizesOpen} onClick={() => setAutoPrizesOpen(!autoPrizesOpen)}>
            <ChevronIcon className={`h-5 w-5 ${!autoPrizesOpen ? 'rotate-90' : '-rotate-90'}`} />
          </PlainButton>
        )}
      </div>
    </div>
  );

  return (
    <div className="GlobalPad bg-white">
      {!isDesktop ? (
        <div className="py-5 space-y-5">
          {activeRound ? (
            editFields
          ) : (
            <>
              {autoPrizes}
              {roundsList}
            </>
          )}
        </div>
      ) : (
        <div className="py-10 flex divide-x h-full ">
          {roundsList}
          {activeRound && editFields}
          {autoPrizes}
        </div>
      )}
      <DeletePrizeModal
        open={deleteModal}
        close={closeModal}
        confirm={() =>
          remove(gameId, venueId, activeRound?.id, prize.applyTo)
            .then(res => setRounds({ ...rounds, roundPrizes: res.getResult() }))
            .finally(() => {
              fireEvent(GACategories.FOOTY_TIPPING, GATags.DELETE_PRIZE);
              setActiveRound(null);
              closeModal();
            })
        }
        applyToText={applyToOptions[prize.applyTo]}
      />
    </div>
  );
};

Prizes.propTypes = {
  fetchRounds: PropTypes.func.isRequired,
  update: PropTypes.func.isRequired,
  remove: PropTypes.func.isRequired,
  isDesktop: PropTypes.bool.isRequired,
  league: PropTypes.shape(),
};

Prizes.defaultProps = {
  league: null,
};

const mapStateToProps = state => ({
  league: getVenueLeague(state),
  isDesktop: getIsDesktop(state),
});

const mapDispatchToProps = {
  fetchRounds: fetchLeaguePrizes,
  update: updateLeaguePrizes,
  remove: removeLeaguePrizes,
};

export default connect(mapStateToProps, mapDispatchToProps)(Prizes);

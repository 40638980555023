import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { t } from 'i18next';
import { useLocation } from 'react-router-dom';
import PlainButton from '../../../../components/PlainButton/PlainButton';
import MPModal from '../../../../components/MPModal/MPModal';
import { venueProps } from '../../../../utils/customPropTypes';
import { getGooglePhotos, removeVenueImage } from '../../../../store/api/venues';
import SimpleCheckbox from '../../../../components/Checkbox/SimpleCheckbox';
import CloseIcon from '../../../../components/Icons/CloseIcon';
import EditIcon from '../../../../components/Icons/EditIcon';
import InputUpload from '../../../../components/FileUpload/InputUpload';
import google from './icons/google.png';
import UploadIcon from '../../../../components/Icons/UploadIcon';
import heart from './icons/heart.png';
import { base64DownscaleUpload } from '../../../../utils/general';
import LoadingAnimation from '../../../../components/LoadingAnimation/LoadingAnimation';
import ChevronIcon from '../../../../components/Icons/ChevronIcon';
import WarningIcon from '../../../../components/Icons/WarningIcon';
import { GACategories, GATags, fireEvent } from '../../../../utils/trackingHelper';

const ProfilePicTab = () => (
  <p className="absolute top-0 left-0 bg-secondary py-2 px-3 rounded-br-lg text-xs font-semibold">
    {t('signup.photos.profilePic')}
  </p>
);

const Photos = ({
  venue,
  fetchGooglePhotos,
  venueImages,
  setVenueImages,
  fieldErrors,
  setFieldErrors,
  removeImage,
  showTitle,
  uploadingImages,
}) => {
  const [googlePhotos, setGooglePhotos] = useState(null);
  const [showGoogleModal, setShowGoogleModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const { pathname } = useLocation();

  const onGoogleModalChange = () => setShowGoogleModal(!showGoogleModal);
  const hasVenueImages = venueImages?.length > 0;

  const track = tag => fireEvent(GACategories.PHOTOS, tag, { venueId: venue.id, page: pathname });

  useEffect(() => {
    if (!googlePhotos && venue.id) {
      setLoading(true);
      fetchGooglePhotos(venue.id)
        .then(r => setGooglePhotos(r.getResult()))
        .finally(() => setLoading(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [venue.id, googlePhotos]);

  const onDrop = useCallback(
    acceptedFiles => {
      track(hasVenueImages ? GATags.PHOTO_UPLOAD_SMALL : GATags.PHOTO_UPLOAD);
      if (acceptedFiles?.length === 0) {
        return;
      }
      const oldVenueImages = [...venueImages];

      const promises = acceptedFiles.map(async (f, i) => ({
        source: 'upload',
        image: await base64DownscaleUpload(f),
        id: Date.now(),
        profilePicture: oldVenueImages.length === 0 && i === 0,
      }));

      Promise.all(promises).then(values => setVenueImages([...oldVenueImages, ...values]));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [venueImages],
  );

  const profilePicture = venueImages.find(ph => ph?.profilePicture);

  const buttonStyles = `flex bg-white border border-mpGrey items-center px-4 mb-6 ${
    hasVenueImages ? 'ml-3 rounded-full py-5' : 'rounded-lg py-8 w-full'
  }`;

  const iconStyle = 'h-6 w-6';

  const editIcon = (
    <EditIcon className="absolute top-2 right-2 bg-[white]/80 rounded-full p-2 h-10 w-10" />
  );

  const filterImages = withErrors => {
    const ids = fieldErrors?.map(e => e.id);
    return venueImages?.filter(i => (withErrors ? ids?.includes(i.id) : !ids?.includes(i.id)));
  };

  return (
    <>
      {uploadingImages && (
        <div className="fixed inset-0 flex items-center justify-center z-10">
          <div className="fixed inset-0 bg-black opacity-60" />
          <div>
            <LoadingAnimation />
            <p className="text-center text-white opacity-90">{t('signup.photos.uploading')}</p>
          </div>
        </div>
      )}
      {showTitle && (
        <h1 className="headingFont text-4xl md:text-5xl lg:text-6xl pb-2">
          {t('editProfile.tabs.photos')}
        </h1>
      )}
      <div className={hasVenueImages ? 'flex' : ''}>
        {hasVenueImages && (
          <div className="flex-1">
            <p className="font-bold">{t('signup.photos.addMore')}</p>
            <p className="opacity-60">{t('signup.photos.reorder')}</p>
          </div>
        )}
        {loading ? (
          <LoadingAnimation size={75} />
        ) : (
          <>
            <InputUpload
              className={buttonStyles}
              acceptedFiles="image/jpeg, image/png"
              onDrop={onDrop}
              allowMultiple
            >
              <UploadIcon className={hasVenueImages ? iconStyle : null} />
              {!hasVenueImages && (
                <div className="ml-4">
                  <p className="font-bold">{t('signup.photos.upload')}</p>
                  <p className="text-xs opacity-60 whitespace-pre-line">
                    {t('signup.photos.uploadHelp')}
                  </p>
                </div>
              )}
            </InputUpload>
            {googlePhotos?.length > 0 && (
              <PlainButton
                className={buttonStyles}
                onClick={() => {
                  track(hasVenueImages ? GATags.PHOTO_GOOGLE_SMALL : GATags.PHOTO_GOOGLE);
                  onGoogleModalChange();
                }}
                testId="googlePhotos"
              >
                <img className={hasVenueImages ? iconStyle : null} alt="" src={google} />
                {!hasVenueImages && <p className="ml-4 font-bold">{t('signup.photos.google')}</p>}
              </PlainButton>
            )}
          </>
        )}
      </div>
      {profilePicture && (
        <div className="relative rounded-lg h-44 md:h-60 w-full overflow-hidden mb-4">
          <ProfilePicTab />
          <img className="w-full h-full object-cover" alt="" src={profilePicture.image} />
        </div>
      )}
      {hasVenueImages && (
        <div className="grid grid-cols-2 gap-4 mb-8">
          {venueImages
            ?.filter(ph => !ph?.profilePicture)
            .map(vp => {
              const hasError = fieldErrors?.some(fe => fe.id === vp.id);
              return (
                <PlainButton
                  onClick={() => {
                    track(GATags.PHOTO_EDIT);
                    setSelectedImage(vp);
                  }}
                  key={vp.id}
                  className={`rounded-lg relative h-28 md:h-36 overflow-hidden ${
                    hasError ? 'border-2 border-lightRed' : ''
                  }`}
                >
                  {editIcon}
                  <img className="w-full h-full object-cover" alt="" src={vp.image} />
                </PlainButton>
              );
            })}
        </div>
      )}
      <GooglePhotoModal
        open={showGoogleModal}
        onClose={onGoogleModalChange}
        googlePhotos={googlePhotos}
        selectPhotos={setVenueImages}
        initialPhotos={venueImages}
        track={track}
      />
      <SelectedImageModal
        selectedImage={selectedImage}
        updateOrder={(moveUp = false) => {
          const copy = [...venueImages];
          const selectedIndex = venueImages.findIndex(ph => ph.id === selectedImage.id);
          if (moveUp && selectedIndex === 0) {
            return;
          }
          if (!moveUp && selectedIndex === venueImages.length - 1) {
            return;
          }
          let movingValue = 1;
          if (moveUp) {
            movingValue = -1;
          }
          const movingElement = copy[selectedIndex + movingValue];
          copy[selectedIndex + movingValue] = selectedImage;
          copy[selectedIndex] = movingElement;
          track(moveUp ? GATags.PHOTO_MOVE_UP : GATags.PHOTO_MOVE_DOWN);
          setVenueImages(copy);
          setSelectedImage(null);
        }}
        removeImage={() => {
          if (selectedImage.source === 'db') {
            removeImage(venue.id, selectedImage.id).then(() =>
              setVenueImages(venueImages.filter(ph => ph.id !== selectedImage.id)),
            );
          } else {
            setVenueImages(venueImages.filter(ph => ph.id !== selectedImage.id));
          }

          if (selectedImage.profilePicture) {
            return;
          }
          track(GATags.PHOTO_DELETE);
          setSelectedImage(null);
        }}
        makeProfilePic={() => {
          const copy = [...venueImages];
          const selectedIndex = venueImages.findIndex(ph => ph.id === selectedImage.id);
          const profilePicIndex = venueImages.findIndex(ph => ph.profilePicture);
          if (profilePicIndex >= 0) {
            copy[profilePicIndex].profilePicture = false;
          }
          copy[selectedIndex].profilePicture = true;
          track(GATags.PHOTO_MAKE_PROFILE_PIC);
          setVenueImages(copy);
          setSelectedImage(null);
        }}
        onClose={() => setSelectedImage(null)}
      />
      <ErrorModal
        open={Boolean(fieldErrors)}
        images={filterImages(true)}
        onClose={() => {
          track(GATags.PHOTO_ERROR);
          setVenueImages(filterImages());
          setFieldErrors(null);
        }}
      />
    </>
  );
};

const GooglePhotoModal = ({ open, onClose, googlePhotos, selectPhotos, initialPhotos, track }) => {
  const [photosToAdd, setPhotosToAdd] = useState(initialPhotos);

  useEffect(() => {
    setPhotosToAdd(initialPhotos);
  }, [initialPhotos]);

  const isDisabled = photosToAdd.length === 0;

  return (
    <MPModal open={open} onClose={onClose} className="max-w-lg text-center pt-16 rounded-2xl">
      <PlainButton
        className="flex absolute right-4 top-4 p-2 bg-mpGrey rounded-full outline-none"
        onClick={onClose}
      >
        <CloseIcon className="w-8 h-8" />
      </PlainButton>
      <div className="px-8">
        <h1 className="headingFont text-4xl md:text-5xl">{t('signup.photos.google.title')}</h1>
        <p className="opacity-90 md:text-xl pt-2 pb-8">{t('signup.photos.google.subtitle')}</p>
        <div className="grid grid-cols-2 gap-4 mb-8">
          {googlePhotos?.map(g => {
            const photoIsAdded = photosToAdd.some(ph => ph.id === g.id);
            return (
              <PlainButton
                onClick={() => {
                  track(photoIsAdded ? GATags.GOOGLE_PHOTO_REMOVED : GATags.GOOGLE_PHOTO_ADDED);
                  if (photoIsAdded) {
                    setPhotosToAdd(photosToAdd.filter(ph => ph.id !== g.id));
                  } else {
                    setPhotosToAdd([
                      ...photosToAdd,
                      {
                        source: 'google',
                        profilePicture: photosToAdd.length === 0,
                        id: g.id,
                        image: g.imageUrl,
                      },
                    ]);
                  }
                }}
                key={g.id}
                className={`relative rounded-lg h-28 md:h-36 overflow-hidden ${
                  photoIsAdded ? 'border-2 border-secondary' : ''
                }`}
                testId="selectImage"
              >
                <img className="w-full h-full object-cover" alt="" src={g.imageUrl} />
                <div className="absolute top-4 right-4 bg-[black]/30 rounded-full h-8 w-8 flex items-center justify-center">
                  <SimpleCheckbox variant="secondary" checked={photoIsAdded} />
                </div>
              </PlainButton>
            );
          })}
        </div>
      </div>
      <div className="border-t border-mpGrey p-4">
        <PlainButton
          disabled={isDisabled}
          className={`w-full py-3 rounded-lg font-semibold ${
            isDisabled ? 'bg-mpGrey text-standardGrey' : 'bg-tertiary text-primaryButtonText'
          }`}
          onClick={() => {
            onClose();
            selectPhotos(photosToAdd);
          }}
          testId="addGooglePhotos"
        >
          <p>{t('signup.photos.google.button')}</p>
        </PlainButton>
      </div>
    </MPModal>
  );
};

const SelectedImageModal = ({
  selectedImage,
  onClose,
  updateOrder,
  removeImage,
  makeProfilePic,
}) => {
  const buttonStyle = 'rounded-lg font-semibold w-full py-3 mb-4 flex items-center justify-center';
  return (
    <MPModal
      open={Boolean(selectedImage)}
      onClose={onClose}
      className="max-w-lg text-center pt-16 rounded-2xl pb-8"
    >
      <PlainButton
        className="flex absolute right-4 top-4 p-2 bg-mpGrey rounded-full outline-none"
        onClick={onClose}
      >
        <CloseIcon className="w-8 h-8" />
      </PlainButton>
      <div className="px-8">
        <h1 className="headingFont text-4xl md:text-5xl pb-8">{t('signup.photos.edit')}</h1>
        <div className="relative rounded-lg h-44 md:h-60 overflow-hidden mb-4">
          <img className="w-full h-full object-cover" alt="" src={selectedImage?.image} />
          {selectedImage?.profilePicture && <ProfilePicTab />}
        </div>

        {!selectedImage?.profilePicture && (
          <PlainButton className={`${buttonStyle} bg-black text-white`} onClick={makeProfilePic}>
            <img alt="" className="mr-2" src={heart} />
            {t('signup.photos.makeProfilePic')}
          </PlainButton>
        )}
        <PlainButton className={`${buttonStyle} bg-mpGrey`} onClick={() => updateOrder(true)}>
          <ChevronIcon className="w-6 -rotate-90 mr-2" />
          {t('signup.photos.moveUp')}
        </PlainButton>
        <PlainButton className={`${buttonStyle} bg-mpGrey`} onClick={() => updateOrder(false)}>
          <ChevronIcon className="w-6 rotate-90 mr-2" />
          {t('signup.photos.moveDown')}
        </PlainButton>
        {!selectedImage?.profilePicture && (
          <PlainButton className={`${buttonStyle} bg-[red]/10 text-lightRed`} onClick={removeImage}>
            {t('signup.photos.deletePhoto')}
          </PlainButton>
        )}
      </div>
    </MPModal>
  );
};

Photos.propTypes = {
  venueImages: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  setVenueImages: PropTypes.func.isRequired,
  venue: venueProps.isRequired,
  fetchGooglePhotos: PropTypes.func.isRequired,
  fieldErrors: PropTypes.arrayOf(PropTypes.shape()),
  setFieldErrors: PropTypes.func.isRequired,
  removeImage: PropTypes.func.isRequired,
  showTitle: PropTypes.bool,
  uploadingImages: PropTypes.bool.isRequired,
};

Photos.defaultProps = {
  fieldErrors: null,
  showTitle: true,
};

GooglePhotoModal.propTypes = {
  googlePhotos: PropTypes.arrayOf(PropTypes.shape()),
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectPhotos: PropTypes.func.isRequired,
  initialPhotos: PropTypes.arrayOf(PropTypes.shape().isRequired),
  track: PropTypes.func.isRequired,
};

GooglePhotoModal.defaultProps = {
  initialPhotos: [],
  googlePhotos: [],
};

SelectedImageModal.propTypes = {
  selectedImage: PropTypes.shape(),
  onClose: PropTypes.func.isRequired,
  updateOrder: PropTypes.func.isRequired,
  removeImage: PropTypes.func.isRequired,
  makeProfilePic: PropTypes.func.isRequired,
};
SelectedImageModal.defaultProps = {
  selectedImage: null,
};

const mdtp = {
  fetchGooglePhotos: getGooglePhotos,
  removeImage: removeVenueImage,
};

export default connect(null, mdtp)(Photos);

const ErrorModal = ({ open, images, onClose }) => (
  <MPModal open={open} className="max-w-md md:max-w-lg bg-transparent px-4">
    <div className="relative bg-white rounded-2xl text-center overflow-hidden max-h-[90vh]">
      <div className="p-8 pb-56 overflow-y-auto max-h-screen flex flex-col">
        <PlainButton className="p-2 bg-mpGrey rounded-full self-end" onClick={onClose}>
          <CloseIcon className="w-8 h-8" />
        </PlainButton>
        <h2 className="headingFont text-4xl md:text-5xl pt-4">{t('signup.photos.errorTitle')}</h2>
        <p className="md:text-xl pt-2 pb-8 text-lightRed">{t('signup.photos.errorSubtitle')}</p>
        {images?.map(i => (
          <div className="relative" key={i.id}>
            <img
              className="w-full h-44 object-cover mb-4 rounded-lg border-2 border-lightRed"
              src={i.image}
              alt=""
            />
            <WarningIcon className="absolute top-2 right-2 bg-[white]/80 text-lightRed rounded-full p-2 h-10 w-10" />
          </div>
        ))}
      </div>
      <div className="fixed bottom-0 inset-x-0 bg-white mx-8 py-4">
        <PlainButton className="rounded-lg font-semibold w-full py-3 bg-mpGrey" onClick={onClose}>
          {t('general_close')}
        </PlainButton>
      </div>
    </div>
  </MPModal>
);

ErrorModal.propTypes = {
  open: PropTypes.bool,
  images: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  onClose: PropTypes.func.isRequired,
};

ErrorModal.defaultProps = {
  open: false,
};

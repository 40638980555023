import { toast } from 'react-toastify';
import i18n from 'i18next';
import * as actions from './actions';
import {
  getOffers,
  getOffer,
  stopOffer,
  createOffer,
  updateOffer,
  getCompetitions,
} from '../api/offers';
import { setHasUnsavedChanges } from '../navigation/actions';
import routes from '../../routes/paths';

export function fetchOffers(venueID, limit) {
  return function _fetchOffers(dispatch) {
    dispatch(actions.fetchOffersPending());
    return dispatch(getOffers(venueID, 0, limit))
      .then(res => res && dispatch(actions.fetchOffersSuccess(res)))
      .catch(() => dispatch(actions.fetchOffersError()));
  };
}

export function fetchMoreOffers(venueID, offset, limit) {
  return function _fetchMoreOffers(dispatch) {
    dispatch(actions.fetchMoreOffersPending());
    return dispatch(getOffers(venueID, offset, limit))
      .then(response => response.getResult())
      .then(res => dispatch(actions.fetchMoreOffersSuccess(res)))
      .catch(() => dispatch(actions.fetchMoreOffersError()));
  };
}

export function fetchOffer(venueID, offerID) {
  return function _fetchOffer(dispatch) {
    dispatch(actions.fetchOfferPending());
    return dispatch(getOffer(venueID, offerID))
      .then(response => response.getResult())
      .then(res => dispatch(actions.fetchOfferSuccess(res)))
      .catch(() => dispatch(actions.fetchOfferError()));
  };
}

export function stopOfferThunk(venueID, offerID) {
  return function _stopOfferThunk(dispatch) {
    dispatch(actions.stopOfferPending());
    return dispatch(stopOffer(venueID, offerID))
      .then(response => response.getResult())
      .then(res => dispatch(actions.stopOfferSuccess(res)))
      .catch(() => dispatch(actions.stopOfferError()));
  };
}

export function createOfferThunk(venueID, offer, navigate) {
  return function _createOfferThunk(dispatch) {
    dispatch(actions.createOfferPending());
    return dispatch(createOffer(venueID, offer))
      .then(() => {
        dispatch(setHasUnsavedChanges(false));
        return dispatch(actions.createOfferSuccess());
      })
      .then(() => {
        toast.success(i18n.t('offers.created'));
        return navigate(routes.offers.path);
      })
      .catch(err => {
        toast.error(err.message);
        return dispatch(actions.createOfferError(err.error));
      });
  };
}

export function updateOfferThunk(venueID, offer, offerID, navigate) {
  return function _updateOfferThunk(dispatch) {
    dispatch(actions.updateOfferPending());
    return dispatch(updateOffer(venueID, offer, offerID))
      .then(() => {
        dispatch(setHasUnsavedChanges(false));
        return dispatch(actions.updateOfferSuccess());
      })
      .then(() => {
        toast.success(i18n.t('offers.updated'));
        return navigate(routes.offers.path);
      })
      .catch(err => {
        toast.error(err.message);
        return dispatch(actions.updateOfferError(err.error));
      });
  };
}

export function getCompetitionsForVenue(venueID) {
  return function _getCompetitionsForVenue(dispatch) {
    return dispatch(getCompetitions(venueID))
      .then(response => response.getResult())
      .then(res => dispatch(actions.getCompetitionsSuccess(res)));
  };
}

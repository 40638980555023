import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { t } from 'i18next';
import { getVenue } from '../../store/venue/selectors';
import { historyProps, venueProps } from '../../utils/customPropTypes';
import { findDomainExt, getWebsiteURL } from '../../utils/environment';
import Snippet from '../../components/Snippet';
import { fireEvent, GACategories, GATags } from '../../utils/trackingHelper';
import PlainButton from '../../components/PlainButton/PlainButton';
import ChevronIcon from '../../components/Icons/ChevronIcon';
import { SliderNavigation } from './common';
import { useHiddenByPermissions } from '../../utils/CustomHooks';
import { cdnUrl } from '../../utils/constants';
import HelpIcon from '../../components/Icons/HelpIcon';

const CDN = `${cdnUrl}/integration/widget`;

const examples = [
  {
    image: {
      mobile: `${CDN}/laptop.png`,
      tablet: `${CDN}/laptop.png`,
      desktop: `${CDN}/laptop.png`,
    },
    text: 'general_desktop',
  },
  {
    image: {
      mobile: `${CDN}/tablet1.png`,
      tablet: `${CDN}/tablet2.png`,
      desktop: `${CDN}/tablet3.png`,
    },
    text: 'general_tablet',
  },
  {
    image: {
      mobile: `${CDN}/mobile1.png`,
      tablet: `${CDN}/mobile2.png`,
      desktop: `${CDN}/mobile3.png`,
    },
    text: 'general_mobile',
  },
];

const WidgetStandard = ({ venue, title, history }) => {
  const [id, setId] = useState(2);

  const snippet = venue && (
    <Snippet
      id="widget"
      codeSnippet={`
 <a style="font-size: 10px;opacity: 0.87" href="${getWebsiteURL()}">${t('integrate_poweredBy')}</a>
 <iframe
   src="https://widget.${findDomainExt()}/?id=${venue.id}"
   width="100%"
   height="800px"
   frameBorder="0">
</iframe>
`}
      gaTracking={() => fireEvent(GACategories.SOCIAL_MEDIA_CONTENT, GATags.COPIED_WIDGET_CODE)}
    />
  );

  const sliderNav = (
    <SliderNavigation
      clickLeft={() => setId(id === 0 ? examples.length - 1 : id - 1)}
      clickRight={() => setId(id === examples.length - 1 ? 0 : id + 1)}
      textComponent={<p className="md:text-xl font-bold opacity-80">{t(examples[id].text)}</p>}
      title={t('integrate_widgetExamples')}
    />
  );

  const integrateWithLink = (text, url) => (
    <li>
      <a href={url} rel="noreferrer" target="_blank" className="md:text-xl underline">
        {t(text)}
      </a>
    </li>
  );

  useHiddenByPermissions();

  return (
    <div className="bg-offWhite flex-1">
      <div className="flex items-center p-4 md:p-6 lg:px-10 mb-8 md:mb-12 lg:mb-14 border-b border-mpGrey bg-white">
        <div className="flex-1">
          <PlainButton className="flex items-center" onClick={() => history.goBack()}>
            <ChevronIcon className="h-6 w-6 md:h-8 md:w-8 rotate-180" />
            <p className="text-xl font-semibold hidden md:flex ml-2">{t('general_back')}</p>
          </PlainButton>
        </div>
        <h1 className="headingFont text-2xl md:text-4xl">{title}</h1>
        <div className="flex-1" />
      </div>

      <div className="flex px-4 md:px-6 lg:px-10">
        <div className="flex-1 lg:pr-10">
          <h2 className="text-xl md:text-2xl leading-6 font-bold pb-2">
            {t('integrate_subtitle')}
          </h2>
          <p className="md:text-xl opacity-80 pb-4">{t('integrate_widgetDescription1')}</p>
          <p className="md:text-xl opacity-80">{t('integrate_widgetDescription2')}</p>

          {snippet}

          <div className="lg:hidden">
            {sliderNav}
            <div className="flex justify-center mb-6 md:mb-8 mt-2 bg-white border border-mpGrey rounded-2xl p-2 pb-0">
              <img
                alt={t(examples[id].text)}
                src={examples[id].image.mobile}
                className="object-contain h-44 sm:hidden"
              />
              <img
                alt={t(examples[id].text)}
                src={examples[id].image.tablet}
                className="object-contain h-96 hidden sm:flex"
              />
            </div>
          </div>

          <h2 className="text-xl md:text-2xl leading-6 font-bold py-4 border-t border-mpGrey">
            {t('integrate_widgetSubtitle')}
          </h2>
          <div className="flex flex-col gap-2 pb-8">
            {integrateWithLink('integrate_widgetWix', 'https://youtu.be/L5jTUKTgruw')}
            {integrateWithLink('integrate_widgetSquarespace', 'https://youtu.be/C2MgY-yo0YQ')}
          </div>

          <div className="flex items-center pb-1">
            <p className="md:text-xl font-bold pr-1">{t('integrate_widgetHelp')}</p>
            <HelpIcon className="h-6" />
          </div>
          <p className="md:text-xl opacity-60 pb-14">
            {t('integrate_widgetHelpDescription')}
            <a className="underline" href={`mailto:${t('integrate_widgetHelpEmail')}`}>
              {t('integrate_widgetHelpEmail')}
            </a>
          </p>
        </div>

        <div className="flex-1 hidden lg:block border-l border-mpGrey pl-10 pt-8">
          {sliderNav}
          <div className="flex justify-center mx-auto mt-2 max-h-[716px]">
            <img
              alt={t(examples[id].text)}
              src={examples[id].image.desktop}
              className="object-contain"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

WidgetStandard.propTypes = {
  venue: venueProps.isRequired,
  title: PropTypes.string.isRequired,
  history: historyProps.isRequired,
};

const mapStateToProps = state => ({
  venue: getVenue(state),
});

export default connect(mapStateToProps)(WidgetStandard);

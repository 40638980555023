import React from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18next';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import routes from '../../../routes/paths';
import { getUser } from '../../../store/auth/selectors';
import { userProps, venueProps } from '../../../utils/customPropTypes';
import { checkAuthentication } from '../../../store/auth/thunks';
import { getVenue } from '../../../store/venue/selectors';

const SwitchProfile = ({ currentVenue, member, checkAuth }) => {
  if (member?.venues?.length < 2) {
    return null;
  }

  return (
    <div className="mb-4 mx-4 lg:mx-0 text-left overflow-x-hidden">
      <p className="text-xs font-semibold opacity-60 text-white uppercase pt-4">
        {t('titles.switchProfiles')}
      </p>
      <div>
        {member.venues.map(
          v =>
            v?.id !== currentVenue?.id && (
              <Link
                key={v?.id}
                className="flex items-center gap-2 py-3 hover:font-bold"
                onClick={() => checkAuth(v?.id)}
                to={`${routes.home.path}?venueId=${v?.id}`}
              >
                <img
                  className="rounded-full border-2 border-white h-8 w-8"
                  alt={v?.name}
                  src={v?.profilePicture['200_160']}
                />
                <div className="flex flex-col text-white">
                  <p className="line-clamp-2 text-sm">{v?.name}</p>
                  <p className="text-xs opacity-60">{v?.city}</p>
                </div>
              </Link>
            ),
        )}
      </div>
    </div>
  );
};

SwitchProfile.propTypes = {
  currentVenue: venueProps,
  member: userProps.isRequired,
  checkAuth: PropTypes.func.isRequired,
};

SwitchProfile.defaultProps = {
  currentVenue: null,
};

const mapStateToProps = state => ({
  currentVenue: getVenue(state),
  member: getUser(state),
});

const mapDispatchToProps = {
  checkAuth: checkAuthentication,
};

export default connect(mapStateToProps, mapDispatchToProps)(SwitchProfile);

import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import SimpleCheckbox from './SimpleCheckbox';

const Checkbox = ({
  onChange,
  label,
  checked,
  indeterminate,
  className,
  inputStyle,
  labelStyle,
  variant,
}) => {
  // in order to allow clicking the label control the checkbox we need all checkboxes on a page to have a unique reference
  const [id] = useState(uuidv4());

  return (
    <div className={`relative py-1 pr-3 flex items-center ${className}`}>
      <SimpleCheckbox
        id={id}
        indeterminate={indeterminate}
        variant={variant}
        checked={checked}
        onChange={onChange}
        type="checkbox"
        className={inputStyle}
      />
      <label className={`${labelStyle} ml-2 text-sm cursor-pointer`} htmlFor={id}>
        {label}
      </label>
    </div>
  );
};

Checkbox.propTypes = {
  checked: PropTypes.bool.isRequired,
  label: PropTypes.string,
  onChange: PropTypes.func,
  indeterminate: PropTypes.bool,
  className: PropTypes.string,
  inputStyle: PropTypes.string,
  labelStyle: PropTypes.string,
  variant: PropTypes.oneOf(['primary', 'secondary']),
};

Checkbox.defaultProps = {
  indeterminate: false,
  label: null,
  onChange: () => {},
  className: '',
  inputStyle: '',
  labelStyle: '',
  variant: 'primary',
};

export default Checkbox;

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getIsDesktop } from '../../store/app/selectors';

const DivHoverEffect = ({ isDesktop, onHover, children, className }) => (
  <div
    role="button"
    onMouseEnter={() => isDesktop && onHover()}
    onMouseLeave={() => isDesktop && onHover()}
    onClick={() => !isDesktop && onHover()}
    onKeyDown={() => !isDesktop && onHover()}
    tabIndex={0}
    className={`focus:outline-none ${className}`}
  >
    {children}
  </div>
);

DivHoverEffect.propTypes = {
  isDesktop: PropTypes.bool.isRequired,
  onHover: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

DivHoverEffect.defaultProps = {
  className: '',
};

const mapStateToProps = state => ({
  isDesktop: getIsDesktop(state),
});

export default connect(mapStateToProps)(DivHoverEffect);

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18next';
import { connect } from 'react-redux';
import { countryProps } from '../../../utils/customPropTypes';
import { getCountries } from '../../../store/app/selectors';
import MPModal from '../../../components/MPModal/MPModal';
import Input from '../Input';
import DropdownSelector from '../../../components/DropdownSelector/DropdownSelector';
import PlainButton from '../../../components/PlainButton/PlainButton';
import AddressSearch from '../AddressSearch';
import CloseIcon from '../../../components/Icons/CloseIcon';
import SpinnerButton from '../../../components/SpinnerButton/SpinnerButton';

const DetailsModal = ({ countries, data, open, onClose, onSubmit, isBilling }) => {
  const [newData, setNewData] = useState(data);
  const [addressQuery, setAddressQuery] = useState('');
  const [loading, setLoading] = useState(false);

  const onInputChange = changes => setNewData(prev => ({ ...prev, ...changes }));
  const selectedCountry = countries.find(c => c.id === newData.countryId);

  const canSubmit =
    !loading &&
    (newData?.name?.length > 0 || newData?.businessName?.length > 0) &&
    newData?.address1?.length > 0 &&
    newData?.city?.length > 0 &&
    newData?.countryId &&
    newData?.postCode?.length > 0;

  const onClick = () => {
    setLoading(true);
    onSubmit(newData).finally(() => {
      onClose();
      setLoading(false);
    });
  };

  const enterKey = event => {
    if (event.key === 'Enter' && canSubmit) {
      onClick();
    }
  };

  return (
    <MPModal
      open={open}
      onClose={onClose}
      className="min-h-0 max-h-[90vh] max-w-md rounded-2xl noScrollBar"
    >
      <PlainButton
        className="absolute right-4 top-4 p-2 bg-mpGrey rounded-full outline-none"
        onClick={onClose}
      >
        <CloseIcon className="w-8 h-8" />
      </PlainButton>

      <h1 className="headingFont text-4xl sm:text-5xl text-center py-8 px-10 pt-16 sm:mt-2">
        {t(isBilling ? 'billing_detailsModalTitle' : 'signup_venue_detailsModalTitle')}
      </h1>
      <div className="flex flex-col gap-4 mb-8 px-8">
        {isBilling ? (
          <>
            <Input
              label={t('auth.signup.form.first_name')}
              placeholder={t('settings.first_name_placeholder')}
              value={newData.firstName}
              onChange={v => onInputChange({ firstName: v })}
              onKeyPress={enterKey}
            />
            <Input
              label={t('auth.signup.form.last_name')}
              placeholder={t('settings.last_name_placeholder')}
              value={newData.lastName}
              onChange={v => onInputChange({ lastName: v })}
              onKeyPress={enterKey}
            />
            <Input
              label={t('billing_detailsBusinessLabel')}
              placeholder={t('billing_detailsBusinessPlaceholder')}
              value={newData.businessName}
              onChange={v => onInputChange({ businessName: v })}
              onKeyPress={enterKey}
            />
          </>
        ) : (
          <Input
            label={t('signup_venue_nameLabel')}
            placeholder={t('signup_venue_namePlaceholder')}
            value={newData.name}
            onChange={v => onInputChange({ name: v })}
            onKeyPress={enterKey}
          />
        )}
        <AddressSearch
          value={addressQuery}
          onChange={v => setAddressQuery(v)}
          onItemClick={i => onInputChange(i)}
          countries={countries}
        />
        <Input
          label={t('signup_venue_address2Label')}
          placeholder={t('signup_venue_address2Placeholder')}
          value={newData.address1}
          onChange={v => onInputChange({ address1: v })}
          onKeyPress={enterKey}
        />
        <Input
          label={t('signup_venue_address3Label')}
          placeholder={t('signup_venue_address3Placeholder')}
          value={newData.address2}
          onChange={v => onInputChange({ address2: v })}
          onKeyPress={enterKey}
        />
        <Input
          label={t('signup_venue_address4Label')}
          placeholder={t('signup_venue_address4Placeholder')}
          value={newData.city}
          onChange={v => onInputChange({ city: v })}
          onKeyPress={enterKey}
        />
        <DropdownSelector
          label={t('signup_venue_address5Label')}
          placeholder={t('signup_venue_address5Placeholder')}
          options={countries.map(c => ({ key: c.id, value: c.name }))}
          selected={selectedCountry?.name}
          onValueChange={v => onInputChange({ countryId: v.key })}
        />
        <Input
          label={t('signup_venue_address6Label')}
          placeholder={t('signup_venue_address6Placeholder')}
          value={newData.postCode}
          onChange={v => onInputChange({ postCode: v })}
          onKeyPress={enterKey}
        />
      </div>

      <div className="sticky bottom-0 p-4 bg-white border-t border-mpGrey sm:p-8">
        <SpinnerButton
          className={`py-4 w-full font-semibold rounded-lg ${
            canSubmit ? 'bg-secondary' : 'bg-mpGrey text-standardGrey'
          }`}
          onClick={onClick}
          disabled={!canSubmit}
          loading={loading}
        >
          {t('signup_venue_detailsModalButton')}
        </SpinnerButton>
      </div>
    </MPModal>
  );
};

DetailsModal.propTypes = {
  countries: PropTypes.arrayOf(countryProps).isRequired,
  data: PropTypes.shape().isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isBilling: PropTypes.bool,
};

DetailsModal.defaultProps = {
  isBilling: false,
};

const mstp = state => ({
  countries: getCountries(state),
});

export default connect(mstp)(DetailsModal);

import { toast } from 'react-toastify';
import i18next from 'i18next';
import * as actions from './actions';
import {
  getThemes,
  createPostPublish,
  getContent,
  getScheduledPosts,
  getPostedPosts,
  deletePost,
  updatePostPublish,
  getFacebookPages,
  selectFacebookPage,
  getAccountDetails,
  getEditableCountryCompetitions,
  getThemeData,
  getFonts,
  getThemeConfig,
  saveThemeConfig,
  getBespokePreviews,
  getCompetitionThemeConfig,
  saveCompetitionCustomConfig,
} from '../api/socialMedia';
import { getLimit, getOffset } from './selectors';

export const fetchThemes = venueId => dispatch =>
  dispatch(getThemes(venueId)).then(response => response.getResult());

export function fetchContentThunk(venueId) {
  return function _fetchContentThunk(dispatch, getState) {
    dispatch(actions.fetchContentPending());
    return dispatch(getContent(venueId, getLimit(getState()), 0))
      .then(result => dispatch(actions.fetchContentSuccess(result)))
      .catch(() => dispatch(actions.fetchContentError()));
  };
}

export function fetchContentNextPage(venueId) {
  return function _fetchContentThunk(dispatch, getState) {
    dispatch(actions.fetchNextContentPending());
    return dispatch(getContent(venueId, getLimit(getState()), getOffset(getState())))
      .then(result => dispatch(actions.fetchNextContentSuccess(result)))
      .catch(() => dispatch(actions.fetchNextContentError()));
  };
}

export function createPostPublishThunk(venueId, post, callback) {
  return function _createPostPublishThunk(dispatch) {
    dispatch(actions.postPublishPending());
    return dispatch(createPostPublish(venueId, post))
      .then(() => {
        toast.success(i18next.t('socialSuccess'));
        // optimistically change the fixture.postAlreadyScheduled or postAlreadySent status without re-fetching:
        if (post.locallyUpdatePosted) {
          dispatch(actions.postWasPublished(post.fixtures));
        } else {
          dispatch(actions.postWasScheduled(post.fixtures));
        }

        return dispatch(actions.postPublishSuccess());
      })
      .then(() => callback && callback())
      .catch(error => {
        if (error.error && error.code === 418) {
          Object.keys(error.error).map(key =>
            toast.error(`${key.charAt(0).toUpperCase() + key.slice(1)} ${error.error[key]}`),
          );
        }
        dispatch(actions.postPublishError());
      });
  };
}

export function updatePostPublishThunk(venueId, post, callback) {
  return function _updatePostPublishThunk(dispatch) {
    dispatch(actions.postPublishPending());
    return dispatch(updatePostPublish(venueId, post))
      .then(() => dispatch(actions.postPublishSuccess()))
      .then(() => callback && callback())
      .catch(() => dispatch(actions.postPublishError()));
  };
}

export function fetchSocialMediaScheduledPosts(venueId, limit) {
  return function _fetchSocialMediaScheduledPosts(dispatch) {
    dispatch(actions.fetchScheduledSocialPostsPending());
    return dispatch(getScheduledPosts(venueId, 0, limit))
      .then(result => dispatch(actions.fetchScheduledSocialPostsSuccess(result)))
      .catch(() => {
        dispatch(actions.fetchScheduledSocialPostsError());
        return false;
      });
  };
}

export function fetchSocialMediaPostedPosts(venueId, limit) {
  return function _fetchSocialMediaPostedPosts(dispatch) {
    dispatch(actions.fetchPostedSocialPostsPending());
    return dispatch(getPostedPosts(venueId, 0, limit))
      .then(result => dispatch(actions.fetchPostedSocialPostsSuccess(result)))
      .catch(() => {
        dispatch(actions.fetchPostedSocialPostsError());
        return false;
      });
  };
}

export function fetchSocialMediaNextScheduledPosts(venueId, offset, limit, callback) {
  return function _fetchSocialMediaNextScheduledPosts(dispatch) {
    dispatch(actions.fetchNextScheduledSocialPostsPending());
    return dispatch(getScheduledPosts(venueId, offset, limit))
      .then(result => dispatch(actions.fetchNextScheduledSocialPostsSuccess(result)))
      .then(() => callback && callback())
      .catch(() => {
        dispatch(actions.fetchNextScheduledSocialPostsError());
        return false;
      });
  };
}

export function fetchSocialMediaNextPostedPosts(venueId, offset, limit, callback) {
  return function _fetchSocialMediaNextPostedPosts(dispatch) {
    dispatch(actions.fetchNextPostedSocialPostsPending());
    return dispatch(getPostedPosts(venueId, offset, limit))
      .then(result => dispatch(actions.fetchNextPostedSocialPostsSuccess(result)))
      .then(() => callback && callback())
      .catch(() => {
        dispatch(actions.fetchNextPostedSocialPostsError());
        return false;
      });
  };
}

export function deleteSocialMediaPost(venueId, postId) {
  return function _deleteSocialMediaPost(dispatch) {
    dispatch(actions.deleteSocialPostPending());
    return dispatch(deletePost(venueId, postId))
      .then(() => dispatch(actions.deleteSocialPostSuccess(postId)))
      .catch(() => dispatch(actions.deleteSocialPostError()));
  };
}

export function fetchFacebookPages(venueId) {
  return function _fetchFacebookPages(dispatch) {
    dispatch(actions.fetchPagesStart());
    return dispatch(getFacebookPages(venueId))
      .then(response => response.getResult())
      .then(result => dispatch(actions.fetchPagesSuccess(result)))
      .catch(() => dispatch(actions.fetchPagesError()));
  };
}

export function fetchSocialMediaAccounts(venueId, facebook = true, twitter = true) {
  return function _fetchSocialMediaAccounts(dispatch) {
    dispatch(actions.fetchSocialAccountsStart(facebook, twitter));
    return dispatch(getAccountDetails(venueId))
      .then(response => response.getResult())
      .then(result => dispatch(actions.fetchSocialAccountsSuccess(result)))
      .catch(() => dispatch(actions.fetchSocialAccountsError()));
  };
}

export function selectFacebookPageThunk(venueId, page) {
  return function _selectFacebookPageThunk(dispatch) {
    return dispatch(selectFacebookPage(venueId, page))
      .then(() => dispatch(fetchSocialMediaAccounts(venueId, true, false)))
      .catch(() => {});
  };
}

export const fetchEditableCountryCompetitions = venueID => dispatch =>
  dispatch(getEditableCountryCompetitions(venueID)).then(res => res.getResult());

export const fetchThemeData = (venueId, type) => dispatch =>
  dispatch(getThemeData(venueId, type)).then(r => r.getResult());

export const fetchFontsForSM = venueId => dispatch =>
  dispatch(getFonts(venueId)).then(r => r.getResult());

export const fetchBespokePreviews = (
  venueId,
  font,
  color,
  language,
  secondaryTextFont,
  sexondaryTextColor,
  themeId,
) => dispatch =>
  dispatch(
    getBespokePreviews(
      venueId,
      font,
      color,
      language,
      themeId,
      secondaryTextFont,
      sexondaryTextColor,
    ),
  ).then(r => r.getResult());

export const fetchThemeConfig = venueId => dispatch =>
  dispatch(getThemeConfig(venueId)).then(r => r.getResult());

export const fetchCompetitionThemeConfig = (venueId, competitionId, type) => dispatch =>
  dispatch(getCompetitionThemeConfig(venueId, competitionId, type)).then(r => r.getResult());

export const saveSMConfig = (venueId, configJSON) => dispatch =>
  dispatch(saveThemeConfig(venueId, configJSON)).then(r => {
    toast.success(i18next.t('marketing.bespoke.saved'));
    return r;
  });

export const saveCompetitionConfig = (venueId, competitionId, config) => dispatch =>
  dispatch(saveCompetitionCustomConfig(venueId, competitionId, config)).then(r => {
    toast.success(i18next.t('marketing.bespoke.saved'));
    return r;
  });

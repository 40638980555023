import React from 'react';

const Garden = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="black">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.888 14.5783H6.38799L6.65723 12.9642H17.2936L17.0244 14.5783H12.5244L11.8513 18.6134H12.5331L12.2639 20.2275H9.26386L9.53309 18.6134H10.2149L10.888 14.5783Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.5072 2.93359L22.4178 7.11464L21.8297 8.66004H13.5115L12.9731 11.8882H11.3367L11.8752 8.66004H3.557L3.4756 7.11831L13.5072 2.93359ZM13.2222 4.7021L7.60346 7.04597H18.2174L13.2222 4.7021Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.67363 16.1924L4.93733 11.8037L3.28177 11.9727L2.98121 16.9749L1.93359 20.0949L3.50343 20.3602L4.36092 17.8064H6.39509L5.99124 20.2275H7.6276L8.30069 16.1924H4.67363Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.2005 16.1924L19.4009 11.8037L21 11.9727L19.6318 16.9749L19.6386 20.0949L17.9802 20.3602L17.9747 17.8064H15.9405L15.5367 20.2275H13.9003L14.5734 16.1924H18.2005Z"
    />
  </svg>
);

export default Garden;

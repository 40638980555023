import React from 'react';
import PropTypes from 'prop-types';

const LogoutIcon = ({ className, isActive }) => (
  <svg className={`stroke-current fill-current ${className}`} viewBox="0 0 24 24" fill="none">
    {isActive ? (
      <>
        <path
          d="M7.11954 4.3708L6.73065 4.3708L6.65489 4.75225L3.83289 18.9618L3.72048 19.5278L4.29754 19.5278L11.4964 19.5278L11.4964 21.4227L3.9168 21.4227C3.55534 21.4227 3.22829 21.4221 2.9345 21.3884C2.63969 21.3546 2.42477 21.2917 2.27462 21.197C2.14085 21.1126 2.03533 20.9893 1.97657 20.767C1.9132 20.5272 1.90183 20.1585 2.01286 19.5982L2.01292 19.5982L2.01424 19.5909L4.8558 3.98408C5.02992 3.12121 5.32967 2.79642 5.59091 2.6503C5.88858 2.4838 6.25095 2.47373 6.73778 2.47373L14.5263 2.47373L14.5263 4.3708L7.11954 4.3708Z"
          strokeWidth="0.947451"
        />
        <path
          d="M16.2948 9.91599L19.0857 9.91599L16.7763 7.28186L16.4664 8.83156L16.2948 9.91599ZM19.5058 10.8634L16.1449 10.8634L15.9681 11.9809L15.9663 11.9926L15.9638 12.0041L15.8057 12.7584L19.1899 12.7585L19.5058 10.8634ZM18.2895 13.7059L15.607 13.7059L15.1031 16.1094L18.2895 13.7059ZM20.2374 12.2366L20.7688 11.8358L20.3784 11.3905L20.2374 12.2366ZM15.0341 11.8212L15.1857 10.8634L14.3803 10.8634L14.3803 10.8634L8.2194 10.8633L7.90349 12.7583L14.8376 12.7584L15.0341 11.8212Z"
          strokeWidth="0.947451"
        />
      </>
    ) : (
      <>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          stroke="none"
          d="M6.88037 4.00225L14.9453 3.93157L14.9453 2.02248L6.47743 2.02248C5.66147 1.87933 5 2.43487 5 3.26329L2.00003 19.6269C2.00003 20.4553 2.6615 21.2429 3.47747 21.3861L11.9453 21.3861L11.9453 19.477L3.88041 19.5477L6.88037 4.00225Z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          stroke="none"
          d="M19.2747 10.6695L13.5474 10.6658L7.82015 10.662L7.45844 12.5549L18.9748 12.5549L19.2747 10.6695Z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          stroke="none"
          d="M20.9188 11.6284L16.3292 6.24756L14.7672 7.57986L18.219 11.6267L13.4808 15.6681L14.6187 17.0022L20.9188 11.6284Z"
        />
      </>
    )}
  </svg>
);

LogoutIcon.propTypes = {
  className: PropTypes.string,
  isActive: PropTypes.bool,
};

LogoutIcon.defaultProps = {
  className: '',
  isActive: false,
};

export default LogoutIcon;

/* global FB */
import React, { useEffect } from 'react';
import { parseISO, differenceInDays } from 'date-fns';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { t } from 'i18next';
import { updateFacebookInfoThunk, facebookLogoutThunk } from '../../store/venue/thunks';
import { getIsDisconnecting } from '../../store/venue/selectors';
import AuthoriseModal from '../AuthoriseModal/AuthoriseModal';
import { AuthModalStart, AuthModalError } from '../../store/venue/actions';
import { toggleAuthModal } from '../../store/settings/actions';
import { getShowAuthModal } from '../../store/settings/selectors';
import { fetchFacebookPages, selectFacebookPageThunk } from '../../store/socialMedia/thunks';
import { getPages, getIsFetchingFacebookAccount } from '../../store/socialMedia/selectors';
import { fireEvent, GATags, GACategories } from '../../utils/trackingHelper';
import Dropdown from '../Dropdown/Dropdown';
import PlainButton from '../PlainButton/PlainButton';
import FacebookIcon from '../Icons/FacebookIcon';
import ChevronIcon from '../Icons/ChevronIcon';
import CloseIcon from '../Icons/CloseIcon';
import RefreshIcon from '../Icons/RefreshIcon';
import Spinner from '../Spinner/Spinner';

export const statusStates = {
  connected: 'connected',
  notConnected: 'notConnected',
  needsReAuth: 'needsReAuth',
};

const FacebookButton = ({
  toggleable,
  selected,
  onToggle,
  facebookStart,
  facebookError,
  updateFacebookInfo,
  updateFacebookLogout,
  isLoading,
  showAuthModal,
  toggleModal,
  fetchPages,
  pages,
  selectPage,
  venueID,
  account,
  isFetchingAccount,
}) => {
  useEffect(() => {
    if (account && !account.pageName) {
      fetchPages(venueID);
    }
  }, [account, fetchPages, venueID]);

  const onLogin = () => {
    fireEvent(GACategories.SOCIAL_MEDIA_ACCOUNT, GATags.CONNECT_FACEBOOK_STARTED, { venueID });

    FB.login(
      res => {
        if (res.authResponse) {
          fireEvent(GACategories.SOCIAL_MEDIA_ACCOUNT, GATags.CONNECT_FACEBOOK_COMPLETED, {
            venueID,
          });
          updateFacebookInfo(venueID, res.authResponse);
        } else {
          facebookError();
        }
      },
      {
        scope: 'pages_manage_posts,business_management',
      },
    );
  };

  const onLogout = () => {
    FB.logout();
    fireEvent(GACategories.SOCIAL_MEDIA_ACCOUNT, GATags.DISCONNECT_FACEBOOK, { venueID });
    updateFacebookLogout(venueID);
  };

  const status = () => {
    if (account) {
      if (differenceInDays(parseISO(account.userTokenExpiryDate), new Date()) < 14) {
        return statusStates.needsReAuth;
      }
      return statusStates.connected;
    }
    return statusStates.notConnected;
  };
  const disconnectButton = () => (
    <Button
      className="bg-[#FFEBE9] text-[#991F16]"
      onClick={onLogout}
      isLoading={isLoading || isFetchingAccount}
      Icon={CloseIcon}
      text={t('editProfile.general.disconnectFb')}
    />
  );

  const getButton = () => {
    if (status() === statusStates.notConnected) {
      return (
        <Button
          className="bg-white border border-mpGrey"
          onClick={toggleModal}
          isLoading={isFetchingAccount}
          Icon={ChevronIcon}
          text={t('editProfile.general.connectFacebook')}
        />
      );
    }

    if (status() === statusStates.connected && !account.pageName) {
      if (pages.length === 0) {
        return disconnectButton();
      }
      return (
        <Dropdown
          data={pages.map(({ name, id, ...rest }) => ({ key: id, value: name, name, id, ...rest }))}
          containerStyle="w-full"
          buttonStyle="bg-white border border-mpGrey shadow-none w-full rounded-lg !p-4"
          buttonText={t('editProfile.general.choose')}
          onClickItem={page => selectPage(venueID, page)}
        />
      );
    }

    if (status() === statusStates.connected && !toggleable) {
      return disconnectButton();
    }
    if (status() === statusStates.needsReAuth) {
      return (
        <>
          <Button
            className="bg-[#FEEFE8] text-[#C25014]"
            onClick={onLogin}
            isLoading={isFetchingAccount}
            Icon={RefreshIcon}
            text={t('editProfile.general.reAuth')}
          />
          <p className="pt-1 text-sm text-[#C25014]">{t('editProfile.general.reAuthError')}</p>
        </>
      );
    }

    // only case left is connected and toggleable
    return (
      <Button
        className={`text-white ${selected ? 'bg-[#1877F2]' : 'bg-[#9da9c4]'}`}
        onClick={onToggle}
        Icon={ChevronIcon}
        text={t('marketing.postPublish.facebook')}
      />
    );
  };

  return (
    <>
      {getButton()}
      <AuthoriseModal
        socialMedia="facebook"
        open={showAuthModal}
        onClose={() => {
          facebookStart();
          toggleModal();
        }}
        onAction={onLogin}
        onCancel={() => toggleModal()}
      />
    </>
  );
};

FacebookButton.propTypes = {
  toggleable: PropTypes.bool,
  updateFacebookInfo: PropTypes.func.isRequired,
  selected: PropTypes.bool,
  onToggle: PropTypes.func,
  facebookStart: PropTypes.func.isRequired,
  facebookError: PropTypes.func.isRequired,
  updateFacebookLogout: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
  fetchPages: PropTypes.func.isRequired,
  pages: PropTypes.arrayOf(PropTypes.shape({ name: PropTypes.string })).isRequired,
  selectPage: PropTypes.func.isRequired,
  venueID: PropTypes.number.isRequired,
  isFetchingAccount: PropTypes.bool.isRequired,
  showAuthModal: PropTypes.bool,
  account: PropTypes.shape(),
};

FacebookButton.defaultProps = {
  toggleable: false,
  selected: false,
  account: null,
  onToggle: () => {},
  showAuthModal: false,
};

const mapStateToProps = state => ({
  isLoading: getIsDisconnecting(state),
  showAuthModal: getShowAuthModal(state),
  pages: getPages(state),
  isFetchingAccount: getIsFetchingFacebookAccount(state),
});

const mapDispatchToProps = dispatch => ({
  updateFacebookInfo: (venueId, facebookInfo) =>
    dispatch(updateFacebookInfoThunk(venueId, facebookInfo)),
  updateFacebookLogout: venueId => dispatch(facebookLogoutThunk(venueId)),
  facebookStart: () => dispatch(AuthModalStart()),
  facebookError: () => dispatch(AuthModalError()),
  toggleModal: () => dispatch(toggleAuthModal()),
  fetchPages: venueID => dispatch(fetchFacebookPages(venueID)),
  selectPage: (venueID, page) => dispatch(selectFacebookPageThunk(venueID, page)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FacebookButton);

const Button = ({ onClick, Icon, text, isLoading, className }) => (
  <PlainButton
    className={`flex items-center justify-center w-full gap-2 rounded-lg p-4 ${className}`}
    onClick={onClick}
    disabled={isLoading}
  >
    {isLoading ? (
      <Spinner className="w-6" />
    ) : (
      <>
        <FacebookIcon className="w-6 h-6" />
        <p className="flex-1 text-left font-semibold">{text}</p>
        <Icon className="w-6 h-6" />
      </>
    )}
  </PlainButton>
);

Button.propTypes = {
  onClick: PropTypes.func.isRequired,
  Icon: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
  isLoading: PropTypes.bool,
};

Button.defaultProps = {
  isLoading: false,
};

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { t } from 'i18next';
import { getVenue } from '../../store/venue/selectors';
import { venueProps } from '../../utils/customPropTypes';
import ChevronIcon from '../../components/Icons/ChevronIcon';
import ServesFood from '../../components/FacilitiesSelector/Icons/ServesFood';
import routes from '../../routes/paths';
import InformationIcon from './icons/InformationIcon';
import PhotoIcon from './icons/PhotoIcon';
import ChannelListIcon from './icons/ChannelListIcon';
import BookingIcon from './icons/BookingIcon';
import HeartIcon from './icons/HeartIcon';
import { GATags, fireEvent } from '../../utils/trackingHelper';
import { userIsOnOnTap } from '../../utils/environment';

const ProfilePageV2 = ({ venue }) => {
  const links = [
    {
      icon: <InformationIcon size={32} />,
      title: t('signup_venue_keyInfoTitle'),
      subtitle: t('profile.general.subtitle'),
      path: routes.profile.subRoutes.general.path,
    },
    {
      icon: <PhotoIcon />,
      title: t('editProfile.tabs.photos'),
      subtitle: t('profile.photos.subtitle'),
      path: routes.profile.subRoutes.photos.path,
    },
    {
      icon: <BookingIcon />,
      title: t('profile.bookings.title'),
      subtitle: t('profile.bookings.subtitle'),
      path: routes.profile.subRoutes.bookings.path,
    },
    {
      icon: <ServesFood />,
      title: t('editProfile.tabs.menu'),
      subtitle: t('profile.menu.subtitle'),
      path: routes.profile.subRoutes.menu.path,
    },
    {
      icon: <HeartIcon />,
      title: t('signup_favourites_title'),
      subtitle: t('profile.favourites.subtitle'),
      path: routes.profile.subRoutes.favourites.path,
    },
    {
      icon: <ChannelListIcon />,
      title: t('editProfile.preferences.channels'),
      subtitle: t('profile.channels.subtitle'),
      path: routes.profile.subRoutes.channels.path,
      hide: userIsOnOnTap,
    },
  ];

  return (
    <div className="bg-offWhite">
      <div className="max-w-[1072px] p-4 sm:p-6 lg:pl-16 lg:pt-14">
        <YourProfile venue={venue} />
        <div className="gap-2 sm:gap-4 lg:gap-8 grid sm:grid-cols-2 xl:grid-cols-3 pt-4 sm:pt-6 lg:pt-8">
          {links
            .filter(l => !l.hide)
            .map(l => (
              <ProfileLink key={l.path} link={l} venueId={venue.id} />
            ))}
        </div>
      </div>
    </div>
  );
};

const YourProfile = ({ venue }) => (
  <div className="relative rounded-2xl overflow-hidden">
    <div
      style={{ backgroundImage: `url(${venue.profilePicture.original})` }}
      className="bg-cover bg-center px-4 pb-4 sm:px-6 sm:pb-6 lg:px-8 lg:pb-8 pt-8  shadow-[inset_0_0_0_1000px_rgba(0,0,0,0.3)]"
    >
      <div className="text-white font-bold">
        <p className="opacity-60 uppercase text-xs sm:text-base">{t('home_yourProfile')}</p>
        <h2 className="headingFont text-4xl sm:text-6xl max-w-2xl">{venue.name}</h2>
      </div>
      <a
        href={venue.matchpintUrl}
        target="_blank"
        rel="noopener noreferrer"
        className="bg-white py-2 lg:py-3 px-6 rounded-full mt-4 inline-block"
        onClick={() => fireEvent(GATags.PROFILE_VIEW, null, { venueId: venue.id })}
      >
        <div className="flex items-center">
          <p className="font-semibold text-xs lg:text-base capitalize">{t('general_view')}</p>
          <ChevronIcon className="h-6" />
        </div>
      </a>
    </div>
  </div>
);

const ProfileLink = ({ link: { icon, title, subtitle, path }, venueId }) => (
  <Link
    className="rounded-lg border-mpGrey border p-4 bg-white flex sm:block items-center"
    to={path}
    onClick={() => fireEvent(GATags.PROFILE_SECTION, null, { venueId, page: path })}
  >
    {icon}
    <p className="font-semibold sm:font-bold sm:pt-4 pl-4 sm:pl-0">{title}</p>
    <p className="hidden sm:block text-sm opacity-60 pt-2">{subtitle}</p>
  </Link>
);

ProfileLink.propTypes = {
  link: PropTypes.shape({
    icon: PropTypes.node.isRequired,
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired,
  }).isRequired,
  venueId: PropTypes.number.isRequired,
};

const mapStateToProps = state => ({
  venue: getVenue(state),
});

ProfilePageV2.propTypes = {
  venue: venueProps.isRequired,
};

YourProfile.propTypes = {
  venue: venueProps.isRequired,
};

export default connect(mapStateToProps)(ProfilePageV2);

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18next';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import { venueProps } from '../../utils/customPropTypes';
import { getVenue } from '../../store/venue/selectors';
import { saveVenue as saveVenueThunk } from '../../store/api/venues';
import { fieldsChanged } from '../../utils/general';
import NumberSelector from '../SignupV2/NumberSelector';
import ProfileHeader from './ProfileHeader';
import KeyInfo from '../SignupV2/Stage3/Steps/KeyInfo';
import DetailsModal from '../SignupV2/Stage3/DetailsModal';
import OpeningHours, { compareOpeningTimes } from '../../components/OpeningHours/OpeningHours';
import FacilitiesSelector from '../../components/FacilitiesSelector/FacilitiesSelector';
import SocialMediaButtons from '../../components/SocialMediaButtons/SocialMediaButtons';
import InstagramButton from '../../components/SocialMediaButtons/InstagramButton';
import SaveBar from './SaveBar/SaveBar';
import { updateVenueSuccess } from '../../store/venue/actions';
import { userIsOnOnTap } from '../../utils/environment';

const GeneralPage = ({ venue, saveVenueChanges, refreshVenue }) => {
  const [details, setDetails] = useState({
    name: venue.name,
    address1: venue.address1,
    address2: venue.address2,
    city: venue.city,
    countryId: venue.countryId,
    postCode: venue.postCode,
  });
  const initialState = {
    longDescription: venue.longDescription,
    website: venue.website,
    phone: venue.phone,
    socialMedia: venue.socialMedia,
    openingHours: venue.openingHours,
    screensNumber: venue.screensNumber,
    hasGarden: venue.hasGarden,
    servesFood: venue.servesFood,
    allowsPet: venue.allowsPet,
    hasWifi: venue.hasWifi,
    hasSound: venue.hasSound,
    hasBigScreen: venue.hasBigScreen,
    hasOutdoorScreen: venue.hasOutdoorScreen,
  };
  const [data, setData] = useState(initialState);
  const [detailsModal, setDetailsModal] = useState(false);
  const [fieldErrors, setFieldErrors] = useState(null);
  const [loading, setLoading] = useState(false);

  const onDetailsModalChange = () => setDetailsModal(prev => !prev);
  const onDataChange = changes => setData(prev => ({ ...prev, ...changes }));

  const currentInstagram =
    data.socialMedia?.instagram?.pageName || data.socialMedia?.instagram || '';

  const fieldsWithChanges = (() => {
    const fields = fieldsChanged(data, venue);

    if (!compareOpeningTimes(data, venue)) {
      fields.push('openingHours');
    }

    if ((venue.socialMedia?.instagram?.pageName || '') !== currentInstagram) {
      fields.push('instagram');
    }

    return fields;
  })();

  const saveVenue = () => {
    setLoading(true);
    return saveVenueChanges({ id: venue?.id, ...data })
      .then(res => {
        refreshVenue(res.getResult());
        setFieldErrors(null);
        toast.success(t('editProfile_keyInfoSaved'));
      })
      .catch(error => {
        if (error.code === 202) {
          setFieldErrors(error.error);
        }
      })
      .finally(() => setLoading(false));
  };

  const divider = <div className="border-b border-mpGrey my-6" />;

  return (
    <>
      <ProfileHeader text={t('signup_venue_keyInfoTitle')} />

      <div className="px-4 mb-36 md:mb-32 max-w-lg mx-auto lg:max-w-xl">
        <KeyInfo
          data={{ ...details, ...data }}
          onChange={onDataChange}
          openModal={onDetailsModalChange}
          fieldErrors={fieldErrors}
        />

        {divider}

        {!userIsOnOnTap && (
          <>
            <p className="font-bold">{t('editProfile_connectSocials')}</p>
            <p className="opacity-60 pb-4">{t('editProfile_connectSocialsSubtitle')}</p>
            <InstagramButton
              value={currentInstagram}
              onChange={e =>
                onDataChange({ socialMedia: { ...data.socialMedia, instagram: e.target.value } })
              }
              errorMessage={fieldErrors?.socialMedia?.instagram}
            />
            <p className="text-sm font-bold pb-2 pt-4">{t('general_facebook')}</p>
            <SocialMediaButtons />

            {divider}
          </>
        )}

        <p className="font-bold pb-4">{t('editProfile_openHours')}</p>
        <OpeningHours
          openingHours={data?.openingHours}
          onChange={openingHours => onDataChange({ openingHours })}
        />

        {divider}

        <p className="font-bold">{t('editProfile.general.facilities')}</p>
        <p className="opacity-60">{t('editProfile_facilitiesSubtitle')}</p>
        <div className="pt-4 flex flex-wrap gap-2">
          <FacilitiesSelector data={data} onClick={setData} />
        </div>

        {divider}

        <p className="font-bold">{t('signup_venue_numScreensTitle')}</p>
        <p className="opacity-60 pb-4">{t('editProfile_numOfScreenSubtitle')}</p>

        <NumberSelector
          counter={data.screensNumber}
          onChange={e => onDataChange({ screensNumber: parseInt(e, 10) })}
        />
      </div>

      {fieldsWithChanges.length > 0 && (
        <SaveBar
          saveCopy={t('editProfile_saveKeyInfo')}
          loading={loading}
          onSave={saveVenue}
          onDiscard={() => {
            setFieldErrors(null);
            setData(initialState);
          }}
          trackingParams={{ fieldsChanged: fieldsWithChanges }}
        />
      )}

      <DetailsModal
        open={detailsModal}
        onClose={onDetailsModalChange}
        data={details}
        onSubmit={c =>
          saveVenueChanges({ id: venue?.id, ...c }).then(res => {
            refreshVenue(res.getResult());
            setDetails(c);
          })
        }
      />
    </>
  );
};

GeneralPage.propTypes = {
  venue: venueProps.isRequired,
  saveVenueChanges: PropTypes.func.isRequired,
  refreshVenue: PropTypes.func.isRequired,
};

const mstp = state => ({
  venue: getVenue(state),
});

const mdtp = {
  saveVenueChanges: saveVenueThunk,
  refreshVenue: updateVenueSuccess,
};

export default connect(mstp, mdtp)(GeneralPage);

import React from 'react';
import PropTypes from 'prop-types';

const MenuIcon = ({ className }) => (
  <svg className={`fill-current ${className}`} viewBox="0 0 24 24">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.1923 13.024H6.28322L6.55245 11.41H17.4615L17.1923 13.024Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.5192 17.0592H5.61014L5.87937 15.4451H16.7885L16.5192 17.0592Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.8654 8.98888H6.9563L7.22553 7.37482H18.1346L17.8654 8.98888Z"
    />
  </svg>
);

MenuIcon.propTypes = {
  className: PropTypes.string,
};

MenuIcon.defaultProps = {
  className: '',
};
export default MenuIcon;

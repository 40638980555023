import * as consts from './constants';

export const initialState = {
  isFetchingTiers: false,
  tiers: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case consts.FETCH_TIERS_START:
      return {
        ...state,
        isFetchingTiers: true,
      };
    case consts.FETCH_TIERS_SUCCESS:
      return {
        ...state,
        isFetchingTiers: false,
        tiers: action.payload,
      };
    case consts.FETCH_TIERS_FAIL:
      return {
        ...state,
        isFetchingTiers: false,
      };
    default:
      return state;
  }
};

export default reducer;

import React from 'react';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';

const bugSnagAPIKey = 'cd25a8aa93f636c437e934e6324fa40e';

const isProduction = process.env.REACT_APP_IS_PROD === 'true';

if (isProduction) {
  Bugsnag.start({
    apiKey: bugSnagAPIKey,
    plugins: [new BugsnagPluginReact()],
  });
}

const ErrorBoundary = isProduction
  ? Bugsnag.getPlugin('react').createErrorBoundary(React)
  : React.Fragment;

export default ErrorBoundary;

import React from 'react';
import PropTypes from 'prop-types';

const AssetBlock = ({ image, title, description }) => (
  <div className="w-56 m-auto lg:m-0">
    <img src={image} alt="" style={{ height: 135 }} />
    <p className="pt-4 font-semibold uppercase text-center">{title}</p>
    <p className="pt-1 opacity-60 text-center">{description}</p>
  </div>
);

AssetBlock.propTypes = {
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

export default AssetBlock;

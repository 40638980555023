import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import LoadingAnimation from '../../components/LoadingAnimation/LoadingAnimation';
import OfferForm from '../../components/OfferForm/OfferForm';
import { fetchOffer, updateOfferThunk } from '../../store/offers/thunks';
import { historyProps, venueProps, offerProps } from '../../utils/customPropTypes';
import { getVenue } from '../../store/venue/selectors';
import { getOfferSelector } from '../../store/offers/selectors';
import { clearOfferAction } from '../../store/offers/actions';
import { fireEvent, GATags, GACategories } from '../../utils/trackingHelper';

const EditOfferPage = ({
  updateOffer,
  history,
  venue,
  getOffer,
  offer,
  match,
  clearOffer,
  title,
}) => {
  useEffect(() => {
    if (venue) {
      getOffer(venue.id, match.params.id);
    }
    // getOffer makesa loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match.params.id, venue]);

  useEffect(() => {
    return () => clearOffer();
    // gets cleared too early:
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!offer) {
    return <LoadingAnimation />;
  }

  return (
    <OfferForm
      saveAction={(id, updatedOffer, navigate) => {
        fireEvent(GACategories.OFFER, GATags.UPDATE_OFFER, { id });
        updateOffer(id, updatedOffer, navigate);
      }}
      navigate={history.push}
      offer={offer}
      title={title}
    />
  );
};

const mapStateToProps = state => ({
  venue: getVenue(state),
  offer: getOfferSelector(state),
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  updateOffer: (venueID, offer, navigate) =>
    dispatch(updateOfferThunk(venueID, offer, ownProps.match.params.id, navigate)),
  getOffer: (venueID, offerID) => dispatch(fetchOffer(venueID, offerID)),
  clearOffer: () => dispatch(clearOfferAction()),
});

EditOfferPage.propTypes = {
  updateOffer: PropTypes.func.isRequired,
  history: historyProps.isRequired,
  venue: venueProps.isRequired,
  getOffer: PropTypes.func.isRequired,
  offer: offerProps,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  clearOffer: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
};

EditOfferPage.defaultProps = {
  offer: null,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditOfferPage);

import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { getUser } from '../../store/auth/selectors';
import { getVenue } from '../../store/venue/selectors';
import { AppcuesId } from '../../utils/constants';
import { userProps, venueProps } from '../../utils/customPropTypes';

const AppcuesScripts = ({ venue, member }) => {
  const [isIdentified, setIsIdentified] = useState(false);

  const typeofwindow = typeof window;
  useEffect(() => {
    if (
      typeofwindow !== 'undefined' &&
      window?.AppcuesSettings &&
      window?.Appcues &&
      venue?.id &&
      member?.id &&
      // Just identify the user once, otherwise Appcues will charge more $$
      !isIdentified
    ) {
      window.Appcues.identify(member?.id, {
        createdAt: member?.createdAt,
        userId: member?.id,
        venueId: venue?.id,
        email: member?.email,
        tierId: venue?.tierId,
        lastLogin: member?.lastLogin,
        venueName: venue?.name,
        countryId: venue?.countryId,
        paidById: venue?.paidById,
        ownerId: venue?.ownerId,
        groupCode: venue?.paymentInfo?.groupCode,
        operatorId: venue?.operatorId,
        interestedIn: venue?.interestedIn,
        lastName: member?.lastName,
        firstName: member?.firstName,
        mindsetStatus: venue?.mindsetFunnelData?.mindsetFunnelBucket,
        coreActionCount: venue?.mindsetFunnelData?.totalCoreActivityCount,
      });
      setIsIdentified(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [typeofwindow, window?.AppcuesSettings, window?.Appcues, venue?.id, member?.id, isIdentified]);

  useEffect(() => {
    if (typeofwindow !== 'undefined' && (!window.AppcuesSettings || !window.Appcues)) {
      window.AppcuesSettings = {
        enableURLDetection: true,
      };
      const script = document.createElement('script');
      script.src = `//fast.appcues.com/${AppcuesId}.js`;
      document.head.appendChild(script);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [typeofwindow, window?.AppcuesSettings, window?.Appcues]);
  return null;
};

const mapStateToProps = state => ({
  venue: getVenue(state),
  member: getUser(state),
});

export default connect(mapStateToProps)(AppcuesScripts);

AppcuesScripts.propTypes = {
  venue: venueProps,
  member: userProps,
};
AppcuesScripts.defaultProps = {
  venue: null,
  member: null,
};

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { t } from 'i18next';
import { fetchTiers, fetchPromoCodeTiers } from '../../store/tiers/thunks';
import { getTiers, getIsFetchingTiers } from '../../store/tiers/selectors';
import { fireEvent, GACategories, GATags } from '../../utils/trackingHelper';
import Tier from './Tier';
import BorderedInput from '../BorderedInput/BorderedInput';
import TextToggle from '../TextToggle/TextToggle';
import PlainButton from '../PlainButton/PlainButton';
import CloseIcon from '../Icons/CloseIcon';
import Spinner from '../Spinner/Spinner';
import Claims from '../Claims/Claims';

export const FREQUENCY = { QUARTERLY: 'quarterly', YEARLY: 'yearly' };

const TierList = ({
  tiers,
  onSelect,
  selectedText,
  unSelectedText,
  fetchTiersList,
  currentTierID,
  countryID,
  onChangeFrequency,
  currentFrequency,
  isFetchingTiers,
  sendPromoCode,
  showPromoCode,
  postPromoCodeAction,
  venueId,
}) => {
  const [promoCode, setPromoCode] = useState('');
  const [codeIsActive, setCodeIsActive] = useState(false);

  // Find index of current tier in tiers array
  // To be able to show 'Upgrade' when the tier is higher than the venue's active tier
  const tierIndx = tiers.findIndex(tier => currentTierID === tier.id);

  useEffect(() => {
    fetchTiersList(countryID);
  }, [countryID, fetchTiersList]);

  const codeInputBtn = (
    <>
      <BorderedInput
        className="my-0 mr-2 py-3 lg:py-4 px-4 2xl:w-80 text-base rounded-lg"
        value={promoCode}
        placeholder={t('auth.signup.promoCode')}
        onChange={e => {
          setPromoCode(e);
          setCodeIsActive(false);
        }}
      />
      <PlainButton
        className="py-3 lg:py-4 px-4 min-w-max rounded-lg bg-mpGrey relative"
        disabled={promoCode.length <= 2}
        onClick={() => {
          onSelect(null);
          sendPromoCode(promoCode, countryID, venueId).then(res => {
            if (res) {
              setCodeIsActive(true);
              postPromoCodeAction(res, promoCode);
            }
          });
        }}
      >
        {isFetchingTiers && (
          <div className="absolute w-full left-0 bg-mpGrey">
            <Spinner className="w-6" />
          </div>
        )}
        <p className="font-semibold">{t('auth.signup.usePromoCode')}</p>
      </PlainButton>
    </>
  );

  const activeCodeBtn = (
    <PlainButton
      className="flex items-center mt-2 xl:mt-4 bg-mpGrey px-4 py-2 rounded-full"
      onClick={() => {
        setCodeIsActive(false);
        setPromoCode('');
        fetchTiersList(countryID);
      }}
    >
      <p className="font-semibold">{promoCode}</p>
      <CloseIcon className="w-6 h-6 ml-2" />
    </PlainButton>
  );

  const onToggle = (newState, tag) => {
    onChangeFrequency(newState);
    fireEvent(GACategories.TIER, tag);
    onSelect(null);
  };

  return (
    <>
      <div className="relative px-4 md:px-6 lg:px-10 pt-10 sm:pt-12 xl:container">
        <div className="flex flex-col xl:flex-row items-start">
          <div className="flex flex-col flex-1 w-full">
            <h1 className="headingFont text-4xl md:text-6xl whitespace-pre-line leading-none mb-4">
              {t('subscriptions_title')}
            </h1>
            <Claims />
            <div className="flex justify-between items-center mt-6">
              <TextToggle
                active={currentFrequency === FREQUENCY.YEARLY}
                onToggleOff={() => onToggle(FREQUENCY.QUARTERLY, GATags.FREQUENCY_QUARTERLY)}
                onToggleOn={() => onToggle(FREQUENCY.YEARLY, GATags.FREQUENCY_YEARLY)}
                offText={t('subscriptions.quarterly')}
                onText={t('subscriptions.yearly')}
                containerClasses="flex-1 sm:mr-4"
              />

              {showPromoCode && <div className="hidden sm:flex xl:hidden">{codeInputBtn}</div>}
            </div>
          </div>
          {showPromoCode && (
            <div className="flex flex-col w-full sm:w-auto">
              <div className="flex sm:hidden xl:flex mt-6 sm:mt-0">{codeInputBtn}</div>
              {codeIsActive && <div className="hidden xl:flex">{activeCodeBtn}</div>}
            </div>
          )}
        </div>
        {codeIsActive && <div className="flex xl:hidden">{activeCodeBtn}</div>}
      </div>

      <div className="flex overflow-x-auto gap-4 md:gap-8 mt-8 lg:mt-10 px-4 md:px-6 lg:px-10 pb-8 lg:overflow-x-visible lg:flex-wrap">
        {tiers?.length > 0 &&
          tiers.map((plan, i) => (
            <Tier
              key={plan.id}
              plan={plan}
              selected={currentTierID === plan.id}
              onSelect={() => onSelect(plan.id, promoCode)}
              selectedText={selectedText}
              unSelectedText={
                // don't show 'upgrade' if the tier is not available or doesn't exist
                // apply only for existing venues and there is a higher tier
                tierIndx !== -1 && tierIndx < i && venueId ? t('general.upgrade') : unSelectedText
              }
              priceField={currentFrequency === FREQUENCY.YEARLY ? 'yearlyPrice' : 'quarterlyPrice'}
              priceText={
                currentFrequency === FREQUENCY.YEARLY
                  ? t('subscriptions.annum')
                  : t('subscriptions.quarter')
              }
            />
          ))}
      </div>
    </>
  );
};

TierList.propTypes = {
  tiers: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  onSelect: PropTypes.func.isRequired,
  selectedText: PropTypes.string,
  unSelectedText: PropTypes.string,
  fetchTiersList: PropTypes.func.isRequired,
  currentTierID: PropTypes.number,
  countryID: PropTypes.number,
  onChangeFrequency: PropTypes.func,
  currentFrequency: PropTypes.oneOf(Object.values(FREQUENCY)),
  isFetchingTiers: PropTypes.bool.isRequired,
  sendPromoCode: PropTypes.func.isRequired,
  showPromoCode: PropTypes.bool,
  postPromoCodeAction: PropTypes.func,
  venueId: PropTypes.number,
};

TierList.defaultProps = {
  selectedText: t('subscriptions_currentPlan'),
  unSelectedText: t('subscriptions_choosePlan'),
  currentTierID: 0,
  countryID: null,
  onChangeFrequency: () => {},
  currentFrequency: FREQUENCY.YEARLY,
  showPromoCode: false,
  postPromoCodeAction: () => {},
  venueId: undefined,
};

const mapStateToProps = state => ({
  tiers: getTiers(state),
  isFetchingTiers: getIsFetchingTiers(state),
});

const mapDispatchToProps = dispatch => ({
  fetchTiersList: countryID => dispatch(fetchTiers(countryID)),
  sendPromoCode: (promoCode, countryId, venueID) =>
    dispatch(fetchPromoCodeTiers(promoCode, countryId, venueID)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TierList);

import React from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';
import { connect } from 'react-redux';
import PageHeader from '../../components/PageHeader/PageHeader';
import TabbedNav from '../../components/TabbedNav/TabbedNav';
import League from './League';
import Prizes from './Prizes';
import PromotionalMaterial from './PromotionalMaterial';
import PaperTips from './PaperTips';
import { historyProps } from '../../utils/customPropTypes';
import { getVenueGame } from '../../store/venue/selectors';
import i18n from '../../i18n';

const routes = {
  league: {
    path: '/footy-tipping',
    text: i18n.t('tipping.navTab.league'),
    component: League,
  },
  prizes: {
    path: '/footy-tipping/prizes',
    text: i18n.t('tipping.navTab.prizes'),
    component: Prizes,
  },
  promotional: {
    path: '/footy-tipping/promotional',
    text: i18n.t('tipping.navTab.promoMaterial'),
    component: PromotionalMaterial,
  },
  paper: {
    path: '/footy-tipping/paper-tips',
    text: i18n.t('tipping.navTab.paperTips'),
    component: PaperTips,
  },
};

const FootyTipping = ({ history, venueGame }) => (
  <>
    <PageHeader title={venueGame?.name} />
    <TabbedNav
      tabs={Object.values(routes).map(({ text, path }) => ({
        text,
        selected: path === history.location.pathname,
        path,
      }))}
    />
    {Object.keys(routes).map(routeKey => {
      const route = routes[routeKey];
      return <Route key={route.path} path={route.path} exact component={route.component} />;
    })}
  </>
);

FootyTipping.propTypes = {
  history: historyProps.isRequired,
  venueGame: PropTypes.shape(),
};

FootyTipping.defaultProps = {
  venueGame: null,
};

const mapStateToProps = state => ({
  venueGame: getVenueGame(state),
});

export default connect(mapStateToProps)(FootyTipping);

import * as consts from './constants';

export const fetchTiersStart = () => ({
  type: consts.FETCH_TIERS_START,
});

export const fetchTiersSuccess = res => ({
  type: consts.FETCH_TIERS_SUCCESS,
  payload: res,
});

export const fetchTiersFail = () => ({
  type: consts.FETCH_TIERS_FAIL,
});

import React from 'react';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import Modal from './Modal';
import PlainButton from '../../components/PlainButton/PlainButton';
import SpinnerButton from '../../components/SpinnerButton/SpinnerButton';
import { GACategories, GATags, fireEvent } from '../../utils/trackingHelper';

const buttonStyles = 'font-semibold flex-1 rounded-lg p-4';

const AddAllFixturesModal = ({ open, onClose, onAdd, loading, venueID }) => (
  <Modal
    closeModal={onClose}
    open={open}
    title={i18next.t('fixtures_filters_add_all_filtered')}
    buttons={
      <div className="flex gap-2">
        <PlainButton
          className={`${buttonStyles} bg-mpGrey`}
          onClick={() => {
            onClose();
            fireEvent(GACategories.FIXTURES, GATags.SELECT_ALL_MODAL_CANCEL, { venueID });
          }}
        >
          {i18next.t('general.cancel')}
        </PlainButton>

        <SpinnerButton
          className={`${buttonStyles} bg-secondary`}
          onClick={() => {
            onAdd();
            fireEvent(GACategories.FIXTURES, GATags.SELECT_ALL_MODAL_ACCEPT, { venueID });
          }}
          loading={loading}
          disabled={loading}
        >
          {i18next.t('general.add')}
        </SpinnerButton>
      </div>
    }
  >
    <p className="pt-2 pb-6 opacity-60">{i18next.t('fixtures_filters_add_all_filtered_text')}</p>
  </Modal>
);

AddAllFixturesModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  onAdd: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  venueID: PropTypes.number.isRequired,
};

export default AddAllFixturesModal;

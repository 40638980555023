import React from 'react';
import PropTypes from 'prop-types';

const ReimburseIcon = ({ className }) => (
  <svg className={`fill-current stroke-current ${className}`} viewBox="0 0 24 24">
    <path
      d="M1.85122 11.6488L1.8364 11.7981L1.98567 11.8129L3.30386 11.9438L3.45312 11.9586L3.46794 11.8093L3.53338 11.1503C3.95914 6.86201 7.57845 3.51227 11.9791 3.51227C15.1612 3.51227 17.9357 5.26336 19.3891 7.85714L17.6466 7.27487L17.4955 7.22435L17.4542 7.37831L17.2647 8.08564L17.2277 8.22358L17.3637 8.26727L21.0227 9.44318L21.1779 9.49305L21.2147 9.33425L22.095 5.53756L22.1278 5.39631L21.9877 5.35879L21.2804 5.16926L21.1293 5.12878L21.0952 5.28142L20.7277 6.92513C18.9772 3.91401 15.7156 1.8876 11.9791 1.8876C6.73501 1.8876 2.4241 5.87876 1.91666 10.9897L1.85122 11.6488ZM22.153 12.3509L22.1678 12.2016L22.0186 12.1868L20.7004 12.0559L20.5511 12.0411L20.5363 12.1904L20.4709 12.8495C20.0451 17.1377 16.4258 20.4874 12.0251 20.4874C8.8561 20.4874 6.09131 18.7507 4.63312 16.1744L6.39023 16.7616L6.54141 16.8121L6.58266 16.6582L6.77218 15.9509L6.80914 15.8129L6.67319 15.7692L3.01433 14.5934L2.85913 14.5435L2.82231 14.7023L1.94209 18.4988L1.90934 18.6401L2.04939 18.6776L2.75668 18.8671L2.90776 18.9076L2.94189 18.7549L3.30626 17.1253C5.06366 20.1084 8.30967 22.1121 12.0251 22.1121C17.2692 22.1121 21.5802 18.1209 22.0876 13.01L22.153 12.3509Z"
      strokeWidth="0.3"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.80404 8.87146V9.17637H10.5316V8.60399L9.92707 7.99951H8.09759L7.48242 8.61469V9.20312H7.00098V9.73805H7.48242V10.0483H7.00098V10.5833H7.48242V11.1289L8.09759 11.7441H9.92707L10.5316 11.1396V10.5672H9.80404V10.8721L9.55262 11.1235H8.48275L8.20993 10.8507V10.5833H9.28515V10.0483H8.20993V9.73805H9.28515V9.20312H8.20993V8.89285L8.48275 8.62004H9.55262L9.80404 8.87146ZM16.5476 10.3618V11.287L16.031 11.8036H15.5519V12.3202H15.0165V11.8036H14.5093L14.0021 11.2964V10.8268H14.6314V11.1038L14.7864 11.2588H15.0165V10.3994H14.5187L14.0115 9.89217V9.02333L14.5187 8.51612H15.0165V7.99951H15.5519V8.51612H16.0028L16.51 9.02333V9.49767H15.8807V9.21588L15.7257 9.0609H15.5519V9.8546H16.0404L16.5476 10.3618ZM15.0165 9.8546V9.0609H14.7958L14.6408 9.21588V9.69962L14.7958 9.8546H15.0165ZM15.9183 10.5544L15.7633 10.3994H15.5519V11.2588H15.7586L15.9183 11.0991V10.5544ZM13.1263 16.2342V16.8634H10.0635V16.0576L10.4829 15.572V15.3292H9.96964V14.7111H10.4829V13.6957L11.1782 13.0003H12.453L13.0711 13.6184V14.093H12.3426V13.8336L12.1385 13.6294H11.5038L11.2224 13.9109V14.7111H12.5303V15.3292H11.2224V15.6548L10.825 16.1184V16.2342H13.1263Z"
      stroke="none"
    />
  </svg>
);

ReimburseIcon.propTypes = {
  className: PropTypes.string,
};

ReimburseIcon.defaultProps = {
  className: '',
};

export default ReimburseIcon;

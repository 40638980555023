import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter, Link } from 'react-router-dom';
import { t } from 'i18next';
import MenuIcon from '../../components/Icons/MenuIcon';
import LoadingAnimation from '../../components/LoadingAnimation/LoadingAnimation';
import Logo from '../../components/Logo/Logo';
import { getIsFetching, getIsFetchingPermissions, getVenue } from '../../store/venue/selectors';
import PlainButton from '../../components/PlainButton/PlainButton';
import styles from './ProtectedLayout.module.scss';
import AppBar from '../../components/AppBar/AppBar';
import Drawer from '../../components/Drawer/Drawer';
import UpgradeModal from '../../components/UpgradeModal/UpgradeModal';
import { venueProps } from '../../utils/customPropTypes';
import { logoutAction } from '../../store/auth/actions';
import { getShowMobileSidebar } from '../../store/app/selectors';
import { setShowMobileSidebar } from '../../store/app/actions';

const ProtectedLayout = ({
  children,
  isFetchingVenue,
  isFetchingPermissions,
  venue,
  logout,
  showMobileSidebar,
  onSetMobileSidebar,
}) => {
  // If there is no venue associated with the current user
  // hide the sidebar and make the topbar always visible
  // they can create a venue or log out
  return (
    <>
      <UpgradeModal />
      <AppBar className={venue ? 'lg:hidden' : ''}>
        <Link to="/">
          <Logo />
        </Link>
        {venue ? (
          <PlainButton
            onClick={() => onSetMobileSidebar(!showMobileSidebar)}
            className={`${styles.menuBtn} bg-secondary`}
          >
            <MenuIcon className="h-6 w-6" />
            <p className="pl-1 font-semibold">{t('editProfile.tabs.menu')}</p>
          </PlainButton>
        ) : (
          <Link onClick={logout} className={styles.logoutBtn} to="/">
            {t('auth.logout')}
          </Link>
        )}
      </AppBar>
      <div className="flex bg-offWhite min-h-screen">
        {venue && (
          <Drawer
            data-test-id="sidebar"
            open={showMobileSidebar}
            onClose={() => onSetMobileSidebar(false)}
          />
        )}
        <div className={venue ? styles.withVenue : styles.withoutVenue}>
          {(isFetchingVenue || isFetchingPermissions) && <LoadingAnimation />}
          {children}
        </div>
      </div>
    </>
  );
};

ProtectedLayout.propTypes = {
  children: PropTypes.node.isRequired,
  isFetchingVenue: PropTypes.bool.isRequired,
  isFetchingPermissions: PropTypes.bool.isRequired,
  venue: venueProps,
  logout: PropTypes.func.isRequired,
  showMobileSidebar: PropTypes.bool.isRequired,
  onSetMobileSidebar: PropTypes.func.isRequired,
};

ProtectedLayout.defaultProps = {
  venue: null,
};

const mapStateToProps = state => ({
  isFetchingVenue: getIsFetching(state),
  isFetchingPermissions: getIsFetchingPermissions(state),
  venue: getVenue(state),
  showMobileSidebar: getShowMobileSidebar(state),
});

const mapDispatchToProps = {
  logout: logoutAction,
  onSetMobileSidebar: setShowMobileSidebar,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProtectedLayout));

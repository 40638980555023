import './styles.scss';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect, Link } from 'react-router-dom';
import queryString from 'query-string';
import React, { useEffect } from 'react';
import i18n from 'i18next';
import PageHeader from '../../components/PageHeader/PageHeader';

import {
  getVenue,
  getIsFinalisingResub,
  getFinaliseResubSuccess,
  getFinaliseResubError,
} from '../../store/venue/selectors';
import { venueProps, historyProps } from '../../utils/customPropTypes';
import { venueResubscribeFinalise } from '../../store/venue/thunks';
import routes from '../../routes/paths';

const Resubscriptions = ({
  history,
  title,
  venue,
  resubFianlise,
  isFinalisingResub,
  finaliseResubSuccess,
  finaliseResubError,
}) => {
  const params = queryString.parse(history.location.search);
  useEffect(() => {
    if (params.resubKey) {
      resubFianlise(venue.id, params.resubKey);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resubFianlise, venue.id]);

  if (!params.resubKey) {
    return <Redirect to={routes.home.path} />;
  }
  return (
    <>
      <PageHeader title={title} />
      <div className="Resubscriptions">
        {isFinalisingResub && <p>{i18n.t('resubscription.loading')}</p>}
        {finaliseResubSuccess && (
          <>
            <p>{i18n.t('resubscription.success')}</p>
            <Link to={routes.home.path}>{i18n.t('resubscription.success2')}</Link>
          </>
        )}
        {finaliseResubError && <p>{finaliseResubError}</p>}
      </div>
    </>
  );
};

Resubscriptions.propTypes = {
  title: PropTypes.string.isRequired,
  venue: venueProps.isRequired,
  history: historyProps.isRequired,
  resubFianlise: PropTypes.func.isRequired,
  isFinalisingResub: PropTypes.bool.isRequired,
  finaliseResubSuccess: PropTypes.bool.isRequired,
  finaliseResubError: PropTypes.string.isRequired,
};

const mapStateToProps = state => ({
  venue: getVenue(state),
  isFinalisingResub: getIsFinalisingResub(state),
  finaliseResubSuccess: getFinaliseResubSuccess(state),
  finaliseResubError: getFinaliseResubError(state),
});

const mapDispatchToProps = dispatch => ({
  resubFianlise: (venueId, resubKey) => dispatch(venueResubscribeFinalise(venueId, resubKey)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Resubscriptions);

import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import lottie from 'lottie-web';
import fanzo from './fanzo.json';

const LoadingAnimation = ({ size }) => {
  const element = useRef(null);
  const lottieInstance = useRef();

  useEffect(() => {
    if (element.current) {
      lottieInstance.current = lottie.loadAnimation({
        loop: true,
        autoplay: true,
        animationData: fanzo,
        container: element.current,
      });
    }
  }, []);

  return (
    <div className="flex justify-center m-auto">
      <div style={{ width: size, height: size }} ref={element} />
    </div>
  );
};

LoadingAnimation.propTypes = {
  size: PropTypes.number,
};

LoadingAnimation.defaultProps = {
  size: 200,
};

export default LoadingAnimation;

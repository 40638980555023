import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import './styles.scss';

const TabbedNav = ({ tabs }) => (
  <div className="bg-anotherGrey flex pl-4">
    {tabs.map(({ path, text, selected, override }) => (
      <Link key={text} to={path} className={`tab${selected ? '_selected' : ''}`}>
        {override || <p>{text}</p>}
      </Link>
    ))}
  </div>
);

TabbedNav.propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      onClick: PropTypes.func.isRequired,
      text: PropTypes.string,
      selected: PropTypes.bool.isRequired,
      override: PropTypes.node,
    }).isRequired,
  ).isRequired,
};

export default TabbedNav;

import React from 'react';
import { userIsOnOnTap } from '../../utils/environment';
import Intercom from './Intercom';
import AppcuesScripts from './AppcuesScripts';
import MSClarity from './MSClarity';
import FacebookInitialiser from './FacebookInitialiser';

const OnLoadScripts = () => (
  <>
    {process.env.REACT_APP_IS_PROD === 'true' && !userIsOnOnTap && <AppcuesScripts />}
    <Intercom />
    <MSClarity />
    <FacebookInitialiser />
  </>
);

export default OnLoadScripts;

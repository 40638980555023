import * as consts from './constants';

export const updateMemberPending = () => ({
  type: consts.UPDATE_MEMBER_PENDING,
});

export const updateMemberSuccess = () => ({
  type: consts.UPDATE_MEMBER_SUCCESS,
});

export const updateMemberError = error => ({
  type: consts.UPDATE_MEMBER_ERROR,
  payload: error,
});

export const toggleAuthModal = () => ({
  type: consts.TOGGLE_AUTH_MODAL,
});

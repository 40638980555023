import React from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18next';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import ProfileHeader from './ProfileHeader';
import ChannelSelector from '../../components/ChannelSelector/ChannelSelector';
import SaveBar from './SaveBar/SaveBar';
import {
  getChannelsChanged,
  getIsSavingChannels,
  getVenue,
  getVenueSelectedChannels,
} from '../../store/venue/selectors';
import { refreshVenueThunk, saveVenueChannels } from '../../store/venue/thunks';
import { venueProps } from '../../utils/customPropTypes';
import { discardChannelChanges } from '../../store/venue/actions';

const ChannelPage = ({
  selectedChannels,
  saveChannels,
  venue,
  isSavingChannels,
  channelsChanged,
  discardChanges,
  refreshVenue,
}) => (
  <>
    <ProfileHeader text={t('editProfile.preferences.channels')} />
    <div className="px-4 pt-4 sm:pt-12 lg:pt-16 w-full max-w-lg mx-auto lg:max-w-xl">
      <p className="opacity-60">{t('editProfile.channels.subtitle')}</p>
      <ChannelSelector />
      {channelsChanged && (
        <SaveBar
          onSave={() =>
            saveChannels(venue.id, selectedChannels).then(() => {
              refreshVenue(venue.id);
              toast.success(t('editProfile.channels.toast'));
            })
          }
          loading={isSavingChannels}
          saveCopy={t('general.saveChannels')}
          onDiscard={discardChanges}
        />
      )}
    </div>
  </>
);

ChannelPage.propTypes = {
  venue: venueProps.isRequired,
  saveChannels: PropTypes.func.isRequired,
  selectedChannels: PropTypes.arrayOf(PropTypes.number).isRequired,
  channelsChanged: PropTypes.bool,
  discardChanges: PropTypes.func.isRequired,
  isSavingChannels: PropTypes.bool.isRequired,
  refreshVenue: PropTypes.func.isRequired,
};
ChannelPage.defaultProps = {
  channelsChanged: false,
};

const mapStateToProps = state => ({
  selectedChannels: getVenueSelectedChannels(state),
  venue: getVenue(state),
  channelsChanged: getChannelsChanged(state),
  isSavingChannels: getIsSavingChannels(state),
});

const mapDispatchToProps = {
  saveChannels: saveVenueChannels,
  discardChanges: discardChannelChanges,
  refreshVenue: refreshVenueThunk,
};

export default connect(mapStateToProps, mapDispatchToProps)(ChannelPage);

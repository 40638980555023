import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18next';
import { formatDistance, parseISO } from 'date-fns';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import { getLocale } from '../../i18n';
import { publicRoutes } from '../../routes/paths';
import { getURLQuery } from '../../utils/general';
import { userProps } from '../../utils/customPropTypes';
import { getUser } from '../../store/auth/selectors';
import { getFieldsErrors, getIsUpdatingMember } from '../../store/settings/selectors';
import { updateMemberThunk } from '../../store/settings/thunks';
import { logoutAction } from '../../store/auth/actions';
import history from '../../routes/history';
import PlainButton from '../../components/PlainButton/PlainButton';
import Input from '../SignupV2/Input';
import SpinnerButton from '../../components/SpinnerButton/SpinnerButton';

const animate = hide =>
  `transition-all ${hide ? 'opacity-0 max-h-0 overflow-hidden' : 'max-h-screen duration-500'}`;

const LoginDetails = ({ member, updateMember, isUpdatingMember, fieldsErrors, logout }) => {
  const [showUpdatePass, setShowUpdatePass] = useState(false);
  const [currentPassword, setCurrentPassword] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const { passwordChangedAt, createdAt, email, id } = member || {};
  const [changedDate, setChangedDate] = useState(passwordChangedAt);
  const canChange = currentPassword && password && passwordConfirm && password === passwordConfirm;
  const passUpdateTime = formatDistance(parseISO(changedDate || createdAt), new Date(), {
    addSuffix: true,
    locale: getLocale(),
  });

  return (
    <>
      <h3 className="font-bold text-xl pb-4 ">{t('settings_loginTitle')}</h3>
      <Input label={t('settings.email')} placeholder={member.email} type="email" disabled />
      <div className={`flex justify-between items-start pt-4 ${animate(showUpdatePass)}`}>
        <div>
          <p className="font-bold pb-2">{t('auth.password')}</p>
          <p className="opacity-60">
            {t(changedDate ? 'settings_passUpdateTime' : 'settings_passCreatedTime', {
              time: passUpdateTime,
            })}
          </p>
        </div>
        <PlainButton
          className="text-xs font-bold rounded-full px-4 py-3.5 bg-mpGrey"
          onClick={() => setShowUpdatePass(true)}
        >
          {t('settings_passUpdateBtn')}
        </PlainButton>
      </div>
      <div className={`flex flex-col gap-4 ${animate(!showUpdatePass)}`}>
        <div>
          <Input
            label={t('settings_passCurrentLabel')}
            placeholder={t('settings_passCurrentPlaceholder')}
            value={currentPassword}
            onChange={e => setCurrentPassword(e)}
            type="password"
          />
          <PlainButton
            className="underline pt-2 opacity-80"
            onClick={() => {
              logout();
              toast.success(t('settings_forgotPassToast'));
              history.push(`${publicRoutes.login}${getURLQuery({ forgottenPassword: true })}`);
            }}
          >
            {t('auth.forgot_password')}
          </PlainButton>
        </div>
        <Input
          label={t('settings_passNewLabel')}
          placeholder={t('settings_passNewPlaceholder')}
          value={password}
          onChange={e => setPassword(e)}
          type="password"
          error={fieldsErrors.password}
        />
        <Input
          label={t('settings_passConfirmLabel')}
          placeholder={t('settings_passConfirmPlaceholder')}
          value={passwordConfirm}
          onChange={e => setPasswordConfirm(e)}
          type="password"
          error={fieldsErrors.password}
        />
        <SpinnerButton
          className={`w-full bg-secondary py-3.5 font-semibold rounded-lg ${
            canChange ? '' : 'opacity-40'
          }`}
          disabled={!canChange && !isUpdatingMember}
          loading={isUpdatingMember}
          onClick={() =>
            updateMember({ email, id, password, passwordConfirm, currentPassword }, id).then(r => {
              setChangedDate(r.passwordChangedAt);
              setShowUpdatePass(false);
              setCurrentPassword('');
              setPassword('');
              setPasswordConfirm('');
            })
          }
        >
          {t('settings_passUpdatePassBtn')}
        </SpinnerButton>
      </div>
    </>
  );
};

LoginDetails.propTypes = {
  member: userProps.isRequired,
  updateMember: PropTypes.func.isRequired,
  isUpdatingMember: PropTypes.bool.isRequired,
  fieldsErrors: PropTypes.shape().isRequired,
  logout: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  member: getUser(state),
  isUpdatingMember: getIsUpdatingMember(state),
  fieldsErrors: getFieldsErrors(state),
});

const mapDispatchToProps = {
  updateMember: updateMemberThunk,
  logout: logoutAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginDetails);

import React from 'react';
import PropTypes from 'prop-types';
import i18n from '../../i18n';
import FanzoVenuesLogoEN from './svg/FanzoVenuesLogoEN';
import FanzoVenuesLogoFR from './svg/FanzoVenuesLogoFR';
import FanzoVenuesLogoDE from './svg/FanzoVenuesLogoDE';
import OnTapLogo from './svg/OnTapLogo.png';
import { userIsOnOnTap } from '../../utils/environment';

const Logo = ({ small }) => {
  if (userIsOnOnTap) {
    return <img src={OnTapLogo} className="h-12" alt="" />;
  }
  if (i18n.resolvedLanguage === 'fr') {
    return <FanzoVenuesLogoFR className={small ? 'h-8 w-24' : 'h-12 w-36'} />;
  }
  if (i18n.resolvedLanguage === 'de') {
    return <FanzoVenuesLogoDE className={small ? 'h-8 w-20' : 'h-12 w-28'} />;
  }
  return <FanzoVenuesLogoEN className={small ? 'h-8 w-24' : 'h-12 w-36'} />;
};

Logo.propTypes = {
  small: PropTypes.bool,
};

Logo.defaultProps = {
  small: false,
};

export default Logo;

import React, { useEffect, useState } from 'react';
import i18n from 'i18next';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import ChevronIcon from '../../components/Icons/ChevronIcon';
import PageHeader from '../../components/PageHeader/PageHeader';
import routes from '../../routes/paths';
import { fetchEditableCountryCompetitions } from '../../store/socialMedia/thunks';
import { getVenueID } from '../../store/venue/selectors';
import bgFanzo from './colourFanzo.jpg';
import { fireEvent, GACategories, GATags } from '../../utils/trackingHelper';
import { useHiddenByPermissions } from '../../utils/CustomHooks';

const styles = {
  column: 'flex-1 py-6 min-w-[300px]',
};

const CustomiseAssets = ({ title, fetchCompetitions, venueId }) => {
  const [competitions, setCompetitions] = useState([]);
  useEffect(() => {
    if (venueId) {
      fetchCompetitions(venueId).then(r => setCompetitions(r));
    }
  }, [fetchCompetitions, venueId]);

  const renderTitle = text => (
    <div className="flex items-center py-2">
      <ChevronIcon className="h-4 w-4 text-primary mr-1" />
      <span className="uppercase text-sm font-extrabold">{text}</span>
    </div>
  );

  useHiddenByPermissions();

  return (
    <>
      <PageHeader title={title} />
      <div className="GlobalPad gap-8 flex flex-wrap max-w-7xl">
        <Link
          onClick={() => fireEvent(GACategories.CUSTOM_ASSET, GATags.CLICKED_COMPETITION)}
          to={routes.marketing.subRoutes.customiseAssets.subRoutes.competitions.path}
          className={styles.column}
        >
          {renderTitle(i18n.t('marketing.customiseAssets.competition'))}
          <div className="grid grid-cols-2 gap-2">
            {competitions.slice(0, 4).map(comp => (
              <img key={comp.id} alt={comp.competition.name} src={comp.logo} />
            ))}
          </div>
        </Link>
        <Link
          to={routes.marketing.subRoutes.customiseAssets.subRoutes.colorAssets.path}
          className={styles.column}
        >
          {renderTitle(i18n.t('marketing.customiseAssets.colour'))}
          <img alt="" className="object-contain" src={bgFanzo} />
        </Link>
      </div>
    </>
  );
};

CustomiseAssets.propTypes = {
  title: PropTypes.string.isRequired,
  fetchCompetitions: PropTypes.func.isRequired,
  venueId: PropTypes.number.isRequired,
};

const mapDispatchToProps = dispatch => ({
  fetchCompetitions: venueId => dispatch(fetchEditableCountryCompetitions(venueId)),
});

const mapStateToProps = state => ({
  venueId: getVenueID(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(CustomiseAssets);

import React from 'react';

const ServesFood = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="black">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.18348 3.1697L8.04911 8.71931C7.97303 9.09151 8.12443 9.43876 8.43544 9.60538L8.89528 9.85174C9.14299 9.98445 9.4593 9.98445 9.74905 9.85174L10.2869 9.60538C10.6507 9.43876 10.9121 9.09151 10.9539 8.71931L11.5775 3.1697L12.9494 3.10855L12.3259 8.65815C12.2243 9.56206 11.5895 10.4054 10.706 10.81L10.1681 11.0564C9.46441 11.3787 8.69623 11.3787 8.09464 11.0564L7.63481 10.81C6.8795 10.4054 6.5118 9.56206 6.69656 8.65815L7.83094 3.10855L9.18348 3.1697Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.0673 3.13843L9.91534 10.4114H8.5517L9.70363 3.13843H11.0673Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.2947 9.73798L8.50284 21.0515H6.59375L8.38564 9.73798H10.2947Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.5555 12.9704L16.2756 21.0515H14.3665L15.6464 12.9704H17.5555Z"
    />
    <path d="M16.6296 4.68496C16.8616 3.8779 17.6527 3.27311 18.4765 3.27311H18.5459L16.8394 14.0479L14.9634 13.0429C14.5813 12.8381 14.4128 12.397 14.5434 11.9429L16.6296 4.68496Z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.0993 4.61684C16.4086 3.54075 17.4635 2.73438 18.5618 2.73438H19.1767L17.2468 14.9196L14.6432 13.5247C14.07 13.2176 13.8173 12.5559 14.0131 11.8748L16.0993 4.61684ZM17.8981 3.91968C17.5513 4.07424 17.2667 4.3815 17.1599 4.75309L15.0736 12.011C15.0084 12.2381 15.0926 12.4586 15.2837 12.561L16.432 13.1762L17.8981 3.91968Z"
    />
    <path d="M7.32902 8.66051H11.6927L11.4367 10.2767H7.07304L7.32902 8.66051Z" />
  </svg>
);

export default ServesFood;

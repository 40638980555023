import React from 'react';

const BookingIcon = () => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.36135 1.69556H28.1795L25.2479 19.2709C24.8844 21.4499 22.7989 23.2164 20.5898 23.2164H6.77158C4.56244 23.2164 3.06623 21.4499 3.42971 19.2709L6.36135 1.69556ZM8.18419 3.84764L5.61153 19.2709C5.44631 20.2614 6.1264 21.0643 7.13056 21.0643H20.9487C21.9529 21.0643 22.9009 20.2614 23.0661 19.2709L25.6387 3.84764H8.18419Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.66516 2.05424H27.7561L26.6791 8.51049H5.58823L6.66516 2.05424ZM7.88039 3.48896L7.28209 7.07577H25.4639L26.0622 3.48896H7.88039Z"
      fill="black"
    />
    <path
      d="M18.8515 19.1351H25.7507C26.6248 19.1351 27.3334 19.8437 27.3334 20.7178C27.3334 21.5919 26.6248 22.3005 25.7507 22.3005H25.7483C26.383 22.3005 26.8975 22.815 26.8975 23.4497C26.8975 24.0844 26.383 24.5989 25.7483 24.5989H24.7383C25.373 24.5989 25.8875 25.1134 25.8875 25.7481C25.8875 26.3828 25.373 26.8973 24.7383 26.8973H23.6656C24.3349 26.8973 24.8774 27.4399 24.8774 28.1091C24.8774 28.7274 24.412 29.2466 23.7973 29.3138L19.3334 29.8018L16.3374 29.5294C14.0725 29.3235 12.0982 27.9036 11.1823 25.822C10.4334 24.1199 10.4812 22.1728 11.3128 20.5095L16 11.1351L16.2783 11.3021C16.5918 11.4902 16.7486 11.5842 16.8779 11.6781C18.1556 12.6064 18.6881 14.2533 18.1957 15.7539C18.1459 15.9057 18.0739 16.0738 17.9298 16.4098L17.585 17.2143C17.4253 17.5869 17.4378 18.0109 17.6191 18.3734C17.8525 18.8402 18.3296 19.1351 18.8515 19.1351Z"
      fill="#F3F3F3"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.8209 17.6403C16.736 17.91 16.7244 18.1974 16.7873 18.473C16.9527 19.1979 17.5974 19.7121 18.341 19.7121H25.6387C25.974 19.7121 26.2757 19.8557 26.4858 20.0847C26.6734 20.2892 26.7879 20.5619 26.7879 20.8613C26.7879 21.4959 26.2733 22.0105 25.6387 22.0105H25.2028C25.357 22.0105 25.5042 22.0409 25.6387 22.096C25.9781 22.2353 26.2362 22.5323 26.3216 22.8959C26.3414 22.9806 26.352 23.0689 26.352 23.1597C26.352 23.6126 26.0899 24.0043 25.7092 24.1915C25.5564 24.2667 25.3845 24.3089 25.2028 24.3089H24.1928C24.6287 24.3089 25.008 24.5516 25.2028 24.9093C25.251 24.998 25.2879 25.0936 25.3116 25.1944C25.3315 25.2791 25.342 25.3673 25.342 25.4581C25.342 25.6568 25.2915 25.8437 25.2028 26.0068C25.0889 26.2159 24.9121 26.3857 24.6977 26.4907C24.5453 26.5654 24.3739 26.6073 24.1928 26.6073H23.1827C23.6187 26.6073 23.998 26.8501 24.1928 27.2079C24.2815 27.3709 24.3319 27.5578 24.3319 27.7565C24.3319 27.9552 24.2815 28.1421 24.1928 28.3051C24.079 28.5142 23.9021 28.6841 23.6877 28.7891C23.5353 28.8638 23.3639 28.9057 23.1827 28.9057H16.5013C13.7475 28.9057 11.5151 26.6733 11.5151 23.9195C11.5151 23.1673 11.6853 22.4249 12.0129 21.7478L14.4188 16.7752L16.5651 12.1784C16.9057 12.2243 17.2038 12.3721 17.4361 12.5851C17.8783 12.9906 18.0818 13.6322 17.8853 14.2566L16.8209 17.6403ZM12.4483 15.8385L15.2668 9.80176L16.8566 10.0162C19.187 10.3304 20.6723 12.6682 19.9666 14.9113L19.1427 17.5302H25.6387C27.4783 17.5302 28.9697 19.0216 28.9697 20.8613C28.9697 21.4949 28.7927 22.0873 28.4855 22.5916C28.5173 22.7762 28.5338 22.966 28.5338 23.1597C28.5338 24.0975 28.1462 24.9448 27.5225 25.5501C27.4981 26.4513 27.1157 27.2631 26.5125 27.8486C26.4637 29.6457 24.9916 31.0875 23.1827 31.0875H16.5013C12.5425 31.0875 9.33331 27.8783 9.33331 23.9195C9.33331 22.8382 9.57794 21.7709 10.0489 20.7975L12.4483 15.8385Z"
      fill="black"
    />
  </svg>
);

export default BookingIcon;

import { useCallback, useState, useEffect, useRef, useMemo } from 'react';
import aa from 'search-insights';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getDomainInfo } from './environment';
import { debounce, flattenRoutes, hasPermission } from './general';
import routes from '../routes/paths';
import i18n from '../i18n';
import { getPermissions } from '../store/venue/selectors';

export const useRequest = func => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);

  const clearResults = () => setData(null);

  const request = useCallback(
    (...args) => {
      setLoading(true);

      func(...args)
        .then(res => setData(res.getResult()))
        .finally(() => setLoading(false));
    },
    [func],
  );

  return [loading, data, request, clearResults];
};

export const useDebugTraceUpdate = props => {
  const prev = useRef(props);
  useEffect(() => {
    const changedProps = Object.entries(props).reduce((accum, [key, value]) => {
      if (prev.current[key] !== value) {
        return { ...accum, [key]: [prev.current[key], value] };
      }
      return accum;
    }, {});
    if (Object.keys(changedProps).length > 0) {
      // eslint-disable-next-line no-console
      console.log('Changed props:', changedProps);
    }
    prev.current = props;
  });
};

export const useScript = (url, attributeId, onLoad = () => {}) => {
  useEffect(() => {
    const existingScript = document?.getElementById(attributeId);
    if (!existingScript) {
      const script = document.createElement('script');
      script.setAttribute('id', attributeId);
      script.addEventListener('load', onLoad);
      script.async = true;
      script.src = url;
      document.body.appendChild(script);
    } else if (existingScript) {
      existingScript.addEventListener('load', onLoad);
    }
    return () => {
      if (existingScript) document.body.removeChild(existingScript);
    };
  }, [attributeId, onLoad, url]);
};

const algoliaSearch = require('algoliasearch');

const ALGOLIA = {
  APP_ID: '84GJKNJAFR',
  API_KEY: '448539443e248903052005fc27384d54',
};

const SEARCH_TYPES = {
  TEAM: 'team',
  SPORT: 'sport',
  COMPETITION: 'competition',
};

export const useAlgoliaSearch = (location, venueId) => {
  const [loading, setLoading] = useState(false);
  const [results, setResults] = useState(null);
  const [queryId, setQueryId] = useState(null);

  const clearResults = () => setResults(null);

  const client = algoliaSearch(ALGOLIA.APP_ID, ALGOLIA.API_KEY);

  const getIndex = useCallback(() => getDomainInfo(i18n.resolvedLanguage).algoliaIndex, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const index = useMemo(() => client.initIndex(getIndex()), [getIndex]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const search = useCallback(
    debounce((query, categories, hitsPerPage) => {
      const filters = `(category:${categories.join(' OR category:')})`;
      if (query.length >= 2) {
        setLoading(true);
        index
          .search(query, { filters, hitsPerPage, clickAnalytics: true })
          .then(r => {
            setResults(r);
            setQueryId(r?.queryID);
          })
          .finally(() => setLoading(false));
      }
    }, 750),
    [index],
  );

  aa('init', {
    appId: ALGOLIA.APP_ID,
    apiKey: ALGOLIA.API_KEY,
  });
  const eventName = `pogba_${location}_clicked_result`;
  const sendEvent = (objectId, indexParam) =>
    aa('clickedObjectIDs', {
      index: getIndex(),
      eventName,
      objectIDs: [objectId],
      userToken: `venue-${venueId}`,
      queryID: queryId,
      // Position needs to be > 0
      // The first object in the list of search results has a position of 1 (not zero), the second has a position of 2, etc.
      // https://www.algolia.com/doc/api-reference/api-methods/clicked-object-ids-after-search/#method-param-positions
      positions: [indexParam + 1],
    });

  return [loading, search, SEARCH_TYPES, clearResults, results?.hits, results?.nbHits, sendEvent];
};

export const useHiddenByPermissions = additionalCondition => {
  const permissions = useSelector(getPermissions);
  const history = useHistory();

  const redirectedFrom = history.location.pathname;
  const redirectTo = routes.subscriptions.path;

  const getRouteData = () =>
    Object.values(flattenRoutes(routes)).find(({ path }) => path === redirectedFrom);

  useEffect(() => {
    if (
      permissions !== null &&
      !hasPermission(permissions, getRouteData()) &&
      !additionalCondition
    ) {
      history.replace({
        pathname: redirectTo,
        state: { redirectedFrom },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [permissions, redirectedFrom, redirectTo, history]);
};

export const useDidMountEffect = (func, deps) => {
  const didMount = useRef(false);

  useEffect(() => {
    if (didMount.current) func();
    else didMount.current = true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
};

export const useDetectOutsideClick = (ref, action, cond) => {
  useEffect(() => {
    const handleClickOutside = event => {
      if (ref.current && !ref.current.contains(event.target)) {
        action(event);
      }
    };
    if (cond) {
      document.addEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, action, cond]);
};

import { t } from 'i18next';
import i18n from '../../i18n';
import { userIsOnOnTap } from '../../utils/environment';

const assets = () => {
  if (userIsOnOnTap) {
    return [
      {
        text1: t('activation_asset1_title'),
        text2: t('activation_asset1_description'),
        img: 'enUS/desktop1.png',
      },
    ];
  }
  if (i18n.resolvedLanguage === 'fr') {
    return [
      {
        text1: t('activation_asset1_title'),
        text2: t('activation_asset1_description'),
        img: 'fr/desktop1.png',
      },
      {
        text1: t('activation_asset2_title'),
        text2: t('activation_asset2_description'),
        img: 'fr/desktop2.png',
      },
      {
        text1: t('activation_asset3_title'),
        text2: t('activation_asset3_description'),
        img: 'fr/desktop3.png',
      },
    ];
  }
  return [
    {
      text1: t('activation_asset1_title'),
      text2: t('activation_asset1_description'),
      img: 'en/desktop1.png',
    },
    {
      text1: t('activation_asset2_title'),
      text2: t('activation_asset2_description'),
      img: 'en/desktop2.png',
    },
    {
      text1: t('activation_asset3_title'),
      text2: t('activation_asset3_description'),
      img: 'en/desktop3.png',
    },
  ];
};

export default assets();

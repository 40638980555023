/* global FB */
import { useEffect } from 'react';

const FacebookInitialiser = () => {
  useEffect(() => {
    window.fbAsyncInit = () => {
      FB.init({
        appId: '1328508254555391',
        autoLogAppEvents: true,
        xfbml: true,
        version: 'v15.0',
      });
    };
    ((d, s, id) => {
      const fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) return;
      const js = d.createElement(s);
      js.id = id;
      js.src = '//connect.facebook.net/en_US/sdk.js';
      fjs.parentNode.insertBefore(js, fjs);
    })(document, 'script', 'facebook-jssdk');
  }, []);

  return null;
};

export default FacebookInitialiser;

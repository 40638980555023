import React from 'react';
import PropTypes from 'prop-types';

const WarningIcon = ({ className }) => (
  <svg className={`fill-current ${className}`} viewBox="0 0 24 24">
    <path d="M14.4271 1.49498C14.5605 1.3154 14.8229 1.3154 14.8995 1.49498L22.8203 20.0815C22.8969 20.2611 22.7301 20.4855 22.5201 20.4855H0.790782C0.580836 20.4855 0.485174 20.2611 0.618589 20.0815L14.4271 1.49498Z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.6231 13.5051L12.5656 8.13257L14.7474 8.13257L13.805 13.5051H11.6231Z"
      fill="white"
    />
    <path
      d="M10.6064 16.1913C10.7626 15.3012 11.6218 14.5796 12.5255 14.5796C13.4293 14.5796 14.0353 15.3012 13.8792 16.1913C13.723 17.0815 12.8638 17.8031 11.96 17.8031C11.0563 17.8031 10.4503 17.0815 10.6064 16.1913Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.4313 15.1168C11.8288 15.1168 11.256 15.5979 11.1519 16.1913C11.0478 16.7848 11.4518 17.2658 12.0543 17.2658C12.6568 17.2658 13.2296 16.7848 13.3337 16.1913C13.4378 15.5979 13.0338 15.1168 12.4313 15.1168ZM12.5255 14.5796C11.6218 14.5796 10.7626 15.3012 10.6064 16.1913C10.4503 17.0815 11.0563 17.8031 11.96 17.8031C12.8638 17.8031 13.723 17.0815 13.8792 16.1913C14.0353 15.3012 13.4293 14.5796 12.5255 14.5796Z"
      fill="white"
    />
  </svg>
);

WarningIcon.propTypes = {
  className: PropTypes.string,
};
WarningIcon.defaultProps = {
  className: '',
};

export default WarningIcon;

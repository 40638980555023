import { toast } from 'react-toastify';
import * as actions from './actions';
import { requestTiers, requestPromoCodeTiers } from '../api/tiers';
import { fireEvent, GACategories, GATags } from '../../utils/trackingHelper';

export const fetchTiers = (countryId = null) => async dispatch => {
  dispatch(actions.fetchTiersStart());
  return dispatch(requestTiers(countryId))
    .then(response => response.getResult())
    .then(res => dispatch(actions.fetchTiersSuccess(res)))
    .catch(() => dispatch(actions.fetchTiersFail()));
};

export const fetchPromoCodeTiers = (promoCode, countryId, venueId) => async dispatch => {
  dispatch(actions.fetchTiersStart());
  return dispatch(requestPromoCodeTiers(promoCode, countryId, venueId))
    .then(response => response.getResult())
    .then(res => {
      fireEvent(GACategories.TIER, GATags.PROMO_CODE);
      return dispatch(actions.fetchTiersSuccess([res]));
    })
    .catch(error => {
      dispatch(actions.fetchTiersFail());
      if (error.code === 202) {
        toast.error(error.message);
      }
    });
};

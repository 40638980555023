import React from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18next';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import AllowsPet from './Icons/AllowsPet';
import Garden from './Icons/Garden';
import OutdoorScreen from './Icons/OutdoorScreen';
import Projector from './Icons/Projector';
import ServesFood from './Icons/ServesFood';
import Sound from './Icons/Sound';
import Wifi from './Icons/Wifi';
import PlainButton from '../PlainButton/PlainButton';
import { GACategories, GATags, fireEvent } from '../../utils/trackingHelper';
import { venueProps } from '../../utils/customPropTypes';
import { getVenue } from '../../store/venue/selectors';

const facilities = {
  hasWifi: { icon: <Wifi />, text: t('facilities.hasWifi') },
  servesFood: { icon: <ServesFood />, text: t('facilities.servesFood') },
  hasBigScreen: { icon: <Projector />, text: t('facilities.hasBigScreen') },
  allowsPet: { icon: <AllowsPet />, text: t('facilities.allowsPet') },
  hasGarden: { icon: <Garden />, text: t('facilities.hasGarden') },
  hasSound: { icon: <Sound />, text: t('facilities.hasSound') },
  hasOutdoorScreen: { icon: <OutdoorScreen />, text: t('facilities.hasOutdoorScreen') },
};

const FacilitiesSelector = ({ data, onClick, venue }) => {
  const { pathname } = useLocation();
  return Object.entries(facilities).map(f => {
    const facilityName = f[0];
    const button = f[1];
    return (
      <PlainButton
        key={facilityName}
        onClick={() => {
          fireEvent(GACategories.FACILITIES, GATags.FACILITES_SELECTED, {
            venueId: venue.id,
            facility: facilityName,
            page: pathname,
          });
          onClick(prev => ({ ...prev, [facilityName]: !data[facilityName] }));
        }}
        className={`flex items-center gap-2 px-6 py-2 rounded-full border whitespace-nowrap ${
          data[facilityName] ? 'border-quaternary bg-secondaryLight' : 'border-mpGrey'
        }`}
        testId="facilitiesSelector"
      >
        {button.icon}
        <p className="font-semibold leading-none">{button.text}</p>
      </PlainButton>
    );
  });
};

FacilitiesSelector.propTypes = {
  data: PropTypes.shape().isRequired,
  onClick: PropTypes.func.isRequired,
  venue: venueProps.isRequired,
};

const mapStateToProps = state => ({
  venue: getVenue(state),
});

export default connect(mapStateToProps)(FacilitiesSelector);

import '../styles.scss';
import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  drawOnCanvas,
  renderImageWithinConstraints,
  percentageToCanvasCoordinates,
  wrappableCanvasText,
  verticalAlignments,
} from '../shared';
import { createImageObject } from '../../../../utils/general';
import { SMConstraintProps } from '../../../../utils/customPropTypes';

const { top, middle, bottom } = verticalAlignments;

const ImageAndFixturesTemplate = ({
  text,
  logo,
  color,
  textColor,
  logoScale,
  templateHeight,
  templateWidth,
  image,
  template,
  loadedImage,
  logoConstraints,
  imageConstraints,
  textConstraints,
  fontSize,
  font,
  isLandscape,
  textVerticallyCentered,
}) => {
  const canvas = useRef(null);

  useEffect(() => {
    drawOnCanvas(canvas, templateWidth, templateHeight, color, (cc, ctx) => {
      const textCoordinates = percentageToCanvasCoordinates(textConstraints, cc.height, cc.width);

      ctx.font = `${fontSize}px ${font}`;
      ctx.fillStyle = textColor;
      if (!isLandscape) {
        wrappableCanvasText(
          ctx,
          text,
          {
            startX: textCoordinates.startX,
            startY: textCoordinates.startY + fontSize,
            endY: textCoordinates.endY,
            endX: textCoordinates.endX,
          },
          fontSize,
          textColor,
          font,
          textVerticallyCentered ? middle : top,
        );
        // cover text bottom and right overflow in portrait mode
        ctx.fillStyle = color;
        ctx.fillRect(textCoordinates.startX, textCoordinates.endY, cc.width, cc.height);
        ctx.fillRect(textCoordinates.endX, textCoordinates.startY, cc.width, cc.height);
      }

      ctx.fillStyle = color;
      // cuts off text by drawing a small rectangle where text ends
      ctx.fillRect(textCoordinates.endX, 0, cc.width, cc.height);
      renderImageWithinConstraints(
        image,
        imageConstraints,
        ctx,
        cc.height,
        cc.width,
        isLandscape,
        1,
      );
      // triangle
      ctx.fillStyle = color;

      const { startX, startY, endX, endY } = percentageToCanvasCoordinates(
        imageConstraints,
        cc.height,
        cc.width,
      );

      const offsetFromSide = (endX - startX) / 4;
      const firstCornerX = startX + offsetFromSide;
      const secondCornerX = endX - offsetFromSide;
      const thirdCornerX = (secondCornerX + firstCornerX) / 2;
      const thirdCornerY = (endY + startY) / 2;

      ctx.beginPath();
      ctx.moveTo(firstCornerX, startY);
      ctx.lineTo(secondCornerX, startY);
      ctx.lineTo(thirdCornerX, thirdCornerY);
      ctx.fill();

      if (isLandscape) {
        wrappableCanvasText(
          ctx,
          text,
          {
            startX: textCoordinates.startX,
            startY: textCoordinates.startY + fontSize,
            endY: textCoordinates.endY,
            endX: textCoordinates.endX,
          },
          fontSize,
          textColor,
          font,
          textVerticallyCentered ? middle : bottom,
        );
        ctx.fillStyle = color;
        // cover text right  overflow in landscape mode
        ctx.fillRect(textCoordinates.endX, textCoordinates.startY, cc.width, cc.height);
      }
      ctx.drawImage(createImageObject(template), 0, 0, templateWidth, templateHeight);
      renderImageWithinConstraints(
        logo,
        logoConstraints,
        ctx,
        cc.height,
        cc.width,
        false,
        logoScale,
      );
    });
  }, [
    logo,
    image,
    template,
    text,
    color,
    textColor,
    logoScale,
    templateHeight,
    templateWidth,
    loadedImage,
    logoConstraints,
    imageConstraints,
    fontSize,
    font,
    textConstraints,
    isLandscape,
    textVerticallyCentered,
  ]);

  return (
    <canvas
      className="ImageGenerator_canvas"
      ref={canvas}
      width={templateWidth}
      height={templateHeight}
    />
  );
};

ImageAndFixturesTemplate.propTypes = {
  templateHeight: PropTypes.number.isRequired,
  templateWidth: PropTypes.number.isRequired,
  text: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  textColor: PropTypes.string.isRequired,
  logoScale: PropTypes.number.isRequired,
  logo: PropTypes.string,
  image: PropTypes.string,
  template: PropTypes.string,
  loadedImage: PropTypes.bool,
  logoConstraints: SMConstraintProps,
  imageConstraints: SMConstraintProps,
  fontSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  font: PropTypes.string.isRequired,
  textConstraints: SMConstraintProps,
  isLandscape: PropTypes.bool.isRequired,
  textVerticallyCentered: PropTypes.bool,
};

ImageAndFixturesTemplate.defaultProps = {
  logo: null,
  image: null,
  template: null,
  logoConstraints: null,
  imageConstraints: null,
  textConstraints: null,
  textVerticallyCentered: false,
  loadedImage: false,
};

export default ImageAndFixturesTemplate;

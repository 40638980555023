import { getURLQuery } from '../../utils/general';
import { getUser } from '../auth/selectors';
import { getVenueID } from '../venue/selectors';
import { request } from './request';

export const getVenuesList = overrideVenueId => dispatch => {
  const venueParam = overrideVenueId ? `?venueId=${overrideVenueId}` : '';
  return dispatch(request(`venues/${venueParam}`));
};

export const getPermissions = venueID => dispatch =>
  dispatch(request(`venues/${venueID}/permissions`));

export const getTwitterAuthURL = venueID => dispatch =>
  dispatch(request(`/meta/${venueID}/social-media/twitter/get-authorization-url`));

export const twitterLogoutReq = venueID => dispatch =>
  dispatch(request(`/meta/${venueID}/social-media/twitter/disconnect`));

export const getVenueChannels = id => dispatch => dispatch(request(`venues/${id}/channels`));

export const updateVenueChannels = (id, channelIds) => dispatch =>
  dispatch(
    request(`venues/${id}/channels/save`, {
      method: 'PUT',
      body: { channelIds },
    }),
  );

export const venueSubscribe = (id, tierId, frequency = 12, promoCode) => dispatch =>
  dispatch(
    request(`venues/${id}/subscribe`, {
      method: 'POST',
      body: { tierId, frequency, promoCode },
    }),
  );

export const subscribeFinalise = (id, key) => dispatch =>
  dispatch(
    request(`venues/${id}/finalise-subscription`, {
      method: 'POST',
      body: { key },
    }),
  );

export const resubscribeFinalise = (id, resubKey) => dispatch =>
  dispatch(
    request(`venues/${id}/resubscribe-callback`, {
      method: 'POST',
      body: { resubKey },
    }),
  );

export const getStatesByCountry = countryId => dispatch =>
  dispatch(request(`meta/countries/${countryId}/states`));

export const getGooglePhotos = venueId => dispatch =>
  dispatch(request(`venues/${venueId}/google-photos`));

export const saveVenue = venue => dispatch =>
  dispatch(
    request(`venues/${venue.id}/update`, {
      method: 'PUT',
      body: { venue },
    }),
  );

export const saveVenueFavs = (venueId, favourites) => dispatch =>
  dispatch(
    request(`sports/${venueId}/favourites`, {
      method: 'POST',
      body: { favourites },
    }),
  );

export const saveVenuePhotos = (venueId, venueImages) => dispatch =>
  dispatch(
    request(`venues/${venueId}/photos/save`, {
      method: 'POST',
      body: { venueImages },
    }),
  );

export const fetchLeagueRankings = (gameId, venueId, params) => dispatch =>
  dispatch(request(`games/${gameId}/venue-league/${venueId}/rankings${getURLQuery(params)}`));

export const fetchLeagueMembers = (gameId, venueId, params = {}) => dispatch =>
  dispatch(request(`games/${gameId}/venue-league/${venueId}/members${getURLQuery(params)}`));

export const updateVenueLeague = (gameId, venueId, venueLeague) => dispatch =>
  dispatch(
    request(`games/${gameId}/venue-league/${venueId}/update`, {
      method: 'PUT',
      body: { venueLeague },
    }),
  );

export const updatePendingVenueMember = (id, action = 'approve') => dispatch =>
  dispatch(
    request(`games/venue-league/member/${id}/${action}`, {
      method: 'PUT',
    }),
  );

export const fetchLeaguePrizes = (gameId, venueId) => dispatch =>
  dispatch(request(`games/${gameId}/venue-league/${venueId}/round-prizes`));

export const updateLeaguePrizes = (gameId, venueId, roundId, prize) => dispatch =>
  dispatch(
    request(`games/${gameId}/venue-league/${venueId}/round-prize/${roundId}`, {
      method: 'POST',
      body: prize,
    }),
  );

export const removeLeaguePrizes = (gameId, venueId, roundId, applyTo) => dispatch =>
  dispatch(
    request(
      `games/${gameId}/venue-league/${venueId}/round-prize/${roundId}/remove?applyTo=${applyTo}`,
      { method: 'POST' },
    ),
  );

export const fetchGameRounds = gameId => dispatch => dispatch(request(`games/${gameId}/rounds`));

export const fetchMemberPredictions = (memberId, roundId) => dispatch =>
  dispatch(request(`games/member/${memberId}/round-predictions/${roundId}`));

export const updateMemberTips = (gameId, memberId, tips, venueId) => dispatch =>
  dispatch(
    request(`games/${gameId}/member/${memberId}/set-tips`, {
      method: 'POST',
      body: {
        venueId,
        tips,
      },
    }),
  );

export const addVenueLeaguePaperTippers = (gameId, venueId, playerNames, leagueId) => dispatch =>
  dispatch(
    request(`games/${gameId}/venue-league/${venueId}/add-offline-players`, {
      method: 'PUT',
      body: { playerNames, leagueId },
    }),
  );

export const updateVenueLeaguePaperTipper = (gameId, venueId, memberId, playerName) => dispatch =>
  dispatch(
    request(`games/${gameId}/venue-league/${venueId}/members/${memberId}`, {
      method: 'PUT',
      body: { playerName },
    }),
  );

export const removeVenueLeaguePaperTipper = (gameId, venueId, memberId, leagueId) => dispatch =>
  dispatch(
    request(`games/${gameId}/venue-league/${venueId}/remove-players`, {
      method: 'DELETE',
      body: { memberIds: [memberId], leagueId },
    }),
  );

export const removeVenueImage = (venueId, imageID) => dispatch =>
  dispatch(
    request(`venues/${venueId}/image/${imageID}/delete`, {
      method: 'DELETE',
    }),
  );

export const updateFacebookInfo = (venueId, facebookInfo) => dispatch =>
  dispatch(
    request(`meta/${venueId}/social-media/facebook/connect`, {
      method: 'PUT',
      body: { payload: facebookInfo },
    }),
  );

export const removeFacebookInfo = venueId => dispatch =>
  dispatch(
    request(`meta/${venueId}/social-media/facebook/disconnect`, {
      method: 'DELETE',
    }),
  );

export const fetchInvoices = (venueID, limit, offset) => dispatch =>
  dispatch(request(`venues/${venueID}/invoices${getURLQuery({ limit, offset })}`));

export const sendTracking = (category, action, data) => (dispatch, getState) => {
  const { id } = getUser(getState());
  const venueId = getVenueID(getState());
  return dispatch(
    request(`tracking/activity-tracking`, {
      method: 'POST',
      body: {
        venueId,
        memberId: id,
        category,
        action,
        data,
      },
    }),
  );
};

export const getAssetGroups = venueID => dispatch =>
  dispatch(request(`meta/${venueID}/asset-groups`));

export const getAssetGroup = (venueID, assetGroupId) => dispatch =>
  dispatch(request(`meta/${venueID}/asset-groups/${assetGroupId}`));

export const fetchFinancialFormDesc = (venueId, params) => dispatch =>
  dispatch(
    request(
      `venues/${venueId}/bank-details/update${getURLQuery({
        ...params,
        formDescription: 1,
      })}`,
      {
        method: 'PUT',
      },
    ),
  );

export const fetchFinancialFormValues = venueId => dispatch =>
  dispatch(request(`venues/${venueId}/bank-details`));

export const saveFinancialFormValues = (venueId, body, method) => dispatch =>
  dispatch(
    request(`venues/${venueId}/bank-details/${method}`, {
      method: method === 'update' ? 'PUT' : 'POST',
      body,
    }),
  );

import React from 'react';
import PropTypes from 'prop-types';
import MinusIcon from '../../components/Icons/MinusIcon';
import PlusIcon from '../../components/Icons/PlusIcon';
import PlainButton from '../../components/PlainButton/PlainButton';

const NumberSelector = ({ counter, onChange }) => {
  const handleChange = increment => onChange(Math.min(Math.max(counter + increment, 1), 99));

  return (
    <div className="flex px-4 py-3 bg-white border border-mpGrey rounded-full items-center justify-between">
      <PlainButton onClick={() => handleChange(-1)} className="outline-none">
        <MinusIcon className="h-12 bg-mpGrey rounded-full" />
      </PlainButton>
      <p className="headingFont text-4xl w-10 border-b border-mpGrey text-center py-2 outline-primary">
        {counter}
      </p>
      <PlainButton onClick={() => handleChange(1)} className="outline-none" testId="numberSelector">
        <PlusIcon className="h-12 bg-mpGrey rounded-full" />
      </PlainButton>
    </div>
  );
};

NumberSelector.propTypes = {
  counter: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default NumberSelector;

import React, { useState } from 'react';
import { getTranslatedDay } from '../../i18n';
import OpenHoursSelector from './OpenHoursSelector';

export const compareOpeningTimes = (pubData, venue) => {
  const newDays = Object.keys(pubData.openingHours).map(k => pubData.openingHours[k]);
  const originalDays = Object.keys(venue.openingHours).map(k => venue.openingHours[k]);
  return originalDays.every(
    (k, i) => k.opening === newDays[i].opening && k.closing === newDays[i].closing,
  );
};

export const checkForMissingOpeningTimes = pubData =>
  Object.entries(pubData.openingHours).some(
    times => !(!times[1].opening && !times[1].closing) && (!times[1].opening || !times[1].closing),
  );

const OpeningHours = ({ openingHours, onChange }) => {
  const [showCopyDayButton, setShowCopyDayButton] = useState(null);

  return Object.keys(openingHours).map((day, i) => (
    <OpenHoursSelector
      key={`${day}`}
      showCopyDayButton={showCopyDayButton === day}
      copyDayButtonAction={() => {
        const newDays = {};
        Object.keys(openingHours).forEach(d => {
          newDays[d] = {
            opening: openingHours[day].opening,
            closing: openingHours[day].closing,
          };
        });
        onChange(newDays);
        setShowCopyDayButton(null);
      }}
      text={getTranslatedDay(i).substring(0, 3)}
      valueFrom={openingHours[day].opening}
      valueTo={openingHours[day].closing}
      setDay={(from, to) => {
        setShowCopyDayButton(day);
        onChange({
          ...openingHours,
          [day]: {
            opening: from,
            closing: to,
          },
        });
      }}
    />
  ));
};

export default OpeningHours;

import { useCallback, useState, useEffect } from 'react';

// eslint-disable-next-line import/prefer-default-export
export const useChartScroll = ({ data, isMobile }) => {
  const limit = isMobile ? 7 : 14;

  const getEndIndex = useCallback(() => {
    if (data.length > limit) {
      return limit;
    }
    return data.length;
  }, [data, limit]);

  const [startIndex, setStartIndex] = useState(0);
  const [endIndex, setEndIndex] = useState(getEndIndex());

  useEffect(() => {
    setStartIndex(0);
    setEndIndex(getEndIndex());
  }, [data, getEndIndex]);

  return {
    lessProps: () => ({
      onClick: () => {
        setStartIndex(startIndex - 1);
        setEndIndex(endIndex - 1);
      },
      disabled: startIndex === 0,
    }),
    moreProps: () => ({
      onClick: () => {
        setStartIndex(startIndex + 1);
        setEndIndex(endIndex + 1);
      },
      disabled: endIndex === data.length,
    }),
    filteredData: data.slice(startIndex, endIndex),
    show: data.length > limit,
  };
};

import * as consts from './constants';

export const initialState = {
  isMobile: false,
  isTablet: false,
  isDesktop: false,
  geoLocale: null,
  countries: [],
  showMobileSidebar: false,
  showSwitchProfiles: false,
};

function appReducer(state = initialState, action) {
  switch (action.type) {
    case consts.RESIZE_SCREEN: {
      return {
        ...state,
        isMobile: window.matchMedia('screen and (max-width: 766px)').matches,
        isTablet: window.matchMedia('screen and (min-width: 767px) and (max-width: 1024px)')
          .matches,
        isDesktop: window.matchMedia('screen and (min-width: 1025px)').matches,
      };
    }
    case consts.SET_GEO_LOCALE:
      return {
        ...state,
        geoLocale: action.payload,
      };
    case consts.FETCH_COUNTRIES_SUCCESS: {
      const countries = action.payload;
      return {
        ...state,
        isFetchingCountries: false,
        countries,
      };
    }
    case consts.SHOW_MOBILE_SIDEBAR: {
      return {
        ...state,
        showMobileSidebar: action.payload,
      };
    }
    case consts.SHOW_SWITCH_PROFILES: {
      return {
        ...state,
        showSwitchProfiles: action.payload,
      };
    }
    default:
      return state;
  }
}

export default appReducer;

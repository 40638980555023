import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';

const StaticContent = ({ data }) => (
  // eslint-disable-next-line react/no-danger
  <div className="Static" dangerouslySetInnerHTML={{ __html: data }} />
);

StaticContent.propTypes = {
  data: PropTypes.string.isRequired,
};

export default StaticContent;

import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import PlainButton from '../../components/PlainButton/PlainButton';
import CloseIcon from '../../components/Icons/CloseIcon';
import MPModal from '../../components/MPModal/MPModal';
import { publicRoutes } from '../../routes/paths';

const styles = {
  title: 'headingFont text-5xl md:text-6xl pt-2',
  subtitle: 'py-4 opacity-90 md:text-xl',
  btn: 'w-full p-4 font-semibold rounded-lg',
};

const AppUserErrorModal = ({ onClose, t }) => (
  <MPModal open onClose={onClose} className="max-w-sm md:max-w-md rounded-2xl">
    <div className="relative px-8 md:px-16 pb-8 md:pb-16 pt-16">
      <PlainButton onClick={onClose} className="absolute top-4 right-4">
        <CloseIcon className="w-12 h-12 p-2 bg-mpGrey rounded-full" />
      </PlainButton>
      <h1 className={styles.title}>{t('signup.appUser.title')}</h1>
      <p className={styles.subtitle}>{t('signup.appUser.text1')}</p>
      <p className={styles.subtitle}>{t('signup.appUser.text2')}</p>
      <p className="opacity-90 md:text-xl font-semibold mt-2">{t('signup.appUser.text3')}</p>
      <p className={`${styles.subtitle} whitespace-pre-line`}>{t('signup.appUser.text4')}</p>
      <Link to={publicRoutes.login}>
        <p className={`${styles.btn} bg-black text-white text-center`}>
          {t('signup.appUser.button1')}
        </p>
      </Link>
      <PlainButton className={`${styles.btn} bg-mpGrey mt-4`} onClick={onClose}>
        {t('signup.appUser.button2')}
      </PlainButton>
    </div>
  </MPModal>
);
AppUserErrorModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default AppUserErrorModal;

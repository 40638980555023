import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18next';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import queryString from 'query-string';
import UnprotectedLayout from '../UnprotectedLayout/UnprotectedLayout';
import TermsAndConditionsSnippet from '../../components/TermsAndConditionsSnippet/TermsAndConditionsSnippet';
import Input from '../SignupV2/Input';
import { historyProps } from '../../utils/customPropTypes';
import { publicRoutes } from '../../routes/paths';
import SpinnerButton from '../../components/SpinnerButton/SpinnerButton';
import { genericRequest } from '../../store/api/request';
import { requestLogin } from '../../store/auth/thunks';
import i18n from '../../i18n';

const ActivateNewUser = ({ generic, history, login }) => {
  const [fieldErrors, setFieldErrors] = useState({});

  const [loading, setLoading] = useState(false);
  const { token, venueId } = queryString.parse(history.location.search);

  const [state, updateState] = useState({
    email: '',
    password: '',
    passwordConfirm: '',
  });

  const passwordsAreOk = Boolean(
    state.password && state.passwordConfirm && state.password === state.passwordConfirm,
  );

  const setState = newData => {
    setFieldErrors({});
    updateState(prev => ({ ...prev, ...newData }));
  };

  useEffect(() => {
    setLoading(true);
    generic(`auth/venue-members/token`, {}, 'POST', { venueId, token })
      .then(res => setState({ email: res?.getResult?.()?.memberEmail }))
      .catch(() => history.push(publicRoutes.landing))
      .finally(() => setLoading(false));
  }, [generic, history, token, venueId]);

  const disabled = !passwordsAreOk || loading;

  return (
    <UnprotectedLayout>
      <div className="max-w-[420px] mx-auto w-full pt-10 sm:pt-14">
        <h1 className="headingFont text-4xl">{i18n.t('activateNewUser_title')}</h1>
        <p className="opacity-60 pt-2">{t('activateNewUser_subtitle')}</p>
        <div className="flex flex-col gap-4 pt-8 sm:pt-14">
          <Input
            label={t('settings.email')}
            placeholder={state.email}
            type="email"
            disabled
            dataTestId="email"
          />
          <Input
            label={t('auth.password')}
            placeholder={t('auth_password_placeholder1')}
            value={state.password}
            onChange={e => setState({ password: e })}
            type="password"
            error={fieldErrors.password}
            dataTestId="password"
          />
          <Input
            label={t('auth.password_confirm')}
            placeholder={t('auth_password_placeholder2')}
            value={state.passwordConfirm}
            onChange={e => setState({ passwordConfirm: e })}
            type="password"
            error={fieldErrors.password}
            dataTestId="currentPassword"
          />
          <SpinnerButton
            className={`w-full  py-3.5 font-semibold rounded-lg ${
              !disabled ? 'bg-secondary' : 'opacity-40 bg-lightGrey'
            }`}
            disabled={disabled}
            loading={loading}
            onClick={() => {
              setLoading(true);
              generic(`auth/venue-members/set-password`, {}, 'POST', {
                password: state.password,
                passwordConfirm: state.passwordConfirm,
                token,
              })
                .then(() => login(state.email, state.password, venueId))
                .catch(e => {
                  if (e.error) {
                    setFieldErrors(e.error);
                  }
                  if (e.message) {
                    toast.error(e.message);
                  }
                })
                .finally(() => setLoading(false));
            }}
          >
            {t('general.submit')}
          </SpinnerButton>
          <TermsAndConditionsSnippet buttonText={t('general.submit')} />
        </div>
      </div>
    </UnprotectedLayout>
  );
};

ActivateNewUser.propTypes = {
  generic: PropTypes.func.isRequired,
  login: PropTypes.func.isRequired,
  history: historyProps.isRequired,
};

const mdtp = {
  generic: genericRequest,
  login: requestLogin,
};

export default connect(null, mdtp)(ActivateNewUser);

import PropTypes from 'prop-types';
import './styles.scss';
import React from 'react';
import PlainButton from '../../../../components/PlainButton/PlainButton';

const InlineColorSelector = ({ colours, onSelect, selected }) => (
  <div className="InlineColorSelector_color_selector">
    {colours.map(c => (
      <PlainButton key={c} onClick={() => onSelect(c)}>
        <div
          className={`InlineColorSelector_color${selected === c ? '_selected' : ''}`}
          style={{ backgroundColor: c }}
        />
      </PlainButton>
    ))}
  </div>
);

InlineColorSelector.propTypes = {
  colours: PropTypes.arrayOf(PropTypes.string).isRequired,
  selected: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
};
InlineColorSelector.defaultProps = {
  selected: undefined,
};

export default InlineColorSelector;

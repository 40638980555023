import React from 'react';
import PropTypes from 'prop-types';
import ChevronIcon from '../Icons/ChevronIcon';

const DropdownSelector = ({ options, selected, onValueChange, onClick, placeholder, label }) => {
  if (!options?.length) {
    return null;
  }
  return (
    <div>
      {label && <p className="font-bold pb-2">{label}</p>}
      <div className="relative">
        <select
          className="w-full appearance-none truncate rounded-lg border border-mpGrey bg-white py-3 pl-4 pr-10 focus:outline-primary"
          onChange={e => onValueChange(options.find(o => `${o.value}` === e.target.value))}
          onClick={onClick}
          value={selected || { key: '', value: '' }}
        >
          {placeholder && (
            <option value="" hidden>
              {placeholder}
            </option>
          )}
          {options?.map(option => (
            <option
              key={option.key}
              id={option.key}
              value={option.value}
              hidden={option.key === null}
            >
              {option.value}
            </option>
          ))}
        </select>
        <div className="pointer-events-none absolute top-1/2 right-4 -translate-y-1/2 transform">
          <ChevronIcon className="text-black w-5 rotate-90" />
        </div>
      </div>
    </div>
  );
};

const keyValue = PropTypes.shape({
  key: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
});

DropdownSelector.propTypes = {
  options: PropTypes.arrayOf(keyValue).isRequired,
  selected: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onValueChange: PropTypes.func.isRequired,
  onClick: PropTypes.func,
  placeholder: PropTypes.string,
  label: PropTypes.string,
};

DropdownSelector.defaultProps = {
  selected: null,
  onClick: () => {},
  placeholder: null,
  label: null,
};

export default DropdownSelector;

import React from 'react';
import { connect } from 'react-redux';
import { getVenue } from '../../store/venue/selectors';
import { userIsOnFanzo } from '../../utils/environment';

const Intercom = ({ venueID }) => {
  React.useEffect(() => {
    const initIntercom = () => {
      const w = window;
      const ic = w.Intercom;
      const APP_ID = process.env.REACT_APP_INTERCOM_APP_ID;
      window.intercomSettings = {
        app_id: APP_ID,
        user_id: venueID,
      };
      if (typeof ic === 'function') {
        ic('reattach_activator');
        ic('update', w.intercomSettings);
      } else {
        const d = document;
        const i = () => {};
        i.c = () => {};
        w.Intercom = i;
        const l = () => {
          const s = d.createElement('script');
          s.type = 'text/javascript';
          s.async = true;
          s.src = `https://widget.intercom.io/widget/${APP_ID}`;
          d.body.appendChild(s);
        };
        if (w.attachEvent) {
          w.attachEvent('onload', l);
        } else {
          w.addEventListener('load', l, false);
        }
      }
    };
    if (userIsOnFanzo) {
      initIntercom();
    }
  }, [venueID]);
  return null;
};

const mapStateToProps = state => {
  const venue = getVenue(state);
  return {
    venueID: venue ? venue.id : null,
  };
};

export default connect(mapStateToProps)(Intercom);

import React from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18next';
import { Link } from 'react-router-dom';
import PlainButton from '../../components/PlainButton/PlainButton';
import MPModal from '../../components/MPModal/MPModal';
import routes from '../../routes/paths';
import CloseIcon from '../../components/Icons/CloseIcon';
import { navigate } from '../../store/navigation/thunks';
import { cdnUrl } from '../../utils/constants';

const styles = {
  modalBtn: 'block w-full rounded-lg bg-black py-4 font-semibold',
};

const PublishProfileModal = ({ open }) => {
  const onClose = () => navigate(routes.home.path);
  return (
    <MPModal
      open={open}
      onClose={onClose}
      className="max-h-full max-w-md overflow-y-auto md:max-w-lg lg:max-w-xl bg-transparent px-4"
    >
      <div className="relative bg-white rounded-2xl text-center p-8 md:p-16">
        <PlainButton
          className="hidden md:flex absolute right-4 top-4 p-2 bg-mpGrey rounded-full outline-none"
          onClick={onClose}
        >
          <CloseIcon className="w-8 h-8" />
        </PlainButton>
        <h1 className="headingFont pb-2 text-4xl md:pt-2 md:text-5xl lg:text-6xl">
          {t('publishProfile_modalTitle')}
        </h1>
        <p className="opacity-80 md:text-xl">{t('publishProfile_modalSubtitle')}</p>
        <img
          src={`${cdnUrl}/onboarding/mobile-preview.png`}
          className="w-full max-h-72 py-8 object-contain"
          alt=""
        />
        <Link
          className={`${styles.modalBtn} mb-2 bg-black text-white`}
          to={routes.subscriptions.path}
        >
          {t('publishProfile_modalCta')}
        </Link>
        <PlainButton className={`${styles.modalBtn} bg-veryLightGrey`} onClick={onClose}>
          {t('publishProfile_modalClose')}
        </PlainButton>
      </div>
    </MPModal>
  );
};

PublishProfileModal.propTypes = {
  open: PropTypes.bool,
};

PublishProfileModal.defaultProps = {
  open: false,
};

export default PublishProfileModal;

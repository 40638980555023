import React, { useEffect, useState } from 'react';
import { Router, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import ReactResizeDetector from 'react-resize-detector';
import PropTypes from 'prop-types';
import { ToastContainer } from 'react-toastify';
import queryString from 'query-string';
import ReactGA4 from 'react-ga4';
import { init } from '@amplitude/analytics-browser';
import Protected from './protected';
import Public from './public';
import { getIsLoggedIn } from '../store/auth/selectors';
import 'react-toastify/dist/ReactToastify.min.css';
import { checkAuthentication } from '../store/auth/thunks';
import './index.scss';
import * as actions from '../store/app/actions';
import history from './history';
import LoadingAnimation from '../components/LoadingAnimation/LoadingAnimation';
import GAPageTracker from '../components/GAPageTracker';
import HeadTagOverride from './HeadTagOverride';
import { getDomainInfo } from '../utils/environment';
import i18n from '../i18n';
import OnLoadScripts from './OnLoadScripts/OnLoadScripts';

const App = ({ isLoggedIn, checkAuth, resizeScreen }) => {
  const [isFetching, setIsFetching] = useState(true);
  useEffect(() => {
    const params = queryString.parse(window.location.search);
    checkAuth(params.venueId, params.activationKey)
      .catch(() => {})
      .finally(() => setIsFetching(false));
    ReactGA4.initialize(getDomainInfo(i18n.resolvedLanguage).ga4Id);
    init(process.env.REACT_APP_AMPLITUDE_TAG);
  }, [checkAuth]);

  return (
    <Router history={history}>
      <GAPageTracker>
        <HeadTagOverride />
        <OnLoadScripts />
        <ReactResizeDetector handleWidth onResize={resizeScreen} />
        <ToastContainer hideProgressBar draggablePercent={50} autoClose={6000} />
        {isFetching && <LoadingAnimation />}
        {!isFetching && <Route render={() => (isLoggedIn ? <Protected /> : <Public />)} />}
      </GAPageTracker>
    </Router>
  );
};

App.propTypes = {
  isLoggedIn: PropTypes.bool.isRequired,
  checkAuth: PropTypes.func.isRequired,
  resizeScreen: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  isLoggedIn: getIsLoggedIn(state),
});

const mapDispatchToProps = dispatch => ({
  checkAuth: (venueId, activationKey) => dispatch(checkAuthentication(venueId, activationKey)),
  resizeScreen: () => dispatch(actions.resizeScreen()),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);

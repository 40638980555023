import React, { useState, useEffect } from 'react';
import { PropTypes } from 'prop-types';
import './styles.scss';
import { connect } from 'react-redux';
import i18n from 'i18next';
import PageHeader from '../../components/PageHeader/PageHeader';
import Snippet from '../../components/Snippet';
import { getVenue } from '../../store/venue/selectors';
import LoadingAnimation from '../../components/LoadingAnimation/LoadingAnimation';
import { request } from '../../store/api/request';
import { venueProps } from '../../utils/customPropTypes';
import Button from '../../components/Button/Button';
import { fireEvent, GATags, GACategories } from '../../utils/trackingHelper';
import assets, { icons } from './assets';

const IntegrateCalendarPage = ({ title, venue, generic }) => {
  const [calendarURL, setCalendarURL] = useState(null);
  const [calService, setCalService] = useState('google');
  const [fetching, setFetching] = useState(false);
  const [error, setError] = useState(false);
  const venueId = venue.id;

  useEffect(() => {
    setFetching(true);
    generic(`venues/${venueId}/fixtures/calendar`)
      .then(r => r.getResult())
      .then(r => {
        fireEvent(GACategories.CALENDAR_SYNC, GATags.CALENDAR_SYNC, { venueId });
        fireEvent(GACategories.CALENDAR_SYNC, GATags.CALENDAR_GOOGLE, { venueId });
        if (r?.calendarURL) {
          setCalendarURL(r.calendarURL);
        } else {
          setError(i18n.t('integrate.calendar.error'));
        }
      })
      .catch(err => setError(err?.error?.message || i18n.t('error')))
      .finally(() => setFetching(false));
  }, [venueId, generic]);

  const renderAppleInstructions = () => (
    <>
      <div className="IntegrateCalendarPage_title_container">
        <img src={icons.iCal} alt="" />
        <h1 className="IntegrateCalendarPage_title">{i18n.t('integrate.calendar.apple.title')}</h1>
      </div>
      <p className="IntegrateCalendarPage_text">
        1. {i18n.t('integrate.calendar.apple.copy_the_url')}
      </p>
      <div className="IntegrateCalendarPage_snippetContainer">
        {calendarURL && (
          <Snippet
            codeSnippet={calendarURL}
            id="calendarCode"
            gaTracking={() =>
              fireEvent(GACategories.CALENDAR_SYNC, GATags.CALENDAR_URL_COPY, { venueId })
            }
          />
        )}
      </div>
      <p className="IntegrateCalendarPage_text">2. {i18n.t('integrate.calendar.apple.open_cal')}</p>
      <img alt="" src={assets.ical1} className="IntegrateCalendarPage_image" />
      <p className="IntegrateCalendarPage_text">
        3. {i18n.t('integrate.calendar.apple.click_file')}
      </p>
      <p className="IntegrateCalendarPage_text">
        4. {i18n.t('integrate.calendar.apple.new_calendar')}
      </p>
      <img alt="" src={assets.ical2} className="IntegrateCalendarPage_image_mini" />
      <p className="IntegrateCalendarPage_text">
        5. {i18n.t('integrate.calendar.apple.paste_url')}
      </p>
      <p className="IntegrateCalendarPage_text">
        6. {i18n.t('integrate.calendar.apple.click_subscribe')}
      </p>
      <img alt="" src={assets.ical3} className="IntegrateCalendarPage_image_mini" />
      <p className="IntegrateCalendarPage_text">7. {i18n.t('integrate.calendar.apple.name_it')}</p>
      <p className="IntegrateCalendarPage_text">
        8. {i18n.t('integrate.calendar.apple.refresh_freq')}
      </p>
      <img alt="" src={assets.ical4} className="IntegrateCalendarPage_image_mini" />
      <p className="IntegrateCalendarPage_text">9. {i18n.t('integrate.calendar.apple.click_ok')}</p>
      <p className="IntegrateCalendarPage_text">10. {i18n.t('integrate.calendar.apple.success')}</p>
    </>
  );

  const renderGoogleInstructions = () => (
    <>
      <div className="IntegrateCalendarPage_title_container">
        <img src={icons.gCal} alt="" />
        <h1 className="IntegrateCalendarPage_title">{i18n.t('integrate.calendar.google.title')}</h1>
      </div>
      <p className="IntegrateCalendarPage_text">
        1. {i18n.t('integrate.calendar.google.copy_the_url')}
      </p>
      <div className="IntegrateCalendarPage_snippetContainer">
        {calendarURL && (
          <Snippet
            codeSnippet={calendarURL}
            id="calendarCode"
            gaTracking={() =>
              fireEvent(GACategories.CALENDAR_SYNC, GATags.CALENDAR_URL_COPY, { venueId })
            }
          />
        )}
      </div>
      <p className="IntegrateCalendarPage_text">
        2. {i18n.t('integrate.calendar.google.go_on_google_calendar')}
      </p>
      <p className="IntegrateCalendarPage_text">
        3. {i18n.t('integrate.calendar.google.click_plus')}
      </p>
      <img alt="" src={assets.google1} className="IntegrateCalendarPage_image" />
      <p className="IntegrateCalendarPage_text">
        4. {i18n.t('integrate.calendar.google.select_from_url')}
      </p>
      <img alt="" src={assets.google2} className="IntegrateCalendarPage_image" />
      <p className="IntegrateCalendarPage_text">
        5. {i18n.t('integrate.calendar.google.paste_and_add')}
      </p>
      <img alt="" src={assets.google3} className="IntegrateCalendarPage_image" />
      <p className="IntegrateCalendarPage_text">6. {i18n.t('integrate.calendar.google.success')}</p>
    </>
  );

  const renderOutlookInstructions = () => (
    <>
      <div className="IntegrateCalendarPage_title_container">
        <img src={icons.outlook} alt="" />
        <h1 className="IntegrateCalendarPage_title">
          {i18n.t('integrate.calendar.outlook.title')}
        </h1>
      </div>
      <p className="IntegrateCalendarPage_text">
        1. {i18n.t('integrate.calendar.outlook.copy_the_url')}
      </p>
      <div className="IntegrateCalendarPage_snippetContainer">
        {calendarURL && (
          <Snippet
            codeSnippet={calendarURL}
            id="calendarCode"
            gaTracking={() =>
              fireEvent(GACategories.CALENDAR_SYNC, GATags.CALENDAR_URL_COPY, { venueId })
            }
          />
        )}
      </div>
      <p className="IntegrateCalendarPage_text">
        2. {i18n.t('integrate.calendar.outlook.login')}{' '}
        <a href="https://outlook.live.com/mail/inbox">{i18n.t('general_here')}</a>
      </p>
      <p className="IntegrateCalendarPage_text">
        3. {i18n.t('integrate.calendar.outlook.click_cal')}
      </p>
      <img alt="" src={assets.outlook1} className="IntegrateCalendarPage_image" />
      <p className="IntegrateCalendarPage_text">
        4. {i18n.t('integrate.calendar.outlook.click_import_calendar')}
      </p>
      <img alt="" src={assets.outlook2} className="IntegrateCalendarPage_image" />
      <p className="IntegrateCalendarPage_text">
        5. {i18n.t('integrate.calendar.outlook.select_web')}
      </p>
      <img alt="" src={assets.outlook3} className="IntegrateCalendarPage_image" />
      <p className="IntegrateCalendarPage_text">
        6. {i18n.t('integrate.calendar.outlook.paste_the_url')}
      </p>
      <p className="IntegrateCalendarPage_text">
        7. {i18n.t('integrate.calendar.outlook.give_calendar_name')}
      </p>
      <p className="IntegrateCalendarPage_text">
        8. {i18n.t('integrate.calendar.outlook.click_import')}
      </p>
      <p className="IntegrateCalendarPage_text">
        9. {i18n.t('integrate.calendar.outlook.success')}
      </p>
    </>
  );

  const calButton = (tag, service, disabled, text) => (
    <Button
      className={`bg-secondary mr-3 text-sm font-semibold ${disabled ? 'opacity-20' : ''}`}
      onClick={() => {
        fireEvent(GACategories.CALENDAR_SYNC, tag, { venueId });
        setCalService(service);
      }}
      disabled={disabled}
    >
      {text}
    </Button>
  );

  return (
    <>
      <PageHeader title={title} />
      {fetching ? (
        <LoadingAnimation />
      ) : (
        <div className="IntegrateCalendarPage GlobalPad">
          {error ? (
            <p className="IntegrateCalendarPage_text_error">{error}</p>
          ) : (
            <>
              <div className="py-4">
                {calButton(
                  GATags.CALENDAR_APPLE,
                  'apple',
                  calService === 'apple',
                  i18n.t('integrate.calendar.apple.title'),
                )}
                {calButton(
                  GATags.CALENDAR_OUTLOOK,
                  'outlook',
                  calService === 'outlook',
                  i18n.t('integrate.calendar.outlook.title'),
                )}
                {calButton(
                  GATags.CALENDAR_GOOGLE,
                  'google',
                  calService === 'google',
                  i18n.t('integrate.calendar.google.title'),
                )}
              </div>
              {calService === 'google' && renderGoogleInstructions()}
              {calService === 'apple' && renderAppleInstructions()}
              {calService === 'outlook' && renderOutlookInstructions()}
            </>
          )}
        </div>
      )}
    </>
  );
};

IntegrateCalendarPage.propTypes = {
  title: PropTypes.string.isRequired,
  venue: venueProps.isRequired,
  generic: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  venue: getVenue(state),
});

const mapDispatchToProps = {
  generic: request,
};

export default connect(mapStateToProps, mapDispatchToProps)(IntegrateCalendarPage);

import React from 'react';

const HeartIcon = () => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.1118 8.11814C9.06472 7.69883 6.75002 8.92517 5.74957 10.9591C4.77585 12.9386 4.9459 15.0971 6.19023 16.5523L13.8748 25.5394L24.7126 16.5523C26.4675 15.0971 27.3949 12.9386 27.1157 10.9591C26.8289 8.92517 24.9444 7.69883 22.7502 8.11814L22.2283 8.21788C21.5707 8.34354 20.9334 8.65335 20.3982 9.10757L16.1206 12.7375L13.1167 9.10757C12.7408 8.65335 12.2123 8.34354 11.5988 8.21788L11.1118 8.11814ZM3.27112 10.1549C4.92146 6.79972 8.7398 4.77675 12.1167 5.46844L12.6036 5.56818C13.7346 5.79984 14.709 6.37095 15.4019 7.20826L16.7953 8.89201L18.7794 7.20826C19.7661 6.37095 20.9408 5.79984 22.1531 5.56818L22.675 5.46844C26.2946 4.77675 29.4031 6.79972 29.8763 10.1549C30.2917 13.1004 28.9119 16.3121 26.3006 18.4775L13.2093 29.3333L3.92682 18.4775C2.07527 16.3121 1.82225 13.1004 3.27112 10.1549Z"
      fill="black"
    />
  </svg>
);

export default HeartIcon;

import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { format } from 'date-fns';
import PropTypes from 'prop-types';
import i18n from '../../i18n';
import FinancialsForm from './FinancialsForm';
import ProfileHeader from '../ProfilePageV2/ProfileHeader';
import InfoIcon from '../../components/Icons/InfoIcon';
import ChevronIcon from '../../components/Icons/ChevronIcon';
import DownloadIcon from '../../components/Icons/DownloadIcon';
import ContentBlock from '../BillingPage/ContentBlock';
import { genericRequest } from '../../store/api/request';
import { getVenueID } from '../../store/venue/selectors';
import { getServicesEndpoint } from '../../utils/environment';
import { formatPrice, getURLQuery } from '../../utils/general';
import SpinnerButton from '../../components/SpinnerButton/SpinnerButton';

const limit = 5;

const Reimbursement = ({ venueId, req }) => {
  const [bills, setBills] = useState([]);
  const [offset, setOffset] = useState(0);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(false);
  const [expand, setExpand] = useState(false);

  useEffect(() => {
    setLoading(true);
    req(`venues/${venueId}/billing/reimbursements${getURLQuery({ limit, offset })}`)
      .then(r => {
        const newBills = r.getResult();
        if (offset === 0) {
          setBills(newBills);
        } else {
          setBills(prev => [...prev, ...newBills]);
        }
        setHasMore(newBills.length === limit);
      })
      .finally(() => setLoading(false));
  }, [req, venueId, offset]);

  return (
    <>
      <ProfileHeader text={i18n.t('titles_reimbursement')} />
      <div className="px-4 md:px-0 md:max-w-md md:mx-auto lg:max-w-lg mb-36 md:mb-32 pt-8 md:pt-12 lg:pt-16">
        <div className="bg-[#DBE6F5] flex p-4 text-[#2E6AC6] rounded-lg mb-4 items-start">
          <InfoIcon className="h-8" />
          <div className="flex-1 px-3 mt-1">
            <p className="font-bold text-xl opacity-90">
              {i18n.t('financials.about_reimbursements')}
            </p>
            <p
              className={`whitespace-pre-line mt-3 transition-all ${
                !expand ? 'max-h-12 truncate line-clamp-2' : 'max-h-screen duration-200'
              }`}
            >
              {i18n.t('financials.campaign_reimbursement')}
            </p>
          </div>
          <button type="button" onClick={() => setExpand(!expand)}>
            <ChevronIcon className={`${expand ? '-rotate-90' : 'rotate-90'} h-8`} />
          </button>
        </div>
        <FinancialsForm />
        <div className="pb-4" />
        <ContentBlock title="reimbursement.paidBills" showButton={false}>
          <p className="opacity-60 pb-2">{i18n.t('reimbursement.paidBills.subtitle')}</p>
          {bills.map(b => (
            <div key={b.id} className="flex justify-between pt-3 items-center">
              <p className="opacity-60">
                {format(new Date(b.paymentDate), 'do ')}
                <span className="uppercase">{format(new Date(b.paymentDate), 'MMM yyyy')}</span>
              </p>
              <p className="opacity-60">{formatPrice(b.amount)}</p>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={`${getServicesEndpoint()}venues/${venueId}/billing/reimbursements/download/${
                  b.id
                }`}
                download
              >
                <DownloadIcon className="h-6" />
              </a>
            </div>
          ))}
          {hasMore && (
            <SpinnerButton
              loading={loading}
              onClick={() => setOffset(offset + limit)}
              className="border rounded-lg border-mpGrey w-full py-3 mt-3 font-semibold"
            >
              {i18n.t('general.load_more')}
            </SpinnerButton>
          )}
        </ContentBlock>
      </div>
    </>
  );
};

Reimbursement.propTypes = {
  venueId: PropTypes.number.isRequired,
  req: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  venueId: getVenueID(state),
});

const mdtp = {
  req: genericRequest,
};

export default connect(mapStateToProps, mdtp)(Reimbursement);

import * as consts from './constants';

export function fetchVenuesPending() {
  return {
    type: consts.FETCH_VENUES_PENDING,
  };
}

export function fetchVenuesSuccess(result) {
  return {
    type: consts.FETCH_VENUES_SUCCESS,
    payload: result,
  };
}

export function fetchVenuesError() {
  return {
    type: consts.FETCH_VENUES_ERROR,
  };
}

export const discardChannelChanges = () => ({ type: consts.DISCARD_CHANNEL_UPDATES });

export function fetchPermissionsPending() {
  return {
    type: consts.FETCH_PERMISSIONS_PENDING,
  };
}

export function fetchPermissionsSuccess(result) {
  return {
    type: consts.FETCH_PERMISSIONS_SUCCESS,
    payload: result,
  };
}

export function fetchPermissionsError() {
  return {
    type: consts.FETCH_PERMISSIONS_ERROR,
  };
}

export function updateVenuePending() {
  return {
    type: consts.UPDATE_VENUE_PENDING,
  };
}

export function updateVenueSuccess(venue) {
  return {
    type: consts.UPDATE_VENUE_SUCCESS,
    payload: venue,
  };
}

export function updateVenueError(error) {
  return {
    payload: error,
    type: consts.UPDATE_VENUE_ERROR,
  };
}

export function fetchVenueChannelsPending() {
  return {
    type: consts.FETCH_ALL_CHANNELS_PENDING,
  };
}

export function fetchVenueChannelsSuccess(result) {
  return {
    type: consts.FETCH_ALL_CHANNELS_SUCCESS,
    payload: result,
  };
}

export function fetchVenueChannelsError() {
  return {
    type: consts.FETCH_ALL_CHANNELS_ERROR,
  };
}

export function updateSelectedChannels(channelId) {
  return {
    type: consts.UPDATE_SELECTED_CHANNELS,
    payload: channelId,
  };
}

export function updateVenueChannelsPending() {
  return {
    type: consts.UPDATE_VENUE_CHANNELS_PENDING,
  };
}

export function updateVenueChannelsSuccess(channels) {
  return {
    type: consts.UPDATE_VENUE_CHANNELS_SUCCESS,
    payload: channels,
  };
}

export function updateVenueChannelsError() {
  return {
    type: consts.UPDATE_VENUE_CHANNELS_ERROR,
  };
}

export function AuthModalStart() {
  return {
    type: consts.AUTHMODAL_CONNECT_START,
  };
}

export function AuthModalSuccess() {
  return {
    type: consts.AUTHMODAL_CONNECT_SUCCESS,
  };
}

export function AuthModalError() {
  return {
    type: consts.AUTHMODAL_CONNECT_ERROR,
  };
}

export function facebookDisconnectSuccess() {
  return {
    type: consts.FACEBOOK_DISCONNECT_SUCCESS,
  };
}

export function facebookDisconnectStart() {
  return {
    type: consts.FACEBOOK_DISCONNECT_START,
  };
}

export function facebookDisconnectError() {
  return {
    type: consts.FACEBOOK_DISCONNECT_ERROR,
  };
}

export function clearVenueFieldErrors() {
  return {
    type: consts.CLEAR_VENUE_FIELD_ERRORS,
  };
}

export function startTwitterLogin() {
  return {
    type: consts.START_TWITTER_LOGIN,
  };
}

export function stopTwitterLogin() {
  return {
    type: consts.STOP_TWITTER_LOGIN,
  };
}

export function setTwitterAuthURL(url) {
  return {
    type: consts.SET_TWITTER_LOGIN_URL,
    payload: url,
  };
}

export const startFinaliseResub = () => ({ type: consts.START_FINALISE_RESUB });

export const successFinaliseResub = () => ({ type: consts.SUCCESS_FINALISE_RESUB });

export const errorFinaliseResub = payload => ({ type: consts.ERROR_FINALISE_RESUB, payload });

export const updateVenueLeague = payload => ({ type: consts.UPDATE_VENUELEAGUE, payload });

import React from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18next';
import OpeningHoursSelector from '../../../../components/OpeningHours/OpeningHours';

const OpeningHours = ({ data, onChange }) => (
  <>
    <h1 className="headingFont text-4xl md:text-5xl lg:text-6xl pb-8 mr-32 md:mr-0">
      {t('signup_venue_openingHoursTitle')}
    </h1>
    <OpeningHoursSelector openingHours={data} onChange={openingHours => onChange(openingHours)} />
  </>
);

OpeningHours.propTypes = {
  data: PropTypes.shape().isRequired,
  onChange: PropTypes.func.isRequired,
};

export default OpeningHours;

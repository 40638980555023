const perm = {
  BESPOKE_OFFER: 'bespoke_offer',
  BESPOKE_WIDGET: 'bespoke_widget',
  BESPOKE_POSTS: 'bespoke_posts',
  SOCIAL_MEDIA_POST: 'social_media_post',
  GREAT_CONTENT_POST: 'great_content_post', // TODO: to be replaced by BESPOKE_POSTS
  FIXTURES: 'fixtures',
};

export default perm;

import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';

const YoutubeEmbed = ({ video }) => {
  // video should be the youtube ID only
  if (video?.includes('youtube.com') || video?.includes('http')) {
    return null;
  }
  return (
    <div className="flex justify-center my-5 px-8 md:px-36 lg:px-auto">
      <div className="videoContainer">
        <iframe
          src={`https://www.youtube.com/embed/${video}`}
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          title="Embedded youtube"
        />
      </div>
    </div>
  );
};

YoutubeEmbed.propTypes = {
  video: PropTypes.string.isRequired,
};

export default YoutubeEmbed;

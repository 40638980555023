import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import i18n, { getLocaleDate } from '../../i18n';
import { fixtureProps } from '../../utils/customPropTypes';
import InfiniteScroll from '../../components/InfiniteScroll';
import HeadingRow from './HeadingRow';
import FixtureRow from './FixtureRow';
import Spinner from '../../components/Spinner/Spinner';
import { fireEvent, GACategories, GATags } from '../../utils/trackingHelper';

const container = 'pt-2 lg:pt-6 mb-10';
const dateClasses = 'sticky bg-offWhite z-10';

const FixturesList = ({
  fixtures,
  fetching,
  hasMore,
  fetchNext,
  loadingPage,
  noResultsText,
  updateFixture,
  bottomBannerDisplayed,
  dateTopHeight,
  hideNoResults,
}) => {
  const dFormat = date => getLocaleDate(date, 'EEEE do LLLL');

  const firstDate = dFormat(fixtures?.[0]?.startTimeUtc);
  let newestDate = dFormat(fixtures?.[0]?.startTimeUtc);

  const getRow = fixture => (
    <FixtureRow key={fixture.id} fixture={fixture} updateFixture={updateFixture} />
  );

  useEffect(() => {
    if (!fetching && fixtures?.length === 0) {
      fireEvent(GACategories.FIXTURES, GATags.NO_FIXTURES);
    }
  }, [fetching, fixtures]);

  return !fetching ? (
    <InfiniteScroll hasMore={hasMore} fetchMore={fetchNext} loading={loadingPage}>
      {fixtures?.length > 0 && (
        <div className={`${container} ${bottomBannerDisplayed ? 'mb-32 md:mb-40 lg:mb-52' : ''}`}>
          <div className={dateClasses} style={{ top: dateTopHeight }}>
            <HeadingRow date={firstDate} />
          </div>
          {fixtures.map(fixture => {
            if (dFormat(fixture.startTimeUtc) === newestDate) {
              return getRow(fixture);
            }
            newestDate = dFormat(fixture.startTimeUtc);
            return (
              <Fragment key={fixture.id}>
                <div className={dateClasses} style={{ top: dateTopHeight }}>
                  <HeadingRow date={newestDate} />
                </div>
                {getRow(fixture)}
              </Fragment>
            );
          })}
        </div>
      )}
      {fixtures?.length === 0 && !hideNoResults && (
        <div className="p-4 md:p-6 md:pt-8 lg:p-10">
          <p className="font-bold text-xl">{noResultsText || i18n.t('fixtures_noFixtures')}</p>
          {!noResultsText && <p className="mt-2">{i18n.t('fixtures_noFixtures_text')}</p>}
        </div>
      )}
    </InfiniteScroll>
  ) : (
    <div className="h-full">
      <Spinner className="w-8" />
    </div>
  );
};

FixturesList.propTypes = {
  fixtures: PropTypes.arrayOf(fixtureProps).isRequired,
  fetching: PropTypes.bool.isRequired,
  hasMore: PropTypes.bool.isRequired,
  fetchNext: PropTypes.func.isRequired,
  loadingPage: PropTypes.bool.isRequired,
  noResultsText: PropTypes.string,
  updateFixture: PropTypes.func.isRequired,
  bottomBannerDisplayed: PropTypes.bool,
  dateTopHeight: PropTypes.string.isRequired,
  hideNoResults: PropTypes.bool,
};

FixturesList.defaultProps = {
  noResultsText: undefined,
  bottomBannerDisplayed: false,
  hideNoResults: false,
};

export default FixturesList;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './styles.scss';
import DivHoverEffect from '../../components/DivHoverEffect/DivHoverEffect';

const StatsBox = ({ stat }) => {
  const [showBack, setShowBack] = useState(false);
  return (
    <DivHoverEffect
      className={showBack ? 'Stats_stat_dark Stats_dark' : 'Stats_stat'}
      onHover={() => setShowBack(!showBack)}
    >
      {!showBack ? (
        <>
          <p className="Stats_stat_value">{stat?.value}</p>
          <p className="Stats_stat_text">{stat?.label}</p>
        </>
      ) : (
        <p className="Stats_stat_text">{stat?.description}</p>
      )}
    </DivHoverEffect>
  );
};

StatsBox.propTypes = {
  stat: PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired,
    description: PropTypes.string.isRequired,
  }).isRequired,
};

export default StatsBox;

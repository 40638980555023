import * as actions from './actions';
import { getListFixtures } from '../api/fixtures';
import { getOffset, getLimit } from './selectors';

export function fetchListFixtures(venueId) {
  return function _fetchListFixtures(dispatch, getState) {
    dispatch(actions.fetchListFixturesPending());
    return dispatch(getListFixtures(venueId, 0, getLimit(getState())))
      .then(res => dispatch(actions.fetchListFixturesSuccess(res)))
      .catch(() => dispatch(actions.fetchListFixturesError()));
  };
}

export function fetchNextListFixtures(venueId) {
  return function _fetchNextListFixtures(dispatch, getState) {
    dispatch(actions.fetchNextListFixturesPending());
    return dispatch(getListFixtures(venueId, getOffset(getState()), getLimit(getState())))
      .then(res => dispatch(actions.fetchNextListFixturesSuccess(res)))
      .catch(() => dispatch(actions.fetchNextListFixturesError()));
  };
}

import React from 'react';
import PropTypes from 'prop-types';

const TickIcon = ({ className, thick }) => (
  <svg className={`fill-current ${className}`} viewBox="0 0 24 24">
    {thick ? (
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.7643 7.28336L9.18998 21.8231C9.0697 21.9885 8.81694 21.9676 8.72548 21.7847L4.04834 12.4304L7.95129 10.4789L9.61898 13.8142L16.2353 4.7168L19.7643 7.28336Z"
      />
    ) : (
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.8823 6.64164L9.36338 19.7301C9.2431 19.8955 8.99033 19.8746 8.89888 19.6916L5.02426 11.9424L6.97574 10.9667L9.44594 15.9071L17.1177 5.35835L18.8823 6.64164Z"
      />
    )}
  </svg>
);

TickIcon.propTypes = {
  className: PropTypes.string,
  thick: PropTypes.bool,
};

TickIcon.defaultProps = {
  className: '',
  thick: false,
};

export default TickIcon;

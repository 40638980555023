export const FETCH_VENUES_PENDING = 'fetch/venues/pending';
export const FETCH_VENUES_SUCCESS = 'fetch/venues/success';
export const FETCH_VENUES_ERROR = 'fetch/venues/error';

export const FETCH_PERMISSIONS_PENDING = 'fetch/permissions/pending';
export const FETCH_PERMISSIONS_SUCCESS = 'fetch/permissions/success';
export const FETCH_PERMISSIONS_ERROR = 'fetch/permissions/error';

export const UPDATE_VENUE_PENDING = 'update/venue/pending';
export const UPDATE_VENUE_SUCCESS = 'update/venue/success';
export const UPDATE_VENUE_ERROR = 'update/venue/error';

export const FETCH_VENUE_SELECTED_CHANNELS_PENDING = 'fetch/venueChannels/selected/pending';
export const FETCH_VENUE_SELECTED_CHANNELS_SUCCESS = 'fetch/venueChannels/selected/success';
export const FETCH_VENUE_SELECTED_CHANNELS_ERROR = 'fetch/venueChannels/selected/error';

export const FETCH_ALL_CHANNELS_PENDING = 'fetch/venueChannels/all/pending';
export const FETCH_ALL_CHANNELS_SUCCESS = 'fetch/venueChannels/all/success';
export const FETCH_ALL_CHANNELS_ERROR = 'fetch/venueChannels/all/error';

export const UPDATE_SELECTED_CHANNELS = 'venueChannels/selected/update';
export const DISCARD_CHANNEL_UPDATES = 'venueChannels/selected/discard';

export const UPDATE_VENUE_CHANNELS_PENDING = 'update/venueChannels/pending';
export const UPDATE_VENUE_CHANNELS_SUCCESS = 'update/venueChannels/success';
export const UPDATE_VENUE_CHANNELS_ERROR = 'update/venueChannels/error';

export const AUTHMODAL_CONNECT_START = 'venue/authModal/start';
export const AUTHMODAL_CONNECT_SUCCESS = 'venue/authModal/success';
export const AUTHMODAL_CONNECT_ERROR = 'venue/authModal/error';

export const FACEBOOK_DISCONNECT_START = 'venue/facebookDisconnect/start';
export const FACEBOOK_DISCONNECT_SUCCESS = 'venue/facebookDisconnect/success';
export const FACEBOOK_DISCONNECT_ERROR = 'venue/facebookDisconnect/error';

export const CLEAR_VENUE_FIELD_ERRORS = 'venue/clear/fieldErrors';

export const START_TWITTER_LOGIN = 'venue/start/twitterLogin';
export const STOP_TWITTER_LOGIN = 'venue/stop/twitterLogin';
export const SET_TWITTER_LOGIN_URL = 'venue/set/twitterLoginURL';

export const START_FINALISE_RESUB = 'venue/start/finaliseResub';
export const SUCCESS_FINALISE_RESUB = 'venue/success/finaliseResub';
export const ERROR_FINALISE_RESUB = 'venue/error/finaliseResub';

export const UPDATE_VENUELEAGUE = 'update/venueLeague';

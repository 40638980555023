import React from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18next';
import PlainButton from '../../components/PlainButton/PlainButton';

const ContentBlock = ({ title, onClick, buttonText, children, showButton }) => (
  <div className="border border-mpGrey rounded-2xl p-8 bg-white">
    <div className="flex justify-between pb-2">
      <p className="text-xl font-bold">{t(title)}</p>
      {showButton && (
        <PlainButton
          onClick={onClick}
          className="rounded-full py-2.5 px-4 bg-mpGrey text-xs font-semibold capitalize"
        >
          {t(buttonText)}
        </PlainButton>
      )}
    </div>
    {children}
  </div>
);

ContentBlock.propTypes = {
  title: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  buttonText: PropTypes.string,
  showButton: PropTypes.bool,
  children: PropTypes.node,
};

ContentBlock.defaultProps = {
  children: null,
  showButton: true,
  onClick: null,
  buttonText: null,
};

export default ContentBlock;

import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';

const PageHeader = ({ title }) => (
  <div className="PageHeader GlobalPad">
    <h1 className="headingFont text-4xl">{title}</h1>
  </div>
);

PageHeader.propTypes = {
  title: PropTypes.string,
};

PageHeader.defaultProps = {
  title: '',
};

export default PageHeader;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18next';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { venueProps } from '../../utils/customPropTypes';
import { getVenue } from '../../store/venue/selectors';
import FacebookButton from '../../components/SocialMediaButtons/SocialMediaButtons';
import { userIsOnOnTap } from '../../utils/environment';
import InstagramButton from '../../components/SocialMediaButtons/InstagramButton';
import { saveVenue as saveVenueThunk } from '../../store/api/venues';
import SaveBar from '../ProfilePageV2/SaveBar/SaveBar';
import { updateVenueSuccess } from '../../store/venue/actions';

const ConnectSocialMedia = ({ venue, saveVenueChanges, refreshVenue }) => {
  const currentInstagram = venue.socialMedia?.instagram?.pageName || '';
  const [instagram, setInstagram] = useState(currentInstagram);
  const [fieldErrors, setFieldErrors] = useState(null);
  const [savingVenue, setSavingVenue] = useState(false);

  if (userIsOnOnTap) {
    return null;
  }
  return (
    <>
      <h3 className="font-bold text-xl pb-1 pt-8 mt-8 border-mpGrey border-t">
        {t('settings_socialTitle')}
      </h3>
      <p className="opacity-60 pb-4">{t('editProfile_connectSocialsSubtitle')}</p>
      <InstagramButton
        value={instagram}
        onChange={e => setInstagram(e.target.value)}
        errorMessage={fieldErrors?.socialMedia?.instagram}
      />
      <p className="font-bold pb-2 pt-4 text-sm">{t('general_facebook')}</p>
      <FacebookButton />

      {currentInstagram !== instagram && (
        <SaveBar
          saveCopy={t('editProfile_saveKeyInfo')}
          loading={savingVenue}
          onSave={() => {
            setSavingVenue(true);
            return saveVenueChanges({
              id: venue?.id,
              socialMedia: { ...venue?.socialMedia, instagram },
            })
              .then(res => {
                refreshVenue(res.getResult());
                setFieldErrors(null);
                toast.success(t('editProfile_keyInfoSaved'));
              })
              .catch(error => {
                if (error.code === 202) {
                  setFieldErrors(error.error);
                }
              })
              .finally(() => setSavingVenue(false));
          }}
          onDiscard={() => {
            setFieldErrors(null);
            setInstagram(currentInstagram);
          }}
        />
      )}
    </>
  );
};

ConnectSocialMedia.propTypes = {
  venue: venueProps.isRequired,
  saveVenueChanges: PropTypes.func.isRequired,
  refreshVenue: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  venue: getVenue(state),
});

const mapDispatchToProps = {
  saveVenueChanges: saveVenueThunk,
  refreshVenue: updateVenueSuccess,
};

export default connect(mapStateToProps, mapDispatchToProps)(ConnectSocialMedia);

import { getURLQuery } from '../../utils/general';
import { request } from './request';

export const getThemes = venueID => dispatch =>
  dispatch(request(`/meta/${venueID}/social-media/themes/list?limit=999`));

export function getContent(venueID, limit, offset) {
  return async function _getContent(dispatch) {
    return dispatch(
      request(
        `meta/${venueID}/great-content?limit=${limit}&numberOfMatches=1&offset=${offset}&sortColumn=id&sortOrder=ASC`,
      ),
    );
  };
}

export const fetchAssetsAPI = (venueID, gameId) => dispatch =>
  dispatch(request(`/games/${gameId}/marketing-assets/list?venueId=${venueID}`));

export function getScheduledPosts(venueID, offset, limit) {
  return async function _getScheduledPosts(dispatch) {
    return dispatch(
      request(
        `meta/${venueID}/social-media/post?scheduledOnly=true&limit=${limit}&offset=${offset}&numberOfMatches=1`,
      ),
    );
  };
}
export function getPostedPosts(venueID, offset, limit) {
  return async function _getPostedPosts(dispatch) {
    return dispatch(
      request(
        `meta/${venueID}/social-media/post?scheduledOnly=false&limit=${limit}&offset=${offset}&numberOfMatches=1`,
      ),
    );
  };
}

export function getFacebookPages(venueID) {
  return async function _getFacebookPages(dispatch) {
    return dispatch(request(`meta/${venueID}/social-media/facebook/pages`));
  };
}

export function getAccountDetails(venueID) {
  return async function _getAccountDetails(dispatch) {
    return dispatch(request(`venues/${venueID}/social-media/accounts`));
  };
}

export function deletePost(venueId, postId) {
  return async function _deletePost(dispatch) {
    return dispatch(
      request(`meta/${venueId}/social-media/post/${postId}/delete`, {
        method: 'DELETE',
      }),
    );
  };
}

export function createPostPublish(venueId, fixturePost) {
  return async function _createPostPublish(dispatch) {
    return dispatch(
      request(`meta/${venueId}/social-media/post/add`, {
        body: fixturePost,
        method: 'POST',
      }),
    );
  };
}

export function updatePostPublish(venueId, fixturePost) {
  return async function _updatePostPublish(dispatch) {
    return dispatch(
      request(`meta/${venueId}/social-media/post/${fixturePost.id}/update`, {
        body: fixturePost,
        method: 'PUT',
      }),
    );
  };
}

export function selectFacebookPage(venueId, page) {
  return async function _selectFacebookPage(dispatch) {
    return dispatch(
      request(`meta/${venueId}/social-media/facebook/select-page`, {
        body: { page },
        method: 'PUT',
      }),
    );
  };
}

export const getEditableCountryCompetitions = venueID => dispatch =>
  dispatch(request(`meta/${venueID}/social-media/themes/competitions/list`));

export const getThemeData = (venueId, type) => dispatch =>
  dispatch(request(`meta/${venueId}/social-media/themes/list${getURLQuery({ type })}`));

export const getFonts = venueId => dispatch =>
  dispatch(request(`meta/${venueId}/social-media/themes/fonts`));

export const getBespokePreviews = (
  venueId,
  font,
  color,
  language,
  themeId,
  secondaryTextFont,
  secondaryTextColor,
) => dispatch =>
  dispatch(
    request(
      `meta/${venueId}/social-media/themes/fixtures-preview-layer${getURLQuery({
        font,
        color,
        language,
        themeId,
        secondaryTextFont,
        secondaryTextColor,
      })}`,
    ),
  );

export const getThemeConfig = venueId => dispatch =>
  dispatch(request(`meta/${venueId}/social-media/themes/colors/config`));

export const getCompetitionThemeConfig = (venueId, competitionId, type) => dispatch =>
  dispatch(
    request(
      `meta/${venueId}/social-media/themes/competitions/${competitionId}/config${getURLQuery({
        type,
      })}`,
    ),
  );

export const saveThemeConfig = (venueId, customConfig) => dispatch =>
  dispatch(
    request(`meta/${venueId}/social-media/themes/colors/config/save`, {
      method: 'PUT',
      body: {
        customConfig,
      },
    }),
  );

export const saveCompetitionCustomConfig = (venueId, competitionId, customConfig) => dispatch =>
  dispatch(
    request(
      `meta/${venueId}/social-media/themes/competitions/${competitionId}/config/save?type=${customConfig.type}`,
      {
        method: 'PUT',
        body: {
          customConfig: JSON.stringify(customConfig),
        },
      },
    ),
  );

import React from 'react';
import PropTypes from 'prop-types';

const RefreshIcon = ({ className }) => (
  <svg className={`fill-current stroke-current ${className}`} viewBox="0 0 24 24">
    <path
      d="M1.85073 11.649L1.83591 11.7982L1.98518 11.8131L3.30315 11.9439L3.45242 11.9587L3.46724 11.8095L3.53267 11.1505C3.95836 6.86295 7.57706 3.51376 11.977 3.51376C15.1585 3.51376 17.9325 5.26454 19.3857 7.85786L17.6436 7.2757L17.4924 7.22518L17.4512 7.37915L17.2617 8.08636L17.2247 8.2243L17.3607 8.26799L21.0191 9.4437L21.1743 9.49358L21.2112 9.33478L22.0913 5.5387L22.124 5.39746L21.984 5.35993L21.2768 5.17044L21.1257 5.12995L21.0916 5.2826L20.7241 6.92597C18.974 3.91537 15.7129 1.88931 11.977 1.88931C6.73374 1.88931 2.42352 5.87983 1.91616 10.99L1.85073 11.649ZM22.1493 12.3509L22.1641 12.2016L22.0148 12.1868L20.6968 12.056L20.5476 12.0412L20.5328 12.1904L20.4673 12.8494C20.0417 17.1369 16.4229 20.4861 12.023 20.4861C8.85453 20.4861 6.09021 18.7497 4.63225 16.1739L6.38902 16.7609L6.5402 16.8115L6.58145 16.6575L6.77094 15.9503L6.8079 15.8124L6.67195 15.7687L3.01368 14.593L2.85848 14.5432L2.82167 14.702L1.94159 18.4978L1.90884 18.6391L2.04889 18.6766L2.75606 18.8661L2.90714 18.9066L2.94127 18.7539L3.30557 17.1246C5.0627 20.1072 8.30817 22.1106 12.023 22.1106C17.2662 22.1106 21.5765 18.12 22.0838 13.0099L22.1493 12.3509Z"
      strokeWidth="0.3"
    />
  </svg>
);

RefreshIcon.propTypes = {
  className: PropTypes.string,
};
RefreshIcon.defaultProps = {
  className: '',
};

export default RefreshIcon;

import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import i18n from 'i18next';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useAlgoliaSearch, useDetectOutsideClick } from '../../utils/CustomHooks';
import { fireEvent, GATags } from '../../utils/trackingHelper';
import Spinner from '../Spinner/Spinner';
import Button from '../Button/Button';
import { venueProps } from '../../utils/customPropTypes';
import { getVenue } from '../../store/venue/selectors';
import PlainButton from '../PlainButton/PlainButton';
import CloseIcon from '../Icons/CloseIcon';
import SearchIcon from '../Icons/SearchIcon';
import { genericRequest } from '../../store/api/request';
import { refreshVenueThunk } from '../../store/venue/thunks';

const convertAlgoliaEntity = e => ({
  id: e.entity_id,
  type: e.category.toLowerCase(),
  logo: e.logo,
  name: e.name,
});

const AddFavourites = ({ venue: { id }, generic, onChange, refreshVenue }) => {
  const { pathname } = useLocation();
  const [favs, setFavs] = useState([]);
  const [value, setValue] = useState('');

  const [loading, search, categories, clearResults, hits, , sendEvent] = useAlgoliaSearch(
    'favTeams',
    id,
  );
  const [isFocused, setIsFocused] = useState(false);
  const ref = useRef();
  useDetectOutsideClick(ref, () => setIsFocused(false));

  useEffect(() => {
    // limit 9999 to ensure we get all favourtes in 1 hit
    generic(`sports/${id}/favourites`, { limit: 9999 }).then(r =>
      setFavs(r.getResult().filter(f => f.type === 'team' || f.type === 'competition')),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSearch = val => {
    setValue(val);
    search(val, [categories.TEAM, categories.COMPETITION], 10);
  };

  const onClear = () => {
    clearResults();
    setValue('');
  };

  // filter results to show only teams that are not already added
  const filteredHits = hits?.filter(
    entity =>
      !favs.find(f => `${f.id}` === entity.entity_id && f.type === entity?.category?.toLowerCase()),
  );

  const showResults = Boolean(filteredHits?.length > 0 && value && isFocused);

  const track = (ent, add) =>
    fireEvent(GATags.FAVOURITE_UPDATED, null, {
      venueId: id,
      page: pathname,
      type: ent.type,
      name: ent.name,
      add,
    });

  return (
    <>
      <div className="w-full my-2 relative" ref={ref}>
        <div
          className={`flex rounded-3xl p-2.5 pl-4 border-2 bg-white ${
            showResults ? 'border-b-transparent rounded-b-none' : ''
          } ${isFocused ? 'border-quaternary' : 'border-mpGrey'}`}
        >
          <input
            className="flex-1 outline-none"
            value={value}
            placeholder={i18n.t('favourites.search.teamsComps')}
            onKeyUp={e => {
              if (e.key === 'Enter') {
                onSearch(e.target.value);
              }
            }}
            onChange={e => onSearch(e.target.value)}
            onFocus={() => setIsFocused(true)}
            data-test-id="searchFavourites"
          />
          {loading && <Spinner />}
          {value?.length > 0 ? (
            <PlainButton className="ml-3" onClick={onClear}>
              <CloseIcon className="w-6 h-6 bg-mpGrey rounded-full" />
            </PlainButton>
          ) : (
            <SearchIcon className="w-6 h-6 ml-3" />
          )}
        </div>
        {showResults && (
          <div className="-mt-1 pt-2 absolute rounded-b-3xl border-2 border-t-0 border-quaternary bg-white right-0 left-0 z-10 overflow-hidden">
            {filteredHits.map((entity, i) => (
              <Button
                capitalize={false}
                className="p-0 w-full"
                key={entity.entity_id}
                onClick={() => {
                  const ent = convertAlgoliaEntity(entity);
                  track(ent, true);
                  sendEvent(entity?.objectID, i);
                  setIsFocused(false);
                  onClear();
                  // algolia returns team_id and as a string
                  // we need an id key to be able to save teams
                  generic(`sports/${id}/favourites`, undefined, 'POST', {
                    favourites: [ent],
                  }).then(() => {
                    refreshVenue(id);
                    setFavs([...favs, ent]);
                    onChange();
                    toast.success(i18n.t('editProfile_favouritesToastAdd', { value: entity.name }));
                  });
                }}
                data-test-id="favouriteResult"
              >
                <div className="p-3 hover:bg-lightGrey flex text-left">
                  <img className="h-5 w-5 mx-2" src={entity.logo} alt="team logo" />
                  <p>
                    {entity.name} ({entity.sport_name})
                  </p>
                </div>
              </Button>
            ))}
          </div>
        )}
      </div>
      <div className="flex gap-2 flex-wrap mt-4">
        {favs.map(t => (
          <div
            key={t.id}
            className="flex items-center rounded-full border-2 border-quaternary bg-secondaryLight py-1 px-2 md:py-2 md:px-4"
          >
            <img alt={t.name} className="h-6 w-6" src={t.competitionLogo || t.logo} />
            <p className="text-xs font-semibold px-2">{t.name}</p>
            <PlainButton
              onClick={() => {
                track(t, false);
                generic(`sports/${id}/favourites/remove`, undefined, 'DELETE', {
                  id: t.id,
                  type: t.type,
                }).then(() => {
                  refreshVenue(id);
                  setFavs(favs.filter(f => !(f.id === t.id && t.type === f.type)));
                  onChange();
                  toast.success(i18n.t('editProfile_favouritesToastRemove', { value: t.name }));
                });
              }}
            >
              <CloseIcon className="w-6 h-6" />
            </PlainButton>
          </div>
        ))}
      </div>
    </>
  );
};

AddFavourites.propTypes = {
  venue: venueProps.isRequired,
  generic: PropTypes.func.isRequired,
  onChange: PropTypes.func,
  refreshVenue: PropTypes.func.isRequired,
};

AddFavourites.defaultProps = {
  onChange: () => {},
};

const mapStateToProps = state => ({
  venue: getVenue(state),
});

const mapDispatchToProps = {
  generic: genericRequest,
  refreshVenue: refreshVenueThunk,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddFavourites);

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18next';
import PasswordInput from '../../components/PasswordInput/PasswordInput';
import Checkbox from '../../components/Checkbox/Checkbox';
import PasswordValidation from '../../components/PasswordInput/PasswordValidation';
import { validatePassword } from '../../utils/FormValidation';

const PasswordSection = ({ state, setState, onKeyPress }) => {
  const [passwordIsFocused, setPasswordIsFocused] = useState(false);
  const passwordValidation = validatePassword(state.password);
  const passwordIsValid = !Object.values(passwordValidation).some(condition => !condition);

  const getBorderColor = (focused, validPass) => {
    if (focused) {
      if (validPass) {
        return 'border-2 border-green';
      }
      return 'border-2 border-lightRed';
    }
    return 'border border-mpGrey';
  };
  return (
    <>
      <PasswordInput
        id="password"
        required
        type="string"
        value={state.password}
        onChange={val => setState({ ...state, password: val })}
        onFocus={() => setPasswordIsFocused(true)}
        label={t('auth.password')}
        onKeyPress={onKeyPress}
        className={`my-0 rounded-lg py-3 px-4 mb-4 ${getBorderColor(
          passwordIsFocused,
          passwordIsValid,
        )}`}
        placeholder={t('auth_passwordPlaceholder')}
        dataTestId="password"
      />
      {passwordIsFocused && (
        <div className="pb-2">
          <PasswordValidation
            text={t('password_validation1')}
            passed={passwordValidation.has8Chars}
          />
          <PasswordValidation
            text={t('password_validation2')}
            passed={passwordValidation.hasANumber}
          />
          <PasswordValidation
            text={t('password_validation3')}
            passed={passwordValidation.hasALetter}
          />
        </div>
      )}
      <Checkbox
        labelStyle="opacity-60 text-base"
        checked={Boolean(state.acceptedMarketing)}
        onChange={() => setState({ ...state, acceptedMarketing: !state.acceptedMarketing })}
        label={t('auth.signup.form.notify_me')}
      />
    </>
  );
};

PasswordSection.propTypes = {
  state: PropTypes.shape().isRequired,
  setState: PropTypes.func.isRequired,
  onKeyPress: PropTypes.func.isRequired,
};

export default PasswordSection;

import PropTypes from 'prop-types';
import React from 'react';
import Spinner from '../Spinner/Spinner';

const SpinnerButton = ({ id, className, children, loading, onClick, disabled }) => (
  <button
    type="button"
    id={id}
    data-test-id="spinnerButton"
    className={`outline-none focus:outline-none flex items-center justify-center ${className}`}
    onClick={onClick}
    disabled={disabled || loading}
  >
    <div className={loading ? 'opacity-0' : ''}>{children}</div>
    <div className={`absolute ${loading ? '' : 'invisible'}`}>
      <Spinner className="w-7" />
    </div>
  </button>
);

SpinnerButton.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  loading: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};
SpinnerButton.defaultProps = {
  id: null,
  className: '',
  loading: false,
  disabled: false,
};

export default SpinnerButton;

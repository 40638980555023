export const getFinancialInitialValues = state => state.financials.initialValues;

export const getFinancialUpdatedValues = state => state.financials.updatedValues;

export const getFinancialsFormDesc = state => state.financials.formDescription;

export const getFinancialsErrors = state => state.financials.errors;

export const getIsFetchingFinancials = state => state.financials.isFetching;

export const getIsUpdatingFinancialValues = state => state.financials.isUpdatingValues;

import React from 'react';
import PropTypes from 'prop-types';
import SpinnerButton from '../SpinnerButton/SpinnerButton';
import PlainButton from '../PlainButton/PlainButton';
import styles from './styles.module.scss';

const FloatingBanner = ({
  text,
  button1Text,
  button1Action,
  button2Text,
  button2Action,
  button1Loading,
  button1Disabled,
}) => (
  <div className={styles.banner}>
    <p className="flex-1 sm:text-xl pb-4 sm:pb-0">{text}</p>
    <div className="flex flex-1 gap-4 sm:gap-6">
      <PlainButton
        className="bg-mpGrey font-semibold flex-1 rounded-lg py-2 md:py-3 sm:text-xl"
        onClick={button2Action}
      >
        {button2Text}
      </PlainButton>
      <SpinnerButton
        className="bg-secondary font-semibold flex-1 rounded-lg py-2 md:py-3 sm:text-xl"
        loading={button1Loading}
        disabled={button1Disabled}
        onClick={button1Action}
      >
        {button1Text}
      </SpinnerButton>
    </div>
  </div>
);

FloatingBanner.propTypes = {
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  button1Text: PropTypes.string.isRequired,
  button1Action: PropTypes.func.isRequired,
  button2Text: PropTypes.string.isRequired,
  button2Action: PropTypes.func.isRequired,
  button1Loading: PropTypes.bool,
  button1Disabled: PropTypes.bool,
};
FloatingBanner.defaultProps = {
  text: '',
  button1Disabled: false,
  button1Loading: false,
};

export default FloatingBanner;

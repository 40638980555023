import React from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { publicRoutes } from '../../routes/paths';
import './styles.scss';

const UnprotectedLink = ({ path, text, blackBackground }) => {
  const linkClass = !blackBackground
    ? `Links_text${path === publicRoutes.login ? '_highlighted' : ''}`
    : `Links_text_white${path === publicRoutes.login ? '_highlighted' : ''}`;
  return (
    <NavLink
      activeClassName="Links_active"
      exact={path === publicRoutes.login}
      className="Links"
      to={path}
    >
      <p className={linkClass}>{text.toUpperCase()}</p>
    </NavLink>
  );
};
UnprotectedLink.propTypes = {
  path: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  blackBackground: PropTypes.bool,
};

UnprotectedLink.defaultProps = {
  blackBackground: false,
};

export default UnprotectedLink;

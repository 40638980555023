import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import i18n from '../../../i18n';
import { getIsMobile } from '../../../store/app/selectors';
import Checkbox from '../../../components/Checkbox/Checkbox';
import { fireEvent, GACategories, GATags } from '../../../utils/trackingHelper';

const TipsRow = ({ fixture, isLoading, onClick, isMobile }) => {
  const { homeTeam, awayTeam, winnerId, canPredict } = fixture;
  const getShortName = team => (team?.shortName || team?.name?.substring(0, 3)).toUpperCase();
  return (
    <div className={`flex flex-row items-center my-2 rounded ${canPredict ? '' : 'opacity-50'}`}>
      <TeamButton
        teamName={isMobile ? getShortName(homeTeam) : homeTeam?.name}
        teamCrest={homeTeam?.logo}
        isWinner={winnerId && homeTeam?.id === winnerId}
        onClick={() => onClick(fixture, homeTeam?.id)}
        disabled={isLoading || !canPredict}
      />
      <span className="uppercase px-2 sm:px-5 lg:px-1 xl:px-5 opacity-60" style={{ fontSize: 10 }}>
        {i18n.t('general.or')}
      </span>
      <TeamButton
        teamName={isMobile ? getShortName(awayTeam) : awayTeam?.name}
        teamCrest={awayTeam?.logo}
        isWinner={winnerId && awayTeam?.id === winnerId}
        onClick={() => onClick(fixture, awayTeam?.id)}
        disabled={isLoading || !canPredict}
      />
    </div>
  );
};

TipsRow.propTypes = {
  fixture: PropTypes.shape().isRequired,
  onClick: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  isMobile: PropTypes.bool,
};

TipsRow.defaultProps = {
  isLoading: false,
  isMobile: false,
};

const mapStateToProps = state => ({
  isMobile: getIsMobile(state),
});

export default connect(mapStateToProps)(TipsRow);

const TeamButton = ({ isWinner, teamName, teamCrest, onClick, disabled }) => {
  const borderColor = isWinner ? 'border-primary' : 'border-transparent';
  const buttonStyle =
    'flex flex-row flex-1 items-center p-2 sm:px-5 lg:px-2 xl:px-5 rounded-md overflow-hidden border';
  return (
    <button
      className={`${buttonStyle} ${borderColor} ${isWinner ? 'bg-secondaryLight' : ''}`}
      onClick={() => {
        fireEvent(GACategories.FOOTY_TIPPING, GATags.SET_SCORES);
        onClick();
      }}
      type="button"
      disabled={disabled}
    >
      <Checkbox className="pr-0" checked={isWinner} />
      <img className="w-7 h-7 mx-3 lg:mx-2 xl:mx-3" src={teamCrest} alt="" />
      <span className="font-semibold text-xs text-left truncate">{teamName}</span>
    </button>
  );
};

TeamButton.propTypes = {
  teamName: PropTypes.string.isRequired,
  teamCrest: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  isWinner: PropTypes.bool,
  disabled: PropTypes.bool,
};

TeamButton.defaultProps = {
  isWinner: false,
  disabled: false,
};

import React from 'react';
import { connect } from 'react-redux';
import { t } from 'i18next';
import { Link } from 'react-router-dom';
import { getVenue } from '../../store/venue/selectors';
import { venueProps } from '../../utils/customPropTypes';
import EditIcon from '../../components/Icons/EditIcon';
import VisibilityIcon from '../../components/Icons/VisibilityIcon';
import routes from '../../routes/paths';
import { GATags, fireEvent } from '../../utils/trackingHelper';

const s = {
  btn: 'bg-white rounded-full lg:rounded-lg px-4 py-3 flex items-center justify-center w-full',
  btnIcon: 'h-5 w-5 lg:h-6 lg:w-6',
  btnText: 'ml-2 font-semibold text-xs lg:text-base capitalize',
};

const ProfileTile = ({ venue }) => (
  <div className="relative mx-4 sm:m-0">
    <div className="h-full w-full bg-black opacity-30 absolute rounded-2xl" />
    <img
      className="h-32 sm:h-36 lg:h-80 w-full pointer-events-none object-cover rounded-2xl"
      src={venue.profilePicture.original}
      alt=""
    />
    <div className="absolute top-0 inset-x-0 flex lg:flex-col justify-between h-full p-4 sm:p-6 lg:p-8 gap-2 sm:gap-6">
      <div className="text-white font-bold">
        <p className="opacity-60 uppercase text-xs sm:text-base">{t('home_yourProfile')}</p>
        <h2 className="headingFont text-xl sm:text-4xl line-clamp-3 sm:line-clamp-2">
          {venue.name}
        </h2>
      </div>
      <div className="flex flex-col lg:flex-row gap-2 sm:gap-3">
        <a
          onClick={() => fireEvent(GATags.HOME_PROFILE_VIEW, null, { venueID: venue.id })}
          href={venue.matchpintUrl}
          target="_blank"
          rel="noopener noreferrer"
          className={s.btn}
        >
          <VisibilityIcon className={s.btnIcon} show />
          <p className={s.btnText}>{t('general_view')}</p>
        </a>
        <Link
          onClick={() => fireEvent(GATags.HOME_PROFILE_EDIT, null, { venueID: venue.id })}
          to={routes.profile.path}
          className={s.btn}
        >
          <EditIcon className={s.btnIcon} />
          <p className={s.btnText}>{t('general.edit')}</p>
        </Link>
      </div>
    </div>
  </div>
);

const mapStateToProps = state => ({
  venue: getVenue(state),
});

ProfileTile.propTypes = {
  venue: venueProps,
};

ProfileTile.defaultProps = {
  venue: null,
};

export default connect(mapStateToProps)(ProfileTile);

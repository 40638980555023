import React from 'react';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import styles from './styles.module.scss';
import SpinnerButton from '../../../components/SpinnerButton/SpinnerButton';
import PlainButton from '../../../components/PlainButton/PlainButton';
import { GATags, fireEvent } from '../../../utils/trackingHelper';
import { getVenueID } from '../../../store/venue/selectors';

const SaveBar = ({ loading, onSave, onDiscard, saveCopy, venueId, trackingParams }) => {
  const { pathname } = useLocation();
  return (
    <div className={styles.banner}>
      <div className="flex flex-col md:flex-row-reverse gap-4 md:max-w-xl mx-auto">
        <SpinnerButton
          className="bg-secondary font-semibold flex-1 rounded-lg py-2 md:py-3"
          loading={loading}
          onClick={() => {
            fireEvent(GATags.PROFILE_SAVE, null, { venueId, page: pathname, ...trackingParams });
            onSave();
          }}
        >
          {saveCopy}
        </SpinnerButton>
        <PlainButton
          className="bg-mpGrey font-semibold flex-1 rounded-lg py-2 md:py-3"
          onClick={() => {
            fireEvent(GATags.PROFILE_DISCARD, null, { venueId, page: pathname, ...trackingParams });
            onDiscard();
          }}
        >
          {t('editProfile_discardChanges')}
        </PlainButton>
      </div>
    </div>
  );
};

SaveBar.propTypes = {
  loading: PropTypes.bool.isRequired,
  onSave: PropTypes.func.isRequired,
  onDiscard: PropTypes.func.isRequired,
  saveCopy: PropTypes.string.isRequired,
  venueId: PropTypes.number.isRequired,
  trackingParams: PropTypes.shape(),
};

SaveBar.defaultProps = {
  trackingParams: null,
};

const mapStateToProps = state => ({
  venueId: getVenueID(state),
});

export default connect(mapStateToProps)(SaveBar);

import React, { Fragment, useCallback, useEffect, useState, useMemo } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { useRequest } from '../../utils/CustomHooks';
import { historyProps } from '../../utils/customPropTypes';
import Dropdown from '../../components/Dropdown/Dropdown';
import {
  fetchGameRounds,
  fetchMemberPredictions,
  fetchLeagueMembers,
  updateMemberTips,
} from '../../store/api/venues';
import { getVenueLeague } from '../../store/venue/selectors';
import i18n, { getLocaleDate } from '../../i18n';
import FloatingBanner from '../../components/FloatingBanner/FloatingBanner';
import MemberButton from './components/MemberButton';
import { getIsMobile } from '../../store/app/selectors';
import BackIcon from '../../components/Icons/BackIcon';
import TipsRow from './components/TipsRow';
import Search from './Search';
import { debounce } from '../../utils/general';
import { fireEvent, GACategories, GATags } from '../../utils/trackingHelper';
import PlainButton from '../../components/PlainButton/PlainButton';

const dFormat = date => getLocaleDate(date, 'EEEE do LLLL');

const PaperTips = ({
  league,
  fetchRounds,
  fetchMembers,
  fetchPredictions,
  updateTips,
  isMobile,
  history,
}) => {
  const [searchValue, setSearchValue] = useState('');
  const [rounds, setRounds] = useState();
  const [, members, fetchMbrs] = useRequest(fetchMembers);
  const [, searchResults, searchMem, clearSearch] = useRequest(fetchMembers);
  const [activeRound, setActiveRound] = useState();
  const [activeMember, setActiveMember] = useState();
  const [originalFixtures, setOriginalFixtures] = useState();
  const [currentFixtures, setCurrentFixtures] = useState();
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const routerState = history?.location?.state;
  const { gameId, venueId } = league || {};
  const getMember = useCallback(id => members?.find(m => m.member.id === id)?.member, [members]);

  const clear = () => {
    clearSearch();
    setSearchValue('');
  };

  const debouncedSearch = useMemo(
    () =>
      debounce(search => {
        if (search.length > 2) {
          fireEvent(GACategories.FOOTY_TIPPING, GATags.SEARCH_TIPPER);
          searchMem(gameId, venueId, { search });
        }
      }, 750),
    [gameId, searchMem, venueId],
  );

  useEffect(() => {
    debouncedSearch(searchValue);
  }, [debouncedSearch, searchValue]);

  useEffect(() => {
    if (gameId) {
      fetchRounds(gameId).then(res =>
        setRounds(res.getResult().map(({ name, id }) => ({ key: id, value: name }))),
      );
    }
  }, [fetchRounds, gameId]);

  useEffect(() => {
    if (gameId && venueId) {
      fetchMbrs(gameId, venueId, { offlinePlayers: true });
    }
  }, [fetchMbrs, gameId, venueId]);

  const fetchPreds = useCallback(
    (memberId, roundId) => {
      setIsLoading(true);
      fetchPredictions(memberId, roundId)
        .then(r => {
          const { fixtures } = r.getResult()[0];
          const fixturesWithWinnerId = fixtures?.map(fixture => ({
            ...fixture,
            winnerId: fixture.predictions.find(p => p.score > 0)?.teamId,
          }));
          setOriginalFixtures(fixturesWithWinnerId);
          setCurrentFixtures(fixturesWithWinnerId);
        })
        .finally(() => setIsLoading(false));
    },
    [fetchPredictions],
  );

  useEffect(() => {
    if (routerState && rounds) {
      setActiveMember(getMember(routerState?.selectedUser));
      setActiveRound(rounds?.[0]);
      fetchPreds(routerState?.selectedUser, rounds?.[0].key);
    }
  }, [fetchPreds, getMember, rounds, routerState]);

  const onUpdateClick = (fixture, newWinner) => {
    setCurrentFixtures(
      currentFixtures.map(f => (f.id === fixture.id ? { ...f, winnerId: newWinner } : f)),
    );
    setHasUnsavedChanges(true);
  };
  const firstDate = dFormat(
    currentFixtures?.length > 0 ? currentFixtures[0].startTimeUtc : undefined,
  );
  let newestDate = firstDate;

  const roundsAndSearch = (
    <div className="flex flex-row mb-5">
      <Dropdown
        data={rounds}
        selected={activeRound}
        buttonStyle={`rounded-md py-2 font-bold ${!activeRound ? 'text-standardGrey' : ''}`}
        containerStyle="mr-2"
        onClickItem={item => {
          fireEvent(GACategories.FOOTY_TIPPING, GATags.SELECT_ROUND);
          setHasUnsavedChanges(false);
          setActiveRound(item);
          if (activeMember) fetchPreds(activeMember.id, item.key);
        }}
        disabled={!rounds}
      />
      <Search
        clear={clear}
        onChange={newVal => setSearchValue(newVal)}
        value={searchValue}
        searchResults={searchResults}
        onClick={id => {
          setActiveMember(members?.find(m => m.member.id === id).member);
          fetchPreds(id, activeRound.key);
          clear();
        }}
      />
    </div>
  );

  const membersList = members?.map(({ member }) => (
    <MemberButton
      key={member.id}
      member={member}
      isActive={activeMember?.id === member.id}
      onClick={() => {
        const roundId = activeRound?.key || rounds[0].key;
        setHasUnsavedChanges(false);
        setActiveMember(member);
        fetchPreds(member.id, roundId);
        if (!activeRound) setActiveRound(rounds?.[0]);
      }}
      disabled={isLoading}
    />
  ));

  const fixturesList = currentFixtures?.map(fixture => {
    const { id, startTimeUtc } = fixture;
    if (dFormat(startTimeUtc) === newestDate) {
      return <TipsRow fixture={fixture} onClick={onUpdateClick} isLoading={isLoading} key={id} />;
    }
    newestDate = dFormat(startTimeUtc);
    return (
      <Fragment key={id}>
        <p className="font-bold text-xs uppercase my-3">{newestDate}</p>
        <TipsRow fixture={fixture} onClick={onUpdateClick} isLoading={isLoading} />
      </Fragment>
    );
  });

  return (
    <div className={`GlobalPad pt-10 bg-white flex-1 ${isLoading ? 'opacity-40' : ''}`}>
      {isMobile ? (
        <>
          {activeMember ? (
            <PlainButton className="flex items-center mb-5" onClick={() => setActiveMember(null)}>
              <BackIcon className="h-8 w-8" />
              <div>
                <h1>{activeRound?.value}</h1>
                <h1 className="headingFont text-4xl">
                  {activeMember.firstName} {activeMember.lastName}
                </h1>
              </div>
            </PlainButton>
          ) : (
            roundsAndSearch
          )}

          <div className="flex flex-row mb-32">
            {activeMember ? (
              <div className="w-full">
                <p className="font-bold text-xs uppercase mb-3">{firstDate}</p>
                {fixturesList}
              </div>
            ) : (
              <div className="w-full p-1">{membersList}</div>
            )}
          </div>
        </>
      ) : (
        <>
          {roundsAndSearch}
          <div className="flex flex-row lg:mb-48 mb-28 divide-x">
            <div className="pr-5 lg:pr-3 xl:pr-10 p-1">{membersList}</div>
            {activeMember && (
              <div className="pl-5 lg:pl-3 xl:pl-10 border-opacity-10 border-mpGrey overflow-auto">
                <p className="font-bold text-xs uppercase mb-3">{firstDate}</p>
                {fixturesList}
              </div>
            )}
          </div>
        </>
      )}

      {hasUnsavedChanges && (
        <FloatingBanner
          button1Text={i18n.t('tipping.saveTips')}
          button1Action={() => {
            const { id: memberId, firstName, lastName } = activeMember;
            const fullName = lastName ? `${firstName} ${lastName}` : firstName;
            const newPredictions = currentFixtures
              .filter(f => f.winnerId)
              .map(f => ({ fixtureId: f.id, teamId: f.winnerId }));
            fireEvent(GACategories.FOOTY_TIPPING, GATags.SAVE_SCORE);
            return updateTips(gameId, memberId, newPredictions, venueId)
              .then(() => fetchPreds(memberId, activeRound.key))
              .finally(() => {
                setHasUnsavedChanges(false);
                toast.success(i18n.t('tipping.saveTips.toast', { fullName }));
              });
          }}
          button1Loading={isLoading}
          button2Text={i18n.t('general.cancel')}
          button2Action={() => {
            setCurrentFixtures(originalFixtures);
            setHasUnsavedChanges(false);
          }}
        />
      )}
    </div>
  );
};

PaperTips.propTypes = {
  league: PropTypes.shape(),
  fetchRounds: PropTypes.func.isRequired,
  fetchMembers: PropTypes.func.isRequired,
  fetchPredictions: PropTypes.func.isRequired,
  updateTips: PropTypes.func.isRequired,
  history: historyProps.isRequired,
  isMobile: PropTypes.bool.isRequired,
};

PaperTips.defaultProps = {
  league: null,
};

const mapStateToProps = state => ({
  league: getVenueLeague(state),
  isMobile: getIsMobile(state),
});

const mapDispatchToProps = {
  fetchRounds: fetchGameRounds,
  fetchMembers: fetchLeagueMembers,
  fetchPredictions: fetchMemberPredictions,
  updateTips: updateMemberTips,
};

export default connect(mapStateToProps, mapDispatchToProps)(PaperTips);

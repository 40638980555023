import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import i18n from 'i18next';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import ChannelCategory from './ChannelCategory';
import { fetchVenueChannels, saveVenueChannels } from '../../store/venue/thunks';
import { getVenueSelectedChannels, getAllChannels, getVenue } from '../../store/venue/selectors';
import BorderedInput from '../BorderedInput/BorderedInput';
import { updateSelectedChannels } from '../../store/venue/actions';
import { venueProps } from '../../utils/customPropTypes';
import { fireEvent, GACategories, GATags } from '../../utils/trackingHelper';

const ChannelSelector = ({
  fetchChannels,
  updateChannel,
  venue,
  allChannels,
  selectedChannels,
}) => {
  const [filter, setFilter] = useState('');
  const channelSelector = useRef(null);
  const { pathname } = useLocation();

  useEffect(() => {
    fetchChannels(venue.id);
  }, [fetchChannels, venue.id]);

  const renderChannels = () => {
    // if window height is below threshold, unfold filter results
    const channelRowHeight = 22;
    const channelGroupRowHeight = 32;
    const foldThreshold = 162;

    let totalHeight = 0;
    const getChannelList = channelGroup =>
      allChannels[channelGroup].filter(c => c.name.toLowerCase().includes(filter.toLowerCase()));

    // Calculate the total element height first:
    Object.keys(allChannels).map(channelGroup => {
      const channelLength = getChannelList(channelGroup).length;
      totalHeight += channelLength > 0 && channelLength * channelRowHeight + channelGroupRowHeight;
      return totalHeight;
    });

    // only map through channelGroups that include the channel searched in the filter field
    return Object.keys(allChannels)
      .sort((a, b) => (a.toLowerCase() > b.toLowerCase() ? 1 : -1))
      .map(channelGroup => {
        const channelList = getChannelList(channelGroup).sort((a, b) =>
          a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1,
        );
        if (channelList.length > 0) {
          const activeChannelCount = channelList.filter(c => selectedChannels.includes(c.id))
            .length;
          return (
            <ChannelCategory
              channelSelector={channelSelector.current}
              key={channelGroup}
              updateChannel={id => {
                fireEvent(GATags.CHANNEL_UPDATED, null, { page: pathname });
                updateChannel(id);
              }}
              selectedChannels={selectedChannels}
              setOpenUponSearch={totalHeight < foldThreshold}
              channelGroup={channelList}
              activeChannelCount={activeChannelCount}
              groupName={channelGroup}
            />
          );
        }
        return null;
      });
  };

  return (
    <>
      <BorderedInput
        onChange={e => {
          fireEvent(GACategories.PROFILE, GATags.SEARCH_CHANNELS, { page: pathname });
          setFilter(e);
        }}
        value={filter}
        placeholder={i18n.t('editProfile.preferences.search_channels')}
        className="rounded-full text-base pl-4 py-2"
      />
      <div className="mt-2" ref={channelSelector} data-test-id="channelSelector">
        {renderChannels()}
      </div>
    </>
  );
};

ChannelSelector.propTypes = {
  fetchChannels: PropTypes.func.isRequired,
  updateChannel: PropTypes.func.isRequired,
  venue: venueProps.isRequired,
  allChannels: PropTypes.shape().isRequired,
  selectedChannels: PropTypes.arrayOf(PropTypes.number).isRequired,
};
const mapStateToProps = state => ({
  selectedChannels: getVenueSelectedChannels(state),
  allChannels: getAllChannels(state),
  venue: getVenue(state),
});

const mapDispatchToProps = dispatch => ({
  fetchChannels: id => dispatch(fetchVenueChannels(id)),
  updateChannel: id => dispatch(updateSelectedChannels(id)),
  saveChannels: (venueID, selectedChannels) =>
    dispatch(saveVenueChannels(venueID, selectedChannels)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ChannelSelector);

import * as consts from './constants';
import { MpCookieRemove } from '../../utils/MpCookie';
import { fireEvent, GACategories, GATags } from '../../utils/trackingHelper';

export function fetchLoginAuthPending() {
  return {
    type: consts.FETCH_LOGIN_AUTH_PENDING,
  };
}

export function fetchLoginAuthSuccess(user) {
  return {
    type: consts.FETCH_LOGIN_AUTH_SUCCESS,
    payload: user,
  };
}

export function fetchLoginAuthError() {
  return {
    type: consts.FETCH_LOGIN_AUTH_ERROR,
  };
}
export function fetchAuthSuccess(user) {
  return {
    type: consts.FETCH_AUTH_SUCCESS,
    payload: user,
  };
}

export function logoutAction() {
  // this is a bit anti pattern but is a much more efficient way of writing it
  MpCookieRemove('token');
  MpCookieRemove('id');
  MpCookieRemove('venueId');

  window.Appcues?.reset();
  fireEvent(GACategories.LOGIN, GATags.LOGOUT);
  return {
    type: consts.AUTH_LOGOUT,
  };
}

export function passwordUpdateError(error) {
  return {
    type: consts.UPDATEPASSWORD_ERROR,
    payload: error,
  };
}

export const ADD_SMFIXTURE = 'add/smFixture';
export const REMOVE_SMFIXTURE = 'remove/smFixture';
export const REMOVE_BULK_SMFIXTURE = 'remove/bulk/smFixture';
export const SET_SMFIXTURES = 'set/smFixtures';
export const UPDATE_SMTHEME = 'update/smTheme';

export const POST_PUBLISH_PENDING = 'post/marketingPostPublish/pending';
export const POST_PUBLISH_SUCCESS = 'post/marketingPostPublish/success';
export const POST_PUBLISH_ERROR = 'post/marketingPostPublish/error';

export const CLEAR_SOCIAL_MEDIA = 'filters/clear/socialMedia';

export const FETCH_CONTENT_PENDING = 'fetch/content/pending';
export const FETCH_CONTENT_SUCCESS = 'fetch/content/success';
export const FETCH_CONTENT_ERROR = 'fetch/content/error';

export const FETCH_NEXT_CONTENT_PENDING = 'fetch/nextContent/pending';
export const FETCH_NEXT_CONTENT_SUCCESS = 'fetch/nextContent/success';
export const FETCH_NEXT_CONTENT_ERROR = 'fetch/nextContent/error';

export const FETCH_POSTED_POSTS_PENDING = 'fetch/posts/posted/pending';
export const FETCH_POSTED_POSTS_SUCCESS = 'fetch/posts/posted/success';
export const FETCH_POSTED_POSTS_ERROR = 'fetch/posts/posted/error';

export const FETCH_SCHEDULED_POSTS_PENDING = 'fetch/posts/scheduled/pending';
export const FETCH_SCHEDULED_POSTS_SUCCESS = 'fetch/posts/scheduled/success';
export const FETCH_SCHEDULED_POSTS_ERROR = 'fetch/posts/scheduled/error';

export const FETCH_NEXT_POSTED_POSTS_PENDING = 'fetch/posts/posted/next/pending';
export const FETCH_NEXT_POSTED_POSTS_SUCCESS = 'fetch/posts/posted/next/success';
export const FETCH_NEXT_POSTED_POSTS_ERROR = 'fetch/posts/posted/next/error';

export const FETCH_NEXT_SCHEDULED_POSTS_PENDING = 'fetch/posts/scheduled/next/pending';
export const FETCH_NEXT_SCHEDULED_POSTS_SUCCESS = 'fetch/posts/scheduled/next/success';
export const FETCH_NEXT_SCHEDULED_POSTS_ERROR = 'fetch/posts/scheduled/next/error';

export const DELETE_POST_PENDING = 'delete/post/pending';
export const DELETE_POST_SUCCESS = 'delete/post/success';
export const DELETE_POST_ERROR = 'delete/post/error';

export const FETCH_PAGES_START = 'fetch/pages/start';
export const FETCH_PAGES_SUCCESS = 'fetch/pages/success';
export const FETCH_PAGES_ERROR = 'fetch/pages/error';

export const FETCH_ACCOUNTS_START = 'fetch/socialAccounts/start';
export const FETCH_ACCOUNTS_SUCCESS = 'fetch/socialAccounts/success';
export const FETCH_ACCOUNTS_ERROR = 'fetch/socialAccounts/error';

export const POST_WAS_SCHEDULED = 'POST_WAS_SCHEDULED';
export const POST_WAS_POSTED = 'POST_WAS_POSTED';

export const SET_SM_FORMAT = 'SET_SM_FORMAT';
export const SET_SM_NUMBER_OF_FIXTURES = 'SET_SM_NUMBER_OF_FIXTURES';

/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Router, Route, Switch, Prompt, withRouter, Redirect } from 'react-router-dom';
import queryString from 'query-string';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import i18n from 'i18next';
import ProtectedLayout from '../pages/ProtectedLayout/ProtectedLayout';
import { routes } from './routeConfig';
import { getHasUnsavedChanges } from '../store/navigation/selectors';
import StrapiPage from '../pages/Strapi/StrapiPage';
import history from './history';
import { publicRoutes } from './paths';
import { getURLQuery } from '../utils/general';
import { existingUserAppCuesFlow, newUserAppCuesFlow } from '../utils/constants';

const routeArray = [];

const renderRoutes = route => {
  if (route.subRoutes) {
    Object.keys(route.subRoutes).map(key => renderRoutes(route.subRoutes[key]));
  }
  const index = routeArray.findIndex(rou => rou.key === route.path);
  if (index > -1) {
    routeArray.splice(index, 1);
  }
  routeArray.push(
    <Route
      key={route.path}
      path={route.path}
      exact={route.exact}
      render={routeProps => <route.component {...routeProps} title={i18n.t(route.title)} />}
    />,
  );
};

const ProtectedRoutes = ({ hasUnsavedChanges }) => {
  Object.keys(routes).map(key => renderRoutes(routes[key]));
  const { subKey, venueId, newPortalUser } = queryString.parse(history.location.search);

  window.onbeforeunload = () => (hasUnsavedChanges ? true : undefined);

  const getAppCue = () => {
    if (newPortalUser === '1') {
      return newUserAppCuesFlow;
    }
    if (newPortalUser === '0') {
      return existingUserAppCuesFlow;
    }
    return undefined;
  };

  return (
    <Router history={history}>
      <>
        <ProtectedLayout>
          <Switch>
            {routeArray}
            <Route exact path="/" component={routes.home.component} />
            <Route path="/index.html" component={routes.home.component} />
            {/* If the user is logged out when they finish payment and return to pogba.
              We redirect to login and preserve the params */}
            <Redirect
              exact
              path={publicRoutes.login}
              to={`${routes.home.path}${getURLQuery({ subKey, appcue: getAppCue() })}`}
            />
            <Redirect
              exact
              path={publicRoutes.activateNewUser}
              to={`${routes.home.path}${getURLQuery({ venueId, appcue: newUserAppCuesFlow })}`}
            />
            <Redirect exact path={publicRoutes.signupV2} to={routes.home.path} />
            <Route component={StrapiPage} />
          </Switch>
        </ProtectedLayout>

        <Prompt when={hasUnsavedChanges} message={i18n.t('navigation.leave')} />
      </>
    </Router>
  );
};

ProtectedRoutes.propTypes = {
  hasUnsavedChanges: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
  hasUnsavedChanges: getHasUnsavedChanges(state),
});

export default withRouter(connect(mapStateToProps)(ProtectedRoutes));

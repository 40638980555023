import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styles from './AssetGroupPage.module.scss';
import LoadingAnimation from '../../components/LoadingAnimation/LoadingAnimation';

const ImageWithSpinner = ({ src }) => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    return () => {
      setLoaded(false);
    };
  }, [src]);

  return (
    <div className="object-cover self-center pointer-events-none">
      <img
        src={src}
        className={loaded ? styles.previewImg : 'hidden'}
        onLoad={() => setLoaded(true)}
        alt=""
      />
      {!loaded && <LoadingAnimation />}
    </div>
  );
};

ImageWithSpinner.propTypes = {
  src: PropTypes.string.isRequired,
};

export default ImageWithSpinner;

import React from 'react';
import PropTypes from 'prop-types';

const PlusIcon = ({ className }) => (
  <svg viewBox="0 0 24 24" className={`fill-current ${className}`}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.1818 4.90909L11.7314 18.8776H9.54954L12 4.90909L14.1818 4.90909Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.7681 12.9678H4.58627L4.96327 10.8188H19.1451L18.7681 12.9678Z"
    />
  </svg>
);

PlusIcon.propTypes = {
  className: PropTypes.string,
};

PlusIcon.defaultProps = {
  className: '',
};

export default PlusIcon;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { NavLink, useRouteMatch } from 'react-router-dom';
import { routes } from '../../routes/routeConfig';

export const getRouteData = hardcodedArr =>
  hardcodedArr.map(el => routes[el]).filter(route => route !== undefined);

const styles = {
  link:
    'flex items-center gap-4 py-4 text-white hover:font-bold hover:opacity-100 px-4 mx-0 lg:mx-2 rounded-2xl',
  icon: 'h-6 w-6 flex-shrink-0',
};

export const CustomNavLink = ({ path, title, Icon, additionalMatch }) => {
  const [hover, setHover] = useState(false);
  const onHover = () => setHover(prev => !prev);
  const isActive = Boolean(useRouteMatch(path)) || additionalMatch;
  const color = isActive ? 'bg-brandedGrey-2 font-bold' : 'opacity-60';
  return (
    <NavLink
      className={`${styles.link} ${color}`}
      onMouseEnter={onHover}
      onMouseLeave={onHover}
      to={path}
      data-test-id="sideBarLinks"
    >
      {Icon && <Icon className={styles.icon} isActive={isActive || hover} />}
      {title}
    </NavLink>
  );
};

CustomNavLink.propTypes = {
  path: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  Icon: PropTypes.func.isRequired,
  additionalMatch: PropTypes.bool,
};

CustomNavLink.defaultProps = {
  additionalMatch: false,
};

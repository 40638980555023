import React from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import i18n, { t } from 'i18next';
import PlainButton from '../PlainButton/PlainButton';
import CopyIcon from '../Icons/CopyIcon';

const Snippet = ({ codeSnippet, id, gaTracking, sideBySideOnTabletUp }) => {
  const copyToClipboard = field => {
    document.querySelector(field).select();
    document.execCommand('copy');
    document.getSelection().removeAllRanges();
    toast.info(i18n.t('integrate.copied'));
  };

  const calculateRows = () => {
    let result;
    if (codeSnippet.split(/\r\n|\r|\n/).length > 20) {
      result = 20;
    } else {
      result = codeSnippet.split(/\r\n|\r|\n/).length;
    }
    if (navigator.userAgent.toLowerCase().indexOf('firefox') > -1) {
      // firefox adds an extra line, see https://stackoverflow.com/questions/7695945/height-of-textarea-does-not-match-the-rows-in-firefox
      return result === 1 ? 1 : result - 1;
      // rows='0' resets to default value, so min rows='1'
    }
    return result;
  };

  return (
    <div className={`${sideBySideOnTabletUp ? 'md:flex' : ''} my-4`}>
      <PlainButton
        className="relative mr-auto w-full outline-none"
        onClick={() => {
          gaTracking();
          copyToClipboard(`#${id}`);
        }}
      >
        <textarea
          rows={calculateRows()}
          readOnly
          className="outline-none resize-none rounded-2xl bg-white border border-mpGrey p-4 w-full"
          id={id}
          value={codeSnippet}
        />
      </PlainButton>
      <PlainButton
        className={`flex justify-center items-center bg-mpGrey w-full sm:w-64 py-3 rounded-lg outline-none ${
          sideBySideOnTabletUp ? 'md:ml-4' : ''
        }`}
        onClick={() => {
          gaTracking();
          copyToClipboard(`#${id}`);
        }}
      >
        <CopyIcon className="h-7 w-7" />
        <p className="font-semibold ml-2">{t('general_copy')}</p>
      </PlainButton>
    </div>
  );
};

Snippet.propTypes = {
  id: PropTypes.string.isRequired,
  codeSnippet: PropTypes.string.isRequired,
  gaTracking: PropTypes.func,
  sideBySideOnTabletUp: PropTypes.bool,
};

Snippet.defaultProps = {
  gaTracking: () => {},
  sideBySideOnTabletUp: false,
};

export default connect()(Snippet);

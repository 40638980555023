import { getYear } from 'date-fns';
import i18n from 'i18next';
import { getWebsiteURL, findDomainExt } from '../../../utils/environment';

const domain = getWebsiteURL();

const XMLSnippet = ({
  id,
  matchpintUrl,
}) => `<rss xmlns:dc="http://purl.org/dc/elements/1.1/" xmlns:sy="http://purl.org/rss/1.0/modules/syndication/" xmlns:admin="http://webns.net/mvcb/"xmlns:content="http://purl.org/rss/1.0/modules/content/" xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#" version="2.0">
  <title>${i18n.t('general.title')}</title>
  <link>https://www-service.${findDomainExt()}/venues/${id}/fixture/xml?newFields=1</link>
  <description/>
  <dc:language>${i18n.resolvedLanguage}</dc:language>
  <dc:creator>${i18n.t('integrate.XML.snippet.info_email')}</dc:creator>
  <dc:rights>${i18n.t('integrate.XML.snippet.copyright_year', {
    year: getYear(new Date()),
  })}</dc:rights>
  <channel>
    <item>
        <title>${i18n.t('integrate.XML.snippet.titleTag')}</title>
        <description>${i18n.t('integrate.XML.snippet.descTag')}</description>
        <sport>${i18n.t('integrate.XML.snippet.sportTag')}</sport>
        <team1>
          ${domain}imagenes/teams/2018-02/7393-norwich.png
        </team1>
        <team2>
          ${domain}imagenes/teams/2018-09/8074-swansea_city_grey.png
        </team2>
        <link>
            <![CDATA[
              ${matchpintUrl}?fixtureId={fixtureId}
            ]]>
        </link>
        <dc:date>2019-03-08 19:45:00</dc:date>
    </item>
    <item>
    <title>${i18n.t('integrate.XML.snippet.titleTag2')}</title>
    <description>${i18n.t('integrate.XML.snippet.descTag2')}</description>
    <sport>${i18n.t('integrate.XML.snippet.sportTag')}</sport>
        <team1>
          ${domain}imagenes/teams/2018-08/6741-hibernian201819.png
        </team1>
        <team2>
          ${domain}imagenes/teams/2018-07/7639-rangersfc2018.png
        </team2>
    <link>
        <![CDATA[
          ${domain}invitation?g=353653&b=${id}
        ]]>
    </link>
    <dc:date>2019-03-08 19:45:00</dc:date>
    <bigScreen>true</bigScreen>
    <sound>false</sound>
  </item>
  </channel>
</rss>`;

export default XMLSnippet;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import i18n from 'i18next';
import { getVenueID } from '../../store/venue/selectors';
import { getFixtures, saveFixturesRequest } from '../../store/api/fixtures';
import { usePaginatedRequest } from './util';
import MyScheduleHeader from '../../components/Fixtures/MyScheduleHeader';
import FixturesList from './FixturesList';
import FloatingBanner from '../../components/FloatingBanner/FloatingBanner';
import { fireEvent, GACategories, GATags } from '../../utils/trackingHelper';
import { getIsDesktop } from '../../store/app/selectors';

const MySchedulePage = ({ fetchFixtures, venueID, saveFixtures, isDesktop }) => {
  const [savingFixtures, setSavingFixtures] = useState(false);

  const [
    fixtures,
    fetching,
    hasMore,
    fetchNext,
    loadingPage,
    ,
    updateFixture,
    changes,
    discardChanges,
    onSuccesfulSave,
  ] = usePaginatedRequest(fetchFixtures, 15, { venueID, onlyScheduled: true });
  const noOfChanges = changes?.length || 0;

  const appBarHeight = 80;
  const dateTopHeight = `${isDesktop ? 0 : appBarHeight}px`;

  return (
    <div className="h-full bg-offWhite">
      <MyScheduleHeader venueID={venueID} />
      <FixturesList
        fixtures={fixtures}
        fetching={fetching}
        hasMore={hasMore}
        fetchNext={fetchNext}
        loadingPage={loadingPage}
        updateFixture={updateFixture}
        noResultsText={i18n.t('fixtures_noFixturesScheduled')}
        bottomBannerDisplayed={Boolean(noOfChanges)}
        dateTopHeight={dateTopHeight}
      />
      {noOfChanges > 0 && (
        <FloatingBanner
          text={
            <>
              <span className="font-bold block">
                {i18n.t('fixtures.changes', { count: noOfChanges })}
              </span>
              {i18n.t('fixtures.changes_subtitle')}
            </>
          }
          button1Text={i18n.t('general.save')}
          button1Action={() => {
            fireEvent(GACategories.FIXTURES, GATags.SAVE_FIXTURES);
            setSavingFixtures(true);
            saveFixtures(venueID, changes)
              .then(onSuccesfulSave)
              .finally(() => setSavingFixtures(false));
          }}
          button1Loading={savingFixtures}
          button2Text={i18n.t('general.cancel')}
          button2Action={discardChanges}
        />
      )}
    </div>
  );
};

MySchedulePage.propTypes = {
  venueID: PropTypes.number.isRequired,
  fetchFixtures: PropTypes.func.isRequired,
  saveFixtures: PropTypes.func.isRequired,
  isDesktop: PropTypes.bool.isRequired,
};

const mapDispatchToProps = {
  fetchFixtures: getFixtures,
  saveFixtures: saveFixturesRequest,
};

const mapStateToProps = state => ({
  venueID: getVenueID(state),
  isDesktop: getIsDesktop(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(MySchedulePage);

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import PageHeader from '../../components/PageHeader/PageHeader';
import { fetchEditableCountryCompetitions } from '../../store/socialMedia/thunks';
import { getVenueID } from '../../store/venue/selectors';
import { fireEvent, GACategories, GATags } from '../../utils/trackingHelper';

const CustomiseCompetitions = ({ title, fetchCompetitions, venueId }) => {
  const [competitions, setCompetitions] = useState([]);
  useEffect(() => {
    if (venueId) {
      fetchCompetitions(venueId).then(r => setCompetitions(r));
    }
  }, [fetchCompetitions, venueId]);

  return (
    <>
      <PageHeader title={title} />
      <div className="GlobalPad flex flex-wrap">
        {competitions.map(comp => (
          <Link
            key={comp.id}
            className="p-2"
            onClick={() =>
              fireEvent(GACategories.CUSTOM_ASSET, GATags.CLICKED_COMPETITION_SPECIFIC, {
                compId: comp.competition.id,
              })
            }
            to={{
              pathname: `/marketing/customise-assets/competitions/${comp.competition.id}/edit`,
              state: { name: comp.competition.name },
            }}
          >
            <img className="h-36 w-44" key={comp.id} alt={comp.competition.name} src={comp.logo} />
          </Link>
        ))}
      </div>
    </>
  );
};

CustomiseCompetitions.propTypes = {
  title: PropTypes.string.isRequired,
  fetchCompetitions: PropTypes.func.isRequired,
  venueId: PropTypes.number.isRequired,
};

const mapDispatchToProps = dispatch => ({
  fetchCompetitions: venueId => dispatch(fetchEditableCountryCompetitions(venueId)),
});

const mapStateToProps = state => ({
  venueId: getVenueID(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(CustomiseCompetitions);

import * as consts from './constants';

export const initialState = {
  isFetching: false,
  venues: [null],
  allChannels: {},
  permissions: null,
  selectedChannels: [],
  initiallySelectedChannels: [],
  fieldErrors: {},
  isFetchingChannels: false,
  isFetchingAllChannels: false,
  isFetchingPermissions: false,
  venueGame: null,
  venueLeague: null,
  isSavingVenue: false,
  isSavingChannels: false,
  isUploadingImage: false,
  authModalStatus: 'start',
  isDisconnecting: false,
  isLoggingInToTwitter: false,
  twitterLoginURL: null,
  isFinalisingResub: false,
  finaliseResubSuccess: false,
  finaliseResubError: '',
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case consts.FETCH_VENUES_PENDING:
      return {
        ...state,
        isFetching: true,
      };
    case consts.FETCH_VENUES_SUCCESS: {
      const venues = action.payload;
      const hasAGame = Boolean(venues[0]?.games?.length > 0);
      return {
        ...state,
        isFetching: false,
        venueGame: hasAGame ? venues[0].games[0] : null,
        venueLeague: hasAGame ? venues[0].games[0].venueLeague : null,
        venues,
      };
    }
    case consts.FETCH_VENUES_ERROR:
      return {
        ...state,
        isFetching: false,
      };
    case consts.FETCH_PERMISSIONS_PENDING:
      return {
        ...state,
        isFetchingPermissions: true,
      };
    case consts.FETCH_PERMISSIONS_SUCCESS: {
      const permissions = action.payload;
      return {
        ...state,
        isFetchingPermissions: false,
        permissions,
      };
    }
    case consts.FETCH_PERMISSIONS_ERROR:
      return {
        ...state,
        isFetchingPermissions: false,
      };
    case consts.UPDATE_VENUE_PENDING:
      return {
        ...state,
        isSavingVenue: true,
      };
    case consts.UPDATE_VENUE_SUCCESS:
      // if we ever have more than one venue, this may break.
      return {
        ...state,
        isSavingVenue: false,
        venues: [action.payload],
        fieldErrors: {},
      };
    case consts.UPDATE_VENUE_ERROR:
      return {
        ...state,
        isSavingVenue: false,
        fieldErrors: action.payload,
      };
    case consts.FETCH_ALL_CHANNELS_PENDING:
      return {
        ...state,
        isFetchingAllChannels: true,
      };
    case consts.FETCH_ALL_CHANNELS_SUCCESS: {
      const allChannels = action.payload;
      const sortedAllChannels = {};
      Object.keys(allChannels)
        // sort groups by number of selected channels
        .sort((cg1, cg2) =>
          allChannels[cg1].filter(el => el.inVenue).length >
          allChannels[cg2].filter(el => el.inVenue).length
            ? -1
            : 1,
        )
        .forEach(channelGroup => {
          sortedAllChannels[channelGroup] = allChannels[channelGroup];
        });

      const selectedChannels = []
        .concat(...Object.keys(allChannels).map(channelGroup => allChannels[channelGroup]))
        .filter(channel => channel.inVenue)
        .map(channel => channel.id);
      return {
        ...state,
        allChannels: sortedAllChannels,
        selectedChannels,
        initiallySelectedChannels: selectedChannels,
        isFetchingAllChannels: false,
      };
    }
    case consts.FETCH_ALL_CHANNELS_ERROR:
      return {
        ...state,
        isFetchingAllChannels: false,
      };
    case consts.DISCARD_CHANNEL_UPDATES: {
      return {
        ...state,
        selectedChannels: state.initiallySelectedChannels,
      };
    }
    case consts.UPDATE_SELECTED_CHANNELS: {
      const channelID = action.payload;
      let selectedChannelsCopy = [...state.selectedChannels];
      // if channelID is one channel - add if not selected, remove if selected.
      const updateChannels = id => {
        if (selectedChannelsCopy.includes(id)) {
          selectedChannelsCopy = selectedChannelsCopy.filter(c => c !== id);
        } else {
          selectedChannelsCopy.push(id);
        }
      };
      // when an entire channel category being selected, then either add the missing channels to select all, or remove all:
      if (Array.isArray(channelID)) {
        if (channelID.every(c => selectedChannelsCopy.includes(c))) {
          selectedChannelsCopy = selectedChannelsCopy.filter(c => !channelID.includes(c));
        } else {
          channelID.forEach(c => selectedChannelsCopy.push(c));
        }
      } else {
        updateChannels(channelID);
      }
      return {
        ...state,
        selectedChannels: selectedChannelsCopy,
      };
    }
    case consts.UPDATE_VENUE_CHANNELS_PENDING:
      return {
        ...state,
        isSavingChannels: true,
      };
    case consts.UPDATE_VENUE_CHANNELS_SUCCESS:
      return {
        ...state,
        initiallySelectedChannels: action.payload,
        isSavingChannels: false,
      };
    case consts.UPDATE_VENUE_CHANNELS_ERROR:
      return {
        ...state,
        isSavingChannels: false,
      };
    case consts.AUTHMODAL_CONNECT_START:
      return {
        ...state,
        authModalStatus: 'start',
      };
    case consts.AUTHMODAL_CONNECT_SUCCESS:
      return {
        ...state,
        authModalStatus: 'success',
      };
    case consts.AUTHMODAL_CONNECT_ERROR:
      return {
        ...state,
        authModalStatus: 'error',
        isLoggingInToTwitter: false,
      };
    case consts.FACEBOOK_DISCONNECT_START:
      return {
        ...state,
        isDisconnecting: true,
      };
    case consts.FACEBOOK_DISCONNECT_SUCCESS:
      return {
        ...state,
        isDisconnecting: false,
      };
    case consts.FACEBOOK_DISCONNECT_ERROR:
      return {
        ...state,
        isDisconnecting: false,
      };
    case consts.CLEAR_VENUE_FIELD_ERRORS:
      return {
        ...state,
        fieldErrors: {},
      };
    case consts.START_TWITTER_LOGIN:
      return {
        ...state,
        isLoggingInToTwitter: true,
      };
    case consts.STOP_TWITTER_LOGIN:
      return {
        ...state,
        isLoggingInToTwitter: false,
      };
    case consts.SET_TWITTER_LOGIN_URL:
      return {
        ...state,
        twitterLoginURL: action.payload,
        isLoggingInToTwitter: false,
      };
    case consts.START_FINALISE_RESUB:
      return {
        ...state,
        isFinalisingResub: true,
        finaliseResubSuccess: false,
        finaliseResubError: '',
      };
    case consts.SUCCESS_FINALISE_RESUB:
      return {
        ...state,
        isFinalisingResub: false,
        finaliseResubSuccess: true,
        finaliseResubError: '',
      };
    case consts.ERROR_FINALISE_RESUB:
      return {
        ...state,
        isFinalisingResub: false,
        finaliseResubSuccess: false,
        finaliseResubError: action.payload,
      };
    case consts.UPDATE_VENUELEAGUE:
      return {
        ...state,
        venueLeague: { ...action.payload },
      };

    default:
      return state;
  }
};

export default reducer;

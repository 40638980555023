import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as sel from '../../store/venue/selectors';
import { venueProps } from '../../utils/customPropTypes';
import i18n from '../../i18n';
import OpeningHours, { compareOpeningTimes } from '../../components/OpeningHours/OpeningHours';
import { updateVenueThunk } from '../../store/venue/thunks';
import { getStatesByCountry, saveVenuePhotos } from '../../store/api/venues';
import TextArea from '../../components/TextArea/TextArea';
import { fieldsChanged, transformImages } from '../../utils/general';
import { fireEvent, GACategories, GATags } from '../../utils/trackingHelper';
import { useDidMountEffect } from '../../utils/CustomHooks';
import routes from '../../routes/paths';
import history from '../../routes/history';
import MPModal from '../../components/MPModal/MPModal';
import Photos from '../SignupV2/Stage3/Steps/Photos';
import SpinnerButton from '../../components/SpinnerButton/SpinnerButton';

const AddPubInfo = ({ venue, saveChanges, savingVenue, savePhotos }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [pubData, setPubData] = useState({
    longDescription: venue.longDescription,
    openingHours: venue.openingHours,
  });

  const [venueImages, setVenueImages] = useState(() => transformImages(venue));
  const [imageFieldErrors, setImageFieldErrors] = useState(null);
  const [loading, setLoading] = useState(false);
  const [hasImageChanges, setHasImageChanges] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);

  useDidMountEffect(() => {
    // if venue images changes at all then they've got changes to save so enable the button
    setHasImageChanges(true);
  }, [venueImages]);

  const fieldStyles = 'pt-6 text-base font-bold';
  const fieldDescriptionStyles = 'pt-2 pb-6 text-base';
  const buttonsCommon = 'flex text-center rounded-md py-3 px-8 justify-center';

  const isModalOpen = history.location?.search?.includes('editProfile');
  useEffect(() => {
    setModalOpen(isModalOpen);
  }, [isModalOpen]);

  useEffect(() => {
    setHasChanges(fieldsChanged(pubData, venue).length > 0 || !compareOpeningTimes(pubData, venue));
  }, [pubData, venue]);

  const buttonDisabled = !(hasChanges || hasImageChanges);

  return (
    <MPModal
      open={modalOpen}
      onClose={() => history?.goBack()}
      className="p-12 rounded-lg max-w-[550px]"
    >
      <h1 className="headingFont text-4xl">{i18n.t('editProfile.general.pub_info')}</h1>
      <p className="pt-3 text-base">{i18n.t('editProfile.general.pub_info_text')}</p>
      <p className={fieldStyles}>{i18n.t('editProfile.general.pub_description')}</p>
      <TextArea
        value={pubData.longDescription}
        placeholder={i18n.t('editProfile.general.pub_description_field')}
        className="mt-2 rounded-md border border-black text-black"
        onChange={e => {
          fireEvent(GACategories.ONBOARDING, GATags.DESCRIPTION);
          setPubData({ ...pubData, longDescription: e });
        }}
      />
      <p className={fieldStyles}>{i18n.t('editProfile.general.pub_hours')}</p>
      <p className={fieldDescriptionStyles}>{i18n.t('editProfile.general.pub_hours_field')}</p>
      <OpeningHours
        openingHours={pubData?.openingHours}
        onChange={openingHours => {
          fireEvent(GACategories.ONBOARDING, GATags.OPENING_HOURS);
          setPubData({
            ...pubData,
            openingHours,
          });
        }}
      />
      <div className="relative">
        <p className={fieldStyles}>{i18n.t('editProfile.general.pub_photo')}</p>
        <p className={fieldDescriptionStyles}>{i18n.t('editProfile.general.pub_photo_field')}</p>
        <Photos
          venueImages={venueImages}
          setVenueImages={setVenueImages}
          venue={venue}
          fieldErrors={imageFieldErrors}
          setFieldErrors={setImageFieldErrors}
          showTitle={false}
        />
      </div>
      <SpinnerButton
        loading={savingVenue || loading}
        disabled={buttonDisabled}
        className={`${buttonsCommon} w-full mt-8 ${
          buttonDisabled ? 'bg-lightGrey cursor-default' : 'bg-primary'
        }`}
        onClick={() => {
          if (hasImageChanges) {
            setLoading(true);
            setImageFieldErrors(null);
            savePhotos(
              venue?.id,
              venueImages.map((vi, i) => ({ ...vi, order: i })),
            )
              .then(r => {
                setHasImageChanges(false);
                const res = r.getResult();
                if (res?.saveErrors?.length === 0) {
                  setVenueImages(res.saveSuccess);
                } else {
                  // if some errors then update venue images partially
                  setVenueImages([
                    ...venueImages.filter(vi => res?.saveErrors.find(f => f.id === vi.id)),
                    ...res.saveSuccess,
                  ]);
                  setImageFieldErrors(res.saveErrors);
                }
              })
              .finally(() => setLoading(false));
          }
          if (hasChanges) {
            saveChanges({ ...pubData, id: venue.id }, () => {
              setHasChanges(false);
              fireEvent(GACategories.PROFILE, GATags.EDIT_PROFILE_GENERAL, { venueId: venue.id });
              history.push(routes.home.path);
            });
          }
        }}
      >
        <p className="font-semibold">{i18n.t('general.save')}</p>
      </SpinnerButton>
    </MPModal>
  );
};

AddPubInfo.propTypes = {
  venue: venueProps.isRequired,
  saveChanges: PropTypes.func.isRequired,
  savingVenue: PropTypes.bool,
  savePhotos: PropTypes.func.isRequired,
};

AddPubInfo.defaultProps = {
  savingVenue: false,
};

const mapStateToProps = state => ({
  venue: sel.getVenue(state),
  savingVenue: sel.getIsSavingVenue(state),
});

const mapDispatchToProps = {
  saveChanges: updateVenueThunk,
  getStates: getStatesByCountry,
  savePhotos: saveVenuePhotos,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddPubInfo);

import React from 'react';
import PropTypes from 'prop-types';
import i18n from '../../i18n';
import styles from './RadioInput.module.scss';

const RadioInput = ({ options, active, onClick }) =>
  Object.keys(options).map(o => (
    <div className="relative py-1 pr-3 flex items-center" key={o}>
      <input
        id={o}
        type="radio"
        value={o}
        checked={active === o}
        onChange={e => onClick(e.currentTarget.value)}
        className={styles.input}
        name="radio_form"
      />
      <label className="ml-5 text-md opacity-60 cursor-pointer" htmlFor={o}>
        {i18n.t(options[o])}
      </label>
    </div>
  ));

RadioInput.propTypes = {
  options: PropTypes.shape().isRequired,
  active: PropTypes.string,
  onClick: PropTypes.func.isRequired,
};

RadioInput.defaultProps = {
  selected: '',
  active: '',
};

export default RadioInput;

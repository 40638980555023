import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import ChevronIcon from '../../../components/Icons/ChevronIcon';
import './styles.scss';
import { fireEvent, GACategories } from '../../../utils/trackingHelper';

const MarketingActionTile = ({ className, label, description, link, id, images, tag }) => (
  <Link
    id={id}
    onClick={() => {
      if (tag) {
        fireEvent(GACategories.SOCIAL_MEDIA_CONTENT, tag);
      }
    }}
    className={`Marketing_tile ${className}`}
    to={link}
  >
    <div className="flex overflow-hidden justify-between px-8 pt-8 lg:pb-5">
      <p className="mr-6 text-sm lg:text-base">{description}</p>
      <img
        src={images.desktop}
        className="hidden lg:block self-start object-contain pt-2 w-1/3 xl:w-2/5"
        alt=""
      />
      <img src={images.mobile} className="h-44 lg:hidden" alt="" />
    </div>
    <div className="flex justify-between bg-veryDarkGrey text-white items-center mt-auto rounded-b-lg px-8 py-4">
      <p className="uppercase font-bold text-sm lg:text-base">{label}</p>
      <ChevronIcon className="h-5 w-5" />
    </div>
  </Link>
);

MarketingActionTile.propTypes = {
  label: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  className: PropTypes.string,
  id: PropTypes.string,
  images: PropTypes.shape({
    desktop: PropTypes.string,
    mobile: PropTypes.string,
  }),
  tag: PropTypes.string,
};

MarketingActionTile.defaultProps = {
  className: '',
  id: null,
  images: {},
  tag: null,
};

export default MarketingActionTile;

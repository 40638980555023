/* eslint-disable jsx-a11y/iframe-has-title */
import React from 'react';
import PropTypes from 'prop-types';

const Iframe = ({ url }) => (
  <div className="container mx-auto px-16">
    <iframe src={url} className="border-none w-full" style={{ height: '680px' }} />
  </div>
);

Iframe.propTypes = {
  url: PropTypes.string.isRequired,
};

export default Iframe;

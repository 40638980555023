/* eslint-disable import/no-extraneous-dependencies */
import { applyMiddleware, combineReducers, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunkMiddleware from 'redux-thunk';
import authReducer from './auth/reducer';
import venueReducer from './venue/reducer';
import fixtureReducer from './fixtures/reducer';
import socialMediaReducer from './socialMedia/reducer';
import offerReducer from './offers/reducer';
import navigationReducer from './navigation/reducer';
import settingsReducer from './settings/reducer';
import appReducer from './app/reducer';
import tierReducer from './tiers/reducer';
import financialReducer from './financials/reducer';

function configureStore(initialState) {
  const reducers = combineReducers({
    auth: authReducer,
    venue: venueReducer,
    fixtures: fixtureReducer,
    socialMedia: socialMediaReducer,
    offers: offerReducer,
    navigation: navigationReducer,
    settings: settingsReducer,
    app: appReducer,
    tiers: tierReducer,
    financials: financialReducer,
  });

  const middleware = [thunkMiddleware];

  return createStore(reducers, initialState, composeWithDevTools(applyMiddleware(...middleware)));
}

export default configureStore;

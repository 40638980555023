import React from 'react';

const ChannelListIcon = () => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24.1981 16.9868H12.5618L12.9207 14.8347H24.5571L24.1981 16.9868Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23.3606 22.0088H11.7243L12.0833 19.8567H23.7196L23.3606 22.0088Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M25.0358 11.9658H13.3994L13.7584 9.81372H25.3948L25.0358 11.9658Z"
      fill="black"
    />
    <path
      d="M8.48797 10.8898C8.62015 10.0975 9.37851 9.45512 10.1818 9.45512C10.9852 9.45512 11.5292 10.0975 11.3971 10.8898C11.2649 11.6822 10.5065 12.3246 9.7032 12.3246C8.89988 12.3246 8.3558 11.6822 8.48797 10.8898Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.12434 10.8898C8.28955 9.89937 9.23751 9.09644 10.2417 9.09644C11.2458 9.09644 11.9259 9.89937 11.7607 10.8898C11.5955 11.8803 10.6475 12.6832 9.64337 12.6832C8.63922 12.6832 7.95912 11.8803 8.12434 10.8898ZM10.122 9.8138C9.51952 9.8138 8.95074 10.2956 8.85161 10.8898C8.75248 11.4841 9.16054 11.9659 9.76303 11.9659C10.3655 11.9659 10.9343 11.4841 11.0334 10.8898C11.1326 10.2956 10.7245 9.8138 10.122 9.8138Z"
      fill="black"
    />
    <path
      d="M7.65035 15.9108C7.78252 15.1185 8.54089 14.4761 9.34421 14.4761C10.1475 14.4761 10.6916 15.1185 10.5594 15.9108C10.4273 16.7032 9.6689 17.3456 8.86558 17.3456C8.06225 17.3456 7.51818 16.7032 7.65035 15.9108Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.28671 15.9108C7.45193 14.9204 8.39989 14.1174 9.40404 14.1174C10.4082 14.1174 11.0883 14.9204 10.9231 15.9108C10.7579 16.9013 9.8099 17.7042 8.80575 17.7042C7.80159 17.7042 7.1215 16.9013 7.28671 15.9108ZM9.28438 14.8348C8.68189 14.8348 8.11312 15.3166 8.01399 15.9108C7.91486 16.5051 8.32291 16.9869 8.92541 16.9869C9.5279 16.9869 10.0967 16.5051 10.1958 15.9108C10.2949 15.3166 9.88688 14.8348 9.28438 14.8348Z"
      fill="black"
    />
    <path
      d="M6.81284 20.9328C6.94501 20.1404 7.70338 19.4981 8.5067 19.4981C9.31003 19.4981 9.8541 20.1404 9.72193 20.9328C9.58976 21.7252 8.83139 22.3675 8.02807 22.3675C7.22475 22.3675 6.68067 21.7252 6.81284 20.9328Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.4492 20.9328C6.61442 19.9423 7.56238 19.1394 8.56653 19.1394C9.57069 19.1394 10.2508 19.9423 10.0856 20.9328C9.92035 21.9233 8.97239 22.7262 7.96824 22.7262C6.96409 22.7262 6.28399 21.9233 6.4492 20.9328ZM8.44688 19.8568C7.84438 19.8568 7.27561 20.3385 7.17648 20.9328C7.07735 21.5271 7.48541 22.0089 8.0879 22.0089C8.69039 22.0089 9.25917 21.5271 9.3583 20.9328C9.45742 20.3385 9.04937 19.8568 8.44688 19.8568Z"
      fill="black"
    />
  </svg>
);

export default ChannelListIcon;

import React from 'react';
import PropTypes from 'prop-types';

const CalendarPlusIcon = ({ className }) => (
  <svg className={`fill-current ${className}`} viewBox="0 0 24 24">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.76436 3.55554H21.128L19.6774 12.7141H18.0411L19.2356 5.17176H6.14474L4.31019 16.7547C4.19237 17.4985 4.70739 18.1015 5.4605 18.1015H11.7332L11.4772 19.7177H5.20452C3.54767 19.7177 2.41464 18.3911 2.67382 16.7547L4.76436 3.55554Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.99442 3.82491H20.8126L20.0447 8.67356H4.22647L4.99442 3.82491ZM5.91467 4.90239L5.48804 7.59609H19.1244L19.551 4.90239H5.91467Z"
    />
    <path d="M6.74214 11.8376C6.82768 11.3248 7.31849 10.9091 7.83838 10.9091C8.35828 10.9091 8.7104 11.3248 8.62486 11.8376L8.58309 12.088C8.49755 12.6009 8.00674 13.0166 7.48685 13.0166C6.96695 13.0166 6.61483 12.6009 6.70037 12.088L6.74214 11.8376Z" />
    <path d="M9.46942 11.8376C9.55496 11.3248 10.0458 10.9091 10.5657 10.9091C11.0856 10.9091 11.4377 11.3248 11.3521 11.8376L11.3104 12.088C11.2248 12.6009 10.734 13.0166 10.2141 13.0166C9.69422 13.0166 9.3421 12.6009 9.42764 12.088L9.46942 11.8376Z" />
    <path d="M12.1967 11.8376C12.2822 11.3248 12.773 10.9091 13.2929 10.9091C13.8128 10.9091 14.1649 11.3248 14.0794 11.8376L14.0376 12.088C13.9521 12.6009 13.4613 13.0166 12.9414 13.0166C12.4215 13.0166 12.0694 12.6009 12.1549 12.088L12.1967 11.8376Z" />
    <path d="M14.924 11.8376C15.0095 11.3248 15.5003 10.9091 16.0202 10.9091C16.5401 10.9091 16.8922 11.3248 16.8067 11.8376L16.7649 12.088C16.6794 12.6009 16.1886 13.0166 15.6687 13.0166C15.1488 13.0166 14.7967 12.6009 14.8822 12.088L14.924 11.8376Z" />
    <path d="M6.74214 15.1103C6.82768 14.5975 7.31849 14.1818 7.83838 14.1818C8.35828 14.1818 8.7104 14.5975 8.62486 15.1103L8.58309 15.3608C8.49755 15.8736 8.00674 16.2893 7.48685 16.2893C6.96695 16.2893 6.61483 15.8736 6.70037 15.3608L6.74214 15.1103Z" />
    <path d="M9.46942 15.1103C9.55496 14.5975 10.0458 14.1818 10.5657 14.1818C11.0856 14.1818 11.4377 14.5975 11.3521 15.1103L11.3104 15.3608C11.2248 15.8736 10.734 16.2893 10.2141 16.2893C9.69422 16.2893 9.3421 15.8736 9.42764 15.3608L9.46942 15.1103Z" />
    <path d="M12.1967 15.1103C12.2822 14.5975 12.773 14.1818 13.2929 14.1818C13.8128 14.1818 14.1649 14.5975 14.0794 15.1103L14.0376 15.3608C13.9521 15.8736 13.4613 16.2893 12.9414 16.2893C12.4215 16.2893 12.0694 15.8736 12.1549 15.3608L12.1967 15.1103Z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.0035 14.2831L18.5454 22.5947H15.8182L17.2762 14.2831L20.0035 14.2831Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.8393 19.6236H13.4007L13.872 16.9374H22.3105L21.8393 19.6236Z"
    />
  </svg>
);

CalendarPlusIcon.propTypes = {
  className: PropTypes.string,
};
CalendarPlusIcon.defaultProps = {
  className: '',
};

export default CalendarPlusIcon;

import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { t } from 'i18next';
import { toast } from 'react-toastify';
import { useLocation } from 'react-router-dom';
import { request } from '../../store/api/request';
import { getVenueID } from '../../store/venue/selectors';
import PlainButton from '../PlainButton/PlainButton';
import CloseIcon from '../Icons/CloseIcon';
import SearchIcon from '../Icons/SearchIcon';
import { GATags, fireEvent } from '../../utils/trackingHelper';
import { refreshVenueThunk } from '../../store/venue/thunks';
import { useDetectOutsideClick } from '../../utils/CustomHooks';

const SportsFavourites = ({ venueId, req, refreshVenue }) => {
  const [sports, setSports] = useState(null);
  const { pathname } = useLocation();

  useEffect(() => {
    req(`venues/${venueId}/sports`).then(res => setSports(res.getResult()));
  }, [req, venueId]);

  const [value, setValue] = useState('');
  const [results, setResults] = useState('');
  const [isFocused, setIsFocused] = useState(false);
  const ref = useRef();
  useDetectOutsideClick(ref, () => setIsFocused(false));

  const onClear = () => setValue('');
  const onSearch = val => {
    setValue(val);
    if (val.length > 1) {
      setResults(
        sports?.filter(
          sport => !sport.selected && sport.name.toLowerCase().includes(val.toLowerCase()),
        ),
      );
    }
  };

  const selectedSports = sports?.filter(s => s.selected);
  const selectedSportIds = selectedSports?.map(e => e.id);
  const showResults = Boolean(results?.length > 0 && value && isFocused);

  const onAddOrRemove = (sport, add) => {
    fireEvent(GATags.FAVOURITE_UPDATED, null, {
      venueId,
      page: pathname,
      type: 'sport',
      name: sport.name,
      add,
    });
    req(`venues/${venueId}/sports/save`, {
      method: 'PUT',
      body: {
        sportIds: add
          ? [...selectedSportIds, sport.id]
          : selectedSportIds.filter(id => id !== sport.id),
      },
    }).then(() => {
      refreshVenue(venueId);
      setSports(prev => prev.map(s => (s.id === sport.id ? { ...s, selected: add } : s)));
      toast.success(
        t(add ? 'editProfile_favouritesToastAdd' : 'editProfile_favouritesToastRemove', {
          value: sport.name,
        }),
      );
    });
  };

  return (
    <>
      <div className="w-full my-2 relative" ref={ref}>
        <div
          className={`flex rounded-3xl p-2.5 pl-4 border-2 bg-white ${
            showResults ? 'border-b-transparent rounded-b-none' : ''
          } ${isFocused ? 'border-quaternary' : 'border-mpGrey'}`}
        >
          <input
            className="flex-1 outline-none"
            value={value}
            placeholder={t('favourites.search.sports')}
            onChange={e => onSearch(e.target.value)}
            onFocus={() => setIsFocused(true)}
          />
          {value?.length > 0 ? (
            <PlainButton className="ml-3" onClick={onClear}>
              <CloseIcon className="w-6 h-6 bg-mpGrey rounded-full" />
            </PlainButton>
          ) : (
            <SearchIcon className="w-6 h-6 ml-3" />
          )}
        </div>
        {showResults && (
          <div className="-mt-1 pt-2 absolute rounded-b-3xl border-2 border-t-0 border-quaternary bg-white right-0 left-0 z-10 overflow-hidden">
            {results.map(sport => (
              <PlainButton
                className="w-full outline-none p-3 text-left hover:bg-lightGrey"
                key={sport.id}
                onClick={() => {
                  onAddOrRemove(sport, true);
                  onClear();
                }}
              >
                {sport.name}
              </PlainButton>
            ))}
          </div>
        )}
      </div>
      {selectedSports && (
        <div className="flex gap-2 flex-wrap mt-4">
          {selectedSports.map(sport => (
            <div
              key={sport.id}
              className="flex items-center rounded-full border-2 border-quaternary bg-secondaryLight py-1 px-2 md:py-2 md:px-4"
            >
              <p className="text-xs font-semibold pl-1 pr-2">{sport.name}</p>
              <PlainButton onClick={() => onAddOrRemove(sport, false)}>
                <CloseIcon className="w-6 h-6" />
              </PlainButton>
            </div>
          ))}
        </div>
      )}
    </>
  );
};

SportsFavourites.propTypes = {
  venueId: PropTypes.number.isRequired,
  req: PropTypes.func.isRequired,
  refreshVenue: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  venueId: getVenueID(state),
});

const mapDispatchToProps = {
  req: request,
  refreshVenue: refreshVenueThunk,
};

export default connect(mapStateToProps, mapDispatchToProps)(SportsFavourites);

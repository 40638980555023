import PropTypes from 'prop-types';

export const infoTileProps = PropTypes.shape({
  current: PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string.isRequired,
    subTitle: PropTypes.string.isRequired,
  }).isRequired,
  previous: PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string.isRequired,
    subTitle: PropTypes.string.isRequired,
  }).isRequired,
});

export const dashboardProps = PropTypes.shape({
  action: PropTypes.shape({
    title: PropTypes.string.isRequired,
    target: PropTypes.string.isRequired,
  }).isRequired,
  fixtures: infoTileProps,
  fans: infoTileProps,
  revenue: infoTileProps,
});

export const permissionsProps = PropTypes.arrayOf(PropTypes.string);

export const venueProps = PropTypes.shape({
  id: PropTypes.number.isRequired,
});

export const venueMemberProps = PropTypes.shape({
  id: PropTypes.number,
  memberId: PropTypes.number,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  email: PropTypes.string,
  type: PropTypes.oneOf(['Admin', 'Other']),
});

export const fixtureProps = PropTypes.shape({
  id: PropTypes.number.isRequired,
});

export const offerProps = PropTypes.shape({
  id: PropTypes.number,
  name: PropTypes.string,
  description: PropTypes.string,
  from: PropTypes.string,
  to: PropTypes.string,
});

export const historyProps = PropTypes.shape({
  push: PropTypes.func.isRequired,
});

export const locationProps = PropTypes.shape({
  state: PropTypes.shape(),
});

export const userProps = PropTypes.shape({
  id: PropTypes.number.isRequired,
  email: PropTypes.string.isRequired,
});

export const countryProps = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  flag: PropTypes.string,
  active: PropTypes.bool,
});

export const channelGroupsProps = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  active: PropTypes.bool,
});

export const sportsProps = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  active: PropTypes.bool.isRequired,
});

export const fixturePostThemeProps = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  fixtureNumbers: PropTypes.arrayOf(PropTypes.number),
});

export const teamProps = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  logo: PropTypes.string.isRequired,
  countryFlag: PropTypes.string,
  sport: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string,
  }),
});

export const SMPreviewProps = PropTypes.shape({
  url: PropTypes.string,
  id: PropTypes.number,
});

export const SMConstraintProps = PropTypes.shape({
  startXPercent: PropTypes.number,
  startYPercent: PropTypes.number,
  endXPercent: PropTypes.number,
  endYPercent: PropTypes.number,
});

import React from 'react';
import { t } from 'i18next';
import { connect } from 'react-redux';
import { getUser } from '../../store/auth/selectors';
import PlainButton from '../../components/PlainButton/PlainButton';
import history from '../../routes/history';
import { userProps } from '../../utils/customPropTypes';
import { fireEvent, GACategories, GATags } from '../../utils/trackingHelper';

const MultipleVenues = ({ member }) => (
  <div className="px-8 my-8 md:my-14 lg:my-16 mx-auto max-w-md md:max-w-lg">
    <h1 className="headingFont text-4xl pb-4 md:text-6xl">
      {t('signup_multipleVenues_welcome', { name: member.firstName })}
    </h1>
    <p className="whitespace-pre-line md:text-xl">
      {t('signup_multipleVenues_text')}
      <a
        href={`mailto:${t('signup_multipleVenues_email')}`}
        className="underline font-bold"
        onClick={() =>
          fireEvent(GACategories.SIGN_UP_V2, GATags.VENUE_NUMBER_CONTACTUS, {
            id: member.id,
            numOfVenues: history?.location?.state?.numOfVenues,
          })
        }
      >
        {t('signup_multipleVenues_email')}
      </a>
    </p>
    <div className="mt-8 p-8 bg-white border border-mpGrey rounded-2xl">
      <p className="text-center font-semibold pb-4 md:pb-6 md:text-xl">
        {t('signup_multipleVenues_backTitle')}
      </p>
      <PlainButton
        className="p-4 rounded-lg bg-mpGrey w-full font-semibold"
        onClick={history.goBack}
      >
        {t('signup_multipleVenues_backBtn')}
      </PlainButton>
    </div>
  </div>
);

const mapStateToProps = state => ({
  member: getUser(state),
});

MultipleVenues.propTypes = {
  member: userProps.isRequired,
};

export default connect(mapStateToProps)(MultipleVenues);

import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { t } from 'i18next';
import queryString from 'query-string';
import { requestLogin } from '../../store/auth/thunks';
import { getLoginIsFetching } from '../../store/auth/selectors';
import Input from '../../components/Input/Input';
import UnprotectedLayout from '../UnprotectedLayout/UnprotectedLayout';
import SpinnerButton from '../../components/SpinnerButton/SpinnerButton';
import { historyProps } from '../../utils/customPropTypes';
import ForgotPasswordModal from './ForgotPasswordModal';
import PlainButton from '../../components/PlainButton/PlainButton';
import { publicRoutes } from '../../routes/paths';
import PasswordInput from '../../components/PasswordInput/PasswordInput';
import { getURLQuery } from '../../utils/general';
import ExistingAppUserModal from './ExistingAppUserModal';

const styles = {
  container: 'w-full max-w-sm md:max-w-md lg:max-w-sm mt-8 md:mt-14 lg:mt-16 px-4 md:px-0',
  title: 'headingFont md:text-center mb-8 text-4xl md:text-6xl',
  input: 'my-0 rounded-lg py-3 px-4 border border-mpGrey',
};

const LoginPage = ({ isFetching, login, history }) => {
  const { newPortalUser, redirect, venueId, forgottenPassword } = queryString.parse(
    history.location.search,
  );

  const [state, setState] = useState({ email: '', password: '' });
  const [modalOpen, setModalOpen] = useState(false);
  const [appUserModalOpen, setAppUserModalOpen] = useState(newPortalUser === '1');
  const verifyInputs = () => state.email.length > 0 && state.password.length > 0;

  const sendCredentials = () => {
    const { email, password } = state;
    login(email, password, venueId).then(() => {
      // if they have a specific redirect param then redirect them there
      if (redirect) {
        if (redirect.startsWith('http')) {
          window.location.href = redirect;
          return null;
        }
        return history.push(redirect);
      }
      return null;
    });
  };

  const enterKey = event => {
    if (event.key === 'Enter' && verifyInputs()) {
      sendCredentials();
    }
  };

  useEffect(() => {
    if (forgottenPassword) {
      setModalOpen(true);
    }
  }, [forgottenPassword]);

  return (
    <UnprotectedLayout displayLegalsBar noPad>
      <ForgotPasswordModal
        open={modalOpen}
        onClose={() => {
          setModalOpen(false);
          history.push(publicRoutes.login);
        }}
      />
      <ExistingAppUserModal open={appUserModalOpen} onClose={() => setAppUserModalOpen(false)} />
      <div className="flex flex-1 justify-center">
        <div className={styles.container}>
          <h1 className={styles.title}>{t('auth.login_title')}</h1>
          <div className="mb-4">
            <Input
              data-test-id="login"
              required
              type="string"
              value={state.email}
              onChange={email => setState({ ...state, email })}
              id="login-email"
              label={t('auth.email')}
              placeholder={t('auth_emailPlaceholder')}
              className={styles.input}
            />
          </div>
          <div className="mb-4">
            <PasswordInput
              dataTestId="password"
              value={state.password}
              onChange={password => setState({ ...state, password })}
              onKeyPress={enterKey}
              label={t('auth.password')}
              placeholder={t('auth_passwordPlaceholder')}
              id="login-password"
              className={styles.input}
            />
          </div>
          <PlainButton
            onClick={() => {
              setModalOpen(true);
              history.push(`${publicRoutes.login}${getURLQuery({ forgottenPassword: true })}`);
            }}
          >
            <p className="text-darkGrey underline">{t('auth.forgot_password')}</p>
          </PlainButton>
          <SpinnerButton
            className={`w-full p-4 font-semibold rounded-lg mt-6 ${
              !verifyInputs() ? 'bg-mpGrey text-standardGrey' : 'bg-tertiary text-primaryButtonText'
            }`}
            onClick={sendCredentials}
            disabled={!verifyInputs()}
            loading={isFetching}
          >
            {t('general.continue')}
          </SpinnerButton>
        </div>
      </div>
    </UnprotectedLayout>
  );
};

const mapDispatchToProps = dispatch => ({
  login: (email, password, venueId) => dispatch(requestLogin(email, password, venueId)),
});

const mapStateToProps = state => ({
  isFetching: getLoginIsFetching(state),
});

LoginPage.propTypes = {
  login: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
  history: historyProps.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);

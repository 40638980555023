import React from 'react';
import PropTypes from 'prop-types';

const StatsIcon = ({ className, isActive }) => (
  <svg className={`fill-current ${className}`} viewBox="0 0 24 24" fill="none">
    {isActive ? (
      <>
        <path d="M3.90499 11.9134H7.40499L6.30995 18.8272H2.80995L3.90499 11.9134Z" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.27232 11.1726H8.27232L6.94263 19.568H1.94263L3.27232 11.1726ZM4.53767 12.6541L3.67728 18.0864H5.67728L6.53767 12.6541H4.53767Z"
        />
        <path d="M10.2179 9.938H13.7179L12.31 18.8272H8.80995L10.2179 9.938Z" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.58519 9.19723H14.5852L12.9426 19.568H7.94263L9.58519 9.19723ZM10.8505 10.6788L9.67728 18.0864H11.6773L12.8505 10.6788H10.8505Z"
        />
        <path d="M17 4.99956H20.5L18.31 18.8272H14.81L17 4.99956Z" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.3674 4.25879H21.3674L18.9426 19.568H13.9426L16.3674 4.25879ZM17.6327 5.74032L15.6773 18.0864H17.6773L19.6327 5.74032H17.6327Z"
        />
      </>
    ) : (
      <>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.27232 11.1726H8.27232L6.94263 19.568H1.94263L3.27232 11.1726ZM4.53767 12.6541L3.67728 18.0864H5.67728L6.53767 12.6541H4.53767Z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.58519 9.19723H14.5852L12.9426 19.568H7.94263L9.58519 9.19723ZM10.8505 10.6788L9.67728 18.0864H11.6773L12.8505 10.6788H10.8505Z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.3674 4.25879H21.3674L18.9426 19.568H13.9426L16.3674 4.25879ZM17.6327 5.74032L15.6773 18.0864H17.6773L19.6327 5.74032H17.6327Z"
        />
      </>
    )}
  </svg>
);

StatsIcon.propTypes = {
  className: PropTypes.string,
  isActive: PropTypes.bool,
};

StatsIcon.defaultProps = {
  className: '',
  isActive: false,
};

export default StatsIcon;

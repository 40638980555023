import React from 'react';
import PropTypes from 'prop-types';

const DownloadIcon = ({ className }) => (
  <svg className={`fill-current ${className}`} viewBox="0 0 24 24">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.97515 18.5649L5.31819 10.9091H3.4091L1.99538 18.9679C1.85223 19.7838 2.40777 20.4453 3.23619 20.4453H19.5998C20.4283 20.4453 21.2159 19.7838 21.359 18.9679L22.7727 10.9091H20.8636L19.5206 18.5649H3.97515Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.6314 14.0408L13.4233 2.7273L15.3324 2.72729L13.5405 14.0408H11.6314Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.3202 15.8152L7.72704 10.4373L9.28815 9.104L12.7425 13.1486L17.4781 9.104L18.6168 10.4373L12.3202 15.8152Z"
    />
  </svg>
);

DownloadIcon.propTypes = {
  className: PropTypes.string,
};
DownloadIcon.defaultProps = {
  className: '',
};

export default DownloadIcon;

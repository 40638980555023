import React, { useState } from 'react';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import InstagramIcon from '../Icons/InstagramIcon';

const InstagramButton = ({ value, onChange, errorMessage }) => {
  const [focus, setFocus] = useState(false);
  const onFocus = () => setFocus(prev => !prev);
  const focusStyle = focus ? 'outline outline-2 outline-primary' : '';
  return (
    <>
      <p className="text-sm font-bold pb-2">{t('general_instagram')}</p>
      <div className={`flex px-4 py-3 rounded-lg bg-white border border-mpGrey ${focusStyle}`}>
        {!(value?.length > 0) && <InstagramIcon className="w-6 h-6 opacity-40 mr-2" />}
        <input
          className="w-full outline-none"
          placeholder={t('editProfile.socialMedia.addInstagram')}
          value={value}
          onChange={onChange}
          onFocus={onFocus}
          onBlur={onFocus}
          type="text"
        />
      </div>
      {Boolean(errorMessage) && <p className="text-xs text-lightRed pt-1">{errorMessage}</p>}
    </>
  );
};

InstagramButton.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
};

InstagramButton.defaultProps = {
  value: '',
  errorMessage: '',
};

export default InstagramButton;

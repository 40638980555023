/* eslint-disable import/prefer-default-export */
import FixturesPage from '../pages/FixturesPage/FixturesPage';
import Marketing from '../pages/Marketing/Marketing';
import PostPublish from '../pages/PostPublish/PostPublish';
import PostToSocial from '../pages/PostToSocial/PostToSocial';
import APIFeed from '../pages/WidgetSelectorPage/APIFeed/APIFeed';
import WidgetStandard from '../pages/WidgetSelectorPage/WidgetStandard';
import WidgetSelectorPage from '../pages/WidgetSelectorPage/WidgetSelectorPage';
import OfferPage from '../pages/OfferPage/OfferPage';
import SettingsPage from '../pages/SettingsPage/SettingsPage';
import CreateOfferPage from '../pages/CreateOfferPage/CreateOfferPage';
import Reimbursement from '../pages/Reimbursement/Reimbursement';
import EditOfferPage from '../pages/EditOfferPage/EditOfferPage';
import ColorAssetEditor from '../pages/AssetEditor/ColorAssetEditor';
import SocialSchedule from '../pages/SocialSchedule/SocialSchedule';
import Subscriptions from '../pages/Subscriptions/Subscriptions';
import Resubscriptions from '../pages/Resubscriptions/Resubscriptions';
import IntegrateCalendarPage from '../pages/IntegrateCalendarPage/IntegrateCalendarPage';
import paths from './paths';
import CustomiseAssets from '../pages/CustomiseAssets/CustomiseAssets';
import CustomiseCompetitions from '../pages/CustomiseAssets/CustomiseCompetitions';
import Stats from '../pages/Stats/Stats';
import CompetitionAssetEditor from '../pages/AssetEditor/CompetitionAssetEditor';
import DashboardPageV2 from '../pages/DashboardPage/DashboardPageV2';
import FootyTipping from '../pages/FootyTipping/FootyTipping';
import MySchedulePage from '../pages/FixturesPage/MySchedulePage';
import Stage2 from '../pages/SignupV2/Stage2';
import MultipleVenues from '../pages/MultipleVenues/MultipleVenues';
import Stage3 from '../pages/SignupV2/Stage3/Stage3';
import PromotionalHub from '../pages/PromotionalHub/PromotionalHub';
import AssetGroupPage from '../pages/PromotionalHub/AssetGroupPage';
import HomeIcon from '../components/Icons/HomeIcon';
import CalendarIcon from '../components/Icons/CalendarIcon';
import MegaPhoneIcon from '../components/Icons/MegaPhoneIcon';
import StatsIcon from '../components/Icons/StatsIcon';
import CrownIcon from '../components/Icons/CrownIcon';
import AvatarIcon from '../components/Icons/AvatarIcon';
import CogIcon from '../components/Icons/CogIcon';
import ProfilePageV2 from '../pages/ProfilePageV2/ProfilePageV2';
import GeneralPage from '../pages/ProfilePageV2/GeneralPage';
import Photos from '../pages/ProfilePageV2/ProfilePagePhotos';
import ChannelPage from '../pages/ProfilePageV2/ChannelPage';
import FavouritesPage from '../pages/ProfilePageV2/FavouritesPage';
import BookingsPage from '../pages/ProfilePageV2/BookingsPage';
import MenuPage from '../pages/ProfilePageV2/MenuPage';
import AccountPage from '../pages/AccountPage/AccountPage';
import BillingPage from '../pages/BillingPage/BillingPage';

export const routes = {
  home: {
    path: paths.home.path,
    component: DashboardPageV2,
    Icon: HomeIcon,
    exact: true,
    title: paths.home.title,
  },
  footyTipping: {
    path: paths.footyTipping.path,
    component: FootyTipping,
    exact: false,
    title: paths.footyTipping.title,
  },
  fixtures: {
    path: paths.fixtures.path,
    component: FixturesPage,
    exact: true,
    title: paths.fixtures.title,
    Icon: CalendarIcon,
    subRoutes: {
      mySchedule: {
        path: paths.fixtures.subRoutes.mySchedule.path,
        exact: true,
        component: MySchedulePage,
        title: paths.fixtures.subRoutes.mySchedule.title,
      },
    },
  },
  marketing: {
    path: paths.marketing.path,
    component: Marketing,
    Icon: MegaPhoneIcon,
    exact: true,
    title: paths.marketing.title,
    subRoutes: {
      postToSocial: {
        path: paths.marketing.subRoutes.postToSocial.path,
        exact: true,
        component: PostToSocial,
        title: paths.marketing.subRoutes.postToSocial.title,
      },
      postPublish: {
        path: paths.marketing.subRoutes.postPublish.path,
        exact: true,
        component: PostPublish,
        title: paths.marketing.subRoutes.postPublish.title,
      },
      socialSchedule: {
        path: paths.marketing.subRoutes.socialSchedule.path,
        exact: true,
        component: SocialSchedule,
        title: paths.marketing.subRoutes.socialSchedule.title,
      },
      customiseAssets: {
        path: paths.marketing.subRoutes.customiseAssets.path,
        exact: true,
        component: CustomiseAssets,
        title: paths.marketing.subRoutes.customiseAssets.title,
        subRoutes: {
          competitions: {
            path: paths.marketing.subRoutes.customiseAssets.subRoutes.competitions.path,
            exact: true,
            component: CustomiseCompetitions,
            title: paths.marketing.subRoutes.customiseAssets.subRoutes.competitions.title,
          },
          competitionsEdit: {
            path: paths.marketing.subRoutes.customiseAssets.subRoutes.competitionsEdit.path,
            component: CompetitionAssetEditor,
            title: paths.marketing.subRoutes.customiseAssets.subRoutes.competitionsEdit.title,
          },
        },
      },
      integrate: {
        path: paths.marketing.subRoutes.integrate.path,
        component: WidgetSelectorPage,
        exact: true,
        title: paths.marketing.subRoutes.integrate.title,
        subRoutes: {
          apiFeed: {
            path: paths.marketing.subRoutes.integrate.subRoutes.apiFeed.path,
            component: APIFeed,
            title: paths.marketing.subRoutes.integrate.subRoutes.apiFeed.title,
          },
          widget: {
            path: paths.marketing.subRoutes.integrate.subRoutes.widget.path,
            component: WidgetStandard,
            title: paths.marketing.subRoutes.integrate.subRoutes.widget.title,
          },
          calendar: {
            path: paths.marketing.subRoutes.integrate.subRoutes.calendar.path,
            component: IntegrateCalendarPage,
            title: paths.marketing.subRoutes.integrate.subRoutes.calendar.title,
            exact: true,
          },
        },
      },
      colorAssets: {
        path: paths.marketing.subRoutes.customiseAssets.subRoutes.colorAssets.path,
        exact: true,
        component: ColorAssetEditor,
        title: paths.marketing.subRoutes.customiseAssets.subRoutes.colorAssets.title,
      },
    },
  },
  stats: {
    path: paths.stats.path,
    component: Stats,
    Icon: StatsIcon,
    exact: true,
    title: paths.stats.title,
  },
  offers: {
    path: paths.offers.path,
    component: OfferPage,
    Icon: CrownIcon,
    exact: true,
    title: paths.offers.title,
    subRoutes: {
      createOffer: {
        path: paths.offers.subRoutes.createOffer.path,
        component: CreateOfferPage,
        title: paths.offers.subRoutes.createOffer.title,
      },
      editOffer: {
        path: paths.offers.subRoutes.editOffer.path,
        component: EditOfferPage,
        title: paths.offers.subRoutes.editOffer.title,
      },
    },
  },
  profile: {
    path: paths.profile.path,
    component: ProfilePageV2,
    Icon: AvatarIcon,
    title: paths.profile.title,
    exact: true,
    subRoutes: {
      general: {
        path: paths.profile.subRoutes.general.path,
        component: GeneralPage,
        exact: true,
      },
      photos: {
        path: paths.profile.subRoutes.photos.path,
        component: Photos,
        exact: true,
      },
      channels: {
        path: paths.profile.subRoutes.channels.path,
        component: ChannelPage,
        exact: true,
      },
      bookings: {
        path: paths.profile.subRoutes.bookings.path,
        component: BookingsPage,
        exact: true,
      },
      favourites: {
        path: paths.profile.subRoutes.favourites.path,
        component: FavouritesPage,
        exact: true,
      },
      menu: {
        path: paths.profile.subRoutes.menu.path,
        component: MenuPage,
        exact: true,
      },
    },
  },
  subscriptions: {
    path: paths.subscriptions.path,
    exact: true,
    title: paths.subscriptions.title,
    component: Subscriptions,
  },
  resubscriptions: {
    path: paths.resubscriptions.path,
    exact: true,
    title: paths.resubscriptions.title,
    component: Resubscriptions,
  },
  signUpV2Stage2: {
    path: paths.signupV2Stage2.path,
    exact: true,
    title: paths.signupV2Stage2.title,
    component: Stage2,
  },
  signUpV2Stage3: {
    path: paths.signupV2Stage3.path,
    exact: true,
    title: paths.signupV2Stage3.title,
    component: Stage3,
  },
  multipleVenues: {
    path: paths.multipleVenues.path,
    exact: true,
    title: paths.multipleVenues.title,
    component: MultipleVenues,
  },
  promotionalHub: {
    path: paths.promotionalHub.path,
    exact: true,
    title: paths.promotionalHub.title,
    component: PromotionalHub,
    Icon: CalendarIcon,
    subRoutes: {
      assetGroup: {
        path: paths.promotionalHub.subRoutes.assetGroup.path,
        exact: true,
        component: AssetGroupPage,
        title: paths.promotionalHub.subRoutes.assetGroup.title,
      },
    },
  },
  account: {
    path: paths.account.path,
    exact: true,
    title: paths.account.title,
    component: AccountPage,
    Icon: CogIcon,
    subRoutes: {
      settings: {
        path: paths.account.subRoutes.settings.path,
        exact: true,
        component: SettingsPage,
        title: paths.account.subRoutes.settings.title,
      },
      billing: {
        path: paths.account.subRoutes.billing.path,
        exact: true,
        component: BillingPage,
        title: paths.account.subRoutes.billing.title,
      },
      reimbursement: {
        path: paths.account.subRoutes.reimbursement.path,
        exact: true,
        component: Reimbursement,
        title: paths.account.subRoutes.reimbursement.title,
      },
    },
  },
};

import React from 'react';
import PropTypes from 'prop-types';

const CopyIcon = ({ className }) => (
  <svg className={`fill-current ${className}`} viewBox="0 0 24 24">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.45427 5.19189H20.3634L18.6568 15.9667H7.74771L9.45427 5.19189ZM10.8346 6.80811L9.64005 14.3505H17.2764L18.471 6.80811H10.8346Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.7549 7.88559H7.88217L7.62619 9.50181H7.13528L5.85536 17.5829H14.0372L14.114 17.098H15.7503L15.4176 19.1991H3.96301L5.7549 7.88559Z"
    />
  </svg>
);

CopyIcon.propTypes = {
  className: PropTypes.string,
};

CopyIcon.defaultProps = {
  className: '',
};

export default CopyIcon;

import React from 'react';
import PropTypes from 'prop-types';
import i18next from 'i18next';
import { fixtureProps } from '../../utils/customPropTypes';
import './styles.scss';
import { getLocaleDate } from '../../i18n';

const StatsFixtureRow = ({ fixture, noBorder, searches }) => {
  const renderSportsDescription = () => (
    <div>
      <p className="fixtureList_description_fixture">{fixture.name}</p>
      <p>{fixture?.competitionOverride || fixture?.competition?.name}</p>
      <p className="text-standardGrey text-sm">
        {getLocaleDate(fixture.startTimeUtc, 'do MMM yyyy HH:mm')}
      </p>
    </div>
  );
  const renderText = text => (
    <div className="fixtureList_sports">
      <p>{text}</p>
    </div>
  );

  return (
    <div className={`GlobalPad fixtureList_container ${noBorder ? '' : 'fixtureList_border'}`}>
      <div className="fixtureList_selector fixtureList_table_cell_col_20 uppercase">
        {renderText(fixture?.sport?.name)}
      </div>
      <div className="fixtureList_selector fixtureList_table_cell_col_60">
        {renderSportsDescription()}
      </div>
      <div className="fixtureList_selector fixtureList_table_cell_col_20">
        {renderText(i18next.t('stats_searches', { count: searches }))}
      </div>
    </div>
  );
};

StatsFixtureRow.propTypes = {
  fixture: fixtureProps.isRequired,
  noBorder: PropTypes.bool.isRequired,
  searches: PropTypes.number.isRequired,
};

export default StatsFixtureRow;

import React from 'react';
import PropTypes from 'prop-types';
import PlainButton from '../../PlainButton/PlainButton';
import PlusIcon from '../../Icons/PlusIcon';
import CloseIcon from '../../Icons/CloseIcon';
import Spinner from '../../Spinner/Spinner';
import s from './AddFixtureButton.module.scss';

const AddFixtureButton = ({ isUpdating, onClick, active, disabled }) => (
  <div className="m-auto">
    {!isUpdating ? (
      <PlainButton onClick={onClick} disabled={disabled}>
        <div className={`${s.btn} ${active ? s.btn_active : ''} ${disabled ? s.btn_disabled : ''}`}>
          <PlusIcon
            className={`${s.add} ${active ? s.add_active : ''} ${disabled ? s.add_disabled : ''}`}
          />
          <CloseIcon className={active ? s.remove_active : s.remove} />
        </div>
      </PlainButton>
    ) : (
      <Spinner className="w-8" />
    )}
  </div>
);

AddFixtureButton.propTypes = {
  isUpdating: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  active: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
};

AddFixtureButton.defaultProps = {
  isUpdating: false,
  disabled: false,
};

export default AddFixtureButton;

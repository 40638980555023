import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import i18next from 'i18next';
import facebook from './facebook.png';
import twitter from './twitter.png';
import success from './success.png';
import error from './error.png';
import './styles.scss';
import SecondaryButton from '../SecondaryButton/SecondaryButton';
import Button from '../Button/Button';
import { getAuthModalStatus } from '../../store/venue/selectors';
import MPModal from '../MPModal/MPModal';

const AuthoriseModal = ({
  open,
  onClose,
  onAction,
  onCancel,
  status,
  socialMedia,
  linkAction,
  tracking,
}) => (
  <MPModal
    className={status === 'start' ? 'AuthoriseModal_bigModal' : 'AuthoriseModal_smallModal'}
    open={open}
    onClose={onClose}
  >
    <>
      {status === 'start' && (
        <>
          <div className="AuthoriseModal_textContainer">
            <p className="AuthoriseModal_textContainer_heading">
              {i18next.t(`authModal.${socialMedia}.Title`)}
            </p>
            <p className="AuthoriseModal_textContainer_body">
              {i18next.t(`authModal.${socialMedia}.Subtitle`)}
            </p>
          </div>
          <div className="AuthoriseModal_imageContainer">
            <div
              className="AuthoriseModal_imageContainer_image"
              style={{ backgroundImage: `url(${socialMedia === 'facebook' ? facebook : twitter})` }}
            >
              <div className="AuthoriseModal_imageContainer_image_textContainer">
                <p className="AuthoriseModal_imageContainer_image_textContainer_text">
                  {i18next.t(`authModal.${socialMedia}.ImageText`)}
                </p>
              </div>
              <img
                alt={socialMedia}
                src={socialMedia === 'facebook' ? facebook : twitter}
                className="AuthoriseModal_imageContainer_placeHolder"
              />
            </div>
          </div>
          <div className="AuthoriseModal_buttonContainer">
            <SecondaryButton
              label={i18next.t('general.cancel')}
              className="px-4"
              bgColor="white"
              onClick={onCancel}
            />
            {onAction && (
              <Button className="bg-secondary ml-3 text-sm font-semibold" onClick={onAction}>
                {i18next.t(`authModal.${socialMedia}.buttonText`)}
              </Button>
            )}
            {linkAction && (
              <a
                target="_blank"
                rel="noopener noreferrer"
                className="bg-secondary ml-3 px-4 uppercase font-semibold text-center no-underline rounded text-sm py-1"
                href={linkAction}
                onClick={tracking}
              >
                {i18next.t(`authModal.${socialMedia}.buttonText`)}
              </a>
            )}
          </div>
        </>
      )}
      {status === 'success' && (
        <>
          <div className="AuthoriseModal_smallModal_imageContainer">
            <img
              alt="success"
              className="AuthoriseModal_smallModal_imageContainer_image"
              src={success}
            />
          </div>
          <div className="AuthoriseModal_textContainer">
            <p className="AuthoriseModal_textContainer_heading">
              {i18next.t(`authModal.${socialMedia}.Connected`)}
            </p>
          </div>
        </>
      )}
      {status === 'error' && (
        <>
          <div className="AuthoriseModal_smallModal_imageContainer">
            <img
              alt="success"
              className="AuthoriseModal_smallModal_imageContainer_image"
              src={error}
            />
          </div>
          <div className="AuthoriseModal_textContainer">
            <p className="AuthoriseModal_textContainer_heading">
              {i18next.t(`authModal.${socialMedia}.Failed`)}
            </p>
          </div>
        </>
      )}
    </>
  </MPModal>
);

AuthoriseModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onAction: PropTypes.func,
  linkAction: PropTypes.string,
  onCancel: PropTypes.func.isRequired,
  tracking: PropTypes.func,
  status: PropTypes.oneOf(['start', 'success', 'error']).isRequired,
  socialMedia: PropTypes.oneOf(['facebook', 'twitter']).isRequired,
};

AuthoriseModal.defaultProps = {
  onAction: null,
  linkAction: null,
  tracking: undefined,
};

const mapStateToProps = state => ({
  status: getAuthModalStatus(state),
});

export default connect(mapStateToProps)(AuthoriseModal);

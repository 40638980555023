import React from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18next';
import PlainButton from '../PlainButton/PlainButton';
import PosterIcon from '../Icons/PosterIcon';
import FacebookIcon from '../Icons/FacebookIcon';
import InstagramIcon from '../Icons/InstagramIcon';
import HorizontalTV from '../Icons/HorizontalTV';
import VerticalTV from '../Icons/VerticalTV';
import Story from '../Icons/Story';

export const types = {
  portrait: 'portrait',
  landscape: 'landscape',
  instagram: 'instagram',
  print: 'print',
  facebook_twitter: 'facebook_twitter',
  story: 'story',
  screen: 'screen',
  screenV: 'screenV',
};

const typeMap = type => {
  switch (type) {
    case types.portrait:
    case types.print:
      return {
        Icon: PosterIcon,
        text: t('marketing.postToSocial.poster'),
      };
    case types.instagram:
      return {
        Icon: InstagramIcon,
        text: t('instagram_post'),
      };
    case types.landscape:
    case types.facebook_twitter:
      return {
        Icon: FacebookIcon,
        text: t('facebook_post'),
      };
    case types.story:
      return {
        Icon: Story,
        text: t('fb_insta_post'),
      };
    case types.screen:
      return {
        Icon: HorizontalTV,
        text: t('tv_horizontal'),
      };
    case types.screenV:
      return {
        Icon: VerticalTV,
        text: t('tv_vertical'),
      };
    default:
      return null;
  }
};

const AssetFormatIcon = ({ type, selected, onClick }) => {
  const { Icon, text } = typeMap(type);
  if (!Icon || !text) {
    return null;
  }
  return (
    <PlainButton
      onClick={onClick}
      className="flex flex-col w-32 items-center text-white font-semibold text-xs"
    >
      <Icon className="h-8" />
      <p
        className={`flex justify-center items-center mt-2 w-full h-11 px-2 uppercase rounded-2xl ${
          selected ? 'bg-secondary text-black' : 'border'
        }`}
      >
        {text}
      </p>
    </PlainButton>
  );
};

AssetFormatIcon.propTypes = {
  type: PropTypes.oneOf(Object.values(types)).isRequired,
  selected: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default AssetFormatIcon;

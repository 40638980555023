import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { eachDayOfInterval, add, isSameDay, parseISO } from 'date-fns';
import { getLocaleDate } from '../../../i18n';
import ChevronIcon from '../../Icons/ChevronIcon';
import styles from './FixtureDateSelector.module.scss';
import PlainButton from '../../PlainButton/PlainButton';
import { fireEvent, GACategories, GATags } from '../../../utils/trackingHelper';

const FixtureDateSelector = ({ openHours, onClick }) => {
  const dates = eachDayOfInterval({ start: new Date(), end: add(new Date(), { months: 1 }) });

  const isSelected = date => isSameDay(parseISO(openHours?.initialData?.date), date);

  const renderDate = date => (
    <div key={date} className={isSelected(date) ? styles.item_selected : styles.item}>
      <PlainButton
        onClick={() => {
          fireEvent(GACategories.FIXTURES, GATags.FILTER_BY_DATE);
          onClick(date);
        }}
        key={date}
        className="px-3 py-1 uppercase text-xs"
      >
        <p className="font-bold">{getLocaleDate(date, 'eee')}</p>
        <p>{getLocaleDate(date, 'd MMM')}</p>
      </PlainButton>
    </div>
  );

  const chevronButton = (onClickF, rotate = false) => (
    <PlainButton className="p-2 rounded-lg bg-black text-white" onClick={onClickF}>
      <ChevronIcon className={`w-6 h-6 ${rotate ? 'rotate-180' : ''}`} />
    </PlainButton>
  );

  const ref = useRef(null);
  const scrollWidth = ref?.current?.offsetWidth - 35;
  const scroll = scrollOffset => {
    ref.current.scrollLeft += scrollOffset;
  };

  return (
    <div className="flex flex-1 mb-4 gap-1">
      {chevronButton(() => scroll(-scrollWidth), true)}
      <div ref={ref} className={`${styles.scroll} noScrollBar`}>
        {dates?.map(date => renderDate(date))}
      </div>
      {chevronButton(() => scroll(scrollWidth))}
    </div>
  );
};

FixtureDateSelector.propTypes = {
  openHours: PropTypes.shape({
    initialData: PropTypes.shape({
      date: PropTypes.string,
    }),
    data: PropTypes.shape({
      date: PropTypes.string,
    }),
  }),
  onClick: PropTypes.func.isRequired,
};

FixtureDateSelector.defaultProps = {
  openHours: { initialData: { date: undefined }, data: { date: undefined } },
};

export default FixtureDateSelector;

import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import i18n from 'i18next';
import { format } from 'date-fns';
import FilterIcon from '../../../components/Icons/FilterIcon';
import Toggle from '../../../components/Toggle/Toggle';
import PlainButton from '../../../components/PlainButton/PlainButton';
import Modal from '../Modal';
import styles from './styles.module.scss';
import PlusIcon from '../../../components/Icons/PlusIcon';
import { dateTypes, filterActions } from '../filtersReducer';
import Spinner from '../../../components/Spinner/Spinner';
import { fireEvent, GACategories, GATags } from '../../../utils/trackingHelper';
import CloseIcon from '../../../components/Icons/CloseIcon';
import ClockIcon from '../../../components/Icons/ClockIcon';

const COMP_FILTERS = 'competitionFilters';

const getFilterType = type => (typeof type === 'number' ? COMP_FILTERS : type);

const FiltersDateSelector = ({ text, minDate, date, setDate, error, disabled }) => (
  <div className={`flex-1 ${styles.calendarInput} ${disabled ? 'opacity-40' : ''}`}>
    <h3 className={styles.h3}>{text}</h3>
    <input
      className={`${styles.calendarInput_inner} ${
        error ? 'border-2 border-primaryRed p-3' : 'p-3.5'
      } ${date ? 'border-primary' : ' border-mpGrey'}`}
      type="date"
      label={i18n.t('general.from')}
      value={date}
      // We save unselected dates as an empty string because the input HTML won't rerender an empty date if we use JS to change a date with a value to undefined
      // https://stackoverflow.com/questions/39622856/react-input-not-updating-when-receiving-props
      onChange={({ currentTarget: { value } }) => setDate(value)}
      min={minDate}
    />
  </div>
);

FiltersDateSelector.propTypes = {
  text: PropTypes.string.isRequired,
  date: PropTypes.string,
  minDate: PropTypes.string,
  error: PropTypes.bool.isRequired,
  setDate: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

FiltersDateSelector.defaultProps = {
  minDate: undefined,
  date: undefined,
  disabled: false,
};

const FixturesFilters = ({ filters, dispatch, fetchPaginated, areThereActiveFilters }) => {
  const [filtersModal, setFiltersModal] = useState(false);
  const {
    activeFilters,
    favouriteFilters,
    sportFilters,
    openHours: {
      data: { date: dateOH },
    },
    dates: {
      data: { fromDate, toDate },
    },
    sportsComps,
  } = filters;

  const todayDate = format(new Date(), 'yyyy-MM-dd');
  const areDatesOk = !fromDate || !toDate || fromDate <= toDate;
  const dateFilled = fromDate || toDate;
  const [lastSelectedId, setLastSelectedId] = useState(undefined);

  // Display them only if the corresponding sport is active
  const activeIds = activeFilters?.data?.map(el => `${el?.id}`);
  const activeCompetitionFilters = sportsComps?.filter(el => activeIds?.includes(`${el?.type}`));

  const getBorderColor = isActive => (isActive ? 'border-secondary' : 'border-mpGrey');

  const firstSportCompChanged = useCallback(node => {
    if (node !== null) {
      node.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
      });
      // eslint-disable-next-line no-param-reassign
      node.style.transition = 'all 0.9s ease';
      node.classList.add('opacity-30');
      setTimeout(() => {
        node?.classList.remove('opacity-30');
      }, 1000);
      setLastSelectedId(undefined);
    }
  }, []);

  const filtersClosedButton = (
    <PlainButton
      className={`flex items-center rounded-full p-2.5 sm:py-3 sm:px-4 border-2 ${getBorderColor(
        areThereActiveFilters,
      )}`}
      onClick={() => {
        fireEvent(GACategories.FIXTURES, GATags.FILTERS_BUTTON);
        setFiltersModal(true);
      }}
    >
      <FilterIcon className="h-6 w-6" />
      <p className="hidden sm:block font-semibold ml-2 capitalize">{i18n.t('fixtures_filter')}</p>
    </PlainButton>
  );

  const sectionTitle = title => (
    <div className="flex items-center text-center pt-4">
      <h3 className={styles?.h3}>{i18n.t(title)}</h3>
    </div>
  );

  const loadMoreButton = ({ type, offset, loadingPage }) => (
    <PlainButton
      onClick={() => {
        fireEvent(GACategories.FIXTURES, GATags.FETCH_MORE_FILTERS, {
          [getFilterType(type)]: getFilterType(type),
        });
        fetchPaginated(type, offset);
      }}
      disabled={loadingPage}
      className={`${loadingPage ? 'bg-transparent' : 'bg-mpGrey'} ${styles.loadMoreButton}`}
    >
      {loadingPage ? (
        <Spinner className="w-5" />
      ) : (
        <>
          <PlusIcon className="w-4 h-4 mr-1" />
          <p>{i18n.t('general.more')}</p>
        </>
      )}
    </PlainButton>
  );

  const filtersSection = (filtersData, onFilterPress, isSelectedSection = false) => (
    <div className="mt-3 gap-2 flex flex-wrap">
      {filtersData?.map((filter, index) => (
        <div className="flex" key={filter?.id}>
          <PlainButton
            onClick={() => onFilterPress(filter)}
            key={filter?.id}
            className={`${styles.filtersButton} ${
              isSelectedSection ? 'bg-black text-white font-normal' : ''
            }`}
          >
            <p>{filter?.name}</p>
            {isSelectedSection && <CloseIcon className="w-4 h-4 ml-2" />}
          </PlainButton>
          {isSelectedSection && filtersData?.length - 1 === index && (
            <PlainButton
              onClick={() =>
                activeFilters?.data?.forEach(f => {
                  dispatch({
                    type: filterActions?.unselectFilter,
                    payload: { filter: f },
                  });
                })
              }
              className="text-xxs font-medium uppercase rounded-full pl-2.5"
            >
              <p>{i18n.t('fixtures_filters_clear_all')}</p>
            </PlainButton>
          )}
        </div>
      ))}
    </div>
  );

  const addFilter = filter => {
    const { type } = filter;
    dispatch({
      type: filterActions?.selectFilter,
      payload: { filter },
    });
    fireEvent(GACategories.FIXTURES, GATags.ADD_FILTER, {
      [getFilterType(type)]: getFilterType(type),
    });
  };

  const setDate = (type, date) => {
    fireEvent(
      GACategories.FIXTURES,
      type === dateTypes?.fromDate ? GATags.FILTER_BY_FROM_DATE : GATags.FILTER_BY_TO_DATE,
    );
    dispatch({
      type: filterActions.setDate,
      payload: { type, date },
    });
  };

  return (
    <div className="flex gap-2 flex-row-reverse">
      {filtersClosedButton}
      <Modal
        open={filtersModal}
        closeModal={() => {
          dispatch({ type: filterActions?.closeUnsaved });
          setFiltersModal(false);
        }}
        title={i18n.t('fixtures_filters')}
        titleClasses="pb-2 md:px-4"
        outerClasses="max-h-full md:max-w-3xl lg:max-w-2xl h-full md:h-5/6 pt-6 md:pt-8 px-4 md:rounded-2xl"
        innerClasses="h-full md:px-4"
        buttons={
          <div className="flex pb-4 pt-3 text-sm gap-4">
            <PlainButton
              className={`${styles?.buttonStyles} bg-mpGrey`}
              onClick={() => {
                fireEvent(GACategories.FIXTURES, GATags.RESET_FILTERS);
                dispatch({ type: filterActions?.resetFilters });
              }}
            >
              {i18n.t('fixtures_filters_reset')}
            </PlainButton>
            <PlainButton
              className={`${styles?.buttonStyles} bg-secondary disabled:opacity-30`}
              onClick={() => {
                fireEvent(GACategories.FIXTURES, GATags.APPLY_FILTERS);
                dispatch({ type: filterActions?.applyFilters });
                setFiltersModal(false);
              }}
              disabled={!areDatesOk}
            >
              {i18n.t('fixtures_filters_apply')}
            </PlainButton>
          </div>
        }
      >
        <h2 className={styles.h2}>{i18n.t('fixtures_filters_date_or_oh')}</h2>
        <p className={styles.p}>{i18n.t('fixtures_filters_date_or_oh_text')}</p>
        <div className="flex flex-col md:flex-row w-full gap-4 my-4">
          <FiltersDateSelector
            text={i18n.t('general.from')}
            minDate={todayDate}
            date={fromDate}
            disabled={Boolean(dateOH)}
            setDate={date => setDate(dateTypes.fromDate, date)}
            error={!areDatesOk}
          />
          <FiltersDateSelector
            text={i18n.t('general.to')}
            minDate={fromDate || todayDate}
            date={toDate}
            disabled={Boolean(dateOH)}
            setDate={date => setDate(dateTypes.toDate, date)}
            error={!areDatesOk}
          />
        </div>
        {!areDatesOk && <p className="text-primaryRed">{i18n.t('fixtures_filters_date_error')}</p>}
        <p className="mx-2 text-center border-b border-mpGrey leading-[0.1em] pt-4">
          <span className="bg-white px-3 font-medium">{i18n.t('general.or')}</span>
        </p>
        <PlainButton
          onClick={() =>
            dispatch({
              type: filterActions.setOpenHours,
              payload: { date: dateOH ? undefined : todayDate },
            })
          }
          className={`flex w-full items-center justify-between mt-8 mb-4 border-2 p-3 rounded-full ${
            dateOH ? ' border-primary' : 'border-mpGrey'
          } ${dateFilled ? 'opacity-40' : ''}`}
        >
          <div className="flex items-center">
            <ClockIcon className="h-6 w-6 mr-2" />
            <p className="font-semibold text-xs md:text-base">
              {i18n.t('fixtures_filters_opening_hours')}
            </p>
          </div>
          <Toggle checked={Boolean(dateOH)} className="pr-0" />
        </PlainButton>
        <h2 className="text-xl pt-4 font-semibold border-t border-mpGrey md:pt-8 md:mt-8">
          {i18n.t('fixtures_filters_sports')}
        </h2>
        <p className="mt-2 mb-1 opacity-60">{i18n.t('fixtures_filters_sports_subtitle')}</p>
        {activeFilters?.data?.length > 0 &&
          filtersSection(
            activeFilters?.data,
            filter => {
              fireEvent(GACategories.FIXTURES, GATags.REMOVE_FILTER, {
                [getFilterType(filter.type)]: getFilterType(filter.type),
              });
              dispatch({
                type: filterActions?.unselectFilter,
                payload: { filter },
              });
            },
            true,
          )}

        {favouriteFilters?.data?.length > 0 && (
          <>
            {sectionTitle('fixtures_filters_sports_favourites')}
            {filtersSection(favouriteFilters?.data, filter => addFilter(filter))}
          </>
        )}
        {sectionTitle('fixtures_filters_sports_sports')}
        {filtersSection(sportFilters?.data, filter => {
          // If it's the last item in the section, fetch more
          if (sportFilters?.data?.length === 1) {
            fetchPaginated(filter?.type, sportFilters?.offset);
          }
          fetchPaginated(filter?.id);
          setLastSelectedId(filter?.id);
          addFilter(filter);
        })}
        {sportFilters?.hasMorePages && loadMoreButton(sportFilters)}

        {activeCompetitionFilters?.length > 0 && (
          <>
            {sectionTitle('fixtures_filters_sports_comps')}
            {activeCompetitionFilters?.map((sport, index) => {
              // If it exists, there's at least one competition
              const { name, id } = sport?.data?.[0]?.sport || {};

              return name ? (
                // If the DOM element changes, the fn will be called
                <div
                  key={name}
                  ref={index === 0 && lastSelectedId === id ? firstSportCompChanged : undefined}
                >
                  <h3 className="uppercase opacity-60 text-xs mt-4 font-bold">{name}</h3>
                  {filtersSection(sport?.data, filter => {
                    // If it's the last item in the section, fetch more
                    if (sport?.data?.length === 1) fetchPaginated(id, sport?.offset);
                    addFilter(filter);
                  })}
                  {sport?.hasMorePages && loadMoreButton(sport)}
                </div>
              ) : null;
            })}
          </>
        )}
      </Modal>
    </div>
  );
};

FixturesFilters.propTypes = {
  filters: PropTypes.shape().isRequired,
  dispatch: PropTypes.func.isRequired,
  fetchPaginated: PropTypes.func.isRequired,
  areThereActiveFilters: PropTypes.bool.isRequired,
};

export default FixturesFilters;

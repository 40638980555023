import * as consts from './constants';

export const initialState = {
  SMnumberOfFixtures: 0,
  SMformat: null,
  fixtures: [],
  theme: 0,
  isUploading: false,
  content: [],
  offset: 15,
  limit: 15,
  postedPosts: [],
  scheduledPosts: [],
  hasMorePostedPosts: false,
  hasMoreScheduledPosts: false,
  isFetchingPostedPosts: false,
  isFetchingScheduledPosts: false,
  isDeletingPost: false,
  hasMorePages: false,
  isFetchingContent: false,
  isFetchingNextContent: false,
  pages: [],
  isFetchingPages: false,
  isFetchingTwitterAccount: false,
  isFetchingFacebookAccount: false,
  accounts: null,
};

function SocialMediaReducer(state = initialState, action) {
  switch (action.type) {
    case consts.ADD_SMFIXTURE:
      return {
        ...state,
        fixtures: state.fixtures.concat([action.payload.fixture]),
      };
    case consts.SET_SMFIXTURES:
      return {
        ...state,
        fixtures: action.payload.fixtures,
      };
    case consts.REMOVE_SMFIXTURE:
      return {
        ...state,
        fixtures: state.fixtures.filter(item => item.id !== action.payload.fixture.id),
      };
    case consts.REMOVE_BULK_SMFIXTURE:
      return {
        ...state,
        fixtures: state.fixtures.filter((_, i) => i < action.payload.desiredNumberOfFixtures),
      };
    case consts.UPDATE_SMTHEME:
      return {
        ...state,
        theme: action.payload.theme,
      };
    case consts.POST_PUBLISH_PENDING:
      return {
        ...state,
        isUploading: true,
      };
    case consts.POST_PUBLISH_SUCCESS:
      return {
        ...state,
        isUploading: false,
        fixtures: initialState.fixtures,
        theme: initialState.theme,
      };
    case consts.POST_PUBLISH_ERROR:
      return {
        ...state,
        isUploading: false,
      };
    case consts.CLEAR_SOCIAL_MEDIA:
      return initialState;
    case consts.FETCH_CONTENT_PENDING:
      return {
        ...state,
        offset: initialState.offset,
        isFetchingContent: true,
      };
    case consts.FETCH_CONTENT_SUCCESS:
      return {
        ...state,
        isFetchingContent: false,
        content: action.payload.data.result,
        hasMorePages: action.payload.data.matches > action.payload.data.result.length,
      };
    case consts.FETCH_CONTENT_ERROR:
      return {
        ...state,
        isFetchingContent: false,
      };
    case consts.FETCH_NEXT_CONTENT_PENDING:
      return {
        ...state,
        isFetchingNextContent: true,
      };
    case consts.FETCH_NEXT_CONTENT_SUCCESS: {
      const newContent = state.content.concat(action.payload.data.result);
      return {
        ...state,
        isFetchingNextContent: false,
        content: newContent,
        offset: state.offset + state.limit,
        hasMorePages: action.payload.data.matches > newContent.length,
      };
    }
    case consts.FETCH_NEXT_CONTENT_ERROR:
      return {
        ...state,
        isFetchingNextContent: false,
      };
    case consts.FETCH_SCHEDULED_POSTS_PENDING:
      return {
        ...state,
        isFetchingScheduledPosts: true,
      };
    case consts.FETCH_SCHEDULED_POSTS_SUCCESS: {
      return {
        ...state,
        scheduledPosts: action.payload.data.result,
        hasMoreScheduledPosts: action.payload.data.result.length < action.payload.data.matches,
        isFetchingScheduledPosts: false,
      };
    }
    case consts.FETCH_SCHEDULED_POSTS_ERROR:
      return {
        ...state,
        isFetchingScheduledPosts: false,
      };

    case consts.FETCH_NEXT_SCHEDULED_POSTS_PENDING:
      return {
        ...state,
        isFetchingScheduledPosts: true,
      };
    case consts.FETCH_NEXT_SCHEDULED_POSTS_SUCCESS: {
      const postsCopy = [...state.scheduledPosts];
      const scheduledPosts = postsCopy.concat(action.payload.data.result);
      return {
        ...state,
        scheduledPosts,
        hasMoreScheduledPosts: scheduledPosts.length < action.payload.data.matches,
        isFetchingScheduledPosts: false,
      };
    }
    case consts.FETCH_NEXT_SCHEDULED_POSTS_ERROR:
      return {
        ...state,
        isFetchingScheduledPosts: false,
      };

    case consts.FETCH_POSTED_POSTS_PENDING:
      return {
        ...state,
        isFetchingPostedPosts: true,
      };
    case consts.FETCH_POSTED_POSTS_SUCCESS: {
      return {
        ...state,
        postedPosts: action.payload.data.result,
        hasMorePostedPosts: action.payload.data.result.length < action.payload.data.matches,
        isFetchingPostedPosts: false,
      };
    }
    case consts.FETCH_POSTED_POSTS_ERROR:
      return {
        ...state,
        isFetchingPostedPosts: false,
      };

    case consts.FETCH_NEXT_POSTED_POSTS_PENDING:
      return {
        ...state,
        isFetchingPostedPosts: true,
      };
    case consts.FETCH_NEXT_POSTED_POSTS_SUCCESS: {
      const postsCopy = [...state.postedPosts];
      const postedPosts = postsCopy.concat(action.payload.data.result);
      return {
        ...state,
        postedPosts,
        hasMorePostedPosts: postedPosts.length < action.payload.data.matches,
        isFetchingPostedPosts: false,
      };
    }
    case consts.FETCH_NEXT_POSTED_POSTS_ERROR:
      return {
        ...state,
        isFetchingPostedPosts: false,
      };

    case consts.DELETE_POST_PENDING:
      return {
        ...state,
        isDeletingPost: true,
      };
    case consts.DELETE_POST_SUCCESS:
      return {
        ...state,
        scheduledPosts: state.scheduledPosts.filter(p => p.id !== action.payload),
        isDeletingPost: false,
      };
    case consts.DELETE_POST_ERROR:
      return {
        ...state,
        isDeletingPost: false,
      };
    case consts.FETCH_PAGES_START:
      return {
        ...state,
        isFetchingPages: true,
      };
    case consts.FETCH_PAGES_SUCCESS:
      return {
        ...state,
        isFetchingPages: false,
        pages: action.payload,
      };
    case consts.FETCH_PAGES_ERROR:
      return {
        ...state,
        isFetchingPages: false,
      };
    case consts.FETCH_ACCOUNTS_START:
      return {
        ...state,
        isFetchingTwitterAccount: action.payload.twitter || false,
        isFetchingFacebookAccount: action.payload.facebook || false,
      };
    case consts.FETCH_ACCOUNTS_SUCCESS:
      return {
        ...state,
        isFetchingTwitterAccount: false,
        isFetchingFacebookAccount: false,
        accounts: action.payload,
      };
    case consts.FETCH_ACCOUNTS_ERROR:
      return {
        ...state,
        isFetchingTwitterAccount: false,
        isFetchingFacebookAccount: false,
      };
    case consts.SET_SM_FORMAT:
      return {
        ...state,
        SMformat: action.payload,
      };
    case consts.SET_SM_NUMBER_OF_FIXTURES:
      return {
        ...state,
        SMnumberOfFixtures: action.payload,
      };
    default:
      return state;
  }
}

export default SocialMediaReducer;

import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';

const Input = ({
  className,
  size,
  showError,
  onChange,
  type,
  value,
  errorMessage,
  maxLength,
  onBlur,
  id,
  label,
  ...rest
}) => (
  <>
    {label && (
      <label htmlFor={id} className="block font-bold mb-2">
        {label}
      </label>
    )}
    <input
      type={type}
      onBlur={onBlur}
      className={`${className} Input_${size} ${showError ? 'Input_error' : ''}`}
      value={value}
      onChange={e => {
        if (maxLength && e.target.value.length > maxLength) {
          return null;
        }
        return onChange(e.target.value);
      }}
      id={id}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
    />
    {showError && errorMessage !== '' && <p className="Input_errorText">{errorMessage}</p>}
  </>
);

Input.propTypes = {
  type: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  size: PropTypes.oneOf(['full', 'single', 'double']),
  showError: PropTypes.bool,
  onChange: PropTypes.func,
  className: PropTypes.string,
  errorMessage: PropTypes.string,
  maxLength: PropTypes.number,
  id: PropTypes.string,
  label: PropTypes.string,
  onBlur: PropTypes.func,
};

Input.defaultProps = {
  value: '',
  onBlur: undefined,
  maxLength: null,
  type: 'string',
  size: 'full',
  showError: false,
  className: '',
  onChange: () => {},
  errorMessage: '',
  id: undefined,
  label: '',
};

export default Input;

import React from 'react';
import i18n from 'i18next';
import './styles.scss';
import PropTypes from 'prop-types';
import Button from '../../../components/Button/Button';
import ImageUpload from '../../../components/ImageUpload/ImageUpload';
import ColorPicker from './ColorPicker/ColorPicker';
import InlineColorSelector from './InlineColorSelector/InlineColorSelector';
import Dropdown from '../../../components/Dropdown/Dropdown';
import Input from '../../../components/Input/Input';
import { getBase64image, textColors } from './AssetEditorUtils';
import PageHeader from '../../../components/PageHeader/PageHeader';
import LoadingAnimation from '../../../components/LoadingAnimation/LoadingAnimation';
import SplitViewWithTabs from '../../../components/SplitViewWithTabs/SplitViewWithTabs';
import Slider from './Slider';
import { fireEvent, GACategories, GATags } from '../../../utils/trackingHelper';

const AssetEditorWrapper = ({
  onChange,
  state,
  isCompetitionEdit,
  fonts,
  children,
  error,
  hasChanges,
  onSave,
  onDefault,
  isLoading,
  title,
  optionalComponent,
}) => {
  const { CUSTOM_ASSET } = GACategories;
  const {
    secondaryTextColor,
    backgroundColor,
    textColor,
    logo,
    logoScale,
    fontSize,
    headline,
    textFont,
    secondaryTextFont,
    defaultimage,
    defaultlogo,
  } = state;

  const imageKey = isCompetitionEdit ? 'backgroundImage' : 'image';

  const assetTitleCharLimit = 30;

  const AEWButton = (text, onClick, marginTop = false) => (
    <Button
      className={`w-52 font-semibold bg-secondary ${marginTop ? 'mt-4' : ''} ${
        !hasChanges ? 'opacity-20' : ''
      }`}
      disabled={!hasChanges}
      onClick={onClick}
    >
      {text}
    </Button>
  );

  const selectedFont = font => ({
    key: font,
    value: fonts.find(({ identifier }) => identifier === font)?.name,
  });

  return (
    <>
      <PageHeader smallPad title={title} />
      <SplitViewWithTabs
        left={
          <div className="AssetEditorWrapper_templateEditor">
            {optionalComponent}
            {!isCompetitionEdit && (
              <>
                <h3 className="AssetEditorWrapper_title">
                  {i18n.t('marketing.bespoke.bg_colour')}
                </h3>
                <ColorPicker
                  color={backgroundColor}
                  onChange={val => {
                    fireEvent(CUSTOM_ASSET, GATags.CHANGE_BACKGROUND);
                    onChange({ backgroundColor: val.hex });
                  }}
                />
              </>
            )}
            <div className="AssetEditorWrapper_divider" />
            <h3 className="AssetEditorWrapper_title">
              {i18n.t('marketing.bespoke.subTitle.font')}
            </h3>
            <div className="flex">
              <div>
                <p className="text-standardGrey">
                  {i18n.t('marketing.branding_assets.font_title')}
                </p>
                <Dropdown
                  buttonStyle="AssetEditorWrapper_darkInput"
                  selected={selectedFont(textFont)}
                  data={fonts.map(font => ({ key: font.identifier, value: font.name }))}
                  onClickItem={({ key }) => {
                    fireEvent(CUSTOM_ASSET, GATags.CHANGE_FONT, { key });
                    onChange({ textFont: key });
                  }}
                />
              </div>
              <div className="flex-1 pl-3">
                <p className="text-standardGrey pb-1">{i18n.t('general.colour')}</p>
                <InlineColorSelector
                  selected={textColor}
                  colours={textColors}
                  onSelect={val => {
                    fireEvent(CUSTOM_ASSET, GATags.CHANGE_COLOUR, { val });
                    onChange({ textColor: val });
                  }}
                />
              </div>
            </div>
            {!isCompetitionEdit && (
              <>
                <p className="text-standardGrey">{i18n.t('marketing.bespoke.scale')}</p>
                {fontSize && (
                  <Slider
                    value={fontSize}
                    min={64}
                    max={200}
                    onChange={e => {
                      fireEvent(CUSTOM_ASSET, GATags.CHANGE_SCALE);
                      onChange({ fontSize: e.target.value });
                    }}
                  />
                )}
              </>
            )}
            <div className="flex">
              <div>
                <p className="text-standardGrey">{i18n.t('general_copy')}</p>
                <Dropdown
                  buttonStyle="AssetEditorWrapper_darkInput"
                  selected={selectedFont(secondaryTextFont)}
                  data={fonts.map(font => ({ key: font.identifier, value: font.name }))}
                  onClickItem={({ key }) => {
                    fireEvent(CUSTOM_ASSET, GATags.CHANGE_COPY, { key });
                    onChange({ secondaryTextFont: key });
                  }}
                />
              </div>
              <div className="flex-1 pl-3">
                <p className="text-standardGrey pb-1">{i18n.t('general.colour')}</p>
                <InlineColorSelector
                  selected={secondaryTextColor}
                  colours={textColors}
                  onSelect={val => onChange({ secondaryTextColor: val })}
                />
              </div>
            </div>
            <div className="AssetEditorWrapper_divider" />
            <h3 className="AssetEditorWrapper_title">{i18n.t('marketing.bespoke.themeImage')}</h3>
            {!isCompetitionEdit && (
              <p className="text-standardGrey pb-2">{i18n.t('marketing.bespoke.if_applicable')}</p>
            )}
            <ImageUpload
              sizeLimitMB={3}
              onUpload={f =>
                getBase64image(f, file => {
                  fireEvent(CUSTOM_ASSET, GATags.UPLOAD_IMAGE);
                  onChange({ [imageKey]: file });
                })
              }
              onDelete={
                // do not allow deletion in comp edit.
                isCompetitionEdit || state[imageKey] === null
                  ? null
                  : () => {
                      fireEvent(CUSTOM_ASSET, GATags.DELETE_IMAGE);
                      onChange({ [imageKey]: null });
                    }
              }
              onDefault={
                state[imageKey] === defaultimage
                  ? null
                  : () => onChange({ [imageKey]: defaultimage })
              }
            />
            {state.backgroundImageMessage && (
              <p className="text-standardGrey font-bold">
                <br />
                {i18n.t('marketing.bespoke.image_upload_intro')}
                <br />
                {state.backgroundImageMessage.width}
                <br />
                {state.backgroundImageMessage.height}
              </p>
            )}
            {!isCompetitionEdit && (
              <>
                <h3 className="AssetEditorWrapper_title">{i18n.t('marketing.bespoke.logo')}</h3>
                <p className="text-standardGrey pb-2">
                  {i18n.t('marketing.bespoke.if_applicable')}
                </p>
                <ImageUpload
                  sizeLimitMB={0.2}
                  onUpload={f =>
                    getBase64image(f, file => {
                      fireEvent(CUSTOM_ASSET, GATags.UPLOAD_LOGO);
                      onChange({ logo: file });
                    })
                  }
                  onDelete={
                    logo === null
                      ? null
                      : () => {
                          fireEvent(CUSTOM_ASSET, GATags.DELETE_LOGO);
                          onChange({ logo: null });
                        }
                  }
                  onDefault={logo === defaultlogo ? null : () => onChange({ logo: defaultlogo })}
                />
                <p className="text-standardGrey py-2">{i18n.t('marketing.bespoke.scale')}</p>
                {logoScale && (
                  <Slider
                    disabled={!logo}
                    value={logoScale * 100}
                    onChange={e => onChange({ logoScale: e.target.value / 100 })}
                  />
                )}
                <div className="AssetEditorWrapper_divider" />
              </>
            )}
            {!isCompetitionEdit && (
              <>
                <h3 className="AssetEditorWrapper_title">
                  {i18n.t('marketing.bespoke.headline_text')}
                </h3>
                <p className="text-standardGrey">{i18n.t('marketing.bespoke.if_applicable')}</p>
                <Input
                  maxLength={assetTitleCharLimit}
                  errorMessage={
                    headline?.length === assetTitleCharLimit
                      ? i18n
                          .t('marketing.bespoke.title_error_message', {
                            maxCharLength: assetTitleCharLimit,
                          })
                          .toUpperCase()
                      : ''
                  }
                  className="AssetEditorWrapper_darkInput"
                  value={headline}
                  placeholder={i18n.t('marketing.bespoke.text')}
                  onChange={val => {
                    fireEvent(CUSTOM_ASSET, GATags.ADD_HEADLINE);
                    onChange({ headline: val });
                  }}
                />
              </>
            )}
          </div>
        }
        right={
          <div className="AssetEditorWrapper_previews">
            {error && <p className="text-primaryRed">{error}</p>}
            <h3 className="AssetEditorWrapper_title_dark">{i18n.t('marketing.preview')}</h3>
            {!isLoading ? children : <LoadingAnimation />}
            <div className="flex flex-1 flex-col items-center pt-4">
              {AEWButton(i18n.t('general.save'), onSave)}
              {AEWButton(i18n.t('general.discard'), onDefault, true)}
            </div>
          </div>
        }
      />
      {/* invisible fonts - necessary for canvas's to render */}
      {fonts &&
        fonts.length &&
        fonts.map(font => (
          <p
            key={font.identifier}
            style={{ fontFamily: font.identifier }}
            className="AssetEditorWrapper_invisibleFont"
          >
            {font.identifier}.
          </p>
        ))}
    </>
  );
};

AssetEditorWrapper.propTypes = {
  onChange: PropTypes.func.isRequired,
  state: PropTypes.shape({
    secondaryTextColor: PropTypes.string,
    backgroundColor: PropTypes.string,
    textColor: PropTypes.string,
    image: PropTypes.string,
    backgroundImage: PropTypes.string,
    logo: PropTypes.string,
    logoScale: PropTypes.number,
    fontSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    headline: PropTypes.string,
    textFont: PropTypes.string,
    secondaryTextFont: PropTypes.string,
    defaultimage: PropTypes.string,
    defaultlogo: PropTypes.string,
    backgroundImageMessage: PropTypes.shape(),
  }).isRequired,
  isCompetitionEdit: PropTypes.bool,
  fonts: PropTypes.arrayOf(PropTypes.shape()),
  children: PropTypes.node,
  error: PropTypes.string,
  hasChanges: PropTypes.bool,
  onSave: PropTypes.func.isRequired,
  onDefault: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  title: PropTypes.string.isRequired,
  optionalComponent: PropTypes.node,
};

AssetEditorWrapper.defaultProps = {
  isCompetitionEdit: false,
  isLoading: false,
  children: null,
  error: null,
  hasChanges: false,
  fonts: [],
  optionalComponent: null,
};

export default AssetEditorWrapper;

import React, { useEffect } from 'react';
import i18next from 'i18next';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import Modal from './Modal';
import { GATags, fireEvent, GACategories } from '../../utils/trackingHelper';
import { historyProps } from '../../utils/customPropTypes';
import paths from '../../routes/paths';
import PlainButton from '../../components/PlainButton/PlainButton';

const buttonStyles = 'font-semibold flex-1 text-xl rounded-lg py-3';

const FixtureLimitModal = ({ open, onClose, history, venueId }) => {
  useEffect(() => {
    if (open) {
      fireEvent(GACategories.TIER, GATags.FIXTURE_LIMIT_MODAL_OPEN, { venueId });
    }
  }, [open, venueId]);

  return (
    <Modal
      closeModal={onClose}
      open={open}
      title={i18next.t('fixtures.limit_modal.header')}
      buttons={
        <div className="flex lg:flex-col pt-3 text-sm gap-2">
          <PlainButton className={`${buttonStyles} bg-mpGrey`} onClick={onClose}>
            {i18next.t('general.cancel')}
          </PlainButton>
          <PlainButton
            className={`${buttonStyles} bg-secondary`}
            onClick={() => {
              fireEvent(GACategories.TIER, GATags.FIXTURE_LIMIT_MODAL_UPGRADE, { venueId });
              history.push(paths.subscriptions.path);
            }}
          >
            {i18next.t('general.upgrade')}
          </PlainButton>
        </div>
      }
    >
      <p className="whitespace-pre-line text-xl pt-4 pb-8">
        {i18next.t('fixtures.limit_modal.text')}
      </p>
    </Modal>
  );
};

FixtureLimitModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  history: historyProps.isRequired,
  open: PropTypes.bool,
  venueId: PropTypes.number.isRequired,
};
FixtureLimitModal.defaultProps = {
  open: false,
};

export default withRouter(FixtureLimitModal);

import PropTypes from 'prop-types';
import React from 'react';

const CalendarIcon = ({ className, isActive }) => (
  <svg viewBox="0 0 24 24" className={`fill-current stroke-current ${className}`} fill="none">
    {isActive ? (
      <>
        <rect
          width="15.2727"
          height="2.20901"
          transform="matrix(1 0 -0.156434 0.987688 5.84717 4)"
          strokeWidth="2.18182"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M21.5336 8.36377H4.05183L2.55147 17.8367C2.35232 19.0941 3.27615 20.1757 4.5492 20.1757H17.6401C18.7792 20.1757 19.801 19.3031 19.9792 18.178L21.5336 8.36377ZM6.18429 11.9971C6.2808 11.4186 6.82849 10.9547 7.41504 10.9547C8.01921 10.9547 8.43542 11.4461 8.33602 12.042L8.29425 12.2924C8.19774 12.871 7.65005 13.3349 7.0635 13.3349C6.45933 13.3349 6.04312 12.8435 6.14252 12.2476L6.18429 11.9971ZM9.45702 11.9971C9.55353 11.4186 10.1012 10.9547 10.6878 10.9547C11.2919 10.9547 11.7081 11.4461 11.6087 12.042L11.567 12.2924C11.4705 12.871 10.9228 13.3349 10.3362 13.3349C9.73206 13.3349 9.31584 12.8435 9.41525 12.2476L9.45702 11.9971ZM12.7297 11.9971C12.8263 11.4186 13.3739 10.9547 13.9605 10.9547C14.5647 10.9547 14.9809 11.4461 14.8815 12.042L14.8397 12.2924C14.7432 12.871 14.1955 13.3349 13.609 13.3349C13.0048 13.3349 12.5886 12.8435 12.688 12.2476L12.7297 11.9971ZM16.0025 11.9971C16.099 11.4186 16.6467 10.9547 17.2332 10.9547C17.8374 10.9547 18.2536 11.4461 18.1542 12.042L18.1124 12.2924C18.0159 12.871 17.4682 13.3349 16.8817 13.3349C16.2775 13.3349 15.8613 12.8435 15.9607 12.2476L16.0025 11.9971ZM6.18429 15.8153C6.2808 15.2368 6.82849 14.7729 7.41504 14.7729C8.01921 14.7729 8.43542 15.2643 8.33602 15.8602L8.29425 16.1106C8.19774 16.6892 7.65005 17.1531 7.0635 17.1531C6.45933 17.1531 6.04312 16.6617 6.14252 16.0657L6.18429 15.8153ZM9.45702 15.8153C9.55353 15.2368 10.1012 14.7729 10.6878 14.7729C11.2919 14.7729 11.7081 15.2643 11.6087 15.8602L11.567 16.1106C11.4705 16.6892 10.9228 17.1531 10.3362 17.1531C9.73206 17.1531 9.31584 16.6617 9.41525 16.0657L9.45702 15.8153ZM12.7297 15.8153C12.8263 15.2368 13.3739 14.7729 13.9605 14.7729C14.5647 14.7729 14.9809 15.2643 14.8815 15.8602L14.8397 16.1106C14.7432 16.6892 14.1955 17.1531 13.609 17.1531C13.0048 17.1531 12.5886 16.6617 12.688 16.0657L12.7297 15.8153Z"
          stroke="none"
        />
      </>
    ) : (
      <>
        <path
          d="M8.0479 10.9956C7.52801 10.9956 7.0372 11.4113 6.95167 11.9241L6.90989 12.1745C6.82435 12.6873 7.17647 13.1031 7.69637 13.1031C8.21626 13.1031 8.70707 12.6873 8.79261 12.1745L8.83438 11.9241C8.91992 11.4113 8.5678 10.9956 8.0479 10.9956Z"
          stroke="none"
        />
        <path
          d="M9.67896 11.9241C9.7645 11.4113 10.2553 10.9956 10.7752 10.9956C11.2951 10.9956 11.6472 11.4113 11.5617 11.9241L11.5199 12.1745C11.4344 12.6873 10.9436 13.1031 10.4237 13.1031C9.90377 13.1031 9.55165 12.6873 9.63719 12.1745L9.67896 11.9241Z"
          stroke="none"
        />
        <path
          d="M13.5025 10.9956C12.9826 10.9956 12.4918 11.4113 12.4063 11.9241L12.3645 12.1745C12.2789 12.6873 12.6311 13.1031 13.151 13.1031C13.6709 13.1031 14.1617 12.6873 14.2472 12.1745L14.289 11.9241C14.3745 11.4113 14.0224 10.9956 13.5025 10.9956Z"
          stroke="none"
        />
        <path
          d="M15.1336 11.9241C15.2191 11.4113 15.7099 10.9956 16.2298 10.9956C16.7497 10.9956 17.1018 11.4113 17.0163 11.9241L16.9745 12.1745C16.889 12.6873 16.3982 13.1031 15.8783 13.1031C15.3584 13.1031 15.0062 12.6873 15.0918 12.1745L15.1336 11.9241Z"
          stroke="none"
        />
        <path
          d="M8.0479 14.2685C7.52801 14.2685 7.0372 14.6842 6.95167 15.197L6.90989 15.4475C6.82435 15.9603 7.17647 16.376 7.69637 16.376C8.21626 16.376 8.70707 15.9603 8.79261 15.4475L8.83438 15.197C8.91992 14.6842 8.5678 14.2685 8.0479 14.2685Z"
          stroke="none"
        />
        <path
          d="M9.67896 15.197C9.7645 14.6842 10.2553 14.2685 10.7752 14.2685C11.2951 14.2685 11.6472 14.6842 11.5617 15.197L11.5199 15.4475C11.4344 15.9603 10.9436 16.376 10.4237 16.376C9.90377 16.376 9.55165 15.9603 9.63719 15.4475L9.67896 15.197Z"
          stroke="none"
        />
        <path
          d="M13.5025 14.2685C12.9826 14.2685 12.4918 14.6842 12.4063 15.197L12.3645 15.4475C12.2789 15.9603 12.6311 16.376 13.151 16.376C13.6709 16.376 14.1617 15.9603 14.2472 15.4475L14.289 15.197C14.3745 14.6842 14.0224 14.2685 13.5025 14.2685Z"
          stroke="none"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          stroke="none"
          d="M4.46901 3.37268H21.9236L19.705 17.3799C19.4223 19.1651 17.7278 20.6123 15.9203 20.6123H5.01124C3.20377 20.6123 1.96773 19.1651 2.25048 17.3799L4.46901 3.37268ZM4.4323 17.3799L5.88288 8.22133H18.9738L17.5232 17.3799C17.429 17.975 16.8641 18.4574 16.2616 18.4574H5.35256C4.75006 18.4574 4.33805 17.975 4.4323 17.3799Z"
        />
      </>
    )}
  </svg>
);

CalendarIcon.propTypes = {
  className: PropTypes.string,
  isActive: PropTypes.bool,
};

CalendarIcon.defaultProps = {
  className: '',
  isActive: false,
};

export default CalendarIcon;

import React from 'react';
import PropTypes from 'prop-types';
import CloseIcon from '../../components/Icons/CloseIcon';
import PlainButton from '../../components/PlainButton/PlainButton';
import MPModal from '../../components/MPModal/MPModal';

const Modal = ({
  outerClasses,
  innerClasses,
  title,
  buttons,
  open,
  closeModal,
  children,
  titleClasses,
}) => (
  <MPModal open={open} onClose={closeModal} className={`bg-white flex flex-col ${outerClasses}`}>
    <PlainButton
      className="absolute top-4 right-4 p-2 bg-mpGrey rounded-full z-50 md:top-8 md:right-8"
      onClick={closeModal}
    >
      <CloseIcon className="w-6 h-6" />
    </PlainButton>
    <p className={`headingFont text-4xl ${titleClasses}`}>{title}</p>
    <div className={`overflow-scroll noScrollBar ${innerClasses}`}>{children}</div>
    {buttons}
  </MPModal>
);

Modal.propTypes = {
  open: PropTypes.bool.isRequired,
  buttons: PropTypes.node,
  closeModal: PropTypes.func.isRequired,
  children: PropTypes.node,
  title: PropTypes.string.isRequired,
  outerClasses: PropTypes.string,
  innerClasses: PropTypes.string,
  titleClasses: PropTypes.string,
};

Modal.defaultProps = {
  children: undefined,
  outerClasses: 'max-w-md rounded-2xl p-8 lg:p-10',
  innerClasses: '',
  buttons: null,
  titleClasses: 'pt-8',
};

export default Modal;

import React, { useEffect, useState } from 'react';
import i18n from 'i18next';
import PropTypes from 'prop-types';
import { OpenSelector, TimeInput } from './Selector';
import SecondaryButton from '../SecondaryButton/SecondaryButton';

const [noneKey, closedKey, openKey, open24Key] = ['none', 'closed', 'open', 'open24'];
const options = [
  { key: noneKey, value: i18n.t('general.select') },
  { key: closedKey, value: i18n.t('editProfile.general.closed') },
  { key: openKey, value: i18n.t('editProfile.general.open') },
  { key: open24Key, value: i18n.t('editProfile.general.open24') },
];

const OpenHoursSelector = ({
  text,
  showCopyDayButton,
  copyDayButtonAction,
  valueFrom,
  valueTo,
  setDay,
}) => {
  const [none, closed, open, open24] = options;
  const [selectedStatus, setSelectedStatus] = useState(none);

  const midnight = '00:00';
  const isClosed = valueFrom === null && valueTo === null;
  const isOpen24 = valueFrom === midnight && valueTo === midnight;
  const isOpen = selectedStatus === open || selectedStatus === open24;

  useEffect(() => {
    if (isClosed) {
      setSelectedStatus(closed);
    }
    if (isOpen24) {
      setSelectedStatus(open24);
    }
    if (!isOpen24 && !isClosed) {
      setSelectedStatus(open);
    }
  }, [closed, isClosed, isOpen24, open, open24]);

  const onStatusSelectorHandler = val => {
    if (val === open.value) {
      setDay('11:00', '23:00');
    }
    if (val === open24.value) {
      setDay(midnight, midnight);
    }
    if (val === closed.value) {
      setDay(null, null);
    }
  };

  return (
    <div className="flex flex-col mb-2">
      <div className="flex items-center">
        <p className="uppercase font-bold w-10 shrink-0 text-xs md:text-base md:mr-2">{text}</p>
        <OpenSelector
          options={options}
          selected={selectedStatus.value}
          onValueChange={val => {
            onStatusSelectorHandler(val);
            setSelectedStatus({ ...selectedStatus, value: val });
          }}
          emptyValue={none}
          isOpen={isOpen}
        />

        {isOpen && (
          <div className="flex items-center w-3/5">
            <TimeInput value={valueFrom} onChange={val => setDay(val, valueTo)} />
            <p className="mx-1 lowercase text-xs md:text-base font-medium opacity-40">
              {i18n.t('general.to')}
            </p>
            <TimeInput value={valueTo} onChange={val => setDay(valueFrom, val)} />
          </div>
        )}
        {isClosed && (
          <div className="flex justify-center items-center h-12 border border-mpGrey rounded-lg w-3/5">
            -
          </div>
        )}
      </div>

      {showCopyDayButton && (
        <SecondaryButton
          label={i18n.t('editProfile.general.applyToOthers')}
          onClick={copyDayButtonAction}
          className="mb-1 mt-2 py-3 text-xs md:text-base font-semibold rounded-full border-mpGrey"
          uppercase={false}
        />
      )}
    </div>
  );
};

OpenHoursSelector.propTypes = {
  text: PropTypes.string,
  valueFrom: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  valueTo: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  showCopyDayButton: PropTypes.bool.isRequired,
  copyDayButtonAction: PropTypes.func.isRequired,
  setDay: PropTypes.func.isRequired,
};

OpenHoursSelector.defaultProps = {
  text: '',
  valueTo: '',
  valueFrom: '',
};

export default OpenHoursSelector;

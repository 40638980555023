import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import i18n from 'i18next';
import PlainButton from '../PlainButton/PlainButton';

const styles = {
  container:
    'relative flex flex-col border-2 rounded-2xl p-8 h-min lg:h-auto min-w-[258px] sm:min-w-[288px] lg:max-w-[330px] transition-all',
  selected: 'border-primary bg-secondaryLight',
  notSelected: 'border-mpGrey bg-white',
  notCentered: 'mt-4 sm:mt-0',
  badge:
    'absolute bg-blue -top-1 -right-1 rounded-tr-2xl rounded-bl-2xl py-2 px-4 text-white font-bold',
  btn: 'font-semibold py-3 rounded-lg',
};

const Tier = ({
  plan,
  selected,
  onSelect,
  selectedText,
  unSelectedText,
  priceField,
  priceText,
}) => {
  const { country, isMostPopular, name, description, details } = plan || {};
  const [isCentered, setIsCentered] = useState(false);
  const ref = useRef(null);

  const handleMobileScroll = useCallback(() => {
    const tierRect = ref.current.getBoundingClientRect();
    setIsCentered(tierRect.left >= 0 && tierRect.right <= window.innerWidth);
  }, [ref]);

  useEffect(() => {
    const refC = ref?.current;
    if (refC) {
      const { parentNode } = refC || {};
      const tierRect = refC.getBoundingClientRect();
      const parentNodeRect = parentNode.getBoundingClientRect();

      if (isMostPopular) {
        // Calculate the position to center the most popular tier
        const offset = (parentNodeRect.width - tierRect.width) / 2;
        const scrollTo = tierRect.left - parentNodeRect.left + parentNode.scrollLeft - offset;
        parentNode.scrollTo({ left: scrollTo, behavior: 'smooth' });
      }

      window.addEventListener('resize', handleMobileScroll);
      parentNode.addEventListener('scroll', handleMobileScroll);
    }

    return () => {
      window.removeEventListener('resize', handleMobileScroll);
      refC?.parentNode.removeEventListener('scroll', handleMobileScroll);
    };
  }, [isMostPopular, ref, handleMobileScroll]);

  const getPrice = () => {
    if (plan[priceField] > 0) {
      return (
        <>
          <p className="text-5xl font-bold">
            {i18n.t('general.price_with_currency', {
              currency: country?.currency?.symbol || i18n.t('general.currency'),
              price: plan[priceField],
            })}
          </p>
          <p className="pl-2">/{priceText}</p>
        </>
      );
    }
    return (
      <p className="text-5xl font-bold">
        {plan[priceField] === 0 ? i18n.t('subscriptions.free') : 'N/A'}
      </p>
    );
  };

  return (
    <div
      className={`${styles.container} ${selected ? styles.selected : styles.notSelected} ${
        isCentered ? '' : styles.notCentered
      }`}
      data-test-id="tierCard"
      ref={ref}
    >
      {isMostPopular && <p className={styles.badge}>{i18n.t('subscriptions.badge')}</p>}
      <h3 className="text-3xl font-bold">{name}</h3>
      <div className="flex py-4">{getPrice()}</div>

      <PlainButton
        className={`${styles.btn} ${selected ? 'bg-lightGrey text-standardGrey' : 'bg-secondary'}`}
        testId="tierSelector"
        onClick={onSelect}
        disabled={selected || !(plan[priceField] >= 0)}
      >
        {selected ? selectedText : unSelectedText}
      </PlainButton>

      <p className="text-base sm:text-xl border-b-2 border-mpGrey py-4">{description}</p>

      <p className="font-bold py-4">{i18n.t('subscriptions_included')}</p>
      <p className="text-sm flex-1 whitespace-pre-line">{details}</p>
    </div>
  );
};

Tier.propTypes = {
  plan: PropTypes.shape().isRequired,
  selected: PropTypes.bool.isRequired,
  onSelect: PropTypes.func.isRequired,
  selectedText: PropTypes.string.isRequired,
  unSelectedText: PropTypes.string.isRequired,
  priceField: PropTypes.string.isRequired,
  priceText: PropTypes.string.isRequired,
};

export default Tier;

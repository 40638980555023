import React from 'react';

const Projector = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.94987 8.45827L3.95054 8.45828H22.3214L22.3221 8.45827C22.3285 8.45822 22.3765 8.45786 22.4233 8.46169C22.4822 8.4665 22.6061 8.48154 22.7347 8.5521C22.8852 8.63463 22.9967 8.76632 23.0534 8.9283C23.1018 9.06678 23.0963 9.19146 23.0913 9.25034C23.0874 9.29721 23.0791 9.34443 23.078 9.35073L23.0779 9.35139L21.6115 18.1427H1.42969L2.89611 9.35139L2.89621 9.35073C2.89722 9.34443 2.90472 9.29721 2.91643 9.25034C2.93112 9.19146 2.96717 9.06678 3.0618 8.9283C3.17249 8.76632 3.32797 8.63463 3.50595 8.5521C3.65812 8.48154 3.78702 8.4665 3.84752 8.46169C3.89568 8.45786 3.94349 8.45822 3.94987 8.45827ZM4.41222 10.0723L3.33528 16.5286H20.2444L21.3213 10.0723H4.41222Z"
      fill="black"
    />
    <path
      d="M18.5527 10.8645C18.2553 12.6473 16.5489 14.0926 14.7415 14.0926C12.934 14.0926 11.7098 12.6473 12.0072 10.8645C12.3046 9.08166 14.0109 7.63638 15.8184 7.63638C17.6259 7.63638 18.85 9.08166 18.5527 10.8645Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.6838 8.44341C14.3282 8.44341 13.0484 9.52737 12.8254 10.8645C12.6023 12.2016 13.5205 13.2856 14.8761 13.2856C16.2317 13.2856 17.5114 12.2016 17.7345 10.8645C17.9575 9.52737 17.0394 8.44341 15.6838 8.44341ZM11.189 10.8645C11.5608 8.63595 13.6937 6.82935 15.953 6.82935C18.2124 6.82935 19.7426 8.63595 19.3708 10.8645C18.9991 13.0931 16.8662 14.8997 14.6068 14.8997C12.3475 14.8997 10.8173 13.0931 11.189 10.8645Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.46874 15.1835H5.19601L5.46524 13.5695H8.73797L8.46874 15.1835Z"
      fill="black"
    />
  </svg>
);

export default Projector;

import React, { useState } from 'react';
import i18n from 'i18next';
import { historyProps } from '../../utils/customPropTypes';
import TierList, { FREQUENCY } from '../../components/TierList/TierList';
import UnprotectedLayout from '../UnprotectedLayout/UnprotectedLayout';
import { fireEvent, GACategories, GATags } from '../../utils/trackingHelper';

const PricesPage = ({ history }) => {
  const [frequency, setFrequency] = useState(FREQUENCY.YEARLY);
  return (
    <UnprotectedLayout noPad>
      <div className="bg-offWhite">
        <div className="xl:container mx-auto">
          <TierList
            onSelect={tier => {
              fireEvent(GACategories.TIER, GATags.SIGN_UP);
              if (tier) {
                history.push({
                  pathname: `/sign-up`,
                  state: { tier, frequency },
                });
              }
            }}
            unSelectedText={i18n.t('subscriptions.getStarted')}
            currentFrequency={frequency}
            onChangeFrequency={setFrequency}
          />
        </div>
      </div>
    </UnprotectedLayout>
  );
};

PricesPage.propTypes = {
  history: historyProps.isRequired,
};

export default PricesPage;

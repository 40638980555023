class ServerError extends Error {
  constructor(message, code, error) {
    super();

    // Built using the link below
    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Error#Custom_Error_Types.
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, ServerError);
    }
    this.isServerError = true;
    this.message = message;
    this.code = code;
    this.error = error;
  }
}

export default ServerError;

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import i18n from 'i18next';
import './styles.scss';
import { getIsMobile } from '../../store/app/selectors';
import FixtureList from '../../components/FixtureList/FixtureList';
import { fetchListFixtures, fetchNextListFixtures } from '../../store/fixtures/thunks';
import { fetchThemes, fetchEditableCountryCompetitions } from '../../store/socialMedia/thunks';
import * as smSel from '../../store/socialMedia/selectors';
import * as fixSel from '../../store/fixtures/selectors';
import { getVenue } from '../../store/venue/selectors';
import PageHeader from '../../components/PageHeader/PageHeader';
import RoundedThemeSelector from './RoundedThemeSelector/RoundedThemeSelector';
import { fixtureProps, venueProps, historyProps } from '../../utils/customPropTypes';
import * as actions from '../../store/socialMedia/actions';
import PageTab from '../../components/PageTab/PageTab';
import SecondaryButton from '../../components/SecondaryButton/SecondaryButton';
import routes from '../../routes/paths';
import { fireEvent, GACategories, GATags } from '../../utils/trackingHelper';
import { getServicesEndpoint, userIsOnFanzo } from '../../utils/environment';
import AssetFormatIcon, { types } from '../../components/AssetFormatIcon/AssetFormatIcon';
import BackIcon from '../../components/Icons/BackIcon';
import { assetTypes } from '../../utils/constants';
import Checkbox from '../../components/Checkbox/Checkbox';
import Button from '../../components/Button/Button';
import { useHiddenByPermissions } from '../../utils/CustomHooks';
import PlainButton from '../../components/PlainButton/PlainButton';
import { getURLQuery } from '../../utils/general';

const PostToSocial = ({
  fixtures,
  fetchNextPage,
  isFetchingNext,
  hasMorePages,
  history,
  venue,
  getFixtures,
  retreiveThemes,
  theme,
  smFixtures,
  addFixture,
  removeFixture,
  updateTheme,
  isFetchingFixtures,
  isMobile,
  bulkRemoveFixture,
  format,
  setFormat,
  nbFixtures,
  setNbFixtures,
  fetchCompetitions,
}) => {
  const basicImageEndpoint = `${getServicesEndpoint()}/meta/${venue.id}/social-media/post/preview`;
  const [printerFriendly, setPrinterFriendly] = useState(false);
  const [showTemplate, setShowTemplate] = useState(!isMobile);
  const [showThemeSelection, setShowThemeSelection] = useState(false);
  const [availableNbFixtures, setAvailableNbFixtures] = useState([]);
  const [competitions, setCompetitions] = useState([]);
  const [imageURL, setImageURL] = useState(basicImageEndpoint);
  const [themes, setThemes] = useState(null);

  useHiddenByPermissions();

  useEffect(() => {
    // if preventFixtureFetch is true and the user refreshes the page - fetch again to prevent empty list
    if (!history?.location?.state?.preventFixtureFetch || fixtures.length === 0) {
      getFixtures(venue.id);
    }
    // Do not run when fixture length changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getFixtures, history.location.state, venue.id]);

  useEffect(() => {
    retreiveThemes(venue.id).then(r => setThemes(r));
    fetchCompetitions(venue.id).then(r => setCompetitions(r));
  }, [fetchCompetitions, retreiveThemes, venue.id]);

  useEffect(() => {
    // effect that loads the image whenever theme or smfixtures changes
    setImageURL(
      `${basicImageEndpoint}${getURLQuery({
        themeId: theme || undefined,
        fixtureIds: smFixtures.map(fix => fix.id),
        t: new Date().getTime(),
        printMode: printerFriendly,
      })}`,
    );
  }, [basicImageEndpoint, printerFriendly, smFixtures, theme, venue.id]);

  useEffect(() => {
    // effect that handles the selected theme whenever format and nbfixtures are set
    if (themes?.length > 0 && format && nbFixtures) {
      const availableThemes = themes.filter(
        elm => elm.format === format && parseInt(elm.nbFixtures, 10) === nbFixtures,
      );
      // if theres only 1 theme auto select it
      if (availableThemes.length === 1) {
        updateTheme(availableThemes[0].id);
      } else {
        setShowThemeSelection(availableThemes);
      }
    }
  }, [format, nbFixtures, themes, updateTheme]);

  const updateNoOfFixtures = newNo => {
    setNbFixtures(newNo);
    // for if you change from a 5 to a 1 template to remove the extra 4 fixtures
    if (newNo > nbFixtures) {
      bulkRemoveFixture(newNo);
    }
  };

  useEffect(() => {
    // effect that populates the number of fixtures whenever the format changes
    if (themes) {
      const availableNbOfFixtures = themes
        .filter(elm => elm.format === format)
        .map(elm => parseInt(elm.nbFixtures, 10))
        // remove duplicates
        .filter((item, pos, arr) => arr.indexOf(item) === pos)
        .sort((e1, e2) => (e1 > e2 ? 1 : -1));

      setAvailableNbFixtures(availableNbOfFixtures);

      // if theres only 1 and you're not on the tour select it
      if (availableNbOfFixtures.length === 1) {
        updateNoOfFixtures(availableNbOfFixtures[0]);
      }
    }
    // updateNoOfFixtures and tourState exlcuded cause hacky bugs
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [format, themes]);

  const resetFixturesSelection = () => {
    updateNoOfFixtures(0);
    updateTheme(0);
    setImageURL(basicImageEndpoint);
    setShowThemeSelection(null);
    setPrinterFriendly(false);
  };

  const getCustomizeButton = () => {
    // by default the buton navigates to color Editor
    let onClick = () => {
      fireEvent(GACategories.SOCIAL_MEDIA_CONTENT, GATags.CLICK_CUSTOMISE);
      history.push({
        pathname: routes.marketing.subRoutes.customiseAssets.subRoutes.colorAssets.path,
        state: {
          returnRoute: routes.marketing.subRoutes.postToSocial.path,
          isLandscape: format === types.landscape,
          nbFixtures,
        },
      });
    };
    let buttonDisabled;
    let text = i18n.t('marketing.bespoke.edit_template');

    // if the selected theme is a competition type and fixture.competition
    // is supported => navigate to comp editor.
    if (format && smFixtures && competitions.length) {
      const themeObj = themes?.find(th => th.id === theme);
      const fixtureComp = smFixtures && smFixtures[0] && smFixtures[0].competition;

      const themeType = themeObj?.type;
      const supportedComp =
        fixtureComp && competitions.find(comp => comp.competitionId === fixtureComp.id);
      if (themeType === assetTypes.comp && supportedComp && fixtureComp) {
        onClick = () => {
          fireEvent(GACategories.SOCIAL_MEDIA_CONTENT, GATags.CLICK_CUSTOMISE);
          history.push({
            pathname: `/marketing/customise-assets/competitions/${fixtureComp.id}/edit`,
            state: {
              returnRoute: routes.marketing.subRoutes.postToSocial.path,
              name: fixtureComp.name,
              format: themeObj.format,
            },
          });
        };
      }

      buttonDisabled = themeType === assetTypes.comp && !supportedComp;

      if (buttonDisabled && fixtureComp && !supportedComp) {
        text = i18n.t('marketing.smPost.cantCustomise');
      }
    }

    return (
      <Button
        className={`${buttonDisabled ? 'bg-mpGrey' : 'bg-secondary'} text-sm font-semibold`}
        disabled={!format || !smFixtures.length || buttonDisabled}
        onClick={onClick}
      >
        {text}
      </Button>
    );
  };

  const disableButton = smFixtures.length === 0 || smFixtures.length !== nbFixtures || !imageURL;

  const PostToSocialRight = (
    <div className={`PostToSocial_column_right${showTemplate ? '_active' : ''}`}>
      <div className="PostToSocial_column_template">
        <p className="PostToSocial_column_template_title">{i18n.t('marketing.preview')}</p>
        {imageURL && (
          <img src={imageURL} alt="preview" className="PostToSocial_column_template_image" />
        )}
        <div className="PostToSocial_column_template_bottom">
          <SecondaryButton
            onClick={() => {
              window.open(
                `${getServicesEndpoint()}meta/${venue.id}/social-media/post/download${getURLQuery({
                  fixtureIds: smFixtures.map(elm => elm.id),
                  themeId: theme,
                  printMode: printerFriendly,
                })}`,
              );
              fireEvent(GACategories.SOCIAL_MEDIA_CONTENT, GATags.DOWNLOAD_SM_POSTER, {
                venueId: venue.id,
              });
            }}
            disabled={disableButton}
            label={i18n.t('general_download')}
            style={{ marginBottom: '8px' }}
          />
          {format === types.landscape && (
            <Button
              className={`bg-secondary text-sm font-semibold ${disableButton ? 'opacity-20' : ''}`}
              disabled={disableButton}
              onClick={() => {
                fireEvent(GACategories.SOCIAL_MEDIA_CONTENT, GATags.CLICK_SCHEDULE);
                history.push(routes.marketing.subRoutes.postPublish.path, {
                  preventFixtureFetch: true,
                  content: {
                    fixtures: smFixtures,
                    themeId: theme,
                    link: imageURL,
                  },
                });
              }}
            >
              {i18n.t('marketing.SchedulePost')}
            </Button>
          )}
          {userIsOnFanzo && getCustomizeButton()}
        </div>
      </div>
    </div>
  );
  return (
    <>
      <PageHeader smallPad title={i18n.t('titles.socialMedia')} />
      {isMobile && (
        <>
          <PageTab
            theme="white"
            onClick={() => setShowTemplate(!showTemplate)}
            show={showTemplate}
            badgeContent={`${smFixtures.length}/${nbFixtures}`}
          />
          <PageTab
            theme="black"
            onClick={() => setShowTemplate(!showTemplate)}
            show={showTemplate}
          />
        </>
      )}
      <div className="flex flex-1">
        <div className={`PostToSocial_column${showTemplate ? '_inactive' : ''} black`}>
          <div className="PostToSocial_column_listTitle">
            {(!nbFixtures || !format) && (
              <>
                <div>
                  <p className="PostToSocial_column_listTitle_header">
                    {i18n.t('marketing.format.title')}
                  </p>
                  <div className="PostToSocial_column_listTitle_buttons">
                    {[types.portrait, types.landscape, types.instagram].map(type => (
                      <AssetFormatIcon
                        key={type}
                        type={type}
                        selected={format === type}
                        onClick={() => {
                          fireEvent(GACategories.SOCIAL_MEDIA_CONTENT, GATags.SELECT_MEDIUM, {
                            method: type,
                          });
                          setFormat(type);
                        }}
                      />
                    ))}
                  </div>

                  {format && (
                    <>
                      <div className="PostToSocial_column_listTitle_hr" />
                      <p className="PostToSocial_column_listTitle_desc">
                        {i18n.t('marketing.themes_select')}
                      </p>
                      <div className="PostToSocial_column_listTitle_fixtureButtons">
                        {availableNbFixtures.map(newFixtureNumber => (
                          <RoundedThemeSelector
                            onSelect={() => {
                              if (newFixtureNumber !== nbFixtures) {
                                updateNoOfFixtures(newFixtureNumber);
                                fireEvent(
                                  GACategories.SOCIAL_MEDIA_CONTENT,
                                  GATags.SELECT_NB_FIXTURES,
                                  null,
                                  {
                                    nb: newFixtureNumber,
                                  },
                                );
                              }
                            }}
                            key={newFixtureNumber}
                            number={newFixtureNumber}
                            selected={nbFixtures === newFixtureNumber}
                          />
                        ))}
                      </div>
                    </>
                  )}
                </div>
                <div />
              </>
            )}
            {nbFixtures && format ? (
              <div className="PostToSocial_column_compact">
                <div className="PostToSocial_column_compact_top">
                  <Button className="px-3" onClick={resetFixturesSelection}>
                    <BackIcon className="h-6 w-6 text-secondary" />
                  </Button>
                  <AssetFormatIcon
                    type={format}
                    selected
                    onClick={() => {
                      setFormat(null);
                      resetFixturesSelection();
                    }}
                  />
                  <RoundedThemeSelector
                    onSelect={resetFixturesSelection}
                    number={nbFixtures}
                    selected
                  />
                </div>
                {showThemeSelection && (
                  <>
                    <div className="PostToSocial_column_listTitle_hr" />
                    <div className="PostToSocial_column_listTitle_themeContainer">
                      {showThemeSelection.map(elm => (
                        <PlainButton
                          className={`PostToSocial_column_listTitle_theme${
                            theme === elm.id ? '_selected' : ''
                          }`}
                          key={elm.id}
                          onClick={() => updateTheme(elm.id)}
                        >
                          <img
                            className={`PostToSocial_column_listTitle_theme_image_${format}`}
                            alt={elm.className}
                            src={elm.layoutImage}
                          />
                        </PlainButton>
                      ))}
                    </div>
                  </>
                )}
                {format === types.portrait && (
                  <>
                    <div className="PostToSocial_column_listTitle_hr" />
                    <Checkbox
                      className="text-white ml-4 mb-6"
                      checked={printerFriendly}
                      variant="secondary"
                      onChange={() => {
                        fireEvent(GACategories.SOCIAL_MEDIA_CONTENT, GATags.PRINTER_FRIENDLY);
                        setPrinterFriendly(!printerFriendly);
                      }}
                      label={i18n.t('marketing.postToSocial.printerFriendly')}
                    />
                  </>
                )}

                <div className="PostToSocial_column_listTitle_hr" />
              </div>
            ) : null}
          </div>
          {theme ? (
            <FixtureList
              fixtures={fixtures}
              fetchMore={() => fetchNextPage(venue.id)}
              isFetchingNext={isFetchingNext}
              isFetching={isFetchingFixtures}
              hasMorePages={hasMorePages}
              addDisabled={smFixtures.length === nbFixtures}
              addFixture={fixture => {
                addFixture(fixture);
                if (isMobile && smFixtures.length + 1 === nbFixtures) {
                  setShowTemplate(!showTemplate);
                }
              }}
              deleteFixture={fixture => removeFixture(fixture)}
              selectedFixtures={smFixtures.map(fix => fix.id)}
            />
          ) : null}
        </div>

        {PostToSocialRight}
      </div>
    </>
  );
};

const mapDispatchToProps = dispatch => ({
  getFixtures: venueID => dispatch(fetchListFixtures(venueID)),
  fetchNextPage: venueID => dispatch(fetchNextListFixtures(venueID)),
  retreiveThemes: venueID => dispatch(fetchThemes(venueID)),
  addFixture: fixture => dispatch(actions.addSMFixture(fixture)),
  removeFixture: fixture => dispatch(actions.removeSMFixture(fixture)),
  bulkRemoveFixture: desiredNumber => dispatch(actions.bulkRemoveSMFixture(desiredNumber)),
  updateTheme: theme => dispatch(actions.updateSMTheme(theme)),
  setNbFixtures: number => dispatch(actions.setNumberOfFixtures(number)),
  setFormat: number => dispatch(actions.setSMFormat(number)),
  fetchCompetitions: venueId => dispatch(fetchEditableCountryCompetitions(venueId)),
});

const mapStateToProps = state => ({
  fixtures: fixSel.getFixturesSelector(state),
  isFetchingFixtures: fixSel.getIsFetching(state),
  isFetchingNext: fixSel.getIsFetchingNext(state),
  hasMorePages: fixSel.getHasMorePages(state),
  venue: getVenue(state),
  smFixtures: smSel.getSMFixtures(state),
  theme: smSel.getSMTheme(state),
  isMobile: getIsMobile(state),
  nbFixtures: smSel.getSMnumberOfFixtures(state),
  format: smSel.getSMformat(state),
});

PostToSocial.propTypes = {
  fixtures: PropTypes.arrayOf(fixtureProps).isRequired,
  fetchNextPage: PropTypes.func.isRequired,
  isFetchingNext: PropTypes.bool.isRequired,
  hasMorePages: PropTypes.bool.isRequired,
  venue: venueProps.isRequired,
  getFixtures: PropTypes.func.isRequired,
  retreiveThemes: PropTypes.func.isRequired,
  theme: PropTypes.number.isRequired,
  smFixtures: PropTypes.arrayOf(fixtureProps).isRequired,
  addFixture: PropTypes.func.isRequired,
  removeFixture: PropTypes.func.isRequired,
  updateTheme: PropTypes.func.isRequired,
  isFetchingFixtures: PropTypes.bool.isRequired,
  isMobile: PropTypes.bool.isRequired,
  bulkRemoveFixture: PropTypes.func.isRequired,
  history: historyProps.isRequired,
  format: PropTypes.oneOf(Object.values(types)),
  setFormat: PropTypes.func.isRequired,
  nbFixtures: PropTypes.number.isRequired,
  setNbFixtures: PropTypes.func.isRequired,
  fetchCompetitions: PropTypes.func.isRequired,
  location: PropTypes.shape().isRequired,
};
PostToSocial.defaultProps = {
  format: null,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PostToSocial));

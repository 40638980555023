import React from 'react';
import i18next from 'i18next';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import Modal from './Modal';
import { historyProps } from '../../utils/customPropTypes';
import paths from '../../routes/paths';
import PlainButton from '../../components/PlainButton/PlainButton';
import { fireEvent, GACategories, GATags } from '../../utils/trackingHelper';

const buttonStyles = 'font-semibold flex-1 text-xl rounded-lg py-3';

const SaveModal = ({ open, onClose, history }) => (
  <Modal
    closeModal={onClose}
    open={open}
    title={i18next.t('fixtures.save_modal.header')}
    buttons={
      <div className="flex flex-col pt-3 text-sm gap-2">
        <PlainButton
          className={`${buttonStyles} bg-secondary`}
          onClick={() => {
            fireEvent(GACategories.FIXTURES, GATags.TO_MARKETING_PAGE);
            history.push(paths.marketing.path);
          }}
        >
          {i18next.t('fixtures.save_modal.promote')}
        </PlainButton>

        <PlainButton
          className={`${buttonStyles} bg-mpGrey`}
          onClick={() => history.push(paths.fixtures.subRoutes.mySchedule.path)}
        >
          {i18next.t('fixtures.save_modal.schedule')}
        </PlainButton>
      </div>
    }
  >
    <p className="whitespace-pre-line text-xl pt-4 pb-8">{i18next.t('fixtures.save_modal.text')}</p>
  </Modal>
);

SaveModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  history: historyProps.isRequired,
  open: PropTypes.bool,
};
SaveModal.defaultProps = {
  open: false,
};

export default withRouter(SaveModal);

import React from 'react';
import PropTypes from 'prop-types';

const AppBar = ({ className, children }) => (
  <nav
    className={`bg-black fixed w-full z-40 flex items-center justify-between p-4 sm:px-6 lg:px-24 ${className}`}
  >
    {children}
  </nav>
);

AppBar.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};
AppBar.defaultProps = {
  children: null,
  className: '',
};

export default AppBar;

import React from 'react';
import PropTypes from 'prop-types';
import PlainButton from '../../../components/PlainButton/PlainButton';

const SocialIcon = ({ Icon, brandColor, onClick, disabled, success, showBadge }) => (
  <PlainButton className="relative" onClick={onClick} disabled={disabled}>
    {showBadge && (
      <div
        className={`absolute -right-1 w-2 h-2 rounded ${success ? 'bg-green' : 'bg-lightRed'}`}
      />
    )}
    <Icon className={`h-7 w-7 ${disabled ? 'text-standardGrey' : brandColor}`} />
  </PlainButton>
);

SocialIcon.propTypes = {
  Icon: PropTypes.func.isRequired,
  brandColor: PropTypes.string.isRequired,
  showBadge: PropTypes.bool,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  success: PropTypes.bool,
};

SocialIcon.defaultProps = {
  showBadge: false,
  onClick: () => {},
  disabled: false,
  success: true,
};

export default SocialIcon;

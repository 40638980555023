import React from 'react';
import PropTypes from 'prop-types';
import upload from './upload.png';

const UploadIcon = ({ className }) => <img className={className} alt="" src={upload} />;

UploadIcon.propTypes = { className: PropTypes.string };
UploadIcon.defaultProps = { className: '' };

export default UploadIcon;

import React from 'react';
import PropTypes from 'prop-types';
import styles from './PlainButton.module.scss';

const PlainButton = ({ className, onClick, children, disabled, testId }) => (
  <button
    data-test-id={testId}
    className={`${styles.button} ${className}`}
    type="button"
    onClick={onClick}
    disabled={disabled}
  >
    {children}
  </button>
);

PlainButton.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.node,
  disabled: PropTypes.bool,
  testId: PropTypes.string,
};

PlainButton.defaultProps = {
  className: '',
  onClick: () => {},
  children: null,
  disabled: false,
  testId: null,
};

export default PlainButton;

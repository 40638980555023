/* eslint-disable react/destructuring-assignment */
import React from 'react';
import PropTypes from 'prop-types';
import {
  BarChart as BarCh,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Rectangle,
  ResponsiveContainer,
} from 'recharts';
import i18next from 'i18next';
import colors from '../../theme/index.module.scss';
import Legend from './Legend';
import { useChartScroll } from './hook';
import ChartScroll from './ChartScroll';

const RectangleOverride = props => {
  const radius = 18;
  const yPos = props.chartHeight * 0.75;

  return (
    <>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <Rectangle {...props} />
      <circle cx={props.x} cy={yPos} r={radius} fill="white" />
      <text x={props.x} y={yPos + 3} textAnchor="middle">
        {props.fixturesListed}
      </text>
    </>
  );
};

RectangleOverride.propTypes = {
  x: PropTypes.number,
  fixturesListed: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  chartHeight: PropTypes.number.isRequired,
};

RectangleOverride.defaultProps = {
  x: 0,
  fixturesListed: 0,
};

const BarChart = ({ data, height, isMobile }) => {
  const { lessProps, moreProps, filteredData, show } = useChartScroll({
    data,
    isMobile,
  });
  return (
    <>
      <ChartScroll lessProps={lessProps} moreProps={moreProps} show={show} />
      <div style={{ width: '100%', height }}>
        <ResponsiveContainer>
          <BarCh
            margin={{ right: isMobile ? 0 : 24, left: isMobile ? 0 : 24 }}
            barGap={0}
            data={filteredData}
          >
            <CartesianGrid vertical={false} />
            <XAxis dataKey="name" />
            <YAxis
              tickLine={false}
              axisLine={false}
              domain={[0, Math.max(...filteredData.map(elm => elm.profileViews))]}
              yAxisId="left"
              dataKey="profileViews"
              orientation="left"
              label={{
                value: i18next.t('stats_barChart_profileViewLegend'),
                angle: -90,
                position: 'insideLeft',
                offset: 15,
              }}
            />
            <YAxis
              label={{
                value: i18next.t('stats_barChart_listViewLegend'),
                angle: 90,
                position: 'insideRight',
                offset: 15,
              }}
              domain={[0, Math.max(...filteredData.map(elm => elm.listViews))]}
              tickLine={false}
              axisLine={false}
              yAxisId="right"
              dataKey="listViews"
              orientation="right"
            />
            <Tooltip cursor={{ fill: colors.secondaryVeryDarkGrey }} />
            <Bar
              name={i18next.t('stats_barChart_profileViews')}
              dataKey="profileViews"
              yAxisId="left"
              fill={colors.lightRed}
            />
            <Bar
              name={i18next.t('stats_barChart_listViews')}
              dataKey="listViews"
              shape={<RectangleOverride chartHeight={height} />}
              yAxisId="right"
              fill={colors.lightGrey}
            />
          </BarCh>
        </ResponsiveContainer>
      </div>
      <Legend
        data={[
          { text: i18next.t('stats_barChart_profileViews'), color: 'bg-lightRed' },
          { text: i18next.t('stats_barChart_listViews'), color: 'bg-lightGrey' },
          { text: i18next.t('stats_barChart_fixturesListed'), color: 'bg-white' },
        ]}
      />
    </>
  );
};

BarChart.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape().isRequired).isRequired,
  height: PropTypes.number.isRequired,
  isMobile: PropTypes.bool.isRequired,
};

export default BarChart;

import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';

const Button = ({ className, capitalize, onClick, children, ...rest }) => (
  <button
    className={`Button ${capitalize ? 'uppercase' : ''} ${className}`}
    type="button"
    onClick={onClick}
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...rest}
  >
    {children}
  </button>
);

Button.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.node,
  capitalize: PropTypes.bool,
};

Button.defaultProps = {
  className: 'bg-transparent',
  onClick: () => {},
  children: null,
  capitalize: true,
};

export default Button;

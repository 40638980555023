import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import i18n from 'i18next';
import { getVenue } from '../../../store/venue/selectors';
import { historyProps, venueProps } from '../../../utils/customPropTypes';
import { findDomainExt } from '../../../utils/environment';
import xmlFeed from './XMLSnippet';
import Snippet from '../../../components/Snippet';
import { fireEvent, GACategories, GATags } from '../../../utils/trackingHelper';
import './styles.scss';
import { Header, SliderNavigation } from '../common';
import { getTableData } from './util';
import JSONFeed from './jsonFeed.json';
import TextToggle from '../../../components/TextToggle/TextToggle';
import { useHiddenByPermissions } from '../../../utils/CustomHooks';
import { cdnUrl } from '../../../utils/constants';

const CDN = `${cdnUrl}/integration/xml`;

const examples = [
  {
    image: `${CDN}/regent.png`,
    text: 'integrate.XML.regent',
    link: 'https://theregentbalham.co.uk/sports-balham',
  },
  {
    image: `${CDN}/batAndBall.png`,
    text: 'integrate.XML.batAndBall',
    link: 'https://www.thebatandball.com/live-sport',
  },
  {
    image: `${CDN}/albion.png`,
    text: 'integrate.XML.albion',
    link: 'https://www.thealbionlondon.com/live-sport',
  },
];

const Title = ({ children, className }) => (
  <h2 className={`font-bold text-xl md:text-[28px] ${className}`}>{children}</h2>
);
const Text = ({ children, className }) => (
  <p className={`opacity-90 text-base md:text-xl ${className}`}>{children}</p>
);

Title.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};
Text.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};
Title.defaultProps = {
  className: '',
};
Text.defaultProps = {
  className: '',
};

const APIFeed = ({ venue, title, history }) => {
  const { JSONT, XMLT } = { JSONT: 'JSON', XMLT: 'XML' };

  const [id, setId] = useState(0);
  const [selectedType, setSelectedType] = useState(JSONT);

  const service = `https://www-service.${findDomainExt()}/venues/${venue.id}/fixture`;

  const typeData = {
    [JSONT]: {
      subTitle: 'integrate.JSON.howTo_description',
      objectsSubTitle: 'integrate.JSON.structure',
      URL: `${service}/json`,
      snippet: JSON.stringify(JSONFeed, null, 2),
      firstHeader: 'integrate.type',
      secondHeader: 'integrate.XML.table.f.description',
    },
    [XMLT]: {
      subTitle: 'integrate.XML.howTo_description',
      objectsSubTitle: 'integrate.XML.main_object',
      URL: `${service}/xml?newFields=1`,
      snippet: xmlFeed(venue),
      firstHeader: 'integrate.XML.table.f.description',
      secondHeader: 'integrate.XML.table.f.example',
    },
  };

  useHiddenByPermissions();

  return (
    <>
      <Header history={history} title={title} />
      <div className="bg-offWhite pb-20">
        <div className="mx-auto 2xl:max-w-[1280px] 2xl:px-0 px-4 sm:px-6 lg:px-10 pt-8">
          <div className="lg:flex border-b border-mpGrey pb-6">
            <div className="flex-1 lg:pr-4">
              <Title className="pb-2">{i18n.t('integrate_subtitle')}</Title>
              <Text className="pb-8">{i18n.t('integrate.XML.how_to_description')}</Text>
            </div>
            <div className="flex-1 lg:pl-4 lg:border-l border-mpGrey">
              <SliderNavigation
                clickLeft={() => setId(id === 0 ? examples.length - 1 : id - 1)}
                clickRight={() => setId(id === examples.length - 1 ? 0 : id + 1)}
                title={i18n.t('integrate_xmlExamples')}
                textComponent={
                  <a
                    target="_blank"
                    rel="noreferrer"
                    className="md:text-xl font-bold opacity-80 underline"
                    href={examples[id].link}
                  >
                    {i18n.t(examples[id].text)}
                  </a>
                }
              />
              <div className="flex justify-center mb-6 md:mb-8 mt-2 p-2 pb-0">
                <img
                  alt={i18n.t(examples[id].text)}
                  src={examples[id].image}
                  className="object-contain h-[160px] md:h-[400px] lg:h-[300px]"
                />
              </div>
            </div>
          </div>
          <Title className="my-4">{i18n.t('integrate.howTo')}</Title>

          <TextToggle
            active={selectedType === XMLT}
            onToggleOff={() => setSelectedType(JSONT)}
            onToggleOn={() => setSelectedType(XMLT)}
            offText={JSONT}
            onText={XMLT}
            containerClasses="py-4 sm:py-6"
          />
          <Text>{i18n.t(typeData[selectedType].subTitle)}</Text>
          {venue && (
            <Snippet
              id="endpoint"
              codeSnippet={typeData[selectedType].URL}
              gaTracking={() =>
                fireEvent(GACategories.SOCIAL_MEDIA_CONTENT, GATags.COPIED_XML_LINK)
              }
              sideBySideOnTabletUp
            />
          )}
          <h2 className="text-xl font-semibold pb-2">{i18n.t('integrate.XML.item_objects')}</h2>
          <Text>{i18n.t(typeData[selectedType].objectsSubTitle)}</Text>
          <div className="overflow-x-auto my-4">
            <div className="w-[900px] md:w-auto ">
              <div className="XMLFeed_row">
                <div className="XMLFeed_column_20 XMLFeed_header">
                  <p>{i18n.t('integrate.XML.table.f.title')}</p>
                </div>
                <div className="XMLFeed_column_30 XMLFeed_header">
                  <p>{i18n.t(typeData[selectedType].firstHeader)}</p>
                </div>
                <div className="XMLFeed_column_50 XMLFeed_header">
                  <p>{i18n.t(typeData[selectedType].secondHeader)}</p>
                </div>
              </div>
              {getTableData(venue, i18n.t, selectedType).map(({ ti, description, example }) => (
                <div key={`${selectedType}${ti}`} className="XMLFeed_row">
                  <div className="XMLFeed_column_20">
                    <p>{ti}</p>
                  </div>
                  <div className="XMLFeed_column_30">
                    <p>{description}</p>
                  </div>
                  <div className="XMLFeed_column_50">
                    <p>{example}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <h2 className="text-xl font-semibold pt-4 pb-2">{i18n.t('integrate.XML.structure')}</h2>
          {venue && (
            <Snippet
              id="xmlfeed"
              codeSnippet={typeData[selectedType].snippet}
              gaTracking={() =>
                fireEvent(GACategories.SOCIAL_MEDIA_CONTENT, GATags.COPIED_XML_STRUCTURE)
              }
            />
          )}
        </div>
      </div>
    </>
  );
};

APIFeed.propTypes = {
  venue: venueProps.isRequired,
  title: PropTypes.string.isRequired,
  history: historyProps.isRequired,
};

const mapStateToProps = state => ({
  venue: getVenue(state),
});

export default connect(mapStateToProps)(APIFeed);

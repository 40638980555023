import * as consts from './constants';

export function updateSMTheme(theme) {
  return {
    type: consts.UPDATE_SMTHEME,
    payload: { theme },
  };
}

export function addSMFixture(fixture) {
  return {
    type: consts.ADD_SMFIXTURE,
    payload: { fixture },
  };
}

export function removeSMFixture(fixture) {
  return {
    type: consts.REMOVE_SMFIXTURE,
    payload: { fixture },
  };
}

export function bulkRemoveSMFixture(desiredNumberOfFixtures) {
  return {
    type: consts.REMOVE_BULK_SMFIXTURE,
    payload: { desiredNumberOfFixtures },
  };
}

export function setSMFixtures(fixtures) {
  return {
    type: consts.SET_SMFIXTURES,
    payload: { fixtures },
  };
}

export function postPublishPending() {
  return {
    type: consts.POST_PUBLISH_PENDING,
  };
}

export function postPublishSuccess() {
  return {
    type: consts.POST_PUBLISH_SUCCESS,
  };
}

export function postPublishError() {
  return {
    type: consts.POST_PUBLISH_ERROR,
  };
}

export function clearSocialMedia() {
  return {
    type: consts.CLEAR_SOCIAL_MEDIA,
  };
}

export function fetchContentPending() {
  return {
    type: consts.FETCH_CONTENT_PENDING,
  };
}

export function fetchContentSuccess(content) {
  return {
    type: consts.FETCH_CONTENT_SUCCESS,
    payload: content,
  };
}

export function fetchContentError() {
  return {
    type: consts.FETCH_CONTENT_ERROR,
  };
}

export function fetchNextContentPending() {
  return {
    type: consts.FETCH_NEXT_CONTENT_PENDING,
  };
}

export function fetchNextContentSuccess(content) {
  return {
    type: consts.FETCH_NEXT_CONTENT_SUCCESS,
    payload: content,
  };
}

export function fetchNextContentError() {
  return {
    type: consts.FETCH_NEXT_CONTENT_ERROR,
  };
}

export function fetchScheduledSocialPostsPending() {
  return {
    type: consts.FETCH_SCHEDULED_POSTS_PENDING,
  };
}

export function fetchScheduledSocialPostsSuccess(result) {
  return {
    type: consts.FETCH_SCHEDULED_POSTS_SUCCESS,
    payload: result,
  };
}

export function fetchScheduledSocialPostsError() {
  return {
    type: consts.FETCH_SCHEDULED_POSTS_ERROR,
  };
}

export function fetchNextScheduledSocialPostsPending() {
  return {
    type: consts.FETCH_NEXT_SCHEDULED_POSTS_PENDING,
  };
}

export function fetchNextScheduledSocialPostsSuccess(result) {
  return {
    type: consts.FETCH_NEXT_SCHEDULED_POSTS_SUCCESS,
    payload: result,
  };
}

export function fetchNextScheduledSocialPostsError() {
  return {
    type: consts.FETCH_NEXT_SCHEDULED_POSTS_ERROR,
  };
}

export function fetchPostedSocialPostsPending() {
  return {
    type: consts.FETCH_POSTED_POSTS_PENDING,
  };
}

export function fetchPostedSocialPostsSuccess(result) {
  return {
    type: consts.FETCH_POSTED_POSTS_SUCCESS,
    payload: result,
  };
}

export function fetchPostedSocialPostsError() {
  return {
    type: consts.FETCH_POSTED_POSTS_ERROR,
  };
}

export function fetchNextPostedSocialPostsPending() {
  return {
    type: consts.FETCH_NEXT_POSTED_POSTS_PENDING,
  };
}

export function fetchNextPostedSocialPostsSuccess(result) {
  return {
    type: consts.FETCH_NEXT_POSTED_POSTS_SUCCESS,
    payload: result,
  };
}

export function fetchNextPostedSocialPostsError() {
  return {
    type: consts.FETCH_NEXT_POSTED_POSTS_ERROR,
  };
}

export function deleteSocialPostPending() {
  return {
    type: consts.DELETE_POST_PENDING,
  };
}

export function deleteSocialPostSuccess(postId) {
  return {
    type: consts.DELETE_POST_SUCCESS,
    payload: postId,
  };
}

export function deleteSocialPostError() {
  return {
    type: consts.DELETE_POST_ERROR,
  };
}

export function fetchPagesStart() {
  return {
    type: consts.FETCH_PAGES_START,
  };
}

export function fetchPagesSuccess(pages) {
  return {
    type: consts.FETCH_PAGES_SUCCESS,
    payload: pages,
  };
}

export function fetchPagesError() {
  return {
    type: consts.FETCH_PAGES_ERROR,
  };
}

export function fetchSocialAccountsStart(facebook, twitter) {
  return {
    type: consts.FETCH_ACCOUNTS_START,
    payload: { twitter, facebook },
  };
}

export function fetchSocialAccountsSuccess(accounts) {
  return {
    type: consts.FETCH_ACCOUNTS_SUCCESS,
    payload: accounts,
  };
}

export function fetchSocialAccountsError() {
  return {
    type: consts.FETCH_ACCOUNTS_ERROR,
  };
}

export function postWasScheduled(fixtures) {
  return {
    type: consts.POST_WAS_SCHEDULED,
    payload: fixtures,
  };
}

export function postWasPublished(fixtures) {
  return {
    type: consts.POST_WAS_POSTED,
    payload: fixtures,
  };
}

export const setNumberOfFixtures = val => ({
  type: consts.SET_SM_NUMBER_OF_FIXTURES,
  payload: val,
});

export const setSMFormat = val => ({
  type: consts.SET_SM_FORMAT,
  payload: val,
});
